import React from 'react';
import { Card } from 'react-bootstrap';

import { Page } from 'components/Common';
import { ExportOrdersForm } from 'lib/orders';

const ExportOrdersPage = () => {
  return (
    <Page
      content={
        <Card>
          <Card.Body>
            <ExportOrdersForm />
          </Card.Body>
        </Card>
      }
    />
  );
};

ExportOrdersPage.propTypes = {};

export default ExportOrdersPage;
