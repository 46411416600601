import { routePaths } from 'appConfig';
import Table from 'core/components/Table';
import { Datetime } from 'lib/datetime';
import { Link } from 'lib/router';
import type { CollectionPoint } from './types';

interface CollectionPointsTableProps {
  data: Array<CollectionPoint>;
  isLoading?: boolean;
  page?: number;
  sizePerPage?: number;
  totalSize?: number;
  sortField?: string;
  sortDir?: 'asc' | 'desc';
  onTableChange?: (action: string, newState: any) => Promise<void>;
}

const CollectionPointsTable = ({
  data,
  isLoading,
  page,
  sizePerPage,
  totalSize,
  sortField,
  sortDir,
  onTableChange
}: CollectionPointsTableProps) => {

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (
        name: string,
        collectionPoint: CollectionPoint
        ) => (
          <Link to={`${routePaths.COLLECTION_POINTS}/${collectionPoint.id}`}>{name}</Link>
        ),
      sort: true,
    },
    {
      dataField: 'created_at',
      text: 'Date',
      // @ts-expect-error
      formatter: (createdAt: string) => <Datetime value={createdAt} />,
      sort: true,
    }
  ];

  return (
    <Table
      remote
      loading={isLoading}
      keyField="id"
      columns={columns}
      data={data}
      pagination={{ page, sizePerPage, totalSize }}
      sort={{ dataField: sortField, order: sortDir }}
      onTableChange={onTableChange}
    />
  );
};

export default CollectionPointsTable;
