import { useCRUD } from 'lib/swr-crud';
import {
  getAddonOption,
  updateAddonOption,
  deleteAddonOption,
  createAddonOptionValue,
  updateAddonOptionValue,
  deleteAddonOptionValue,
  reorderAddonOptionValue,
} from './api';

const useAddonOption = (addonOptionId) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/cms/addon_options/${addonOptionId}`,
    async () => {
      return getAddonOption(addonOptionId);
    },
  );
  const addonOption = data || null;

  const update = async (body) => {
    const data = await updateAddonOption(addonOptionId, body);
    mutate(data);
    return data;
  };

  const _delete = async () => {
    await deleteAddonOption(addonOptionId);
  };

  const addValue = async (body) => {
    await createAddonOptionValue(addonOptionId, body);
  };

  const updateValue = async (valueId, body) => {
    await updateAddonOptionValue(addonOptionId, valueId, body);
  };

  const deleteValue = async (valueId) => {
    await deleteAddonOptionValue(addonOptionId, valueId);
  };

  const reorderValue = async (valueId, body) => {
    await reorderAddonOptionValue(addonOptionId, valueId, body);
  };

  return {
    addonOption,
    error,
    update,
    _delete,
    addValue,
    updateValue,
    deleteValue,
    reorderValue,
    mutate,
    isValidating,
    isLoading,
  };
};

export default useAddonOption;
