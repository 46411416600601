import React, { useState } from 'react';
import { Button } from 'components/Common';
import { Col, Row } from 'reactstrap';
import AddonOptionValueLines from './AddonOptionValueLines';
import NewAddonOptionValueModal from '../NewAddonOptionValueModal';

const AddonOptionValueSection = ({
  addonOption,
  onAdd,
  onUpdate,
  onDelete,
  onReorder,
  mutate,
}) => {
  const [isOpenModal, setOpenModal] = useState(false);

  const _renderValues = () => {
    if (addonOption?.values?.length < 1) {
      return (
        <p className="text-center">
          No Option value yet. Please add more Option Value.
        </p>
      );
    }

    return (
      <AddonOptionValueLines
        addonOptionId={addonOption?.id}
        values={addonOption?.values}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onReorder={onReorder}
        mutate={mutate}
      />
    );
  };

  return (
    <div>
      <Row>
        <Col>{_renderValues()}</Col>
      </Row>
      <Row>
        <Col xs={12} className="d-flex">
          <Button
            color={'info'}
            onClick={() => setOpenModal(true)}
            className="flex-fill"
          >
            Add More Option Value
          </Button>
        </Col>
      </Row>

      <NewAddonOptionValueModal
        isOpen={isOpenModal}
        onClose={() => setOpenModal(false)}
        onAdd={onAdd}
        mutate={mutate}
      />
    </div>
  );
};

export default AddonOptionValueSection;
