import { routePaths } from 'appConfig';

import { USER_ROLE } from 'lib/users/constants';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const HomeRedirect = () => {
  const history = useHistory();

  useEffect(() => {
    const authUser = JSON.parse(
      localStorage.getItem('authUser') || '{}',
    );
    const userRole = authUser?.data?.role;

    if (userRole) {
      if (
        userRole === USER_ROLE.KITCHEN_REPRESENTATIVE_MAIN ||
        userRole === USER_ROLE.KITCHEN_REPRESENTATIVE_OTHER
      ) {
        history.push(routePaths.COMPLAINTS);
      } else {
        history.push(routePaths.ANALYTICS);
      }
    } else {
      history.push('/login');
    }
  }, [history]);

  return <></>;
};

export default HomeRedirect;
