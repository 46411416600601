import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';

import Label from '../Label';
import Tooltip from '../Tooltip';

const QuantityInput = ({
  name,
  label,
  value,
  onChange,
  min,
  max,
  step,
  required,
  disabled,
  error,
  ...others
}) => {
  const [rawValue, setRawValue] = React.useState(value);

  React.useEffect(() => {
    setRawValue(value);
  }, [value]);

  const canDecrement = min <= parseInt(value) - parseInt(step);
  const canIncrement = max >= parseInt(value) + parseInt(step);

  const decrement = () => {
    if (canDecrement) {
      value = parseInt(value) - parseInt(step);
      onChange(value);
    }
  };

  const increment = () => {
    if (canIncrement) {
      value = parseInt(value) + parseInt(step);
      onChange(value);
    }
  };

  const handleOnBlur = () => {
    const rawValueInt = parseInt(rawValue);

    let newValue = rawValueInt;

    // if invalid, keep current value
    if (Number.isNaN(rawValueInt)) newValue = value;

    if (rawValueInt > max) newValue = max;

    if (rawValueInt < min) newValue = min;

    onChange(newValue);
    setRawValue(newValue);
  };

  const handleOnChange = (e) => {
    const newValue = e.target.value;

    setRawValue(newValue);
  };

  return (
    <FormGroup>
      <Label htmlFor={name} error={error} required={required}>
        {label}
      </Label>
      <div className="input-group input-spinner">
        <div className="input-group-prepend">
          <button
            style={{ minWidth: '26px' }}
            className="btn btn-decrement btn-spinner"
            type="button"
            onClick={decrement}
            disabled={disabled || !canDecrement}
          >
            -
          </button>
        </div>
        <input
          {...others}
          name={name}
          type="text"
          style={{ textAlign: 'center' }}
          className="form-control"
          required={required}
          placeholder=""
          value={rawValue}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          disabled={disabled}
        />
        <Tooltip
          trigger={!canIncrement ? ['hover', 'focus'] : []}
          content={
            'You have reached the maximum quantity available! '
          }
          placement="top"
        >
          <div className="input-group-append">
            <button
              style={{ minWidth: '26px' }}
              className="btn btn-increment btn-spinner"
              type="button"
              onClick={increment}
              disabled={disabled || !canIncrement}
            >
              +
            </button>
          </div>
        </Tooltip>
      </div>
    </FormGroup>
  );
};

QuantityInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

QuantityInput.defaultProps = {
  min: 1,
  max: 999,
  step: 1,
  required: false,
  disabled: false,
  error: false,
};

export default QuantityInput;
