import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { FormManager, Form } from 'lib/form';
import { merge } from 'lib/javascript';
import notification from 'lib/notification';
import validator from 'lib/validator';
import { Button } from 'components/Common';

import { putProduct } from './api';
import useProduct from './useProduct';
import TabContentFieldset from './TabContentFieldset';
import { createTab } from './utils';

const defaultValues = {
  tab_contents: [createTab()],
};

const validationSchema = validator.object({
  tab_contents: validator.array().of(
    validator.object({
      label: validator.string().required(),
      content: validator.string(),
    }),
  ),
});

const TabContentForm = ({ productId }) => {
  const { isLoading, product, mutate } = useProduct(productId);

  const handleOnSubmit = async (product, actions) => {
    try {
      const { response, error } = await putProduct(
        productId,
        product,
      );

      if (error) throw error;

      mutate(response.data);

      notification.success(
        'Successfully updated product tab contents',
      );
      return;
    } catch (error) {
      actions.setErrors(error.errors);
      actions.setStatus({
        error:
          error.errors?.message ||
          'Error updating product tab contents',
      });
      return;
    }
  };

  if (isLoading) return null;

  return (
    <FormManager
      initialValues={merge({}, defaultValues, product)}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Row>
            <Col>
              <TabContentFieldset
                name="tab_contents"
                product={product}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '24px' }}>
            <Col>
              <Button type="submit" loading={isSubmitting}>
                Save Changes
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </FormManager>
  );
};

TabContentForm.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default TabContentForm;
