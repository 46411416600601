import * as yup from 'yup';
import {
  COUNTRIES,
  countryOptions,
  ORDER_NOTES_TEMPLATE,
} from './constants';
import {
  AddressFormModel,
  Address as AddressInterface,
  AddressPayload,
} from './types';
import { Form, FormManager } from 'lib/form';
import { FormikHelpers } from 'formik';
import { Button, Title } from 'components/Common';
import { Col, Row } from 'reactstrap';
import {
  CheckboxField,
  SelectField,
  TextField,
} from 'components/formFields';
import React from 'react';
import { generatePayload } from './utils';
import { UpdateAddressError } from './errors';

interface AddressFormProps {
  address?: AddressInterface;
  title?: string;
  onSubmit: (payload: AddressPayload) => Promise<void>;
}

const defaultValues: AddressFormModel = {
  id: null,
  label: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  country: COUNTRIES.SINGAPORE,
  postal_code: '',
  address_1: '',
  address_2: '',
  address_3: null,
  company_name: '',
  delivery_notes: '',
  leave_cooler_bag: false,
  type: 'shipping',
  do_not_have_unit_number: false,
  is_order_address: false,
};

const AddressForm = ({
  address,
  title,
  onSubmit,
}: AddressFormProps) => {
  const isBillingAddress = address?.type === 'billing';
  const isOrderAddress = address?.is_order_address;

  const isLeaveCoolerBagChecked = Boolean(
    address?.delivery_notes &&
      address?.delivery_notes.startsWith(ORDER_NOTES_TEMPLATE.trim()),
  );

  const initialValues: AddressFormModel = {
    ...defaultValues,
    leave_cooler_bag: isLeaveCoolerBagChecked,
    ...address,
    company_name: isBillingAddress ? address?.company_name : null,
    delivery_notes: isBillingAddress ? null : address?.delivery_notes,
    do_not_have_unit_number: address
      ? address?.address_3 === null
      : true,
  };
  const addressId = address?.id;

  const validationSchema = yup.object({
    label:
      addressId && !address?.label
        ? yup.string().nullable()
        : yup.string().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    phone_number: yup.string().required(),
    country: yup.string().required(),
    postal_code: yup.string().length(6).required(),
    address_1: yup.string().required(),
    address_2: yup.string().nullable(),
    address_3: yup
      .string()
      .nullable()
      .when('do_not_have_unit_number', {
        is: false,
        then: yup.string().nullable().required(),
      }),
    ...(isBillingAddress
      ? { company_name: yup.string().nullable() }
      : {}),
    delivery_notes: yup.string().nullable(),
  });

  const handleOnSubmit = async (
    values: AddressFormModel,
    actions: FormikHelpers<AddressFormModel>,
  ) => {
    try {
      const payload = generatePayload(values);
      await onSubmit(payload);
    } catch (error) {
      if (error instanceof UpdateAddressError) {
        const fields =
          // @ts-ignore-line
          error.serverError?.response?.data?.errors ?? [];
        Object.keys(fields).forEach((key) => {
          actions.setErrors({ [key]: fields[key][0] });
        });
      }
    }
  };

  return (
    <FormManager
      initialValues={initialValues}
      validateOnBlur
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {(actions) => {
        const { values, isSubmitting, setFieldValue } = actions;
        const hasUnitNumber = !values.do_not_have_unit_number;

        return (
          <Form>
            {title && <Title>{title}</Title>}
            <input
              type="hidden"
              name="id"
              value={initialValues?.id ?? undefined}
            />
            {!isOrderAddress && !isBillingAddress && (
              <Row>
                <Col>
                  {/* @ts-ignore-line */}
                  <TextField
                    name="label"
                    label="Delivery Address's Nickname"
                    required={!Boolean(addressId && !address.label)}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={6}>
                {/* @ts-ignore-line */}
                <TextField
                  name="first_name"
                  label="First Name"
                  required
                />
              </Col>
              <Col lg={6}>
                {/* @ts-ignore-line */}
                <TextField
                  name="last_name"
                  label="Last Name"
                  required
                />
              </Col>
            </Row>
            <Row>
              {isBillingAddress && (
                <Col lg={6}>
                  {/* @ts-ignore-line */}
                  <TextField
                    name="company_name"
                    label="Company Name"
                  />
                </Col>
              )}
              <Col lg={6}>
                {/* @ts-ignore-line */}
                <TextField
                  name="phone_number"
                  label="Contact Number"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                {/* @ts-ignore-line */}
                <SelectField
                  name="country"
                  label="Country"
                  options={countryOptions}
                  required
                />
              </Col>
              <Col lg={6}>
                {/* @ts-ignore-line */}
                <TextField
                  name="postal_code"
                  label="Postal Code"
                  required
                  maxLength={6}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* @ts-ignore-line */}
                <TextField
                  name="address_1"
                  label="Block No. & Street"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* @ts-ignore-line */}
                <TextField name="address_2" label="Building Name" />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                {/* @ts-ignore-line */}
                <TextField
                  name="address_3"
                  label="Unit No."
                  disabled={!hasUnitNumber}
                  required={hasUnitNumber}
                  formGroupProps={{ className: 'mb-1' }}
                />
                {/* @ts-ignore-line */}
                <CheckboxField
                  name="do_not_have_unit_number"
                  label="There's no Unit No."
                />
              </Col>
            </Row>
            {!isBillingAddress && !isOrderAddress && (
              <Row>
                <Col>
                  {/* @ts-ignore-line */}
                  <TextField
                    name="delivery_notes"
                    label="Delivery Notes"
                    type="textarea"
                    formGroupProps={{ className: 'mb-1' }}
                    placeholder="i.e. Specific lift lobby? Put order inside dry riser? Alternate contact person & number? Beware of dog/cat/dinosaur?"
                    rows="4"
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                      const { value } = event.target;
                      const {
                        leave_cooler_bag,
                      }: { leave_cooler_bag: boolean } = values;

                      if (
                        leave_cooler_bag &&
                        !value.startsWith(ORDER_NOTES_TEMPLATE)
                      ) {
                        setFieldValue(
                          'delivery_notes',
                          ORDER_NOTES_TEMPLATE,
                        );
                      } else {
                        setFieldValue('delivery_notes', value);
                      }
                    }}
                  />
                  {/* @ts-ignore-line */}
                  <CheckboxField
                    name="leave_cooler_bag"
                    label="Will you leave a cooler bag/box outside?"
                    helperText="So that our delivery folks can place your order inside IF you're not present"
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                      const { checked } = event.target;
                      const {
                        delivery_notes,
                      }: { delivery_notes?: string | null } = values;

                      if (checked) {
                        setFieldValue(
                          'delivery_notes',
                          `${ORDER_NOTES_TEMPLATE}${
                            delivery_notes ?? ''
                          }`,
                        );
                      } else {
                        if (
                          delivery_notes?.startsWith(
                            ORDER_NOTES_TEMPLATE.trim(),
                          )
                        ) {
                          setFieldValue(
                            'delivery_notes',
                            delivery_notes.substring(
                              ORDER_NOTES_TEMPLATE.length,
                            ),
                          );
                        }
                      }
                    }}
                  />
                </Col>
              </Row>
            )}
            <div className="d-flex mt-3">
              {/* @ts-ignore-line */}
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                <span>Save Details</span>
              </Button>
            </div>
          </Form>
        );
      }}
    </FormManager>
  );
};

export default AddressForm;
