import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components/Common';
import { USER_ROLE } from './constants';
import {
  DISPLAY_KITCHEN_MAIN,
  DISPLAY_KITCHEN_OTHER,
} from 'lib/complaint/utils';

const RoleSelect = ({
  name,
  label,
  defaultValue,
  value,
  onChange,
  required,
  error,
  disabled,
  classes,
  styles,
}) => {
  return (
    <Select
      name={name}
      label={label}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      required={required}
      error={error}
      disabled={disabled}
      classes={classes}
      styles={styles}
      options={[
        {
          label: 'Admin',
          value: USER_ROLE.ADMIN,
        },
        {
          label: 'Customer Service',
          value: USER_ROLE.CUSTOMER_SERVICE,
        },
        {
          label: 'Executive Assistant',
          value: USER_ROLE.EXECUTIVE_ASSISTANT,
        },
        {
          label: 'Customer',
          value: USER_ROLE.CUSTOMER,
        },
        {
          label: 'Affiliate',
          value: USER_ROLE.AFFILIATE,
        },
        {
          label: DISPLAY_KITCHEN_MAIN,
          value: USER_ROLE.KITCHEN_REPRESENTATIVE_MAIN,
        },
        {
          label: DISPLAY_KITCHEN_OTHER,
          value: USER_ROLE.KITCHEN_REPRESENTATIVE_OTHER,
        },
      ]}
    />
  );
};

RoleSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  value: PropTypes.oneOfType(Object.values(USER_ROLE)),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    container: PropTypes.string,
  }),
  styles: PropTypes.shape({
    container: PropTypes.object,
  }),
};

export default RoleSelect;
