import { useCRUD } from 'lib/swr-crud';
import {
  addCatalogueItem,
  deleteCatalogue,
  deleteCatalogueItem,
  getCatalogue,
  putCatalogue,
  sortCatalogueItem,
  updateCatalogueItem,
} from './api';
import {
  Catalogue,
  CatalogueItemPayload,
  CataloguePayload,
} from './types';

const useCatalogue = (catalogueId: number | string) => {
  const { data, error, mutate, isLoading } = useCRUD(
    `/cms/catalogues/${catalogueId}`,
    async () => {
      return getCatalogue(catalogueId);
    },
  );

  const catalogue: Catalogue | undefined = data;

  const update = async (body: CataloguePayload) => {
    await putCatalogue(catalogueId, body);
  };

  const remove = async () => {
    await deleteCatalogue(catalogueId);
  };

  const addItem = async (body: CatalogueItemPayload) => {
    await addCatalogueItem(catalogueId, body);
  };

  const updateItem = async (
    itemId: string | number,
    body: CatalogueItemPayload,
  ) => {
    await updateCatalogueItem(catalogueId, itemId, body);
  };

  const deleteItem = async (itemId: string | number) => {
    await deleteCatalogueItem(catalogueId, itemId);
  };

  const sortItem = async (
    changedId?: string | number,
    beforeId?: string | number | null,
  ) => {
    await sortCatalogueItem(catalogueId, changedId as string, {
      before_id: beforeId as string,
    });
  };

  return {
    catalogue,
    update,
    remove,
    addItem,
    updateItem,
    deleteItem,
    sortItem,
    mutate,
    isLoading,
    error,
  };
};

export default useCatalogue;
