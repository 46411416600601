import { displayName } from 'lib/utils';

import { isEmptyAddress, formatAddress } from '../utils';
import { Address as AddressInterface } from '../types';
import { Button } from 'components/Common';

interface AddressProps {
  address: AddressInterface | null;
  pinned: boolean;
  isProcessing?: boolean;
  showDefaultBadge?: boolean;
  showActions?: boolean;
  showPhoneNumber?: boolean;
  isOrderAddress?: boolean;
  className?: string;
  onSetDefaultAddress?: (addressId: string | null) => Promise<void>;
  onSetEditAddress?: (address: AddressInterface | null) => void;
  onSetDeletedAddress?: (address: AddressInterface) => Promise<void>;
  title?: string;
}

const Address = ({
  address,
  pinned,
  showDefaultBadge,
  showPhoneNumber = true,
  showActions = true,
  isOrderAddress = false,
  isProcessing,
  onSetEditAddress,
  onSetDeletedAddress,
  onSetDefaultAddress,
  className,
  title,
}: AddressProps) => {
  const renderCardText = () => {
    if (!address || isEmptyAddress(address)) {
      return (
        <div className="d-flex flex-column">
          <p className="mb-0">
            You have not set up this type of address yet.
          </p>
          <div className="d-flex justify-content-end align-align-items-center">
            {/* @ts-ignore-line */}
            <Button
              variant="link"
              color="secondary"
              className="p-0 border-0"
              style={{ textDecoration: 'none' }}
              onClick={() =>
                onSetEditAddress && onSetEditAddress(address)
              }
            >
              <span>
                <i className="fas fa-edit tw-m-0 tw-font-light"></i>{' '}
                Edit
              </span>
            </Button>
          </div>
        </div>
      );
    }

    const {
      first_name,
      last_name,
      label,
      default: isDefault,
      phone_number,
      delivery_notes,
    } = address;

    const fullName = displayName({
      firstName: first_name,
      lastName: last_name,
    });

    return (
      <div className="d-flex flex-column">
        {title && <h5 className="font-weight-bold">{title}</h5>}
        <div className="d-flex flex-column">
          {(label || (showDefaultBadge && isDefault)) && (
            <div className="d-flex align-items-center mb-1">
              {label && (
                <p
                  className="mb-0 mr-2"
                  style={{
                    color: '#8b8b8b',
                    fontWeight: '600',
                    lineHeight: '1.25',
                    fontSize: '0.75rem',
                  }}
                >
                  {label}
                </p>
              )}
              {showDefaultBadge && isDefault && (
                <div className="badge badge-warning px-2 py-1 font-weight-bold">
                  Default
                </div>
              )}
            </div>
          )}
          <div className="d-flex flex-column">
            <p className="d-block mb-0 font-weight-bolder">
              {fullName ?? '(full name N/A)'}
            </p>
            {showPhoneNumber && (
              <p className="d-block mb-0 mt-1">
                <span>{phone_number ?? '(phone number N/A)'}</span>
              </p>
            )}
            <p
              className="mt-1"
              style={{ whiteSpace: 'pre-line', lineHeight: '1.375' }}
            >
              {formatAddress(address)}
            </p>
          </div>
        </div>
        {delivery_notes && !isOrderAddress && (
          <div className="d-flex align-items-center mb-3">
            <p className="mb-0 mr-1 font-weight-bold">
              Delivery Notes:
            </p>
            <div
              style={{
                fontStyle: 'italic',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: 'calc(100% - 120px)',
              }}
              title={delivery_notes}
            >
              {delivery_notes}
            </div>
          </div>
        )}
        {showActions && (
          <div className="d-flex">
            {/* @ts-ignore-line */}
            <Button
              variant="link"
              color="secondary"
              className="p-0 border-0"
              style={{ textDecoration: 'none' }}
              onClick={() =>
                onSetEditAddress && onSetEditAddress(address)
              }
              loading={isProcessing}
              disabled={isProcessing}
            >
              <span>
                <i className="fas fa-edit tw-m-0 tw-font-light"></i>{' '}
                Edit
              </span>
            </Button>
            {!isDefault && !isOrderAddress && (
              <>
                {/* @ts-ignore-line */}
                <Button
                  variant="link"
                  color="secondary"
                  className="p-0 border-0 ml-4"
                  style={{ textDecoration: 'none' }}
                  onClick={() =>
                    onSetDeletedAddress &&
                    onSetDeletedAddress(address)
                  }
                  loading={isProcessing}
                  disabled={isProcessing}
                >
                  <span>
                    <i className="fas fa-trash-alt tw-m-0 tw-font-light"></i>{' '}
                    Delete
                  </span>
                </Button>
                {/* @ts-ignore-line */}
                <Button
                  variant="link"
                  color="secondary"
                  className="p-0 border-0 ml-4"
                  style={{ textDecoration: 'none' }}
                  onClick={() =>
                    onSetDefaultAddress &&
                    onSetDefaultAddress(address.id)
                  }
                  loading={isProcessing}
                  disabled={isProcessing}
                >
                  <span>
                    <i className="fas fa-check tw-m-0 tw-font-light"></i>{' '}
                    Set as Default
                  </span>
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`card card-dashboard h-100 ${className}`}
      style={
        pinned
          ? {
              border: '1px solid #a42e27',
              backgroundColor: 'rgba(164, 46, 39, 0.02)',
            }
          : { border: '1px solid transparent' }
      }
    >
      <div className="card-body">{renderCardText()}</div>
    </div>
  );
};

export default Address;
