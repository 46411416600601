import React from 'react';
import PropTypes from 'prop-types';

import { routePaths } from 'appConfig';
import notification from 'lib/notification';
import { useRouter } from 'lib/router';

import TagForm from './TagForm';
import { postCreateTag } from './api';

const AddTagForm = ({ onSubmit }) => {
  const { push } = useRouter();

  const handleOnSubmit = async (values, actions) => {
    try {
      const response = await postCreateTag(values);

      notification.success('Successfully created tag. ');
      push({ pathname: routePaths.TAGS });
      onSubmit(null, response);
      return;
    } catch (err) {
      actions.setErrors(err.errors);
      actions.setStatus({
        error: err.errors?.message || 'Error creating tag',
      });
      onSubmit(err, null);
      return;
    }
  };

  return <TagForm onSubmit={handleOnSubmit} buttonText="Create" />;
};

AddTagForm.propTypes = {
  onSubmit: PropTypes.func,
};

AddTagForm.defaultProps = {
  onSubmit: () => {},
};

export default AddTagForm;
