import httpClient, { getErrorMessage, getResponseData } from 'lib/httpClient';
import { CollectionPoint } from './types';

const url = '/cms/collection_points';

export const getCollectionPoint = async (collectionPointId: string) => {
  try {
    const response = await httpClient.get(`${url}/${collectionPointId}`);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putCollectionPoint = async (
  collectionPointId: string,
  data: any,
) => {
  try {
    const response = await httpClient.put(`${url}/${collectionPointId}`, data);
    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteCollectionPoint = async (collectionPointId: string) => {
  try {
    const response = await httpClient.delete(`${url}/${collectionPointId}`);
    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const createCollectionPoint = async (payload: CollectionPoint) => {
  try {
    const response = await httpClient.post(url, payload);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const createCollectionSlot = async (
  collectionPointId: string,
  payload: { slot: string, timeframe: number },
) => {
  try {
    const response = await httpClient.post(`${url}/${collectionPointId}/slots`, payload);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const updateCollectionSlot = async (
  collectionPointId: string,
  slotId: string,
  payload: { slot: string, timeframe: number },
) => {
  try {
    const response = await httpClient.put(`${url}/${collectionPointId}/slots/${slotId}`, payload);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteCollectionSlot = async (
  collectionPointId: string,
  slotId: string,
) => {
  try {
    const response = await httpClient.delete(`${url}/${collectionPointId}/slots/${slotId}`);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
