import { CheckboxInput } from '../Common';
import { Field, ErrorMessage } from 'lib/form';

const CheckboxField = ({ name, onChange, helperText, ...props }) => {
  return (
    <Field name={name}>
      {(fieldProps) => {
        const { field, form, meta } = fieldProps;

        const error = meta.touched && meta.error;

        const _onChange = (event) => {
          form.setFieldValue(field.name, event.target.checked);
          if (onChange) onChange(event);
        };

        return (
          <CheckboxInput
            {...props}
            name={field.name}
            checked={field.value}
            onChange={_onChange}
            onBlur={field.onBlur}
            error={error}
            helperText={
              error ? <ErrorMessage name={field.name} /> : helperText
            }
          />
        );
      }}
    </Field>
  );
};

export default CheckboxField;
