import React, { ReactNode } from 'react';
import { Tabs as RBTabs } from 'react-bootstrap';
import { SelectCallback } from 'react-bootstrap/esm/helpers';

interface Props {
  activeKey: string | number;
  defaultActiveKey: string | number;
  onSelect?: SelectCallback;
  children: ReactNode;
}

const Tabs = ({
  activeKey,
  defaultActiveKey,
  onSelect,
  children,
}: Props) => {
  return (
    <RBTabs
      activeKey={activeKey}
      defaultActiveKey={defaultActiveKey}
      onSelect={onSelect}
      variant="tabs"
    >
      {children}
    </RBTabs>
  );
};

export default Tabs;
