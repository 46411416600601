import { SET_COMPLAINT_FORM_STATUS } from './actionTypes';

const initialState = {
  isResolutionFormDisable: true,
  isCorrectiveActionFormDisable: true,
  isComplaintFormDisable: true,
};

export default function complaintFormReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case SET_COMPLAINT_FORM_STATUS:
      return {
        ...state,
        [action.payload.formType]: action.payload.isDisabled,
      };
    default:
      return state;
  }
}
