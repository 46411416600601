import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'components/Common';

const OperationMover = ({ isMoved, onMove }) => {
  const [isLoading, setLoading] = useState(false);

  const _onClick = () => {
    setLoading(true);
    onMove(!isMoved).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div
      style={{
        marginRight: '0.5rem',
      }}
    >
      {isLoading ? (
        <Spinner size="sm" />
      ) : (
        <div
          onClick={_onClick}
          className={'bg-primary rounded'}
          style={{
            width: '1.2rem',
            minWidth: '1.2rem',
            height: '1.2rem',
            minHeight: '1.2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i
            className={`mdi mdi-arrow-${isMoved ? 'right' : 'left'}`}
            style={{
              fontSize: '1.1rem',
              lineHeight: '1.1rem',
              color: 'white',
            }}
          ></i>
        </div>
      )}
    </div>
  );
};

OperationMover.propTypes = {
  isLeft: PropTypes.bool,
  onMove: PropTypes.func,
};

OperationMover.defaultProps = {
  isLeft: true,
  onMove: () => {},
};

export default OperationMover;
