import React from 'react';
import PropTypes from 'prop-types';
import {
  useEventEditorId,
  useStoreEditorRef,
} from '@udecode/slate-plugins-core';
import { insertImage } from '@udecode/slate-plugins-image';
import { ToolbarButton } from '@udecode/slate-plugins-toolbar';

import { MediaPickerModal, Asset } from 'lib/media';
import { merge } from 'lib/javascript';

const ToolbarImage = ({ mediaPickerModalProps, ...props }) => {
  const [
    showMediaPickerModal,
    setShowMediaPickerModal,
  ] = React.useState(false);

  const editor = useStoreEditorRef(useEventEditorId('focus'));

  const handleOnProcessFile = (error, asset) => {
    if (error || !editor) return;

    insertImage(editor, asset.url);
  };

  return (
    <React.Fragment>
      <ToolbarButton
        onMouseDown={async (event) => {
          if (!editor) return;

          event.preventDefault();

          setShowMediaPickerModal(true);
        }}
        {...props}
      />
      <MediaPickerModal
        open={showMediaPickerModal}
        onClose={() => setShowMediaPickerModal(false)}
        onProcessFile={handleOnProcessFile}
        {...merge({}, mediaPickerModalProps)}
      />
    </React.Fragment>
  );
};

ToolbarImage.propTypes = {
  mediaPickerModalProps: PropTypes.shape({
    parent: PropTypes.oneOf([
      'category',
      'product',
      'product-tab-content',
    ]).isRequired,
    parentId: PropTypes.string.isRequired,
    allowMultiple: PropTypes.bool,
    maxFiles: PropTypes.number,
    availableAssets: PropTypes.arrayOf(Asset),
  }).isRequired,
};

export default ToolbarImage;
