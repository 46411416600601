import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import Profile from './auth/profile/reducer';
import getAuthUserReducer from './auth/getAuthUser/reducer';
import roleReducer from './auth/GetRoleUser/reducer';
import complaintFormStatusReducer from './features/complaint/complaintFormReducer';

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  getAuthUser: getAuthUserReducer,
  role: roleReducer,
  complaintFormStatus: complaintFormStatusReducer,
});

export default rootReducer;
