import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { NullableTextField } from 'components/formFields';

const MacroFields = ({ name, requiredFields }) => {
  const makeFieldName = (nestedFieldName) =>
    `${name}.${nestedFieldName}`;

  const isRequired = (fieldName) =>
    requiredFields.includes(fieldName);

  return (
    <>
      <div className="d-flex flex-column">
        <h5>Macronutrients</h5>
        <Row>
          <Col>
            <NullableTextField
              name={makeFieldName('calorie')}
              label="Calories (kcal)"
              type="number"
              required={isRequired('calorie')}
            />
          </Col>
          <Col>
            <NullableTextField
              name={makeFieldName('protein')}
              label="Protein (g)"
              type="number"
              required={isRequired('protein')}
            />
          </Col>
          <Col>
            <NullableTextField
              name={makeFieldName('fat')}
              label="Fats (g)"
              type="number"
              required={isRequired('fat')}
            />
          </Col>
          <Col>
            <NullableTextField
              name={makeFieldName('carb')}
              label="Carbs (g)"
              type="number"
              required={isRequired('carb')}
            />
          </Col>
        </Row>
      </div>
      <hr />
      <div className="d-flex flex-column">
        <h5>Fats</h5>
        <Row>
          <Col>
            <NullableTextField
              name={makeFieldName('polyunsaturated_fat')}
              label="Polyunsaturated Fat (g)"
              type="number"
              required={isRequired('polyunsaturated_fat')}
            />
          </Col>
          <Col>
            <NullableTextField
              name={makeFieldName('monounsaturated_fat')}
              label="Monounsaturated Fat (g)"
              type="number"
              required={isRequired('monounsaturated_fat')}
            />
          </Col>
          <Col>
            <NullableTextField
              name={makeFieldName('saturated_fat')}
              label="Saturated Fat (g)"
              type="number"
              required={isRequired('saturated_fat')}
            />
          </Col>
          <Col>
            <NullableTextField
              name={makeFieldName('trans_fat')}
              label="Trans Fat (g)"
              type="number"
              required={isRequired('trans_fat')}
            />
          </Col>
        </Row>
      </div>
      <hr />
      <div className="d-flex flex-column">
        <h5>Carbohydrates</h5>
        <Row>
          <Col>
            <NullableTextField
              name={makeFieldName('dietary_fiber')}
              label="Dietary Fiber (g)"
              type="number"
              required={isRequired('dietary_fiber')}
            />
          </Col>
          <Col>
            <NullableTextField
              name={makeFieldName('sugar')}
              label="Sugar (g)"
              type="number"
              required={isRequired('sugar')}
            />
          </Col>
        </Row>
      </div>
      <hr />
      <div className="d-flex flex-column">
        <h5>Cholesterol</h5>
        <Row>
          <Col sm={6}>
            <NullableTextField
              name={makeFieldName('cholesterol')}
              label="Cholesterol (mg)"
              type="number"
              required={isRequired('cholesterol')}
            />
          </Col>
        </Row>
      </div>
      <hr />
      <div className="d-flex flex-column">
        <h5>Vitamins</h5>
        <Row>
          <Col>
            <NullableTextField
              name={makeFieldName('vitamin_a')}
              label="Vitamin A (mcg)"
              type="number"
              required={isRequired('vitamin_a')}
            />
          </Col>
          <Col>
            <NullableTextField
              name={makeFieldName('vitamin_c')}
              label="Vitamin C (mcg)"
              type="number"
              required={isRequired('vitamin_c')}
            />
          </Col>
        </Row>
      </div>
      <hr />
      <div className="d-flex flex-column">
        <h5>Minerals</h5>
        <Row>
          <Col>
            <NullableTextField
              name={makeFieldName('sodium')}
              label="Sodium (mg)"
              type="number"
              required={isRequired('sodium')}
            />
          </Col>
          <Col>
            <NullableTextField
              name={makeFieldName('calcium')}
              label="Calcium (mg)"
              type="number"
              required={isRequired('calcium')}
            />
          </Col>
          <Col>
            <NullableTextField
              name={makeFieldName('iron')}
              label="Iron (mg)"
              type="number"
              required={isRequired('iron')}
            />
          </Col>
          <Col>
            <NullableTextField
              name={makeFieldName('potassium')}
              label="Potassium (mg)"
              type="number"
              required={isRequired('potassium')}
            />
          </Col>
        </Row>
      </div>
      <hr />
    </>
  );
};

MacroFields.propTypes = {
  name: PropTypes.string.isRequired,
  requiredFields: PropTypes.arrayOf(PropTypes.string),
};

MacroFields.defaultProps = {
  requiredFields: [],
};

export default MacroFields;
