import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';

import { RadioGroupContext } from '../contexts/radioContexts';

const RadioButtonGroup = ({
  name,
  value: valueProp,
  onChange,
  children,
}) => {
  const [value, setValueState] = useState(valueProp);

  const handleChange = useCallback(
    (event) => {
      setValueState(event.target.value);

      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange],
  );

  const contextValue = useMemo(
    () => ({
      name,
      value,
      onChange: handleChange,
    }),
    [name, value, handleChange],
  );

  return (
    <RadioGroupContext.Provider value={contextValue}>
      <ButtonGroup toggle>{children}</ButtonGroup>
    </RadioGroupContext.Provider>
  );
};

RadioButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
};

RadioButtonGroup.defaultProps = {
  onChange: () => {},
};

export default RadioButtonGroup;
