import { SimpleTable } from 'components/Common';
import datetime, { formatDate, formatTime } from 'lib/datetime';
import { DELIVERY_TIMESLOT_DURATION_HOURS } from 'lib/deliveryDateTimes';
import React from 'react';

const DeliveryDateTimeLogs = ({ data }) => {
  const columns = [
    {
      dataField: 'number',
      text: '#',
      formatter: (_cell, _row, index) => (
        <div className="number">{index + 1}</div>
      ),
    },
    {
      dataField: 'old_delivery_datetime',
      text: 'Old Delivery Date & Time',
      formatter: (_cell, row) => {
        const oldDeliveryDateTime = row?.old?.delivery_datetime;

        return (
          <div className="old-delivery-datetime-log" style={{ minWidth: '230px' }}>
            <p>
              <span>{formatDate(oldDeliveryDateTime)}</span>
              &nbsp;
              <span>{formatTime(oldDeliveryDateTime)}</span> -
              <span>
                {formatTime(
                  datetime(oldDeliveryDateTime).add(
                    DELIVERY_TIMESLOT_DURATION_HOURS,
                    'hours',
                  ),
                )}
              </span>
            </p>
          </div>
        );
      },
    },
    {
      dataField: 'new_delivery_datetime',
      text: 'New Delivery Date & Time',
      formatter: (_cell, row) => {
        const newDeliveryDateTime = row?.new?.delivery_datetime;

        return (
          <div className="new-delivery-datetime-log" style={{ minWidth: '230px' }}>
            <p>
              <span>{formatDate(newDeliveryDateTime)}</span>
              &nbsp;
              <span>{formatTime(newDeliveryDateTime)}</span> -
              <span>
                {formatTime(
                  datetime(newDeliveryDateTime).add(
                    DELIVERY_TIMESLOT_DURATION_HOURS,
                    'hours',
                  ),
                )}
              </span>
            </p>
          </div>
        );
      },
    },
    {
      dataField: 'updated_at',
      text: 'Updated At',
      formatter: (_cell, row) => {
        const updatedAt = row?.updated_at;

        return (
          <div className="new-delivery-datetime-log" style={{ minWidth: '140px' }}>
            <p>
              <span>{formatDate(updatedAt)}</span>
              &nbsp;
              <span>{formatTime(updatedAt)}</span>
            </p>
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <SimpleTable
        data={data}
        columns={columns}
        classes="table-delivery-datetime-logs"
        noDataIndication={() => (
          <tr>
            <td>
              <p className="pl-2 pt-1 pb-1"> No Delivery Date & Time Logs </p>
            </td>
          </tr>
        )}
      />
    </React.Fragment>
  );
};

export default DeliveryDateTimeLogs;