import toastr from 'toastr';
import { merge } from 'lodash';
import 'toastr/build/toastr.min.css';

import defaultOptions from './defaultOptions';

export const configure = (options) => {
  const mergedOptions = merge({}, defaultOptions, options);

  toastr.options = mergedOptions;
};

export const info = (message, title, options) =>
  toastr.info(message, title, options);

export const warning = (message, title, options) =>
  toastr.warning(message, title, options);

export const error = (message, title, options) =>
  toastr.error(message, title, options);

export const success = (message, title, options) =>
  toastr.success(message, title, options);

export const clear = () => toastr.clear();
