import React from 'react';
import PropTypes from 'prop-types';

import yup from 'lib/validator';

import { merge } from 'lib/javascript';
import { FormManager, Form, GeneralError } from 'lib/form';
import { Button } from 'components/Common';
import { TextField } from 'components/formFields';

const defaultValues = {
  name: '',
};

const validationSchema = yup.object({
  name: yup.string().required(),
});

const CategoryForm = ({ initialValues, onSubmit, buttonText }) => {
  return (
    <React.Fragment>
      <FormManager
        initialValues={merge({}, defaultValues, initialValues)}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <GeneralError />
            <TextField name="name" label="Category Name" required />
            <Button type="submit" loading={isSubmitting}>
              {buttonText}
            </Button>
          </Form>
        )}
      </FormManager>
    </React.Fragment>
  );
};

CategoryForm.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  buttonText: PropTypes.string,
};

CategoryForm.defaultProps = {
  initialValues: defaultValues,
  onSubmit: () => {},
  buttonText: 'Submit',
};

export default CategoryForm;
