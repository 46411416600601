import { useCRUD } from 'lib/swr-crud';
import { getUserLoyaltyPoints } from './api';

const useUserLoyaltyPoints = (userId) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/users/${userId}/yb_reps`,
    async () => {
      const loyaltyPoints = await getUserLoyaltyPoints(userId);

      return loyaltyPoints;
    },
  );

  const loyaltyPoints = data || null;

  return {
    loyaltyPoints,
    error,
    mutate,
    isValidating,
    isLoading,
  };
};

export default useUserLoyaltyPoints;
