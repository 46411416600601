import * as routePaths from './routePaths';

const kitchenNavConfig = [
  {
    title: 'CRM',
    children: [
      {
        title: 'Feedback',
        to: routePaths.COMPLAINTS,
        icon: 'bx bx-sad',
      },
    ],
  },
];

export default kitchenNavConfig;
