import { useGet } from 'lib/swr-crud';
import { Log, LogsQueryParams } from './types';
import { APIErrorResponse, APIListResponse } from 'types/api';
import { AxiosError } from 'axios';
import { getLogs, getLogsUrl } from './api';

interface UseLogsOptions {
  url: string;
  params?: LogsQueryParams;
}

const useLogs = ({ url, params }: UseLogsOptions) => {
  const { data, isLoading, mutate } = useGet<
    APIListResponse<Log>,
    AxiosError<APIErrorResponse>
  >(getLogsUrl(url, params), async () => await getLogs(url, params));

  const total: number = data?.meta?.total ?? 0;

  const _logs =
    data?.data
      ?.filter((log) => log.nodes.length > 0)
      .map((log) => log) ?? [];

  return {
    logs: _logs,
    isLoading,
    total,
    mutate,
  };
};

export default useLogs;
