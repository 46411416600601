import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'components/Common';

import { formatCouponCode } from './utils';

const CouponCodeInput = ({
  name,
  type,
  className,
  label,
  helperText,
  value,
  onChange,
  onBlur,
  required,
  error,
  autoComplete,
  ...props
}) => {
  const handleOnChange = (ev) => {
    const value = ev.target.value;

    const formattedCouponCode = formatCouponCode(value);

    onChange(formattedCouponCode);
  };

  return (
    <TextInput
      name={name}
      type="text"
      className={className}
      label={label}
      helperText={helperText}
      value={formatCouponCode(value)}
      onChange={handleOnChange}
      onBlur={onBlur}
      required={required}
      error={error}
      autoComplete={autoComplete}
      {...props}
    />
  );
};

CouponCodeInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
};

CouponCodeInput.defaultProps = {
  type: 'text',
  className: null,
  label: null,
  helperText: null,
  value: null,
  onChange: undefined,
  onBlur: () => {},
  required: false,
  autoComplete: undefined,
};

export default CouponCodeInput;
