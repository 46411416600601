import { Table } from 'core/components';
import { Catalogue, CataloguesParams } from './types';
import { Link } from 'lib/router';
import { routePaths } from 'appConfig';
import { Datetime } from 'lib/datetime';
import { textFilter } from 'core/components/Table';
import PublishCatalogueButton from './PublishCatalogueButton';

interface CataloguesTableProps extends CataloguesParams {
  data: Array<Catalogue>;
  isLoading?: boolean;
  totalSize?: number;
  onTableChange?: (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => Promise<void>;
  mutateCatalogues?: (callback: (data: Catalogue[]) => Catalogue[]) => void;
}

const CataloguesTable = ({
  data,
  isLoading,
  totalSize,
  onTableChange,
  mutateCatalogues,
  page,
  sizePerPage,
  sortField,
  sortDir,
  query,
}: CataloguesTableProps) => {
  const mutateCatalogue = (
    catalogueId: number | string,
    newCatalogue: Catalogue,
  ) => {
    mutateCatalogues && mutateCatalogues((data: Array<Catalogue>) =>
      data.map((datum) => {
        if (datum.id !== catalogueId) return datum;

        return {
          ...datum,
          ...newCatalogue,
        };
      }),
    );
  };

  const columns = [
    {
      dataField: 'title',
      text: 'Title',
      formatter: (title: string, catalogue: Catalogue) => (
        <Link to={`${routePaths.CATALOGUES}/${catalogue.id}`}>{title}</Link>
      ),
      sort: true,
      filter: textFilter({ delay: 1500, defaultValue: query }),
    },
    {
      dataField: 'created_at',
      text: 'Date',
      formatter: (createdAt: string) => <Datetime value={createdAt} />,
      sort: true,
    },
    {
      dataField: 'total_items',
      text: 'Product',
    },
    {
      dataField: '',
      text: 'Actions',
      formatter: (_: unknown, catalogue: Catalogue) => (
        <PublishCatalogueButton
          catalogue={catalogue}
          mutateCatalogue={mutateCatalogue}
        />
      ),
    },
  ];

  return (
    <Table
      remote
      loading={isLoading}
      keyField="id"
      columns={columns}
      data={data}
      pagination={{ page, sizePerPage, totalSize }}
      sort={{ dataField: sortField, order: sortDir }}
      onTableChange={onTableChange}
    />
  );
};

export default CataloguesTable;
