import PropTypes from 'prop-types';

const propTypes = {
  keyField: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['string', 'number', 'bool', 'date']),
    }),
  ).isRequired,
  remote: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      filter: PropTypes.bool,
      pagination: PropTypes.bool,
      sort: PropTypes.bool,
      cellEdit: PropTypes.bool,
    }),
  ]),
  noDataIndication: PropTypes.func,
  loading: PropTypes.bool,
  caption: PropTypes.string,
  striped: PropTypes.bool,
  bordered: PropTypes.bool,
  hover: PropTypes.bool,
  condensed: PropTypes.bool,
  id: PropTypes.string,
  tabIndexCell: PropTypes.bool,
  classes: PropTypes.string,
  wrapperClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  headerWrapperClasses: PropTypes.string,
  bodyClasses: PropTypes.string,
  cellEdit: PropTypes.shape({
    mode: PropTypes.oneOf(['click', 'dbclick']).isRequired,
    blurToSave: PropTypes.bool,
    nonEditableRows: PropTypes.func,
    timeToCloseMessage: PropTypes.func,
    autoSelectText: PropTypes.bool,
    beforeSaveCell: PropTypes.func,
    afterSaveCell: PropTypes.func,
    onStartEdit: PropTypes.func,
    errorMessage: PropTypes.string,
    onErrorMessageDisappear: PropTypes.func,
  }),
  selectRow: PropTypes.shape({
    mode: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
    selected: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    classes: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    bgColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    nonSelectable: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
    nonSelectableStyle: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),
    nonSelectableClasses: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),
    clickToSelect: PropTypes.bool,
    clickToExpand: PropTypes.bool,
    clickToEdit: PropTypes.bool,
    onSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
    selectColumnPosition: PropTypes.oneOf(['left', 'right']),
    hideSelectColumn: PropTypes.bool,
    hideSelectAll: PropTypes.bool,
    selectionRenderer: PropTypes.func,
    selectionHeaderRenderer: PropTypes.func,
    headerColumnStyle: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),
    selectColumnStyle: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),
  }),
  rowStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  rowClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowEvents: PropTypes.object,
  hiddenRows: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  sort: PropTypes.shape({
    dataField: PropTypes.string,
    order: PropTypes.oneOf(['asc', 'desc']),
    sortCaret: PropTypes.func,
    sortFunc: PropTypes.func,
  }),
  defaultSorted: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string,
      order: PropTypes.oneOf(['asc', 'desc']),
    }),
  ),
  defaultSortDirection: PropTypes.oneOf(['asc', 'desc']),
  pagination: PropTypes.object,
  filter: PropTypes.object,
  filterPosition: PropTypes.oneOf(['inline', 'top', 'bottom']),
  onTableChange: PropTypes.func,
  onDataSizeChange: PropTypes.func,
};

export default propTypes;
