import { Badge } from 'reactstrap';
// @ts-ignore
import { isNumber, gt } from 'lib/javascript';

interface TotalComplaintCountBadgeProps {
  value: number;
  className?: string;
  color?: string;
  bgColor?: string;
}

const TotalComplaintCountBadge = ({
  value,
  className,
  color = '#f8f9fa',
  bgColor = '#495057',
}: TotalComplaintCountBadgeProps) => {
  if (isNumber(value) && gt(value, 0)) {
    return (
      <Badge
        className={className}
        style={{ backgroundColor: bgColor, color }}
      >
        <span title={`${value} feedback(s)`}>{value}</span>
      </Badge>
    );
  }
  return null;
};

export default TotalComplaintCountBadge;
