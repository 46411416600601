import React from 'react';

import { UserPropType } from './typedef';

const Email = ({ user }) => {
  return <React.Fragment>{user.email}</React.Fragment>;
};

Email.propTypes = {
  user: UserPropType.isRequired,
};

export default Email;
