import notification from 'lib/notification';
import { Order } from 'lib/orders';
import { displayName } from 'lib/utils';
import { memo, useCallback } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { storeComplaint } from './api';
import ComplaintForm from './ComplaintForm';
import ComplaintInformation from './ComplaintInformation';
import { ComplaintPayload } from './types';

interface ComplaintModalInterface {
  complaintModal: boolean;
  setComplaintModal: (value: boolean) => void;
  mutateOrder: () => void;
  orderData: Order;
}

const ComplaintModal = ({
  complaintModal,
  setComplaintModal,
  mutateOrder,
  orderData,
}: ComplaintModalInterface) => {
  const toggle = useCallback(() => {
    setComplaintModal(!complaintModal);
  }, [complaintModal, setComplaintModal]);

  const { first_name, last_name, completed_orders_count } =
    orderData?.user || {};

  const { order_number, delivery_datetime, id } = orderData || {};

  const customerName = displayName({
    firstName: first_name,
    lastName: last_name,
  });

  const onSubmit = async (
    payload: ComplaintPayload,
    attachments: File[] | null,
  ) => {
    try {
      await storeComplaint(payload, id!, attachments);
      mutateOrder();
      toggle();
      notification.success('Added new Feedback.');
    } catch (error) {
      notification.error(
        'Something went wrong while adding new Feedback.',
      );
    }
  };

  return (
    <Modal
      isOpen={complaintModal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-lg"
      tabindex="-1"
      toggle={() => {
        toggle();
      }}
      style={{
        zIndex: 1050,
      }}
    >
      <ModalHeader toggle={() => toggle()}>Feedback Form</ModalHeader>
      <ModalBody>
        <ComplaintInformation
          completeOrderCount={completed_orders_count}
          customerName={customerName}
          deliveryDatetime={delivery_datetime}
          orderNumber={order_number}
          orderId={id!}
          userId={orderData && orderData.user.user_id}
          email={orderData && orderData.user.email}
          user={orderData && orderData.user}
        />

        <ComplaintForm
          onSubmit={onSubmit}
          hideAttachments={true}
          showComplaintModalAttachments={true}
          mutate={mutateOrder}
        />
      </ModalBody>
    </Modal>
  );
};

export default memo(ComplaintModal);
