import { SimpleTable } from 'components/Common';
import { formatDate, formatTime } from 'lib/datetime';
import { getUserFullName } from 'lib/users';
import React from 'react';
import { CouponLogDiff } from '.';

/**
 * @typedef {import('./typedef').Log} Log
 */

/**
 * @typedef Props
 * @type Object
 *
 * @property {Log[]} logs
 */

/**
 * @param {Props} input
 */
const CouponLog = ({ logs }) => {
  const columns = [
    {
      dataField: 'number',
      text: '#',
      formatter: (_cell, _row, index) => (
        <div className="number">{index + 1}</div>
      ),
    },
    {
      dataField: 'description',
      text: 'Log Description',
      formatter: (_cell, row) => <div>{row.description}</div>,
    },
    {
      dataField: 'changelog',
      text: 'Changelog',
      classes: 'w-100',
      formatter: (_cell, row) => {
        return (
          <div style={{ minWidth: '600px' }}>
            <CouponLogDiff
              title="Coupon Code"
              type="NORMAL"
              oldLog={row?.properties?.old?.coupon_code}
              newLog={row?.properties?.new?.coupon_code}
              logStyle={{ textTransform: 'uppercase' }}
            />

            <CouponLogDiff
              title="Description"
              type="NORMAL"
              oldLog={row?.properties?.old?.description}
              newLog={row?.properties?.new?.description}
            />

            <CouponLogDiff
              title="Custom Error Message"
              type="NORMAL"
              oldLog={row?.properties?.old?.custom_error_message}
              newLog={row?.properties?.new?.custom_error_message}
            />

            <CouponLogDiff
              title="Product Rules Operator"
              type="RULE_OPERATOR"
              oldLog={row?.properties?.old?.rules?.product?.operator}
              newLog={row?.properties?.new?.rules?.product?.operator}
            />

            <CouponLogDiff
              title="Included Product Rules"
              type="OBJECT_LIST"
              oldLog={row?.properties?.old?.rules?.product?.included}
              newLog={row?.properties?.new?.rules?.product?.included}
            />

            <CouponLogDiff
              title="Excluded Product Rules"
              type="OBJECT_LIST"
              oldLog={row?.properties?.old?.rules?.product?.excluded}
              newLog={row?.properties?.new?.rules?.product?.excluded}
            />

            <CouponLogDiff
              title="Category Rules Operator"
              type="RULE_OPERATOR"
              oldLog={row?.properties?.old?.rules?.category?.operator}
              newLog={row?.properties?.new?.rules?.category?.operator}
            />

            <CouponLogDiff
              title="Included Category Rules"
              type="OBJECT_LIST"
              oldLog={row?.properties?.old?.rules?.category?.included}
              newLog={row?.properties?.new?.rules?.category?.included}
            />

            <CouponLogDiff
              title="Excluded Category Rules"
              type="OBJECT_LIST"
              oldLog={row?.properties?.old?.rules?.category?.excluded}
              newLog={row?.properties?.new?.rules?.category?.excluded}
            />

            <CouponLogDiff
              title="Allowed User"
              type="OBJECT_LIST"
              oldLog={row?.properties?.old?.rules?.allowed_users}
              newLog={row?.properties?.new?.rules?.allowed_users}
            />

            <CouponLogDiff
              title="Allow Discount With Excluded Items"
              type="BOOLEAN"
              oldLog={
                row?.properties?.old?.rules
                  ?.is_valid_with_excluded_item
              }
              newLog={
                row?.properties?.new?.rules
                  ?.is_valid_with_excluded_item
              }
            />

            <CouponLogDiff
              title="Minimum Order Subtotal"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.rules?.min_purchase_amount
              }
              newLog={
                row?.properties?.new?.rules?.min_purchase_amount
              }
            />

            <CouponLogDiff
              title="Maximum Order Subtotal"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.rules?.max_purchase_amount
              }
              newLog={
                row?.properties?.new?.rules?.max_purchase_amount
              }
            />

            <CouponLogDiff
              title="First Purchase Only"
              type="BOOLEAN"
              oldLog={
                row?.properties?.old?.rules?.is_first_order_only
              }
              newLog={
                row?.properties?.new?.rules?.is_first_order_only
              }
            />

            <CouponLogDiff
              title="Allow Free Shipping"
              type="BOOLEAN"
              oldLog={row?.properties?.old?.rules?.is_free_shipping}
              newLog={row?.properties?.new?.rules?.is_free_shipping}
            />

            <CouponLogDiff
              title="Maximum Total Usage"
              type="NULL_IS_INFINITY"
              oldLog={row?.properties?.old?.rules?.usage_limit}
              newLog={row?.properties?.new?.rules?.usage_limit}
            />

            <CouponLogDiff
              title="Max Usage Limit per Customer"
              type="NULL_IS_INFINITY"
              oldLog={
                row?.properties?.old?.rules?.usage_limit_per_user
              }
              newLog={
                row?.properties?.new?.rules?.usage_limit_per_user
              }
            />

            <CouponLogDiff
              title="Discount Type is Percentage"
              type="BOOLEAN"
              oldLog={row?.properties?.old?.is_percent}
              newLog={row?.properties?.new?.is_percent}
            />

            <CouponLogDiff
              title="Discount Amount"
              type="NORMAL"
              oldLog={row?.properties?.old?.discount_value}
              newLog={row?.properties?.new?.discount_value}
            />

            <CouponLogDiff
              title="Valid From"
              type="DATE_AS_STRING"
              oldLog={row?.properties?.old?.start_at}
              newLog={row?.properties?.new?.start_at}
            />

            <CouponLogDiff
              title="Valid Till"
              type="DATE_AS_STRING"
              oldLog={row?.properties?.old?.end_at}
              newLog={row?.properties?.new?.end_at}
            />
          </div>
        );
      },
    },
    {
      dataField: 'causer',
      text: 'Action By',
      formatter: (_cell, row) => (
        <div>
          {getUserFullName(row.causer)} &lt;{row.causer.email}&gt;
        </div>
      ),
    },
    {
      dataField: 'changed_at',
      text: 'Changed At',
      formatter: (_cell, row) => {
        const createdAt = row?.created_at;

        return (
          <div>
            <p>
              <span>{formatDate(createdAt)}</span>
              &nbsp;
              <span>{formatTime(createdAt)}</span>
            </p>
          </div>
        );
      },
    },
  ];

  return (
    <SimpleTable
      data={logs}
      columns={columns}
      noDataIndication={() => (
        <tr>
          <td>
            <p className="pl-2 pt-1 pb-1">No Logs</p>
          </td>
        </tr>
      )}
    />
  );
};

export default CouponLog;
