import { useListCRUD } from 'lib/swr-crud';
import httpClient, { getResponseData } from 'lib/httpClient';

const fetchers = {
  read: async (url, query) => {
    try {
      const response = await httpClient({
        method: 'get',
        url,
        query,
      });

      return getResponseData(response);
    } catch (error) {
      throw error;
    }
  },
};

const useUserLoyaltyPointsLogs = ({ userId } = {}) => {
  const url = `/cms/users/${userId}/yb_reps/logs`;

  const { data, error, isLoading, mutate } = useListCRUD(url, 'id', {
    fetchers,
  });

  const loyaltyPointsLogs = data?.data || [];
  const total = data?.meta?.total;

  const mutateLoyaltyPointsLogs = (updater) =>
    mutate((data) => ({
      ...data,
      data: updater(data.data),
    }));

  return {
    loyaltyPointsLogs,
    total,
    error,
    isLoading,
    mutateLoyaltyPointsLogs,
    mutate,
  };
};

export default useUserLoyaltyPointsLogs;
