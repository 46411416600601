import { useListCRUD } from 'lib/swr-crud';
import httpClient, {
  getErrorMessage,
  getResponseData,
} from 'lib/httpClient';
import { useMemo } from 'react';
import { normalize, schema } from 'normalizr';

/* ---------------------------------- utils --------------------------------- */

const ingredient = new schema.Entity('ingredients');

const addonOptionValue = new schema.Entity('addonOptionValues', {
  ingredient,
});

const addonOption = new schema.Entity('addonOptions', {
  values: [addonOptionValue],
});

const normalizeAddonOptions = (addonOptions) =>
  normalize(addonOptions || [], [addonOption]);

const fetchers = {
  read: async (url, query) => {
    try {
      const response = await httpClient({
        method: 'get',
        url,
        query,
      });

      return getResponseData(response);
    } catch (err) {
      throw getErrorMessage(err);
    }
  },
  update: async (url, data) => {
    try {
      const response = await httpClient({
        method: 'put',
        url,
        data,
      });

      return getResponseData(response);
    } catch (err) {
      throw getErrorMessage(err);
    }
  },
};

/* ---------------------------------- main ---------------------------------- */

const useProductVariantAddonOptions = (variantId) => {
  const url = `/cms/products/variants/${variantId}/addon_options`;

  const {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
    update,
  } = useListCRUD(url, '', {
    fetchers,
  });

  const addonOptions = useMemo(() => data?.data || [], [data]);

  const normalizedAddonOptions = useMemo(() => {
    return normalizeAddonOptions(addonOptions);
  }, [addonOptions]);

  const selectAddonOptionById = (addonOptionId) =>
    normalizedAddonOptions?.entities?.addonOptions?.[addonOptionId];

  const selectAddonOptionValueById = (addonOptionValueId) =>
    normalizedAddonOptions?.entities?.addonOptionValues?.[
      addonOptionValueId
    ];

  const selectIngredientById = (ingredientId) =>
    normalizedAddonOptions?.entities?.ingredients?.[ingredientId];

  return {
    addonOptions,
    normalizedAddonOptions,
    selectAddonOptionById,
    selectAddonOptionValueById,
    selectIngredientById,
    error,
    mutate,
    isValidating,
    isLoading,
    update,
  };
};

export default useProductVariantAddonOptions;
