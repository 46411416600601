import { routePaths } from 'appConfig';
import { Page } from 'components/Common';
import {
  CatalogueForm,
  CataloguePayload,
  postAddCatalogue,
} from 'lib/catalogues';
import notification from 'lib/notification';
import React from 'react';
import { useHistory } from 'react-router';
import { Card, CardBody, Col, Row } from 'reactstrap';

const NewCataloguePage = () => {
  const history = useHistory();

  const handleOnSubmit = async (body: CataloguePayload) => {
    try {
      const res = await postAddCatalogue(body);
      notification.success('Successfully create manual collection');
      history.push(`${routePaths.CATALOGUES}/${res.id}`);
    } catch (err) {
      const message = err?.message || err?.errors?.message;
      if (Boolean(message)) {
        notification.error(message);
      }
      throw err;
    }
  };

  return (
    <Page
      content={
        <React.Fragment>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CatalogueForm onSubmit={handleOnSubmit} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </React.Fragment>
      }
    />
  );
};

export default NewCataloguePage;
