import { SimpleTable } from 'components/Common';
import { formatDate, formatTime } from 'lib/datetime';
import { getUserFullName } from 'lib/users';
import React from 'react';
import { OrderLogDiff } from '.';

/**
 * @typedef {import('./typedef').Log} Log
 */

/**
 * @typedef Props
 * @type Object
 *
 * @property {Log[]} logs
 */

/**
 * @param {Props} input
 */
const OrderLog = ({ logs }) => {
  const columns = [
    {
      dataField: 'number',
      text: '#',
      formatter: (_cell, _row, index) => (
        <div className="number">{index + 1}</div>
      ),
    },
    {
      dataField: 'description',
      text: 'Log Description',
      formatter: (_cell, row) => <div>{row.description}</div>,
    },
    {
      dataField: 'changelog',
      text: 'Changelog',
      classes: 'w-50',
      formatter: (_cell, row) => {
        return (
          <div style={{ minWidth: '700px' }}>
            <OrderLogDiff
              title="Customer Information"
              type="STATIC_LOG"
              oldLog={row?.properties?.old?.user}
              newLog={row?.properties?.new?.user}
            />

            <OrderLogDiff
              title="Order Status"
              type="NORMAL"
              oldLog={row?.properties?.old?.status}
              newLog={row?.properties?.new?.status}
            />

            <OrderLogDiff
              title="Billing Address: First Name"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.addresses?.billing?.first_name
              }
              newLog={
                row?.properties?.new?.addresses?.billing?.first_name
              }
            />

            <OrderLogDiff
              title="Billing Address: Last Name"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.addresses?.billing?.last_name
              }
              newLog={
                row?.properties?.new?.addresses?.billing?.last_name
              }
            />

            <OrderLogDiff
              title="Billing Address: Phone Number"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.addresses?.billing?.phone_number
              }
              newLog={
                row?.properties?.new?.addresses?.billing?.phone_number
              }
            />

            <OrderLogDiff
              title="Billing Address: Postal Code"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.addresses?.billing?.postal_code
              }
              newLog={
                row?.properties?.new?.addresses?.billing?.postal_code
              }
            />

            <OrderLogDiff
              title="Billing Address: Address 1"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.addresses?.billing?.address_1
              }
              newLog={
                row?.properties?.new?.addresses?.billing?.address_1
              }
            />

            <OrderLogDiff
              title="Billing Address: Address 2"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.addresses?.billing?.address_2
              }
              newLog={
                row?.properties?.new?.addresses?.billing?.address_2
              }
            />

            <OrderLogDiff
              title="Recipient Address: First Name"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.addresses?.shipping?.first_name
              }
              newLog={
                row?.properties?.new?.addresses?.shipping?.first_name
              }
            />

            <OrderLogDiff
              title="Recipient Address: Last Name"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.addresses?.shipping?.last_name
              }
              newLog={
                row?.properties?.new?.addresses?.shipping?.last_name
              }
            />

            <OrderLogDiff
              title="Recipient Address: Phone Number"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.addresses?.shipping
                  ?.phone_number
              }
              newLog={
                row?.properties?.new?.addresses?.shipping
                  ?.phone_number
              }
            />

            <OrderLogDiff
              title="Recipient Address: Postal Code"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.addresses?.shipping?.postal_code
              }
              newLog={
                row?.properties?.new?.addresses?.shipping?.postal_code
              }
            />

            <OrderLogDiff
              title="Recipient Address: Address 1"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.addresses?.shipping?.address_1
              }
              newLog={
                row?.properties?.new?.addresses?.shipping?.address_1
              }
            />

            <OrderLogDiff
              title="Recipient Address: Address 2"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.addresses?.shipping?.address_2
              }
              newLog={
                row?.properties?.new?.addresses?.shipping?.address_2
              }
            />

            <OrderLogDiff
              title="Company Name"
              type="NORMAL"
              oldLog={
                row?.properties?.old?.addresses?.billing?.company
              }
              newLog={
                row?.properties?.new?.addresses?.billing?.company
              }
            />

            <OrderLogDiff
              title="Notes"
              type="NORMAL"
              oldLog={row?.properties?.old?.note}
              newLog={row?.properties?.new?.note}
            />

            <OrderLogDiff
              title="Requires Utensil"
              type="BOOLEAN"
              oldLog={row?.properties?.old?.with_utensil}
              newLog={row?.properties?.new?.with_utensil}
            />

            <OrderLogDiff
              title="Delivery Date Time"
              type="DELIVERY_SLOT"
              oldLog={row?.properties?.old?.delivery_slot}
              newLog={row?.properties?.new?.delivery_slot}
            />

            <OrderLogDiff
              title="Discount"
              type="STATIC_LOG"
              oldLog={row?.properties?.old?.discount}
              newLog={row?.properties?.new?.discount}
            />

            <OrderLogDiff
              title="Donation"
              type="STATIC_LOG"
              oldLog={row?.properties?.old?.donation}
              newLog={row?.properties?.new?.donation}
            />
          </div>
        );
      },
    },
    {
      dataField: 'causer',
      text: 'Action By',
      formatter: (_cell, row) => {
        const isCausedByUser = row?.causer !== null;

        const displayedInfo = isCausedByUser
          ? `${getUserFullName(row?.causer)} <${row.causer.email}>`
          : 'System';

        return <div>{displayedInfo}</div>;
      },
    },
    {
      dataField: 'changed_at',
      text: 'Changed At',
      formatter: (_cell, row) => {
        const createdAt = row?.created_at;

        return (
          <div>
            <p>
              <span>{formatDate(createdAt)}</span>
              &nbsp;
              <span>{formatTime(createdAt)}</span>
            </p>
          </div>
        );
      },
    },
  ];

  return (
    <SimpleTable
      data={logs}
      columns={columns}
      noDataIndication={() => (
        <tr>
          <td>
            <p className="pl-2 pt-1 pb-1">No Logs</p>
          </td>
        </tr>
      )}
    />
  );
};

export default OrderLog;
