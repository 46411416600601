import { reduce, set } from 'lodash';
import { merge } from 'lodash';

export * from 'lodash';
export { merge };

/**
 * Convert an object with string dot-notation key into nested object e.g. { 'a.b': 1 } -> { a: { b: 1 }}
 * @param {Object} obj object to be expanded into nested objects
 * @returns {Object} expanded object
 */
export const expand = (obj) => {
  return reduce(
    obj,
    (result, value, key) => {
      return set(result, key, value);
    },
    {},
  );
};
