import * as yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

const addMethods = () => {
  const PHONE = 'phone';
  const DECIMAL_NUMBER = 'decimalNumber';

  yup.addMethod(yup.string, PHONE, function testPhone(errorMessage) {
    const errMsg =
      typeof errorMessage === 'string' && errorMessage
        ? errorMessage
        : // eslint-disable-next-line no-template-curly-in-string
          'Must be a valid phone number.';

    return this.test(PHONE, errMsg, (value) => {
      try {
        return isValidPhoneNumber('+' + value);
      } catch (err) {
        return false;
      }
    });
  });

  yup.addMethod(
    yup.number,
    DECIMAL_NUMBER,
    function testDecimalNumber(optionalErrorMessage) {
      const errorMessage =
        typeof optionalErrorMessage === 'string' &&
        optionalErrorMessage
          ? optionalErrorMessage
          : 'Must be a valid number and has a maximum decimal number of 2.';

      return this.test(DECIMAL_NUMBER, errorMessage, (value) => {
        const decimalRegex = /^\d*(\.{1}\d{1,2})?$/;

        return decimalRegex.test(value);
      });
    },
  );
};

export default addMethods;
