import {
  ADDRESS_TYPE,
  COUNTRIES,
  REQUIRED_ADDRESS_FIELDS,
} from './constants';
// @ts-ignore-line
import { some, every, isEmpty, isEqual } from 'lib/javascript';
import {
  Address,
  AddressFormModel,
  AddressPayload,
  AddressType,
} from './types';

export const getAddressesByType = (
  type: AddressType,
  addresses: Address[],
): Address[] => addresses.filter((address) => address.type === type);

export const sortAddressesByDefault = (
  addresses: Address[],
): Address[] => {
  return addresses.sort((a, b) => {
    if (a.default === b.default) {
      return 0;
    }
    return a.default ? -1 : 1;
  });
};

export const findDefaultBillingAddress = (addresses = []) => {
  const defaultBillingAddress = addresses.find(
    (address) =>
      address.type === ADDRESS_TYPE.BILLING && address.default,
  );

  const copyOfDefaultBillingAddress = Object.assign(
    {},
    defaultBillingAddress,
  );

  return copyOfDefaultBillingAddress;
};

export const findDefaultShippingAddress = (addresses = []) => {
  const defaultShippingAddress = addresses.find(
    (address) =>
      address.type === ADDRESS_TYPE.SHIPPING && address.default,
  );

  const copyOfDefaultShippingAddress = Object.assign(
    {},
    defaultShippingAddress,
  );

  return copyOfDefaultShippingAddress;
};

export const formatAddress = (address: Address): string => {
  const {
    company_name,
    address_1,
    address_2,
    address_3,
    postal_code,
  } = address;

  const formattedAddress: (string | null | undefined)[] = [
    company_name,
    address_1,
    address_2,
    address_3,
    postal_code,
    COUNTRIES.SINGAPORE,
  ].filter((field) => !isEmpty(field));

  return formattedAddress.join('\n');
};

export const isIncompleteAddress = (address) =>
  !isEmptyAddress(address) &&
  some(REQUIRED_ADDRESS_FIELDS, (field) => isEmpty(address[field]));

export const isEmptyAddress = (address: Address) =>
  !address ||
  every(REQUIRED_ADDRESS_FIELDS, (field) => isEmpty(address[field]));

export const isBillingAddressAndShippingAddressSame = (
  billingAddress,
  shippingAddress,
) => {
  const {
    id: billingAddressId,
    type: billingAddressType,
    ...billingAddressWithoutIdAndType
  } = billingAddress || {};
  const {
    id: shippingAddressId,
    type: shippingAddressType,
    ...shippingAddressWithoutIdAndType
  } = shippingAddress || {};

  return isEqual(
    billingAddressWithoutIdAndType,
    shippingAddressWithoutIdAndType,
  );
};

export const hasAddressesBeenSetup = (addresses) => {
  const billingAddress = findDefaultBillingAddress(addresses);
  const shippingAddress = findDefaultShippingAddress(addresses);

  return (
    !isIncompleteAddress(billingAddress) &&
    !isIncompleteAddress(shippingAddress)
  );
};

export const generatePayload = (
  address: AddressFormModel,
): AddressPayload => {
  const {
    id,
    label,
    first_name,
    last_name,
    company_name,
    phone_number,
    postal_code,
    address_1,
    address_2,
    address_3,
    do_not_have_unit_number,
    delivery_notes,
    type,
  } = address;

  const _label = type === 'billing' ? null : label;

  const unitAndFloorNumber = do_not_have_unit_number
    ? null
    : address_3;

  return {
    id,
    label: _label,
    first_name,
    last_name,
    company_name,
    phone_number,
    postal_code,
    address_1,
    address_2,
    address_3: unitAndFloorNumber,
    delivery_notes,
    type,
  };
};
