import FullCalendar from '@fullcalendar/react';
import React, { useEffect, useState } from 'react';
import {} from 'react-bootstrap';
import BootstrapTheme from '@fullcalendar/bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid';
import OperationSwitch from './OperationSwitch';
import { Button, Spinner } from 'components/Common';
import datetime, { formatDatetime } from 'lib/datetime';
import {
  getDeliveryDates,
  getDeliveryTimesByDate,
  getCutOffDates,
  getCutOffTimesByDate,
  DELIVERY_TIMESLOT_DURATION_HOURS,
} from 'lib/deliveryDateTimes';
import { useConfirmation } from 'lib/confirm';
import useModifiedDatetimes from '../useModifiedDatetimes';
import {
  DELIVERY_SLOT_CONDITION,
  OPERATION_DATETIME_TYPE,
} from '../constants';
import OperationMover from './OperationMover';
import { useDeliverySlotsContext } from '../DeliverySlotsContext';

const OperationCalendar = () => {
  const calendarComponentRef = React.useRef();
  const [currentRange, setCurrentRange] = useState({
    startDate: datetime().startOf('month'),
    endDate: datetime().endOf('month'),
  });
  const {
    modifiedDatetimes,
    isLoading,
    createModifiedDatetime,
    deleteModifiedDatetime,
    timezone,
  } = useModifiedDatetimes(currentRange);
  const { getFirstCutOffTime } = useDeliverySlotsContext();

  const { confirm } = useConfirmation();

  const _updateCurrentRange = () => {
    let calendarApi = calendarComponentRef.current.getApi();
    const currentDate = calendarApi.getDate();
    const startOfMonth = datetime(currentDate).startOf('month');
    const endOfMonth = datetime(currentDate).endOf('month');

    setCurrentRange({ startDate: startOfMonth, endDate: endOfMonth });
  };

  const _getDeliverySlotIdByTimeSlot = (timeSlot) => {
    if (modifiedDatetimes) {
      const found = modifiedDatetimes.find((item) =>
        datetime(item.blocked_datetime).isSame(
          datetime(timeSlot).tz(timezone, true),
        ),
      );
      if (found) {
        return found.id;
      }
    }
    return null;
  };

  const getModifiedDataByTimeSlot = (timeSlot) => {
    if (!modifiedDatetimes) return null;

    const found = modifiedDatetimes.find((item) => {
      return datetime(item.blocked_datetime).isSame(
        datetime(timeSlot).tz(timezone, true),
      );
    });

    if (!found) return null;

    return found;
  };

  const _updateCurrentAvailableSlots = () => {
    const cutOffClassName =
      'bg-warning text-white border border-dark rounded';
    const deliveryClassName = 'bg-light border border-dark rounded';

    const { startDate, endDate } = currentRange;

    const deliveryTimeSlots = getDeliveryDates({
      start: startDate,
      end: endDate,
    }).map((date) => getDeliveryTimesByDate(date));

    const cutOffTimeSlots = getCutOffDates({
      start: startDate,
      end: endDate,
    }).map((date) => getCutOffTimesByDate(date));

    const _deliverySlots = deliveryTimeSlots
      .flat()
      .map((timeSlot, index) => {
        const { condition } =
          getModifiedDataByTimeSlot(timeSlot) || {};

        return {
          id: `delivery-time-slot-${formatDatetime(timeSlot)}`,
          title: `${timeSlot.format('ha')}-${timeSlot
            .add(DELIVERY_TIMESLOT_DURATION_HOURS, 'hours')
            .format('ha')}`,
          start: timeSlot.toDate(),
          className: deliveryClassName,
          payload: {
            type: OPERATION_DATETIME_TYPE.DELIVERY,
            condition,
            timeSlot,
          },
        };
      });

    const _cutOffSlots = cutOffTimeSlots
      .flat()
      .map((timeSlot, index) => {
        const { move_to, condition } =
          getModifiedDataByTimeSlot(timeSlot) || {};

        return {
          id: `cut-off-time-slot-${formatDatetime(timeSlot)}`,
          title: timeSlot.format('ha'),
          start: datetime(move_to || timeSlot).toDate(),
          className: cutOffClassName,
          payload: {
            type: OPERATION_DATETIME_TYPE.CUTOFF,
            condition,
            timeSlot,
          },
        };
      });

    //here to process blocked deliverySlot

    return [..._deliverySlots, ..._cutOffSlots];
  };

  const findDeliveryDatesUntilNextCutOff = ({ timeSlot }) => {
    // look up the next cut-off date until 30 days later
    // if there's a scenario where the next cut-off happens later than 30 days
    // increase the 30 days to an appropriate number accordingly
    const nextCutOffDate = getFirstCutOffTime({
      start: datetime(timeSlot).add(1, 'day'),
      end: datetime(timeSlot).add(30, 'days'),
    });

    const deliveryDatesUntilNextCutOff = getDeliveryDates({
      start: timeSlot,
      end: nextCutOffDate,
    });

    return deliveryDatesUntilNextCutOff;
  };

  const blockDeliveryDatesUntilNextCutOff = async ({ timeSlot }) => {
    const deliveryDates = findDeliveryDatesUntilNextCutOff({
      timeSlot,
    });

    const deliveryTimeSlots = deliveryDates.flatMap((date) =>
      getDeliveryTimesByDate(date),
    );

    return await Promise.all(
      deliveryTimeSlots.map((timeSlot) =>
        modifyOperationDatetime({
          type: OPERATION_DATETIME_TYPE.DELIVERY,
          timeSlot,
        }),
      ),
    );
  };

  const removeModifiedOperationDatetime = async ({ timeSlot }) => {
    const _deliverySlotId = _getDeliverySlotIdByTimeSlot(timeSlot);

    if (_deliverySlotId != null)
      return await deleteModifiedDatetime(_deliverySlotId);

    throw new Error(
      'Cannot remove a timeslot that is not modified yet',
    );
  };

  const modifyOperationDatetime = async ({
    type,
    timeSlot,
    moveTo,
  }) => {
    const modifiedData = getModifiedDataByTimeSlot(timeSlot);

    const isModifiedDataExist = Boolean(modifiedData);

    if (isModifiedDataExist)
      await removeModifiedOperationDatetime({ timeSlot });

    return await createModifiedDatetime({
      type,
      payload: timeSlot,
      moveTo,
    });
  };

  const _onToggleDeliverySlot = async (isOn, { type, timeSlot }) => {
    if (isOn)
      return await removeModifiedOperationDatetime({ timeSlot });

    if (type === OPERATION_DATETIME_TYPE.CUTOFF) {
      try {
        await confirm({
          title: 'Disable delivery Date-times?',
          content:
            'Do you also want to disable the next delivery date-times?',
          buttonLabels: {
            yes: 'Yes',
            no: 'No, I will disable them myself',
          },
        });
        await blockDeliveryDatesUntilNextCutOff({ timeSlot });
      } catch (err) {}
    }

    return await modifyOperationDatetime({ type, timeSlot });
  };

  const _onMoveOperationDatetime = (
    wantToMove,
    { type, timeSlot },
  ) => {
    const oneDayEarlier = datetime(timeSlot).subtract(1, 'day');

    if (wantToMove)
      return modifyOperationDatetime({
        type,
        timeSlot,
        moveTo: oneDayEarlier,
      });

    return removeModifiedOperationDatetime({ timeSlot });
  };

  const renderEventContent = ({ event }) => {
    const {
      timeSlot,
      type,
      condition,
    } = event?.extendedProps?.payload;

    const isBlocked = condition === DELIVERY_SLOT_CONDITION.BLOCKED;
    const isMoved = condition === DELIVERY_SLOT_CONDITION.MOVED;

    const canMove =
      type === OPERATION_DATETIME_TYPE.CUTOFF && !isBlocked;
    const canBlock = !isMoved;

    return (
      <>
        {canBlock && (
          <OperationSwitch
            value={!isBlocked}
            onToggle={(isOn) => {
              return _onToggleDeliverySlot(isOn, { type, timeSlot });
            }}
          />
        )}
        {canMove && (
          <OperationMover
            isMoved={isMoved}
            onMove={(wantToMove) =>
              _onMoveOperationDatetime(wantToMove, { type, timeSlot })
            }
          />
        )}
        <i>{event.title}</i>
      </>
    );
  };

  const _onToday = () => {
    calendarComponentRef.current.getApi().today();
    _updateCurrentRange();
  };

  const _onBackNav = () => {
    calendarComponentRef.current.getApi().prev();
    _updateCurrentRange();
  };
  const _onNextNav = () => {
    calendarComponentRef.current.getApi().next();
    _updateCurrentRange();
  };

  useEffect(() => {
    _updateCurrentRange();
  }, []);

  const _events = _updateCurrentAvailableSlots();

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div
          className="bg-warning border border-dark rounded"
          style={{
            width: '15px',
            height: '15px',
            marginRight: '5px',
          }}
        />
        <span>cut-off time</span>
        <div
          className="bg-light border border-dark rounded"
          style={{
            width: '15px',
            height: '15px',
            margin: '0px 5px',
          }}
        />
        <span>delivery slot time</span>
      </div>
      <div className="d-flex justify-content-between my-2 align-items-end">
        <h3>
          {currentRange.startDate
            ? datetime(currentRange.startDate).format('MMMM YYYY')
            : ''}
        </h3>
        <div>
          <Button className="mr-4" color="info" onClick={_onToday}>
            Today
          </Button>
          <Button className="mr-1" onClick={_onBackNav}>
            Back
          </Button>
          <Button onClick={_onNextNav}>Next</Button>
        </div>
      </div>
      <FullCalendar
        ref={calendarComponentRef}
        initialView="dayGridMonth"
        plugins={[BootstrapTheme, dayGridPlugin]}
        handleWindowResize={true}
        themeSystem="bootstrap"
        headerToolbar={null}
        events={_events}
        editable={false}
        droppable={false}
        eventLimit={true}
        selectable={false}
        dateClick={null}
        eventContent={renderEventContent}
        // drop={onDrop}
        id="calendar"
      />

      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.2)',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <Spinner size="lg" />
        </div>
      )}
    </>
  );
};

export default OperationCalendar;
