import React from 'react';
import PropTypes from 'prop-types';
import { BulletList } from 'react-content-loader';
import { Price } from 'core/components';
import { POINT_NAME } from 'lib/loyaltyProgram';
import { DISCOUNT_METHOD } from 'lib/discount';
import { AppliedCoupons, ApplyCouponCodeForm } from 'lib/coupons';

import {
  findUtensilOrderLine,
  getEarnedLoyaltyPoints,
  getEstimatedLoyaltyPointsToBeEarned,
  getRedeemedLoyaltyPoints,
  isUtensilNeeded,
  getAppliedDiscountMethod,
} from './utils';
import { OrderPropType } from './typedef';
import { applyCouponToOrder, removeCouponFromOrder } from './api';

const OrderSummary = ({ isLoading, order, mutateOrder }) => {
  const {
    sub_total_after_tax,
    delivery_total_after_tax,
    discount_total,
    tax_total,
    grand_total,
  } = order || {};

  const utensilOrderLine = order && findUtensilOrderLine(order);
  const needUtensils = order && isUtensilNeeded(order);
  const getUtensilPriceAfterTax = () =>
    utensilOrderLine?.line_total + utensilOrderLine?.tax_total;
  const productLinesTotal = needUtensils
    ? sub_total_after_tax - getUtensilPriceAfterTax()
    : sub_total_after_tax;

  const redeemedLoyaltyPoints = getRedeemedLoyaltyPoints(order);
  const earnedLoyaltyPoints = getEarnedLoyaltyPoints(order);
  const estimatedLoyaltyPointsToBeEarned = getEstimatedLoyaltyPointsToBeEarned(
    order,
  );

  const renderDiscountMethod = () => {
    switch (getAppliedDiscountMethod(order)) {
      case DISCOUNT_METHOD.COUPON:
        return (
          <AppliedCoupons
            discounts={order.discounts}
            onRemoveCoupon={async (couponCode) => {
              await removeCouponFromOrder(order.id, couponCode);
              return await mutateOrder();
            }}
          />
        );

      case DISCOUNT_METHOD.LOYALTY_POINTS:
        return `Redeemed ${redeemedLoyaltyPoints} YB Reps`;

      default:
        return null;
    }
  };

  const renderContent = () => (
    <table className="table table-summary">
      <tbody>
        <tr>
          <td>Earned {POINT_NAME}</td>
          <td>
            {earnedLoyaltyPoints
              ? earnedLoyaltyPoints
              : `${estimatedLoyaltyPointsToBeEarned} (estimated)`}
          </td>
        </tr>
        <tr className="summary-subtotal">
          <td>Subtotal:</td>
          <td>
            <Price value={productLinesTotal} />
          </td>
        </tr>
        <tr>
          <td>Delivery:</td>
          <td>
            <Price value={delivery_total_after_tax} />
          </td>
        </tr>
        {needUtensils && (
          <tr>
            <td>Utensil:</td>
            <td>
              <Price value={getUtensilPriceAfterTax()} />
            </td>
          </tr>
        )}
        {discount_total !== 0 ? (
          <React.Fragment>
            <tr>
              <td>Discount Method: </td>
              <td>{renderDiscountMethod()}</td>
            </tr>
            <tr>
              <td>Discount Amount:</td>
              <td>
                <Price value={-1 * discount_total} />
              </td>
            </tr>
          </React.Fragment>
        ) : (
          <tr>
            <td>
              <ApplyCouponCodeForm
                onSubmit={async ({ coupon_code }) => {
                  await applyCouponToOrder(order.id, coupon_code);
                  return await mutateOrder();
                }}
              />
            </td>
          </tr>
        )}
        <tr className="summary-total">
          <td>Total:</td>
          <td>
            <div>
              <Price value={grand_total} />
            </div>
            <div>
              <small>
                Includes <Price value={tax_total} /> GST
              </small>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <div className="card card-dashboard">
      <div className="card-body">
        {isLoading ? (
          <BulletList uniqueKey="order-summary-loading" />
        ) : (
          renderContent()
        )}
      </div>
    </div>
  );
};

OrderSummary.propTypes = {
  isLoading: PropTypes.bool,
  order: OrderPropType.isRequired,
  mutateOrder: PropTypes.func,
};

export default OrderSummary;
