import React from 'react';

import { routePaths } from 'appConfig';
import { Page } from 'components/Common';
import { SetupVariant } from 'lib/productVariants';
import { useRouter } from 'lib/router';
import { constants, useProduct } from 'lib/product';

const SetupVariantPage = () => {
  const router = useRouter();

  const { productId } = router.query;
  const { product } = useProduct(productId);

  return (
    <Page
      breadcrumbProps={{
        config: {
          product: {
            path: routePaths.PRODUCT_DETAILS,
            label: product?.name,
          },
        },
      }}
      content={
        <SetupVariant
          productId={productId}
          onSubmit={(error) => {
            if (error) return;

            router.push({
              pathname: routePaths.PRODUCT_DETAILS,
              urlParams: { productId },
              queryParams: { tab: constants.TAB_IDS.VARIANTS },
            });
          }}
        />
      }
    />
  );
};

SetupVariantPage.propTypes = {};

export default SetupVariantPage;
