import jwtDecode from 'jwt-decode';

import webStorage from './webStorage';

export const STORAGE_ITEM_KEY = 'jwt';

const jwtClient = () => {
  let storage = webStorage.local;

  const setStorage = (newStorage) => {
    storage = newStorage;
  };

  const getToken = async () => {
    if (!storage.getItem)
      throw new Error('Unable to read from current storage');

    return await storage.getItem(STORAGE_ITEM_KEY);
  };

  const setToken = (token) => {
    if (!storage.setItem)
      throw new Error('Unable to write to current storage');

    return storage.setItem(STORAGE_ITEM_KEY, token);
  };

  const removeToken = (token) => {
    if (!storage.removeItem)
      throw new Error('Unable to remove item from current storage');

    return storage.removeItem(STORAGE_ITEM_KEY, token);
  };

  const decodeToken = async () => {
    const token = await getToken();

    if (!token) return;

    const decoded = jwtDecode(token);

    return decoded;
  };

  const getTokenExpIn = async () => {
    const decodedToken = await decodeToken();

    if (!decodedToken) return;

    const { exp } = decodedToken;

    return exp;
  };

  return {
    setStorage,
    getToken,
    setToken,
    removeToken,
    decodeToken,
    getTokenExpIn,
  };
};

export default jwtClient();
