import cn from 'classnames';
import Icon from '../Icon';
import { SortableHandle } from 'react-sortable-hoc';

interface DragHandlerProps {
  icon?: string;
  className?: string;
}

const DragHandler = SortableHandle(
  ({ icon = 'menu', className }: DragHandlerProps) => (
    <div
      className={cn(
        'd-flex align-items-center p-2 text-primary',
        className,
      )}
    >
      <span style={{ cursor: 'grab' }}>
        <Icon name={icon} />
      </span>
    </div>
  ),
);

export default DragHandler;
