import {
  GET_ROLE_USER,
  GET_ROLE_USER_SUCCESS,
  GET_ROLE_USER_ERROR,
} from './actionTypes';

export const getRoleUser = () => ({
  type: GET_ROLE_USER,
});

export const getRoleUserSuccess = (user) => ({
  type: GET_ROLE_USER_SUCCESS,
  payload: user,
});

export const getRoleUserError = (error) => ({
  type: GET_ROLE_USER_ERROR,
  payload: error,
});
