import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Common';
import { FormManager, Form } from 'lib/form';
import validator from 'lib/validator';
import { SelectField } from 'components/formFields';

import { cancelOrder } from './api';
import { CANCEL_REASON, LABEL_BY_CANCEL_REASON } from './constants';
import useOrder from './useOrder';
import notification from 'lib/notification';

const cancelReasonOptions = Object.values(CANCEL_REASON).map(
  (cancelReason) => ({
    value: cancelReason,
    label: LABEL_BY_CANCEL_REASON[cancelReason],
  }),
);

const CancelOrderForm = ({ orderId, onSubmit }) => {
  const { mutate } = useOrder(orderId);

  const initialValues = {
    cancel_reason: CANCEL_REASON.REQUESTED_BY_CUSTOMER,
  };

  const validationSchema = validator.object({
    cancel_reason: validator.string().required(),
  });

  const handleOnSubmit = async (values) => {
    try {
      const { cancel_reason } = values;

      const response = await cancelOrder(orderId, { cancel_reason });

      const canceledOrder = response.data;

      mutate(canceledOrder);

      onSubmit(null, canceledOrder);

      notification.success('Successfully canceled order');
    } catch (err) {
      notification.error('Something went wrong when canceling order');
    }
  };

  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <SelectField
            name="cancel_reason"
            label="Reason"
            required
            options={cancelReasonOptions}
          />

          <Button type="submit" loading={isSubmitting}>
            Cancel
          </Button>
        </Form>
      )}
    </FormManager>
  );
};

CancelOrderForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

CancelOrderForm.defaultProps = {
  onSubmit: () => {},
};

export default CancelOrderForm;
