import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { connectInput, Form, FormManager } from 'lib/form';
import yup from 'lib/validator';
import {
  CheckboxField,
  SelectField,
  TextField,
  PhoneNumberField,
} from 'components/formFields';
import {
  Button,
  DatePicker,
  CheckboxGroup,
  CheckboxInput,
} from 'components/Common';
import datetime from 'lib/datetime';
import notification from 'lib/notification';
import { USER_GOAL } from './constants';
import RoleSelect from './RoleSelect';

const DatePickerField = connectInput(DatePicker, {
  onChangeArgType: 'raw-value',
});

const RoleSelectField = connectInput(RoleSelect);

const validationSchema = yup.object({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email().required(),
  gender: yup.string().nullable(),
  role: yup.string().required(),
  birthday: yup.date().nullable(),
  goals: yup.array().of(yup.string()),
  is_subscribe_to_mailchimp: yup.bool(),
  phone_number: yup.string().phone().nullable().required('Required'),
});

const UserDetails = ({ user, update }) => {
  const onUpdateUser = async (values, actions) => {
    try {
      await update(values);
      notification.success('Successfully update User Details');
    } catch (error) {
      actions.setErrors(error.errors);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Card>
      <CardBody>
        <FormManager
          initialValues={{
            gender: 'male',
            ...user,
          }}
          validationSchema={validationSchema}
          onSubmit={onUpdateUser}
        >
          {({ isSubmitting, values, setFieldValue }) => {
            return (
              <Form>
                <Row>
                  <Col md={12} lg={6}>
                    <TextField
                      name={'first_name'}
                      label="First Name"
                      required
                    />
                  </Col>

                  <Col md={12} lg={6}>
                    <TextField
                      name={'last_name'}
                      label="Last Name"
                      required
                    />
                  </Col>

                  <Col md={12} lg={6}>
                    <TextField
                      disabled
                      name={'email'}
                      label="Email"
                      required
                    />
                  </Col>
                  <Col md={12} lg={6}>
                    <PhoneNumberField
                      name={'phone_number'}
                      label="Phone Number"
                      required
                    />
                  </Col>

                  <Col md={12}>
                    <RoleSelectField name="role" label="Role" />
                  </Col>

                  <Col md={12} lg={6}>
                    <SelectField
                      name={'gender'}
                      label="Gender"
                      options={[
                        {
                          label: 'Please select an option',
                          value: null,
                        },
                        { label: 'Male', value: 'male' },
                        { label: 'Female', value: 'female' },
                      ]}
                    />
                  </Col>

                  <Col md={12} lg={6}>
                    <DatePickerField
                      name={'birthday'}
                      label="Birthday"
                      outputFormat={(output) =>
                        datetime(output).format('YYYY-MM-DD')
                      }
                      toMonth={new Date()}
                      disabledDays={{ after: new Date() }}
                    />
                  </Col>
                  <Col md={12} lg={6}>
                    <CheckboxGroup
                      name="goals"
                      label="Goal"
                      value={values?.goals}
                      onChange={(value) => {
                        setFieldValue('goals', value);
                      }}
                    >
                      {Object.values(USER_GOAL).map((goal) => (
                        <CheckboxInput
                          key={goal}
                          label={goal}
                          value={goal}
                        />
                      ))}
                    </CheckboxGroup>
                  </Col>

                  <Col md={12} lg={6}>
                    <CheckboxField
                      type="checkbox"
                      name="is_subscribe_to_mailchimp"
                      label="Subscribe to MailChimp"
                    />
                  </Col>

                  <Col xs={12}>
                    <Button type="submit" loading={isSubmitting}>
                      Update User Details
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </FormManager>
      </CardBody>
    </Card>
  );
};

export default UserDetails;
