import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { Button } from 'components/Common';
import { FormManager, Form, connectInput } from 'lib/form';
import validator from 'lib/validator';
import CouponCodeInput from '../CouponCodeInput';

const initialValues = {
  coupon_code: '',
};

const validationSchema = validator.object({
  coupon_code: validator.string(),
});

const CouponCodeField = connectInput(CouponCodeInput, {
  onChangeArgType: 'raw-value',
});

const ApplyCouponCodeForm = ({ onSubmit }) => {
  const handleOnSubmit = async ({ coupon_code }, actions) => {
    try {
      await onSubmit({ coupon_code });
      actions.setStatus({ error: null });
      actions.setErrors(null);
      return;
    } catch (err) {
      actions.setErrors({ coupon_code: err.errors.message });
      return;
    }
  };

  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Row noGutters>
            <Col xs="auto">
              <CouponCodeField
                name="coupon_code"
                placeholder="Enter Coupon Code"
              />
            </Col>
            <Col xs="auto">
              <Button
                type="submit"
                style={{ minWidth: 'unset' }}
                loading={isSubmitting}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </FormManager>
  );
};

ApplyCouponCodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ApplyCouponCodeForm;
