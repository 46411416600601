import React from 'react';
import { Card } from 'react-bootstrap';

import { Page, Tabs, Tab } from 'components/Common';
import { useProduct, constants } from 'lib/product';
import {
  UpdateVariantForm,
  useProductVariant,
  utils,
} from 'lib/productVariants';
import { useRouter } from 'lib/router';
import { ProductVariantNutritionForm } from 'lib/productVariantNutrition';
import { routePaths } from 'appConfig';

const TAB = {
  DETAIL: 'detail',
  NUTRITION: 'nutrition',
};

const VariantPage = () => {
  const { query, updateQueryParams } = useRouter();

  const { productId, variantId, tab } = query;

  const { product } = useProduct(productId);

  const {
    productVariant,
    mutate: mutateProductVariant,
  } = useProductVariant(variantId);

  if (!product || !productVariant) return null;

  return (
    <Page
      breadcrumbProps={{
        config: {
          product: {
            path: routePaths.PRODUCT_DETAILS,
            label: product.name,
          },
          'product-variants': {
            path: `${routePaths.PRODUCT_DETAILS}/${constants.TAB_IDS.VARIANTS}`,
            as: `${routePaths.PRODUCT_DETAILS}?tab=${constants.TAB_IDS.VARIANTS}`,
            label: 'Variants',
          },
          'product-variant': {
            path: routePaths.PRODUCT_VARIANT,
            label: utils.makeProductVariantLabel(
              productVariant,
              product.options,
            ),
          },
        },
      }}
      content={
        <Card>
          <Card.Body>
            <Tabs
              activeKey={tab}
              onSelect={(tab) => updateQueryParams({ tab })}
            >
              <Tab eventKey={TAB.DETAIL} title="Detail">
                <div style={{ margin: '24px 0' }}>
                  <UpdateVariantForm variantId={variantId} />
                </div>
              </Tab>
              <Tab eventKey={TAB.NUTRITION} title="Nutritional Value">
                <div style={{ margin: '24px 0' }}>
                  <ProductVariantNutritionForm
                    productVariantId={variantId}
                    initialValues={productVariant?.nutrition}
                    onSubmit={(err) => !err && mutateProductVariant()}
                  />
                </div>
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      }
    />
  );
};

VariantPage.propTypes = {};

export default VariantPage;
