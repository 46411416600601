import React from 'react';
import { Spinner as RBSpinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Spinner = ({ animation, role, size, variant, as }) => {
  return (
    <RBSpinner
      animation={animation}
      role={role}
      size={size}
      variant={variant}
      as={as}
    />
  );
};

Spinner.propTypes = {
  animation: PropTypes.oneOf(['border', 'grow']),
  role: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  as: PropTypes.string,
};

Spinner.defaultProps = {
  animation: 'border',
  role: 'status',
};

export default Spinner;
