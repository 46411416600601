import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage as FormikErrorMessage } from 'formik';

const ErrorMessage = ({ name }) => {
  return (
    <FormikErrorMessage
      name={name}
      render={(message) => <p style={{ color: 'red' }}>{message}</p>}
    />
  );
};

ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ErrorMessage;
