import jwtClient, { STORAGE_ITEM_KEY } from './jwtClient';
import webStorage, {
  REMOVE_ITEM_FROM_SYNCED_SESSION_STORAGE,
} from './webStorage';
import { refreshJwtToken } from 'services/api';

const REFRESHING_SESSION = 'REFRESHING_SESSION';
const TRUE = 'true';
const FALSE = 'false';

const sessionManager = () => {
  let refreshTimeoutId = null;

  const getIsRefreshingSession = () =>
    webStorage.local.getItem(REFRESHING_SESSION) === TRUE;

  const setIsRefreshingSession = (isRefreshingSession) =>
    webStorage.local.setItem(
      REFRESHING_SESSION,
      isRefreshingSession ? TRUE : FALSE,
    );

  setIsRefreshingSession(false);

  const setRememberMe = (newRememberMe) => {
    if (!newRememberMe) {
      jwtClient.setStorage(webStorage.syncedSession);
    } else {
      jwtClient.setStorage(webStorage.local);
    }
  };

  const getRememberMe = async () => {
    jwtClient.setStorage(webStorage.local);
    const token = await jwtClient.getToken();

    if (!token) {
      jwtClient.setStorage(webStorage.syncedSession);
      return false;
    }

    return true;
  };

  const getRefreshedToken = async () => {
    const isRefreshingSession = getIsRefreshingSession();

    if (isRefreshingSession) {
      refreshSession();
      return; // do not refresh token if other tab is currently refreshing session
    }

    setIsRefreshingSession(true);

    const { response, error } = await refreshJwtToken();

    if (error) {
      endSession();
    } else {
      startSession(response.access_token);
    }

    setIsRefreshingSession(false);
  };

  const getRefreshTime = async () => {
    const tokenExpIn = await jwtClient.getTokenExpIn();

    const currentTimestamp = Date.now() / 1000;

    const threshold = 5;

    const refreshTime = tokenExpIn - currentTimestamp - threshold;

    if (refreshTime > 0) return refreshTime * 1000;

    return threshold * 1000;
  };

  const refreshSession = async () => {
    const refreshTime = await getRefreshTime();

    refreshTimeoutId = window.setTimeout(
      getRefreshedToken,
      refreshTime,
    );
  };

  const abortRefreshSession = () => {
    if (refreshTimeoutId) {
      window.clearTimeout(refreshTimeoutId);
      refreshTimeoutId = null;
    }
  };

  const validateSession = async () => {
    let token = await jwtClient.getToken();

    if (!token) {
      jwtClient.setStorage(webStorage.syncedSession);
      token = await jwtClient.getToken();
    }

    return Boolean(token);
  };

  const hasActiveSession = () => Boolean(refreshTimeoutId);

  const startSession = (token) => {
    jwtClient.setToken(token);
  };

  const endSession = () => {
    jwtClient.removeToken();
  };

  const isLogoutFromOtherTab = (storageEvent) => {
    const rememberMeLogout =
      storageEvent.key === STORAGE_ITEM_KEY &&
      storageEvent.newValue === null;
    const notRememberMeLogout =
      storageEvent.key === REMOVE_ITEM_FROM_SYNCED_SESSION_STORAGE;

    const logoutFromOtherTab =
      rememberMeLogout || notRememberMeLogout;

    return logoutFromOtherTab;
  };

  return {
    setRememberMe,
    getRememberMe,
    getIsRefreshingSession,
    setIsRefreshingSession,
    getRefreshedToken,
    abortRefreshSession,
    refreshSession,
    validateSession,
    hasActiveSession,
    startSession,
    endSession,
    isLogoutFromOtherTab,
  };
};

export default sessionManager();
