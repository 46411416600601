import React from 'react';

import { UserPropType } from './typedef';

const PhoneNumber = ({ user }) => {
  return (
    <React.Fragment>{user?.phone_number || 'N/A'}</React.Fragment>
  );
};

PhoneNumber.propTypes = {
  user: UserPropType.isRequired,
};

export default PhoneNumber;
