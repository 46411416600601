import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Common';
import yup from 'lib/validator';
import { FormManager, Form, connectInput } from 'lib/form';
import { ProductAutoComplete } from 'lib/product';
import {
  ProductVariantPicker,
  ProductVariantQuantityInput,
} from 'lib/productVariants';
import {
  ProductVariantAddonSelectGroup,
  getSelectedAddonOptionValueIds,
} from 'lib/productAddons';

const ProductAutoCompleteField = connectInput(ProductAutoComplete, {
  onChangeArgType: 'raw-value',
});
const ProductVariantPickerField = connectInput(ProductVariantPicker, {
  onChangeArgType: 'raw-value',
});
const ProductVariantQuantityField = connectInput(
  ProductVariantQuantityInput,
  {
    onChangeArgType: 'raw-value',
  },
);
const ProductVariantAddonSelectGroupField = connectInput(
  ProductVariantAddonSelectGroup,
  {
    onChangeArgType: 'raw-value',
  },
);

const AddOrderLineForm = ({ onSubmit }) => {
  const [selectedProduct, setSelectedProduct] = React.useState(null);

  const handleOnSubmit = (formValues) => {
    const { product_variant, quantity, addons } = formValues;

    const orderLineToAdd = {
      product_variant_id: product_variant.id,
      quantity,
      addon_option_value_ids: getSelectedAddonOptionValueIds(addons),
    };

    return onSubmit(orderLineToAdd);
  };

  return (
    <FormManager
      initialValues={{
        product: '',
        product_variant: '',
        quantity: 1,
        addons: {},
      }}
      validationSchema={yup.object({
        product: yup.string().required(),
        product_variant: yup
          .object({ id: yup.string().required() })
          .required(),
        quantity: yup.number().required(),
        addons: yup.object().nullable(),
      })}
      onSubmit={handleOnSubmit}
    >
      {({ setFieldValue, values, isSubmitting }) => (
        <Form>
          <ProductAutoCompleteField
            name="product"
            required
            label="Product"
            onChange={(_selectedOptionValue, selectedOption) => {
              setSelectedProduct(selectedOption);
              setFieldValue(
                'product_variant',
                selectedOption.variants[0],
              );
              setFieldValue('addons', {}); // reset addons field
            }}
          />
          <ProductVariantPickerField
            name="product_variant"
            required
            label="Product Variant"
            productOptions={selectedProduct?.options}
            productVariants={selectedProduct?.variants}
          />
          <ProductVariantQuantityField
            name="quantity"
            required
            label="Quantity"
            productVariant={values['product_variant']}
          />
          <ProductVariantAddonSelectGroupField
            name="addons"
            required
            productVariantId={values['product_variant']?.id}
          />
          <Button loading={isSubmitting} type="submit">
            Add
          </Button>
        </Form>
      )}
    </FormManager>
  );
};

AddOrderLineForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

AddOrderLineForm.defaultProps = {
  onSubmit: () => {},
};

export default AddOrderLineForm;
