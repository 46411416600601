import React from 'react';
import PropTypes from 'prop-types';
import {
  SlatePlugins,
  getSlatePluginType,
  useStoreEditorState,
  useEventEditorId,
  someNode,
  createReactPlugin,
  createHistoryPlugin,
  createParagraphPlugin,
  createHeadingPlugin,
  createBoldPlugin,
  createItalicPlugin,
  createUnderlinePlugin,
  createStrikethroughPlugin,
  createSlatePluginsComponents,
  createSlatePluginsOptions,
  createEditorPlugins,
  // toolbar
  HeadingToolbar,
  ToolbarElement,
  ToolbarMark,
  ToolbarList,
  ToolbarTable,
  ToolbarLink,
  // list
  createListPlugin,
  // link
  createLinkPlugin,
  // image
  createImagePlugin,
  // media embed
  createMediaEmbedPlugin,
  // table
  createTablePlugin,
  insertTable,
  deleteTable,
  addColumn,
  deleteColumn,
  addRow,
  deleteRow,
  // key modifiers
  createSoftBreakPlugin,
  createExitBreakPlugin,
  createResetNodePlugin,
  createSelectOnBackspacePlugin,
  // constants
  KEYS_HEADING,
  ELEMENT_CODE_BLOCK,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_TD,
  ELEMENT_TABLE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_UL,
  ELEMENT_OL,
  ELEMENT_TODO_LI,
  ELEMENT_PARAGRAPH,
  ELEMENT_IMAGE,
  ELEMENT_MEDIA_EMBED,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MARK_STRIKETHROUGH,
  // utils
  isBlockAboveEmpty,
  isSelectionAtBlockStart,
  // serializers
  serializeHTMLFromNodes,
  // deserializers
  deserializeHTMLToDocumentFragment,
} from '@udecode/slate-plugins';
import {
  AiOutlineTable,
  AiOutlineInsertRowRight,
  AiOutlineInsertRowBelow,
  AiOutlineDeleteColumn,
  AiOutlineDeleteRow,
} from 'react-icons/ai';
import { CgExtensionRemove } from 'react-icons/cg';
import {
  MdLooksOne,
  MdLooksTwo,
  MdLooks3,
  MdLooks4,
  MdLooks5,
  MdLooks6,
  MdFormatBold,
  MdFormatItalic,
  MdFormatUnderlined,
  MdFormatStrikethrough,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdLink,
  MdImage,
} from 'react-icons/md';
import { ImEmbed2 } from 'react-icons/im';
import { Container, Row } from 'react-bootstrap';

import ToolbarImage from './ToolbarImage';

const exitBreakPluginOptions = {
  rules: [
    {
      hotkey: 'mod+enter',
    },
    {
      hotkey: 'mod+shift+enter',
      before: true,
    },
    {
      hotkey: 'enter',
      query: {
        start: true,
        end: true,
        allow: KEYS_HEADING,
      },
    },
  ],
};

const softBreakPluginOptions = {
  rules: [
    { hotkey: 'shift+enter' },
    {
      hotkey: 'enter',
      query: {
        allow: [ELEMENT_CODE_BLOCK, ELEMENT_BLOCKQUOTE, ELEMENT_TD],
      },
    },
  ],
};

const resetBlockTypesCommonRule = {
  types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI],
  defaultType: ELEMENT_PARAGRAPH,
};

const resetBlockTypePluginOptions = {
  rules: [
    {
      ...resetBlockTypesCommonRule,
      hotkey: 'Enter',
      predicate: isBlockAboveEmpty,
    },
    {
      ...resetBlockTypesCommonRule,
      hotkey: 'Backspace',
      predicate: isSelectionAtBlockStart,
    },
  ],
};

const plugins = [
  createReactPlugin(),
  createHistoryPlugin(),
  // elements
  createParagraphPlugin(), // paragraph element
  createHeadingPlugin(), // heading elements

  // marks
  createBoldPlugin(), // bold mark
  createItalicPlugin(), // italic mark
  createUnderlinePlugin(), // underline mark
  createStrikethroughPlugin(), // strikethrough mark

  // lists
  createListPlugin(),

  // link
  createLinkPlugin(),

  // image
  createImagePlugin(),

  // media embed
  createMediaEmbedPlugin(),

  // table
  createTablePlugin(),

  // key modifiers
  createSoftBreakPlugin(softBreakPluginOptions),
  createExitBreakPlugin(exitBreakPluginOptions),
  createResetNodePlugin(resetBlockTypePluginOptions),
  createSelectOnBackspacePlugin({
    allow: [ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED],
  }),
];

const components = createSlatePluginsComponents();

const options = createSlatePluginsOptions();

const HeadingTools = () => {
  const editor = useStoreEditorState(useEventEditorId('focus'));

  const tools = [
    {
      icon: <MdLooksOne />,
      pluginKey: ELEMENT_H1,
    },
    {
      icon: <MdLooksTwo />,
      pluginKey: ELEMENT_H2,
    },
    {
      icon: <MdLooks3 />,
      pluginKey: ELEMENT_H3,
    },
    {
      icon: <MdLooks4 />,
      pluginKey: ELEMENT_H4,
    },
    {
      icon: <MdLooks5 />,
      pluginKey: ELEMENT_H5,
    },
    {
      icon: <MdLooks6 />,
      pluginKey: ELEMENT_H6,
    },
  ];

  return (
    <React.Fragment>
      {tools.map((tool) => (
        <ToolbarElement
          key={tool.pluginKey}
          icon={tool.icon}
          type={getSlatePluginType(editor, tool.pluginKey)}
        />
      ))}
    </React.Fragment>
  );
};

const BasicMarkTools = () => {
  const editor = useStoreEditorState(useEventEditorId('focus'));
  return (
    <React.Fragment>
      <ToolbarMark
        type={getSlatePluginType(editor, MARK_BOLD)}
        icon={<MdFormatBold />}
      />
      <ToolbarMark
        type={getSlatePluginType(editor, MARK_ITALIC)}
        icon={<MdFormatItalic />}
      />
      <ToolbarMark
        type={getSlatePluginType(editor, MARK_UNDERLINE)}
        icon={<MdFormatUnderlined />}
      />
      <ToolbarMark
        type={getSlatePluginType(editor, MARK_STRIKETHROUGH)}
        icon={<MdFormatStrikethrough />}
      />
    </React.Fragment>
  );
};

const ListTools = () => {
  const editor = useStoreEditorState(useEventEditorId('focus'));
  return (
    <React.Fragment>
      <ToolbarList
        type={getSlatePluginType(editor, ELEMENT_UL)}
        icon={<MdFormatListBulleted />}
      />
      <ToolbarList
        type={getSlatePluginType(editor, ELEMENT_OL)}
        icon={<MdFormatListNumbered />}
      />
    </React.Fragment>
  );
};

const TableTools = () => (
  <React.Fragment>
    <ToolbarTable
      icon={<CgExtensionRemove />}
      transform={deleteTable}
    />
    <ToolbarTable
      icon={<AiOutlineInsertRowRight />}
      transform={addColumn}
    />
    <ToolbarTable
      icon={<AiOutlineInsertRowBelow />}
      transform={addRow}
    />
    <ToolbarTable
      icon={<AiOutlineDeleteColumn />}
      transform={deleteColumn}
    />
    <ToolbarTable
      icon={<AiOutlineDeleteRow />}
      transform={deleteRow}
    />
  </React.Fragment>
);

const Tools = ({ mediaPickerModalProps }) => {
  const editor = useStoreEditorState(useEventEditorId('focus'));

  const tableType = getSlatePluginType(editor, ELEMENT_TABLE);

  const isSelectingTable = someNode(editor, {
    match: { type: tableType },
  });

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <HeadingTools />
          <BasicMarkTools />
          <ListTools />
          <ToolbarLink icon={<MdLink />} />
          <ToolbarImage
            icon={<MdImage />}
            mediaPickerModalProps={mediaPickerModalProps}
          />
          <ToolbarElement
            type={getSlatePluginType(editor, ELEMENT_MEDIA_EMBED)}
            icon={<ImEmbed2 />}
          />
          {!isSelectingTable && (
            <ToolbarTable
              icon={<AiOutlineTable />}
              transform={insertTable}
            />
          )}
        </Row>
        <Row>
          {isSelectingTable && (
            <React.Fragment>
              <p>Table Options: </p>
              <TableTools />
            </React.Fragment>
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};

const defaultValue = '<p class="slate-p"></p>';

const editableProps = {
  placeholder: 'Type here...',
};

const RichTextEditor = ({
  id,
  mediaPickerModalProps,
  initialValue,
  onChange,
}) => {
  const editor = createEditorPlugins();

  return (
    <React.Fragment>
      <SlatePlugins
        id={id}
        editableProps={editableProps}
        initialValue={deserializeHTMLToDocumentFragment(editor, {
          plugins,
          element: initialValue || defaultValue,
        })}
        onChange={(nodes) =>
          onChange(serializeHTMLFromNodes(editor, { plugins, nodes }))
        }
        plugins={plugins}
        components={components}
        options={options}
      >
        <HeadingToolbar>
          <Tools mediaPickerModalProps={mediaPickerModalProps} />
        </HeadingToolbar>
      </SlatePlugins>
    </React.Fragment>
  );
};

RichTextEditor.propTypes = {
  /** The editor ID. Required to manage multiple mounted editors */
  id: PropTypes.string.isRequired,
  /** The props for the MediaPickerModalProps */
  mediaPickerModalProps: PropTypes.shape({
    /** the resource that the image is attached to */
    parent: PropTypes.oneOf([
      'category',
      'product',
      'product-tab-content',
    ]).isRequired,
    /** the ID of the resouce that the image is attached to */
    parentId: PropTypes.string.isRequired,
    allowMultiple: PropTypes.bool,
    maxFiles: PropTypes.number,
  }).isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};

RichTextEditor.defaultProps = {
  initialValue: defaultValue,
  onChange: () => {},
};

export default RichTextEditor;
