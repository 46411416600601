import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';
import datetime from 'lib/datetime';
import { CREATE_ORDER_LINE_ERROR_CODE } from './constants';

export const getOrders = async () => {
  try {
    const response = await httpClient.get('/cms/orders');

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getOrderStatusList = async () => {
  try {
    const response = await httpClient.get('/order-statuses');

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putUpdateOrderStatus = async (orderId, status) => {
  try {
    const response = await httpClient.put(
      `/cms/orders/${orderId}/status`,
      { status },
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getOrderById = async (orderId) => {
  try {
    const response = await httpClient.get(`/cms/orders/${orderId}`);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getOrderLogsById = async (orderId) => {
  try {
    const response = await httpClient.get(
      `/cms/orders/${orderId}/logs`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const postAddOrder = async ({ user_id }) => {
  try {
    const response = await httpClient.post(`/cms/orders`, {
      user_id,
    });

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putUpdateOrder = async (
  orderId,
  { addresses, has_utensil, delivery_time, notes, collection_point_id },
) => {
  try {
    const response = await httpClient.put(`/cms/orders/${orderId}`, {
      addresses,
      has_utensil,
      delivery_time: datetime(delivery_time).toISOString(), // ensure it's ISO 8601 string (milli seconds), API might return date-time in micro seconds
      notes,
      collection_point_id,
    });

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteOrder = async (orderId) => {
  try {
    const response = await httpClient.delete(
      `/cms/orders/${orderId}`,
    );

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const refundOrder = async (orderId, { reason }) => {
  try {
    const response = await httpClient.post(
      `/cms/orders/${orderId}/refund`,
      { reason },
    );

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const cancelOrder = async (orderId, { cancel_reason }) => {
  try {
    const response = await httpClient.post(
      `/cms/orders/${orderId}/cancel`,
      { cancel_reason },
    );

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const postAddOrderLine = async (
  orderId,
  { product_variant_id, quantity, addon_option_value_ids },
) => {
  try {
    const response = await httpClient.post(
      `/cms/orders/${orderId}/lines`,
      {
        product_variant_id,
        quantity,
        addon_option_value_ids,
      },
    );

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putUpdateOrderLine = async (
  orderId,
  orderLineId,
  { unit_price, quantity },
) => {
  try {
    const response = await httpClient.put(
      `/cms/orders/${orderId}/lines/${orderLineId}`,
      { unit_price, quantity },
    );

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteOrderLine = async (orderId, orderLineId) => {
  try {
    const response = await httpClient.delete(
      `/cms/orders/${orderId}/lines/${orderLineId}`,
    );

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putUpdateOrderLineAddons = async (
  orderId,
  orderLineId,
  { addonOptionValueIds },
) => {
  try {
    const response = await httpClient.put(
      `/cms/orders/${orderId}/lines/${orderLineId}/addon_option_values`,
      {
        addon_option_value_ids: addonOptionValueIds,
      },
    );

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getOrderInvoice = async (orderId) => {
  try {
    const response = await httpClient.post(
      `/cms/orders/${orderId}/invoice`,
    );

    return response;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getOrderInvoiceNow = async (orderId) => {
  try {
    const response = await httpClient.post(
      `/cms/orders/${orderId}/invoice/now`,
      null,
      { responseType: 'blob' },
    );

    return response;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getOrdersExport = async ({
  delivery_start,
  delivery_end,
  order_statuses,
}) => {
  try {
    const response = await httpClient.post(
      `/cms/reports/orders/general/excel`,
      {
        delivery_start,
        delivery_end,
        order_statuses,
      },
      { responseType: 'blob' },
    );

    return response;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getOrdersExportNow = async ({
  delivery_start,
  delivery_end,
  order_statuses,
}) => {
  try {
    const response = await httpClient.post(
      `/cms/reports/orders/general/excel/now`,
      {
        delivery_start,
        delivery_end,
        order_statuses,
      },
      { responseType: 'blob' },
    );

    return response;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putBulkUpdateOrderStatus = async ({
  order_ids,
  status,
}) => {
  try {
    const response = await httpClient.put(`/cms/orders/bulk/status`, {
      order_ids,
      status,
    });

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const applyCouponToOrder = async (orderId, couponCode) => {
  try {
    const response = await httpClient.post(
      `/cms/orders/${orderId}/coupons/${couponCode}`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const removeCouponFromOrder = async (orderId, couponCode) => {
  try {
    await httpClient.delete(
      `/cms/orders/${orderId}/coupons/${couponCode}`,
    );
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const duplicateOrder = async (
  orderId,
  { user_id, addresses },
) => {
  try {
    const response = await httpClient.post(
      `/cms/orders/${orderId}/duplicate`,
      {
        user_id,
        addresses,
      },
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
