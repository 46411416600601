import { LogsQueryParams, LogTable, useLogs } from 'lib/log';
import { useState } from 'react';

interface UserLoveMessagesLogProps {
  loveMessageId: string;
}

const UserLoveMessagesLog = ({
  loveMessageId,
}: UserLoveMessagesLogProps) => {
  const logsUrl = `/cms/love_messages/${loveMessageId}/logs`;
  const [params, setParams] = useState<LogsQueryParams>({
    page: 1,
    perPage: 15,
  });

  const { logs, isLoading, total } = useLogs({
    url: logsUrl,
    params,
  });

  const handleTableChange = async (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => {
    switch (action) {
      case 'pagination':
        const { page: _page, sizePerPage } = newState;
        setParams((prev) => ({
          ...prev,
          page: _page,
          perPage: sizePerPage,
        }));
        break;

      default:
        break;
    }
  };

  return (
    <>
      <h5 className="mb-3">Love Message Log</h5>
      <LogTable
        data={logs}
        isLoading={isLoading}
        onTableChange={handleTableChange}
        page={params.page}
        perPage={params.perPage}
        totalSize={total}
      />
    </>
  );
};

export default UserLoveMessagesLog;
