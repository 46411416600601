import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { Page } from 'components/Common';
import { Table } from 'core/components';
import { Datetime } from 'lib/datetime';
import { Link } from 'lib/router';
import {
  useCategories,
  PublishCategoryButton,
} from 'lib/productCategory';
import { routePaths } from 'appConfig';

const CategoriesPage = () => {
  const { categories, isLoading, mutate } = useCategories();

  const mutateCategory = (categoryId, newData) => {
    mutate(async (data) =>
      data.map((datum) => {
        if (datum.id !== categoryId) return datum;

        return {
          ...datum,
          ...newData,
        };
      }),
    );
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (name, category) => (
        <Link to={`${routePaths.CATEGORIES}/${category.id}`}>
          {name}
        </Link>
      ),
    },
    {
      dataField: 'created_at',
      text: 'Created At',
      type: 'date',
      formatter: (cell) => <Datetime value={cell} />,
    },
    {
      dataField: 'updated_at',
      text: 'Updated At',
      type: 'date',
      formatter: (cell) => <Datetime value={cell} />,
    },
    {
      dataField: '',
      text: 'Actions',
      formatter: (_cell, row) => (
        <PublishCategoryButton
          category={row}
          mutateCategory={mutateCategory}
        />
      ),
    },
  ];

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <Table
              keyField="id"
              columns={columns}
              data={categories}
              loading={isLoading}
            />
          </CardBody>
        </Card>
      }
    />
  );
};

export default CategoriesPage;
