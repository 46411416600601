import { routePaths } from 'appConfig';
import { Page } from 'components/Common';
import {
  ComplaintCategoriesTable,
  ComplaintCategory,
  useComplaintCategories,
} from 'lib/complaint-categories';
import { useRouter } from 'lib/router';
import { mapValues } from 'lodash';
import { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { getRoleUser } from 'store/auth/GetRoleUser/actions';
import { UserRoleRedux } from 'store/auth/GetRoleUser/types';
// @ts-ignore
import { includes } from 'lib/javascript';

const ComplaintCategoriesPage = () => {
  const { query, updateQueryParams, setQueryParams } = useRouter();
  const history = useHistory();

  const dispatch = useDispatch();
  const roleState = useSelector((state: UserRoleRedux) => state.role);
  const { isExecutiveAssistant, isAdmin } = roleState?.role;
  useEffect(() => {
    dispatch(getRoleUser());
  }, [dispatch]);

  const {
    page = 1,
    sizePerPage = 15,
    search = '',
    // TODO: will implement user ids filter
    // userIds = '',
  } = query;

  const {
    complaintCategories,
    isLoading,
    mutate,
    onDelete,
    total,
  } = useComplaintCategories({
    params: { page, perPage: sizePerPage, search },
  });

  const onTableChange = async (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => {
    switch (action) {
      case 'pagination':
        const { page: _page, sizePerPage } = newState;
        updateQueryParams({
          page: _page,
          sizePerPage,
        });
        break;

      case 'filter':
        const mappedFilters = mapValues(
          newState.filters,
          (filter) => filter.filterVal,
        );

        const name = mappedFilters['name'];

        if (!name) {
          setQueryParams({
            page: newState.page,
            sizePerPage: newState.sizePerPage,
          });
        } else {
          updateQueryParams({
            page: '1',
            sizePerPage: newState.sizePerPage,
            search: name,
          });
        }
        break;

      default:
        break;
    }
  };

  const onShowDetails = (complaintCategory: ComplaintCategory) => {
    history.push(
      `${routePaths.COMPLAINT_CATEGORIES}/${complaintCategory.id}`,
    );
  };

  const onClickUser = (userId: string) => {
    history.push(`${routePaths.USERS}/${userId}`);
  };

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <div className={`d-flex justify-content-end mb-2 `}>
              <Button
                hidden={
                  !includes([isExecutiveAssistant, isAdmin], true)
                }
                onClick={() => {
                  history.push(
                    `${routePaths.NEW_COMPLAINT_CATEGORY}`,
                  );
                }}
              >
                Add Feedback Category
              </Button>
            </div>
            <ComplaintCategoriesTable
              data={complaintCategories}
              isLoading={isLoading}
              totalSize={total}
              page={parseInt(page?.toString() ?? '1')}
              perPage={sizePerPage}
              allowFilter
              onTableChange={onTableChange}
              onShowDetails={onShowDetails}
              onDelete={onDelete}
              onClickUser={onClickUser}
            />
          </CardBody>
        </Card>
      }
    />
  );
};

export default ComplaintCategoriesPage;
