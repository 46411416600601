import httpClient, { getResponseData } from 'lib/httpClient';
import { useListCRUD } from 'lib/swr-crud';
import { postCreateDonation } from './api';

const fetchers = {
  read: async (url, query) => {
    try {
      const response = await httpClient({
        method: 'get',
        url,
        query,
      });

      return getResponseData(response);
    } catch (error) {
      throw error;
    }
  },
  create: async (_url, donation) => {
    try {
      const response = await postCreateDonation(donation);
      return response;
    } catch (err) {
      throw err;
    }
  },
};

const useDonations = ({
	page = 1,
	sizePerPage = 15,
	sortField = 'organization_name',
	sortDir = 'asc',
	organizationName = '',
	headline = '',
	shortDescription = '',
	description = '',
} = {}, isActive = false) => {
	const url = '/cms/events/donations';

  const query = isActive ? {
    is_active: true
  } : {
    page,
    per_page: sizePerPage,
    sort_field: sortField,
    sort_dir: sortDir,
    organization_name: organizationName,
    headline,
    short_description: shortDescription,
    description,
    is_active: false
  }

	const { data, error, isLoading, create, mutate } = useListCRUD(
		url,
		'id',
		{ fetchers, query },
	);
	const donations = data?.data || [];
	const total = data?.meta?.total || 0;

	return {
		donations,
		error,
		isLoading,
		mutate,
    create,
		total,
	};
};

export default useDonations;
