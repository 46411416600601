import { Route, withRouter, Redirect } from 'react-router-dom';
import useSession from 'lib/useSession';
import { ConfirmationContextProvider } from 'lib/confirm';
import { DeliverySlotsContextProvider } from 'lib/operation';
import { routePaths } from 'appConfig';
import { defaultSWRConfig } from 'lib/swr-crud/swr-config';
import { SWRConfig } from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getRoleUser } from 'store/auth/GetRoleUser/actions';

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  roles,
  ...routeProps
}) => {
  const { loading, session } = useSession();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRoleUser());
  }, [dispatch]);

  const roleState = useSelector((state) => state.role);
  const roleName = roleState?.role?.name;

  if (loading) return null;

  if (!session) return <Redirect to={routePaths.LOGOUT} />;

  if (roles && !roles.includes(roleName)) {
    return <Redirect to={routePaths.PAGE_404} />;
  }
  return (
    <Route
      {...routeProps}
      render={(props) => {
        return (
          <SWRConfig value={defaultSWRConfig}>
            <ConfirmationContextProvider>
              <DeliverySlotsContextProvider>
                <Layout>
                  <Component {...props} />
                </Layout>
              </DeliverySlotsContextProvider>
            </ConfirmationContextProvider>
          </SWRConfig>
        );
      }}
    />
  );
};

export default withRouter(Authmiddleware);
