import datetime, { formatDate, formatTime } from 'lib/datetime';
import { DELIVERY_TIMESLOT_DURATION_HOURS } from 'lib/deliveryDateTimes';
import { isEmpty } from 'lodash';
import React from 'react';

/**
 * @param {PropsInput}
 */
const OrderLogDiff = ({ title, newLog, oldLog, type, logStyle }) => {
  const shouldShow = () => {
    if (type === 'STATIC_LOG') {
      return oldLog?.id !== newLog?.id;
    }

    const isBothEmpty = isEmpty(oldLog) && isEmpty(newLog);

    return !isBothEmpty && oldLog !== newLog;
  };

  const formatLog = (log) => {
    if (type === 'STATIC_LOG') {
      return log?.value;
    }

    if (type === 'BOOLEAN') {
      const isNullOrUndifined = log === null || log === undefined;

      return isNullOrUndifined ? '' : log ? 'Yes' : 'No';
    }

    if (type === 'DELIVERY_SLOT') {
      if (isEmpty(log)) {
        return (
          <span className="text-color-mute">&lt;No Date&gt;</span>
        );
      }
      const endOfdeliverySlot = datetime(log).add(
        DELIVERY_TIMESLOT_DURATION_HOURS,
        'hour',
      );

      return (
        <>
          {formatDate(log)} {formatTime(log)} -{' '}
          {formatTime(endOfdeliverySlot)}
        </>
      );
    }

    return log;
  };

  const formatLogNewLog = () => {
    return formatLog(newLog);
  };

  const formatLogOldLog = () => {
    return formatLog(oldLog);
  };

  return (
    <React.Fragment>
      {shouldShow() && (
        <p>
          <b>{title}</b>:{' '}
          <span style={logStyle}>
            <strike>{formatLogOldLog()}</strike> {formatLogNewLog()}
          </span>
        </p>
      )}
    </React.Fragment>
  );
};

export default OrderLogDiff;

/**
 * @typedef PropsInput
 * @type {object}
 *
 * @property {JSX.Element} title
 * @property {('STATIC_LOG'|'NORMAL'|'BOOLEAN'|'DELIVERY_SLOT')} type
 * @property {any} oldLog
 * @property {any} newLog
 * @property {React.CSSProperties} logStyle
 */
