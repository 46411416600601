import PropTypes from 'prop-types';
import { AddressPropType } from 'lib/address';

export const OrderLinePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  product_variant_id: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  unit_price: PropTypes.number.isRequired,
  tax_total: PropTypes.number.isRequired,
  description: PropTypes.string,
  tax_rate: PropTypes.number,
  meta: PropTypes.shape({
    tax: PropTypes.shape({
      inclusive: PropTypes.bool,
    }),
  }),
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
});

export const AdditionalOrderLinePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  unit_price: PropTypes.number.isRequired,
  tax_total: PropTypes.number.isRequired,
  description: PropTypes.string,
  tax_rate: PropTypes.number,
  meta: PropTypes.shape({
    tax: PropTypes.shape({
      inclusive: PropTypes.bool,
    }),
  }),
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
});

export const OrderPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  order_number: PropTypes.string.isRequired,
  user: PropTypes.shape({
    user_id: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone_number: PropTypes.string,
    email: PropTypes.string,
  }),
  status: PropTypes.string,
  addresses: PropTypes.shape({
    billing: AddressPropType,
    shipping: AddressPropType,
  }),
  sub_total: PropTypes.number,
  delivery_total: PropTypes.number,
  tax_total: PropTypes.number,
  grand_total: PropTypes.number,
  notes: PropTypes.string,
  delivery_datetime: PropTypes.string,
  additionals: PropTypes.arrayOf(AdditionalOrderLinePropType),
  lines: PropTypes.arrayOf(OrderLinePropType),
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  is_cancellable: PropTypes.bool.isRequired,
  is_refundable: PropTypes.bool.isRequired,
});
