import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'lib/router';

import { isEmpty, map } from 'lib/javascript';
import { Table } from 'core/components';
import * as typedef from './typedef';

const VariantsTable = ({ onClickSubjectLinkTo, data }) => {
  const columns = [
    {
      dataField: 'options',
      text: 'Variant',
      formatter: (options, row) => {
        const label = isEmpty(options)
          ? 'Default'
          : map(options, (option) => option).join(', ');

        return <Link to={onClickSubjectLinkTo(row)}>{label}</Link>;
      },
    },
    {
      dataField: 'sku',
      text: 'SKU',
    },
    {
      dataField: 'stock',
      text: 'Stock',
    },
    {
      dataField: 'price',
      text: 'Unit Price',
    },
    {
      dataField: 'alias',
      text: 'Alias',
    },
  ];

  return <Table keyField="id" columns={columns} data={data} />;
};

VariantsTable.propTypes = {
  onClickSubjectLinkTo: PropTypes.func,
  data: PropTypes.arrayOf(typedef.ProductVariant),
};

VariantsTable.defaultProps = {
  onClickSubjectLinkTo: () => '#',
  data: [],
};

export default VariantsTable;
