import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const getTags = async ({ page, per_page }) => {
  try {
    const response = await httpClient.get('/cms/tags', {
      params: { page, per_page },
    });

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getTagById = async (tagId) => {
  try {
    const response = await httpClient.get(`/cms/tags/${tagId}`);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const postCreateTag = async ({ name }) => {
  try {
    const response = await httpClient.post('/cms/tags', { name });

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putUpdateTag = async (tagId, { name }) => {
  try {
    const response = await httpClient.put(`/cms/tags/${tagId}`, {
      name,
    });

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteTag = async (tagId) => {
  try {
    await httpClient.delete(`/cms/tags/${tagId}`);

    return;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
