import yup from 'lib/validator';
import { mergeSchemas } from 'lib/validator';

export const minLength8 = yup.string().min(8);

export const minLength16 = yup.string().min(16);

export const containsNumbers = yup
  .string()
  .matches(/[0-9]/, 'password must contain number');

export const containsLowerCaseAlphas = yup
  .string()
  .matches(/[a-z]/, 'password must contain lower case alphabet');

export const containsUpperCaseAlphas = yup
  .string()
  .matches(/[A-Z]/, 'password must contain upper case alphabet');

export const containsSpecialChars = yup
  .string()
  .matches(
    /[@#$%^&*()_+|~\-=\\`{}[\]:";'<>/!]/,
    'password must contain special character',
  );

export const adminPasswordSchema = mergeSchemas(
  minLength16,
  containsNumbers,
  containsLowerCaseAlphas,
  containsUpperCaseAlphas,
  containsSpecialChars,
);
