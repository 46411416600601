import { CouponPropType } from './typedef';
import { getCouponType, getLabelOfCouponType } from './utils';

const CouponType = ({ coupon }) => {
  const couponType = getCouponType(coupon);
  const label = getLabelOfCouponType(couponType);

  return label;
};

CouponType.propTypes = {
  coupon: CouponPropType,
};

export default CouponType;
