import { useState } from 'react';
import useUserLoveMessages from './use-user-love-messages';
import {
  LoveMessage,
  LoveMessagePayload,
  UserLoveMessagesQueryParams,
} from './types';
import notification from 'lib/notification';
import { Button } from 'components/Common';
import LoveMessageForm from './LoveMessageForm';
import LoveMessagesTable from './LoveMessagesTable';
// @ts-ignore-line
import { pick } from 'lib/javascript';
import UserLoveMessagesLog from './UserLoveMessagesLog';
import { routePaths } from 'appConfig';
import { useHistory } from 'react-router';

interface UserLoveMessagesProps {
  userId: string;
}

const UserLoveMessages = ({ userId }: UserLoveMessagesProps) => {
  const history = useHistory();
  const [params, setParams] = useState<UserLoveMessagesQueryParams>({
    page: 1,
    perPage: 15,
  });

  const {
    loveMessages,
    isLoading,
    total,
    createLoveMessage,
    updateLoveMessage,
    deleteLoveMessage,
  } = useUserLoveMessages({ userId, params });

  const [showSendLoveForm, setShowSendLoveForm] = useState(false);
  const [loveMessage, setLoveMessage] = useState<LoveMessage | null>(
    null,
  );

  const onCloseLoveMessageForm = () => {
    setLoveMessage(null);
    setShowSendLoveForm(false);
  };

  const onShowDetails = (loveMessage: LoveMessage) => {
    setLoveMessage(loveMessage);
    setShowSendLoveForm(true);
  };

  const onSubmitLoveMessage = async (payload: LoveMessagePayload) => {
    const isUpdate = Boolean(payload.id);
    try {
      const newPayload = pick(payload, ['content']);
      if (isUpdate) {
        const loveMessageId = payload.id;
        if (!loveMessageId) return;

        await updateLoveMessage(loveMessageId, newPayload);
      } else {
        await createLoveMessage(newPayload);
      }
      onCloseLoveMessageForm();
      notification.success(
        `Love message ${isUpdate ? 'updated' : 'sent'}.`,
      );
    } catch (error) {
      notification.error(
        `Something went wrong while ${
          isUpdate ? 'updating' : 'send'
        } love message.`,
      );
    }
  };

  const handleTableChange = async (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => {
    switch (action) {
      case 'pagination':
        const { page: _page, sizePerPage } = newState;
        setParams((prev) => ({
          ...prev,
          page: _page,
          perPage: sizePerPage,
        }));
        break;

      default:
        break;
    }
  };

  const onClickUser = (userId: string) => {
    history.push(`${routePaths.USERS}/${userId}`);
  };

  return (
    <>
      {!showSendLoveForm ? (
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-end">
            {/* @ts-ignore */}
            <Button
              color="danger"
              onClick={() => setShowSendLoveForm(true)}
            >
              Send Love
            </Button>
          </div>
          <div className="mt-4">
            <LoveMessagesTable
              data={loveMessages}
              isLoading={isLoading}
              totalSize={total}
              page={params.page}
              perPage={params.perPage}
              onTableChange={handleTableChange}
              onShowDetails={onShowDetails}
              onClickUser={onClickUser}
              onDelete={deleteLoveMessage}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="mb-5">
            <LoveMessageForm
              loveMessage={loveMessage}
              onCancel={onCloseLoveMessageForm}
              onSubmit={onSubmitLoveMessage}
            />
          </div>
          {loveMessage && (
            <UserLoveMessagesLog
              loveMessageId={loveMessage.id ?? ''}
            />
          )}
        </>
      )}
    </>
  );
};

export default UserLoveMessages;
