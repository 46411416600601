import React from 'react';
import PropTypes from 'prop-types';

import { Skeleton } from 'components/Common';

import AddonSelectGroup from '../AddonSelectGroup';
import useProductVariantAddonOptions from '../useProductVariantAddonOptions';
import useProductVariantAddonRules from '../useProductVariantAddonRules';

import useSetDefaultSelectedAddons from './useSetDefaultSelectedAddons';

const ProductVariantAddonSelectGroup = ({
  productVariantId,
  value,
  onChange,
}) => {
  const {
    isLoading: isAddonOptionsLoading,
    addonOptions,
    selectAddonOptionById,
    selectAddonOptionValueById,
  } = useProductVariantAddonOptions(productVariantId);
  const {
    isLoading: isAddonRulesLoading,
    addonRules,
  } = useProductVariantAddonRules(productVariantId);

  useSetDefaultSelectedAddons({
    productVariantId,
    selectedAddons: value,
    setSelectedAddons: onChange,
    addonOptions: isAddonOptionsLoading ? [] : addonOptions,
    addonRules: isAddonRulesLoading ? [] : addonRules,
  });

  if (isAddonOptionsLoading || isAddonRulesLoading)
    return <Skeleton type="line" options={{ numberOfLines: 1 }} />;

  return (
    <AddonSelectGroup
      addonOptions={addonOptions || []}
      addonRules={addonRules || []}
      value={value}
      onChange={onChange}
      selectAddonOptionById={selectAddonOptionById}
      selectAddonOptionValueById={selectAddonOptionValueById}
    />
  );
};

ProductVariantAddonSelectGroup.propTypes = {
  productVariantId: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      addon_option_id: PropTypes.string,
      addon_option_value_id: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
};

export default ProductVariantAddonSelectGroup;
