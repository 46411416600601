import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router';

import { routePaths } from 'appConfig';
import { Button, Page } from 'components/Common';
import { useTag, EditTagForm, deleteTag } from 'lib/tag';
import { useRouter } from 'lib/router';
import notification from 'lib/notification';
import { useConfirmation } from 'lib/confirm';

const TagDetailsPage = () => {
  const history = useHistory();
  const { query } = useRouter();
  const { tagId } = query;
  const { tag } = useTag(tagId);
  const { confirm } = useConfirmation();
  const [isDeleting, setIsDeleting] = React.useState(false);

  const handleDeleteTag = async () => {
    await confirm();

    try {
      setIsDeleting(true);

      await deleteTag(tagId);

      history.replace(routePaths.TAGS);

      notification.success('Successfully deleted tag');
    } catch (err) {
      const errorMessage =
        err.errors.message ||
        'Something went wrong when deleting tag';

      notification.error(errorMessage);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Page
      breadcrumbProps={{
        config: {
          'tag-details': {
            path: routePaths.TAG_DETAIL,
            label: tag?.name,
          },
        },
      }}
      content={
        <Card>
          <CardBody>
            <div
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                color="danger"
                onClick={handleDeleteTag}
                loading={isDeleting}
                disabled={isDeleting}
              >
                Delete Tag
              </Button>
            </div>
            <EditTagForm tagId={tagId} />
          </CardBody>
        </Card>
      }
    />
  );
};

TagDetailsPage.propTypes = {};

export default TagDetailsPage;
