import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { Page } from 'components/Common';
import { useCoupons, CouponsTable } from 'lib/coupons';
import { useRouter } from 'lib/router';
import { mapValues } from 'lib/javascript';

const CouponsPage = () => {
  const { query, updateQueryParams } = useRouter();

  const [filterParams, setFilterParams] = React.useState({
    couponCode: '',
  });

  const { page = 1, sizePerPage = 15 } = query;

  const { isLoading, coupons, total } = useCoupons({
    page,
    sizePerPage,
    couponCode: filterParams.couponCode,
  });

  const handleOnTableChange = (action, newState) => {
    switch (action) {
      case 'pagination':
        updateQueryParams({
          page: newState.page,
          sizePerPage: newState.sizePerPage,
        });
        break;
      case 'filter':
        const mappedFilters = mapValues(
          newState.filters,
          (filter) => filter.filterVal,
        );
        const couponCode = mappedFilters['coupon_code'];

        setFilterParams({
          couponCode,
        });
        updateQueryParams({
          page: 1,
          sizePerPage: newState.sizePerPage,
        });
        break;

      default:
    }
  };

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <CouponsTable
              loading={isLoading}
              data={coupons}
              onTableChange={handleOnTableChange}
              page={parseInt(page)}
              sizePerPage={parseInt(sizePerPage)}
              totalSize={total}
            />
          </CardBody>
        </Card>
      }
    />
  );
};

CouponsPage.propTypes = {};

export default CouponsPage;
