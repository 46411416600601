import * as routePaths from './routePaths';

const breadcrumbConfig = {
  users: {
    path: routePaths.USERS,
    label: 'Users',
  },
  user: {
    path: routePaths.USER_DETAILS,
    label: 'User Details',
  },
  categories: {
    path: routePaths.CATEGORIES,
    label: 'Categories',
  },
  'new-product-category': {
    path: routePaths.NEW_CATEGORY,
    label: 'New Category',
  },

  products: {
    path: routePaths.PRODUCTS,
    label: 'Products',
  },
  'new-product': {
    path: routePaths.NEW_PRODUCT,
    label: 'New Product',
  },
  product: {
    path: routePaths.PRODUCT_DETAILS,
    label: 'Product',
  },
  tags: {
    path: routePaths.TAGS,
    label: 'Tags',
  },
  'new-tag': {
    path: routePaths.NEW_TAG,
    label: 'New Tag',
  },
  'tag-details': {
    path: routePaths.TAG_DETAIL,
    label: 'Tag',
  },
  'setup-product-variants': {
    path: routePaths.PRODUCT_VARIANT_SETUP,
    label: 'Setup Variants',
  },
  orders: {
    path: routePaths.ORDERS,
    label: 'Orders',
  },
  'orders-export': {
    path: routePaths.ORDERS_EXPORT,
    label: 'Orders Export',
  },
  ingredients: {
    path: routePaths.INGREDIENTS,
    label: 'Ingredients',
  },
  'new-ingredient': {
    path: routePaths.NEW_INGREDIENT,
    label: 'New Ingredient',
  },
  ingredient: {
    path: routePaths.INGREDIENT_DETAIL,
    label: 'Ingredient',
  },

  'addon-options': {
    path: routePaths.ADDON_OPTIONS,
    label: 'Addon Options',
  },
  'new-addon-option': {
    path: routePaths.NEW_ADDON_OPTION,
    label: 'New Addon Option',
  },
  'addon-option': {
    path: routePaths.ADDON_OPTION_DETAILS,
    label: 'Addon Option',
  },

  'addon-rules': {
    path: routePaths.ADDON_RULES,
    label: 'Addon Rules',
  },
  'new-addon-rule': {
    path: routePaths.NEW_ADDON_RULE,
    label: 'New Addon Rule',
  },
  'addon-rule': {
    path: routePaths.ADDON_RULE_DETAILS,
    label: 'Addon Rule',
  },

  coupons: {
    path: routePaths.COUPONS,
    label: 'Coupons',
  },
  'new-coupon': {
    path: routePaths.NEW_COUPON,
    label: 'New Coupon',
  },
  donations: {
    path: routePaths.DONATIONS,
    label: 'Donations',
  },
  'new-donation': {
    path: routePaths.NEW_DONATION,
    label: 'New Donation Event',
  },
  donation: {
    path: routePaths.DONATION_DETAILS,
    label: 'Donation',
  },
  collections: {
    path: routePaths.COLLECTIONS,
    label: 'Collections',
  },
  'new-collection': {
    path: routePaths.NEW_COLLECTION,
    label: 'New Collection',
  },
  catalogues: {
    path: routePaths.CATALOGUES,
    label: 'Manual Collection',
  },
  'new-catalogue': {
    path: routePaths.NEW_CATALOGUE,
    label: 'New Manual Collection',
  },
  catalogue: {
    path: routePaths.CATALOGUE_DETAIL,
    label: 'Manual Collection Details',
  },
  coupon: {
    path: routePaths.COUPON_DETAILS,
    label: 'Coupon',
  },

  operation: {
    path: routePaths.OPERATION,
    label: 'Operation Date Time',
  },

  'collection-points': {
    path: routePaths.COLLECTION_POINTS,
    label: 'Collection Points',
  },
  'new-collection-point': {
    path: routePaths.NEW_COLLECTION_POINT,
    label: 'New Collection Point',
  },
  'collection-point': {
    path: routePaths.COLLECTION_POINT_DETAILS,
    label: 'Collection Point Details',
  },

  'love-messages': {
    path: routePaths.LOVE_MESSAGES,
    label: 'Love Messages',
  },
  'love-message': {
    path: routePaths.LOVE_MESSAGE_DETAIL,
    label: 'Love Message Details',
  },

  'report-kitchen-product': {
    path: routePaths.REPORT_KITCHEN_PRODUCT,
    label: 'Kitchen Report Product',
  },

  analytics: {
    path: routePaths.ANALYTICS,
    label: 'Analytics',
  },

  'complaint-categories': {
    path: routePaths.COMPLAINT_CATEGORIES,
    label: 'Feedback Categories',
  },
  'complaint-category': {
    path: routePaths.COMPLAINT_CATEGORY_DETAIL,
    label: 'Feedback Category Details',
  },
  'new-complaint-category': {
    path: routePaths.NEW_COMPLAINT_CATEGORY,
    label: 'New Feedback Category',
  },

  complaints: {
    path: routePaths.COMPLAINTS,
    label: 'Feedback',
  },

  'complain-detail': {
    path: routePaths.COMPLAINT_DETAIL,
    label: 'Feedback Details',
  },
};

export default breadcrumbConfig;
