import React from 'react';

import combinate from 'combinate';

import { useFormContext } from 'lib/form';

const VariantsCombinator = () => {
  const { values, setFieldValue } = useFormContext();

  const { options } = values;

  React.useEffect(() => {
    const normalizedOptions = options.reduce((result, option) => {
      result[option.label.toLowerCase()] = option.options;

      return result;
    }, {});

    const combinations = combinate(normalizedOptions);

    const variants = combinations.map((combination) => ({
      price: 0,
      sku: '',
      stock: 0,
      options: combination,
    }));

    setFieldValue('variants', variants);
  }, [options, setFieldValue]);

  return null;
};

VariantsCombinator.propTypes = {};

export default VariantsCombinator;
