import httpClient, { getErrorMessage, getResponseData } from 'lib/httpClient';
import { useListCRUD } from 'lib/swr-crud';
import { Catalogue, CataloguesParams } from './types';

const fetchers = {
  create: async () => {},
  read: async (url: string, query?: any) => {
    try {
      // @ts-ignore
      const response = await httpClient({
        method: 'GET',
        url,
        query,
      });

      return getResponseData(response);
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
};

const useCatalogues = ({
  page,
  sizePerPage,
  sortField,
  sortDir,
  query,
  withDeleted,
}: CataloguesParams = {}) => {
  const url = '/cms/catalogues';

  const { data, error, create, isLoading, mutate } = useListCRUD(
    url,
    'id',
    {
      fetchers,
      query: {
        page,
        per_page: sizePerPage,
        sort_field: sortField,
        sort_dir: sortDir,
        query,
        deleted: withDeleted,
      },
    },
  );

  const catalogues: Array<Catalogue> = data?.data ?? [];
  const total: number = data?.meta?.total ?? 0;

  const _mutate = (updater: Function) => {
    mutate((data: { data: Catalogue[] }) => ({
      ...data,
      data: updater(data.data),
    }));
  };

  return {
    catalogues,
    total,
    create,
    error,
    isLoading,
    mutate: _mutate,
  };
};

export default useCatalogues;
