import React from 'react';
import PropTypes from 'prop-types';
import yup from 'lib/validator';
import { Col, Container, Row } from 'reactstrap';

import { FormManager, Form, GeneralError } from 'lib/form';
import notification from 'lib/notification';
import { TextField } from 'components/formFields';
import { Button } from 'components/Common';

const initialValues = {
  name: '',
};

const validationSchema = yup.object({
  name: yup.string().max(50).required(),
});

const NewCollectionForm = ({ onSubmit }) => {
  const handleOnSubmit = async (values, actions) => {
    try {
      actions.setStatus({
        error: null,
      });
      await onSubmit(values);
    } catch (error) {
      actions.setErrors(error.errors);
      actions.setStatus({
        error: error.errors?.message,
      });
      notification.error(error.errors?.message);
    }
  };

  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {(form) => (
        <Form autoComplete="off">
          <GeneralError />
          <Container>
            <Row>
              <Col>
                <TextField
                  name="name"
                  label="Collection Name"
                  required
                  maxLength={50}
                />
              </Col>
            </Row>

            <Button type="submit" loading={form.isSubmitting}>
              Create
            </Button>
          </Container>
        </Form>
      )}
    </FormManager>
  );
};

NewCollectionForm.propTypes = {
  onSubmit: PropTypes.func,
  isEditMode: PropTypes.bool,
};

NewCollectionForm.defaultProps = {
  onSubmit: () => {},
  isEditMode: false,
};

export default NewCollectionForm;
