import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import { userRoutes, authRoutes } from './allRoutes';
import Authmiddleware from './middleware/Authmiddleware';
import NonAuthLayout from '../components/NonAuthLayout';
import Pages404 from 'pages/Utility/pages-404';
import { routePaths } from 'appConfig';
import { HomeRedirect } from 'lib/home';

const NonAuthmiddleware = ({
  component: Component,
  layout: Layout,
  ...routeProps
}) => (
  <Route
    {...routeProps}
    render={(props) => {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

const Routes = ({ Layout }) => {
  return (
    <Switch>
      {authRoutes.map((route) => (
        <NonAuthmiddleware
          {...route}
          path={route.path}
          layout={NonAuthLayout}
          component={route.component}
          key={route.path}
        />
      ))}
      {userRoutes.map((route) => (
        <Authmiddleware
          {...route}
          path={route.path}
          layout={Layout}
          component={route.component}
          roles={route.roles}
          key={route.path}
        />
      ))}

      <Route path={routePaths.HOME} component={HomeRedirect} exact />
      <Route path="*" component={Pages404} />
    </Switch>
  );
};

Routes.propTypes = {
  Layout: PropTypes.node.isRequired,
};

export default Routes;
