import React from 'react';
import PropTypes from 'prop-types';

import { FormManager, Form, GeneralError } from 'lib/form';
import validator from 'lib/validator';
import { Button } from 'components/Common';
import { TextField } from 'components/formFields';
import { merge } from 'lib/javascript';

const defaultValues = {
  name: '',
};

const validationSchema = validator.object({
  name: validator.string().required(),
});

const TagForm = ({ initialValues, onSubmit, buttonText }) => {
  return (
    <FormManager
      initialValues={merge({}, defaultValues, initialValues)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <GeneralError />
          <TextField name="name" label="Name" />
          <Button type="submit" loading={isSubmitting}>
            {buttonText}
          </Button>
        </Form>
      )}
    </FormManager>
  );
};

TagForm.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  buttonText: PropTypes.string,
};

TagForm.defaultProps = {
  initialValues: {},
  onSubmit: () => {},
  buttonText: 'Submit',
};

export default TagForm;
