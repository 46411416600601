// saga.js
import { USER_ROLE } from 'lib/users/constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_ROLE_USER,
  GET_ROLE_USER_ERROR,
  GET_ROLE_USER_SUCCESS,
} from './actionTypes';

function getRoleFromLocalStorage() {
  const authUser = localStorage.getItem('authUser');
  if (authUser) {
    const parsedAuthUser = JSON.parse(authUser);
    const roleName = parsedAuthUser.data?.role || '';
    const kitchenRepresentativeRoles = [
      USER_ROLE.KITCHEN_REPRESENTATIVE_MAIN,
      USER_ROLE.KITCHEN_REPRESENTATIVE_OTHER,
    ];
    return {
      name: roleName,
      isAdmin: roleName === USER_ROLE.ADMIN,
      isExecutiveAssistant:
        roleName === USER_ROLE.EXECUTIVE_ASSISTANT,
      isCustomerService: roleName === USER_ROLE.CUSTOMER_SERVICE,
      isCustomer: roleName === USER_ROLE.CUSTOMER,
      isAffiliate: roleName === USER_ROLE.AFFILIATE,
      isKitchenRepresentative: kitchenRepresentativeRoles.includes(
        roleName,
      ),
    };
  }
  throw new Error('No authUser found in local storage');
}

// Saga to handle GET_ROLE_USER action
function* getRoleUserSaga() {
  try {
    const role = yield call(getRoleFromLocalStorage);
    yield put({ type: GET_ROLE_USER_SUCCESS, payload: role });
  } catch (error) {
    yield put({ type: GET_ROLE_USER_ERROR, payload: error.message });
  }
}

// Watcher saga
function* watchGetRoleUser() {
  yield takeLatest(GET_ROLE_USER, getRoleUserSaga);
}

export default watchGetRoleUser;
