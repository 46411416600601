import { Badge } from 'reactstrap';
import firstOrderIcon from '../../assets/images/first_order.svg'

interface FirstOrderBadgeProps {
  className?: string;
  color?: string;
};

const FirstOrderBadge = ({
  className,
  color = '',
}: FirstOrderBadgeProps) => {
  return (
    <Badge className={className} color={color}>
      <img src={firstOrderIcon} alt="" height="20" title="First Order" />
    </Badge>
  );
};

export default FirstOrderBadge;
