import datetime from 'lib/datetime';
import validator from 'lib/validator';
import { Form, FormManager, connectInput } from 'lib/form';
import { Button } from 'components/Common';
import { Col, Row } from 'reactstrap';
import { ProductAutoComplete } from 'lib/product';
import type { ProductReportPayload } from './types';
import useKitchenReport from './useKitchenReport';
import notification from 'lib/notification';
import {
  INITIAL_ORDER_STATUSES_TO_EXPORT,
  ISO_DATE_FORMAT,
} from './constants';
import {
  DatePickerField,
  OrderStatusAutoCompleteField,
  getErrorMessage,
  getSuccessMessage,
} from './utils';

const ProductAutoCompleteField = connectInput(ProductAutoComplete, {
  onChangeArgType: 'raw-value',
});

const ProductReportForm = () => {
  const initialValues: ProductReportPayload = {
    delivery_start: datetime().format(ISO_DATE_FORMAT),
    delivery_end: null,
    variant_id: '',
    order_statuses: INITIAL_ORDER_STATUSES_TO_EXPORT,
  };

  const validationSchema = validator.object({
    delivery_start: validator.string().required(),
    delivery_end: validator.string().nullable(),
    variant_id: validator.string().required(),
    order_statuses: validator.array().of(validator.string()).min(1).required(),
  });

  const { requestKitchenReport } = useKitchenReport('variant');

  const handleOnSubmit = async (values: ProductReportPayload) => {
    try {
      await requestKitchenReport(values);
      notification.success(getSuccessMessage('variant'));
    } catch (err) {
      notification.error(getErrorMessage(err));
    }
  };

  return (
    <div style={{ margin: '24px 0' }}>
      <FormManager
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="d-flex flex-wrap" style={{ gap: '1rem' }}>
              {/* @ts-ignore */}
              <DatePickerField
                name="delivery_start"
                label="Delivery Start At"
                outputFormat={ISO_DATE_FORMAT}
                required
              />

              {/* @ts-ignore */}
              <DatePickerField
                name="delivery_end"
                label="Delivery End At"
                outputFormat={ISO_DATE_FORMAT}
                clearable
              />
            </div>
            <Row>
              <Col>
                {/* @ts-ignore */}
                <ProductAutoCompleteField
                  name="variant_id"
                  label="Product"
                  isMulti={false}
                  required
                  excludedProductFamilies={['customizable-meal']}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* @ts-ignore */}
                <OrderStatusAutoCompleteField
                  name="order_statuses"
                  label="Order Status"
                  isMulti
                  required
                />
              </Col>
            </Row>
            {/* @ts-ignore */}
            <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
              Send Report
            </Button>
          </Form>
        )}
      </FormManager>
    </div>
  );
};

export default ProductReportForm;
