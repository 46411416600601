import { takeEvery, put, call } from 'redux-saga/effects';
import { GET_AUTH_USER } from './actionTypes';
import { getAuthUserSuccess, getAuthUserError } from './actions';

function* getAuthUser() {
  try {
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    if (authUser) {
      yield put(getAuthUserSuccess(authUser));
    } else {
      throw new Error('User not authenticated');
    }
  } catch (error) {
    yield put(getAuthUserError(error.message));
  }
}

export function* watchGetAuthUser() {
  yield takeEvery(GET_AUTH_USER, getAuthUser);
}

export default function* authUserSaga() {
  yield watchGetAuthUser();
}
