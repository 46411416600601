import { Address } from '../types';

export const getDialogTitle = (address: Address | undefined) => {
  if (address?.type === undefined) {
    return 'New Delivery Address';
  }

  return address.type === 'billing'
    ? 'Edit Billing Address'
    : 'Edit Delivery Address';
};
