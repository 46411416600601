import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { compile } from 'path-to-regexp';

import { routePaths } from 'appConfig';
import { Page } from 'components/Common';
import { Table } from 'core/components';
import { Datetime } from 'lib/datetime';
import * as productFamily from 'lib/productFamily';
import {
  PublishProductButton,
  useProducts,
  Stock,
} from 'lib/product';

const ProductsPage = () => {
  const { isLoading, products, mutate } = useProducts();

  const mutateProduct = (productId, newData) => {
    mutate(async (data) =>
      data.map((datum) => {
        if (datum.id !== productId) return datum;

        return {
          ...datum,
          ...newData,
        };
      }),
    );
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (cell, row) => (
        <Link
          to={compile(routePaths.PRODUCT_DETAILS)({
            productId: row?.id || '',
          })}
        >
          {cell}
        </Link>
      ),
    },
    {
      dataField: 'product_family',
      text: 'Product Family',
      formatter: (cell) =>
        productFamily.constants.LABEL_BY_PRODUCT_FAMILY[cell],
    },
    {
      dataField: 'variants[0].stock',
      text: 'Stock',
      formatter: (_cell, row) => <Stock product={row} />,
    },
    {
      dataField: 'variants[0].created_at',
      text: 'Created At',
      type: 'date',
      formatter: (cell) => <Datetime value={cell} />,
    },
    {
      dataField: 'variants[0].updated_at',
      text: 'Update At',
      type: 'date',
      formatter: (cell) => <Datetime value={cell} />,
    },
    {
      dataField: '',
      text: 'Actions',
      formatter: (_cell, row) => (
        <PublishProductButton
          product={row}
          mutateProduct={mutateProduct}
        />
      ),
    },
  ];

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <Table
              keyField="id"
              columns={columns}
              loading={isLoading}
              data={products}
            />
          </CardBody>
        </Card>
      }
    />
  );
};

ProductsPage.propTypes = {};

export default ProductsPage;
