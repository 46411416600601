import React from 'react';

import Button from '../Button';
import Spinner from '../Spinner';

const IconButton = ({
  variant,
  color,
  children,
  className,
  loading,
  ...others
}) => {
  return (
    <Button
      {...others}
      variant={variant}
      color={color}
      className={className}
      loading={loading}
      block={false}
      renderLoading={() => <Spinner size="sm" />}
    >
      {children}
    </Button>
  );
};

export default IconButton;
