import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';

import { Alert } from 'core/components';
import { Button, ReadOnly, Tab, Tabs } from 'components/Common';
import {
  TextField,
  CheckboxField,
  ReadOnlyField,
} from 'components/formFields';
import { FormManager, Form, connectInput } from 'lib/form';
import { FullName, Email, PhoneNumber, UserBadges } from 'lib/users';
import { Addresses } from 'lib/address';
import { DeliveryDateTimePicker } from 'lib/deliveryDateTimes';
import { useDeliverySlotsContext } from 'lib/operation';
import { useConfirmation } from 'lib/confirm';
import yup from 'lib/validator';
import notification from 'lib/notification';
import { expand } from 'lib/javascript';

import { OrderPropType } from './typedef';
import OrderStatusSelect from './OrderStatusSelect';
import {
  isUtensilNeeded,
  isOrderConfirmed,
  isOrderLegacy,
} from './utils';
import datetime, { Datetime } from 'lib/datetime';
import { Link } from 'lib/router';
import { routePaths } from 'appConfig';
import { isNil } from 'lib/javascript';
import {
  SelectCollectionPoint,
  SelectCollectionPointSlot,
} from 'lib/collection-points';
import { DeliverySlotInfo } from './DeliverySlotInfo';

const validationSchema = yup.object({
  delivery_datetime: yup.string().nullable(),
  has_utensil: yup.bool(),
  notes: yup.string().nullable(),
});

const DeliveryDateTimeField = connectInput(DeliveryDateTimePicker, {
  onChangeArgType: 'raw-value',
});

const DELIVERY_METHODS = {
  DOORSTEP: 'DOORSTEP',
  CLICK_AND_COLLECT: 'CLICK_AND_COLLECT',
};

const ManageOrderDetails = ({
  order,
  updateOrder,
  updateOrderStatus,
}) => {
  const { confirm } = useConfirmation();
  const [isStatusLoading, setStatusLoading] = React.useState(false);
  const { getCutOffTimeBeforeDateTime } = useDeliverySlotsContext();
  const hasCollectionPoint = !isNil(order?.collection_point);

  const [collectionPoint, setCollectionPoint] = React.useState(
    order?.collection_point,
  );
  const [
    isFetchingCollectionSlot,
    setIsFetchingCollectionSlot,
  ] = React.useState(true);

  const [
    collectionPointSlot,
    setCollectionPointSlot,
  ] = React.useState(
    hasCollectionPoint ? order?.delivery_datetime : null,
  );

  const [deliveryMethod, setDeliveryMethod] = React.useState(
    hasCollectionPoint
      ? DELIVERY_METHODS.CLICK_AND_COLLECT
      : DELIVERY_METHODS.DOORSTEP,
  );

  const isCollectionPointSelected =
    deliveryMethod === DELIVERY_METHODS.CLICK_AND_COLLECT;

  if (!order) return null;

  const getInitialValues = () => {
    const has_utensil = isUtensilNeeded(order);

    const initialValues = {
      ...order,
      has_utensil,
    };

    return initialValues;
  };

  const handleStatusOnChange = async (newValue) => {
    confirm({
      title: 'Change Order Status',
      content:
        'This change is applied immediately. Are you sure to change the order status?',
    }).then(async () => {
      try {
        setStatusLoading(true);
        await updateOrderStatus(newValue);
      } finally {
        setStatusLoading(false);
      }
    });
  };

  const getDeliverySlotPayload = (deliverySlot) => ({
    collection_point_id: isCollectionPointSelected
      ? collectionPoint?.id
      : null,
    delivery_datetime: isCollectionPointSelected
      ? collectionPointSlot
      : deliverySlot,
  });

  const handleUpdateOrder = async (values) => {
    const {
      addresses,
      has_utensil,
      delivery_datetime,
      notes,
    } = values;

    const payload = {
      addresses,
      has_utensil,
      notes: isCollectionPointSelected ? '' : notes,
      ...getDeliverySlotPayload(delivery_datetime),
    };

    return await updateOrder(payload);
  };

  const _updateOrderAddresses = async ({ addresses }, actions) => {
    try {
      const { delivery_datetime } = order;

      const deliveryPayload = getDeliverySlotPayload(
        delivery_datetime,
      );

      if (!deliveryPayload.delivery_datetime) {
        notification.error(
          'Please save a delivery date-time first below! ',
        );

        return;
      }

      const has_utensil = isUtensilNeeded(order);

      await updateOrder(
        {
          addresses,
          has_utensil,
          ...deliveryPayload,
        },
        actions,
      );
    } catch (err) {
      if (err.errors) {
        const { addresses } = expand(err.errors);
        const addressesError = { errors: addresses };
        throw addressesError; // rethrow server errors only concerning about addresses, so that <AddressForm> can use this errors object to set as error
      }

      throw err;
    }
  };

  const defaultDeliveryDateTime = getInitialValues()
    ?.delivery_datetime;

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            {isOrderConfirmed(
              order,
              getCutOffTimeBeforeDateTime(order.delivery_datetime),
            ) && (
              <Col xs={12}>
                <Alert>IMPORTANT: This order is confirmed.</Alert>
              </Col>
            )}

            {isOrderLegacy(order) && (
              <Col xs={12}>
                <Alert color="warning">
                  <p>
                    This is a legacy order. The following features are
                    disabled:{' '}
                  </p>
                  <ul>
                    <li>Update "Requires Utensil"</li>
                    <li>Edit Order Lines</li>
                  </ul>
                </Alert>
              </Col>
            )}

            <Col
              xs={12}
              className="d-flex justify-content-between align-item-center"
            >
              <ReadOnly
                name={'created_at'}
                label={'Created At'}
                value={order?.created_at}
                render={(value) => <Datetime value={value} />}
              />
            </Col>

            <Col xs={12}>
              <OrderStatusSelect
                label="Status"
                value={order?.status}
                onChange={handleStatusOnChange}
                isLoading={isStatusLoading}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <FormManager
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={handleUpdateOrder}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12} lg={4}>
                      <ReadOnlyField
                        name="user"
                        label="Customer Name"
                        render={(user) => (
                          <div className="d-flex align-items-center">
                            <FullName user={user} />
                            <UserBadges data={user} />
                          </div>
                        )}
                      />
                    </Col>

                    <Col xs={12} lg={4}>
                      <ReadOnlyField
                        name="user"
                        label="Customer Email"
                        render={(user) => (
                          <Link
                            to={`${routePaths.USERS}/${user.user_id}`}
                          >
                            <Email user={user} />
                          </Link>
                        )}
                      />
                    </Col>

                    <Col xs={12} lg={4}>
                      <ReadOnlyField
                        name="user"
                        label="Customer Phone Number"
                        render={(user) => <PhoneNumber user={user} />}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Row>
                <Col xs={12}>
                  <Addresses
                    order={order}
                    onSubmit={_updateOrderAddresses}
                  />
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Tabs
                    defaultActiveKey={DELIVERY_METHODS.DOORSTEP}
                    activeKey={deliveryMethod}
                    onSelect={(tab) => setDeliveryMethod(tab)}
                  >
                    <Tab
                      eventKey={DELIVERY_METHODS.DOORSTEP}
                      title="Doorstep Delivery"
                    >
                      <div className="py-4">
                        <Row>
                          <Col xs={12}>
                            {!hasCollectionPoint && (
                              <p>
                                Original Delivery Date Time: <br />
                                <DeliverySlotInfo
                                  deliverySlot={datetime(
                                    defaultDeliveryDateTime,
                                  )}
                                />
                              </p>
                            )}
                            <DeliveryDateTimeField
                              name="delivery_datetime"
                              label="Delivery Date-time"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Tab>
                    <Tab
                      eventKey={DELIVERY_METHODS.CLICK_AND_COLLECT}
                      title="Click & Collect"
                    >
                      <div className="py-4">
                        {hasCollectionPoint && (
                          <p>
                            Original Collection Point & Slot: <br />
                            <DeliverySlotInfo
                              deliverySlot={datetime(
                                defaultDeliveryDateTime,
                              )}
                            />{' '}
                            at <b>{order?.collection_point?.name}</b>
                          </p>
                        )}
                        <SelectCollectionPoint
                          value={collectionPoint?.id}
                          onChange={setCollectionPoint}
                        />

                        {collectionPoint && (
                          <SelectCollectionPointSlot
                            collectionPoint={collectionPoint?.id}
                            value={collectionPointSlot}
                            onChange={setCollectionPointSlot}
                            onFetching={setIsFetchingCollectionSlot}
                          />
                        )}
                      </div>
                    </Tab>
                  </Tabs>
                  <Row>
                    <Col xs={12} className="mb-3">
                      <CheckboxField
                        name="has_utensil"
                        label="Requires Utensil"
                        helperText="Required Save Order Details for update the Total Summary"
                        disabled={isOrderLegacy(order)}
                      />
                    </Col>

                    {!isCollectionPointSelected && (
                      <Col xs={12}>
                        <TextField
                          type="textarea"
                          name="notes"
                          label="Order Notes"
                        />
                      </Col>
                    )}

                    <Col xs={12}>
                      <Button
                        type="submit"
                        loading={isSubmitting}
                        disabled={
                          isCollectionPointSelected &&
                          isFetchingCollectionSlot
                        }
                      >
                        Save Order Details
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          );
        }}
      </FormManager>
    </>
  );
};

ManageOrderDetails.propTypes = {
  order: OrderPropType.isRequired,
  onSubmit: PropTypes.func,
};

ManageOrderDetails.defaultProps = {
  onSubmit: () => {},
};

export default ManageOrderDetails;
