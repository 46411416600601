import { useListCRUD } from 'lib/swr-crud';
import httpClient, { getResponseData } from 'lib/httpClient';
import { omitBy, isEmpty } from 'lib/javascript';

const fetchers = {
  read: async (url, query) => {
    try {
      const response = await httpClient({
        method: 'get',
        url,
        query,
      });

      return getResponseData(response);
    } catch (error) {
      throw error;
    }
  },
};

const useCollections = ({
  page,
  sizePerPage,
  sortField,
  sortDir,
} = {}) => {
  const url = '/cms/collections';

  const { data, error, create, isLoading, mutate } = useListCRUD(
    url,
    'id',
    {
      fetchers,
      query: {
        page,
        per_page: sizePerPage,
        sort_field: sortField,
        sort_dir: sortDir,
      },
    },
  );

  const collections = data?.data || [];
  const total = data?.meta?.total;

  const _mutate = (updater) =>
    mutate((data) => ({
      ...data,
      data: updater(data.data),
    }));

  return {
    collections,
    total,
    create,
    error,
    isLoading,
    mutate: _mutate,
  };
};

export default useCollections;
