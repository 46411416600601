import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { routePaths } from 'appConfig';
import { Page } from 'components/Common';
import notification from 'lib/notification';
import { useCategories, CategoryForm } from 'lib/productCategory';

const NewCategoryPage = () => {
  const { create } = useCategories();

  const history = useHistory();

  const handleOnSubmit = async (formValues, actions) => {
    try {
      const { image, ...category } = formValues;

      await create(category);

      notification.success('Successfully created category');
      history.push(routePaths.CATEGORIES);
      return;
    } catch (error) {
      actions.setErrors(error.errors);
      actions.setStatus({
        error: error.errors?.message || 'Error creating category',
      });
      return;
    }
  };

  return (
    <Page
      content={
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <CategoryForm
                  onSubmit={handleOnSubmit}
                  buttonText="Create"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      }
    />
  );
};

export default NewCategoryPage;
