import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Common';

import notification from 'lib/notification';

import { CouponPropType } from '../typedef';
import Coupon from '../Coupon';

const AppliedCoupons = ({ discounts, onRemoveCoupon }) => {
  const [
    removingCouponCodes,
    setRemovingCouponCodes,
  ] = React.useState([]);

  const hasCouponApplied = discounts?.coupons?.length > 0;

  if (!hasCouponApplied) return null;

  const getAppliedCouponErrorMessage = () => {
    if (!hasCouponApplied || discounts.is_valid) return null;

    return (
      discounts.coupons[0]?.custom_error_message || // if no custom error message, return validation error message
      discounts?.reject_reason?.message
    );
  };

  const isCouponCodeBeingRemoved = (couponCode) =>
    removingCouponCodes.includes(couponCode);

  const setIsRemovingCouponCode = (couponCode, isRemoving) => {
    setRemovingCouponCodes((removingCouponCodes) => {
      if (isRemoving) return [...removingCouponCodes, couponCode];

      return removingCouponCodes.filter(
        (removingCouponCode) => removingCouponCode !== couponCode,
      );
    });
  };

  const handleRemoveCoupon = async (coupon) => {
    const { coupon_code: couponCode } = coupon;

    try {
      setIsRemovingCouponCode(couponCode, true);

      await onRemoveCoupon(couponCode);
    } catch (err) {
      notification.error('Error removing coupon');
    } finally {
      setIsRemovingCouponCode(couponCode, false);
    }
  };

  return (
    <div>
      {discounts.coupons.map((coupon) => (
        <Coupon
          key={coupon.coupon_code}
          couponCode={coupon.coupon_code}
          rightRender={
            <Button
              variant="link"
              loading={isCouponCodeBeingRemoved(coupon.coupon_code)}
              onClick={() => handleRemoveCoupon(coupon)}
            >
              Remove
            </Button>
          }
        />
      ))}
      <small style={{ color: 'red' }}>
        {getAppliedCouponErrorMessage()}
      </small>
    </div>
  );
};

AppliedCoupons.propTypes = {
  discounts: PropTypes.shape({
    coupons: PropTypes.arrayOf(CouponPropType),
    is_valid: PropTypes.bool,
    reject_reason: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.shape({
        error_code: PropTypes.string,
        message: PropTypes.string,
      }),
    ]),
    delivery_discount: PropTypes.number,
    sub_total_discount: PropTypes.number,
  }),
  isRemovingCoupon: PropTypes.bool,
  onRemoveCoupon: PropTypes.func,
};

AppliedCoupons.defaultProps = {
  coupons: [],
  isRemovingCoupon: false,
  onRemoveCoupon: () => {},
};

export default AppliedCoupons;
