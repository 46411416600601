import React from 'react';
import { TagsInput, Button } from 'components/Common';

import { Container, Row, Col } from 'react-bootstrap';
import yup from 'lib/validator';
import {
  FormManager,
  Form,
  GeneralError,
  FieldArray,
  connectInput,
} from 'lib/form';
import useProduct from 'lib/product/useProduct';
import notification from 'lib/notification';
import { useConfirmation } from 'lib/confirm';
import { TextField } from 'components/formFields';

import * as api from '../api';
import VariantsCombinator from './VariantsCombinator';

const initialValues = {
  options: [
    {
      label: '',
      options: [],
    },
  ],
  variants: [],
};

const validationSchema = yup.object({
  options: yup
    .array()
    .of(
      yup.object({
        label: yup.string().required(),
        options: yup.array().of(yup.string()).min(1).required(),
      }),
    )
    .min(1)
    .required(),
  variants: yup
    .array()
    .of(
      yup.object({
        price: yup.number().required(),
        sku: yup.string().required(),
        stock: yup.number().required(),
        alias: yup.string().required(),
      }),
    )
    .min(1)
    .required(),
});

const TagsField = connectInput(TagsInput, {
  onChangeArgType: 'raw-value',
});

const SetupVariant = ({ productId, onSubmit }) => {
  const { mutate } = useProduct(productId);
  const { confirm } = useConfirmation();

  const mapOptions = (options) =>
    options.map((option, index) => ({
      ...option,
      position: index + 1,
      options: option.options.map((optionValue, index) => ({
        position: index + 1,
        value: optionValue,
      })),
    }));

  const handleOnSubmit = async (formValues, actions) => {
    try {
      await confirm({
        title: 'Are you sure to create these variants?',
        content:
          'You may only create variants once. Please double confirm that these variants are correct.',
        buttonLabels: {
          yes: "Yes, I'm sure",
          no: 'Cancel',
        },
      });
    } catch (err) {
      return Promise.reject();
    }

    try {
      const { options, variants } = formValues;

      const { response, error } = await api.createProductVariants({
        productId,
        options: mapOptions(options),
        variants,
      });

      if (error) throw error;

      mutate(response.data);
      notification.success('Successfully created variants');

      onSubmit(null, response);

      return;
    } catch (error) {
      actions.setErrors(error.errors);
      actions.setStatus({
        error: error.errors?.message || 'Error creating variants',
      });

      onSubmit(error, null);

      return;
    }
  };

  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form>
            <GeneralError />
            <VariantsCombinator />
            <Container>
              <Row>
                <Col>
                  <h5>Options</h5>
                </Col>
              </Row>
              <FieldArray name="options">
                {({ push, remove }) => (
                  <React.Fragment>
                    {values.options.map((option, index) => (
                      <Row
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Col>
                          <TextField
                            label="Label"
                            name={`options[${index}].label`}
                          />
                        </Col>
                        <Col>
                          <TagsField
                            label="Values"
                            name={`options[${index}].options`}
                          />
                        </Col>
                        {values.options.length !== 1 && (
                          <Col xs={1}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </Button>
                          </Col>
                        )}
                      </Row>
                    ))}

                    <Row>
                      <Col>
                        <Button
                          type="button"
                          variant="outlined"
                          color="secondary"
                          onClick={() =>
                            push({ label: '', options: [] })
                          }
                        >
                          Add More Options
                        </Button>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </FieldArray>

              <hr />

              <Row style={{ marginTop: 24 }}>
                <Col>
                  <h5>Variants</h5>
                </Col>
              </Row>
              {values.variants?.length === 0 && (
                <p>
                  No variants yet, try adding some options to see
                  variants.{' '}
                </p>
              )}

              <FieldArray name="variants">
                {() =>
                  values.variants.map((variant, index) => (
                    <React.Fragment>
                      <Row
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Col>
                          <p>
                            {Object.values(variant.options).join(
                              ', ',
                            )}
                          </p>
                        </Col>
                        <Col>
                          <TextField
                            type="number"
                            label="Price"
                            name={`variants[${index}].price`}
                          />
                        </Col>
                        <Col>
                          <TextField
                            label="SKU"
                            name={`variants[${index}].sku`}
                          />
                        </Col>
                        <Col>
                          <TextField
                            type="number"
                            label="Stock"
                            name={`variants[${index}].stock`}
                          />
                        </Col>
                        <Col>
                          <TextField
                            type="alias"
                            label="Alias"
                            name={`variants[${index}].alias`}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  ))
                }
              </FieldArray>
              <Button type="submit" loading={isSubmitting}>
                Create Variants
              </Button>
            </Container>
          </Form>
        );
      }}
    </FormManager>
  );
};

SetupVariant.propTypes = {};

export default SetupVariant;
