import PropTypes from 'prop-types';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
);

const MediaUploadInputOffline = ({ onChange, onError, ...props }) => {
  const handleUpdateFiles = (fileItems) => {
    const filesWithUrls = fileItems.map((fileItem) => ({
      file: fileItem.file,
      url: URL.createObjectURL(fileItem.file),
    }));
    if (onChange) {
      onChange(filesWithUrls);
    }
  };

  return (
    <FilePond
      {...props}
      onupdatefiles={handleUpdateFiles}
      acceptedFileTypes={['image/png', 'image/jpeg']}
      allowFileSizeValidation
      maxFileSize="3MB"
      onError={onError}
    />
  );
};

MediaUploadInputOffline.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

export default MediaUploadInputOffline;
