import React from 'react';
import PropTypes from 'prop-types';

import { isString, isArray } from 'lib/javascript';

import useFormContext from './useFormContext';

const FieldArrayError = ({ name }) => {
  const { errors, touched } = useFormContext();

  const shouldShowError = errors[name] && touched[name];

  if (!shouldShowError) return null;

  if (isString(errors[name]))
    return <p style={{ color: 'red' }}>{errors[name]}</p>;

  if (isArray(errors[name]) && isString(errors[name][0]))
    return <p style={{ color: 'red' }}>{errors[name][0]}</p>;

  return null;
};

FieldArrayError.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FieldArrayError;
