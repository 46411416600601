import * as productVariants from 'lib/productVariants';

export const getTotalStock = (product) => {
  console.log(
    '🚀 ~ file: utils.js ~ line 4 ~ getTotalStock ~ product',
    product,
  );
  if (!product) return 0;

  if (productVariants.utils.hasProductVariants(product))
    return product.variants.reduce((result, variant) => {
      console.log(
        '🚀 ~ file: utils.js ~ line 9 ~ returnproduct.variants.reduce ~ variant',
        variant,
      );
      result += variant.stock;

      return result;
    }, 0);

  return product?.variants?.[0].stock;
};

export const createTab = () => ({
  label: '',
  content: null,
});
