import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { List } from 'react-content-loader';

import { Button, Page } from 'components/Common';
import notification from 'lib/notification';
import { useHistory, useParams } from 'react-router';
import {
  AddonOptionForm,
  useAddonOption,
  AddonOptionValueSection,
} from 'lib/addon-options';
import { useConfirmation } from 'lib/confirm';
import { routePaths } from 'appConfig';

const AddonOptionDetailsPage = () => {
  const { confirm } = useConfirmation();

  const history = useHistory();
  const { addonOptionId } = useParams();
  const {
    isLoading,
    addonOption,
    update,
    _delete,
    addValue,
    updateValue,
    deleteValue,
    reorderValue,
    mutate,
  } = useAddonOption(addonOptionId);
  const [isDeleting, setDeleting] = useState(false);

  const onUpdate = async (values, actions) => {
    try {
      const data = await update(values);
      mutate(data);
      notification.success('Successfully update Addon Option');
    } catch (error) {
      actions.setErrors(error.errors);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const onDelete = async () => {
    await confirm();

    try {
      setDeleting(true);
      await _delete();
      history.replace(routePaths.ADDON_OPTIONS);
      notification.success('Successfully delete Addon Option');
    } catch (error) {
      notification.error(error?.errors?.message);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Page
      content={
        <React.Fragment>
          {isLoading ? (
            <List uniqueKey="addon-option-loading" />
          ) : (
            <>
              <Card>
                <CardBody>
                  <Row>
                    <Col
                      xs={12}
                      className="d-flex justify-content-end align-item-center"
                    >
                      <div>
                        <Button
                          color={'danger'}
                          onClick={onDelete}
                          loading={isDeleting}
                        >
                          Delete Addon Option
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <AddonOptionForm
                        onSubmit={onUpdate}
                        initialValues={addonOption}
                        buttonText="Update"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h4>Option Values</h4>
                    </Col>
                    <Col xs={12}>
                      <AddonOptionValueSection
                        addonOption={addonOption}
                        onAdd={addValue}
                        onUpdate={updateValue}
                        onDelete={deleteValue}
                        onReorder={reorderValue}
                        mutate={mutate}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}
        </React.Fragment>
      }
    />
  );
};

export default AddonOptionDetailsPage;
