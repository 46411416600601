import { useCRUD } from 'lib/swr-crud';
import {
  deleteCollectionPoint,
  getCollectionPoint,
  putCollectionPoint,
} from './api';
import { CollectionPoint } from './types';

const useCollectionPoint = (collectionPointId: string) => {
  const { data, isLoading, mutate, error } = useCRUD(
    `/cms/collection_points/${collectionPointId}`,
    async () => {
      return getCollectionPoint(collectionPointId);
    },
  );

  const collectionPoint: CollectionPoint | null = data;

  const updateCollectionPoint = (collectionPoint: CollectionPoint) => {
    return putCollectionPoint(collectionPointId, collectionPoint);
  };

  const removeCollectionPoint = () => {
    return deleteCollectionPoint(collectionPointId);
  };

  return {
    collectionPoint,
    updateCollectionPoint,
    removeCollectionPoint,
    isLoading,
    mutate,
    error,
  };
}

export default useCollectionPoint;
