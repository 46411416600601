import React, { useState } from 'react';

interface ImagePreviewProps {
  images: string[];
  show: boolean;
  closePreview: () => void;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({
  images,
  show,
  closePreview,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  React.useEffect(() => {
    if (show) {
      setCurrentIndex(0);
    }
  }, [show]);

  if (!show) return null;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    );
  };

  const handleThumbnailClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1050,
      }}
      className="d-flex flex-column justify-content-center align-items-center"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          closePreview();
        }
      }}
    >
      {images.length > 0 && (
        <div
          style={{
            maxWidth: '80%',
            maxHeight: '80%',
            marginBottom: '100px',
          }}
        >
          <img
            src={images[currentIndex]}
            alt={`Preview ${currentIndex + 1}`}
            className="img-fluid"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      )}

      {images.length === 0 && (
        <span
          className="text-white font-size-18 p-2 rounded"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
        >
          No images available
        </span>
      )}

      {images.length > 0 && (
        <div
          className="d-flex justify-content-center"
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            overflowX: 'auto',
            gap: '10px',
            background: 'rgba(0, 0, 0, 0.7)',
            padding: '10px',
            borderRadius: '8px',
            maxWidth: '90%',
          }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className={`img-thumbnail ${
                index === currentIndex ? 'border-primary' : ''
              }`}
              style={{
                width: '60px',
                height: '60px',
                objectFit: 'cover',
                cursor: 'pointer',
                flexShrink: 0,
              }}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
      )}

      <button
        onClick={handlePrev}
        style={{
          position: 'fixed',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          background: 'rgba(0, 0, 0, 0.7)',
          border: 'none',
          borderRadius: '5px',
          padding: '5px',
          zIndex: 1060,
        }}
        className="text-white"
      >
        <i
          className="bx bx-chevron-left"
          style={{ fontSize: '50px' }}
        ></i>
      </button>

      <button
        onClick={handleNext}
        style={{
          position: 'fixed',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          background: 'rgba(0, 0, 0, 0.7)',
          border: 'none',
          borderRadius: '5px',
          padding: '5px',
          zIndex: 1060,
        }}
        className="text-white"
      >
        <i
          className="bx bx-chevron-right"
          style={{ fontSize: '50px' }}
        ></i>
      </button>

      <button
        onClick={closePreview}
        style={{
          position: 'fixed',
          top: '10px',
          right: '10px',
          background: 'rgba(0, 0, 0, 0.7)',
          border: 'none',
          borderRadius: '5px',
          padding: '5px',
          zIndex: 1070,
        }}
        className="text-white"
      >
        <i className="bx bx-x" style={{ fontSize: '30px' }}></i>
      </button>
    </div>
  );
};

export default ImagePreview;
