import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { List } from 'react-content-loader';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { routePaths } from 'appConfig';
import { Button, Tabs, Tab } from 'components/Common';
import notification from 'lib/notification';
import { useRouter } from 'lib/router';
import { useConfirmation } from 'lib/confirm';

import useIngredient from './useIngredient';
import IngredientForm from './IngredientForm';
import { IngredientNutritionForm } from 'lib/ingredientNutrition';

const TAB = {
  DETAIL: 'detail',
  NUTRITION: 'nutrition',
};

const IngredientDetails = () => {
  const { confirm } = useConfirmation();
  const history = useHistory();
  const { query, updateQueryParams } = useRouter();

  const { tab, ingredientId } = query;

  const [isDeleting, setDeleting] = useState(false);

  const {
    update,
    _delete,
    ingredient,
    isLoading,
    mutate,
  } = useIngredient(ingredientId);

  const onUpdate = async (values, actions) => {
    try {
      await update(values);
      notification.success('Successfully update Ingredient Details');
    } catch (error) {
      actions.setErrors(error.errors);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const onDelete = () => {
    confirm({
      title: 'To Delete Ingredient?',
      content: 'This action is irreversible. Are you sure?',
    }).then(async () => {
      try {
        setDeleting(true);
        await _delete();
        notification.success(
          'Successfully delete Ingredient Details',
        );
        history.replace(routePaths.INGREDIENTS);
      } catch (error) {
        notification.error(error?.errors?.message);
      } finally {
        setDeleting(false);
      }
    });
  };

  if (isLoading) return <List uniqueKey="ingredient-details" />;

  return (
    <React.Fragment>
      <Row style={{ marginBottom: '24px' }}>
        <Col
          xs={12}
          className="d-flex justify-content-end align-item-center"
        >
          <div>
            <Button
              color={'danger'}
              onClick={onDelete}
              loading={isDeleting}
            >
              Delete Ingredient
            </Button>
          </div>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Tabs
            activeKey={tab}
            onSelect={(tab) =>
              updateQueryParams({
                tab,
              })
            }
          >
            <Tab eventKey={TAB.DETAIL} title="Details">
              <div style={{ margin: '24px 0' }}>
                <IngredientForm
                  initialValues={ingredient}
                  onSubmit={onUpdate}
                  buttonTitle={'Update Ingredient'}
                />
              </div>
            </Tab>
            <Tab eventKey={TAB.NUTRITION} title="Nutritional Values">
              <div style={{ margin: '24px 0' }}>
                <IngredientNutritionForm
                  ingredientId={ingredientId}
                  initialValues={ingredient?.nutrition}
                  onSubmit={(err) => !err && mutate()}
                />
              </div>
            </Tab>
          </Tabs>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default IngredientDetails;
