import { Button } from 'components/Common';
import { TextField } from 'components/formFields';
import { Form, FormManager, GeneralError } from 'lib/form';
import validator from 'lib/validator';
import { Col, Row } from 'reactstrap';
import {
  ComplaintCategory,
  ComplaintCategoryModel,
  ComplaintCategoryPayload,
} from './types';

interface ComplaintCategoryFormProps {
  complaintCategory?: ComplaintCategory | null;
  onSubmit: (
    complaintCategory: ComplaintCategoryPayload,
  ) => Promise<void>;
  showCancel?: boolean;
  onCancel?: () => void;
}
const ComplaintCategoryForm = ({
  complaintCategory,
  onSubmit,
  onCancel,
  showCancel,
}: ComplaintCategoryFormProps) => {
  const defaultValue: ComplaintCategoryModel = {
    id: null,
    name: '',
  };

  const initialValues: ComplaintCategoryModel = {
    ...defaultValue,
    ...complaintCategory,
  };
  const validationSchema = validator.object({
    name: validator.string().required(),
  });

  const handleOnSubmit = async (
    payload: ComplaintCategoryPayload,
  ) => {
    await onSubmit(payload);
  };

  return (
    <FormManager
      initialValues={initialValues}
      validateOnBlur
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting, values }) => {
        return (
          <Form>
            <input
              type="hidden"
              value={initialValues.id ?? undefined}
            />
            <GeneralError />
            <Row>
              <Col>
                {/* @ts-ignore */}
                <TextField
                  name="name"
                  label="Name"
                  required
                  placeholder="Enter Name"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {showCancel && (
                  <>
                    {/* @ts-ignore */}
                    <Button
                      type="button"
                      variant="outlined"
                      color="dark"
                      onClick={onCancel}
                      disabled={isSubmitting}
                    >
                      <span>Cancel</span>
                    </Button>
                  </>
                )}
                {/* @ts-ignore */}
                <Button
                  type="submit"
                  className={showCancel && 'ml-2'}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  <span>{'Update Feedback Category'}</span>
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </FormManager>
  );
};

export default ComplaintCategoryForm;
