import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray as FormikFieldArray } from 'formik';

import FieldArrayError from './FieldArrayError';

const FieldArray = ({
  name,
  render,
  children,
  component,
  validateOnChange,
}) => {
  return (
    <React.Fragment>
      <FormikFieldArray
        name={name}
        render={render}
        component={component}
        validateOnChange={validateOnChange}
      >
        {children}
      </FormikFieldArray>
      <FieldArrayError name={name} />
    </React.Fragment>
  );
};

FieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  render: PropTypes.func,
  children: PropTypes.func,
  component: PropTypes.node,
  validateOnChange: PropTypes.bool,
};

export default FieldArray;
