import { DatePicker } from 'components/Common';
import { connectInput } from 'lib/form';
import { OrderStatusAutoComplete } from 'lib/orders';
import { ReportCategory, ReportError } from './types';

export const DatePickerField = connectInput(DatePicker, {
  onChangeArgType: 'raw-value',
});

export const OrderStatusAutoCompleteField = connectInput(
  OrderStatusAutoComplete,
  {
    onChangeArgType: 'raw-value',
  },
);

export const getSuccessMessage = (reportCategory: ReportCategory): string => {
  return `We will send you your ${reportCategory} kitchen reports via email in just a few minutes. In the meantime, please take a moment to sit back and relax!`;
};

export const getErrorMessage = (err: ReportError): string => {
  return err?.errors?.message || 'Something went wrong when send reports.'
};
