import { Button } from 'components/Common';
import { TextField } from 'components/formFields';
import { Form, FormManager } from 'lib/form';
import validator from 'lib/validator';
import { Col, Row } from 'reactstrap';
import { ComplaintCategoryPayload } from './types';

interface AddComplaintCategoryFormProps {
  onSubmit: (
    complaintCategory: ComplaintCategoryPayload,
  ) => Promise<void>;
}

const AddComplaintCategoryForm = ({
  onSubmit,
}: AddComplaintCategoryFormProps) => {
  const initialValues = {
    name: '',
  };

  const validationSchema = validator.object({
    name: validator.string().required(),
  });

  const handleOnSubmit = async (
    payload: ComplaintCategoryPayload,
  ) => {
    await onSubmit(payload);
  };

  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
      validateOnBlur
    >
      {({ isSubmitting, values }) => {
        return (
          <Form>
            <Row>
              <Col>
                {/* @ts-ignore */}
                <TextField
                  name="name"
                  label="Name"
                  required
                  placeholder="Enter Name"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* @ts-ignore */}
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  <span>{'Add Feedback Category'}</span>
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </FormManager>
  );
};

export default AddComplaintCategoryForm;
