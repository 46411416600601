import { useCRUD } from 'lib/swr-crud';
import { deleteDonation, getDonationById, putUpdateDonation } from './api';

const useDonation = (donationId) => {
  const { data, error, mutate, isLoading } = useCRUD(
    `/cms/events/donations/${donationId}`,
    async () => {
      return getDonationById(donationId);
    },
  );

  const donation = data?.data || null;

  const update = async (body) => {
    const data = await putUpdateDonation(donationId, body);
    mutate(data);
    return data;
  };

  const _delete = async () => {
    await deleteDonation(donationId);
  };

  return {
    donation,
    error,
    mutate,
    update,
    _delete,
    isLoading
  };
};

export default useDonation;
