import React from 'react';
import BadgeRenderer from './BadgeRenderer';
import {
  CompletedOrdersCountBadge,
  TotalComplaintCountBadge,
} from 'lib/orders';
import { User } from './types';
import TierBadge from './badges/TierBadge';
import { every } from 'lodash';

interface UserBadgesProps {
  data: User | null;
  className?: string;
  style?: React.CSSProperties;
}
const isArrayAllFalse = (
  arr: Array<number | string | boolean | null>,
) => {
  return every(arr, (item) => !item);
};

const UserBadges: React.FC<UserBadgesProps> = ({
  data,
  className,
  style,
}) => {
  if (!data) {
    return <></>;
  }

  if (
    isArrayAllFalse([
      data.completed_orders_count,
      data?.complaint_count,
      data?.tier,
    ])
  ) {
    return <></>;
  }

  return (
    <div
      className={`${className} d-flex align-items-start`}
      style={style}
    >
      <BadgeRenderer
        className="ml-1 mb-1"
        BadgeComponent={CompletedOrdersCountBadge}
        value={data.completed_orders_count}
      />
      <BadgeRenderer
        className="ml-1 mb-1"
        BadgeComponent={TotalComplaintCountBadge}
        value={data?.complaint_count}
      />

      <BadgeRenderer
        className="ml-1 mb-1"
        BadgeComponent={TierBadge}
        value={data?.tier}
      />
    </div>
  );
};

export default UserBadges;
