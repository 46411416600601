export const WINDOW_STORAGE_EVENT = 'storage';

export const GET_ITEM_FROM_SYNCED_SESSION_STORAGE =
  'GET_ITEM_FROM_SYNCED_SESSION_STORAGE';

export const SET_ITEM_TO_SYNCED_SESSION_STORAGE =
  'SET_ITEM_TO_SYNCED_SESSION_STORAGE';

export const REMOVE_ITEM_FROM_SYNCED_SESSION_STORAGE =
  'REMOVE_ITEM_FROM_SYNCED_SESSION_STORAGE';

export const CLEAR_SYNCED_SESSION_STORAGE =
  'CLEAR_SYNCED_SESSION_STORAGE';
