export const USER_GOAL = {
  BUILD_MUSCLE: 'Build Muscle',
  LOSE_WEIGHT: 'Lose Weight',
  STAY_LEAN: 'Stay Lean or Maintain Weight',
  FUEL_SPORT: 'Fuel My Sport',
  EAT_HEALTHY: 'Eat Healthy',
};

export const USER_ROLE = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  CUSTOMER_SERVICE: 'customer-service',
  EXECUTIVE_ASSISTANT: 'executive-assistant',
  AFFILIATE: 'affiliate',
  KITCHEN_REPRESENTATIVE_MAIN: 'kitchen-rep-main',
  KITCHEN_REPRESENTATIVE_OTHER: 'kitchen-rep-other',
};


export const CMSRoles = [
  USER_ROLE.ADMIN,
  USER_ROLE.CUSTOMER_SERVICE,
  USER_ROLE.EXECUTIVE_ASSISTANT,
];

export const KitchenRoles = [
  USER_ROLE.KITCHEN_REPRESENTATIVE_MAIN,
  USER_ROLE.KITCHEN_REPRESENTATIVE_OTHER,
];