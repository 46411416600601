import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { routePaths } from 'appConfig';
import { Page, Button } from 'components/Common';
import { useRouter } from 'lib/router';
import { useHistory } from 'react-router';
import { CollectionsTable, useCollections } from 'lib/collection';

const OrdersPage = () => {
  const history = useHistory();
  const { query, updateQueryParams } = useRouter();

  const {
    page = 1,
    sizePerPage = 15,
    sortField = 'created_at',
    sortDir = 'desc',
  } = query;

  const { isLoading, collections, total, mutate } = useCollections({
    page,
    sizePerPage,
    sortField,
    sortDir,
  });

  const handleOnTableChange = (action, newState) => {
    switch (action) {
      case 'pagination':
        updateQueryParams({
          page: newState.page,
          sizePerPage: newState.sizePerPage,
        });
        break;

      case 'filter':
        // const mappedFilters = mapValues(
        //   newState.filters,
        //   (filter) => filter.filterVal,
        // );

        // const orderNumber = mappedFilters['order_number'];
        // const status = mappedFilters['status'];
        // const customerId = mappedFilters['user.user_id'];

        // setOrderNumber(orderNumber);
        // setStatus(status);
        // setCustomerId(customerId);

        break;

      case 'sort':
        const { sortField, sortOrder } = newState;

        updateQueryParams({
          sortField,
          sortDir: sortOrder,
        });

        break;

      default:
    }
  };

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <div className="d-flex justify-content-end mb-2">
              <Button
                onClick={() => {
                  history.push(`${routePaths.NEW_COLLECTION}`);
                }}
              >
                Create New Collection
              </Button>
            </div>
            <CollectionsTable
              isLoading={isLoading}
              data={collections}
              page={parseInt(page)}
              sizePerPage={parseInt(sizePerPage)}
              totalSize={total}
              onTableChange={handleOnTableChange}
              getPathToDetailsPage={(collection) =>
                `${routePaths.COLLECTIONS}/${collection.id}`
              }
              sortDir={sortDir}
              sortField={sortField}
              mutateCollections={mutate}
            />
          </CardBody>
        </Card>
      }
    />
  );
};

OrdersPage.propTypes = {};

export default OrdersPage;
