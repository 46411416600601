import { useCRUD } from 'lib/swr-crud';
import {
  getCouponByCouponCode,
  putUpdateCoupon,
  deleteCoupon,
} from './api';

const useCoupon = (couponCode) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/cms/coupons/${couponCode}`,
    async () => {
      return getCouponByCouponCode(couponCode);
    },
  );

  const coupon = data || null;

  const update = async (body) => {
    const data = await putUpdateCoupon(couponCode, body);
    mutate(data);
    return data;
  };

  const _delete = async () => {
    await deleteCoupon(couponCode);
  };

  return {
    coupon,
    error,
    mutate,
    update,
    _delete,
    isValidating,
    isLoading,
  };
};

export default useCoupon;
