import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Card } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';

import { useConfirmation } from 'lib/confirm';
import { FieldArray, Field, useField } from 'lib/form';
import { RichTextEditor } from 'lib/rich-text-editor';
import { Button, IconButton, Label } from 'components/Common';
import { TextField } from 'components/formFields';

import { createTab } from './utils';
import { Product } from './typedef';

const TabContentFieldset = ({ name, product }) => {
  const { confirm } = useConfirmation();
  const [field] = useField(name);
  const tabs = field.value || [];

  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <React.Fragment>
          {tabs.map((_tab, index) => (
            <Row>
              <Col>
                <Card style={{ border: '1px #ced4da solid' }}>
                  <Card.Body>
                    <Row>
                      <Col sm={12} md={11}>
                        <TextField
                          name={`${name}[${index}].label`}
                          label="Label"
                          required
                        />
                        <FormGroup>
                          <Label>Content</Label>
                          <Field name={`${name}[${index}].content`}>
                            {({ field, form }) => (
                              <RichTextEditor
                                id={field.name}
                                mediaPickerModalProps={{
                                  parent: 'product-tab-content',
                                  parentId: product.id,
                                  availableAssets: product.assets,
                                }}
                                initialValue={field.value}
                                onChange={(newValue) =>
                                  form.setFieldValue(
                                    field.name,
                                    newValue,
                                  )
                                }
                              />
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={1}>
                        <IconButton
                          color="danger"
                          onClick={() =>
                            confirm({
                              title: 'Remove this tab? ',
                            }).then(() => remove(index))
                          }
                        >
                          <MdDelete />
                        </IconButton>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))}
          <Row>
            <Col>
              <Button
                color="secondary"
                onClick={() => push(createTab())}
                block
              >
                Add Tab
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </FieldArray>
  );
};

TabContentFieldset.propTypes = {
  name: PropTypes.string.isRequired,
  product: Product,
};

export default TabContentFieldset;
