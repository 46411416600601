export const MAX_LENGTHS = {
  NAME: 50,
  EXCERPT: 200,
  SHORT_DESCRIPTION: 1000,
};

export const TAB_IDS = {
  BASIC_DETAILS: 'basic-details',
  TAB_CONTENT: 'tab-content',
  ASSOCIATIONS: 'associations',
  VARIANTS: 'variants',
  INVENTORY: 'inventory',
  NUTRITION: 'nutrition',
  ADDONS: 'addons',
};
