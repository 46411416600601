import useSWR, { SWRConfiguration } from 'swr';
import { Key, MutatorCallback } from 'swr/dist/types';

export type KeyedMutator<D> = (
  data?: D | Promise<D> | MutatorCallback<D>,
  shouldRevalidate?: boolean,
) => Promise<D | undefined>;

export const useGet = <R extends unknown, E extends unknown>(
  key: Key,
  fetcher: Fetcher<R>,
  options: SWRConfiguration<R, E> = {},
): ResponseUseGet<R, E> => {
  const { data, error, isValidating, mutate } = useSWR<R, E>(
    key,
    fetcher,
    options,
  );

  const isLoading = !data && !error;

  return {
    data,
    error,
    isValidating,
    mutate,
    isLoading,
  };
};

type ResponseUseGet<R, E> = {
  data?: R;
  error?: E;
  isValidating: boolean;
  mutate: KeyedMutator<R>;
  isLoading: boolean;
};

type Fetcher<R extends unknown> = (key: string) => Promise<R>;
