import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Common';

const PublishButton = ({
  beforePublish,
  onPublish,
  isPublished,
  publishRequest,
  unPublishRequest,
}) => {
  const [isPublishing, setPublishing] = React.useState(false);

  const color = isPublished ? 'secondary' : 'primary';
  let label = isPublished ? 'Unpublish' : 'Publish';
  if (isPublishing) label += 'ing';
  const icon = isPublished ? 'fa fa-eye-slash' : 'fa fa-eye';
  const request = isPublished ? unPublishRequest : publishRequest;
  const disabled = isPublishing;

  return (
    <Button
      color={color}
      onClick={async () => {
        try {
          if (beforePublish) await beforePublish();

          setPublishing(true);

          const response = await request();

          onPublish(!isPublished, response);
        } catch (err) {
          console.log('err', err);
        } finally {
          setPublishing(false);
        }
      }}
      disabled={disabled}
    >
      <i className={`${icon} mr-2`}></i>
      {label}
    </Button>
  );
};

PublishButton.propTypes = {
  /** Must return a promise. Resolve to continue or reject to abort the publish/unpublish request. Useful for confirmation */
  beforePublish: PropTypes.func,
  /** Hook after publish/unpublish request. Accepts 2 arguments: a boolean, true if the request was publish, false if the request was unpublish; and response of the publish/unpublish request. Can use to update the isPublished state. Signature: (wasRequestPublish, response) => void */
  onPublish: PropTypes.func,
  /** Boolean to specify if it's currently published */
  isPublished: PropTypes.bool.isRequired,
  /** the API request function to publish */
  publishRequest: PropTypes.func.isRequired,
  /** the API request function to unpublish */
  unPublishRequest: PropTypes.func.isRequired,
};

PublishButton.defaultProps = {
  beforePublish: undefined,
  onPublish: () => {},
};

export default PublishButton;
