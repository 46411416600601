import {
  GET_AUTH_USER,
  GET_AUTH_USER_SUCCESS,
  GET_AUTH_USER_ERROR,
} from './actionTypes';

const initialState = {
  authUser: null,
  loading: false,
  error: null,
};

const getAuthUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUTH_USER:
      return { ...state, loading: true };
    case GET_AUTH_USER_SUCCESS:
      return { ...state, loading: false, authUser: action.payload };
    case GET_AUTH_USER_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default getAuthUserReducer;
