import { ChangeEvent, useEffect } from 'react';
import type { CollectionPoint } from './types';
import useCollectionPoints from './useCollectionPoints';
import { Select, Skeleton } from 'components/Common';

interface SelectCollectionPointProps {
  value?: string | null;
  onChange: (collectionPoint?: CollectionPoint | null) => void;
}

const SelectCollectionPoint = ({
  value,
  onChange,
}: SelectCollectionPointProps) => {
  const { collectionPoints, isLoading } = useCollectionPoints();

  const handleOnChange = (collectionPointId: string) => {
    const collectionPoint = collectionPoints.find(({ id }) => {
      return String(id) === collectionPointId;
    });

    onChange(collectionPoint);
  };

  const getCollectionPointOptions = (): Array<{
    value: string;
    label: string;
  }> => {
    return collectionPoints.map(({ id, name }) => ({
      value: String(id),
      label: name,
    }));
  };

  useEffect(() => {
    if (isLoading || !collectionPoints.length) return;

    if (!value) {
      onChange(collectionPoints[0]);
    } else {
      const collectionPoint = collectionPoints.find(({ id }) => {
        return String(id) === value;
      });

      if (!collectionPoint) return;

      onChange(collectionPoint);
    }
  }, [collectionPoints, isLoading, onChange, value]);

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-w-full">
        {isLoading ? (
          // @ts-ignore
          <Skeleton
            type="line"
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
          />
        ) : (
          <Select
            label="Collection Point"
            options={getCollectionPointOptions()}
            disabled={isLoading}
            value={value}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              handleOnChange(e.target.value)
            }
          />
        )}
      </div>
    </div>
  );
};

export default SelectCollectionPoint;
