import { Datetime } from 'lib/datetime';
import { CatalogueItem } from './types';
import { Icon, IconButton, SortableTable } from 'components/Common';
import { useState } from 'react';
import { useConfirmation } from 'lib/confirm';
import {
  Column,
  DragHandler,
  SortableItem,
} from 'components/Common/SortableTable';

interface CatalogueItemsTableProps {
  data: CatalogueItem[];
  onSelectedItem: (id: string | number) => void;
  onDelete: (id: string | number) => Promise<void>;
  onSort: (
    changedId?: string | number,
    beforeId?: string | number | null,
  ) => Promise<void>;
}

const CatalogueItemsTable = ({
  data,
  onSelectedItem,
  onDelete,
  onSort,
}: CatalogueItemsTableProps) => {
  const { confirm } = useConfirmation();
  const [loadingItemIds, setLoadingItemIds] = useState<number[]>([]);

  const isLoadingItem = (id: number) => loadingItemIds.includes(id);
  const addLoadingItem = async (id: number) => {
    return new Promise((resolve) => {
      setLoadingItemIds((prev) => {
        const updatedIds = [...prev, id];
        resolve(updatedIds);
        return updatedIds;
      });
    });
  };

  const removeLoadingItem = async (id: number) => {
    return new Promise((resolve) => {
      setLoadingItemIds((prev) => {
        const updatedIds = prev.filter((item) => item !== id);
        resolve(updatedIds);
        return updatedIds;
      });
    });
  };

  const _onDelete = async (id: number) => {
    await confirm();

    try {
      await addLoadingItem(id);
      await onDelete(id);
    } catch (err) {
      throw err;
    } finally {
      await removeLoadingItem(id);
    }
  };

  const _onSort = async (
    changedData: CatalogueItem,
    beforeData: CatalogueItem | null,
  ) => {
    try {
      addLoadingItem(changedData?.id as number);

      const beforeId = beforeData ? beforeData.id : null;
      await onSort(changedData?.id ?? 0, beforeId);
    } catch (err) {
      throw err;
    } finally {
      removeLoadingItem(changedData?.id as number);
    }
  };

  const columns: Column<CatalogueItem>[] = [
    {
      dataField: 'sortable',
      formatter: (_cell, _row) => <DragHandler />,
    },
    {
      dataField: 'title',
      text: 'Title',
      classes: 'align-middle',
    },
    {
      dataField: 'created_at',
      text: 'Date',
      classes: 'align-middle',
      formatter: (createdAt: string) => (
        <Datetime value={createdAt} />
      ),
    },
    {
      dataField: 'id',
      text: 'Actions',
      formatter: (id: number) => (
        <div className="d-flex">
          {/* @ts-ignore */}
          <IconButton
            onClick={() => onSelectedItem(id)}
            loading={isLoadingItem(id)}
            disabled={isLoadingItem(id)}
            color="info"
            className="mr-2"
          >
            <Icon name="pencil" />
          </IconButton>

          {/* @ts-ignore */}
          <IconButton
            onClick={() => _onDelete(id)}
            loading={isLoadingItem(id)}
            disabled={isLoadingItem(id)}
            color="danger"
          >
            <Icon name="delete" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <SortableTable<CatalogueItem>
      columns={columns}
      dataSource={data}
      onSort={_onSort}
      renderSortableItem={(children, row, index) => (
        // @ts-expect-error
        <SortableItem
          index={index as number}
          key={row?.id ?? index}
          disabled={isLoadingItem(row?.id as number)}
          className={isLoadingItem(row?.id as number) && 'bg-light'}
        >
          {children}
        </SortableItem>
      )}
      noDataIndication={() => (
        <tr>
          <td colSpan={columns.length}>
            <p className="pl-2 pt-1 pb-1 text-center mb-0">
              No items on this Manual Collection
            </p>
          </td>
        </tr>
      )}
    />
  );
};

export default CatalogueItemsTable;
