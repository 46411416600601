import useSWR from 'swr';
import { defaultSWRConfig } from './swr-config';

const useCRUD = (key, fetcher, options = {}) => {
  const { data, error, isValidating, mutate } = useSWR(key, fetcher, {
    ...defaultSWRConfig,
    ...options,
  });

  const isLoading = !data && !error;

  return {
    data,
    error,
    isValidating,
    mutate,
    isLoading,
  };
};

export default useCRUD;
