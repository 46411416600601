import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { routePaths } from 'appConfig';
import { Button, Page } from 'components/Common';
import { useRouter } from 'lib/router';
import { mapValues } from 'lib/javascript';
import {
  AddonOptionsTable,
  useAddonOptions,
} from 'lib/addon-options';
import { useHistory } from 'react-router';

const AddonOptionsPage = () => {
  const history = useHistory();
  const { query, updateQueryParams } = useRouter();
  const [filter, setFilter] = React.useState({ name: '', label: '' });

  const {
    page = 1,
    sizePerPage = 15,
    sortField = 'name',
    sortDir = 'desc',
  } = query;

  const { isLoading, addonOptions, total } = useAddonOptions({
    page,
    sizePerPage,
    ...filter,
    sortField,
    sortDir,
  });

  const handleOnTableChange = (action, newState) => {
    switch (action) {
      case 'pagination':
        updateQueryParams({
          page: newState.page,
          sizePerPage: newState.sizePerPage,
        });
        break;

      case 'filter':
        const mappedFilters = mapValues(
          newState.filters,
          (filter) => filter.filterVal,
        );

        const name = mappedFilters['name'];
        const label = mappedFilters['label'];

        setFilter({ name, label });
        updateQueryParams({
          page: 1,
          sizePerPage: newState.sizePerPage,
        });

        break;

      case 'sort':
        const { sortField, sortOrder } = newState;

        updateQueryParams({
          sortField,
          sortDir: sortOrder,
        });

        break;

      default:
    }
  };

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <Row>
              <Col
                xs={12}
                className="d-flex justify-content-end align-item-center mb-2"
              >
                <div>
                  <Button
                    onClick={() => {
                      history.push(routePaths.NEW_ADDON_OPTION);
                    }}
                  >
                    Add Addon Option
                  </Button>
                </div>
              </Col>
            </Row>

            <AddonOptionsTable
              loading={isLoading}
              data={addonOptions}
              page={parseInt(page)}
              sizePerPage={parseInt(sizePerPage)}
              totalSize={total}
              onTableChange={handleOnTableChange}
              getPathToDetailsPage={(obj) =>
                `${routePaths.ADDON_OPTIONS}/${obj.id}`
              }
              sortDir={sortDir}
              sortField={sortField}
            />
          </CardBody>
        </Card>
      }
    />
  );
};

AddonOptionsPage.propTypes = {};

export default AddonOptionsPage;
