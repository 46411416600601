import { createQueryString } from 'lib/utils/createQueryString';
import {
  LoveMessagesResponse,
  LoveMessagePayload,
  UserLoveMessagesQueryParams,
  LoveMessageResponse,
  LoveMessagesQueryParams,
} from './types';
import httpClient, { getResponseData } from 'lib/httpClient';

export const getUserLoveMessagesUrl = (
  userId: string,
  params?: UserLoveMessagesQueryParams,
) => {
  const formattedQueryString = createQueryString({
    page: params?.page ?? undefined,
    per_page: params?.perPage ?? undefined,
  });

  return `/cms/users/${userId}/love_messages?${formattedQueryString}`;
};

export const getUserLoveMessages = async (
  userId: string,
  params?: UserLoveMessagesQueryParams,
) => {
  const response = await httpClient.get<LoveMessagesResponse>(
    getUserLoveMessagesUrl(userId, params),
  );

  return response.data;
};

export const createUserLoveMessage = async (
  userId: string,
  payload: LoveMessagePayload,
) => {
  const response = await httpClient.post<LoveMessageResponse>(
    `/cms/users/${userId}/love_messages`,
    payload,
  );

  return response.data;
};

export const updateUserLoveMessage = async (
  userId: string,
  loveMessageId: string,
  payload: LoveMessagePayload,
) => {
  const response = await httpClient.put<LoveMessageResponse>(
    `/cms/users/${userId}/love_messages/${loveMessageId}`,
    payload,
  );

  return getResponseData(response).data;
};

export const deleteUserLoveMessage = async (
  userId: string,
  loveMessageId: string,
) => {
  const response = await httpClient.delete(
    `/cms/users/${userId}/love_messages/${loveMessageId}`,
  );

  return getResponseData(response).data;
};

export const getLoveMessagesUrl = (
  params?: LoveMessagesQueryParams,
) => {
  const formattedQueryString = createQueryString({
    page: params?.page ?? undefined,
    per_page: params?.perPage ?? undefined,
    search: params?.search ?? undefined,
    user_ids: params?.userIds?.join(','),
  });

  return `/cms/love_messages?${formattedQueryString}`;
};

export const getLoveMessages = async (
  params?: LoveMessagesQueryParams,
) => {
  const response = await httpClient.get<LoveMessagesResponse>(
    getLoveMessagesUrl(params),
  );

  return response.data;
};

export const getLoveMessage = async (loveMessageId: string) => {
  const response = await httpClient.get<LoveMessageResponse>(
    `/cms/love_messages/${loveMessageId}`,
  );

  return response.data;
};

export const updateLoveMessage = async (
  loveMessageId: string,
  payload: LoveMessagePayload,
) => {
  const response = await httpClient.put<LoveMessageResponse>(
    `/cms/love_messages/${loveMessageId}`,
    payload,
  );

  return getResponseData(response).data;
};

export const deleteLoveMessage = async (loveMessageId: string) => {
  const response = await httpClient.delete(
    `/cms/love_messages/${loveMessageId}`,
  );

  return getResponseData(response).data;
};
