import { useListCRUD } from 'lib/swr-crud';
import { upperFirst } from 'lib/javascript';

import { getIngredientTypes } from './api';

const fetchers = {
  read: async (_url) => {
    const response = await getIngredientTypes();

    return response.map((ingredientType) => ({
      name: ingredientType,
      label: upperFirst(ingredientType),
    }));
  },
};

const useOrderStatus = () => {
  const url = '/cms/ingredient_type';

  const { data, error, isLoading, mutate } = useListCRUD(url, 'id', {
    fetchers,
  });

  const ingredientTypes = data || [];

  return {
    ingredientTypes,
    error,
    isLoading,
    mutate,
  };
};

export default useOrderStatus;
