import React from 'react';
import { Col, Row } from 'reactstrap';

import { Page, Button } from 'components/Common';
import { useCoupons, CouponForm } from 'lib/coupons';
import notification from 'lib/notification';
import { useRouter } from 'lib/router';
import { routePaths } from 'appConfig';

const NewCouponPage = () => {
  const { create } = useCoupons();
  const router = useRouter();
  const [isCreating, setIsCreating] = React.useState(false);

  const handleOnSubmit = async (fieldValues, actions) => {
    try {
      setIsCreating(true);

      await create(fieldValues);

      notification.success('Successfully created coupon');
      router.push({ pathname: routePaths.COUPONS });
      return;
    } catch (error) {
      actions.setErrors(error.errors);
      actions.setStatus({
        error: error.errors?.message || 'Error creating coupon',
      });

      return;
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Page
      content={
        <React.Fragment>
          <Row>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '12px',
              }}
            >
              <Button
                type="submit"
                form="create-coupon-form"
                loading={isCreating}
              >
                Create
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <CouponForm
                id="create-coupon-form"
                onSubmit={handleOnSubmit}
              />
            </Col>
          </Row>
        </React.Fragment>
      }
    />
  );
};

NewCouponPage.propTypes = {};

export default NewCouponPage;
