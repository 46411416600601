import React from 'react';
import PropTypes from 'prop-types';
import { Button as RBButton } from 'react-bootstrap';

import Spinner from '../Spinner';

const VARIANT = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  LINK: 'link',
};

const COLOR = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  LIGHT: 'light',
  DARK: 'dark',
};

const SHAPE = {
  ROUNDED_CORNERS: 'rounded-corners',
  SQUARE: 'square',
  ROUND: 'round',
};

const Button = ({
  variant,
  color,
  shape,
  children,
  className,
  loading,
  block,
  renderLoading,
  ...others
}) => {
  const getRBVariant = () => {
    if (variant === VARIANT.OUTLINED) return `outline-${color}`;

    if (variant === VARIANT.LINK) return VARIANT.LINK;

    return color;
  };

  return (
    <RBButton
      {...others}
      className={className}
      variant={getRBVariant()}
      block={block}
    >
      {loading ? renderLoading(children) : children}
    </RBButton>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(Object.values(VARIANT)),
  color: PropTypes.oneOf(Object.values(COLOR)),
  shape: PropTypes.oneOf(Object.values(SHAPE)),
  loading: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  renderLoading: PropTypes.func,
};

Button.defaultProps = {
  variant: VARIANT.CONTAINED,
  color: COLOR.PRIMARY,
  shape: SHAPE.SQUARE,
  loading: false,
  block: false,
  className: null,
  renderLoading: (children) => (
    <React.Fragment>
      <Spinner size="sm" /> {children}
    </React.Fragment>
  ),
};

export default Button;
