import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'lib/form';

const ON_CHANGE_ARG_TYPE = {
  EVENT: 'event',
  RAW_VALUE: 'raw-value',
};

const _updateFormFieldValue = (
  onChangeArg,
  fieldProps,
  options = {},
) => {
  const { field, form } = fieldProps;

  switch (options.onChangeArgType) {
    case ON_CHANGE_ARG_TYPE.RAW_VALUE:
      form.setFieldValue(field.name, onChangeArg);
      break;

    case ON_CHANGE_ARG_TYPE.EVENT:
    default:
      field.onChange(onChangeArg);
  }
};

const connectInput = (WrappedComponent, options = {}) => {
  const ConnectedInput = ({
    name,
    onChange,
    helperText,
    ...props
  }) => {
    const updateFormFieldValue = options.updateFormFieldValue
      ? options.updateFormFieldValue
      : _updateFormFieldValue;

    return (
      <Field name={name}>
        {(fieldProps) => {
          const { field, meta } = fieldProps;

          const error = meta.touched && meta.error;

          const _onChange = (...arg) => {
            updateFormFieldValue(arg[0], fieldProps, options);
            if (onChange) onChange(...arg);
          };

          const value = options.mapValue
            ? options.mapValue(field.value)
            : field.value;

          return (
            <WrappedComponent
              {...props}
              name={field.name}
              value={value}
              onChange={_onChange}
              onBlur={field.onBlur}
              error={error}
              helperText={
                error ? (
                  <ErrorMessage name={field.name} />
                ) : (
                  helperText
                )
              }
            />
          );
        }}
      </Field>
    );
  };

  ConnectedInput.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  };

  return ConnectedInput;
};

export default connectInput;
