import PropTypes from 'prop-types';

export const Asset = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  extension: PropTypes.string,
  url: PropTypes.string.isRequired,
  size: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
});
