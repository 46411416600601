import React from 'react';
import PropTypes from 'prop-types';
import { isStockInfinity } from './utils';

const IngredientStock = ({ ingredient }) => {
  if (isStockInfinity(ingredient)) return '∞';

  const { stock } = ingredient;
  const noStock = stock === 0;

  return <p style={noStock ? { color: 'red' } : {}}>{stock}</p>;
};

IngredientStock.propTypes = {
  ingredient: PropTypes.object,
};

export default IngredientStock;
