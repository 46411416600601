import { map, isEmpty } from 'lib/javascript';

export const hasProductVariants = (product) => {
  if (!product) return false;

  const hasOnlyDefaultVariant =
    product.variants.length === 1 &&
    isEmpty(product.variants[0].options);

  return !hasOnlyDefaultVariant;
};

export const makeProductVariantLabel = (
  productVariant,
  productOptions,
) => {
  if (isEmpty(productVariant.options)) return 'Default';

  return map(productVariant.options, (optionValue, optionKey) => {
    const optionLabel = productOptions
      ? productOptions[optionKey].label
      : optionKey;

    return `${optionValue} ${optionLabel}`;
  }).join(', ');
};
