import { formatDate } from 'lib/datetime';
import React from 'react';
import { changeOperatorName, isStaticLogsDifferent } from '.';

/**
 * @param {PropsInput}
 */
const CouponLogDiff = ({ title, newLog, oldLog, type, logStyle }) => {
  const shouldShow = () => {
    if (type === 'OBJECT_LIST') {
      return isStaticLogsDifferent(oldLog, newLog);
    }

    return oldLog !== newLog;
  };

  const formatLog = (log) => {
    if (type === 'BOOLEAN') {
      return log === null ? '' : log ? 'Yes' : 'No';
    }

    if (type === 'RULE_OPERATOR') {
      return changeOperatorName(log);
    }

    if (type === 'NULL_IS_INFINITY') {
      return log === null ? '∞' : log;
    }

    if (type === 'DATE_AS_STRING') {
      return log === null ? (
        <span className="text-color-mute">&lt;No Date&gt;</span>
      ) : (
        formatDate(log)
      );
    }

    if (type === 'OBJECT_LIST') {
      if (log?.length === 0) {
        return <span className="text-color-mute">&lt;None&gt;</span>;
      }

      return log?.map((log) => log.value)?.join(', ');
    }

    return log;
  };

  const formatLogNewLog = () => {
    return formatLog(newLog);
  };

  const formatLogOldLog = () => {
    return formatLog(oldLog);
  };

  return (
    <React.Fragment>
      {shouldShow() && (
        <p>
          <b>{title}</b>:{' '}
          <span style={logStyle}>
            <strike>{formatLogOldLog()}</strike> {formatLogNewLog()}
          </span>
        </p>
      )}
    </React.Fragment>
  );
};

export default CouponLogDiff;

/**
 * @typedef PropsInput
 * @type {object}
 *
 * @property {JSX.Element} title
 * @property {('OBJECT_LIST'|'BOOLEAN'|'NULL_IS_INFINITY'|'NORMAL'|'RULE_OPERATOR'|'DATE_AS_STRING')} type
 * @property {any} oldLog
 * @property {any} newLog
 * @property {React.CSSProperties} logStyle
 */
