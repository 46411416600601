import { isNull } from 'lodash';
import { Badge } from 'reactstrap';

interface TierBadgeProps {
  value: string;
  className?: string;
  color?: string;
  bgColor?: string;
}

const formatTitle = (value: string) => {
  const base = 'Total Orders in the Last 3 Months is';

  switch (value) {
    case 'AAA':
      return `${base} ($1000 and above)`;
    case 'AA':
      return `${base} ($500 to $999)`;
    case 'A':
      return `${base} ($250 to $499)`;
    default:
      return '';
  }
};

const TierBadge = ({
  value,
  className,
  color = '#00796B',
  bgColor = 'transparent',
}: TierBadgeProps) => {
  if (isNull(value)) {
    return <></>;
  }

  return (
    <Badge
      className={className}
      style={{ backgroundColor: bgColor, color, fontWeight: 800 }}
    >
      <span title={formatTitle(value)}>{value}</span>
    </Badge>
  );
};

export default TierBadge;
