import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Media } from 'reactstrap';

import { Price } from 'core/components';

const MetricCard = ({ name, value, isMonetary, ribbonText }) => {
  return (
    <Card className="mini-stats-wid">
      <CardBody>
        <Media>
          <Media body>
            <p className="text-muted font-weight-medium">{name}</p>
            <h4 className="mb-0">
              {isMonetary ? <Price value={value} /> : value}
            </h4>
          </Media>
        </Media>
      </CardBody>
      {ribbonText && <div className="ribbon">{ribbonText}</div>}
    </Card>
  );
};

MetricCard.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  isMonetary: PropTypes.bool,
  ribbonText: PropTypes.string,
};

MetricCard.defaultProps = {
  isMonetary: false,
};

export default MetricCard;
