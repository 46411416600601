import React from 'react';
import PropTypes from 'prop-types';

import Table, { textFilter } from 'core/components/Table';
import { Datetime } from 'lib/datetime';
import { Link } from 'lib/router';
import PublishCollectionButton from './PublishCollectionButton';

const CollectionsTable = ({
  isLoading,
  data,
  page,
  sizePerPage,
  totalSize,
  onTableChange,
  getPathToDetailsPage,
  sortField,
  sortDir,
  mutateCollections,
}) => {
  const mutateCollection = (collectionId, newData) => {
    mutateCollections((data) =>
      data.map((datum) => {
        if (datum.id !== collectionId) return datum;

        return {
          ...datum,
          ...newData,
        };
      }),
    );
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter({ delay: 3000 }),
      formatter: (name, collection) => (
        <Link to={getPathToDetailsPage(collection)}>{name}</Link>
      ),
    },
    {
      dataField: 'created_at',
      text: 'Date',
      formatter: (updatedAt) => <Datetime value={updatedAt} />,
      sort: true,
    },
    {
      dataField: 'products_count',
      text: 'Product',
    },
    {
      dataField: '',
      text: 'Actions',
      formatter: (_cell, row) => (
        <PublishCollectionButton
          collection={row}
          mutateCollection={mutateCollection}
        />
      ),
    },
  ];

  return (
    <Table
      remote
      loading={isLoading}
      keyField="id"
      columns={columns}
      data={data}
      pagination={{ page, sizePerPage, totalSize }}
      onTableChange={onTableChange}
      sort={{ dataField: sortField, order: sortDir }}
    />
  );
};

CollectionsTable.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  onOrderStatusChange: PropTypes.func.isRequired,
  isOrderUpdating: PropTypes.func.isRequired,
  /** passes the order row as argument and expects to return the route path to the order details page  */
  getPathToDetailsPage: PropTypes.func.isRequired,
  sortField: PropTypes.string,
  sortDir: PropTypes.string,
  mutateCollections: PropTypes.func,
};

CollectionsTable.defaultProps = {
  isLoading: false,
};

export default CollectionsTable;
