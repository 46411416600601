import { routePaths } from 'appConfig';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logoutUser } from '../../store/actions';
import useSession from 'lib/useSession';

const Logout = ({ history, logoutUser }) => {
  const { loading, session } = useSession();

  useEffect(() => {
    const handleLogout = () => {
      if (loading) return;

      if (!session) {
        history.replace(routePaths.LOGIN);
        return;
      }

      logoutUser(history);
    };

    handleLogout();
  }, [history, logoutUser, loading, session]);

  return <>Logging out...</>;
};

export default withRouter(connect(null, { logoutUser })(Logout));
