import React from 'react';
import PropTypes from 'prop-types';

import Table, { textFilter } from 'core/components/Table';
import { Link } from 'lib/router';

const AddonOptionsTable = ({
  loading,
  data,
  page,
  sizePerPage,
  totalSize,
  onTableChange,
  getPathToDetailsPage,
  sortField,
  sortDir,
}) => {
  const columns = [
    {
      dataField: 'name',
      text: 'Name (internal Reference)',
      formatter: (name, addonOption) => {
        return (
          <Link to={getPathToDetailsPage(addonOption)}>{name}</Link>
        );
      },
      filter: textFilter({ delay: 1500 }),
    },
    {
      dataField: 'label',
      text: 'Option Label',
      filter: textFilter({ delay: 1500 }),
    },
  ];

  return (
    <Table
      remote
      loading={loading}
      keyField="id"
      columns={columns}
      data={data}
      pagination={{ page, sizePerPage, totalSize }}
      onTableChange={onTableChange}
      sort={{ dataField: sortField, order: sortDir }}
    />
  );
};

AddonOptionsTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  sortField: PropTypes.string,
  sortDir: PropTypes.string,
};

AddonOptionsTable.defaultProps = {
  loading: false,
};

export default AddonOptionsTable;
