import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Skeleton } from 'components/Common';

import { routePaths } from 'appConfig';
import { Link } from 'lib/router';
import { Coupon, formatCouponCode } from 'lib/coupons';

const ReferralCode = ({ isLoading, value }) => {
  const [copied, setCopied] = React.useState(false);

  if (isLoading) return <Skeleton />;

  if (!value)
    return (
      <p>
        Your Referral URL:{' '}
        <strong>
          'Refer a Friend' is available only for customers with at
          least 1 completed order with us. 🙂
        </strong>
      </p>
    );

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          marginRight: 12,
        }}
      >
        <Coupon
          couponCode={value}
          rightRender={
            <React.Fragment>
              <CopyToClipboard
                text={formatCouponCode(value)}
                onCopy={() => {
                  setCopied(true);

                  setTimeout(() => {
                    setCopied(false);
                  }, 1500);
                }}
              >
                <Button variant="link">
                  {copied ? 'copied!' : 'copy'}
                </Button>
              </CopyToClipboard>
              <Link to={`${routePaths.COUPONS}/${value}`}>
                <Button variant="link">edit</Button>
              </Link>
            </React.Fragment>
          }
        />
      </div>
    </div>
  );
};

ReferralCode.propTypes = {
  isLoading: PropTypes.bool,
  /** String value of the referral code e.g. RAF_YUMMYBOSS10 */
  value: PropTypes.string,
};

ReferralCode.defaultProps = {
  isLoading: false,
};

export default ReferralCode;
