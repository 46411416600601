import { Page } from 'components/Common';
import { OperationCalendar } from 'lib/operation';
import React from 'react';
import { Card, CardBody } from 'reactstrap';

const OperationPage = () => {
  return (
    <Page
      content={
        <Card>
          <CardBody>
            {/* <div className="d-flex justify-content-end mb-2">
              <Button onClick={() => setNewOrderModalOpen(true)}>
                Add New Order
              </Button>
            </div> */}
            <OperationCalendar />
          </CardBody>
        </Card>
      }
    />
  );
};

OperationPage.propTypes = {};

export default OperationPage;
