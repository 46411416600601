export const REFUND_REASON = {
  DUPLICATE: 'duplicate',
  FRAUDULENT: 'fradulent',
  REQUESTED_BY_CUSTOMER: 'requested_by_customer',
};

export const LABEL_BY_REFUND_REASON = {
  [REFUND_REASON.DUPLICATE]: 'Duplicate',
  [REFUND_REASON.FRAUDULENT]: 'Fradulent',
  [REFUND_REASON.REQUESTED_BY_CUSTOMER]: 'Requested by Customer',
};

export const CANCEL_REASON = {
  DUPLICATE: 'duplicate',
  FRAUDULENT: 'fradulent',
  REQUESTED_BY_CUSTOMER: 'requested_by_customer',
  ABANDONED: 'abandoned',
};

export const LABEL_BY_CANCEL_REASON = {
  [CANCEL_REASON.DUPLICATE]: 'Duplicate',
  [CANCEL_REASON.FRAUDULENT]: 'Fradulent',
  [CANCEL_REASON.REQUESTED_BY_CUSTOMER]: 'Requested by Customer',
  [CANCEL_REASON.ABANDONED]: 'Abandoned',
};

export const ORDER_STATUS = {
  PAYMENT_RECEIVED: 'payment-received',
};

export const CREATE_ORDER_LINE_ERROR_CODE = {
  PRODUCT_OUT_OF_STOCK: 'product_stock_exceeded',
  INGREDIENT_OUT_OF_STOCK: 'ingredient.stock.not_enough',
};
