import React from 'react';
import PropTypes from 'prop-types';
import ReactTagsInput from 'react-tagsinput';
import { FormGroup, FormFeedback, FormText } from 'reactstrap';
import classNames from 'classnames';

import Label from '../Label';

import 'react-tagsinput/react-tagsinput.css';

const TagsInput = ({
  name,
  label,
  helperText,
  error,
  onBlur,
  required,
  value,
  onChange,
}) => {
  const HelperText = error ? FormFeedback : FormText;

  const inputProps = {
    name,
    onBlur,
  };

  return (
    <FormGroup>
      <Label htmlFor={name} error={error} required={required}>
        {label}
      </Label>
      <ReactTagsInput
        className={classNames('react-tagsinput', {
          'is-invalid': error,
        })}
        value={value}
        onChange={onChange}
        inputProps={inputProps}
        addKeys={[188]}
      />
      {helperText && <HelperText>{helperText}</HelperText>}
    </FormGroup>
  );
};

TagsInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

TagsInput.defaultProps = {
  value: [],
  onChange: () => {},
};

export default TagsInput;
