import { useCRUD } from 'lib/swr-crud';
import { getCouponLogsByCouponCode } from './api';

const useCouponLogs = (couponCode) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/cms/coupons/${couponCode}/logs`,
    async () => {
      return getCouponLogsByCouponCode(couponCode);
    },
  );

  const logs = data || null;

  return {
    logs,
    error,
    mutate,
    isValidating,
    isLoading,
  };
};

export default useCouponLogs;
