import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const getUsers = async (params) => {
  try {
    const response = await httpClient.get('/cms/users', {
      params,
    });

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getUserById = async (userId) => {
  try {
    const response = await httpClient.get(`/users/${userId}`);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const updateUserById = async (userId, body) => {
  try {
    const response = await httpClient.put(
      `/cms/users/${userId}`,
      body,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await httpClient.delete(`/cms/users/${userId}`);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
