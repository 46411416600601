import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton } from 'react-bootstrap';

import { useRadioGroupContext } from '../contexts/radioContexts';

const RadioButton = ({
  id,
  name: nameProp,
  label,
  checked: checkedProp,
  value,
  onChange: onChangeProp,
  variant,
}) => {
  const radioGroupCtx = useRadioGroupContext();

  let checked = checkedProp;
  let name = nameProp;

  if (radioGroupCtx) {
    if (typeof checked === 'undefined') {
      checked = radioGroupCtx.value === value;
    }
    if (typeof name === 'undefined') {
      name = radioGroupCtx.name;
    }
  }

  const onChange = (e) => {
    onChangeProp(e);
    if (radioGroupCtx) radioGroupCtx.onChange(e);
  };

  return (
    <ToggleButton
      id={id}
      type="radio"
      variant={variant}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    >
      {label}
    </ToggleButton>
  );
};

RadioButton.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  checkedRender: PropTypes.func,
};

RadioButton.defaultProps = {
  onChange: () => {},
  checkedRender: () => null,
};

export default RadioButton;
