import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const SidebarContentItems = (props) => {
  const hasChildren = (configItem) =>
    configItem.children && configItem.children.length;

  const getItemKey = (configItem) =>
    configItem.to || configItem.title;

  const renderMenuItem = (item) => {
    const children = hasChildren(item) && (
      <SidebarContentItems
        config={item.children}
        nestedLevel={props.nestedLevel + 1}
      />
    );

    switch (props.nestedLevel) {
      case 0:
        return (
          <React.Fragment>
            <li className="menu-title">{item.title}</li>
            {hasChildren(item) && children}
          </React.Fragment>
        );

      case 1:
        return (
          <li>
            <Link
              to={item.to}
              className={classNames('waves-effect', {
                'has-arrow': hasChildren(item),
              })}
            >
              <i className={item.icon}></i>
              <span>{item.title}</span>
            </Link>
            {hasChildren(item) && (
              <ul className="sub-menu" aria-expanded="false">
                {children}
              </ul>
            )}
          </li>
        );

      case 2:
        return (
          <li>
            <Link to={item.to}>{item.title}</Link>
          </li>
        );

      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      {props.config.map((item) => {
        return (
          <React.Fragment key={getItemKey(item)}>
            {renderMenuItem(item)}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const configItemShape = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
};

configItemShape.children = PropTypes.arrayOf(
  PropTypes.shape(configItemShape),
);

SidebarContentItems.propTypes = {
  config: PropTypes.arrayOf(PropTypes.shape(configItemShape)),
  nestedLevel: PropTypes.number,
};

SidebarContentItems.defaultProps = {
  nestedLevel: 0,
};

export default SidebarContentItems;
