import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
import { DatetimeAMPM } from 'lib/datetime';
import { useComplaintCategories } from 'lib/complaint-categories';
import { USER_ROLE } from 'lib/users/constants';
import { DISPLAY_KITCHEN_MAIN, DISPLAY_KITCHEN_OTHER } from './utils';

interface ComplaintTagsProps {
  data: {
    [key: string]: any;
  };
  removeTag: (key: string, value: string) => void;
}

interface TagMapping {
  [key: string]: {
    color: string;
    label?: string;
    type: 'array' | 'date' | 'text';
    tooltip?: string;
    icon?: string;
  };
}

const ComplaintTags: React.FC<ComplaintTagsProps> = ({
  data,
  removeTag,
}) => {
  const [page, _setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(15);

  const { complaintCategories, total } = useComplaintCategories({
    params: { page, perPage: sizePerPage },
  });

  useEffect(() => {
    if (complaintCategories.length !== total) {
      setSizePerPage(total);
    }
  }, [complaintCategories.length, total]);

  const tagMapping: TagMapping = {
    started_reported_at: {
      color: 'secondary',
      label: 'Reported Date From',
      type: 'date',
      tooltip: 'Start date of the report',
      icon: 'bx bx-calendar',
    },
    ended_reported_at: {
      color: 'secondary',
      label: 'Reported Date To',
      type: 'date',
      tooltip: 'End date of the report',
      icon: 'bx bx-calendar',
    },
    started_order_delivery_at: {
      color: 'primary',
      label: 'Delivery Date From',
      type: 'date',
      tooltip: 'Start date of the delivery',
      icon: 'bx bx-package',
    },
    ended_order_delivery_at: {
      color: 'primary',
      label: 'Delivery Date To',
      type: 'date',
      tooltip: 'End date of the delivery',
      icon: 'bx bx-package',
    },
    kitchen: {
      color: 'info',
      label: '',
      type: 'array',
      tooltip: 'Kitchen representative',
      icon: 'bx bx-food-menu',
    },
    status: {
      color: 'danger',
      label: '',
      type: 'array',
      tooltip: 'Feedback status',
      icon: 'bx bx-check-circle',
    },
    complaint_categories: {
      color: 'success',
      label: '',
      type: 'array',
      tooltip: 'Feedback categories',
      icon: 'bx bxs-label',
    },
  };

  const tags: JSX.Element[] = [];

  Object.keys(data).forEach((key) => {
    const value = data[key];
    const tag = tagMapping[key];

    if (
      key === 'complaint_categories' &&
      Array.isArray(value) &&
      value.length > 0
    ) {
      value.forEach((item: string) => {
        tags.push(
          <Badge
            key={`${key}-${item}`}
            color={tag.color}
            className="mr-1 mb-1 font-size-12"
            role="button"
            title={`${item}`}
          >
            {tag.icon && <i className={`${tag.icon} mr-1`}></i>}
            {
              complaintCategories.find(
                (category) => category.id === item,
              )?.name
            }
            {
              <i
                className="bx bx-x ml-2"
                onClick={() => removeTag(key, item)}
              ></i>
            }
          </Badge>,
        );
      });
    } else if (
      key === 'kitchen' &&
      Array.isArray(value) &&
      value.length > 0
    ) {
      value.forEach((item: string) => {
        tags.push(
          <Badge
            key={`${key}-${item}`}
            color={tag.color}
            className="mr-1 mb-1 font-size-12"
            role="button"
            title={`${item}`}
          >
            {tag.icon && <i className={`${tag.icon} mr-1`}></i>}
            {item === USER_ROLE.KITCHEN_REPRESENTATIVE_MAIN
              ? DISPLAY_KITCHEN_MAIN
              : item === USER_ROLE.KITCHEN_REPRESENTATIVE_OTHER
              ? DISPLAY_KITCHEN_OTHER
              : item}
            {
              <i
                className="bx bx-x ml-2"
                onClick={() => removeTag(key, item)}
              ></i>
            }
          </Badge>,
        );
      });
    } else if (
      tag?.type === 'array' &&
      Array.isArray(value) &&
      value.length > 0
    ) {
      value.forEach((item: string) => {
        tags.push(
          <Badge
            key={`${key}-${item}`}
            color={tag.color}
            className="mr-1 mb-1 font-size-12"
            title={tag.tooltip}
          >
            {tag.icon && <i className={`${tag.icon} mr-1`}></i>}
            {tag.label && tag.label !== ''
              ? `${tag.label}: `
              : ''}{' '}
            {item}
            {
              <i
                className="bx bx-x ml-2"
                onClick={() => removeTag(key, item)}
                role="button"
              ></i>
            }
          </Badge>,
        );
      });
    } else if (tag?.type === 'date' && value) {
      tags.push(
        <Badge
          key={key}
          color={tag.color}
          className="mr-1 mb-1 font-size-12"
          title={tag.tooltip}
        >
          {tag.icon && <i className={`${tag.icon} mr-1`}></i>}
          {tag.label && tag.label !== '' ? `${tag.label}: ` : ''} (
          <DatetimeAMPM value={value} />)
          {
            <i
              className="bx bx-x ml-2"
              onClick={() => removeTag(key, value)}
              role="button"
            ></i>
          }
        </Badge>,
      );
    } else if (tag?.type === 'text' && value !== null) {
      tags.push(
        <Badge
          key={key}
          color={tag.color}
          className="mr-1 mb-1 font-size-12"
          title={tag.tooltip}
        >
          {tag.icon && <i className={`${tag.icon} mr-1`}></i>}
          {tag.label && tag.label !== '' ? `${tag.label}: ` : ''}{' '}
          {value}
          {
            <i
              className="bx bx-x ml-2"
              onClick={() => removeTag(key, value)}
              role="button"
            ></i>
          }
        </Badge>,
      );
    }
  });

  return <div className="d-flex flex-wrap">{tags}</div>;
};

export default ComplaintTags;
