import React from 'react';
import PropTypes from 'prop-types';

import { FormManager, Form } from 'lib/form';
import validator from 'lib/validator';
import { TextField } from 'components/formFields';
import { Button } from 'components/Common';

import { deleteDeductUserLoyaltyPoints } from './api';
import useUserLoyaltyPoints from './useUserLoyaltyPoints';
import useUserLoyaltyPointsLogs from './useUserLoyaltyPointsLogs';
import { POINT_NAME } from './utils';
import notification from 'lib/notification';

const DeductLoyaltyPointsForm = ({ userId, onSubmit }) => {
  const userLoyaltyPoints = useUserLoyaltyPoints(userId);
  const userLoyaltyPointsLogs = useUserLoyaltyPointsLogs({ userId });

  return (
    <FormManager
      initialValues={{ point: 0 }}
      validationSchema={validator.object({
        point: validator.number().required(),
      })}
      onSubmit={async ({ point }) => {
        try {
          const response = await deleteDeductUserLoyaltyPoints(
            userId,
            point,
          );

          await userLoyaltyPoints.mutate();

          await userLoyaltyPointsLogs.mutate();

          onSubmit(null, response);

          notification.success(
            'Successfully deducted loyalty points',
          );

          return;
        } catch (err) {
          notification.error(
            'Something went wrong when deducting loyalty points',
          );

          onSubmit(err);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <TextField
            name="point"
            type="number"
            label={`${POINT_NAME} to deduct`}
            required
          />
          <Button type="submit" loading={isSubmitting}>
            Submit
          </Button>
        </Form>
      )}
    </FormManager>
  );
};

DeductLoyaltyPointsForm.propTypes = {
  userId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

DeductLoyaltyPointsForm.defaultProps = {
  onSubmit: () => {},
};

export default DeductLoyaltyPointsForm;
