import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'components/Common';

import { OrderPropType } from '../typedef';
import { isRefundable } from '../utils';
import RefundOrderForm from '../RefundOrderForm';

const RefundOrderButton = ({ order, style, className }) => {
  const [showRefundDialog, setShowRefundDialog] = React.useState(
    false,
  );

  const openRefundDialog = () => setShowRefundDialog(true);

  const closeRefundDialog = () => setShowRefundDialog(false);

  if (!isRefundable(order)) return null;

  return (
    <React.Fragment>
      <Button
        className={className}
        style={style}
        onClick={openRefundDialog}
      >
        Refund Order
      </Button>
      <Modal
        open={showRefundDialog}
        onClose={closeRefundDialog}
        title="Refund Order"
        content={
          <React.Fragment>
            <p>This will be a full refund. </p>
            <RefundOrderForm
              orderId={order.id}
              onSubmit={(err) => {
                if (!err) closeRefundDialog();
              }}
            />
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

RefundOrderButton.propTypes = {
  order: OrderPropType,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default RefundOrderButton;
