import { useState } from 'react';
import { saveAs } from 'file-saver';
import contentDisposition from 'content-disposition';

import config from 'appConfig';
import { useConfirmation } from 'lib/confirm';
import notification from 'lib/notification';

import {
  exportSalesReportByOrder,
  exportSalesReportByOrderNow,
} from './api';

const useSalesReportByOrderExport = ({
  startDate: start_date,
  endDate: end_date,
}) => {
  const [
    isExportingSalesReportByOrder,
    setIsExportingSalesReportByOrder,
  ] = useState(false);

  const { alert } = useConfirmation();

  const requestExportAsync = async () => {
    try {
      setIsExportingSalesReportByOrder(true);

      await exportSalesReportByOrder({
        start_date,
        end_date,
      });

      await alert({
        title: 'Your Sales Report by Order are Being Exported',
        content:
          "We'll email to you your sales report by order export in a few minutes. For now, Sit back and relax! ",
      });
    } catch (err) {
      notification.error(
        'Something went wrong when exporting sales report by order',
      );
    } finally {
      setIsExportingSalesReportByOrder(false);
    }
  };

  const requestExportSync = async () => {
    try {
      setIsExportingSalesReportByOrder(true);

      const response = await exportSalesReportByOrderNow({
        start_date,
        end_date,
      });

      const contentDispositionHeader =
        response.headers['content-disposition'];

      const parsedContentDispositionHeader = contentDisposition.parse(
        contentDispositionHeader,
      );

      const { filename } = parsedContentDispositionHeader.parameters;

      const salesReportByOrderExportData = response.data;

      saveAs(salesReportByOrderExportData, filename);
    } catch (err) {
      notification.error(
        'Something went wrong when exporting sales report by order',
      );
    } finally {
      setIsExportingSalesReportByOrder(false);
    }
  };

  const requestExport = () => {
    if (config.featureToggle.salesReportByOrderExport === 'sync')
      return requestExportSync({
        start_date,
        end_date,
      });
    return requestExportAsync({
      start_date,
      end_date,
    });
  };

  return {
    isExportingSalesReportByOrder,
    requestExport,
  };
};

export default useSalesReportByOrderExport;
