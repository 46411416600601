import PropTypes from 'prop-types';

export const AddressPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  default: PropTypes.bool.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  company_name: PropTypes.string,
  phone_number: PropTypes.string,
  country: PropTypes.string,
  postal_code: PropTypes.string,
  address_1: PropTypes.string,
  address_2: PropTypes.string,
  address_3: PropTypes.string,
});
