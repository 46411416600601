import React from 'react';

import { Select } from 'components/Common';

import { LOGIC_OPERATOR } from './constants';

const LABEL_BY_LOGIC_OPERATOR = {
  [LOGIC_OPERATOR.AND]: 'All',
  [LOGIC_OPERATOR.OR]: 'At least one',
};

const options = Object.values(LOGIC_OPERATOR).map((operator) => ({
  label: LABEL_BY_LOGIC_OPERATOR[operator],
  value: operator,
}));

const LogicOperatorSelect = (props) => {
  return <Select {...props} options={options} />;
};

LogicOperatorSelect.propTypes = Select.propTypes;

export default LogicOperatorSelect;
