import { createContext, useContext } from 'react';

export const defaultCheckboxGroupContextValues = {
  name: '',
  onChange: () => {},
  value: [],
};

export const CheckboxGroupContext = createContext(
  defaultCheckboxGroupContextValues,
);

export const useCheckoutGroupContext = () => {
  const checkboxGroupContext = useContext(CheckboxGroupContext);

  return checkboxGroupContext;
};
