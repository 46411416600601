import { createQueryString } from 'lib/utils/createQueryString';
import { Log, LogsQueryParams } from './types';
import { APIListResponse } from 'types/api';
import httpClient from 'lib/httpClient';

export const getLogsUrl = (url: string, params?: LogsQueryParams) => {
  const formattedQueryString = createQueryString({
    page: params?.page ?? undefined,
    per_page: params?.perPage ?? undefined,
  });

  return `${url}?${formattedQueryString}`;
};

export const getLogs = async (
  url: string,
  params?: LogsQueryParams,
) => {
  const response = await httpClient.get<APIListResponse<Log>>(
    getLogsUrl(url, params),
  );

  return response.data;
};
