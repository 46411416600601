import {
  intersection,
  isEmpty,
  groupBy,
  isEqual,
  size,
  difference,
} from 'lib/javascript';

export const hasAddonOptionValue = (addonOption) =>
  !isEmpty(addonOption?.values);

export const getDefaultAddonOptionValue = (addonOption) => {
  if (!hasAddonOptionValue(addonOption)) return null;

  const [
    firstAddonOptionValue,
    ...restOfAddonOptionValues
  ] = addonOption?.values;

  if (addonOption?.values?.length === 1) return firstAddonOptionValue;

  if (isIngredientOutOfStock(firstAddonOptionValue)) {
    const newAddonOption = {
      ...addonOption,
      values: restOfAddonOptionValues,
    };

    return getDefaultAddonOptionValue(newAddonOption);
  }

  return firstAddonOptionValue;
};

export const getDefaultAddonOptionValues = (addonOptions) =>
  addonOptions.map(getDefaultAddonOptionValue).filter(Boolean);

export const getActiveAddonRules = (
  addonRules,
  selectedAddonOptionValueIds,
) => {
  if (isEmpty(addonRules)) return [];

  let activeRules = [];

  addonRules.forEach((addonRule) => {
    const addonOptionValueIdsForAddonRuleToBeActive = addonRule.selected_addon_option_values.map(
      (addonOptionValue) => addonOptionValue.id,
    );

    const isRuleActive = !isEmpty(
      intersection(
        addonOptionValueIdsForAddonRuleToBeActive,
        selectedAddonOptionValueIds,
      ),
    );

    if (isRuleActive) activeRules.push(addonRule);
  });

  return activeRules;
};

export const getSelectedAddonOptionIds = (selectedAddonOptions) =>
  Object.keys(selectedAddonOptions).map(Number);

export const getSelectedAddonOptionValueIds = (
  selectedAddonOptions,
) => Object.values(selectedAddonOptions).map(Number);

export const makeDefaultSelectedAddons = (addonOptions) => {
  return addonOptions.reduce((result, addonOption) => {
    result[addonOption.id] = getDefaultAddonOptionValue(
      addonOption,
    ).id;

    return result;
  }, {});
};

export const getSmallerObj = (obj1, obj2) =>
  size(obj1) < size(obj2) ? obj1 : obj2;

export const getNextVisibleSelectedAddons = ({
  addonOptions,
  addonRules,
  selectedAddons,
  depth = 1,
  leastVisibleSelectedAddons,
}) => {
  const MAX_DEPTH = 5;

  // break recursion if depth is more than 5 to avoid infinite loop, return memoized selectedAddons that has the least visible addon options
  if (depth > MAX_DEPTH) return leastVisibleSelectedAddons;

  let nextAddonSelections = {};

  const activeRules = getActiveAddonRules(
    addonRules,
    getSelectedAddonOptionValueIds(selectedAddons),
  );

  if (isEmpty(activeRules))
    nextAddonSelections = {
      ...makeDefaultSelectedAddons(addonOptions),
      ...selectedAddons,
    };

  const addonRulesByIsVisible = groupBy(activeRules, 'is_visible');

  const addonOptionsToHide = (
    addonRulesByIsVisible.false || []
  ).flatMap((addonRule) => addonRule.value_addon_options);

  const addonOptionsToShow = (
    addonRulesByIsVisible.true || []
  ).flatMap((addonRule) => addonRule.value_addon_options);

  const hiddenAddonOptionIds = addonOptionsToHide.map(
    (addonOption) => addonOption.id,
  );

  const visibleAddonOptions = addonOptions
    .filter(hasAddonOptionValue)
    .filter(
      (addonOption) => !hiddenAddonOptionIds.includes(addonOption.id),
    )
    .concat(addonOptionsToShow);

  // using the visible addon options, compute the next selectedAddons
  nextAddonSelections = visibleAddonOptions.reduce(
    (result, visibleAddonOption) => {
      result[visibleAddonOption.id] =
        selectedAddons[visibleAddonOption.id] || // get previously selected addon option value for previously already visible addon options
        getDefaultAddonOptionValue(visibleAddonOption).id; // for addon options that just turned visible, get the default addon option value

      return result;
    },
    {},
  );

  // recursive call to find the final visible addon options
  // because one call might not be enough for complex addon rules
  // consider the example A(1, 2, 3), B(4, 5, 6), C(7, 8, 9) where A, B & C are the addon options
  // and the number in the parenthesis are the respective addon option values
  // consider having addon rules of
  //  i.) selecting B(5) hides A & C;
  //  ii.) selecting C(7) hides A
  // let B(5) is currently selected so A & B are hidden
  // then B changes value from 5 to 4, running just a call of getNextVisibleSelectedAddons
  // will yield a selections of A(1), B(4) and C(7)
  // but there's also rule ii, so addon option A should be hidden
  // therefore require another call
  if (!isEqual(selectedAddons, nextAddonSelections))
    return getNextVisibleSelectedAddons({
      addonOptions,
      addonRules,
      selectedAddons: nextAddonSelections,
      depth: depth + 1,
      leastVisibleSelectedAddons: !leastVisibleSelectedAddons
        ? nextAddonSelections
        : getSmallerObj(
            nextAddonSelections,
            leastVisibleSelectedAddons,
          ),
    });

  return nextAddonSelections;
};

export const getVisibleAddonOptions = (
  addonOptions,
  addonRules,
  selectedAddons,
) => {
  const nextVisibleSelectedAddons = getNextVisibleSelectedAddons({
    addonOptions,
    addonRules,
    selectedAddons,
  });

  const nextVisibleAddonOptionIds = getSelectedAddonOptionIds(
    nextVisibleSelectedAddons,
  );

  const visibleAddonOptions = addonOptions.filter((addonOption) =>
    nextVisibleAddonOptionIds.includes(Number(addonOption.id)),
  );

  return visibleAddonOptions;
};

export const getInitialSelectedAddonOptionValues = (
  addonOptions,
  addonRules,
) => {
  // assume all addon options have the default value selected
  const defaultSelectedAddonOptionValues = getDefaultAddonOptionValues(
    addonOptions,
  );

  // run the default selected values against addon rules
  // to see which addon options are visible based on the rules
  const visibleAddonOptions = getVisibleAddonOptions(
    addonOptions,
    addonRules,
    defaultSelectedAddonOptionValues.map(
      (optionValue) => optionValue.id,
    ),
  );

  // return only the default addon option values which their parent addon options are visible based on the rules
  return getDefaultAddonOptionValues(visibleAddonOptions);
};

export const getAddonCost = (selectedAddonOptionValues) => {
  const cost = selectedAddonOptionValues.reduce(
    (cost, addonOptionValue) => {
      cost += addonOptionValue.price;

      return cost;
    },
    0,
  );

  return cost;
};

export const isIngredientOutOfStock = (addonOptionValue) => {
  const { ingredient } = addonOptionValue;

  if (!ingredient) return false; // if no ingredient attached to an addon option value, choosing that option value won't deduct any ingredient's stock.

  const { stock } = ingredient;

  if (stock === null) return false; // null means infinity stock

  return stock <= 0;
};

export const findRelatedAddonOptions = (
  addonOptions,
  relatedToAddonOption,
) => {
  const sameLabel = (addonOption) =>
    addonOption.label === relatedToAddonOption.label;

  return addonOptions.filter(sameLabel);
};

export const persistSelectedIngredientForChangedAddonOptions = ({
  prevSelectedAddons,
  newSelectedAddons,
  addonOptions,
  selectAddonOptionValueById,
  selectAddonOptionById,
}) => {
  let processedNewSelectedAddons = { ...newSelectedAddons };

  const prevSelectedAddonOptionIds = getSelectedAddonOptionIds(
    prevSelectedAddons,
  );
  const newSelectedAddonOptionIds = getSelectedAddonOptionIds(
    newSelectedAddons,
  );

  const changedAddonOptionIds = difference(
    newSelectedAddonOptionIds,
    prevSelectedAddonOptionIds,
  );

  changedAddonOptionIds.forEach((changedAddonOptionId) => {
    const changedAddonOption = selectAddonOptionById(
      changedAddonOptionId,
    );

    const relatedAddonOptions = findRelatedAddonOptions(
      addonOptions,
      changedAddonOption,
    );

    relatedAddonOptions.forEach((relatedAddonOption) => {
      const prevSelectedAddonOptionValueId =
        prevSelectedAddons[relatedAddonOption.id];

      if (!prevSelectedAddonOptionValueId) return;

      const prevSelectedAddonOptionValue = selectAddonOptionValueById(
        prevSelectedAddonOptionValueId,
      );

      if (!prevSelectedAddonOptionValue) return;

      const prevSelectedIngredientId =
        prevSelectedAddonOptionValue.ingredient;

      if (!prevSelectedIngredientId) return;

      const matchingAddonOptionValueId = changedAddonOption.values.find(
        (addonOptionValueId) =>
          selectAddonOptionValueById(addonOptionValueId)
            .ingredient === prevSelectedIngredientId,
      );

      if (!matchingAddonOptionValueId) return;

      processedNewSelectedAddons = {
        ...processedNewSelectedAddons,
        [changedAddonOptionId]: matchingAddonOptionValueId,
      };
    });
  });

  return processedNewSelectedAddons;
};
