import datetime, { formatDatetime, formatTime } from 'lib/datetime';
import { DELIVERY_TIMESLOT_DURATION_HOURS } from 'lib/deliveryDateTimes/constants';
import { DISCOUNT_METHOD } from 'lib/discount';

import { ORDER_STATUS } from './constants';

export const mapOrderStateToOptionLabel = (orderState) =>
  orderState.label;

export const mapOrderStateToOptionValue = (orderState) =>
  orderState.name;

export const mapOrderStatusToOptions = (orderStatus) =>
  orderStatus.map((orderState) => ({
    label: mapOrderStateToOptionLabel(orderState),
    value: mapOrderStateToOptionValue(orderState),
  }));

export const mapOrderDeliveryDateTimeToOptions = (dateTimes) =>
  dateTimes.map((dateTime) => ({
    label: formatDeliveryDateTimeRange(dateTime),
    value: dateTime,
  }));

export const isOrderLineUtensil = (orderLine) =>
  orderLine.description === 'utensil';

export const findUtensilOrderLine = (order) => {
  const utensilOrderLine = order.additionals.find(isOrderLineUtensil);

  return utensilOrderLine;
};

export const isUtensilNeeded = (order) => {
  const utensilOrderLine = findUtensilOrderLine(order);

  const needUtensil = Boolean(utensilOrderLine);

  return needUtensil;
};

export const formatDeliveryDateTimeRange = (dateTime) => {
  return `${formatDatetime(dateTime)} - ${formatTime(
    datetime(dateTime).add(DELIVERY_TIMESLOT_DURATION_HOURS, 'hours'),
  )}`;
};

export const isRefundable = (order) => order?.is_refundable;

export const isCancellable = (order) => order?.is_cancellable;

export const getRedeemedLoyaltyPoints = (order) =>
  order?.discounts?.yb_reps;

export const getEarnedLoyaltyPoints = (order) =>
  order?.rewarded_yb_reps?.given;

export const getEstimatedLoyaltyPointsToBeEarned = (order) =>
  order?.rewarded_yb_reps?.estimated;

export const hasCouponApplied = (order) =>
  order?.discounts?.coupons?.length > 0;

export const getAppliedCoupons = (order) => order?.discounts?.coupons;

export const getAppliedYbReps = (order) => order?.discounts?.yb_reps;

export const hasLoyaltyPointsApplied = (order) =>
  Boolean(getAppliedYbReps(order));

export const getAppliedDiscountMethod = (order) => {
  if (hasCouponApplied(order)) return DISCOUNT_METHOD.COUPON;

  if (hasLoyaltyPointsApplied(order))
    return DISCOUNT_METHOD.LOYALTY_POINTS;

  return DISCOUNT_METHOD.NONE;
};

export const isOrderConfirmed = (
  order,
  cutOffTimeBeforeOrderDelivery,
) => {
  const { status } = order;
  const now = datetime();

  return (
    status === ORDER_STATUS.PAYMENT_RECEIVED &&
    now.isAfter(cutOffTimeBeforeOrderDelivery)
  );
};

export const isOrderLegacy = (order) => order.is_legacy;
