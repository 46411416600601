import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormGroup, Input, FormFeedback, FormText } from 'reactstrap';

import Label from '../Label';

const TextInput = ({
  name,
  type,
  className,
  label,
  helperText,
  value,
  onChange,
  onBlur,
  required,
  error,
  autoComplete,
  formGroupProps,
  ...props
}) => {
  const HelperText = error ? FormFeedback : FormText;

  return (
    <FormGroup
      className={formGroupProps.className}
      style={formGroupProps.style}
    >
      <Label htmlFor={name} error={error} required={required}>
        {label}
      </Label>
      <Input
        {...props}
        type={type}
        className={classNames('form-control', className)}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        invalid={error}
        autoComplete={autoComplete}
      />

      {helperText && <HelperText>{helperText}</HelperText>}
    </FormGroup>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  formGroupProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.object,
  }),
};

TextInput.defaultProps = {
  type: 'text',
  className: null,
  label: null,
  helperText: null,
  value: null,
  onChange: undefined,
  onBlur: () => {},
  required: false,
  autoComplete: undefined,
  formGroupProps: {
    className: '',
    style: {},
  },
};

export default TextInput;
