// @ts-nocheck
import FullCalendar from '@fullcalendar/react';
import BootstrapTheme from '@fullcalendar/bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useEffect, useRef, useState } from 'react';
import datetime, { formatDatetime } from 'lib/datetime';
import type { CollectionPoint } from './types';
import useSelectableSlots from './useSelectableSlots';
import { Button, Spinner } from 'components/Common';
import { setAsSingaporeDateTime } from './utils';
import { DELIVERY_TIMESLOT_DURATION_HOURS } from 'lib/deliveryDateTimes';

interface CollectionPointSlotsCalendarProps {
  collectionPoint: CollectionPoint;
}

const CollectionPointSlotsCalendar = ({
  collectionPoint,
}: CollectionPointSlotsCalendarProps) => {
  const calendarComponentRef = useRef();

  const [dateRange, setDateRange] = useState({
    startDate: setAsSingaporeDateTime(datetime())
      .startOf('month'),
    endDate: setAsSingaporeDateTime(datetime())
      .endOf('month'),
  });

  const {
    selectableSlots,
    isLoading,
  } = useSelectableSlots(collectionPoint.id, dateRange);

  const updateDateRange = () => {
    const calendarApi = calendarComponentRef.current.getApi();

    const currentDate = calendarApi.getDate();
    const startDate = setAsSingaporeDateTime(datetime(currentDate))
      .startOf('month');
    const endDate = setAsSingaporeDateTime(datetime(currentDate))
      .endOf('month');

    setDateRange({ startDate, endDate });
  };

  const onToday = () => {
    calendarComponentRef.current.getApi().today();
    updateDateRange();
  };

  const onPrevious = () => {
    calendarComponentRef.current.getApi().prev();
    updateDateRange();
  };

  const onNext = () => {
    calendarComponentRef.current.getApi().next();
    updateDateRange();
  };

  const updateCurrentSelectableSlots = () => {
    return selectableSlots
      .filter(({ is_available }) => is_available)
      .map(({ slot }) => {
        const datetimeSlot = setAsSingaporeDateTime(
          datetime(slot)
        );

        return {
          id: `collection-point-selectable-slot-${formatDatetime(datetimeSlot)}`,
          title: `${datetimeSlot.format('ha')}-${datetimeSlot
            .add(DELIVERY_TIMESLOT_DURATION_HOURS, 'hour')
            .format('ha')}`,
          start: datetimeSlot.toDate(),
          className: 'bg-light border border-light rounded d-flex justify-content-center',
        }
      });
  };

  const renderEventContent = ({ event }) => {
    return <div>{event.title}</div>;
  };

  const events = updateCurrentSelectableSlots();

  useEffect(() => {
    updateDateRange();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between my-2 align-items-end">
        <h3>
          {dateRange.startDate
            ? datetime(dateRange.startDate).format('MMMM YYYY')
            : ''}
        </h3>
        <div>
          <Button className="mr-4" color="info" onClick={onToday}>
            Today
          </Button>
          <Button className="mr-1" onClick={onPrevious}>
            Previous
          </Button>
          <Button onClick={onNext}>Next</Button>
        </div>
      </div>
      <div className="relative w-100 h-100 d-flex">
        <FullCalendar
          ref={calendarComponentRef}
          initialView="dayGridMonth"
          plugins={[BootstrapTheme, dayGridPlugin]}
          handleWindowResize={true}
          themeSystem="bootstrap"
          headerToolbar={null}
          events={events}
          height="auto"
          id="calendar"
          editable={false}
          droppable={false}
          eventLimit={true}
          selectable={false}
          dateClick={null}
          eventContent={renderEventContent}
        />
        {isLoading && (
          <div
            style={{
              position: 'absolute',
              top: '57px',
              right: 0,
              left: 0,
              bottom: 0,
              backgroundColor: 'rgba(0,0,0,0.2)',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              zIndex: 1,
            }}
          >
            <Spinner size="lg" />
          </div>
        )}
      </div>
    </>
  );
};

export default CollectionPointSlotsCalendar
