import React from 'react';
import PropTypes from 'prop-types';
import config from 'appConfig';

import { ReactComponent as LogoHorizontal } from './logo-horizontal.svg';
import { ReactComponent as LogoHorizontalDark } from './logo-horizontal-dark.svg';
import { ReactComponent as LogoVertical } from './logo-vertical.svg';
import { ReactComponent as LogoVerticalDark } from './logo-vertical-dark.svg';
import { ReactComponent as LogoMark } from './logomark.svg';
import { ReactComponent as LogoMarkDark } from './logomark-dark.svg';

const VARIANT = {
  LOGO_HORIZONTAL: 'logo-horizontal',
  LOGO_VERTICAL: 'logo-vertical',
  LOGOMARK: 'logomark',
};

const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
};

const Logo = ({ variant, theme, size, unoptimized }) => {
  const Component = getComponent({ variant, theme });
  const { width, height } = getWidthHeight({ variant, size });
  const alt = getAlt({ variant, theme });

  return <Component title={alt} width={width} height={height} />;
};

const getComponent = ({ variant, theme }) => {
  const variantTheme = variant + theme;

  switch (variantTheme) {
    case VARIANT.LOGO_HORIZONTAL + THEME.LIGHT:
      return LogoHorizontal;

    case VARIANT.LOGO_HORIZONTAL + THEME.DARK:
      return LogoHorizontalDark;

    case VARIANT.LOGO_VERTICAL + THEME.LIGHT:
      return LogoVertical;

    case VARIANT.LOGO_VERTICAL + THEME.DARK:
      return LogoVerticalDark;

    case VARIANT.LOGOMARK + THEME.LIGHT:
      return LogoMark;

    case VARIANT.LOGOMARK + THEME.DARK:
      return LogoMarkDark;

    default:
      return null;
  }
};

const getWidthHeight = ({ variant, size }) => {
  let aspectRatio;

  switch (variant) {
    case VARIANT.LOGO_HORIZONTAL:
      aspectRatio = 1259 / 278;
      break;
    case VARIANT.LOGO_VERTICAL:
    case VARIANT.LOGOMARK:
    default:
      aspectRatio = 1; // square
      break;
  }

  const scaledSize = size * 10;
  const width = aspectRatio * scaledSize;
  const height = scaledSize;

  return { width, height };
};

const getAlt = ({ variant, theme }) => {
  const base = config.appName;
  let alt = base;

  switch (variant) {
    case VARIANT.LOGO_VERTICAL:
      alt += ' Logo Vertical';
      break;
    case VARIANT.LOGO_HORIZONTAL:
      alt += ' Logo Horizontal';
      break;
    case VARIANT.LOGOMARK:
      alt += ' Logomark';
      break;
    default:
      alt += ' Logo';
  }

  switch (theme) {
    case THEME.DARK:
      alt += ' Dark';
      break;
    default:
  }

  return alt;
};

Logo.propTypes = {
  variant: PropTypes.oneOf(Object.values(VARIANT)),
  theme: PropTypes.oneOf(Object.values(THEME)),
  unoptimized: PropTypes.bool,
  size: PropTypes.number,
};

Logo.defaultProps = {
  variant: VARIANT.LOGO_HORIZONTAL,
  theme: THEME.LIGHT,
  unoptimized: false,
  size: 5,
};

export default Logo;
