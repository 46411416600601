import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import qs from 'qs';

import { Layout as AuthLayout, ResetPasswordForm } from 'lib/auth';
import { Button } from 'components/Common';
import { getVerifyResetToken } from 'services/api';

const useQuery = () => {
  const queryString = useLocation().search;

  if (!queryString) return null;

  const parsed = qs.parse(queryString, { ignoreQueryPrefix: true });

  return parsed;
};

const ResetPassword = () => {
  const query = useQuery();

  const [isTokenValid, setIsTokenValid] = React.useState(false);
  const [
    isPasswordResetSuccess,
    setIsPasswordResetSuccess,
  ] = React.useState(false);

  const { email, token } = query || {};

  React.useEffect(() => {
    if (!email || !token) {
      setIsTokenValid(false);
    } else {
      getVerifyResetToken({ email, token }).then(({ error }) => {
        setIsTokenValid(!error);
      });
    }
  }, [email, token]);

  const handleOnResetPassword = (err) => {
    if (!err) setIsPasswordResetSuccess(true);
  };

  if (!isTokenValid)
    return (
      <AuthLayout
        title="Reset Password Token is Invalid"
        subtitle=""
        content={
          <p>The reset password token is expired or invalid. </p>
        }
        footer={null}
      />
    );

  if (isPasswordResetSuccess)
    return (
      <AuthLayout
        title="Password Reset Success"
        subtitle=""
        content={
          <div>
            <p>Please login using the new password you've set. </p>
            <Link to="/login">
              <Button>Go to Login</Button>
            </Link>
          </div>
        }
        footer={null}
      />
    );

  return (
    <AuthLayout
      title="Change Password"
      subtitle=""
      content={
        <ResetPasswordForm
          email={email}
          token={token}
          onResetPassword={handleOnResetPassword}
        />
      }
      footer={null}
    />
  );
};

ResetPassword.propTypes = {};

export default ResetPassword;
