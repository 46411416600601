import React, { useState } from 'react';
import PropTypes from 'prop-types';

import notification from 'lib/notification';

import { AddonOptionMultiSelector } from 'lib/addon-options';
import { Col, Row } from 'reactstrap';
import useProductVariantAddonOptions from './useProductVariantAddonOptions';
import useProductVariantAddonRules from './useProductVariantAddonRules';
import { AddonRuleMultiSelector } from 'lib/addon-rules';

const ProductAddonsDetails = ({ variantId }) => {
  const [isOptionLoading, setOptionLoading] = useState(false);
  const [isRuleLoading, setRuleLoading] = useState(false);
  const {
    addonOptions,
    update: updateOptions,
  } = useProductVariantAddonOptions(variantId);
  const {
    addonRuleids,
    update: updateRules,
  } = useProductVariantAddonRules(variantId);

  const addonOptionids = addonOptions.map((d) => d.id);

  const _onUpdateOption = async (values) => {
    try {
      setOptionLoading(true);
      await updateOptions({ addon_option_ids: values });
      notification.success('Success updating Addon Options');
    } catch (err) {
      notification.error(
        err.errors.message || 'Error occur when update Addon Options',
      );
    } finally {
      setOptionLoading(false);
    }
  };

  const _onUpdateRule = async (values) => {
    try {
      setRuleLoading(true);
      await updateRules({ addon_rule_ids: values });
      notification.success('Success updating Addon Rules');
    } catch (err) {
      notification.error(
        err.errors.message || 'Error occur when update Addon Rules',
      );
    } finally {
      setRuleLoading(false);
    }
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <AddonOptionMultiSelector
          sortable
          label="Options"
          value={addonOptionids}
          onChange={_onUpdateOption}
          isLoading={isOptionLoading}
        />
      </Col>
      <Col md={12} lg={12}>
        <AddonRuleMultiSelector
          label="Rules"
          value={addonRuleids}
          onChange={_onUpdateRule}
          isLoading={isRuleLoading}
        />
      </Col>
    </Row>
  );
};

ProductAddonsDetails.propTypes = {
  variantId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

ProductAddonsDetails.defaultProps = {
  onSubmit: () => {},
};

export default ProductAddonsDetails;
