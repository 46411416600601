import { useGet } from 'lib/swr-crud';
import {
  LoveMessagesResponse,
  LoveMessagePayload,
  UserLoveMessagesQueryParams,
} from './types';
import { AxiosError } from 'axios';
import { APIErrorResponse } from 'types/api';
import {
  createUserLoveMessage,
  deleteUserLoveMessage,
  getUserLoveMessages,
  getUserLoveMessagesUrl,
  updateUserLoveMessage,
} from './api';

interface UseUserLoveMessagesOptions {
  userId: string;
  params?: UserLoveMessagesQueryParams;
}

const useUserLoveMessages = ({
  userId,
  params,
}: UseUserLoveMessagesOptions) => {
  const { data, isLoading, mutate } = useGet<
    LoveMessagesResponse,
    AxiosError<APIErrorResponse>
  >(
    userId ? getUserLoveMessagesUrl(userId, params) : null,
    async () => await getUserLoveMessages(userId, params),
  );

  const prevData = data;

  const mutateLoveMessage = (
    loveMessageId: string,
    newLoveMessage: LoveMessagePayload,
  ) => {
    mutate((currentData) => {
      if (!currentData) return prevData;

      const updatedData = currentData.data.map((loveMessage) =>
        loveMessage.id === loveMessageId
          ? newLoveMessage
          : loveMessage,
      );

      return {
        ...currentData,
        updatedData,
      };
    });
  };

  const createLoveMessage = async (payload: LoveMessagePayload) => {
    try {
      return await createUserLoveMessage(userId, payload);
    } catch (error) {
      throw error;
    } finally {
      mutate();
    }
  };

  const updateLoveMessage = async (
    loveMessageId: string,
    payload: LoveMessagePayload,
  ) => {
    try {
      const newLoveMessage = await updateUserLoveMessage(
        userId,
        loveMessageId,
        payload,
      );

      return mutateLoveMessage(loveMessageId, newLoveMessage);
    } catch (error) {
      await mutate(prevData); // revert optimistic update
      throw error;
    }
  };

  const total: number = data?.meta?.total ?? 0;

  const deleteLoveMessage = async (loveMessageId: string) => {
    await deleteUserLoveMessage(userId, loveMessageId);
    mutate();
  };

  return {
    loveMessages: data?.data ?? [],
    isLoading,
    total,
    createLoveMessage,
    updateLoveMessage,
    deleteLoveMessage,
  };
};

export default useUserLoveMessages;
