import React from 'react';
import { FormManager, Form } from 'lib/form';
import yup from 'lib/validator';
import { TextField } from 'components/formFields';
import { Button } from 'components/Common';
import { Col, Row } from 'reactstrap';

const validationSchema = yup.object({
  name: yup.string().required(),
  label: yup.string().required(),
});

const AddonOptionForm = ({ onSubmit, initialValues, buttonText }) => {
  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Row>
              <Col md={12} lg={6}>
                <TextField
                  name={'label'}
                  label="Option Label"
                  required
                />
              </Col>

              <Col md={12} lg={6}>
                <TextField
                  name={'name'}
                  label="Name (Internal Reference)"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Button type="submit" loading={isSubmitting}>
                  {buttonText}
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </FormManager>
  );
};

export default AddonOptionForm;
