import { Form } from 'react-bootstrap';
import DatetimeName from './DatetimeName';
import { Complaint } from './types';
import { getOverviewData } from './utils';
import { routePaths } from 'appConfig';
import { useHistory } from 'react-router-dom';

interface ComplaintOverviewInterface {
  complaint: Complaint | null;
}

const ComplaintOverview = ({
  complaint,
}: ComplaintOverviewInterface) => {
  const overviewData = getOverviewData(complaint);

  const {
    reporter,
    reporter_id,
    reported_at,
    resolver,
    resolver_id,
    resolved_at,
    corrector,
    corrector_id,
    corrected_at,
    verificator,
    verificator_id,
    verified_at,
  } = overviewData;

  const history = useHistory();
  const onShowUser = (userId?: string) => {
    history.push(`${routePaths.USERS}/${userId}`);
  };
  return (
    <Form>
      <Form.Group>
        <Form.Check
          type="checkbox"
          id="checkbox-1"
          className="mb-0"
          label={
            <>
              <span>Created </span>
              <DatetimeName
                datetime={reported_at}
                name={reporter}
                userId={reporter_id}
              />
            </>
          }
          disabled
          checked={!!reporter && !!reported_at}
        />
      </Form.Group>
      <Form.Group>
        <Form.Check
          type="checkbox"
          id="checkbox-2"
          label={
            <>
              <span>Resolved </span>
              <DatetimeName
                datetime={resolved_at}
                name={resolver}
                userId={resolver_id}
              />
            </>
          }
          disabled
          checked={!!resolved_at}
        />
      </Form.Group>
      <Form.Group>
        <Form.Check
          type="checkbox"
          id="checkbox-3"
          label={
            <>
              <span>Corrected </span>
              <DatetimeName
                datetime={corrected_at}
                name={corrector}
                userId={corrector_id}
              />
            </>
          }
          disabled
          checked={!!corrected_at}
        />
      </Form.Group>
      <Form.Group>
        <Form.Check
          type="checkbox"
          id="checkbox-4"
          label={
            <>
              <span>Verified </span>
              <DatetimeName
                datetime={verified_at}
                name={verificator}
                userId={verificator_id}
              />
            </>
          }
          disabled
          checked={!!verified_at}
        />
      </Form.Group>
    </Form>
  );
};

export default ComplaintOverview;
