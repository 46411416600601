import PropTypes from 'prop-types';

export const IngredientPropType = PropTypes.shape({
  id: PropTypes.string,
  stock: PropTypes.number,
  unit_of_measurement: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['main', 'carbs', 'sides']),
  alias: PropTypes.string,
});

export const AddonOptionValuePropType = PropTypes.shape({
  id: PropTypes.string,
  ingredient: IngredientPropType,
  label: PropTypes.string,
  consume_quantity: PropTypes.number,
  price: PropTypes.number,
});

export const AddonOptionPropType = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.arrayOf(AddonOptionValuePropType),
});

export const AddonRulePropType = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  is_visible: PropTypes.bool,
  selected_addon_option_values: PropTypes.arrayOf(
    AddonOptionValuePropType,
  ),
  value_addon_options: PropTypes.arrayOf(AddonOptionPropType),
});
