// @ts-nocheck
// fix: is missing the following properties
import { Button } from 'components/Common';
import { TextField } from 'components/formFields';
import { Form, FormManager } from 'lib/form';
import yup from 'lib/validator';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import CollectionPointAddressFields from './CollectionPointAddressFields';
import { CollectionPoint } from './types';

interface CollectionPointFormProps {
  initialValues?: CollectionPoint;
  onSubmit: (collectionPoint: CollectionPoint) => Promise<void>;
  buttonLabel?: string
  transparentCard?: boolean
}

const defaultValues: CollectionPoint = {
  name: '',
  address: {
    first_name: '',
    last_name: '',
    phone_number: '',
    postal_code: '',
    address_1: '',
    address_2: '',
    address_3: '',
  }
}

const validationSchema = yup.object({
  name: yup.string().required(),
  address: yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    phone_number: yup.string().required(),
    postal_code: yup.string().length(6).required(),
    address_1: yup.string().required(),
    address_2: yup.string().nullable(),
    address_3: yup.string().nullable(),
  }),
});

const CollectionPointForm = ({
  initialValues = defaultValues,
  onSubmit,
  buttonLabel = 'Add',
  transparentCard
}: CollectionPointFormProps) => {
  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Card style={ transparentCard ? { backgroundColor: 'transparent', boxShadow: 'none' } : {} }>
              <CardBody>
                <Row>
                  <Col>
                    <TextField
                      name="name"
                      label="Collection Point Name"
                      required
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card style={ transparentCard ? { backgroundColor: 'transparent', boxShadow: 'none' } : {} }>
              <CardBody>
                <CardTitle>Address</CardTitle>
                <Row>
                  <Col>
                    <CollectionPointAddressFields name="address" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
              <Col>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  {buttonLabel}
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </FormManager>
  )
}

export default CollectionPointForm;
