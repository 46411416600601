import { useCRUD } from 'lib/swr-crud';
import {
  getIngredientById,
  updateIngredientById,
  deleteIngredientById,
} from './api';

const useIngredient = (ingredientId) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/cms/ingredients/${ingredientId}`,
    async () => {
      return getIngredientById(ingredientId);
    },
  );

  const update = async (body) => {
    const data = await updateIngredientById(ingredientId, body);

    mutate(data);
    return data;
  };

  const _delete = async () => {
    await deleteIngredientById(ingredientId);
  };

  const ingredient = data || null;

  return {
    ingredient,
    error,
    update,
    _delete,
    mutate,
    isValidating,
    isLoading,
  };
};

export default useIngredient;
