import { Address } from 'lib/orders';

class UpdateAddressError extends Error {
  address: Address;
  serverError: Object;

  constructor({ address, serverError }) {
    const { message } = serverError?.errors || {};

    super(message || 'Something went wrong when updating address');

    this.name = 'UpdateAddressError';
    this.address = address;
    this.serverError = serverError;
  }
}

export default UpdateAddressError;
