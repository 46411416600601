import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

import { useDeliverySlotsContext } from 'lib/operation';

import useOrderStatus from './useOrderStatus';
import { OrderPropType } from './typedef';
import { isOrderConfirmed } from './utils';

const OrderStatus = ({ order }) => {
  const { isLoading, orderStatus } = useOrderStatus();
  const { getCutOffTimeBeforeDateTime } = useDeliverySlotsContext();
  const { status, delivery_datetime } = order;

  const cutOffTimeBeforeOrderDelivery = getCutOffTimeBeforeDateTime(
    delivery_datetime,
  );

  if (isLoading)
    return (
      <ContentLoader height={13} style={{ width: '80%' }}>
        <rect x="0" y="0" rx="0" ry="0" width="680" height="13" />
      </ContentLoader>
    );

  const match = orderStatus.find((state) => state.name === status);
  const label = match
    ? [
        match.label,
        isOrderConfirmed(order, cutOffTimeBeforeOrderDelivery) &&
          '(Confirmed)',
      ]
        .filter(Boolean)
        .join(' ')
    : 'Unknown';

  return <React.Fragment>{label}</React.Fragment>;
};

OrderStatus.propTypes = {
  order: OrderPropType.isRequired,
  onChange: PropTypes.func,
};

OrderStatus.defaultProps = {
  onChange: () => {},
};

export default OrderStatus;
