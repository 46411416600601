import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  AutoComplete,
  SortableAutoComplete,
} from 'components/Common';
import { getAddonOptions } from './api';
import notification from 'lib/notification';

const AddonOptionMultiSelector = ({
  name,
  value,
  onChange,
  label,
  required,
  error,
  helperText,
  isLoading,
  sortable,
}) => {
  const [_isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const loadOptions = async () => {
    try {
      setLoading(true);
      const { meta } = await getAddonOptions({});
      const { data } = await getAddonOptions({
        per_page: meta.total,
      });

      setOptions(data);
    } catch (err) {
      notification.error(err?.errors?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadOptions();
  }, []);

  const Component = sortable ? SortableAutoComplete : AutoComplete;

  return (
    <Component
      isLoading={isLoading || _isLoading}
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      disabled={isLoading || _isLoading}
      options={options}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isMulti={true}
    />
  );
};

AddonOptionMultiSelector.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  sortable: PropTypes.bool,
};

AddonOptionMultiSelector.defaultProps = {
  sortable: false,
};

export default AddonOptionMultiSelector;
