import React from 'react';
import PropTypes from 'prop-types';
import { Link as RRLink } from 'react-router-dom';

import qs from 'qs';
import { compile } from 'path-to-regexp';

import { isPlainObject } from 'lib/javascript';

const Link = ({ to, replace, children }) => {
  const mapToRRToObj = ({
    pathname: pathnameArg,
    urlParams,
    queryParams,
  }) => {
    const search = qs.stringify(queryParams, {
      addQueryPrefix: true,
    });

    const pathname = compile(pathnameArg)(urlParams);

    return {
      pathname,
      search,
    };
  };

  const mapToRRTo = (to) => {
    if (isPlainObject(to)) return mapToRRToObj(to);

    return to; // if it's a string or a function, pass through as it is
  };

  return (
    <RRLink to={mapToRRTo(to)} replace={replace}>
      {children}
    </RRLink>
  );
};

Link.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      urlParams: PropTypes.object,
      queryParams: PropTypes.object,
    }),
  ]),
  replace: PropTypes.bool,
};

Link.defaultProps = {
  replace: false,
};

export default Link;
