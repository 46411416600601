import React from 'react';
import PropTypes from 'prop-types';

import Table from 'core/components/Table';
import { Datetime } from 'lib/datetime';

import { LoyaltyPointsLogPropType } from './typedef';
import { POINT_NAME } from './utils';
import { LABEL_BY_LOG_EVENT_TYPE } from './constants';

const LoyaltyPointsLogsTable = ({
  isLoading,
  data,
  page,
  sizePerPage,
  totalSize,
  onPageChange,
  onSizePerPageChange,
}) => {
  const columns = [
    {
      dataField: 'point',
      text: POINT_NAME,
    },
    {
      dataField: 'type',
      text: 'Event',
      formatter: (eventType) =>
        LABEL_BY_LOG_EVENT_TYPE[eventType] || 'Unknown',
    },
    {
      dataField: 'created_at',
      text: 'Date',
      formatter: (createdAt) => <Datetime value={createdAt} />,
    },
  ];

  const onTableChange = (action, newState) => {
    switch (action) {
      case 'pagination':
        if (newState.page !== page) onPageChange(newState.page);
        if (newState.sizePerPage !== sizePerPage)
          onSizePerPageChange(newState.sizePerPage);
        break;

      default:
    }
  };

  return (
    <Table
      loading={isLoading}
      keyField="id"
      data={data}
      columns={columns}
      pagination={{ page, sizePerPage, totalSize }}
      onTableChange={onTableChange}
    />
  );
};

LoyaltyPointsLogsTable.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(LoyaltyPointsLogPropType),
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  onSizePerPageChange: PropTypes.func,
};

LoyaltyPointsLogPropType.defaultProps = {
  isLoading: false,
  onPageChange: () => {},
  onSizePerPageChange: () => {},
};

export default LoyaltyPointsLogsTable;
