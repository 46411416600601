import { Icon, IconButton } from 'components/Common';
import { Table } from 'core/components';
import { useConfirmation } from 'lib/confirm';
import { DatetimeAMPM } from 'lib/datetime';
import notification from 'lib/notification';
import { FullName, User, UserBadges } from 'lib/users';
import { displayName } from 'lib/utils';
import { Asset, Complaint, ComplaintsQueryParams } from './types';

import { USER_ROLE } from 'lib/users/constants';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRoleUser } from 'store/auth/GetRoleUser/actions';
import { UserRoleRedux } from 'store/auth/GetRoleUser/types';
import {
  DISPLAY_KITCHEN_MAIN,
  DISPLAY_KITCHEN_OTHER,
  getStatusInfo,
} from './utils';
import { routePaths } from 'appConfig';
import { ComplaintCategory } from 'lib/complaint-categories';
import ComplaintListFilter from './ComplaintListFilter';
import { Order } from 'lib/orders';
import { Link } from 'react-router-dom';
import { ImagePreview } from 'components/Common/ImagePreview';

interface ComplaintsTableProps extends ComplaintsQueryParams {
  data: Complaint[];
  isLoading?: boolean;
  totalSize?: number;
  onTableChange?: (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => Promise<void>;
  onStatusesFilterChange: (statuses: string) => void;
  onShowDetails?: (complaintId: string) => void;
  onClickUser?: (userId: string) => void;
  onDelete?: (complaintId: string) => Promise<void>;
  allowFilter?: boolean;
}
const ComplaintsTable = ({
  data,
  isLoading,
  onDelete,
  onShowDetails,
  onTableChange,
  totalSize,
  page,
  perPage,
  search,
  onStatusesFilterChange,
  allowFilter,
}: ComplaintsTableProps) => {
  const { confirm } = useConfirmation();
  const dispatch = useDispatch();
  const roleState = useSelector((state: UserRoleRedux) => state.role);
  const { isAdmin, isExecutiveAssistant } = roleState?.role || {};
  const [images, setImages] = React.useState<string[]>([]);
  const [showImagePreview, setShowImagePreview] = React.useState(
    false,
  );
  useEffect(() => {
    dispatch(getRoleUser());
  }, [dispatch]);

  const _onDelete = async (complaintId: string) => {
    await confirm();

    try {
      if (onDelete) {
        await onDelete(complaintId);
        notification.success('Feedback successfully deleted.');
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        notification.error(
          'This feedback has already been verified and cannot be deleted.',
        );
        return;
      }
      notification.error(
        'Something went wrong while deleting feedback.',
      );
    }
  };

  const handleShowImagePreview = (assets: Asset[]) => {
    setImages(assets.map((asset) => asset.url));
    setShowImagePreview(true);
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      formatter: (id: string, complaint: Complaint) => (
        <Link to={`${routePaths.COMPLAINTS}/${id}`}>{id}</Link>
      ),
    },
    {
      dataField: 'order',
      text: 'Order #',
      formatter: (order: Order) => {
        return (
          <Link to={`${routePaths.ORDERS}/${order.id}`}>
            <span>#{order.order_number}</span>
          </Link>
        );
      },
    },
    {
      dataField: 'customer',
      text: 'Customer Name',
      formatter: (customer: User, complaint: Complaint) => {
        const fullName = displayName({
          firstName: customer?.first_name,
          lastName: customer?.last_name,
        });
        return (
          <Link to={`${routePaths.USERS}/${customer.user_id}`}>
            <div className="d-flex flex-row align-items-start">
              <FullName user={customer} />
              {customer && <UserBadges data={customer} />}
            </div>
          </Link>
        );
      },
    },
    {
      dataField: 'feedback',
      text: 'Feedback',
      formatter: (feedback: string, complaint: Complaint) => {
        const { statuses, tooltips } = getStatusInfo(complaint);

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <span>{complaint?.feedback}</span>
            <div className="float-right">
              {tooltips.map((tooltip, index) => (
                <span
                  key={index}
                  className="badge bg-primary ml-1"
                  style={{ color: 'white', marginLeft: 'auto' }}
                  title={tooltip}
                >
                  {statuses[index]}
                </span>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'kitchen_representative',
      text: 'Kitchen Representative',
      formatter: (kitchen_representative: any) => {
        switch (kitchen_representative) {
          case USER_ROLE.KITCHEN_REPRESENTATIVE_MAIN:
            return <span>{DISPLAY_KITCHEN_MAIN}</span>;
          case USER_ROLE.KITCHEN_REPRESENTATIVE_OTHER:
            return <span>{DISPLAY_KITCHEN_OTHER}</span>;
          default:
            return <span>N/A</span>;
        }
      },
    },
    {
      dataField: 'complaint_categories',
      text: 'Categories',
      formatter: (categories: ComplaintCategory[]) => {
        return (
          <>
            {categories.map((category, index) => (
              <span
                key={index}
                className="badge bg-primary mr-1"
                style={{ color: 'white' }}
              >
                {category.name}
              </span>
            ))}
          </>
        );
      },
    },
    {
      dataField: 'reported_at',
      text: 'Reported Date',
      formatter: (reportedAt: string) => (
        <DatetimeAMPM value={reportedAt} />
      ),
    },
    {
      dataField: 'order.delivery_datetime',
      text: 'Order Delivery Date Time',
      formatter: (reportedAt: string) => (
        <DatetimeAMPM value={reportedAt} />
      ),
    },
    {
      dataField: '',
      text: 'Actions',
      formatter: (_: unknown, complaint: Complaint) => (
        <div className="d-flex flex-row">
          {/* @ts-ignore */}
          <IconButton
            onClick={() =>
              onShowDetails && onShowDetails(complaint.id)
            }
            color="info"
            variant="link"
          >
            <Icon name="pencil" />
          </IconButton>
          {/* @ts-ignore-line */}
          <IconButton
            className={
              isAdmin || isExecutiveAssistant ? '' : 'd-none'
            }
            color="danger"
            variant="link"
            onClick={() => _onDelete(complaint.id as string)}
          >
            <Icon name="delete" />
          </IconButton>
          {/* @ts-ignore-line */}
          <IconButton
            color="danger"
            variant={'link'}
            className={complaint.assets.length > 0 ? '' : 'd-none'}
            onClick={() => handleShowImagePreview(complaint.assets)}
          >
            <Icon name="paperclip" />
          </IconButton>
        </div>
      ),
    },
  ];

  const onChangeStatuses = (statuses: string) => {
    onStatusesFilterChange(statuses);
  };

  const handleCloseImagePreview = () => {
    setShowImagePreview(false);
  };

  return (
    <>
      {allowFilter && <ComplaintListFilter />}
      <Table
        remote
        loading={isLoading}
        keyField="id"
        columns={columns}
        data={data}
        pagination={{
          page,
          sizePerPage: perPage,
          totalSize,
        }}
        onTableChange={onTableChange}
      />
      <ImagePreview
        images={images}
        show={showImagePreview}
        closePreview={handleCloseImagePreview}
        key={'imagePreview'}
      />
    </>
  );
};

export default ComplaintsTable;
