import React from 'react';
import PropTypes from 'prop-types';

const OrderNumber = ({ number }) => {
  return <React.Fragment>#{number}</React.Fragment>;
};

OrderNumber.propTypes = {
  number: PropTypes.number.isRequired,
};

export default OrderNumber;
