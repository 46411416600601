import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import { isEqual, omit, isArray } from 'lodash';
import { Form } from 'react-bootstrap';

import Label from '../Label';

const Input = (props) => (
  <components.Input {...props} isHidden={false} />
);

const AutoComplete = ({
  value,
  onChange,
  options,
  name,
  label,
  error,
  required,
  helperText,
  isMulti,
  disabled,
  getOptionValue,
  getOptionLabel,
  formatOptionLabel,
  disableOptionGroup,
  components,
  ...otherProps
}) => {
  const HelperText = error ? Form.Control.Feedback : Form.Text;

  const findOptionByValue = (optionValue) =>
    options.find((option) =>
      isEqual(getOptionValue(option), optionValue),
    );

  const getValue = () => {
    if (options && value) {
      return isMulti
        ? value.map(findOptionByValue)
        : findOptionByValue(value);
    } else {
      return isMulti ? [] : '';
    }
  };

  const handleDisableOptionGroup = (selectedOption) => {
    if (isArray(selectedOption)) {
      return selectedOption.map(handleDisableOptionGroup);
    }

    return findOptionByValue(getOptionValue(selectedOption));
  };

  const handleOnChange = (selectedOption, { action }) => {
    if (selectedOption === null || action === 'clear') {
      onChange(null, selectedOption);
      return;
    }

    if (disableOptionGroup)
      selectedOption = handleDisableOptionGroup(selectedOption);
    // this is to ensure that the selected option object passed to "onChange" callback is complete (with "options" key)
    // because if "disableOptionGroup" prop is true, "options" key will be removed from each option object passed through the "options" prop (refer to comments in `getOptions` below)
    // this is to simply revert the operation done on option objects when calling back "onChange"

    onChange(
      isMulti
        ? selectedOption.map((item) => getOptionValue(item))
        : getOptionValue(selectedOption),
      selectedOption,
    );
  };

  const getOptions = () => {
    if (disableOptionGroup)
      return options.map((option) => omit(option, 'options'));
    // react-select will take nested 'options' key in the option object as grouped options
    // this will cause a problem if the nested `options` key is not an array
    // to prevent it, omit the `options` key unless grouped options is really needed
    // watch this issue: https://github.com/JedWatson/react-select/issues/3706

    return options;
  };

  return (
    <Form.Group>
      {label && (
        <Label htmlFor={name} error={error} required={required}>
          {label}
        </Label>
      )}
      <ReactSelect
        className={error && 'is-invalid'}
        {...otherProps}
        value={getValue()}
        onChange={handleOnChange}
        options={getOptions()}
        isMulti={isMulti}
        isDisabled={disabled}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        formatOptionLabel={formatOptionLabel}
        components={{ ...components, Input }}
        styles={{
          singleValue: (provided, state) => {
            return {
              ...provided,
              color: state.selectProps.menuIsOpen
                ? '#d0d0d0'
                : '#000',
            };
          },
        }}
      />
      {helperText && (
        <HelperText type={error && 'invalid'}>
          {helperText}
        </HelperText>
      )}
    </Form.Group>
  );
};

AutoComplete.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  disableOptionGroup: PropTypes.bool,
};

AutoComplete.defaultProps = {
  name: null,
  label: null,
  error: false,
  required: false,
  helperText: null,
  isMulti: false,
  disabled: false,
  getOptionValue: (option) => option.value,
  getOptionLabel: (option) => option.label,
  formatOptionLabel: undefined,
  disableOptionGroup: true,
};

export default AutoComplete;
