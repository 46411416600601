import { useState } from 'react';
import { saveAs } from 'file-saver';
import contentDisposition from 'content-disposition';

import config from 'appConfig';
import { useConfirmation } from 'lib/confirm';
import notification from 'lib/notification';

import { getOrdersExport, getOrdersExportNow } from './api';

const useOrdersExport = () => {
  const [isExportingOrders, setIsExportingOrders] = useState(false);

  const { alert } = useConfirmation();

  const requestOrdersExportAsync = async ({
    delivery_end,
    delivery_start,
    order_statuses,
  }) => {
    try {
      setIsExportingOrders(true);

      await getOrdersExport({
        delivery_end,
        delivery_start,
        order_statuses,
      });

      await alert({
        title: 'Your Orders are Being Exported',
        content:
          "We'll email to you your orders export in a few minutes. For now, Sit back and relax! ",
      });
    } catch (err) {
      notification.error(
        'Something went wrong when exporting orders',
      );
    } finally {
      setIsExportingOrders(false);
    }
  };

  const requestOrdersExportSync = async ({
    delivery_end,
    delivery_start,
    order_statuses,
  }) => {
    try {
      setIsExportingOrders(true);

      const response = await getOrdersExportNow({
        delivery_end,
        delivery_start,
        order_statuses,
      });

      const contentDispositionHeader =
        response.headers['content-disposition'];

      const parsedContentDispositionHeader = contentDisposition.parse(
        contentDispositionHeader,
      );

      const { filename } = parsedContentDispositionHeader.parameters;

      const orderInvoiceData = response.data;

      saveAs(orderInvoiceData, filename);
    } catch (err) {
      notification.error(
        'Something went wrong when exporting orders',
      );
    } finally {
      setIsExportingOrders(false);
    }
  };

  const requestOrdersExport = ({
    delivery_end,
    delivery_start,
    order_statuses,
  }) => {
    if (config.featureToggle.ordersExport === 'sync')
      return requestOrdersExportSync({
        delivery_end,
        delivery_start,
        order_statuses,
      });
    return requestOrdersExportAsync({
      delivery_end,
      delivery_start,
      order_statuses,
    });
  };

  return {
    isExportingOrders,
    requestOrdersExport,
  };
};

export default useOrdersExport;
