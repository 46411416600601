import httpClient, { getErrorMessage } from 'lib/httpClient';
import { KitchenProductPayload, ReportCategory } from './types';

export const getKitchenReport = async (body: KitchenProductPayload, reportCategory: ReportCategory) => {
  try {
    const response = await httpClient.post(
      `/cms/reports/kitchen/${reportCategory}`,
      body,
    );

    return response;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
