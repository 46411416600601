import { useListCRUD } from 'lib/swr-crud';

const useUsers = ({
  page,
  sizePerPage,
  name,
  email,
  search,
  sortField,
  sortDir,
  phoneNumberFlag,
}) => {
  const url = '/cms/users';

  const { data, error, create, isLoading, mutate } = useListCRUD(
    url,
    'user_id',
    {
      query: {
        page,
        per_page: sizePerPage,
        name,
        search,
        email,
        sort_field: sortField,
        sort_dir: sortDir,
        phone_number: phoneNumberFlag,
      },
    },
  );
  const users = data?.data || [];
  const total = data?.meta?.total || 0;

  return {
    users,
    create,
    error,
    isLoading,
    mutate,
    total,
  };
};

export default useUsers;
