import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

import { Select } from 'components/Common';
import { isEmpty, isNil } from 'lib/javascript';

import useOrderStatus from './useOrderStatus';
import {
  mapOrderStateToOptionValue,
  mapOrderStatusToOptions,
} from './utils';

const OrderStatus = ({
  styles,
  value,
  label,
  disabled,
  isLoading: isLoadingProp,
  onChange,
}) => {
  const {
    isLoading: isOrderStatusLoading,
    orderStatus,
  } = useOrderStatus();

  React.useEffect(() => {
    if (isNil(value) || (isEmpty(value) && orderStatus))
      onChange(mapOrderStateToOptionValue(orderStatus[0]));
  }, [value, onChange, orderStatus]);

  const handleOnChange = (e) => {
    const newValue = e.target.value;
    onChange(newValue);
  };

  const isLoading = isLoadingProp || isOrderStatusLoading;

  if (isLoading)
    return (
      <ContentLoader height={35} style={{ width: '100%' }}>
        <rect x="0" y="0" rx="0" ry="0" width="680" height="25" />
      </ContentLoader>
    );

  return (
    <Select
      styles={styles}
      value={value}
      label={label}
      onChange={handleOnChange}
      disabled={disabled}
      options={mapOrderStatusToOptions(orderStatus)}
    />
  );
};

OrderStatus.propTypes = {
  /**
   * order status value e.g. 'awaiting-payment'
   */
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

OrderStatus.defaultProps = {
  onChange: () => {},
  isLoading: false,
};

export default OrderStatus;
