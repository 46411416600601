import { Page } from 'components/Common';
import {
  AddComplaintCategoryForm,
  ComplaintCategoryPayload,
  storeComplaintCategory,
} from 'lib/complaint-categories';
import notification from 'lib/notification';
import { Card, CardBody } from 'reactstrap';
// @ts-ignore-line
import { pick } from 'lib/javascript';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'appConfig';

const NewComplaintCategoryPage = () => {
  const history = useHistory();

  const onSubmit = async (payload: ComplaintCategoryPayload) => {
    try {
      const name = pick(payload, ['name']);
      await storeComplaintCategory(name);
      history.replace(routePaths.COMPLAINT_CATEGORIES);
      notification.success('Feedback category added.');
    } catch (error) {
      notification.error(
        'Something went wrong while adding new complaint category.',
      );
    }
  };
  return (
    <Page
      content={
        <Card>
          <CardBody>
            <AddComplaintCategoryForm onSubmit={onSubmit} />
          </CardBody>
        </Card>
      }
    />
  );
};

export default NewComplaintCategoryPage;
