import httpClient, { getErrorMessage, getResponseData } from 'lib/httpClient';

export const updateEventDonationActiveStatus = async (
	donationId,
	{ is_active }
) => {
	try {
		const response = await httpClient.put(
			`cms/events/donations/${donationId}/activate`,
			{ is_active }
		);

		return { response: getResponseData(response) };
	} catch (err) {
		return { error: getErrorMessage(err) };
	}
};

export const activateEventDonation = (donationId) =>
  updateEventDonationActiveStatus(donationId, { is_active: true });

export const deactivateEventDonation = (donationId) =>
	updateEventDonationActiveStatus(donationId, { is_active: false });

export const postCreateDonation = async (donation) => {
  try {
    const response = await httpClient.post('/cms/events/donations', donation);
    return getResponseData(response);
  } catch (err) {
    getErrorMessage(err);
  }
};

export const getDonationById = async (donationId) => {
  try {
    const response = await httpClient.get(
      `/cms/events/donations/${donationId}`,
    );
    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putUpdateDonation = async (donationId, changes) => {
  try {
    const response = await httpClient.put(
      `/cms/events/donations/${donationId}`,
      changes,
    );
    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteDonation = async (donationId) => {
  try {
    const response = await httpClient.delete(
      `/cms/events/donations/${donationId}`,
    );
    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
