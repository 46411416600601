import React from 'react';
import classNames from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import tableFilter from './tableFilter';
import propTypes from './propTypes';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './tableFilter.css';
import './Table.css';
import LoadingOverlay from './LoadingOverlay';

const Table = ({
  keyField,
  data,
  columns,
  remote,
  noDataIndication,
  loading,
  caption,
  striped,
  bordered,
  hover,
  condensed,
  id,
  tabIndexCell,
  classes,
  wrapperClasses,
  headerClasses,
  headerWrapperClasses,
  bodyClasses,
  cellEdit,
  selectRow,
  rowStyle,
  rowClasses,
  rowEvents,
  hiddenRows,
  sort,
  defaultSorted,
  defaultSortDirection,
  pagination,
  filter,
  filterPosition,
  onTableChange,
  onDataSizeChange,
}) => {
  return (
    <LoadingOverlay active={loading}>
      <BootstrapTable
        // IMPORTANT:
        // do not use 'loading' prop and 'overlay' prop for loading indicator
        // due to its rendering method, it will unmount and re-mount the whole table component
        // see here: https://github.com/react-bootstrap-table/react-bootstrap-table2/blob/master/packages/react-bootstrap-table2/src/bootstrap-table.js#L38-L46
        // This causes several issues such as filter text input getting unfocused when the loading overlay is displayed while still typing
        // and also potential performance issue
        bootstrap4
        pagination={
          pagination
            ? paginationFactory(pagination)
            : paginationFactory()
        }
        keyField={keyField}
        data={data}
        columns={columns}
        remote={remote}
        noDataIndication={noDataIndication}
        caption={caption}
        striped={striped}
        bordered={bordered}
        hover={hover}
        condensed={condensed}
        id={id}
        tabIndexCell={tabIndexCell}
        classes={classes}
        wrapperClasses={classNames(
          'table-responsive',
          wrapperClasses,
        )}
        headerClasses={classNames('thead-light', headerClasses)}
        headerWrapperClasses={headerWrapperClasses}
        bodyClasses={bodyClasses}
        cellEdit={cellEdit}
        selectRow={selectRow}
        rowStyle={rowStyle}
        rowClasses={rowClasses}
        rowEvents={rowEvents}
        hiddenRows={hiddenRows}
        sort={sort}
        defaultSorted={defaultSorted}
        defaultSortDirection={defaultSortDirection}
        filter={filter}
        filterPosition={filterPosition}
        onTableChange={onTableChange}
        onDataSizeChange={onDataSizeChange}
      />
    </LoadingOverlay>
  );
};

Table.propTypes = propTypes;

Table.defaultProps = {
  noDataIndication: 'No Data',
  bordered: false,
  filter: tableFilter(),
};

export default Table;
