import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Common';
import { merge, mapValues } from 'lib/javascript';
import { FormManager, Form } from 'lib/form';
import validator from 'lib/validator';
import notification from 'lib/notification';
import {
  MacroFields,
  macroValidationSchema,
  macroInitialValues,
} from 'lib/nutrition';
import { putUpdateProductVariantNutrition } from './api';
import { isNil } from 'lib/javascript';

const ProductVariantNutritionForm = ({
  productVariantId,
  initialValues,
  onSubmit,
}) => {
  const defaultValues = {
    constant: macroInitialValues,
  };

  const requiredFields = {
    calorie: true,
    protein: true,
    fat: true,
    carb: true,
    sodium: true,
  };

  const validationSchema = validator.object({
    constant: macroValidationSchema(requiredFields),
  });

  const handleSubmit = async (formValues, actions) => {
    try {
      actions.setStatus({
        error: null,
      });

      const floatConstant = mapValues(
        formValues.constant,
        (value, key) => {
          return !isNil(value) ? Math.round(value * 100) / 100 : null;
        },
      );

      const updatedNutrition = await putUpdateProductVariantNutrition(
        productVariantId,
        {
          ...formValues,
          constant: floatConstant,
        },
      );

      notification.success('Successfully updated nutritional values');

      onSubmit(null, updatedNutrition);
    } catch (err) {
      actions.setStatus({ error: Object.values(err?.errors)?.[0] });
      actions.setFieldError(Object.keys(err?.errors)?.[0]);

      notification.error(
        'Something went wrong when updating nutritional values',
      );

      onSubmit(err);
    }
  };

  return (
    <FormManager
      initialValues={merge(defaultValues, initialValues)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <MacroFields
            name="constant"
            requiredFields={Object.keys(requiredFields)}
          />
          <Button type="submit" loading={isSubmitting}>
            Update Nutritional Values
          </Button>
        </Form>
      )}
    </FormManager>
  );
};

ProductVariantNutritionForm.propTypes = {
  productVariantId: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

ProductVariantNutritionForm.defaultProps = {
  initialValues: {},
  onSubmit: () => {},
};

export default ProductVariantNutritionForm;
