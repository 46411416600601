import { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactLoadingOverlay from 'react-loading-overlay';

import { Spinner } from 'components/Common';

const LoadingOverlay = ({ active, children }) => {
  const overlay = useRef(null);

  const getOverlayWrapper = () => {
    if (!overlay.current) return null;

    const { wrapper } = overlay.current;

    return wrapper;
  };

  const getTHeadHeight = () => {
    const wrapper = getOverlayWrapper();

    if (!wrapper) return 0;

    const headerDOM = wrapper.current.parentElement.querySelector(
      'thead',
    );

    return window.getComputedStyle(headerDOM).height;
  };

  const getTBodyHeight = () => {
    const wrapper = getOverlayWrapper();

    if (!wrapper) return 0;

    const bodyDOM = wrapper.current.parentElement.querySelector(
      'tbody',
    );

    if (!bodyDOM) return 0;

    return window.getComputedStyle(bodyDOM).height;
  };

  return (
    <ReactLoadingOverlay
      ref={overlay}
      // NOTE: do not set 'spinner' prop to true for a default spinner UI
      // because the 'react-loading-overlay' package is using 'emotion' package as css-in-js solution
      // There's an issue of 'emotion' package in production build which causes keyframes to be overwritten
      // and therefore the spinner is not animating
      // see more here: https://github.com/emotion-js/emotion/issues/2221
      spinner={<Spinner />}
      active={active}
      styles={{
        overlay: (base) => ({
          ...base,
          marginTop: getTHeadHeight(),
          height: getTBodyHeight(),
        }),
      }}
    >
      {children}
    </ReactLoadingOverlay>
  );
};

LoadingOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
};

LoadingOverlay.defaultProps = {
  active: false,
};

export default LoadingOverlay;
