import { Button, Modal } from 'components/Common';
import { SelectField } from 'components/formFields';
import { Form, FormManager } from 'lib/form';
import notification from 'lib/notification';
import yup from 'lib/validator';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { dayOptions, defaultHourOptions, timeSlotOptions } from './constants';
import type { CollectionPointSlotLine } from './types';

interface CollectionPointSlotModalProps {
  isOpen: boolean;
  title: string;
  buttonLabel: string;
  initialValues?: CollectionPointSlotLine;
  onClose: () => void;
  onSubmit: (line: CollectionPointSlotLine) => Promise<void>;
}

const defaultFormValues: CollectionPointSlotLine = {
  id: '',
  day: '',
  hour: '',
};

const validationSchema = yup.object({
  day: yup.string().required(),
  hour: yup.string().required(),
});

const CollectionPointSlotModal = ({
  isOpen,
  title,
  buttonLabel,
  onClose,
  onSubmit,
  initialValues = defaultFormValues,
}: CollectionPointSlotModalProps) => {
  const [hourOptions, setHourOptions] = useState<
    Array<{ value: string, label: string }>
  >([defaultHourOptions]);

  useEffect(() => {
    const { day } = initialValues;
    setHourOptions(day ? timeSlotOptions[day] : [defaultHourOptions]);
  }, [initialValues]);

  const _onSubmit = async (line: CollectionPointSlotLine) => {
    const isUpdate = initialValues.day !== defaultFormValues.day
      && initialValues.hour !== defaultFormValues.hour;

    try {
      await onSubmit(line);
      notification.success(`Successfully ${
        isUpdate ? 'update' : 'add new'
      } Collection Slot`);
      onClose && onClose();
    } catch (err) {
      let message = err;
      if (typeof err === 'object') {
        const errors = err.errors;
        if (errors && errors.message) {
          message = errors.message
        }
      }
      notification.error(message || `Error ${isUpdate
        ? 'updating'
        : 'creating'
      } collection slot`);
    }
  };

  return (
    // @ts-expect-error
    <Modal
      open={isOpen}
      onClose={onClose}
      title={title}
    >
      <FormManager
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (line: CollectionPointSlotLine) => {
          await _onSubmit(line);
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <Row>
              <Col>
                {/* @ts-expect-error */}
                <SelectField
                  label="Day"
                  name="day"
                  options={dayOptions}
                  required
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    const { value } = event.target;
                    setFieldValue('day', value);
                    setHourOptions(value ? timeSlotOptions[value] : [defaultHourOptions]);
                    setFieldValue('hour', '');
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* @ts-expect-error */}
                <SelectField
                  label="Hour"
                  name="hour"
                  options={hourOptions}
                  required
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    const { value } = event.target;
                    setFieldValue('hour', value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* @ts-expect-error */}
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                  color="primary"
                >
                  {buttonLabel}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </FormManager>
    </Modal>
  );
};

export default CollectionPointSlotModal;
