import { useEffect } from 'react';

import { isEmpty } from 'lib/javascript';

import {
  getDefaultAddonOptionValue,
  getNextVisibleSelectedAddons,
} from '../utils';

// NOTE: difference between CMS and storefront
// On the storefront, the default selected addons are needed to be set every time the showing product changed
// On the CMS, doing this will result in setting the default selected addons for the EditOrderLineAddonsForm
// which is not what desired because we want to populate the saved selected addons
// therefore, on the CMS `shouldSetDefaultSelectedAddons` in `useEffect` is not dependant on the product variant ID

const useSetDefaultSelectedAddons = ({
  selectedAddons,
  setSelectedAddons,
  addonOptions,
  addonRules,
}) => {
  const selectedAddonsStr = JSON.stringify(selectedAddons);

  useEffect(() => {
    const setDefaultSelectedAddonOptions = () => {
      const selectedAddons = JSON.parse(selectedAddonsStr);

      const areAddonOptionsAndRulesFetched =
        !isEmpty(addonOptions) && !isEmpty(addonRules);

      const shouldSetDefaultSelectedAddons =
        areAddonOptionsAndRulesFetched && isEmpty(selectedAddons);

      if (!shouldSetDefaultSelectedAddons) return;

      const defaultSelectedAddons = addonOptions.reduce(
        (result, addonOption) => {
          result[addonOption.id] = getDefaultAddonOptionValue(
            addonOption,
          ).id;

          return result;
        },
        {},
      );

      const nextVisibleSelectedAddons = getNextVisibleSelectedAddons({
        addonOptions,
        addonRules,
        selectedAddons: defaultSelectedAddons,
      });

      setSelectedAddons(nextVisibleSelectedAddons);
    };

    setDefaultSelectedAddonOptions();
  }, [
    selectedAddonsStr,
    addonOptions,
    addonRules,
    setSelectedAddons,
  ]);
};

export default useSetDefaultSelectedAddons;
