import React from 'react';
import PropTypes from 'prop-types';
import yup from 'lib/validator';

import { FormManager, Form, GeneralError } from 'lib/form';
import { useProductVariant } from 'lib/productVariants';
import notification from 'lib/notification';
import { TextField } from 'components/formFields';
import { Button } from 'components/Common';

import { putProductVariant } from './api';
import { List } from 'react-content-loader';

const validationSchema = yup.object({
  sku: yup.string().required(),
  stock: yup.number().required(),
  price: yup.number().required(),
  alias: yup.string().required(),
});

const UpdateVariantForm = ({ variantId, onSubmit }) => {
  const { productVariant, mutate, isLoading } = useProductVariant(
    variantId,
  );
  const handleOnSubmit = async (formValues, actions) => {
    try {
      const { response, error } = await putProductVariant(
        variantId,
        formValues,
      );

      if (error) throw error;

      mutate(response.data);

      notification.success('Successfully updated product variant');
      onSubmit(null, response);
      return;
    } catch (error) {
      actions.setErrors(error.errors);
      actions.setStatus({
        error:
          error.errors?.message ||
          'Error updating product product variant',
      });
      onSubmit(error, null);
      return;
    }
  };

  if (isLoading) {
    return <List uniqueKey="variant-form-loading" />;
  }

  return (
    <FormManager
      initialValues={productVariant}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting, values }) => {
        return (
          <Form>
            <GeneralError />
            <TextField name="sku" label="SKU" required />
            <TextField
              name="stock"
              label="Stock"
              type="number"
              required
            />
            <TextField
              name="price"
              label="Price"
              type="number"
              required
            />
            <TextField name="alias" label="Alias" required />
            <Button type="submit" loading={isSubmitting}>
              Save Changes
            </Button>
          </Form>
        );
      }}
    </FormManager>
  );
};

UpdateVariantForm.propTypes = {
  variantId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

UpdateVariantForm.defaultProps = {
  onSubmit: () => {},
};

export default UpdateVariantForm;
