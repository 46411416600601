import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';

import { DatePicker, Select } from 'components/Common';
import datetime, { formatTime } from 'lib/datetime';

import {
  DELIVERY_TIMESLOT_DURATION_HOURS,
  AVAILABLE_DELIVERY_DAYS,
} from '../constants';
import { getDeliveryTimesByDate } from '../utils';
import { useDeliverySlotsContext } from 'lib/operation';

const DeliveryDateTimePicker = ({
  value,
  onChange,
  disabled,
  helperText,
}) => {
  const {
    availableDeliverySlots,
    isLoading,
  } = useDeliverySlotsContext();

  const handleDateOnChange = (newDeliveryDate) => {
    const newDeliveryDateTime = getDeliveryTimesByDate(
      newDeliveryDate,
    )?.[0];

    onChange(newDeliveryDateTime.toISOString());
  };

  const handleTimeOnChange = (e) => {
    const value = e.target.value;

    onChange(value);
  };

  useEffect(() => {
    const callbackInitialValue = () => {
      if (isLoading) return;

      if (!value) {
        // onChange(nextDeliverySlot.toISOString());
        return;
      }
    };

    callbackInitialValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDeliverySlots, isLoading]);

  const getCurrentTimesOptions = () => {
    return getDeliveryTimesByDate(value).map((deliveryTime) => ({
      value: deliveryTime.toISOString(),
      label: `${formatTime(deliveryTime)} - ${formatTime(
        datetime(deliveryTime).add(
          DELIVERY_TIMESLOT_DURATION_HOURS,
          'hours',
        ),
      )}`,
    }));
  };

  const getDisabledDays = (day) => {
    const isDeliveryDay = AVAILABLE_DELIVERY_DAYS.includes(
      datetime(day).day(),
    );

    return !isDeliveryDay;
  };

  return (
    <Container fluid style={{ padding: 0 }}>
      <Row>
        <Col xs="auto">
          <DatePicker
            label="Delivery Date"
            value={value ? datetime(value).toDate() : null}
            onChange={handleDateOnChange}
            disabled={disabled || isLoading}
            disabledDays={getDisabledDays}
          />
        </Col>
        <Col xs="auto">
          <Select
            label="Delivery Time"
            disabled={disabled || isLoading}
            value={value ? datetime(value).toISOString() : null}
            onChange={handleTimeOnChange}
            options={getCurrentTimesOptions()}
          />
        </Col>
      </Row>
      {helperText}
    </Container>
  );
};

DeliveryDateTimePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DeliveryDateTimePicker;
