import React from 'react';
import notification from 'lib/notification';
import { List } from 'react-content-loader';
import { useCollection } from 'lib/collection';
import { useHistory, useParams } from 'react-router';
import CollectionDetails from 'lib/collection/CollectionDetails';
import { postAddCollection } from 'lib/collection/api';
import { Page } from 'components/Common';
import NewCollectionForm from 'lib/collection/NewCollectionForm';
import { routePaths } from 'appConfig';

const NewCollectionPage = () => {
  const history = useHistory();

  const onCreateCollection = async (values) => {
    const res = await postAddCollection(values);

    notification.success(`Successfully create collection`);
    history.push(`${routePaths.COLLECTIONS}/${res.id}`);
  };

  return (
    <Page
      content={
        <React.Fragment>
          <NewCollectionForm onSubmit={onCreateCollection} />
        </React.Fragment>
      }
    />
  );
};

NewCollectionPage.propTypes = {};

export default NewCollectionPage;
