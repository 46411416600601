import { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { DISPLAY_KITCHEN_MAIN, DISPLAY_KITCHEN_OTHER } from './utils';
import validator from 'lib/validator';
import { connectInput, FormManager } from 'lib/form';
import { USER_ROLE } from 'lib/users/constants';
import { Button, DatePicker, Icon } from 'components/Common';
import datetime from 'lib/datetime';
import ComplaintCategoryAutoComplete from './CategoryComplaintAutoComplete';
import { useRouter } from 'lib/router';
import ComplaintTags from './ComplaintTags';
import { useDispatch, useSelector } from 'react-redux';
import { getRoleUser } from 'store/auth/GetRoleUser/actions';
import { UserRoleRedux } from 'store/auth/GetRoleUser/types';
import config from 'appConfig';

const DatePickerField = connectInput(DatePicker, {
  onChangeArgType: 'raw-value',
});

const ComplaintCategorySelectorField = connectInput(
  ComplaintCategoryAutoComplete,
  {
    onChangeArgType: 'raw-value',
  },
);

const ComplaintListFilter = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { query, updateQueryParams } = useRouter();
  const dispatch = useDispatch();

  const roleState = useSelector((state: UserRoleRedux) => state.role);

  const { isKitchenRepresentative } = roleState?.role;

  useEffect(() => {
    dispatch(getRoleUser());
  }, []);

  const handleRemoveTag = (key: string, value: string) => {
    let updatedValues: string[] | string | null = null;

    if (
      key === 'status' ||
      key === 'complaint_categories' ||
      key === 'kitchen'
    ) {
      const currentValues = Array.isArray(query[key])
        ? query[key]
        : query[key]?.split(',') || [];

      updatedValues = currentValues.filter(
        (item: string) => item !== value,
      );

      updatedValues = updatedValues.length > 0 ? updatedValues : null;
    } else {
      updatedValues = '';
    }

    updateQueryParams({
      [key]:
        updatedValues !== null
          ? Array.isArray(updatedValues)
            ? updatedValues.join(',')
            : updatedValues
          : null,
    });
  };

  const {
    status,
    kitchen,
    started_reported_at,
    ended_reported_at,
    started_order_delivery_at,
    ended_order_delivery_at,
    complaint_categories,
  } = query;

  const queryInitialValues = {
    status: status ? status.split(',') : [],
    kitchen: kitchen ? kitchen.split(',') : [],
    started_reported_at: started_reported_at ?? '',
    ended_reported_at: ended_reported_at ?? '',
    started_order_delivery_at: started_order_delivery_at ?? '',
    ended_order_delivery_at: ended_order_delivery_at ?? '',
    complaint_categories: complaint_categories
      ? complaint_categories.split(',')
      : [],
  };

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const validationSchema = validator.object({
    status: validator.array().of(validator.string()).optional(),
    kitchen: validator.array().of(validator.string()).optional(),
    started_reported_at: validator.string().optional(),
    ended_reported_at: validator.string().optional(),
    started_order_delivery_at: validator.string().optional(),
    ended_order_delivery_at: validator.string().optional(),
    complaint_categories: validator
      .array(validator.string())
      .optional(),
  });

  const defaultValues = {
    status: [],
    kitchen: [],
    started_reported_at: null,
    ended_reported_at: null,
    started_order_delivery_at: null,
    ended_order_delivery_at: null,
    complaint_categories: [],
  };

  const initialValues = {
    ...defaultValues,
    ...queryInitialValues,
  };

  const handleOnSubmit = async (values: any) => {};

  const handleChange = (
    e: any,
    field: string,
    value: string,
    values: any,
    setFieldValue: any,
  ) => {
    const isChecked = e.target.checked;
    const updatedValues = isChecked
      ? [...values[field], value]
      : values[field].filter((item: string) => item !== value);

    setFieldValue(field, updatedValues);
    updateQueryParams({
      [field]: updatedValues.join(','),
    });
  };

  const handleChangeDate = (
    field: string,
    value: string,
    setFieldValue: any,
  ) => {
    updateQueryParams({
      [field]: value,
    });

    setFieldValue(field, value);
  };

  return (
    <div className="relative">
      <button
        onClick={togglePopover}
        className="btn btn-primary d-flex align-items-center mb-2"
      >
        <i className="bx bx-filter-alt mr-2 font-size-16"></i> Filter
      </button>

      {isPopoverOpen && (
        <FormManager
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting, values, setFieldValue, setValues }) => {
            return (
              <Row
                className="show position-absolute w-100 p-0 z-index-1 bg-transparent"
                style={{
                  backgroundColor: 'white',
                  zIndex: 1000,
                }}
              >
                <Col sm={9} xs={12} lg={6}>
                  <Card className="w-100 shadow border">
                    <CardBody>
                      <div
                        className="float-right mb-2"
                        role="button"
                        onClick={togglePopover}
                      >
                        <Icon name={'close'} fontSize={18} />
                      </div>
                      <br />
                      <span className="font-weight-bold font-size-14">
                        Status
                      </span>

                      <div className="align-items-center mb-2 border-bottom py-2">
                        <>
                          <FormGroup check inline>
                            <Input
                              type="checkbox"
                              id="checkbox-unresolved"
                              checked={values.status.includes(
                                'unresolved',
                              )}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  'status',
                                  'unresolved',
                                  values,
                                  setFieldValue,
                                )
                              }
                            />
                            <Label check for="checkbox-unresolved">
                              Unresolved
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Input
                              type="checkbox"
                              id="checkbox-uncorrected"
                              checked={values.status.includes(
                                'uncorrected',
                              )}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  'status',
                                  'uncorrected',
                                  values,
                                  setFieldValue,
                                )
                              }
                            />
                            <Label check for="checkbox-uncorrected">
                              Uncorrected
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Input
                              type="checkbox"
                              id="checkbox-unverified"
                              checked={values.status.includes(
                                'unverified',
                              )}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  'status',
                                  'unverified',
                                  values,
                                  setFieldValue,
                                )
                              }
                            />
                            <Label check for="checkbox-unverified">
                              Unverified
                            </Label>
                          </FormGroup>
                        </>
                        <i
                          className="bx bx-trash font-size-18 text-danger float-right"
                          onClick={() => {
                            setFieldValue('status', []);
                            updateQueryParams({
                              status: '',
                            });
                          }}
                          role="button"
                        ></i>
                      </div>
                      {!isKitchenRepresentative && (
                        <>
                          <span className="font-weight-bold font-size-14">
                            Kitchen
                          </span>
                          <div className=" align-items-center mb-2 border-bottom py-2">
                            <>
                              <FormGroup check inline>
                                <Input
                                  type="checkbox"
                                  id="checkbox-main-kitchen"
                                  checked={values.kitchen.includes(
                                    USER_ROLE.KITCHEN_REPRESENTATIVE_MAIN,
                                  )}
                                  onChange={(e) =>
                                    handleChange(
                                      e,
                                      'kitchen',
                                      USER_ROLE.KITCHEN_REPRESENTATIVE_MAIN,
                                      values,
                                      setFieldValue,
                                    )
                                  }
                                />
                                <Label
                                  check
                                  for="checkbox-main-kitchen"
                                >
                                  {DISPLAY_KITCHEN_MAIN}
                                </Label>
                              </FormGroup>
                              <FormGroup check inline>
                                <Input
                                  type="checkbox"
                                  id="checkbox-other-kitchen"
                                  checked={values.kitchen.includes(
                                    USER_ROLE.KITCHEN_REPRESENTATIVE_OTHER,
                                  )}
                                  onChange={(e) =>
                                    handleChange(
                                      e,
                                      'kitchen',
                                      USER_ROLE.KITCHEN_REPRESENTATIVE_OTHER,
                                      values,
                                      setFieldValue,
                                    )
                                  }
                                />
                                <Label
                                  check
                                  for="checkbox-other-kitchen"
                                >
                                  {DISPLAY_KITCHEN_OTHER}
                                </Label>
                              </FormGroup>
                            </>
                            <i
                              className="bx bx-trash font-size-18 text-danger float-right"
                              onClick={() => {
                                setFieldValue('kitchen', []);
                                updateQueryParams({
                                  kitchen: '',
                                });
                              }}
                              role="button"
                            ></i>
                          </div>
                        </>
                      )}
                      <div className="d-flex align-items-center mb-2 justify-content-between">
                        <div className="d-flex">
                          {/* @ts-ignore */}
                          <DatePickerField
                            name="started_reported_at"
                            label="Reported Date From"
                            block
                            outputFormat={(output) =>
                              datetime(output)
                                .tz(config.timezone)
                                .startOf('day')
                                .toISOString()
                            }
                            onChange={(e) => {
                              handleChangeDate(
                                'started_reported_at',
                                e,
                                setFieldValue,
                              );
                            }}
                          />
                          <span className="mx-2"></span>
                          {/* @ts-ignore */}
                          <DatePickerField
                            name="ended_reported_at"
                            label="Reported Date To"
                            block
                            outputFormat={(output) =>
                              datetime(output)
                                .tz(config.timezone)
                                .endOf('day')
                                .toISOString()
                            }
                            onChange={(e) => {
                              handleChangeDate(
                                'ended_reported_at',
                                e,
                                setFieldValue,
                              );
                            }}
                          />
                        </div>
                        <i
                          className="bx bx-trash font-size-18 text-danger"
                          onClick={() => {
                            setFieldValue('started_reported_at', '');
                            setFieldValue('ended_reported_at', '');
                            updateQueryParams({
                              started_reported_at: '',
                              ended_reported_at: '',
                            });
                          }}
                          role="button"
                        ></i>
                      </div>
                      <div className="d-flex align-items-center mb-2 justify-content-between ">
                        <div className="d-flex">
                          {/* @ts-ignore */}
                          <DatePickerField
                            name="started_order_delivery_at"
                            label="Order Delivery Date From"
                            block
                            outputFormat={(output) =>
                              datetime(output)
                                .tz(config.timezone)
                                .startOf('day')
                                .toISOString()
                            }
                            onChange={(e) => {
                              handleChangeDate(
                                'started_order_delivery_at',
                                e,
                                setFieldValue,
                              );
                            }}
                          />
                          <span className="mx-2"></span>
                          {/* @ts-ignore */}
                          <DatePickerField
                            name="ended_order_delivery_at"
                            label="Order Delivery Date To"
                            block
                            outputFormat={(output) =>
                              datetime(output)
                                .tz(config.timezone)
                                .endOf('day')
                                .toISOString()
                            }
                            onChange={(e) => {
                              handleChangeDate(
                                'ended_order_delivery_at',
                                e,
                                setFieldValue,
                              );
                            }}
                          />
                        </div>
                        <i
                          className="bx bx-trash font-size-18 text-danger"
                          onClick={() => {
                            setFieldValue(
                              'started_order_delivery_at',
                              '',
                            );
                            setFieldValue(
                              'ended_order_delivery_at',
                              '',
                            );
                            updateQueryParams({
                              started_order_delivery_at: '',
                              ended_order_delivery_at: '',
                            });
                          }}
                          role="button"
                        ></i>
                      </div>

                      <ComplaintCategorySelectorField
                        name="complaint_categories"
                        label="Category"
                        // @ts-ignore
                        onChange={(value) => {
                          setFieldValue(
                            'complaint_categories',
                            value,
                          );
                          updateQueryParams({
                            complaint_categories:
                              value && value.join(','),
                          });
                        }}
                        isMulti
                        helperText={undefined}
                      />
                      <Row>
                        <Col xs={6} className="p-0">
                          {/* @ts-ignore */}
                          <Button
                            block
                            className={'mr-1'}
                            onClick={togglePopover}
                          >
                            Okay
                          </Button>
                        </Col>
                        <Col xs={6} className="p-0">
                          {/* @ts-ignore */}
                          <Button
                            block
                            className={'ml-1'}
                            variant={'outlined'}
                            color="dark"
                            onClick={() => {
                              setValues(defaultValues);
                              updateQueryParams({
                                ...defaultValues,
                                status: '',
                                kitchen: '',
                                complaint_categories: null,
                              });
                            }}
                          >
                            Reset Filter
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            );
          }}
        </FormManager>
      )}

      <ComplaintTags
        data={queryInitialValues}
        removeTag={handleRemoveTag}
      />
    </div>
  );
};

export default ComplaintListFilter;
