import httpClient, {
  getErrorMessage,
  getResponseData,
} from 'lib/httpClient';
import {
  Catalogue,
  CatalogueItemPayload,
  CataloguePayload,
} from './types';

const url = '/cms/catalogues';

const updateCataloguePublishStatus = async (
  catalogueId: number | string,
  { publish }: { publish: boolean },
) => {
  try {
    const response = await httpClient.put(
      `${url}/${catalogueId}/publish`,
      { publish },
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const publishCatalogue = async (
  catalogueId: number | string,
) =>
  await updateCataloguePublishStatus(catalogueId, { publish: true });

export const unpublishCatalogue = async (
  catalogueId: number | string,
) =>
  await updateCataloguePublishStatus(catalogueId, { publish: false });

export const postAddCatalogue = async (
  body: CataloguePayload,
): Promise<Catalogue> => {
  try {
    const response = await httpClient.post(url, body);
    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putCatalogue = async (
  catalogueId: string | number,
  body: CataloguePayload,
): Promise<Catalogue> => {
  try {
    const response = await httpClient.put(
      `${url}/${catalogueId}`,
      body,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getCatalogue = async (
  catalogueId: string | number,
): Promise<Catalogue> => {
  try {
    const response = await httpClient.get(`${url}/${catalogueId}`);
    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteCatalogue = async (
  catalogueId: string | number,
) => {
  try {
    const response = await httpClient.delete(`${url}/${catalogueId}`);
    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const addCatalogueItem = async (
  catalogueId: string | number,
  body: CatalogueItemPayload,
) => {
  try {
    delete body.id;
    const response = await httpClient.post(
      `${url}/${catalogueId}/items`,
      body,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const updateCatalogueItem = async (
  catalogueId: string | number,
  itemId: string | number,
  body: CatalogueItemPayload,
) => {
  try {
    delete body.id;
    const response = await httpClient.put(
      `${url}/${catalogueId}/items/${itemId}`,
      body,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteCatalogueItem = async (
  catalogueId: string | number,
  itemId: string | number,
) => {
  try {
    const response = await httpClient.delete(
      `${url}/${catalogueId}/items/${itemId}`,
    );
    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const sortCatalogueItem = async (
  catalogueId: string | number,
  itemId: string | number,
  body: { before_id: string | number },
) => {
  try {
    const response = await httpClient.put(
      `${url}/${catalogueId}/items/${itemId}/rank`,
      { ...body },
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
