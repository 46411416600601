import validator from 'lib/validator';

const maybeRequiredString = (isRequired) => {
  return isRequired
    ? validator.number().nullable().required()
    : validator.number().nullable().notRequired();
};

const macroValidationSchema = (requiredFields = {}) =>
  validator.object({
    calorie: maybeRequiredString(requiredFields?.calorie ?? false),
    protein: maybeRequiredString(requiredFields?.protein ?? false),
    fat: maybeRequiredString(requiredFields?.fat ?? false),
    carb: maybeRequiredString(requiredFields?.carb ?? false),
    sodium: maybeRequiredString(requiredFields?.sodium ?? false),
  });

export default macroValidationSchema;
