import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { routePaths } from 'appConfig';
import { Button, Page } from 'components/Common';
import { useRouter } from 'lib/router';
import { mapValues } from 'lib/javascript';
import { AddonRulesTable, useAddonRules } from 'lib/addon-rules';
import { useHistory } from 'react-router';

const IngredientsPage = () => {
  const history = useHistory();
  const { query, updateQueryParams } = useRouter();
  const [filter, setFilter] = React.useState({ label: '' });

  const {
    page = 1,
    sizePerPage = 15,
    sortField = 'label',
    sortDir = 'desc',
  } = query;

  const { isLoading, addonRules, total } = useAddonRules({
    page,
    sizePerPage,
    ...filter,
    sortField,
    sortDir,
  });

  const handleOnTableChange = (action, newState) => {
    switch (action) {
      case 'pagination':
        updateQueryParams({
          page: newState.page,
          sizePerPage: newState.sizePerPage,
        });
        break;

      case 'filter':
        const mappedFilters = mapValues(
          newState.filters,
          (filter) => filter.filterVal,
        );

        const label = mappedFilters['label'];

        setFilter({ label });
        updateQueryParams({
          page: 1,
          sizePerPage: newState.sizePerPage,
        });

        break;

      case 'sort':
        const { sortField, sortOrder } = newState;

        updateQueryParams({
          sortField,
          sortDir: sortOrder,
        });

        break;

      default:
    }
  };

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <Row>
              <Col
                xs={12}
                className="d-flex justify-content-end align-item-center mb-2"
              >
                <div>
                  <Button
                    onClick={() => {
                      history.push(routePaths.NEW_ADDON_RULE);
                    }}
                  >
                    Add Addon Rule
                  </Button>
                </div>
              </Col>
            </Row>

            <AddonRulesTable
              loading={isLoading}
              data={addonRules}
              page={parseInt(page)}
              sizePerPage={parseInt(sizePerPage)}
              totalSize={total}
              onTableChange={handleOnTableChange}
              getPathToDetailsPage={(obj) =>
                `${routePaths.ADDON_RULES}/${obj.id}`
              }
              sortDir={sortDir}
              sortField={sortField}
            />
          </CardBody>
        </Card>
      }
    />
  );
};

IngredientsPage.propTypes = {};

export default IngredientsPage;
