import React from 'react';
import PropTypes from 'prop-types';

import TextInput from '../TextInput';

const PasswordInput = ({
  name,
  className,
  label,
  helperText,
  value,
  onChange,
  onBlur,
  required,
  error,
}) => {
  return (
    <TextInput
      type="password"
      name={name}
      className={className}
      label={label}
      helperText={helperText}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      required={required}
      error={error}
    />
  );
};

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
};

PasswordInput.defaultProps = {
  className: null,
  label: null,
  helperText: null,
  value: null,
  onChange: undefined,
  onBlur: () => {},
  required: false,
};

export default PasswordInput;
