import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { routePaths } from 'appConfig';
import { postJwtLogin, postJwtLogout } from 'services/api';
import sessionManager from 'lib/sessionManager';
import { getErrorMessage } from 'lib/httpClient';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';

function* loginUser({ payload: { user, history } }) {
  sessionManager.setRememberMe(user.rememberMe);

  const { response, error } = yield call(postJwtLogin, {
    email: user.email,
    password: user.password,
  });

  if (response) {
    sessionManager.startSession(response.access_token);
    localStorage.setItem('authUser', JSON.stringify(response));
    yield put(loginSuccess(response));

    history.push(routePaths.HOME);
  } else {
    yield put(apiError(error.errors));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('authUser');

    const { response, error } = yield call(postJwtLogout);

    if (error) throw error;

    sessionManager.endSession();
    history.push('/login');
    yield put(logoutUserSuccess(response));
  } catch (error) {
    if (error.response.status === 401) history.push('/login');

    const errorData = getErrorMessage(error);

    yield put(apiError(errorData.errors));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
