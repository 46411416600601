import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Common';
import useOrderInvoice from './useOrderInvoice';

const OrderInvoiceButton = ({
  orderId,
  variant,
  label,
  ...props
}) => {
  const {
    isRequestingOrderInvoice,
    requestOrderInvoice,
  } = useOrderInvoice(orderId);

  return (
    <Button
      {...props}
      loading={isRequestingOrderInvoice}
      variant={variant}
      onClick={requestOrderInvoice}
    >
      {label}
    </Button>
  );
};

OrderInvoiceButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  variant: PropTypes.string,
  label: PropTypes.string,
};

OrderInvoiceButton.defaultProps = {
  variant: 'link',
  label: 'Invoice',
};

export default OrderInvoiceButton;
