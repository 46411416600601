import config from 'appConfig';
import { Page } from 'components/Common';
import { ComplaintCategorySummeries } from 'lib/complaint-categories';
import useComplaintCategorySummaries from 'lib/complaint-categories/use-complaint-category-summaries';
import ComplaintsTable from 'lib/complaint/ComplaintsTable';
import useComplaints from 'lib/complaint/use-complaints';
import datetime from 'lib/datetime';
import { useRouter } from 'lib/router';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

const ComplaintsPage = () => {
  const { query, updateQueryParams, setQueryParams } = useRouter();
  const history = useHistory();

  const {
    page = 1,
    sizePerPage = 15,
    search = '',
    status = '',
    kitchen = '',
    started_reported_at = undefined,
    ended_reported_at = undefined,
    started_order_delivery_at = '',
    ended_order_delivery_at = '',
    complaint_categories = '',
  } = query;

  useEffect(() => {
    let params = {};
    if (
      started_reported_at === undefined &&
      ended_reported_at === undefined
    )
      params = {
        ...query,
        started_reported_at: datetime()
          .tz(config.timezone)
          .startOf('month')
          .toISOString(),
        ended_reported_at: datetime()
          .tz(config.timezone)
          .endOf('day')
          .toISOString(),
      };

    updateQueryParams(params);
  }, []);

  const {
    complaints,
    isLoading,
    mutate,
    onDelete,
    total,
  } = useComplaints({
    params: {
      page,
      perPage: sizePerPage,
      search,
      status,
      kitchen,
      started_reported_at,
      ended_reported_at,
      started_order_delivery_at,
      ended_order_delivery_at,
      complaint_categories,
    },
  });

  const {
    complaintCategorySummaries,
    isLoading: isLoadingComplaintCategories,
  } = useComplaintCategorySummaries({
    params: {
      status,
      kitchen,
      started_reported_at,
      ended_reported_at,
      started_order_delivery_at,
      ended_order_delivery_at,
      complaint_categories,
    },
  });

  const onTableChange = async (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => {
    switch (action) {
      case 'pagination':
        const { page: _page, sizePerPage } = newState;
        updateQueryParams({
          page: _page,
          sizePerPage,
        });
        break;
      case 'sort':
        const { sortField, sortOrder } = newState;
        updateQueryParams({
          sortField,
          sortOrder,
        });
        break;
      case 'filter':
        const { search } = newState;
        updateQueryParams({
          search,
        });
        break;
    }
  };

  const onShowDetails = (id: string) => {
    history.push(`/complaints/${id}`);
  };

  const onClickUser = (id: string) => {
    history.push(`/users/${id}`);
  };
  const onStatusesFilterChange = (statuses: string) => {
    mutate();
    updateQueryParams({
      status: statuses,
    });
  };

  return (
    <Page
      content={
        <>
          <Card className="bg-transparent shadow-none p-0 m-0">
            <CardBody className="bg-transparent shadow-none p-0 m-0">
              <ComplaintCategorySummeries
                data={complaintCategorySummaries}
                isLoading={isLoadingComplaintCategories}
              />
            </CardBody>
          </Card>
          <Card
            style={{
              minHeight: '80vh',
            }}
          >
            <CardBody>
              <ComplaintsTable
                onStatusesFilterChange={onStatusesFilterChange}
                data={complaints}
                isLoading={isLoading}
                totalSize={total}
                page={parseInt(page?.toString() ?? '1')}
                perPage={sizePerPage}
                allowFilter
                onTableChange={onTableChange}
                onShowDetails={onShowDetails}
                onDelete={onDelete}
                onClickUser={onClickUser}
              />
            </CardBody>
          </Card>
        </>
      }
    ></Page>
  );
};

export default ComplaintsPage;
