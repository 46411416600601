import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { routePaths } from 'appConfig';
import { Page } from 'components/Common';
import { useRouter } from 'lib/router';
import { mapValues } from 'lib/javascript';
import { useIngredients, IngredientTable } from 'lib/ingredients';

const IngredientsPage = () => {
  const { query, updateQueryParams } = useRouter();
  const [filter, setFilter] = React.useState({ name: '' });

  const {
    page = 1,
    sizePerPage = 15,
    sortField = 'created_at',
    sortDir = 'desc',
  } = query;

  const { isLoading, ingredients, total } = useIngredients({
    page,
    sizePerPage,
    ...filter,
    sortField,
    sortDir,
  });

  const handleOnTableChange = (action, newState) => {
    switch (action) {
      case 'pagination':
        updateQueryParams({
          page: newState.page,
          sizePerPage: newState.sizePerPage,
        });
        break;

      case 'filter':
        const mappedFilters = mapValues(
          newState.filters,
          (filter) => filter.filterVal,
        );

        const name = mappedFilters['name'];

        setFilter({ name });
        updateQueryParams({
          page: 1,
          sizePerPage: newState.sizePerPage,
        });

        break;

      case 'sort':
        const { sortField, sortOrder } = newState;

        updateQueryParams({
          sortField,
          sortDir: sortOrder,
        });

        break;

      default:
    }
  };

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <IngredientTable
              loading={isLoading}
              data={ingredients}
              page={parseInt(page)}
              sizePerPage={parseInt(sizePerPage)}
              totalSize={total}
              onTableChange={handleOnTableChange}
              getPathToDetailsPage={(ingredient) =>
                `${routePaths.INGREDIENTS}/${ingredient.id}`
              }
              sortDir={sortDir}
              sortField={sortField}
            />
          </CardBody>
        </Card>
      }
    />
  );
};

IngredientsPage.propTypes = {};

export default IngredientsPage;
