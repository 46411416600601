import ComplaintOverview from './ComplaintOverview';
import { Complaint } from './types';

interface ComplaintOverviewInterface {
  complaint?: Complaint | null;
  isLoading?: boolean;
}

const OrderComplaintDetails = ({
  complaint,
  isLoading,
}: ComplaintOverviewInterface) => {
  return !complaint || isLoading ? (
    <div className="d-flex align-items-center justify-content-center">
      <span className="text-muted font-size-14">
        No Feedback Found
      </span>
    </div>
  ) : (
    <div>
      <hr />
      <h5 className="font-size-15  mb-3">Overview</h5>

      <ComplaintOverview complaint={complaint} />
    </div>
  );
};

export default OrderComplaintDetails;
