import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { Button } from 'components/Common';
import { FormManager, Form, connectInput } from 'lib/form';
import { ProductVariantAddonSelectGroup } from 'lib/productAddons';
import { getSelectedAddonOptionValueIds } from 'lib/productAddons/utils';

const ProductVariantAddonSelectGroupField = connectInput(
  ProductVariantAddonSelectGroup,
  {
    onChangeArgType: 'raw-value',
  },
);

const EditOrderLineAddonsForm = ({
  productVariantId,
  addons,
  onSubmit,
}) => {
  const initialValues = {
    addons,
  };

  const validationSchema = yup.object({
    addons: yup.object(),
  });

  const handleOnSubmit = (formValues) => {
    const { addons } = formValues;

    const addonOptionValueIds = getSelectedAddonOptionValueIds(
      addons,
    );

    return onSubmit(addonOptionValueIds);
  };

  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
      enableReinitialization
    >
      {({ isSubmitting }) => (
        <Form>
          <ProductVariantAddonSelectGroupField
            name="addons"
            required
            productVariantId={productVariantId}
          />
          <Button type="submit" loading={isSubmitting}>
            Update Addons
          </Button>
        </Form>
      )}
    </FormManager>
  );
};

EditOrderLineAddonsForm.propTypes = {
  productVariantId: PropTypes.string.isRequired,
  addons: PropTypes.objectOf(PropTypes.string),
  onSubmit: PropTypes.func,
};

EditOrderLineAddonsForm.defaultProps = {
  addons: [],
  onSubmit: () => {},
};

export default EditOrderLineAddonsForm;
