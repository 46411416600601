import { Button } from 'components/Common';
import { TextField } from 'components/formFields';
import { Form, FormManager } from 'lib/form';
import validator from 'lib/validator';
import { Col, Row } from 'reactstrap';
import {
  ComplaintResolutionPayload,
  ComplaintResolutionSchema,
} from './types';
import { Datetime } from 'lib/datetime';
import { displayName } from 'lib/utils';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'appConfig';

interface ResolutionFormInterface {
  isDisabled?: boolean;
  data?: ComplaintResolutionSchema;
  onSubmit: (payload: ComplaintResolutionPayload) => Promise<void>;
  onCancel: () => void;
}

const ResolutionForm = ({
  isDisabled,
  data,
  onSubmit,
  onCancel,
}: ResolutionFormInterface) => {
  const validationSchema = validator.object({
    resolution: validator.string().required(),
    compensation: validator.number().nullable(),
  });

  const history = useHistory();

  const {
    resolution,
    id: complaintId,
    compensation_amount,
    resolved_at,
    resolver,
  } = data || {};

  const { user_id: userId } = resolver || {};

  const resolverName = displayName({
    firstName: resolver?.first_name,
    lastName: resolver?.last_name,
  });

  const compensation =
    compensation_amount && compensation_amount / 100;

  const defaultValues = {
    id: complaintId || '',
    resolution: resolution || '',
    compensation: compensation || '',
  };

  const initialValues = {
    ...defaultValues,
  };

  const handleOnSubmit = async (values: any) => {
    const payload: ComplaintResolutionPayload = {
      resolution: values.resolution,
      compensation_amount: values.compensation,
    };
    await onSubmit(payload);
  };

  const onShowUser = (userId: string) => {
    history.push(`${routePaths.USERS}/${userId}`);
  };

  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
      validateOnBlur
    >
      {({ isSubmitting, values, setValues, resetForm }) => {
        const handleCancel = () => {
          resetForm(defaultValues);
          onCancel();
        };

        return (
          <Form>
            <Row>
              <Col>
                <>
                  {resolved_at && resolver && (
                    <div className="pb-3">
                      {' '}
                      <span>Updated at </span>
                      <Datetime value={resolved_at} />
                      <span> by </span>
                      <Button
                        variant="link"
                        color="secondary"
                        className="p-0 border-0 text-left text-decoration-none"
                        style={{ textDecoration: 'none' }}
                        onClick={() => onShowUser(userId || '')}
                        shape={undefined}
                        children={<span>{`${resolverName}`}</span>}
                        loading={undefined}
                        block={undefined}
                        renderLoading={undefined}
                      ></Button>
                    </div>
                  )}
                </>
                <TextField
                  name={'resolution'}
                  label="Resolution"
                  required
                  type="textarea"
                  rows={5}
                  disabled={isSubmitting || isDisabled}
                  onChange={undefined}
                  helperText={undefined}
                />
                <TextField
                  name={'compensation'}
                  label="Compensation"
                  type="number"
                  defaultValue={values.compensation}
                  disabled={isSubmitting || isDisabled}
                  onChange={undefined}
                  helperText={undefined}
                />
              </Col>
            </Row>
            {!isDisabled && (
              <div className="d-flex justify-content-end">
                <Button
                  type="button"
                  className="mr-2"
                  onClick={handleCancel}
                  variant={'outlined'}
                  color="dark"
                  shape={undefined}
                  block={undefined}
                  renderLoading={undefined}
                  loading={undefined}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className={`float-right`}
                  loading={isSubmitting}
                  disabled={isSubmitting || isDisabled}
                  variant={undefined}
                  color={undefined}
                  shape={undefined}
                  block={undefined}
                  renderLoading={undefined}
                >
                  Save
                </Button>
              </div>
            )}
          </Form>
        );
      }}
    </FormManager>
  );
};

export default ResolutionForm;
