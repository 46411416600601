import React from 'react';
import PropTypes from 'prop-types';

import { AutoComplete } from 'components/Common';
import useProducts from './useProducts';

const ProductAutoComplete = ({
  name,
  value,
  onChange,
  label,
  required,
  error,
  helperText,
  isMulti,
  excludedProductFamilies,
}) => {
  const { isLoading, products } = useProducts();

  const options = products.filter((product) => {
    if (excludedProductFamilies?.length) {
      return !excludedProductFamilies.includes(product.product_family);
    }

    return product;
  });

  return (
    <AutoComplete
      isLoading={isLoading}
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      options={options}
      isMulti={isMulti}
      getOptionLabel={(product) => product.name}
      getOptionValue={(product) => product.id}
    />
  );
};

ProductAutoComplete.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isMulti: PropTypes.bool,
  excludedProductFamilies: PropTypes.array,
};

ProductAutoComplete.defaultProps = {
  isMulti: false,
  excludedProductFamilies: [],
};

export default ProductAutoComplete;
