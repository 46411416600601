import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const getUserLoyaltyPoints = async (userId) => {
  try {
    const response = await httpClient.get(
      `/cms/users/${userId}/yb_reps`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getUserLoyaltyPointsLogs = async (userId) => {
  try {
    const response = await httpClient.get(
      `/cms/users/${userId}/yb_reps/logs`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const postAddUserLoyaltyPoints = async (userId, point) => {
  try {
    const response = await httpClient.post(
      `/cms/users/${userId}/yb_reps`,
      { point },
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteDeductUserLoyaltyPoints = async (
  userId,
  point,
) => {
  try {
    const response = await httpClient.delete(
      `/cms/users/${userId}/yb_reps`,
      { data: { point } },
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
