import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { Skeleton, Modal, Button } from 'components/Common';
import { useRouter } from 'lib/router';

import useUserLoyaltyPoints from './useUserLoyaltyPoints';
import { POINT_NAME } from './utils';
import LoyaltyPointsLogsTable from './LoyaltyPointsLogsTable';
import useUserLoyaltyPointsLogs from './useUserLoyaltyPointsLogs';
import AddLoyaltyPointsForm from './AddLoyaltyPointsForm';
import DeductLoyaltyPointsForm from './DeductLoyaltyPointsForm';

const UserLoyaltyPointsDetails = ({ userId }) => {
  const { query, updateQueryParams } = useRouter();
  const [
    showAddLoyaltyPointsModal,
    setShowAddLoyaltyPointsModal,
  ] = React.useState(false);
  const [
    showDeductLoyaltyPointsModal,
    setShowDeductLoyaltyPointsModal,
  ] = React.useState(false);

  const { page = 1, sizePerPage = 15 } = query;

  const {
    loyaltyPoints,
    isLoading: isPointsLoading,
  } = useUserLoyaltyPoints(userId);
  const {
    loyaltyPointsLogs,
    total,
    isLoading: isLogsLoading,
  } = useUserLoyaltyPointsLogs({ userId });

  if (isPointsLoading) return <Skeleton />;

  return (
    <div>
      <Row>
        <Col>
          <p>
            You have {loyaltyPoints.total} {POINT_NAME}.{' '}
          </p>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '24px',
          }}
        >
          <Button
            onClick={() => setShowAddLoyaltyPointsModal(true)}
            color="primary"
            style={{ marginRight: '12px' }}
          >
            Add {POINT_NAME}
          </Button>
          <Button
            onClick={() => setShowDeductLoyaltyPointsModal(true)}
            color="secondary"
          >
            Deduct {POINT_NAME}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <LoyaltyPointsLogsTable
            isLoading={isLogsLoading}
            data={loyaltyPointsLogs}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={total}
            onPageChange={(page) => updateQueryParams({ page })}
            onSizePerPageChange={(sizePerPage) =>
              updateQueryParams({ sizePerPage })
            }
          />
        </Col>
      </Row>
      <Modal
        open={showAddLoyaltyPointsModal}
        onClose={() => setShowAddLoyaltyPointsModal(false)}
        title={`Add ${POINT_NAME}`}
        content={
          <AddLoyaltyPointsForm
            userId={userId}
            onSubmit={(err) =>
              !err && setShowAddLoyaltyPointsModal(false)
            }
          />
        }
      />
      <Modal
        open={showDeductLoyaltyPointsModal}
        onClose={() => setShowDeductLoyaltyPointsModal(false)}
        title={`Deduct ${POINT_NAME}`}
        content={
          <DeductLoyaltyPointsForm
            userId={userId}
            onSubmit={(err) =>
              !err && setShowDeductLoyaltyPointsModal(false)
            }
          />
        }
      />
    </div>
  );
};

UserLoyaltyPointsDetails.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserLoyaltyPointsDetails;
