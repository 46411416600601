import config from 'appConfig';
import type { Dayjs } from 'dayjs';
import datetime from 'lib/datetime';
import { defaultDateTime } from './constants';

export const formatDateTime = (
  { day, hour }: {
    day: number,
    hour: number,
  }): string => {
  return defaultDateTime.add({ day, hour }).utc().toISOString();
};

export const getDayHourFromSlot = (date: string) => {
  const slot = datetime(date)
    .tz(config.timezone);

  const day = slot.format('d');
  const hour = slot.format('H');

  return { day, hour };
};

export const setAsSingaporeDateTime = (dateTimeSlot: Dayjs) => {
  return datetime(dateTimeSlot).tz(config.timezone);
};
