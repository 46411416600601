import PropTypes from 'prop-types';

export const ReferralRewardCouponPropType = PropTypes.shape({
  coupon_code: PropTypes.string.isRequired,
  end_at: PropTypes.string.isRequired,
  usage: PropTypes.number,
  usage_limit: PropTypes.number,
});

export const ReferreePropType = PropTypes.shape({
  user: PropTypes.shape({
    user_id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone_number: PropTypes.string,
    email: PropTypes.string,
    goal: PropTypes.string,
    gender: PropTypes.string,
    birthday: PropTypes.string,
    is_subscribe_to_mailchimp: PropTypes.bool,
  }),
  referred_at: PropTypes.string,
});
