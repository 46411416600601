import React, { createContext, Component, useContext } from 'react';

export const MediaManagerContext = createContext({
  uploadingMedia: [],
  setUploadingMedia: () => {},
});

export class MediaManagerContextProvider extends Component {
  constructor(props) {
    super(props);

    this.setUploadingMedia = (uploadingMedia) => {
      this.setState({ uploadingMedia });
    };

    this.state = {
      uploadingMedia: [],
      setUploadingMedia: this.setUploadingMedia,
    };
  }

  render() {
    const { children } = this.props;

    return (
      <MediaManagerContext.Provider value={this.state}>
        {children}
      </MediaManagerContext.Provider>
    );
  }
}

export const useMediaManagerContext = () => {
  const context = useContext(MediaManagerContext);

  return context;
};
