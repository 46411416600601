import React, { useState } from 'react';

import { Button, Page, Tabs, Tab } from 'components/Common';
import {
  FullName,
  UserBadges,
  UserDetails,
  useUser,
} from 'lib/users';
import { OrdersTable, useOrders } from 'lib/orders';
import {
  useUserReferralCode,
  ReferralCode,
  ReferreesTable,
  useUserReferrees,
  ReferralRewardCouponsTable,
  useUserRewardCoupons,
  ExportReferredCustomer,
} from 'lib/referral';
import {
  POINT_NAME,
  UserLoyaltyPointsDetails,
} from 'lib/loyaltyProgram';
import { useRouter } from 'lib/router';
import { routePaths } from 'appConfig';
import { useHistory, useParams } from 'react-router';
import { List } from 'react-content-loader';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useConfirmation } from 'lib/confirm';
import notification from 'lib/notification';
import UserAddress from 'lib/users/UserAddress';
import {
  findDefaultBillingAddress,
  findDefaultShippingAddress,
  UpdateAddressError,
  useUserAddresses,
} from 'lib/address';
import { UserLoveMessages } from 'lib/love-message';
import ComplaintsTable from 'lib/complaint/ComplaintsTable';
import useComplaints from 'lib/complaint/use-complaints';

const TAB_IDS = {
  ORDERS: 'orders',
  REFERRAL: 'referrals',
  LOYALTY_POINTS: 'loyalty-points',
  COMPLAINTS: 'feedback',
};

const USER_TAB_IDS = {
  DETAILS: 'details',
  ADDRESS: 'address',
  LOVE_MESSAGE: 'loveMessage',
};

const UserDetailsPage = () => {
  const history = useHistory();

  const { userId } = useParams();

  const router = useRouter();
  const { query, updateQueryParams, setQueryParams } = router;
  const { tab } = query;

  const [userActiveTab, setUserActiveTab] = useState(
    USER_TAB_IDS.DETAILS,
  );

  const { confirm } = useConfirmation();
  const [isDeleting, setIsDeleting] = React.useState(false);

  const [filterParams, setFilterParams] = React.useState({
    orderNumber: '',
    status: '',
    customerId: '',
  });

  const {
    page = 1,
    sizePerPage = 15,
    sortField = 'created_at',
    sortDir = 'desc',
    complaintPage = 1,
    complaintPerPage = 15,
  } = query;

  const { isLoading, user, update, remove } = useUser(userId);

  const defaultBillingAddress = findDefaultBillingAddress(
    user?.addresses,
  );
  const defaultShippingAddress = findDefaultShippingAddress(
    user?.addresses,
  );

  const { updateAddress } = useUserAddresses(userId);

  const handleOnChangeAddresses = (addresses) =>
    Promise.all(
      addresses.map((address) =>
        updateAddress(address.id, address).catch((err) => {
          throw new UpdateAddressError({ address, serverError: err });
        }),
      ),
    );

  const {
    isLoading: isOrderLoading,
    orders,
    total,
    mutateOrders,
  } = useOrders({
    page,
    sizePerPage,
    ...filterParams,
    customerId: userId,
    sortField,
    sortDir,
  });

  const {
    isLoading: isReferralCodeLoading,
    referralCode,
  } = useUserReferralCode(userId);

  const [rewardCouponsPage, setRewardCouponsPage] = React.useState(1);
  const [
    rewardCouponsSizePerPage,
    setRewardCouponsSizePerPage,
  ] = React.useState(5);

  const {
    isLoading: isRewardCouponsLoading,
    rewardCoupons,
    total: rewardCouponsTotal,
  } = useUserRewardCoupons({
    userId,
    page: rewardCouponsPage,
    sizePerPage: rewardCouponsSizePerPage,
  });

  const [referreesPage, setReferreesPage] = React.useState(1);
  const [
    referreesSizePerPage,
    setReferreesSizePerPage,
  ] = React.useState(5);

  const {
    isLoading: isReferreesLoading,
    referrees,
    total: referreesTotal,
  } = useUserReferrees({
    userId,
    page: referreesPage,
    sizePerPage: referreesSizePerPage,
  });

  const deleteUser = async () => {
    try {
      await confirm({
        title: 'Delete User?',
        content: 'This action is irreversible. Are you sure?',
      });
    } catch (err) {
      return;
    }

    try {
      setIsDeleting(true);

      await remove();

      history.replace(routePaths.USERS);

      notification.success('Successfully deleted user');
    } catch (err) {
      notification.error('Something went wrong when deleting user');
    } finally {
      setIsDeleting(false);
    }
  };

  const {
    complaints,
    isLoading: isLoadingComplaints,
    mutate,
    onDelete,
    total: totalComplaints,
  } = useComplaints({
    params: {
      page: complaintPage,
      perPage: complaintPerPage,
      userId,
    },
  });

  const onTableComplaintChange = async (action, newState) => {
    switch (action) {
      case 'pagination':
        const {
          page: _complaintPage,
          sizePerPage: _complaintPerPage,
        } = newState;
        updateQueryParams({
          complaintPage: _complaintPage,
          complaintPerPage: _complaintPerPage,
        });
        break;
    }
  };

  const onShowDetails = (id) => {
    history.push(`/complaints/${id}`);
  };

  return (
    <Page
      content={
        <React.Fragment>
          {isLoading ? (
            <List uniqueKey="user-details-loading" />
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <div className="card-title d-flex align-items-center flex-row justify-content-between">
                  <FullName user={user} />
                  <UserBadges data={user} />
                </div>
                <Button
                  color="danger"
                  onClick={deleteUser}
                  loading={isDeleting}
                >
                  Delete
                </Button>
              </div>

              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Tabs
                        defaultActiveKey={USER_TAB_IDS.DETAILS}
                        activeKey={userActiveTab}
                        onSelect={(newKey) =>
                          setUserActiveTab(newKey)
                        }
                      >
                        <Tab
                          eventKey={USER_TAB_IDS.DETAILS}
                          title="Details"
                        >
                          <div style={{ margin: '24px 0' }}>
                            <UserDetails
                              isLoading={isLoading}
                              user={user}
                              update={update}
                            />
                          </div>
                        </Tab>

                        <Tab
                          eventKey={USER_TAB_IDS.ADDRESS}
                          title="Address"
                        >
                          <div style={{ margin: '24px 0' }}>
                            <UserAddress userId={userId} />
                          </div>
                        </Tab>

                        <Tab
                          eventKey={USER_TAB_IDS.LOVE_MESSAGE}
                          title="Love Message"
                        >
                          <div style={{ margin: '24px 0' }}>
                            <UserLoveMessages userId={userId} />
                          </div>
                        </Tab>
                      </Tabs>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Tabs
                        activeKey={tab}
                        onSelect={(newKey) =>
                          setQueryParams({ tab: newKey })
                        }
                      >
                        <Tab eventKey={TAB_IDS.ORDERS} title="Orders">
                          <div style={{ margin: '24px 0' }}>
                            <OrdersTable
                              loading={isOrderLoading}
                              data={orders}
                              page={parseInt(page)}
                              sizePerPage={parseInt(sizePerPage)}
                              totalSize={total}
                              getPathToDetailsPage={(order) =>
                                `${routePaths.ORDERS}/${order.id}`
                              }
                              sortDir={sortDir}
                              sortField={sortField}
                              updateQueryParams={updateQueryParams}
                              setFilterParams={setFilterParams}
                              mutateOrders={mutateOrders}
                              isHideUserColumn
                            />
                          </div>
                        </Tab>
                        <Tab
                          eventKey={TAB_IDS.REFERRAL}
                          title="Referral"
                        >
                          <div style={{ margin: '24px 0' }}>
                            <section style={{ marginBottom: '24px' }}>
                              <h2>Referral Code</h2>
                              <ReferralCode
                                isLoading={isReferralCodeLoading}
                                value={referralCode}
                              />
                              {!!referralCode && (
                                <div className="d-flex justify-content-end align-items-center">
                                  <ExportReferredCustomer
                                    userID={userId}
                                  />
                                </div>
                              )}
                            </section>

                            <section style={{ marginBottom: '24px' }}>
                              <h2>Referral Reward Coupons</h2>
                              <ReferralRewardCouponsTable
                                isLoading={isRewardCouponsLoading}
                                data={rewardCoupons}
                                currentPage={rewardCouponsPage}
                                total={rewardCouponsTotal}
                                onChangePage={setRewardCouponsPage}
                                sizePerPage={rewardCouponsSizePerPage}
                                onChangeSizePerPage={
                                  setRewardCouponsSizePerPage
                                }
                              />
                            </section>

                            <section style={{ marginBottom: '24px' }}>
                              <h2>Referred Friends</h2>
                              <ReferreesTable
                                isLoading={isReferreesLoading}
                                data={referrees}
                                currentPage={referreesPage}
                                total={referreesTotal}
                                onChangePage={setReferreesPage}
                                sizePerPage={referreesSizePerPage}
                                onChangeSizePerPage={
                                  setReferreesSizePerPage
                                }
                              />
                            </section>
                          </div>
                        </Tab>
                        <Tab
                          eventKey={TAB_IDS.LOYALTY_POINTS}
                          title={POINT_NAME}
                        >
                          <div style={{ margin: '24px 0' }}>
                            <UserLoyaltyPointsDetails
                              userId={userId}
                            />
                          </div>
                        </Tab>
                        <Tab
                          eventKey={TAB_IDS.COMPLAINTS}
                          title={'Feedback'}
                        >
                          <div style={{ margin: '24px 0' }}>
                            <ComplaintsTable
                              data={complaints}
                              isLoading={isLoadingComplaints}
                              totalSize={totalComplaints}
                              page={parseInt(
                                complaintPage?.toString() ?? '1',
                              )}
                              perPage={complaintPerPage}
                              onTableChange={onTableComplaintChange}
                              onShowDetails={onShowDetails}
                              onDelete={onDelete}
                            />
                          </div>
                        </Tab>
                      </Tabs>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </React.Fragment>
      }
    />
  );
};

UserDetailsPage.propTypes = {};

export default UserDetailsPage;
