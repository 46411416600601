import { Button } from 'components/Common';
import {
  Address as AddressInterface,
  AddressType,
  useUserAddresses,
  getAddressesByType,
  AddressLoader,
  sortAddressesByDefault,
  AddressFormModal,
  AddressPayload,
  UpdateAddressError,
} from 'lib/address';
import { Address, getDialogTitle } from 'lib/address/Addresses';
import { useConfirmation } from 'lib/confirm';
import notification from 'lib/notification';
import { useState } from 'react';
import { Col, Row } from 'reactstrap';
// @ts-ignore-line
import { omit } from 'lib/javascript';

const UserAddress = ({ userId }: { userId: string }) => {
  const {
    addresses,
    isLoading,
    markAddressAsDefault,
    deleteAddress,
    createAddress,
    updateAddress,
  } = useUserAddresses({ userId });

  const addressTypes: Array<{
    type: AddressType;
    addresses: AddressInterface[];
    title: string;
    className?: string;
  }> = [
    {
      type: 'shipping',
      title: 'Delivery Address',
      className: '',
      addresses: getAddressesByType('shipping', addresses),
    },
    {
      type: 'billing',
      title: 'Billing Address',
      className: '',
      addresses: getAddressesByType('billing', addresses),
    },
  ];

  const [isProcessing, setIsProcessing] = useState(false);
  const [addressPayload, setAddressPayload] = useState<
    AddressInterface | undefined
  >(undefined);
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);

  const { confirm } = useConfirmation();

  const onCloseAddressForm = () => {
    setIsOpenAddressModal(false);
    setAddressPayload(undefined);
  };

  const onSetDefaultAddress = async (addressId: string | null) => {
    if (!addressId) return;

    try {
      setIsProcessing(true);
      await markAddressAsDefault(addressId);
    } catch (error) {
      notification.error('Failed to set address as default.');
    } finally {
      setIsProcessing(false);
    }
  };

  const onSetDeletedAddress = async (address: AddressInterface) => {
    if (!address.id) return;

    // @ts-ignore-line
    await confirm({
      title: 'Delete Address',
      content: 'Are you sure to delete this address?',
    });

    try {
      setIsProcessing(true);
      await deleteAddress(address.id);
      notification.success('Address successfully deleted.');
    } catch (error) {
      notification.error('Failed to delete address.');
    } finally {
      setIsProcessing(false);
    }
  };

  const onSetEditAddress = (address: AddressInterface) => {
    setAddressPayload(address);
    setIsOpenAddressModal(true);
  };

  const handleOnSubmit = async (payload: AddressPayload) => {
    try {
      let message = 'Address successfully created.';
      if (!payload.id) {
        await createAddress(payload);
      } else {
        const addressId = payload.id;
        const newAddress = omit(payload, ['id']);
        message = 'Address successfully updated.';
        await updateAddress(addressId, newAddress);
      }
      onCloseAddressForm();
      notification.success(message);
    } catch (error) {
      throw new UpdateAddressError({
        address: payload,
        serverError: error,
      });
    }
  };

  return (
    <>
      <Row>
        {addressTypes.map(({ type, title, addresses }) => {
          const isBillingAddress = type === 'billing';

          return (
            <Col
              sm={{ order: isBillingAddress ? '1' : '2' }}
              lg={{ size: 6, order: isBillingAddress ? '2' : '1' }}
              key={type}
            >
              <div className="d-flex align-items-center justify-content-between w-100 mb-2">
                <h5 className="mb-0 font-weight-bold">{title}</h5>
                {!isLoading && !isBillingAddress && (
                  // @ts-ignore
                  <Button
                    variant="link"
                    color="secondary"
                    className="p-0"
                    onClick={() => setIsOpenAddressModal(true)}
                  >
                    <span>
                      <i className="fas fa-plus"></i> Add New Address
                    </span>
                  </Button>
                )}
              </div>
              {isLoading ? (
                <AddressLoader />
              ) : (
                <div className="d-flex flex-column w-100">
                  {sortAddressesByDefault(addresses).map(
                    (address) => (
                      <Address
                        key={address.id}
                        pinned={address.default ?? false}
                        address={address}
                        showDefaultBadge={!isBillingAddress}
                        isProcessing={isProcessing}
                        onSetDefaultAddress={onSetDefaultAddress}
                        onSetDeletedAddress={onSetDeletedAddress}
                        onSetEditAddress={onSetEditAddress}
                      />
                    ),
                  )}
                </div>
              )}
            </Col>
          );
        })}
      </Row>
      <AddressFormModal
        isOpen={isOpenAddressModal}
        onSubmit={handleOnSubmit}
        onHide={onCloseAddressForm}
        address={addressPayload}
        title={getDialogTitle(addressPayload)}
      />
    </>
  );
};

export default UserAddress;
