import React from 'react';
import { Asset } from './types';

interface KitchenImagePreviewProps {
  assets: Asset[];
}

const KitchenImagePreview: React.FC<KitchenImagePreviewProps> = ({
  assets,
}) => {
  return (
    <div>
      {assets.length > 0 && <h6>Images</h6>}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {assets.map((asset) => (
          <div
            key={asset.id}
            style={{
              padding: '10px',
              textAlign: 'center',
            }}
          >
            <img
              src={asset.url}
              alt={`Asset ${asset.id}`}
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'cover',
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default KitchenImagePreview;
