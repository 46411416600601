import { useState } from 'react';
import { saveAs } from 'file-saver';
import contentDisposition from 'content-disposition';

import config from 'appConfig';
import { useConfirmation } from 'lib/confirm';
import notification from 'lib/notification';

import { getOrderInvoice, getOrderInvoiceNow } from './api';

const useOrderInvoice = (orderId) => {
  const [
    isRequestingOrderInvoice,
    setIsRequestingOrderInvoice,
  ] = useState(false);

  const { alert } = useConfirmation();

  const requestOrderInvoiceAsync = async () => {
    try {
      setIsRequestingOrderInvoice(true);

      await getOrderInvoice(orderId);

      await alert({
        title: 'Your Order Invoice is Being Prepared',
        content:
          "We'll email to you your order invoice in a few minutes. For now, Sit back and relax! ",
      });
    } catch (err) {
      notification.error(
        'Something went wrong when generating order invoice',
      );
    } finally {
      setIsRequestingOrderInvoice(false);
    }
  };

  const requestOrderInvoiceSync = async () => {
    try {
      setIsRequestingOrderInvoice(true);

      const response = await getOrderInvoiceNow(orderId);

      const contentDispositionHeader =
        response.headers['content-disposition'];

      const parsedContentDispositionHeader = contentDisposition.parse(
        contentDispositionHeader,
      );

      const { filename } = parsedContentDispositionHeader.parameters;

      const orderInvoiceData = response.data;

      saveAs(orderInvoiceData, filename);
    } catch (err) {
      notification.error(
        'Something went wrong when generating order invoice',
      );
    } finally {
      setIsRequestingOrderInvoice(false);
    }
  };

  const requestOrderInvoice = () => {
    if (config.featureToggle.orderInvoice === 'sync')
      return requestOrderInvoiceSync();
    return requestOrderInvoiceAsync();
  };

  return {
    isRequestingOrderInvoice,
    requestOrderInvoice,
  };
};

export default useOrderInvoice;
