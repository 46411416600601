import { Dayjs } from 'dayjs';
import { dateToString, timeToString } from 'lib/datetime';
import { DELIVERY_TIMESLOT_DURATION_HOURS } from 'lib/deliveryDateTimes';

type Props = {
  deliverySlot: Dayjs;
  slotDuration?: number;
};

export const DeliverySlotInfo = ({
  deliverySlot,
  slotDuration = DELIVERY_TIMESLOT_DURATION_HOURS,
}: Props) => {
  if (!deliverySlot.isValid()) {
    return <></>;
  }

  const endSlot = deliverySlot.clone().add(slotDuration, 'hour');

  return (
    <>
      <b>{dateToString(deliverySlot)}</b>{' '}
      <b>{timeToString(deliverySlot)}</b> -{' '}
      <b>{timeToString(endSlot)}</b>
    </>
  );
};
