import { Form, FormManager, GeneralError } from 'lib/form';
import { CataloguePayload } from './types';
import yup from 'lib/validator';
import { Col, Row } from 'reactstrap';
import { CheckboxField, TextField } from 'components/formFields';
import { Button } from 'components/Common';
import { FormikHelpers } from 'formik';
import { ChangeEvent } from 'react';
import slugify from '@sindresorhus/slugify';

interface CatalogueFormProps {
  onSubmit: (body: CataloguePayload) => Promise<void>;
  initialValues?: CataloguePayload;
  buttonLabel?: string;
}

const defaultValues: CataloguePayload & { type_manually: boolean } = {
  title: '',
  slug: '',
  type_manually: false,
}

const validationSchema = yup.object({
  title: yup.string().max(50).required(),
  slug: yup.string(),
  type_manually: yup.bool(),
});

const CatalogueForm = ({
  onSubmit,
  initialValues = defaultValues,
  buttonLabel = 'Add',
}: CatalogueFormProps) => {
  const handleOnSubmit = async (
    values: CataloguePayload,
    actions: FormikHelpers<CataloguePayload>,
  ) => {
    try {
      actions.setStatus({
        error: null,
      });
      await onSubmit(values);
    } catch (err) {
      actions.setErrors(err?.errors);
      actions.setStatus({
        error: err?.errors?.message,
      });
      throw err;
    }
  };

  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form autoComplete="off">
          <GeneralError />
          <Row>
            <Col xs={12} md={6}>
              {/* @ts-ignore */}
              <TextField
                name="title"
                label="Manual Collection Title"
                maxLength={50}
                required
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.target;
                  if (!values.type_manually) {
                    const slug = value.length === 0 ? '' : slugify(value);
                    setFieldValue('slug', slug);
                  }
                }}
              />
            </Col>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={12}>
                  {/* @ts-ignore */}
                  <TextField
                    name="slug"
                    label="Slug"
                    required
                    disabled={!values.type_manually}
                  />
                </Col>
                <Col xs={12}>
                  {/* @ts-ignore */}
                  <CheckboxField
                    name="type_manually"
                    label="Type Manually"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const { checked } = e.target;

                      if (!checked) {
                        const sluggedTitle = slugify(values.title);
                        const isSlugSynced = sluggedTitle === values.slug;
                        setFieldValue('slug', isSlugSynced ? values.slug : sluggedTitle);
                      }
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* @ts-ignore */}
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            <span>{buttonLabel}</span>
          </Button>
        </Form>
      )}
    </FormManager>
  );
};

export default CatalogueForm;
