import { useState, useEffect } from 'react';
import { WINDOW_STORAGE_EVENT } from 'lib/webStorage';
import jwtClient, { STORAGE_ITEM_KEY } from 'lib/jwtClient';

const useSession = () => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    const initSession = async () => {
      const token = await jwtClient.getToken();
      if (token) setSession(token);
      else setSession(false);
    };

    initSession();
  }, []);

  useEffect(() => {
    const syncSession = async (event) => {
      if (event.key === STORAGE_ITEM_KEY) {
        const token = await jwtClient.getToken();
        if (token) setSession(token);
        else setSession(false);
      }
    };

    window.addEventListener(WINDOW_STORAGE_EVENT, syncSession);

    return () => {
      window.removeEventListener(WINDOW_STORAGE_EVENT, syncSession);
    };
  }, []);

  return {
    loading: session === null,
    session,
  };
};

export default useSession;
