import { useGet } from 'lib/swr-crud';
import {
  LoveMessagesQueryParams,
  LoveMessagesResponse,
} from './types';
import { AxiosError } from 'axios';
import { APIErrorResponse } from 'types/api';
import {
  deleteLoveMessage,
  getLoveMessages,
  getLoveMessagesUrl,
} from './api';

interface UseLoveMessagesOptions {
  params?: LoveMessagesQueryParams;
}

const useLoveMessages = ({ params }: UseLoveMessagesOptions) => {
  const { data, isLoading, mutate } = useGet<
    LoveMessagesResponse,
    AxiosError<APIErrorResponse>
  >(
    getLoveMessagesUrl(params),
    async () => await getLoveMessages(params),
  );

  const total: number = data?.meta?.total ?? 0;

  const onDelete = async (loveMessageId: string) => {
    await deleteLoveMessage(loveMessageId);
    mutate();
  };

  return {
    loveMessages: data?.data ?? [],
    isLoading,
    total,
    mutate,
    onDelete,
  };
};

export default useLoveMessages;
