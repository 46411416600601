import { Button, Page } from 'components/Common';
import { Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router';
import { routePaths } from 'appConfig';
import { useRouter } from 'lib/router';
import { CataloguesParams, CataloguesTable, useCatalogues } from 'lib/catalogues';
// @ts-expect-error
import { mapValues } from 'lib/javascript';

const CataloguesPage = () => {
  const history = useHistory();
  const {
    query: searchParams,
    updateQueryParams,
    setQueryParams,
  } = useRouter();

  const {
    page = 1,
    sizePerPage = 15,
    sortField = 'created_at',
    sortDir = 'desc',
    withDeleted = 'false',
    query = '',
  } = searchParams as CataloguesParams;

  const { catalogues, isLoading, total, mutate } = useCatalogues({
    page,
    sizePerPage,
    sortField,
    sortDir,
    withDeleted,
    query,
  });

  const handleTableChange = async (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => {
    switch (action) {
      case 'pagination':
        updateQueryParams({ page: newState.page });
        break;

      case 'filter':
        const mappedFilters = mapValues(
          newState.filters,
          (filter: { filterVal: string; }) => filter.filterVal,
        );

        const title = mappedFilters['title'];
        if (!title) {
          setQueryParams({ page: newState.page });
        } else {
          updateQueryParams({ page: '1', query: title });
        }
        break;

      case 'sort':
        const { sortField, sortOrder } = newState;
        updateQueryParams({
          sortField,
          sortDir: sortOrder,
        });
        break;

      default:
        break;
    }
  };

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <div className="d-flex justify-content-end mb-2">
              {/* @ts-ignore */}
              <Button
                onClick={() => {
                  history.push(`${routePaths.NEW_CATALOGUE}`)
                }}
              >
                <span>Create New Manual Collection</span>
              </Button>
            </div>
            <CataloguesTable
              data={catalogues}
              isLoading={isLoading}
              page={page}
              sizePerPage={sizePerPage}
              sortField={sortField}
              sortDir={sortDir}
              query={query}
              totalSize={total}
              onTableChange={handleTableChange}
              mutateCatalogues={mutate}
            />
          </CardBody>
        </Card>
      }
    />
  );
};

export default CataloguesPage;
