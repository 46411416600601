import React from 'react';

import { Page } from 'components/Common';
import { IngredientDetails } from 'lib/ingredients';

const IngredientDetailsPage = () => {
  return (
    <Page
      content={
        <React.Fragment>
          <IngredientDetails />
        </React.Fragment>
      }
    />
  );
};

export default IngredientDetailsPage;
