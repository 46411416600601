import React from 'react';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import CorrectiveActionForm from './CorrectiveActionForm';
import { Button, Icon } from 'components/Common';
import { ComplaintCorrectiveActionPayload } from './types';

interface CorrectiveActionCardProps {
  isCorrectiveActionFormDisable: boolean;
  correctiveActionData: any;
  onSubmitCorrectiveAction: (
    payload: ComplaintCorrectiveActionPayload,
  ) => Promise<void>;
  onCancelCorrectiveAction: () => void;
  is_verified: boolean;
  isAdmin: boolean;
  isKitchenRepresentative: boolean;
  setisCorrectiveActionFormDisable: (value: boolean) => void;
  onDelete: () => Promise<void>;
}

const CorrectiveActionCard: React.FC<CorrectiveActionCardProps> = ({
  isCorrectiveActionFormDisable,
  correctiveActionData,
  onSubmitCorrectiveAction,
  onCancelCorrectiveAction,
  is_verified,
  isAdmin,
  isKitchenRepresentative,
  setisCorrectiveActionFormDisable,
  onDelete,
}) => (
  <Card>
    <CardHeader className="bg-white pb-0">
      <CardTitle>
        <div className="d-flex flex-row align-items-center justify-content-between">
          Corrective Action
          <div className="d-flex flex-row justify-content-end p-0">
            {isCorrectiveActionFormDisable && !is_verified && (
              // @ts-ignore
              <Button
                className={`mr-2`}
                variant={'outlined'}
                color="dark"
                onClick={onDelete}
              >
                <span className="mr-2">Delete Corrective Action</span>
              </Button>
            )}
            {isCorrectiveActionFormDisable && (
              // @ts-ignore
              <Button
                className={`float-right ${
                  is_verified ||
                  (!isAdmin && !isKitchenRepresentative)
                    ? 'd-none'
                    : ''
                }`}
                onClick={() =>
                  setisCorrectiveActionFormDisable(false)
                }
              >
                Edit Corrective Action
              </Button>
            )}
          </div>
        </div>
      </CardTitle>
    </CardHeader>
    <CardBody>
      <CorrectiveActionForm
        data={correctiveActionData}
        onSubmit={onSubmitCorrectiveAction}
        isDisabled={isCorrectiveActionFormDisable}
        onCancel={onCancelCorrectiveAction}
      />
    </CardBody>
  </Card>
);

export default CorrectiveActionCard;
