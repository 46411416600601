import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const getCollectionById = async (collectionId) => {
  try {
    const response = await httpClient.get(
      `/cms/collections/${collectionId}`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const postAddCollection = async (body) => {
  try {
    const response = await httpClient.post(`/cms/collections`, body);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putUpdateCollection = async (collectionId, body) => {
  try {
    const response = await httpClient.put(
      `/cms/collections/${collectionId}`,
      body,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteCollection = async (collectionId) => {
  try {
    const response = await httpClient.delete(
      `/cms/collections/${collectionId}`,
    );

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const updateCollectionPublishStatus = async (
  collectionId,
  { publish },
) => {
  try {
    const response = await httpClient.put(
      `/cms/collections/${collectionId}/publish`,
      {
        publish,
      },
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const publishCollection = (collectionId) =>
  updateCollectionPublishStatus(collectionId, { publish: true });

export const unpublishCollection = (collectionId) =>
  updateCollectionPublishStatus(collectionId, { publish: false });
