import React from 'react';
import PropTypes from 'prop-types';

import {
  AddonOptionPropType,
  AddonOptionValuePropType,
  AddonRulePropType,
} from '../typedef';
import AddonSelect from '../AddonSelect';
import {
  getNextVisibleSelectedAddons,
  getVisibleAddonOptions,
  persistSelectedIngredientForChangedAddonOptions,
} from '../utils';

const AddonSelectGroup = ({
  addonOptions,
  addonRules,
  value: selectedAddons,
  onChange,
  selectAddonOptionById,
  selectAddonOptionValueById,
}) => {
  const selectedAddonsStr = JSON.stringify(selectedAddons);

  const getSelectedAddonValueId = React.useCallback(
    (addonOption) => selectedAddons[addonOption.id],
    [selectedAddons],
  );

  const handleOnChange = React.useCallback(
    (addonOption, newAddonOptionValueId) => {
      /**
       * selectedAddons data structure
       * {
       *  <selectedAdonOptionId>: <selectedAddonOptionValueId>
       * }
       */

      const selectedAddons = JSON.parse(selectedAddonsStr);

      const newSelectedAddons = {
        ...selectedAddons,
        [addonOption.id]: newAddonOptionValueId,
      };

      // get new addon option that are visible after the new selection based on addonRules
      const nextVisibleSelectedAddons = getNextVisibleSelectedAddons({
        addonOptions,
        addonRules,
        selectedAddons: newSelectedAddons,
      });

      const nextSelectedAddons = persistSelectedIngredientForChangedAddonOptions(
        {
          prevSelectedAddons: selectedAddons,
          newSelectedAddons: nextVisibleSelectedAddons,
          addonOptions,
          selectAddonOptionById,
          selectAddonOptionValueById,
        },
      );

      onChange(nextSelectedAddons);
    },
    [
      selectedAddonsStr,
      addonOptions,
      addonRules,
      onChange,
      selectAddonOptionById,
      selectAddonOptionValueById,
    ],
  );

  const visibleAddonOptions = getVisibleAddonOptions(
    addonOptions,
    addonRules,
    selectedAddons,
  );

  return (
    <div>
      {visibleAddonOptions.map((addonOption) => {
        return (
          <AddonSelect
            key={addonOption.id}
            addonOption={addonOption}
            value={getSelectedAddonValueId(addonOption)}
            onChange={handleOnChange}
          />
        );
      })}
    </div>
  );
};

AddonSelectGroup.propTypes = {
  addonOptions: PropTypes.arrayOf(AddonOptionPropType),
  addonRules: PropTypes.arrayOf(AddonRulePropType),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      addonOption: AddonOptionPropType,
      addonOptionValue: AddonOptionValuePropType,
    }),
  ),
  onChange: PropTypes.func,
  selectAddonOptionById: PropTypes.func.isRequired,
  selectAddonOptionValueById: PropTypes.func.isRequired,
};

AddonSelectGroup.defaultProps = {
  addonOptions: [],
  addonRules: [],
};

export default AddonSelectGroup;
