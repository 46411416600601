import { Card, CardBody, Col, Media, Row } from 'reactstrap';
import { ComplaintCategorySummary } from './types';
import LoadingOverlay from 'react-loading-overlay';
import { Spinner } from 'components/Common';

interface ComplaintCategorySummariesProps {
  data: ComplaintCategorySummary[];
  isLoading: boolean;
}

const ComplaintCategorySummeries = ({
  data,
  isLoading,
}: ComplaintCategorySummariesProps) => {
  const total = data.reduce(
    (acc, category) => acc + category.count,
    0,
  );
  return (
    <LoadingOverlay active={isLoading} spinner text="Fetching Data">
      {!isLoading && (
        <Row>
          <Col>
            <div className="d-flex flex-row mb-4 justify-content-start align-items-center">
              <h4 className="card-title mb-0">
                Feedback Category Overview
              </h4>
              <div
                className="d-flex align-items-center ml-2 p-1"
                style={{
                  border: '1px solid #A42E27',
                  backgroundColor: '#A42E27',
                  borderRadius: '4px',
                }}
              >
                <h4 className="mb-0 text-white font-size-16">
                  total: {total}
                </h4>
              </div>
            </div>
          </Col>
        </Row>
      )}
      <Row
        style={{
          minHeight: isLoading ? '100px' : 'auto',
        }}
      >
        {data.map((category) => {
          return (
            <Col lg={3} xs={12} md={4} key={category.id}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        {category.name}
                      </p>
                      <h4 className="mb-0">{category.count}</h4>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    </LoadingOverlay>
  );
};

export default ComplaintCategorySummeries;
