import classNames from 'classnames';
import { get, debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import SortableItem from './SortableItem';

export interface Column<T> {
  dataField: string;
  text?: string;
  classes?: string;
  formatter?: (
    cell?: string | number,
    row?: T,
    rowIndex?: string | number,
  ) => React.ReactNode;
}

interface SortableTableProps<T> {
  columns: Column<T>[];
  dataSource: T[];
  onSort: (changedData?: T, beforeData?: T | null) => Promise<void>;
  renderSortableItem?: (
    children?: React.ReactNode,
    row?: T,
    rowIndex?: string | number,
  ) => React.ReactNode;
  noDataIndication?: () => React.ReactNode;
  keyField?: string;
  tableClasses?: string;
}

const SortContainer = SortableContainer((props: any) => (
  <tbody {...props} />
));

const DEBOUNCE_WAIT_MS = 300;

const SortableTable = <T,>({
  keyField = 'id',
  columns = [],
  tableClasses,
  dataSource,
  renderSortableItem = (children, row, index) => (
    // @ts-expect-error
    <SortableItem index={index} key={row?.id || index}>
      {children}
    </SortableItem>
  ),
  onSort = async () => {},
  noDataIndication = () => 'No Data',
}: SortableTableProps<T>) => {
  const [values, setValues] = useState(dataSource);

  useEffect(() => {
    // Update the state when the dataSource prop changes
    setValues(dataSource);
  }, [dataSource]);

  const memoizedValues = useMemo(() => values, [values]);

  const headers = columns.map((column) => (
    <th className={column.classes} key={column[keyField]}>
      {column.text}
    </th>
  ));

  const rows = useMemo(() => {
    if (!memoizedValues || memoizedValues.length === 0) return [];

    return memoizedValues.map((row, index) => {
      const children = columns.map((column) => {
        const cellValue = get(row, column.dataField);
        const toRenderInCell: React.ReactElement = column.formatter
          ? column.formatter(cellValue, row, index)
          : cellValue;

        return (
          <td className={column.classes} key={column[keyField]}>
            {toRenderInCell}
          </td>
        );
      });

      return renderSortableItem(children, row, index);
    });
  }, [memoizedValues, columns, renderSortableItem, keyField]);

  const arrayMove = (records: T[], from: number, to: number) => {
    records = records.slice();
    records.splice(
      to < 0 ? records.length + to : to,
      0,
      records.splice(from, 1)[0],
    );

    return records;
  };

  const debouncedOnSort = useMemo(
    () =>
      debounce(async (changedData: T, beforeData: T | null) => {
        await onSort(changedData, beforeData);
      }, DEBOUNCE_WAIT_MS),
    [onSort],
  );

  const onSortEnd = async ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      const changedData = memoizedValues[oldIndex];
      const newRecords = arrayMove(
        memoizedValues,
        oldIndex,
        newIndex,
      );
      const beforeData = newRecords[newIndex + 1] || null;
      setValues(newRecords);
      await debouncedOnSort(changedData, beforeData);
    }
  };

  const DraggableContainer = (props: any) => (
    <SortContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  return (
    <>
      <table
        className={classNames('table table-mobile', tableClasses)}
      >
        <thead>
          <tr>{headers}</tr>
        </thead>
        <DraggableContainer>
          {rows && rows.length > 0 ? rows : noDataIndication()}
        </DraggableContainer>
      </table>
    </>
  );
};

export default SortableTable;
