import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import {
  ComplaintOrFeedbackCard,
  ResolutionForCustomerCard,
  CorrectiveActionCard,
  Complaint,
  ComplaintInput,
  ComplaintResolutionSchema,
  ComplaintCorrectiveActionSchema,
} from 'lib/complaint';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { setComplaintFormStatus } from 'store/features/complaint/actionTypes';

interface ComplaintActionsSectionProps {
  data: Complaint | null;
  complaintId: string | null;
  isKitchenRepresentative: boolean;
  isAdmin: boolean;
  onSubmit: (payload: any) => Promise<void>;
  onSubmitResolution: (payload: any) => Promise<void>;
  onSubmitCorrectiveAction: (payload: any) => Promise<void>;
  mutateComplaint: () => Promise<void>;
  onDeleteResolution: () => Promise<void>;
  onDeleteCorrectiveAction: () => Promise<void>;
}

const ComplaintActionsSection: React.FC<ComplaintActionsSectionProps> = ({
  data,
  complaintId,
  isKitchenRepresentative,
  isAdmin,
  onSubmit,
  onSubmitResolution,
  onSubmitCorrectiveAction,
  mutateComplaint,
  onDeleteResolution,
  onDeleteCorrectiveAction,
}) => {
  const dispatch = useDispatch();
  const {
    isResolutionFormDisable,
    isCorrectiveActionFormDisable,
    isComplaintFormDisable,
  } = useSelector((state: RootState) => state.complaintFormStatus);

  useEffect(() => {
    dispatch(setComplaintFormStatus('isResolutionFormDisable', true));
    dispatch(
      setComplaintFormStatus('isCorrectiveActionFormDisable', true),
    );
    dispatch(setComplaintFormStatus('isComplaintFormDisable', true));
  }, [dispatch]);

  const handleComplaintFormDisable = (value: boolean) => {
    dispatch(setComplaintFormStatus('isComplaintFormDisable', value));
  };

  const handleResolutionFormDisable = (value: boolean) => {
    dispatch(
      setComplaintFormStatus('isResolutionFormDisable', value),
    );
  };

  const handleCorrectiveActionFormDisable = (value: boolean) => {
    dispatch(
      setComplaintFormStatus('isCorrectiveActionFormDisable', value),
    );
  };

  const onCancelCorrectiveAction = () => {
    dispatch(
      setComplaintFormStatus('isCorrectiveActionFormDisable', true),
    );
  };

  const onCancelResolution = () => {
    dispatch(setComplaintFormStatus('isResolutionFormDisable', true));
  };

  const onCancelComplaint = () => {
    dispatch(setComplaintFormStatus('isComplaintFormDisable', true));
  };

  const {
    feedback,
    reported_at,
    kitchen_representative,
    complaint_categories,
    corrective_action,
    compensation_amount,
    resolution,
    resolved_at,
    resolver,
    corrected_at,
    corrector,
    is_verified,
    assets,
  } = data || {};

  const complaintCategoryIds = complaint_categories?.map((category) =>
    String(category.id),
  );

  const complaintData: ComplaintInput = {
    feedback: feedback || '',
    reported_at: reported_at || '',
    kitchen_representative: kitchen_representative || '',
    complaint_categories: complaintCategoryIds || [],
    id: complaintId || '',
    assets: assets || [],
    is_verified: is_verified || false,
  };

  const resolutionData: ComplaintResolutionSchema = {
    id: complaintId || '',
    compensation_amount: compensation_amount || 0,
    resolution: resolution || '',
    resolved_at: resolved_at || '',
    resolver: resolver || undefined,
  };

  const correctiveActionData: ComplaintCorrectiveActionSchema = {
    id: complaintId || '',
    corrective_action: corrective_action || '',
    corrector: corrector || undefined,
    corrected_at: corrected_at || '',
  };
  return (
    <>
      <Row>
        <Col>
          <ComplaintOrFeedbackCard
            isComplaintFormDisable={isComplaintFormDisable}
            complaintData={complaintData}
            onSubmit={onSubmit}
            onCancelComplaint={onCancelComplaint}
            mutateComplaint={mutateComplaint}
            is_verified={is_verified || false}
            isKitchenRepresentative={isKitchenRepresentative}
            setisComplaintFormDisable={handleComplaintFormDisable}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ResolutionForCustomerCard
            isResolutionFormDisable={isResolutionFormDisable}
            resolutionData={resolutionData}
            onSubmitResolution={onSubmitResolution}
            onCancelResolution={onCancelResolution}
            is_verified={is_verified || false}
            isKitchenRepresentative={isKitchenRepresentative}
            setisResolutionFormDisable={handleResolutionFormDisable}
            onDelete={onDeleteResolution}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CorrectiveActionCard
            isCorrectiveActionFormDisable={
              isCorrectiveActionFormDisable
            }
            correctiveActionData={correctiveActionData}
            onSubmitCorrectiveAction={onSubmitCorrectiveAction}
            onCancelCorrectiveAction={onCancelCorrectiveAction}
            is_verified={is_verified || false}
            isAdmin={isAdmin}
            isKitchenRepresentative={isKitchenRepresentative}
            setisCorrectiveActionFormDisable={
              handleCorrectiveActionFormDisable
            }
            onDelete={onDeleteCorrectiveAction}
          />
        </Col>
      </Row>
    </>
  );
};

export default ComplaintActionsSection;
