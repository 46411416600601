import { routePaths } from 'appConfig';
import { Page } from 'components/Common';
import {
  LoveMessage,
  LoveMessagesTable,
  useLoveMessages,
} from 'lib/love-message';
import { useRouter } from 'lib/router';
// @ts-ignore
import { mapValues } from 'lib/javascript';
import { useHistory } from 'react-router';
import { Card, CardBody } from 'reactstrap';

const LoveMessagesPage = () => {
  const { query, updateQueryParams, setQueryParams } = useRouter();
  const history = useHistory();

  const {
    page = 1,
    sizePerPage = 15,
    search = '',
    // TODO: will implement user ids filter
    // userIds = '',
  } = query;

  const {
    loveMessages,
    isLoading,
    total,
    onDelete,
  } = useLoveMessages({
    params: { page, perPage: sizePerPage, search },
  });

  const onTableChange = async (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => {
    switch (action) {
      case 'pagination':
        const { page: _page, sizePerPage } = newState;
        updateQueryParams({
          page: _page,
          sizePerPage,
        });
        break;

      case 'filter':
        const mappedFilters = mapValues(
          newState.filters,
          (filter) => filter.filterVal,
        );

        const content = mappedFilters['content'];

        if (!content) {
          setQueryParams({
            page: newState.page,
            sizePerPage: newState.sizePerPage,
          });
        } else {
          updateQueryParams({
            page: '1',
            sizePerPage: newState.sizePerPage,
            search: content,
          });
        }
        break;

      default:
        break;
    }
  };

  const onShowDetails = (loveMessage: LoveMessage) => {
    history.push(`${routePaths.LOVE_MESSAGES}/${loveMessage.id}`);
  };

  const onClickUser = (userId: string) => {
    history.push(`${routePaths.USERS}/${userId}`);
  };

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <LoveMessagesTable
              data={loveMessages}
              isLoading={isLoading}
              totalSize={total}
              page={parseInt(page?.toString() ?? '1')}
              perPage={sizePerPage}
              allowFilter
              onTableChange={onTableChange}
              onShowDetails={onShowDetails}
              showRecipient
              onClickUser={onClickUser}
              onDelete={onDelete}
            />
          </CardBody>
        </Card>
      }
    />
  );
};

export default LoveMessagesPage;
