import { Datetime } from 'lib/datetime';
import { routePaths } from 'appConfig';
import { Link } from 'react-router-dom';

interface DatetimeNameInterface {
  datetime?: string;
  name?: string;
  email?: string;
  userId?: string;
}

const DatetimeName = ({
  datetime,
  name,
  userId,
}: DatetimeNameInterface) => {
  if (!datetime || !name) {
    return <span> - </span>;
  }
  return (
    <>
      <span> at </span>
      <Datetime value={datetime} />
      <span> by </span>
      {name && userId && (
        <Link to={`${routePaths.USERS}/${userId}`}>{name}</Link>
      )}
    </>
  );
};

export default DatetimeName;
