import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

const ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
};

const SIZE = {
  SMALL: 'small',
  DEFAULT: 'default',
};

const Title = ({ align, size, children }) => {
  return (
    <h2
      className={classNames({
        title: size === SIZE.DEFAULT,
        'text-center': align === ALIGN.CENTER,
        'title-sm': size === SIZE.SMALL,
      })}
    >
      {children}
    </h2>
  );
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(Object.values(ALIGN)),
  size: PropTypes.oneOf(Object.values(SIZE)),
};

Title.defaultProps = {
  align: ALIGN.LEFT,
  size: SIZE.DEFAULT,
};

export default Title;
