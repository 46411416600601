export const PRODUCT_FAMILY = {
  NORMAL: 'normal-meal',
  MERCHANDISE: 'merch',
  CUSTOMIZABLE: 'customizable-meal',
};

export const LABEL_BY_PRODUCT_FAMILY = {
  [PRODUCT_FAMILY.NORMAL]: 'Normal',
  [PRODUCT_FAMILY.MERCHANDISE]: 'Merchandise',
  [PRODUCT_FAMILY.CUSTOMIZABLE]: 'Customizable',
};

export const productFamilyOptions = Object.values(PRODUCT_FAMILY).map(
  (productFamily) => ({
    value: productFamily,
    label: LABEL_BY_PRODUCT_FAMILY[productFamily],
  }),
);
