import React from 'react';
import PropTypes from 'prop-types';

import { routePaths } from 'appConfig';
import Table from 'core/components/Table';
import { Datetime } from 'lib/datetime';
import { Link } from 'lib/router';

import { TagPropType } from './typedef';

const TagsTable = ({
  isLoading,
  data,
  page,
  sizePerPage,
  totalSize,
  onPageChange,
  onSizePerPageChange,
}) => {
  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (name, tag) => (
        <Link
          to={{
            pathname: routePaths.TAG_DETAIL,
            urlParams: { tagId: tag.id },
          }}
        >
          {name}
        </Link>
      ),
    },
    {
      dataField: 'created_at',
      text: 'Created At',
      formatter: (createdAt) => <Datetime value={createdAt} />,
    },
    {
      dataField: 'updated_at',
      text: 'Update At',
      formatter: (updatedAt) => <Datetime value={updatedAt} />,
    },
  ];

  const onTableChange = (action, newState) => {
    switch (action) {
      case 'pagination':
        if (newState.page !== page) onPageChange(newState.page);
        if (newState.sizePerPage !== sizePerPage)
          onSizePerPageChange(newState.sizePerPage);
        break;

      default:
    }
  };

  return (
    <Table
      remote
      loading={isLoading}
      keyField="id"
      data={data}
      columns={columns}
      pagination={{ page, sizePerPage, totalSize }}
      onTableChange={onTableChange}
    />
  );
};

TagsTable.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(TagPropType),
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  onSizePerPageChange: PropTypes.func,
};

TagsTable.defaultProps = {
  isLoading: false,
  onPageChange: () => {},
  onSizePerPageChange: () => {},
};

export default TagsTable;
