import { useCRUD } from 'lib/swr-crud';
import { getTagById } from './api';

const useTag = (tagId) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/cms/tags/${tagId}`,
    async () => {
      return getTagById(tagId);
    },
  );

  const tag = data || null;

  return {
    tag,
    error,
    mutate,
    isValidating,
    isLoading,
  };
};

export default useTag;
