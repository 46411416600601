export const DELIVERY_TIMESLOT_DURATION_HOURS = 3;

export const DAYS = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};

export const AVAILABLE_DELIVERY_DAYS = [
  DAYS.sun,
  DAYS.mon,
  DAYS.wed,
  DAYS.thu,
];

export const DELIVERY_TIMESLOTS_BY_DAY = {
  [DAYS.sun]: [{ hours: 19, minutes: 0 }],
  [DAYS.mon]: [
    { hours: 10, minutes: 0 },
    { hours: 19, minutes: 0 },
  ],
  [DAYS.tue]: [],
  [DAYS.wed]: [{ hours: 19, minutes: 0 }],
  [DAYS.thu]: [
    { hours: 10, minutes: 0 },
    { hours: 19, minutes: 0 },
  ],
  [DAYS.fri]: [],
  [DAYS.sat]: [],
};

export const AVAILABLE_CUTOFF_DAYS = [DAYS.tue, DAYS.fri];

export const CUTOFF_TIMESLOT_BY_DAY = {
  [DAYS.sun]: [],
  [DAYS.mon]: [],
  [DAYS.tue]: [{ hours: 18, minutes: 0 }],
  [DAYS.wed]: [],
  [DAYS.thu]: [],
  [DAYS.fri]: [{ hours: 18, minutes: 0 }],
  [DAYS.sat]: [],
};
