export const LIMIT_TYPE = {
  GLOBAL_LIMIT: 'global',
  PER_USER: 'per-user',
};

export const LABEL_BY_LIMIT_TYPE = {
  [LIMIT_TYPE.GLOBAL_LIMIT]: 'System-wide',
  [LIMIT_TYPE.PER_USER]: 'Per User',
};

export const DISCOUNT_TARGET = {
  CART: 'cart',
  PRODUCT: 'product',
};

export const LOGIC_OPERATOR = {
  AND: 'and',
  OR: 'or',
};

export const COUPON_TYPE = {
  REFERRAL: 'referral',
  REFERRAL_REWARD: 'referral-reward',
  CAMPAIGN: 'campaign',
};

export const LABEL_BY_COUPON_TYPE = {
  [COUPON_TYPE.REFERRAL]: 'Referral',
  [COUPON_TYPE.REFERRAL_REWARD]: 'Reward',
  [COUPON_TYPE.CAMPAIGN]: 'Campaign',
};
