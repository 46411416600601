import {
  GET_AUTH_USER,
  GET_AUTH_USER_ERROR,
  GET_AUTH_USER_SUCCESS,
} from './actionTypes';

export const getAuthUser = () => ({
  type: GET_AUTH_USER,
});

export const getAuthUserSuccess = (user) => ({
  type: GET_AUTH_USER_SUCCESS,
  payload: user,
});

export const getAuthUserError = (error) => ({
  type: GET_AUTH_USER_ERROR,
  payload: error,
});
