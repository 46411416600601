import * as routePaths from './routePaths';
import navConfig from './navConfig';
import breadcrumbConfig from './breadcrumbConfig';
import kitchenNavConfig from './kitchenNavConfig';

const config = {
  appName: process.env.REACT_APP_WEBSITE_NAME,
  appDescription: process.env.REACT_APP_WEBSITE_DESCRIPTION,
  api: {
    host: process.env.REACT_APP_API_HOST,
    version: process.env.REACT_APP_API_VERSION,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  },
  location: {
    country: 'SG',
  },
  currency: 'SGD',
  locale: 'en-SG',
  featureToggle: {
    orderInvoice: process.env.REACT_APP_FEATURE_TOGGLE_ORDER_INVOICE,
    ordersExport: process.env.REACT_APP_FEATURE_TOGGLE_ORDERS_EXPORT,
    salesReportByOrderExport:
      process.env
        .REACT_APP_FEATURE_TOGGLE_SALES_REPORT_BY_ORDER_EXPORT,
  },
  timezone: 'Asia/Singapore',
};

export default config;
export { routePaths, navConfig, breadcrumbConfig, kitchenNavConfig };
