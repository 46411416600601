import React from 'react';
import { useFormikContext } from 'formik';
import { Alert } from 'reactstrap';

const GeneralError = () => {
  const { status } = useFormikContext();

  const hasError = status?.error;

  if (!hasError) return null;

  return (
    <Alert color="danger" className="mb-2">
      {status.error}
    </Alert>
  );
};

GeneralError.propTypes = {};

export default GeneralError;
