import React from 'react';
import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import config from 'appConfig';
import { Logo } from 'components/Common';

import profile from '../../assets/images/dashboard-login-image.png';

const Layout = ({ title, subtitle, content, footer }) => {
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{title}</h5>
                        <p>{subtitle}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img
                        src={profile}
                        alt=""
                        className="img-fluid"
                      />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <Logo variant="logomark" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">{content}</div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {footer}
                <p>
                  © {new Date().getFullYear()} {config.appName}.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
};

export default Layout;
