import { Icon, IconButton, SimpleTable } from 'components/Common';
import { useConfirmation } from 'lib/confirm';
import notification from 'lib/notification';
import { useState } from 'react';
import CollectionPointSlotModal from './CollectionPointSlotModal';
import { DAY_LABELS, HOUR_LABELS } from './constants';
import type { CollectionPointSlotLine } from './types';

interface CollectionPointSlotLinesProps {
  lines: Array<CollectionPointSlotLine>;
  onUpdate: (line: CollectionPointSlotLine) => Promise<void>;
  onDelete: (slotId: string) => Promise<void>;
}

const CollectionPointSlotLines = ({
  lines,
  onUpdate,
  onDelete,
}: CollectionPointSlotLinesProps) => {
  const mutableLines = lines;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeId, setActiveId] = useState('');
  const [loadingIds, setLoadingIds] = useState<Array<string>>([]);

  const { confirm } = useConfirmation();

  const isLoadingLine = (id: string) => loadingIds.includes(id);
  const addLoadingLine = (id: string) =>
    setLoadingIds([...loadingIds, id]);
  const removeLoadingLine = (id: string) =>
    setLoadingIds(loadingIds.filter((line) => line !== id));

  const handleOpenModal = (id: string) => {
    setActiveId(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setActiveId('');
    setIsModalOpen(false);
  };

  const getInitialValues = (id: string) => {
    if (id) {
      const selectedLine = lines
        .find((line) => line.id === id) || { id, day: '', hour: '' }

      return {
        id: selectedLine.id,
        day: selectedLine.day,
        hour: selectedLine.hour,
      }
    }
  };

  const _onUpdate = async (line: CollectionPointSlotLine) => {
    const { id } = line;
    try {
      addLoadingLine(id);
      await onUpdate(line);
    } catch (err) {
      throw err;
    } finally {
      removeLoadingLine(id);
    }
  };

  const _onDelete = async (slotId: string) => {
    await confirm();

    try {
      addLoadingLine(slotId);
      await onDelete(slotId);
      notification.success('Deleted collection slot');
    } catch (err) {
      notification.error(
        err?.errors?.message || 'Failed to delete collection slot'
      );
    } finally {
      removeLoadingLine(slotId);
    }
  };

  const columns = [
    {
      dataField: 'day',
      text: 'Day',
      classes: 'w-50 align-middle',
      formatter: (_cell: any, {day}: CollectionPointSlotLine) => (
        <div>{DAY_LABELS[day]}</div>
      ),
    },
    {
      dataField: 'houe',
      text: 'Time',
      classes: 'w-50 align-middle',
      formatter: (_cell: any, { hour }: CollectionPointSlotLine) => (
        <div>{HOUR_LABELS[hour]}</div>
      ),
    },
    {
      dataField: 'id',
      text: '',
      formatter: (id: string) => (
        <div className="d-flex">
          {/* @ts-expect-error */}
          <IconButton
            className="mr-2"
            type="submit"
            color="success"
            onClick={() => handleOpenModal(id)}
            loading={isLoadingLine(id)}
            disabled={isLoadingLine(id)}
          >
            <Icon name="pencil" />
          </IconButton>
          {/* @ts-expect-error */}
          <IconButton
            color="danger"
            onClick={() => _onDelete(id)}
            loading={isLoadingLine(id)}
            disabled={isLoadingLine(id)}
          >
            <Icon name="delete" />
          </IconButton>
        </div>
      )
    }
  ];

  return (
    <>
      <SimpleTable
        data={mutableLines}
        columns={columns}
        renderBodyRow={(children, row) => (
          <tr key={row.id}>{children}</tr>
        )}
      />

      <CollectionPointSlotModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={_onUpdate}
        title="Update Collection Slot"
        buttonLabel="Save"
        initialValues={getInitialValues(activeId)}
      />
    </>
  );
};

export default CollectionPointSlotLines;
