import httpClient, { getResponseData } from 'lib/httpClient';

export const getUserReferralCode = async (userId) => {
  try {
    const response = await httpClient.get(
      `/cms/users/${userId}/coupon_referral`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw err;
  }
};
