const { useCRUD, formatUrlWithQueryParams } = require('lib/swr-crud');
const { getSalesMetrics } = require('./api');

const useSalesMetrics = ({ startDate, endDate }) => {
  const queryParams = {
    start_date: startDate,
    end_date: endDate,
  };

  const { data, isLoading } = useCRUD(
    formatUrlWithQueryParams('/cms/reports/sales', queryParams),
    async () => {
      return getSalesMetrics(queryParams);
    },
  );

  const salesMetrics = data || null;

  return {
    isLoading,
    salesMetrics,
  };
};

export default useSalesMetrics;
