import React from 'react';

import { Page, Tabs, Tab } from 'components/Common';
import datetime from 'lib/datetime';
import {
  DateRangeToggle,
  getFirstDayAndLastDayInMonthOf,
  SalesMetrics,
  ReportByOrderTable,
  ReportByProductTable,
  ReportByCategoryTable,
  ReportByCouponTable,
  ExportReportByOrderButton,
} from 'lib/business-analytics';
import { useRouter } from 'lib/router';

const TAB = {
  ORDER: 'order',
  PRODUCT: 'product',
  CATEGORY: 'category',
  COUPON: 'coupon',
};

const AnalyticsPage = () => {
  const [queryDateRange, setQueryDateRange] = React.useState(
    getFirstDayAndLastDayInMonthOf(datetime()),
  );

  const queryStartDate = datetime(queryDateRange[0]).format(
    'YYYY-MM-DD',
  );
  const queryEndDate = datetime(queryDateRange[1]).format(
    'YYYY-MM-DD',
  );

  const { query, setQueryParams } = useRouter();
  const { tab } = query;

  return (
    <Page
      content={
        <React.Fragment>
          <DateRangeToggle
            value={queryDateRange}
            onChange={setQueryDateRange}
          />
          <SalesMetrics
            startDate={queryStartDate}
            endDate={queryEndDate}
          />
          <Tabs
            activeKey={tab}
            onSelect={(newKey) => setQueryParams({ tab: newKey })}
          >
            <Tab eventKey={TAB.ORDER} title="Order">
              <div style={{ margin: '24px 0' }}>
                <div className="d-flex justify-content-end mb-2">
                  <ExportReportByOrderButton
                    startDate={queryStartDate}
                    endDate={queryEndDate}
                  />
                </div>
                <ReportByOrderTable
                  startDate={queryStartDate}
                  endDate={queryEndDate}
                />
              </div>
            </Tab>
            <Tab eventKey={TAB.PRODUCT} title="Product">
              <div style={{ margin: '24px 0' }}>
                <ReportByProductTable
                  startDate={queryStartDate}
                  endDate={queryEndDate}
                />
              </div>
            </Tab>
            <Tab eventKey={TAB.CATEGORY} title="Category">
              <div style={{ margin: '24px 0' }}>
                <ReportByCategoryTable
                  startDate={queryStartDate}
                  endDate={queryEndDate}
                />
              </div>
            </Tab>
            <Tab eventKey={TAB.COUPON} title="Coupon">
              <div style={{ margin: '24px 0' }}>
                <ReportByCouponTable
                  startDate={queryStartDate}
                  endDate={queryEndDate}
                />
              </div>
            </Tab>
          </Tabs>
        </React.Fragment>
      }
    />
  );
};

AnalyticsPage.propTypes = {};

export default AnalyticsPage;
