import React, { Key, ReactNode } from 'react';
import { Tab as RBTab } from 'react-bootstrap';

interface Props {
  title: ReactNode;
  children: ReactNode;
  eventKey?: string;
  key?: Key | null;
}

const Tab = ({ title, children, eventKey, key }: Props) => {
  return (
    <RBTab title={title} eventKey={eventKey} key={key}>
      {children}
    </RBTab>
  );
};

export default Tab;
