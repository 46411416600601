import React from 'react';
import PropTypes from 'prop-types';

import { useConfirmation } from 'lib/confirm';
import { PublishButton } from 'lib/publish';

import * as typedef from './typedef';
import * as api from './api';

const PublishProductButton = ({ product, mutateProduct }) => {
  const { confirm } = useConfirmation();

  const isPublished = Boolean(product.published_at);
  const associatedWithCategory =
    product.categories && product.categories.length !== 0;
  const canPublish = associatedWithCategory;
  const shouldConfirm = !isPublished && !canPublish;

  return (
    <PublishButton
      isPublished={isPublished}
      publishRequest={() => api.publishProduct(product.id)}
      unPublishRequest={() => api.unpublishProduct(product.id)}
      beforePublish={async () => {
        if (!shouldConfirm) return Promise.resolve();

        return await confirm({
          title: 'Confirmation to Publish Product',
          content:
            'This product is not associated with a category yet, it will be not visible on the storefront. Are you sure to publish it? ',
          buttonLabels: {
            yes: 'Yes, publish it',
            no: 'Cancel',
          },
        });
      }}
      onPublish={(_, result) => {
        const { response, error } = result;

        if (error) return;

        const newItem = response.data;

        mutateProduct(product.id, newItem);
      }}
    />
  );
};

PublishProductButton.propTypes = {
  product: typedef.Product.isRequired,
  mutateProduct: PropTypes.func.isRequired,
};

export default PublishProductButton;
