import {
  GET_ROLE_USER,
  GET_ROLE_USER_SUCCESS,
  GET_ROLE_USER_ERROR,
} from './actionTypes';

const defaultRole = {
  name: '',
  isAdmin: false,
  isExecutiveAssistant: false,
  isCustomerService: false,
  isCustomer: false,
  isAffiliate: false,
  isKitchenRepresentative: false,
};

const initialState = {
  role: defaultRole,
  loading: false,
  error: null,
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLE_USER:
      return { ...state, loading: true };
    case GET_ROLE_USER_SUCCESS:
      return { ...state, loading: false, role: action.payload };
    case GET_ROLE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default roleReducer;
