import { useListCRUD } from 'lib/swr-crud';
import httpClient, { getResponseData } from 'lib/httpClient';

const fetchers = {
  read: async (url, query) => {
    try {
      const response = await httpClient({
        method: 'get',
        url,
        query,
      });

      return getResponseData(response);
    } catch (error) {
      throw error;
    }
  },
};

const useUserRewardCoupons = ({ userId, page, sizePerPage } = {}) => {
  const shouldFetch = userId;

  const url = shouldFetch
    ? `/cms/coupons/${userId}/referral_reward`
    : null;

  const { data, error, create, isLoading, mutate } = useListCRUD(
    url,
    'id',
    {
      fetchers,
      query: {
        page,
        per_page: sizePerPage,
      },
    },
  );

  const rewardCoupons = data?.data || [];
  const total = data?.meta?.total;
  const pageCount = data?.meta?.last_page;

  const mutateRewardCoupons = (updater) =>
    mutate((data) => ({
      ...data,
      data: updater(data.data),
    }));

  return {
    rewardCoupons,
    total,
    pageCount,
    create,
    error,
    isLoading,
    mutateRewardCoupons,
  };
};

export default useUserRewardCoupons;
