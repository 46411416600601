import React from 'react';
import PropTypes from 'prop-types';
import { Alert as RSAlert, Fade } from 'reactstrap';

const Alert = ({
  children,
  className,
  closeClassName,
  color,
  isOpen,
  toggle,
  tag,
  fade,
  transition,
}) => {
  return (
    <RSAlert
      className={className}
      closeClassName={closeClassName}
      color={color}
      isOpen={isOpen}
      toggle={toggle}
      tag={tag}
      fade={fade}
      transition={transition}
    >
      {children}
    </RSAlert>
  );
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeClassName: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  fade: PropTypes.bool,
  // Controls the transition of the alert fading in and out
  // See Fade for more details
  transition: PropTypes.shape(Fade.propTypes),
};

Alert.defaultProps = {
  className: null,
  closeClassName: null,
  color: 'danger',
  isOpen: true,
  toggle: null,
  fade: true,
  transition: {},
};

export default Alert;
