import { useCRUD } from 'lib/swr-crud';
import { deleteProduct, getProductById } from './api';

const useProduct = (productId) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/cms/products/${productId}`,
    async () => {
      const { response, error } = await getProductById(productId);

      if (error) throw error;

      return response.data;
    },
  );

  const product = data || null;

  const remove = async () => {
    try {
      const { error } = await deleteProduct(productId);

      if (error) throw error;

      mutate(null);
    } catch (err) {
      throw err;
    }
  };

  return {
    product,
    error,
    mutate,
    remove,
    isValidating,
    isLoading,
  };
};

export default useProduct;
