import { useCRUD } from 'lib/swr-crud';
import {
  getCollectionById,
  postAddCollection,
  putUpdateCollection,
  deleteCollection,
} from './api';

const useCollection = (collectionId) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/cms/collections/${collectionId}`,
    async () => {
      return getCollectionById(collectionId);
    },
  );

  const update = (body) => {
    return putUpdateCollection(collectionId, body);
  };
  const _delete = () => {
    return deleteCollection(collectionId);
  };

  const collection = data || null;

  return {
    collection,
    error,
    mutate,
    isValidating,
    isLoading,
    update,
    _delete,
  };
};

export default useCollection;
