import {
  createCollectionSlot,
  updateCollectionSlot,
  deleteCollectionSlot,
} from './api';

const useCollectionPointSlots = (collectionPointId: string) => {
  const create = async (
    payload: { slot: string, timeframe: number },
  ) => {
    await createCollectionSlot(collectionPointId, payload);
  };

  const update = async (
    slotId: string,
    payload: { slot: string, timeframe: number },
  ) => {
    await updateCollectionSlot(collectionPointId, slotId, payload);
  };

  const deleteSlot = async (slotId: string) => {
    await deleteCollectionSlot(collectionPointId, slotId);
  };

  return {
    create,
    update,
    deleteSlot,
  };
};

export default useCollectionPointSlots;
