import PropTypes from 'prop-types';
import Table, { textFilter } from 'core/components/Table';
import { Link } from 'lib/router';
import FullName from './FullName';
import UserBadges from './UserBadges';

const UserTable = ({
  loading,
  data,
  page,
  sizePerPage,
  totalSize,
  onTableChange,
  getPathToDetailsPage,
  sortField,
  sortDir,
  name,
  email,
  phoneNumberFlag,
}) => {
  const columns = [
    {
      dataField: 'first_name',
      text: 'Full Name',
      formatter: (id, user) => {
        return (
          <Link to={getPathToDetailsPage(user)}>
            <div className="d-flex flex-row align-items-center">
              <FullName user={user} />
              <UserBadges data={user} />
            </div>
          </Link>
        );
      },
      filter: textFilter({ delay: 1500, defaultValue: name }),
    },
    {
      dataField: 'email',
      text: 'Email',
      filter: textFilter({ delay: 1500, defaultValue: email }),
    },

    {
      dataField: 'phone_number',
      text: 'Phone No.',
      filter: textFilter({
        delay: 1500,
        defaultValue: phoneNumberFlag,
      }),
    },

    {
      dataField: 'goal',
      text: 'Goal',
      //   filter: textFilter({ delay: 3000 }),
    },
    {
      dataField: 'gender',
      text: 'Gender',
      //   filter: selectFilter({
      //     options: [
      //       { label: 'Male', value: 'male' },
      //       { label: 'Female', value: 'female' },
      //     ],
      //   }),
    },
    {
      dataField: 'birthday',
      text: 'Birthday',
      //   filter: dateFilter({
      //     comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
      //     style: { display: 'inline-grid' },
      //   }),
    },
  ];

  return (
    <Table
      remote
      loading={loading}
      keyField="user_id"
      columns={columns}
      data={data}
      pagination={{ page, sizePerPage, totalSize }}
      onTableChange={onTableChange}
      sort={{ dataField: sortField, order: sortDir }}
    />
  );
};

UserTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  sortField: PropTypes.string,
  sortDir: PropTypes.string,
};

UserTable.defaultProps = {
  loading: false,
};

export default UserTable;
