import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ name, fontSize }) => {
  return <i className={`mdi mdi-${name} font-size-${fontSize}`}></i>;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
};

Icon.defaultProps = {
  fontSize: 16,
};

export default Icon;
