import React from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Breadcrumb as RSBreadcrumb,
  BreadcrumbItem as RSBreadcrumbItem,
} from 'reactstrap';
import { merge } from 'lib/javascript';

import { breadcrumbConfig as staticBreadcrumbConfig } from 'appConfig';

const pathToTo = (path, { URLParams } = {}) => {
  if (!URLParams) return path;

  const to = Object.keys(URLParams).reduce((to, paramName) => {
    return to.replace(`:${paramName}`, URLParams[paramName]);
  }, path);

  return to;
};

const Breadcrumb = ({ config: dynamicBreadcrumbConfig }) => {
  const match = useRouteMatch();
  const URLParams = useParams();

  const breadcrumbConfig = merge(
    {},
    staticBreadcrumbConfig,
    dynamicBreadcrumbConfig,
  );

  const crumbs = Object.values(breadcrumbConfig)
    .filter((route) => {
      return match.path.includes(route.path);
    })
    .map((route) => ({
      to: pathToTo(route.as ?? route.path, { URLParams }),
      label: route.label,
      title: route?.title,
      active: route.path === match.path,
    }));

  const title = crumbs[crumbs.length - 1]?.title;
  const currentRouteLabel = crumbs[crumbs.length - 1].label;

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">
            {title ?? currentRouteLabel}
          </h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <RSBreadcrumb>
                {crumbs.map((crumb) => (
                  <RSBreadcrumbItem active={crumb.active}>
                    <Link to={crumb.to}>{crumb.label}</Link>
                  </RSBreadcrumbItem>
                ))}
              </RSBreadcrumb>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  config: PropTypes.objectOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      as: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

export default Breadcrumb;
