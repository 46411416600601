import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { debounce, isEmpty } from 'lib/javascript';

import { Asset } from 'lib/media';
import MediaUploadInputOffline from '../MediaUploadInputOffline';
import MediaGalleryOffline from '../MediaGallery/MediaGalleryOffline';

const MediaPickerModalOffline = ({
  open,
  onClose,
  onProcessFile,
  allowMultiple,
  allowImagePreview,
  maxFiles,
  availableAssets,
}) => {
  const [fileData, setFileData] = useState([]); // Stores files and URLs
  const [error, setError] = useState(null);

  const handleOnClose = () => {
    setError(null);
    onClose();
  };

  const handleOnProcessFiles = () => {
    setFileData([]); // Reset file state
    handleOnClose();
  };

  const handleUploadInputError = (error) => {
    const { body } = error;
    setError(body);
  };

  const handleUploadInputWarning = (warning) => {
    const { type, body } = warning;
    if (type === 'warning' && body === 'Max files') {
      setError(`Please upload no more than ${maxFiles} images.`);
    } else {
      setError(body);
    }
  };

  const handleFilesChangeDebounced = debounce((filesWithUrls) => {
    onProcessFile(null, filesWithUrls);
    setFileData(filesWithUrls);
    handleOnProcessFiles();
  }, 300);

  const handleFilesChange = (filesWithUrls) => {
    handleFilesChangeDebounced(filesWithUrls);
  };

  return (
    <Modal
      isOpen={open}
      toggle={handleOnClose}
      size="lg"
      style={{ zIndex: 9999 }}
    >
      <ModalHeader toggle={handleOnClose}>Upload Image</ModalHeader>
      <ModalBody>
        <div>
          <MediaUploadInputOffline
            onChange={handleFilesChange}
            onError={handleUploadInputError}
            onWarning={handleUploadInputWarning}
            allowMultiple={allowMultiple}
            allowImagePreview={allowImagePreview}
            maxFiles={maxFiles}
            instantUpload
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}

          {!isEmpty(availableAssets) && (
            <div style={{ paddingTop: '24px' }}>
              <h6>Or Choose from Uploaded Media:</h6>
              <MediaGalleryOffline
                assets={availableAssets}
                isAssetSelectable
                onAssetSelect={(asset) => {
                  onProcessFile(null, asset);
                  handleOnClose();
                }}
              />
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

MediaPickerModalOffline.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onProcessFile: PropTypes.func,
  allowMultiple: PropTypes.bool,
  maxFiles: PropTypes.number,
  availableAssets: PropTypes.arrayOf(Asset),
};

MediaPickerModalOffline.defaultProps = {
  availableAssets: [],
};

export default MediaPickerModalOffline;
