import { isAxiosError } from './axios-helper';

const getErrorMessage = (err) => {
  if (isAxiosError(err)) {
    const expectedErrorStatuses = [403, 409];
    if (expectedErrorStatuses.includes(err.response?.status ?? 0)) {
      throw err;
    }
  }

  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const res = err.response.data;

    return res;
  }

  if (err.request) {
    return err.message;
  }

  // Something happened in setting up the request that triggered an Error
  return err.message;
};

export default getErrorMessage;
