import { useGet } from 'lib/swr-crud';
import { LoveMessagePayload, LoveMessageResponse } from './types';
import { AxiosError } from 'axios';
import { APIErrorResponse } from 'types/api';
import { getLoveMessage, updateLoveMessage } from './api';

const useLoveMessage = (loveMessageId: string) => {
  const { data, isLoading, mutate } = useGet<
    LoveMessageResponse,
    AxiosError<APIErrorResponse>
  >(
    `/love_messages/${loveMessageId}`,
    async () => await getLoveMessage(loveMessageId),
  );

  const _updateLoveMessage = async (payload: LoveMessagePayload) => {
    try {
      return await updateLoveMessage(loveMessageId, payload);
    } catch (error) {
      await mutate(data);
      throw error;
    }
  };

  return {
    loveMessage: data?.data ?? null,
    isLoading,
    updateLoveMessage: _updateLoveMessage,
  };
};

export default useLoveMessage;
