import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import { routePaths } from 'appConfig';
import { Button, Page } from 'components/Common';
import {
  ComplaintActionsSection,
  ComplaintModal,
  OrderComplaintDetails,
} from 'lib/complaint';
import { useConfirmation } from 'lib/confirm';
import { isEmpty } from 'lib/javascript';
import notification from 'lib/notification';
import {
  CancelOrderButton,
  CREATE_ORDER_LINE_ERROR_CODE,
  deleteOrder,
  deleteOrderLine,
  duplicateOrder,
  FirstOrderBadge,
  isOrderLegacy,
  isUtensilNeeded,
  KitchenBadge,
  ManageOrderDetails,
  ManageOrderLines,
  NewOrderModal,
  OrderDonation,
  OrderInvoiceButton,
  OrderLog,
  OrderNumber,
  OrderSummary,
  postAddOrderLine,
  putUpdateOrder,
  putUpdateOrderLine,
  putUpdateOrderLineAddons,
  putUpdateOrderStatus,
  RefundOrderButton,
  useOrder,
  useOrderLogs,
} from 'lib/orders';
import DeliveryDateTimeLogs from 'lib/orders/DeliveryDateTimeLogs';
import { useRouter } from 'lib/router';
import { List } from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { getRoleUser } from 'store/auth/GetRoleUser/actions';
import useComplaint from 'lib/complaint/use-complaint';
import { setComplaintFormStatus } from 'store/features/complaint/actionTypes';
import LoadingOverlay from 'react-loading-overlay';
import { isAxiosError } from 'lib/httpClient';

const OrderDetailsPage = () => {
  const history = useHistory();
  const { confirm } = useConfirmation();
  const { query, push } = useRouter();
  const { orderId } = query;
  const { isLoading, order, mutate } = useOrder(orderId);

  const [
    removingOrderLineIds,
    setRemovingOrderLineIds,
  ] = React.useState([]);

  const [
    updatingOrderLineIds,
    setUpdatingOrderLineIds,
  ] = React.useState([]);

  const [isOrderDuplicating, setOrderDuplicating] = React.useState(
    false,
  );

  const [isNewOrderModalOpen, setNewOrderModalOpen] = React.useState(
    false,
  );

  const [isDeletingOrder, setIsDeletingOrder] = React.useState(false);

  const { logs } = useOrderLogs(orderId);

  const updateOrder = async (
    {
      addresses,
      delivery_datetime,
      has_utensil,
      notes,
      collection_point_id,
    },
    actions,
  ) => {
    try {
      const updatedOrder = await putUpdateOrder(order.id, {
        addresses,
        delivery_time: delivery_datetime,
        has_utensil,
        collection_point_id,
        ...(isEmpty(notes) ? {} : { notes }),
      });

      notification.success('Successfully updated order');

      mutate(updatedOrder);
    } catch (err) {
      // actions.setErrors(err.errors);
      // either notification or field error
      let msg = err;
      if (typeof err === 'object') {
        const _errors = err.errors;
        const _keys = Object.keys(_errors);
        if (_errors && _keys.length > 0) {
          msg = _errors[_keys[0]];
        }
      }

      notification.error(msg);
      throw err;
    }
  };

  const updateOrderStatus = async (newValue) => {
    try {
      const data = await putUpdateOrderStatus(order.id, newValue);
      notification.success('Successfully updated status.');
      mutate(data);
    } catch (err) {
      notification.error(
        'Something went wrong when updating status.',
      );
    }
  };

  const _updateDuplicatedOrder = async (newOrder) => {
    try {
      setOrderDuplicating(true);
      await putUpdateOrder(newOrder.id, {
        addresses: newOrder.addresses,
        delivery_time: newOrder.delivery_datetime,
        has_utensil: isUtensilNeeded(order),
        notes: order.notes || '-',
      });

      const out_of_stock_list = [];

      // //add order line into new order
      const arrPromise = order.lines.map(async (line) => {
        try {
          return await postAddOrderLine(newOrder.id, {
            product_variant_id: line.product_variant_id,
            quantity: line.quantity,
            addon_option_value_ids:
              line?.components?.addon_option_values.map(
                (addonOptionValue) => addonOptionValue.id,
              ) || [],
          });
        } catch (err) {
          const isProductOutOfStock =
            err.errors.message ===
              CREATE_ORDER_LINE_ERROR_CODE.PRODUCT_OUT_OF_STOCK ||
            CREATE_ORDER_LINE_ERROR_CODE.INGREDIENT_OUT_OF_STOCK;

          if (isProductOutOfStock) out_of_stock_list.push(line);

          return;
        }
      });

      await Promise.all(arrPromise);

      if (out_of_stock_list.length > 0) {
        const item_names = out_of_stock_list.map(
          (item) => item.product.name,
        );

        notification.error(
          'Oops! These items are out of stock and were not added to your cart: ' +
            item_names.join(', '),
        );
      }

      setNewOrderModalOpen(false);

      history.push(`/orders/${newOrder.id}`);

      return;
    } catch (err) {
      throw err;
    } finally {
      setOrderDuplicating(false);
    }
  };

  const _handleDuplicatedOrder = async ({ user, addresses }) => {
    try {
      const { user_id } = user;

      const duplicatedOrder = await duplicateOrder(orderId, {
        user_id,
        addresses,
      });

      setNewOrderModalOpen(false);

      notification.success('Successfully duplicated order! ');

      push({
        pathname: routePaths.ORDER_DETAIL,
        urlParams: { orderId: duplicatedOrder.id },
      });
    } catch (err) {
      notification.error(
        err.errors.message ||
          'Something went wrong when duplicating order',
      );
    }
  };

  const _deleteOrder = async () => {
    try {
      setIsDeletingOrder(true);

      await deleteOrder(orderId);

      mutate(null);

      history.replace(routePaths.ORDERS);

      notification.success('Successfully deleted order.');
    } catch (err) {
      notification.error('Something went wrong when deleting order.');
    } finally {
      setIsDeletingOrder(false);
    }
  };

  const addOrderLine = async (orderLineToAdd) => {
    try {
      return await mutate(async (order) => {
        try {
          const response = await postAddOrderLine(
            order.id,
            orderLineToAdd,
          );

          const newOrder = response.data;

          return newOrder;
        } catch (err) {
          notification.error(err.errors.message);

          throw err;
        }
      });
    } catch (err) {
      throw err;
    }
  };

  const updateOrderLine = async (
    orderLineId,
    { unit_price, quantity },
  ) => {
    try {
      setUpdatingOrderLineIds((updatingOrderLines) => [
        ...updatingOrderLines,
        orderLineId,
      ]);

      return await mutate(async (order) => {
        try {
          const response = await putUpdateOrderLine(
            order.id,
            orderLineId,
            { unit_price, quantity },
          );

          notification.success('Successfully updated order line');

          const updatedOrder = response.data;

          return { ...order, ...updatedOrder };
        } catch (err) {
          notification.error(err?.response?.data?.errors?.message);
          if (!isAxiosError(err)) {
            throw err;
          }
        }
      });
    } catch (err) {
      notification.error(err.errors.message);
      throw err;
    } finally {
      setUpdatingOrderLineIds((updatingOrderLineIds) =>
        updatingOrderLineIds.filter(
          (updatingOrderLineId) =>
            updatingOrderLineId !== orderLineId,
        ),
      );
    }
  };

  const removeFromOrder = async (orderLine) => {
    try {
      setRemovingOrderLineIds((removingOrderLineIds) => [
        ...removingOrderLineIds,
        orderLine.id,
      ]);

      return await mutate(async (order) => {
        try {
          await deleteOrderLine(order.id, orderLine.id);

          const newOrderLines = order.lines.filter(
            (line) => line.id !== orderLine.id,
          );

          const newOrder = {
            ...order,
            lines: newOrderLines,
          };

          return newOrder;
        } catch (err) {
          notification.error(err?.response?.data?.errors?.message);

          throw err;
        }
      });
    } catch (err) {
      throw err;
    } finally {
      setRemovingOrderLineIds((removingOrderLineIds) =>
        removingOrderLineIds.filter(
          (removingOrderLineId) =>
            removingOrderLineId !== orderLine.id,
        ),
      );
    }
  };

  const updateOrderLineAddons = async (
    orderLineId,
    { addonOptionValueIds },
  ) => {
    try {
      setUpdatingOrderLineIds((updatingOrderLines) => [
        ...updatingOrderLines,
        orderLineId,
      ]);

      return await mutate(async (order) => {
        try {
          const response = await putUpdateOrderLineAddons(
            order.id,
            orderLineId,
            { addonOptionValueIds },
          );

          notification.success('Successfully updated addons');

          const updatedOrder = response.data;

          return { ...order, ...updatedOrder };
        } catch (err) {
          notification.error(err.errors.message);

          throw err;
        }
      });
    } catch (err) {
      notification.error(err.errors.message);
      throw err;
    } finally {
      setUpdatingOrderLineIds((updatingOrderLineIds) =>
        updatingOrderLineIds.filter(
          (updatingOrderLineId) =>
            updatingOrderLineId !== orderLineId,
        ),
      );
    }
  };

  const isRemovingOrderLine = (orderLineId) =>
    removingOrderLineIds.includes(orderLineId);

  const isUpdatingOrderLine = (orderLineId) =>
    updatingOrderLineIds.includes(orderLineId);

  const isReadyDuplicate =
    order?.delivery_datetime &&
    !isEmpty(order?.addresses?.shipping?.first_name) &&
    !isEmpty(order?.addresses?.billing?.first_name) &&
    order?.lines?.length > 0;

  const [complaintModal, setComplaintModal] = useState(false);

  const onShowComplaint = (complaintsId) => {
    history.push(`${routePaths.COMPLAINTS}/${complaintsId}`);
  };

  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);

  const { isKitchenRepresentative, isAdmin } = roleState?.role;

  useEffect(() => {
    dispatch(getRoleUser());
  }, [dispatch]);

  const complaintId = order?.complaint?.id ?? null;

  const {
    complaint,
    isLoading: isLoadingComplaint,
    updateComplaint,
    updateCorrectiveAction,
    updateResolution,
    deleteResolution,
    deleteCorrectiveAction,
    mutate: mutateComplaint,
  } = useComplaint(complaintId);

  const onSubmit = async (payload) => {
    try {
      await updateComplaint(payload);

      mutateComplaint();
      notification.success('Feedback updated.');
      dispatch(
        setComplaintFormStatus('isComplaintFormDisable', true),
      );
    } catch (error) {
      notification.error(
        'Something went wrong while updating Feedback.',
      );
    }
  };

  const onSubmitResolution = async (payload) => {
    try {
      await updateResolution(payload);

      mutateComplaint();
      notification.success('Resolution updated.');
      dispatch(
        setComplaintFormStatus('isResolutionFormDisable', true),
      );
    } catch (error) {
      notification.error(
        'Something went wrong while updating Feedback resolution.',
      );
    }
  };

  const onSubmitCorrectiveAction = async (payload) => {
    try {
      await updateCorrectiveAction(payload);

      mutateComplaint();
      notification.success('Corrective action updated.');
      dispatch(
        setComplaintFormStatus('isCorrectiveActionFormDisable', true),
      );
    } catch (error) {
      notification.error(
        'Something went wrong while updating corrective action.',
      );
    }
  };

  const onDeleteResolution = async () => {
    await confirm();
    try {
      await deleteResolution();
      notification.success('Resolution deleted.');
      await mutateComplaint();
    } catch (error) {
      notification.error(
        'Something went wrong while deleting feedback resolution.',
      );
    }
  };

  const onDeleteCorrectiveAction = async () => {
    await confirm();
    try {
      await deleteCorrectiveAction();
      notification.success('Corrective action deleted.');
      await mutateComplaint();
    } catch (error) {
      notification.error(
        'Something went wrong while deleting feedback corrective action.',
      );
    }
  };

  return (
    <Page
      breadcrumbProps={{
        config: {
          order: {
            path: routePaths.ORDER_DETAIL,
            label: (
              <React.Fragment>
                <div className="d-flex align-items-center">
                  <div>
                    Order <OrderNumber number={order?.order_number} />
                  </div>
                </div>
              </React.Fragment>
            ),
            title: (
              <React.Fragment>
                <div className="d-flex align-items-center">
                  <div>
                    Order <OrderNumber number={order?.order_number} />
                  </div>
                  {order?.is_first_order && (
                    <FirstOrderBadge className="ml-1" />
                  )}
                  <KitchenBadge
                    kitchenName={order?.kitchen?.display_name ?? ''}
                  />
                </div>
              </React.Fragment>
            ),
          },
        },
      }}
      content={
        <React.Fragment>
          {isLoading ? (
            <List />
          ) : (
            <>
              <Row>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '24px',
                  }}
                >
                  {isReadyDuplicate && (
                    <div style={{ marginRight: '8px' }}>
                      <Button
                        color={'info'}
                        onClick={() => setNewOrderModalOpen(true)}
                        loading={isOrderDuplicating}
                      >
                        Duplicate Order
                      </Button>
                    </div>
                  )}
                  <OrderInvoiceButton
                    orderId={order.id}
                    variant="contained"
                    color="info"
                    label="Invoice"
                    style={{ marginRight: '8px' }}
                  />
                  <RefundOrderButton
                    order={order}
                    style={{ marginRight: '8px' }}
                  />
                  <CancelOrderButton
                    order={order}
                    style={{ marginRight: '8px' }}
                  />
                  <div>
                    <Button
                      color={'danger'}
                      onClick={() =>
                        confirm({
                          title: `To Delete Order #${order?.order_number}?`,
                          content:
                            'This action is irreversible. Are you sure?',
                        }).then(_deleteOrder)
                      }
                      loading={isDeletingOrder}
                    >
                      Delete Order
                    </Button>
                  </div>
                </Col>
              </Row>
              <ManageOrderDetails
                order={order}
                updateOrder={updateOrder}
                updateOrderStatus={updateOrderStatus}
              />
              <Row>
                <Col lg={12} xl={8}>
                  <Card>
                    <CardBody>
                      <ManageOrderLines
                        data={order?.lines}
                        addOrderLine={addOrderLine}
                        removeFromOrder={removeFromOrder}
                        isRemovingOrderLine={isRemovingOrderLine}
                        isUpdatingOrderLine={isUpdatingOrderLine}
                        updateOrderLine={updateOrderLine}
                        updateOrderLineAddons={updateOrderLineAddons}
                        disabled={isOrderLegacy(order)}
                      />
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader className="bg-white pb-0">
                      <CardTitle>Delivery Date & Time Log</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <DeliveryDateTimeLogs
                        data={order?.change_delivery_datetime?.logs}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12} xl={4}>
                  <OrderSummary
                    isLoading={isLoading}
                    order={order}
                    mutateOrder={mutate}
                  />
                  {order?.donation?.has_donation && (
                    <OrderDonation
                      isLoading={isLoading}
                      order={order}
                    />
                  )}
                </Col>
                <Col xs={12}>
                  <Card
                    className={`
                    ${
                      order.complaint &&
                      isLoadingComplaint &&
                      'd-none'
                    }
                    `}
                  >
                    <CardHeader className="bg-white ">
                      <CardTitle>
                        Feedback
                        {!order.complaint && (
                          <Button
                            className={`float-right`}
                            onClick={() => {
                              setComplaintModal(true);
                            }}
                          >
                            Add Feedback
                          </Button>
                        )}
                      </CardTitle>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <OrderComplaintDetails
                        complaint={order.complaint}
                        isLoading={isLoadingComplaint}
                      />
                      {!isLoadingComplaint && order.complaint && (
                        <Button
                          block
                          onClick={() => {
                            onShowComplaint(order.complaint.id);
                          }}
                        >
                          View Feedback
                        </Button>
                      )}
                    </CardBody>
                  </Card>
                  <LoadingOverlay
                    active={isLoadingComplaint && order.complaint}
                    spinner
                    text="Fetching Feedback"
                  >
                    {isLoadingComplaint && order.complaint && (
                      <div
                        style={{ minHeight: '100px' }}
                        className="mb-2"
                      ></div>
                    )}
                    {order.complaint && !isLoadingComplaint && (
                      <ComplaintActionsSection
                        data={complaint}
                        complaintId={order.complaint.id}
                        isKitchenRepresentative={
                          isKitchenRepresentative
                        }
                        isAdmin={isAdmin}
                        onSubmit={onSubmit}
                        onSubmitResolution={onSubmitResolution}
                        onSubmitCorrectiveAction={
                          onSubmitCorrectiveAction
                        }
                        mutateComplaint={mutateComplaint}
                        onDeleteResolution={onDeleteResolution}
                        onDeleteCorrectiveAction={
                          onDeleteCorrectiveAction
                        }
                      />
                    )}
                  </LoadingOverlay>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardHeader className="bg-white pb-0">
                      <CardTitle>General Order Log</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <OrderLog logs={logs} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <NewOrderModal
            isOpen={isNewOrderModalOpen}
            onClose={() => setNewOrderModalOpen(false)}
            title="DUPLICATE ORDER"
            buttonTitle="Duplicate"
            onSubmit={_handleDuplicatedOrder}
          />
          <ComplaintModal
            complaintModal={complaintModal}
            setComplaintModal={setComplaintModal}
            orderData={order}
            mutateOrder={mutate}
          />
        </React.Fragment>
      }
    />
  );
};

OrderDetailsPage.propTypes = {};

export default OrderDetailsPage;
