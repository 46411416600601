import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import config from 'appConfig';

import yup from 'lib/validator';

// availity-reactstrap-validation

import { Alert } from 'core/components';
import { Layout as AuthLayout } from 'lib/auth';
import { FormManager, Form } from 'lib/form';
import {
  TextField,
  PasswordField,
  CheckboxField,
} from 'components/formFields';
import { Button } from 'components/Common';

// actions
import { loginUser } from '../../store/actions';

const initialValues = {
  email: '',
  password: '',
  rememberMe: true,
};

const validationSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
  rememberMe: yup.bool(),
});

const Login = (props) => {
  // handleValidSubmit
  function handleValidSubmit(values) {
    return props.loginUser(values, props.history);
  }

  return (
    <AuthLayout
      title="Welcome Back !"
      subtitle={`Sign in to continue to ${config.appName}.`}
      content={
        <FormManager
          className="form-horizontal"
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleValidSubmit}
        >
          {() => (
            <Form>
              {Boolean(props.error) ? (
                <Alert color="danger">{props.error.message}</Alert>
              ) : null}

              <TextField
                name="email"
                label="Email"
                className="form-control"
                placeholder="Enter email"
                type="email"
                required
              />

              <PasswordField
                name="password"
                label="Password"
                type="password"
                required
                placeholder="Enter Password"
              />

              <CheckboxField
                type="checkbox"
                name="rememberMe"
                label="Remember me"
              />

              <div className="mt-3">
                <Button type="submit" className="btn-block">
                  {props.loading ? 'Logging In...' : 'Log In'}
                </Button>
              </div>

              <div className="mt-4 text-center">
                <Link to="/forgot-password" className="text-muted">
                  <i className="mdi mdi-lock mr-1"></i> Forgot your
                  password?
                </Link>
              </div>
            </Form>
          )}
        </FormManager>
      }
      footer={
        <p>
          Don't have an account ?{' '}
          <Link
            to="register"
            className="font-weight-medium text-primary"
          >
            {' '}
            Signup now{' '}
          </Link>{' '}
        </p>
      }
    />
  );
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  loginUser: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

Login.defaultProps = {
  loading: false,
  error: null,
};

const mapStatetoProps = (state) => {
  const { loading, error } = state.Login;
  return { loading, error };
};

export default withRouter(
  connect(mapStatetoProps, { loginUser })(Login),
);
