import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from '../Label';

const Select = ({
  name,
  label,
  options,
  defaultValue,
  value,
  onChange,
  required,
  error,
  disabled,
  classes,
  styles,
}) => {
  return (
    <div
      className={classNames('form-group', classes.container)}
      style={styles.container}
    >
      <Label htmlFor={name} error={error} required={required}>
        {label}
      </Label>
      <div className="select-custom">
        <select
          name={name}
          id={name}
          className="form-control"
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    container: PropTypes.string,
  }),
  styles: PropTypes.shape({
    container: PropTypes.object,
  }),
};

Select.defaultProps = {
  classes: {
    container: null,
  },
  styles: {
    container: {},
  },
};

export default Select;
