import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import { useParams, useHistory } from 'react-router';

import { routePaths } from 'appConfig';
import { Page, Skeleton, Button } from 'components/Common';
import {
  useCoupon,
  CouponForm,
  useCouponLogs,
  CouponLog,
} from 'lib/coupons';
import notification from 'lib/notification';

const CouponDetailsPage = () => {
  const history = useHistory();
  const { couponCode } = useParams();
  const { coupon, isLoading, update, _delete } = useCoupon(
    couponCode,
  );
  const { logs } = useCouponLogs(couponCode);

  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const onUpdate = async (changes, actions) => {
    try {
      setIsUpdating(true);
      await update(changes);
      notification.success('Successfully updated coupon');
    } catch (error) {
      actions.setErrors(error.errors);
    } finally {
      setIsUpdating(false);
    }
  };

  const onDelete = async () => {
    try {
      setIsDeleting(true);
      await _delete();
      history.replace(routePaths.COUPONS);
      notification.success('Successfully deleted coupon');
    } catch (error) {
      notification.error(error?.errors?.message);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Page
      content={
        isLoading ? (
          <Skeleton type="page" />
        ) : (
          <React.Fragment>
            <Row>
              <Col
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: '12px',
                }}
              >
                <div style={{ marginRight: '8px' }}>
                  <Button
                    color={'danger'}
                    type="button"
                    loading={isDeleting}
                    onClick={onDelete}
                  >
                    Delete
                  </Button>
                </div>
                <div>
                  <Button
                    color={'primary'}
                    type="submit"
                    form="edit-coupon-form"
                    loading={isUpdating}
                  >
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <CouponForm
                  id="edit-coupon-form"
                  initialValues={coupon}
                  buttonText="Update"
                  onSubmit={onUpdate}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader className="bg-white pb-0">
                    <CardTitle>Coupon Log</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CouponLog logs={logs} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        )
      }
    />
  );
};

CouponDetailsPage.propTypes = {};

export default CouponDetailsPage;
