import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Common';
import useSalesReportByOrderExport from './useSalesReportByOrderExport';

const ExportReportByOrderButton = ({ startDate, endDate }) => {
  const {
    isExportingSalesReportByOrder,
    requestExport,
  } = useSalesReportByOrderExport({ startDate, endDate });

  return (
    <Button
      onClick={requestExport}
      loading={isExportingSalesReportByOrder}
    >
      Export All
    </Button>
  );
};

ExportReportByOrderButton.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default ExportReportByOrderButton;
