import { Card, CardBody, Col, Row } from 'reactstrap';
import React, { useState } from 'react';
import { Button } from 'components/Common';
import CatalogueItemFormModal from './CatalogueItemFormModal';
import { CatalogueItem, CatalogueItemPayload } from './types';
import notification from 'lib/notification';
import CatalogueItemsTable from './CatalogueItemsTable';
// @ts-ignore
import { isEmpty, isNull } from 'lib/javascript';

interface CatalogueItemsProps {
  items?: CatalogueItem[];
  onAdd: (payload: CatalogueItemPayload) => Promise<void>;
  onUpdate: (payload: CatalogueItemPayload) => Promise<void>;
  onDelete: (id: string | number) => Promise<void>;
  onSort: (
    changedId?: string | number,
    beforeId?: string | number | null,
  ) => Promise<void>;
}

const CatalogueItems = ({
  items,
  onAdd,
  onUpdate,
  onDelete,
  onSort,
}: CatalogueItemsProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<
    CatalogueItem | undefined
  >(undefined);

  const _initialValues: CatalogueItemPayload = {
    id: null,
    url: '',
    title: '',
    excerpt: '',
    image_url: '',
    price: null,
  };

  const [
    initialValues,
    setInitialValues,
  ] = useState<CatalogueItemPayload>(_initialValues);

  const onCloseModal = () => {
    setInitialValues(_initialValues);
    setIsOpenModal(false);
    setSelectedItem(undefined);
  };

  const onSubmit = async (payload: CatalogueItemPayload) => {
    try {
      if (isNull(payload.id) || isEmpty(payload.id)) {
        await onAdd(payload);
      } else {
        await onUpdate(payload);
      }
      onCloseModal();
    } catch (err) {}
  };

  const onUrlExtraction = async (url: string) => {
    try {
      const response = await fetch(url);
      const htmlContent = await response.text();

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlContent;

      const title = tempDiv.querySelector('title')?.innerText ?? '';
      const description =
        tempDiv
          .querySelector('meta[name="description"]')
          ?.getAttribute('content') ?? '';
      const image =
        tempDiv
          .querySelector('meta[property="og:image"]')
          ?.getAttribute('content') ?? '';

      setInitialValues((prev) => ({
        ...prev,
        url,
        title,
        excerpt: description,
        image_url: image,
      }));
    } catch (err) {
      notification.error(
        `Can not extract url, please input manually`,
      );
    }
  };

  const findItemById = (id: string | number) =>
    items?.find((item) => item.id === id);

  const handleOnSelectedItem = (id: string | number) => {
    const item = findItemById(id);

    if (!item) return;

    setSelectedItem(item);
    setInitialValues({
      id: item.id,
      url: item.url,
      title: item.title,
      excerpt: item.excerpt,
      price: item.price ?? null,
      image_url: item.image_url,
    });

    setIsOpenModal(true);
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h4>Manual Collection Items</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <CatalogueItemsTable
                data={items ?? []}
                onSelectedItem={handleOnSelectedItem}
                onDelete={onDelete}
                onSort={onSort}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex">
              {/* @ts-ignore */}
              <Button
                color="info"
                className="flex-fill"
                onClick={() => setIsOpenModal(true)}
              >
                {(items?.length ?? 0) > 0
                  ? 'Add More Manual Collection Item'
                  : 'Add New Manual Collection Item'}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <CatalogueItemFormModal
        isOpen={isOpenModal}
        onClose={onCloseModal}
        initialValues={initialValues}
        onUrlExtraction={onUrlExtraction}
        onSubmit={onSubmit}
        buttonLabel={Boolean(selectedItem?.id) ? 'Update' : 'Add'}
      />
    </React.Fragment>
  );
};

export default CatalogueItems;
