import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete } from 'components/Common';
import useCategories from './useCategories';

const CategoryAutoComplete = ({
  name,
  value,
  onChange,
  label,
  required,
  error,
  helperText,
  isMulti,
  isLoading: isLoadingProp,
  disabled,
}) => {
  const {
    isLoading: isCategoriesLoading,
    categories,
  } = useCategories();

  const isLoading = isLoadingProp || isCategoriesLoading;
  const shouldDisable = disabled || isLoading;

  return (
    <AutoComplete
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      options={categories}
      getOptionLabel={(category) => category.name}
      getOptionValue={(category) => category.id}
      isMulti={isMulti}
      isLoading={isLoading}
      disabled={shouldDisable}
    />
  );
};

CategoryAutoComplete.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

CategoryAutoComplete.defaultProps = {
  isMulti: false,
  isLoading: false,
  disabled: false,
};

export default CategoryAutoComplete;
