import { Dayjs } from 'dayjs';
import datetime, { formatTime, sortDateTimesAsc } from 'lib/datetime';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DatePicker, Select, Skeleton } from 'components/Common';
import { setAsSingaporeDateTime } from './utils';
import useSelectableSlots from './useSelectableSlots';
import { isEmpty } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { DELIVERY_TIMESLOT_DURATION_HOURS } from 'lib/deliveryDateTimes';

interface SelectCollectionPointSlotProps {
  collectionPoint: string;
  value: Date | null;
  onChange: (date: Date | string | null) => void;
  onFetching: (isFetching: boolean) => void;
}

const styles = {
  label: {
    fontWeight: 600,
    color: '#333',
  },
};

const SelectCollectionPointSlot = ({
  collectionPoint,
  value,
  onChange,
  onFetching,
}: SelectCollectionPointSlotProps) => {
  const prevCollectionPoint = useRef(collectionPoint);

  const [dateRange, setDateRange] = useState({
    startDate: setAsSingaporeDateTime(
      datetime(value?.toString()),
    ).startOf('month'),
    endDate: setAsSingaporeDateTime(
      datetime(value?.toString()),
    ).endOf('month'),
  });

  const handleOnMonthChange = (month: Date) => {
    const selectedMonth = datetime(month);
    setDateRange({
      startDate: setAsSingaporeDateTime(selectedMonth).startOf(
        'month',
      ),
      endDate: setAsSingaporeDateTime(selectedMonth).endOf('month'),
    });
    onChange(month);
  };

  const { selectableSlots, isLoading } = useSelectableSlots(
    collectionPoint,
    dateRange,
  );

  const disabledInput =
    !collectionPoint || !selectableSlots.length || isLoading;

  const convertSlotToSingaporeDatetime = useCallback(
    (slot: string) => {
      return setAsSingaporeDateTime(datetime(slot));
    },
    [],
  );

  const getCurrentSlotTimes = (date?: string) => {
    return selectableSlots
      .filter(({ slot }) => {
        const currentDatetime = convertSlotToSingaporeDatetime(slot);
        return (
          datetime(date).isSame(currentDatetime, 'day') &&
          datetime(date).isSame(currentDatetime, 'month') &&
          datetime(date).isSame(currentDatetime, 'year')
        );
      })
      .map(({ slot }) => convertSlotToSingaporeDatetime(slot));
  };

  const getDisabledDays = (day: Dayjs) => {
    const isDayAmongSlotDates = selectableSlots.some(({ slot }) =>
      convertSlotToSingaporeDatetime(slot).isSame(
        datetime(day),
        'day',
      ),
    );

    return !isDayAmongSlotDates;
  };

  const handleOnDateChange = (selectedDate: string) => {
    const currentSlotTimes = getCurrentSlotTimes(selectedDate);
    if (isEmpty(currentSlotTimes)) return;

    onChange(currentSlotTimes[0].toISOString());
  };

  const availableTimes = getCurrentSlotTimes(value?.toString() || '');

  useEffect(() => {
    const initialValueCallback = () => {
      if (isLoading || !collectionPoint) return;

      const availableSlots = selectableSlots
        .filter(({ is_available }) => is_available)
        .map(({ slot }) => convertSlotToSingaporeDatetime(slot));

      const [nextAvailableSlot] = sortDateTimesAsc(availableSlots);

      if (
        !value ||
        prevCollectionPoint.current !== collectionPoint ||
        !availableTimes.length
      ) {
        onChange(
          nextAvailableSlot ? nextAvailableSlot.toISOString() : null,
        );
      }

      prevCollectionPoint.current = collectionPoint;
    };

    onFetching(isLoading);
    initialValueCallback();
  }, [
    isLoading,
    selectableSlots,
    value,
    collectionPoint,
    onChange,
    convertSlotToSingaporeDatetime,
    onFetching,
    availableTimes,
  ]);

  const getCurrentSlotTimesOptions = () => {
    if (!availableTimes.length) {
      return [{ value: '', label: '' }];
    }

    return availableTimes.map((slot) => ({
      value: slot.toISOString(),
      label: `${formatTime(slot)} - ${formatTime(
        datetime(slot).add(DELIVERY_TIMESLOT_DURATION_HOURS, 'hour'),
      )}`,
    }));
  };

  return (
    <div className="tw-flex tw-gap-x-4">
      {isLoading || !collectionPoint ? (
        <Row>
          <Col xs="auto">
            {/* @ts-ignore */}
            <Skeleton type="line" height={40} />
          </Col>
          <Col xs="auto">
            {/* @ts-ignore */}
            <Skeleton type="line" height={40} />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs="auto">
            {/* @ts-ignore */}
            <DatePicker
              label="Date"
              disabled={disabledInput}
              disabledDays={getDisabledDays}
              value={value ? datetime(value).toDate() : null}
              onChange={handleOnDateChange}
              onMonthChange={handleOnMonthChange}
            />
          </Col>
          <Col xs="auto">
            <Select
              label="Time"
              disabled={disabledInput}
              value={value ? datetime(value).toISOString() : null}
              options={getCurrentSlotTimesOptions()}
              styles={styles}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                onChange(e.target.value)
              }
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SelectCollectionPointSlot;
