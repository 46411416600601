import React from 'react';
import PropTypes from 'prop-types';

import { useConfirmation } from 'lib/confirm';
import { PublishButton } from 'lib/publish';

import * as api from './api';

const PublishCategoryButton = ({ category, mutateCategory }) => {
  const { confirm } = useConfirmation();

  const isPublished = Boolean(category.published_at);
  const hasImage = category.assets && category.assets.length !== 0;
  const canPublish = hasImage;
  const shouldConfirm = !isPublished && !canPublish;

  return (
    <PublishButton
      isPublished={isPublished}
      publishRequest={() => api.publishCategory(category.id)}
      unPublishRequest={() => api.unpublishCategory(category.id)}
      beforePublish={async () => {
        if (!shouldConfirm) return Promise.resolve();

        return await confirm({
          title: 'Confirmation to Publish Category',
          content:
            'This category does not have an image uploaded yet. Are you sure to publish it?',
          buttonLabels: {
            yes: 'Yes, publish it',
            no: 'Cancel',
          },
        });
      }}
      onPublish={(_, result) => {
        const { response, error } = result;

        if (error) return;

        const newItem = response.data;

        mutateCategory(category.id, newItem);
      }}
    />
  );
};

PublishCategoryButton.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    published_at: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

export default PublishCategoryButton;
