import { useListCRUD } from 'lib/swr-crud';
import httpClient, { getResponseData } from 'lib/httpClient';

const fetchers = {
  read: async (url, query) => {
    const response = await httpClient({
      method: 'get',
      url,
      query,
    });

    return getResponseData(response);
  },
};

const useAddonOptions = ({
  page,
  sizePerPage,
  name,
  label,
  sortField,
  sortDir,
} = {}) => {
  const url = '/cms/addon_options';

  const { data, error, isLoading, mutate } = useListCRUD(url, 'id', {
    fetchers,
    query: {
      page,
      per_page: sizePerPage,
      name,
      label,
      sort_field: sortField,
      sort_dir: sortDir,
    },
  });

  const addonOptions = data?.data || [];
  const total = data?.meta?.total;

  return {
    addonOptions,
    total,
    error,
    isLoading,
    mutate,
  };
};

export default useAddonOptions;
