import React from 'react';
import PropTypes from 'prop-types';
// import { FormGroup, Input } from 'reactstrap';
import { FormCheck, FormText } from 'react-bootstrap';

import Label from '../Label';
import { useCheckoutGroupContext } from '../contexts/checkboxContexts';

const CheckboxInput = ({
  name: nameProp,
  label,
  checked: checkedProp,
  value,
  onChange: onChangeProp,
  onBlur,
  required,
  helperText,
  disabled,
}) => {
  const checkboxGroupCtx = useCheckoutGroupContext();

  let checked = checkedProp;
  let name = nameProp;
  let id = name;

  if (checkboxGroupCtx) {
    if (typeof checked === 'undefined') {
      checked = checkboxGroupCtx.value.includes(value);
    }
    if (typeof name === 'undefined') {
      name = checkboxGroupCtx.name;
      id = value;
    }
  }

  const onChange = (e) => {
    onChangeProp(e);
    if (checkboxGroupCtx) checkboxGroupCtx.onChange(e);
  };

  return (
    <FormCheck id={id}>
      <FormCheck.Input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      <Label htmlFor={id} check required={required}>
        {label}
      </Label>
      {helperText && <FormText>{helperText}</FormText>}
    </FormCheck>
  );
};

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

CheckboxInput.defaultProps = {
  value: null,
  onChange: () => {},
  onBlur: () => {},
  required: false,
  disabled: false,
};

export default CheckboxInput;
