import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Col } from 'reactstrap';

import { Label } from 'components/Common';

import * as types from 'lib/media';

import './MediaInputOffline.css';
import MediaGalleryOffline from '../MediaGallery/MediaGalleryOffline';
import MediaPickerModalOffline from '../MediaPickerModal/MediaPickerModalOffline';
import { isAssetOfKind } from 'lib/media/utils';

const ITEM_SIZE = '150px';

const MediaInputOffline = ({
  name,
  error,
  required,
  label,
  value: allAssets,
  onChange,
  parent,
  parentId,
  assetKind,
  allowMultiple,
  maxFiles,
  noContainer,
}) => {
  const value = assetKind
    ? allAssets.filter((asset) => isAssetOfKind(asset, assetKind))
    : allAssets;

  const [showModal, setShowModal] = React.useState(false);
  const [assets, setAssets] = React.useState(value);

  const canAdd = assets.length < maxFiles;

  const noOfAddedFiles = assets.length;
  const noOfFilesCanBeAdded = maxFiles - noOfAddedFiles;

  const handleOnProcessFile = (error, filesWithUrls) => {
    if (error) return;

    setAssets((assets) => (assets || []).concat(filesWithUrls));
    if (onChange) {
      onChange((assets || []).concat(filesWithUrls));
    }
  };

  return (
    <FormGroup>
      <Label htmlFor={name} error={error} required={required}>
        {label} ({noOfAddedFiles}/{maxFiles})
      </Label>

      <MediaGalleryOffline
        assets={assets}
        onAssetsChange={setAssets}
        itemSize={ITEM_SIZE}
      >
        {canAdd && (
          <Col xs="auto">
            <button
              type="button"
              className="add-image-btn"
              style={{ width: ITEM_SIZE, height: ITEM_SIZE }}
              onClick={() => setShowModal(true)}
            >
              <i className="bx bx-plus"></i>
            </button>
          </Col>
        )}
      </MediaGalleryOffline>

      <MediaPickerModalOffline
        open={showModal}
        onClose={() => setShowModal(false)}
        allowMultiple={false}
        maxFiles={noOfFilesCanBeAdded}
        onProcessFile={handleOnProcessFile}
        allowImagePreview={false}
        noContainer={noContainer}
      />
    </FormGroup>
  );
};

MediaInputOffline.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.arrayOf(types.Asset),
  onChange: PropTypes.func,
  parent: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  maxFiles: PropTypes.number,
  assetKind: PropTypes.oneOfType(Object.values(types.ASSET_KIND)),
};

MediaInputOffline.defaultProps = {
  value: [],
  onChange: () => {},
  error: false,
  required: false,
  assetKind: null,
};

MediaInputOffline.whyDidYouRender = true;

export default MediaInputOffline;
