import { useListCRUD } from 'lib/swr-crud';
import httpClient, { getResponseData } from 'lib/httpClient';

const fetchers = {
  read: async (url, query) => {
    try {
      const response = await httpClient({
        method: 'get',
        url,
        query,
      });

      return getResponseData(response);
    } catch (error) {
      throw error;
    }
  },
};

const formatOrderNumber = (orderNumber) =>
  orderNumber && orderNumber.replace(/^(#)/, '');

const useOrders = ({
  page,
  sizePerPage,
  orderNumber,
  status,
  customerId,
  deliveryStartDateTime,
  deliveryEndDateTime,
  sortField,
  sortDir,
} = {}) => {
  const url = '/cms/orders';

  const { data, error, create, isLoading, mutate } = useListCRUD(
    url,
    'id',
    {
      fetchers,
      query: {
        page,
        per_page: sizePerPage,
        order_number: formatOrderNumber(orderNumber),
        status,
        user_id: customerId,
        delivery_start: deliveryStartDateTime,
        delivery_end: deliveryEndDateTime,
        sort_field: sortField,
        sort_dir: sortDir,
      },
    },
  );

  const orders = data?.data || [];
  const total = data?.meta?.total;

  const mutateOrders = (updater = (arg) => arg) =>
    mutate((data) => ({
      ...data,
      data: updater(data.data),
    }));

  return {
    orders,
    total,
    create,
    error,
    isLoading,
    mutateOrders,
  };
};

export default useOrders;
