import React from 'react';
import PropTypes from 'prop-types';
import yup from 'lib/validator';

import { Row, Col } from 'reactstrap';

import { FormManager, Form, GeneralError } from 'lib/form';
import { passwordSchema } from 'lib/auth';
import { Button } from 'components/Common';
import { PasswordField } from 'components/formFields';
import { postResetPassword } from 'services/api';

const initialValues = {
  password: '',
  repeatPassword: '',
};

const validationSchema = yup.object({
  password: passwordSchema.adminPasswordSchema,
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
});

const ResetPasswordForm = ({ email, token, onResetPassword }) => {
  const handleOnSubmit = async ({ password }, actions) => {
    try {
      const { error, response } = await postResetPassword({
        email,
        token,
        password,
      });

      if (error) throw error;

      onResetPassword(null, response);

      return response;
    } catch (err) {
      onResetPassword(err, null);

      actions.setErrors(err.errors);
    }
  };

  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {() => (
        <Form>
          <GeneralError />

          <PasswordField
            name="password"
            label="New Password"
            autoComplete="new-password"
            required
          />

          <PasswordField
            name="repeatPassword"
            label="Repeat Your New Password"
            autoComplete="new-password"
            required
          />
          <Row className="form-group">
            <Col className="text-right">
              <Button type="submit">Change Password</Button>
            </Col>
          </Row>
        </Form>
      )}
    </FormManager>
  );
};

ResetPasswordForm.propTypes = {
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onResetPassword: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
