import { useCRUD } from 'lib/swr-crud';
import { getOrderLogsById } from './api';

const useOrderLogs = (orderId) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/cms/orders/${orderId}/logs`,
    async () => {
      return getOrderLogsById(orderId);
    },
  );

  const logs = data || null;

  return {
    logs,
    error,
    mutate,
    isValidating,
    isLoading,
  };
};

export default useOrderLogs;
