import React from 'react';

interface BadgeRendererProps {
  value: number | string | null;
  BadgeComponent: React.ElementType;
  className?: string;
}

const BadgeRenderer: React.FC<BadgeRendererProps> = ({
  value,
  BadgeComponent,
  className = '',
}) => {
  if (!value) return null;
  return <BadgeComponent className={className} value={value} />;
};

export default BadgeRenderer;
