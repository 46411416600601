import React from 'react';

import { Page } from 'components/Common';
import notification from 'lib/notification';
import { routePaths } from 'appConfig';
import { useHistory } from 'react-router';
import { Card, CardBody } from 'reactstrap';
import { createAddonRule, AddonRuleForm } from 'lib/addon-rules';

const NewAddonRulePage = () => {
  const history = useHistory();

  const onCreate = async (values, actions) => {
    try {
      await createAddonRule(values);
      notification.success('Successfully create Addon Rule');
      history.push(`${routePaths.ADDON_RULES}`);
    } catch (error) {
      actions.setErrors(error.errors);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Page
      content={
        <React.Fragment>
          <Card>
            <CardBody>
              <AddonRuleForm
                onSubmit={onCreate}
                initialValues={{
                  label: '',
                  is_visible: false,
                  selected_addon_option_value_ids: [],
                  value_addon_option_ids: [],
                }}
                buttonText="Add"
              />
            </CardBody>
          </Card>
        </React.Fragment>
      }
    />
  );
};

export default NewAddonRulePage;
