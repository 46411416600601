import React from 'react';
import { FormManager, Form, connectInput } from 'lib/form';
import yup from 'lib/validator';
import { TextField } from 'components/formFields';
import { Button } from 'components/Common';
import { Col, Row } from 'reactstrap';
import {
  AddonOptionValueMultiSelector,
  AddonOptionMultiSelector,
} from 'lib/addon-options';

const validationSchema = yup.object({
  label: yup.string().required(),
  is_visible: yup.boolean().required(),
  selected_addon_option_value_ids: yup.array(yup.string()),
  value_addon_option_ids: yup.array(yup.string()),
});

const AddonOptionValueSelectorField = connectInput(
  AddonOptionValueMultiSelector,
  {
    onChangeArgType: 'raw-value',
  },
);

const AddonOptionMultiSelectorField = connectInput(
  AddonOptionMultiSelector,
  {
    onChangeArgType: 'raw-value',
  },
);

const AddonRuleForm = ({ onSubmit, initialValues, buttonText }) => {
  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => {
        return (
          <Form>
            <Row>
              <Col md={12} lg={8}>
                <TextField name={'label'} label="Label" required />
              </Col>
            </Row>

            <Row>
              <Col md={12} lg={8}>
                <AddonOptionValueSelectorField
                  name="selected_addon_option_value_ids"
                  label="When the following option value(s) is/are chosen"
                  required
                  onChange={(value) => {
                    setFieldValue(
                      'selected_addon_option_value_ids',
                      value,
                    );
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12} lg={8}>
                <AddonOptionMultiSelectorField
                  name="value_addon_option_ids"
                  label="Hide AddOn Option(s)"
                  required
                  onChange={(value) => {
                    setFieldValue('value_addon_option_ids', value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Button type="submit" loading={isSubmitting}>
                  {buttonText}
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </FormManager>
  );
};

export default AddonRuleForm;
