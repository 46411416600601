import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { Page } from 'components/Common';
import { AddTagForm } from 'lib/tag';

const NewTagPage = () => {
  return (
    <Page
      content={
        <Card>
          <CardBody>
            <AddTagForm />
          </CardBody>
        </Card>
      }
    />
  );
};

NewTagPage.propTypes = {};

export default NewTagPage;
