import React from 'react';

import * as types from './typedef';
import { getTotalStock } from './utils';

const Stock = ({ product }) => {
  const totalStock = getTotalStock(product);
  const noStock = totalStock === 0;

  return <p style={noStock ? { color: 'red' } : {}}>{totalStock}</p>;
};

Stock.propTypes = {
  product: types.Product,
};

export default Stock;
