import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Page } from 'components/Common';
import { AddProductForm } from 'lib/product';

const NewProductPage = () => {
  return (
    <Page
      content={
        <Card>
          <CardBody>
            <AddProductForm />
          </CardBody>
        </Card>
      }
    />
  );
};

NewProductPage.propTypes = {};

export default NewProductPage;
