import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { TextField, SelectField, CheckboxField } from 'components/formFields';

import { countryOptions } from '../constants';

const AddressFormFields = ({
  name,
  showCompanyName,
  hasUnitNumber,
}) => {
  const makeNestedFieldName = (fieldName) => `${name}.${fieldName}`;

  return (
    <React.Fragment>
      <Row>
        <Col>
          <TextField
            name={makeNestedFieldName('first_name')}
            label="First Name"
            required
          />
        </Col>
        <Col>
          <TextField
            name={makeNestedFieldName('last_name')}
            label="Last Name"
            required
          />
        </Col>
      </Row>
      <Row>
        {showCompanyName && (
          <Col>
            <TextField
              name={makeNestedFieldName('company_name')}
              label="Company Name"
            />
          </Col>
        )}
        <Col>
          <TextField
            name={makeNestedFieldName('phone_number')}
            label="Contact Number"
            required
          />
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <SelectField
            name={makeNestedFieldName('country')}
            label="Country"
            options={countryOptions}
            required
          />
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <TextField
            name={makeNestedFieldName('postal_code')}
            label="Postal Code"
            required
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <TextField
            name={makeNestedFieldName('address_1')}
            label="Block No. & Street"
            required
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            name={makeNestedFieldName('address_2')}
            label="Building Name"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            name={makeNestedFieldName('address_3')}
            label="Unit No."
            disabled={!hasUnitNumber}
            required={hasUnitNumber}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CheckboxField
            name={makeNestedFieldName('do_not_have_unit_number')}
            label="There's no Unit No."
          />
        </Col>
      </Row>
      <hr className="mt-1 mb-3" />
    </React.Fragment>
  );
};

AddressFormFields.propTypes = {
  name: PropTypes.string.isRequired,
  showCompanyName: PropTypes.bool,
  hasUnitNumber: PropTypes.bool,
};

AddressFormFields.defaultProps = {
  showCompanyName: true,
  hasUnitNumber: true,
};

export default AddressFormFields;
