import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const process = async ({
  method = 'post',
  url,
  fieldName,
  file,
  cancelToken,
  onUploadProgress = () => {},
  onFormData = () => {},
}) => {
  try {
    // set data
    const formData = new FormData();

    onFormData(formData);

    formData.append(fieldName, file, file.name);

    // the request itself
    const response = await httpClient({
      method,
      url,
      data: formData,
      cancelToken,
      onUploadProgress,
    });

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const remove = async ({ imageId }) => {
  try {
    const response = await httpClient.delete(
      `/cms/images/${imageId}`,
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};
