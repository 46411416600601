import React from 'react';
import yup from 'lib/validator';
import { FormManager, Form, GeneralError } from 'lib/form';
import { merge } from 'lib/javascript';
import { TextField } from 'components/formFields';
import { Col, Row } from 'reactstrap';
import { Button } from 'components/Common';

const defaultValues = {
  organization_name: '',
  headline: '',
  short_description: '',
  description: '',
  link_text: '',
  link: '',
};

const validationSchema = yup.object({
  organization_name: yup.string().required(),
  headline: yup.string().required(),
});

const DonationForm = ({ initialValues, onSubmit, buttonTitle }) => {
  return (
    <React.Fragment>
      <FormManager
        initialValues={merge({}, defaultValues, initialValues)}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <GeneralError />
            <Row>
              <Col>
                <TextField
                  name="organization_name"
                  label="Organization Name"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  name="headline"
                  label="Headline"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  name="short_description"
                  label="Short Description"
                  type="textarea"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  name="description"
                  label="Description"
                  type="textarea"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  name="link_text"
                  label="Link Text"
                  helperText="Learn more Leave blank to show a default text (Visit Site)"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField name="link" label="Link" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {buttonTitle}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </FormManager>
    </React.Fragment>
  );
};

export default DonationForm;
