import { Dayjs } from 'dayjs';
import datetime, { dateToString } from 'lib/datetime';
import { displayName } from 'lib/utils';
import {
  Complaint,
  ComplaintOverviewPayload,
  ComplaintPayload,
  ComplaintSchema,
  StatusInfo,
} from './types';

export const DISPLAY_KITCHEN_MAIN = 'Kitchen SGF';
export const DISPLAY_KITCHEN_OTHER = 'Kitchen FG';

export const ISO_DATETIME_FORMAT_WITH_MILLISECONDS = `YYYY-MM-DDTHH:mm:ss.SSS[Z]`;

export const getComplaintPayload = (
  data: ComplaintSchema,
): ComplaintPayload => {
  const {
    complaint_categories,
    feedback,
    kitchen_representative,
    reported_date,
    reported_time,
  } = data;

  const combinedDateTime = `${reverseDate(
    datetime(reported_date),
  )} ${reported_time}:00`;

  const reported_at = datetime
    .tz(combinedDateTime, 'Asia/Singapore')
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  const payload: ComplaintPayload = {
    complaint_categories,
    feedback,
    kitchen_representative,
    reported_at,
    is_verified: false,
  };

  return payload;
};

export const reverseDate = (date: Dayjs) => {
  const convertDate = dateToString(date);
  const [day, month, year] = convertDate.split('-');
  return `${year}-${month}-${day}`;
};

export const getOverviewData = (
  data: Complaint | null,
): ComplaintOverviewPayload => {
  const {
    reporter,
    reported_at,
    resolver,
    resolved_at,
    corrector,
    corrected_at,
    verificator,
    verified_at,
  } = data || {};
  return {
    reporter:
      displayName({
        firstName: reporter?.first_name,
        lastName: reporter?.last_name,
      }) ||
      reporter?.email ||
      '',
    reporter_id: reporter?.user_id || '',
    reported_at: reported_at || '',
    resolver:
      displayName({
        firstName: resolver?.first_name,
        lastName: resolver?.last_name,
      }) ||
      resolver?.email ||
      '',
    resolver_id: resolver?.user_id || '',
    resolved_at: resolved_at,
    corrector:
      displayName({
        firstName: corrector?.first_name,
        lastName: corrector?.last_name,
      }) ||
      corrector?.email ||
      '',
    corrector_id: corrector?.user_id || '',
    corrected_at: corrected_at,
    verificator:
      displayName({
        firstName: verificator?.first_name,
        lastName: verificator?.last_name,
      }) ||
      verificator?.email ||
      '',
    verificator_id: verificator?.user_id || '',
    verified_at: verified_at,
  };
};

export const getStatusInfo = (complaint: Complaint): StatusInfo => {
  const statuses: string[] = [];
  const tooltips: string[] = [];

  if (complaint.corrective_action) {
    statuses.push('C');
    tooltips.push('Corrected');
  }
  if (complaint.resolution) {
    statuses.push('R');
    tooltips.push('Resolved');
  }
  if (complaint.is_verified) {
    statuses.push('V');
    tooltips.push('Verified');
  }

  return {
    statuses,
    tooltips,
  };
};