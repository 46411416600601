import ContentLoader from 'react-content-loader';

const AddressLoader = () => {
  return (
    <ContentLoader className="tw-w-full tw-flex tw-h-[225px] tw-bg-[#f9f9f9] tw-px-10 tw-pt-8 tw-pb-6">
      {/* Title */}
      <rect x="0" y="10" rx="4" ry="4" width="120" height="20" />
      <rect x="130" y="10" rx="4" ry="4" width="80" height="20" />

      {/* Name */}
      <rect x="0" y="40" rx="4" ry="4" width="150" height="20" />

      {/* Phone Number */}
      <rect x="0" y="70" rx="4" ry="4" width="200" height="20" />

      {/* Address */}
      <rect x="0" y="100" rx="4" ry="4" width="300" height="20" />
      <rect x="0" y="130" rx="4" ry="4" width="200" height="20" />
      <rect x="0" y="160" rx="4" ry="4" width="100" height="20" />

      {/* Delivery Notes */}
      <rect x="0" y="190" rx="4" ry="4" width="150" height="20" />

      {/* Edit Button */}
      <rect x="0" y="220" rx="4" ry="4" width="50" height="20" />
    </ContentLoader>
  );
};

export default AddressLoader;
