import { Button, Image, Modal } from 'components/Common';
import { Form, FormManager } from 'lib/form';
import { CatalogueItemPayload } from './types';
import { FormikHelpers } from 'formik';
import yup from 'lib/validator';
import { Col, Row } from 'reactstrap';
import { TextField } from 'components/formFields';
import { useState } from 'react';
import ImagePlaceholder from '../../assets/images/placeholder.png';

interface CatalogueItemFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  initialValues?: CatalogueItemPayload;
  buttonLabel?: string;
  onUrlExtraction?: (url: string) => Promise<void>;
  onSubmit: (payload: CatalogueItemPayload) => Promise<void>;
}

const validationSchema = yup.object({
  id: yup.string().nullable(),
  url: yup.string().url().required(),
  title: yup.string().required(),
  excerpt: yup.string().nullable(),
  price: yup.number().positive().nullable(),
  image_url: yup.string().url().nullable(),
});

const CatalogueItemFormModal = ({
  isOpen,
  onClose,
  title = 'Add New Manual Collection Item',
  initialValues,
  buttonLabel = 'Add',
  onUrlExtraction = async (_: string) => {},
  onSubmit,
}: CatalogueItemFormModalProps) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleOnSubmit = async (
    values: CatalogueItemPayload,
    actions: FormikHelpers<CatalogueItemPayload>,
  ) => {
    try {
      actions.setStatus({
        error: null,
      });

      const payload = {
        ...values,
        price: values.price ?? null,
      };

      await onSubmit(payload);
    } catch (err) {
      actions.setErrors(err?.errors);
      actions.setStatus({
        error: err?.errors?.message,
      });
      throw err;
    }
  };

  const onExtraction = async (url: string) => {
    setIsFetching(true);
    await onUrlExtraction(url).then((res) => {
      setIsFetching(false);
    });
  };

  return (
    <Modal open={isOpen} onClose={onClose} title={title} className="">
      <FormManager
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Row>
              <Col className="position-relative">
                {/* @ts-ignore */}
                <TextField
                  name="url"
                  label="URL"
                  required
                  style={{ paddingRight: '8.625rem' }}
                />
                {/* @ts-ignore */}
                <Button
                  type="button"
                  className="position-absolute"
                  style={{
                    top: '32px',
                    right: '16px',
                    padding: '0.25rem 0.5rem',
                    fontSize: '12px',
                  }}
                  onClick={() => onExtraction(values.url)}
                  disabled={isFetching}
                  loading={isFetching}
                >
                  Extract Metadata
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {/* @ts-ignore */}
                <TextField
                  name="title"
                  label="Title"
                  required
                  disabled={isFetching}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* @ts-ignore */}
                <TextField
                  name="excerpt"
                  label="Excerpt"
                  type="textarea"
                  rows="3"
                  disabled={isFetching}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* @ts-ignore */}
                <TextField type="number" name="price" label="Price" />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* @ts-ignore */}
                <TextField
                  name="image_url"
                  label="Image URL"
                  disabled={isFetching}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex justify-content-center mb-4">
                  {/* @ts-ignore */}
                  <Image
                    src={
                      Boolean(values.image_url)
                        ? values.image_url
                        : ImagePlaceholder
                    }
                    width={400}
                    height={320}
                    alt={values?.title ?? ''}
                    objectFit="cover"
                    className="rounded"
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                {/* @ts-ignore */}
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                >
                  {buttonLabel}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </FormManager>
    </Modal>
  );
};

export default CatalogueItemFormModal;
