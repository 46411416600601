import React from 'react';
import { Modal } from 'components/Common';
import yup from 'lib/validator';
import { FormManager, Form, connectInput } from 'lib/form';
import { Button } from 'components/Common';
import { IngredientSelector } from 'lib/ingredients';
import { Col, Row } from 'reactstrap';
import { TextField } from 'components/formFields';
import notification from 'lib/notification';

const IngredientSelectorField = connectInput(IngredientSelector, {
  onChangeArgType: 'raw-value',
});

const validationSchema = yup.object({
  ingredient_id: yup.string().nullable(),
  label: yup.string().required(),
  consume_quantity: yup.number().integer().required(),
  price: yup.number().required(),
});

const NewAddonOptionValueModal = ({
  isOpen,
  onClose,
  onAdd,
  mutate,
}) => {
  const _onAdd = async (values, actions) => {
    try {
      await onAdd(values);
      mutate();
      notification.success('Successfully add new Option Value');
      onClose && onClose();
    } catch (err) {
      actions.setErrors(err.errors);
      notification.error(err.errors.message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={'Add New Option Value'}
    >
      <FormManager
        initialValues={{
          label: '',
          ingredient_id: null,
          consume_quantity: 0,
          price: 0,
        }}
        validationSchema={validationSchema}
        onSubmit={_onAdd}
      >
        {({ setFieldValue, values, isSubmitting }) => (
          <Form>
            <Row>
              <Col md={12} lg={6}>
                <TextField name={'label'} label="Label" required />
              </Col>
            </Row>

            <Row>
              <Col md={12} lg={6}>
                <IngredientSelectorField
                  name="ingredient"
                  label="Ingredient"
                  isClearable
                  onChange={(ingredient) => {
                    setFieldValue(
                      'ingredient_id',
                      ingredient?.id || null,
                    );
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12} lg={6}>
                <TextField
                  name={'price'}
                  label="Price"
                  required
                  type="number"
                />
              </Col>

              <Col md={12} lg={6}>
                <TextField
                  name={'consume_quantity'}
                  label="Consume Quantity"
                  required
                  type="number"
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Button loading={isSubmitting} type="submit">
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </FormManager>
    </Modal>
  );
};

export default NewAddonOptionValueModal;
