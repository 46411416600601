import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import Breadcrumb from 'core/components/Breadcrumb';

const Page = ({ breadcrumbProps, content }) => {
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb config={breadcrumbProps.config} />
        {content}
      </Container>
    </div>
  );
};

Page.propTypes = {
  content: PropTypes.node.isRequired,
  breadcrumbProps: PropTypes.shape(Breadcrumb.propTypes),
};

Page.defaultProps = {
  breadcrumbProps: {},
};

export default Page;
