import { useConfirmation } from 'lib/confirm';
import {
  ComplaintCategory,
  ComplaintCategoriesQueryParams,
} from './types';
import { Table } from 'core/components';
import { Button, Icon, IconButton } from 'components/Common';
import { textFilter } from 'core/components/Table';
import { Datetime } from 'lib/datetime';
import { User } from 'lib/users';
import { displayName } from 'lib/utils';
import notification from 'lib/notification';
import { useDispatch, useSelector } from 'react-redux';
import { UserRoleRedux } from 'store/auth/GetRoleUser/types';
import { useEffect } from 'react';
import { getRoleUser } from 'store/auth/GetRoleUser/actions';

import { includes, gt } from 'lodash';

interface ComplaintCategoriesTableProps
  extends ComplaintCategoriesQueryParams {
  data: ComplaintCategory[];
  isLoading?: boolean;
  totalSize?: number;
  onTableChange?: (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => Promise<void>;
  onShowDetails?: (complaintCategory: ComplaintCategory) => void;
  onClickUser?: (userId: string) => void;
  onDelete?: (complaintCategoryId: string) => Promise<void>;
  allowFilter?: boolean;
}

const ComplaintCategoriesTable = ({
  data,
  isLoading,
  page,
  perPage,
  totalSize,
  allowFilter,
  search,
  onTableChange,
  onShowDetails,
  onClickUser,
  onDelete,
}: ComplaintCategoriesTableProps) => {
  const { confirm } = useConfirmation();
  const dispatch = useDispatch();
  const roleState = useSelector((state: UserRoleRedux) => state.role);
  const { isExecutiveAssistant, isAdmin } = roleState?.role;
  useEffect(() => {
    dispatch(getRoleUser());
  }, [dispatch]);
  const _onDelete = async (complaintCategoryId: string) => {
    await confirm();

    try {
      if (onDelete) {
        await onDelete(complaintCategoryId);
        notification.success(
          'Feedback category successfully deleted.',
        );
      }
    } catch (error) {
      notification.error(
        error?.errors?.message ||
          'Failed to delete Feedback category.',
      );
    }
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      formatter: (
        id: string,
        complaintCategory: ComplaintCategory,
      ) => (
        // @ts-ignore
        <Button
          variant="link"
          color="secondary"
          className="p-0 border-0 text-left"
          style={{ textDecoration: 'none' }}
          onClick={() =>
            onShowDetails && onShowDetails(complaintCategory)
          }
        >
          <span>{id}</span>
        </Button>
      ),
    },
    {
      dataField: 'name',
      text: 'Name',
      ...(allowFilter
        ? {
            filter: textFilter({ delay: 1500, defaultValue: search }),
          }
        : {}),
    },
    {
      dataField: 'created_at',
      text: 'Created At',
      formatter: (createdAt: string) => (
        <Datetime value={createdAt} />
      ),
    },
    {
      dataField: 'last_updated_by',
      text: 'Updated By',
      formatter: (updatedBy: User) => {
        const fullName = displayName({
          firstName: updatedBy?.first_name,
          lastName: updatedBy?.last_name,
        });

        return (
          // @ts-ignore
          <Button
            variant="link"
            color="secondary"
            className="p-0 border-0 text-left"
            style={{ textDecoration: 'none' }}
            onClick={() =>
              onClickUser && onClickUser(updatedBy?.user_id)
            }
          >
            <span>{fullName || 'N/A'}</span>
          </Button>
        );
      },
    },
    {
      dataField: '',
      text: 'Actions',
      formatter: (
        _: unknown,
        complaintCategory: ComplaintCategory,
      ) => (
        <div className="d-flex flex-row">
          {/* @ts-ignore */}
          <IconButton
            hidden={!includes([isExecutiveAssistant, isAdmin], true)}
            onClick={() =>
              onShowDetails && onShowDetails(complaintCategory)
            }
            color="info"
            variant="link"
          >
            <Icon name="pencil" />
          </IconButton>
          {/* @ts-ignore-line */}
          <IconButton
            hidden={
              !includes([isExecutiveAssistant, isAdmin], true) ||
              gt(complaintCategory.complaint_count, 0)
            }
            color="danger"
            variant="link"
            onClick={() => _onDelete(complaintCategory.id as string)}
          >
            <Icon name="delete" />
          </IconButton>
          {!includes([isExecutiveAssistant, isAdmin], true) && (
            <span className="ml-2">No actions available</span>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      remote
      loading={isLoading}
      keyField="id"
      columns={columns}
      data={data}
      pagination={{
        page,
        sizePerPage: perPage,
        totalSize,
      }}
      onTableChange={onTableChange}
    />
  );
};

export default ComplaintCategoriesTable;
