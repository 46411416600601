import React from 'react';
import PropTypes from 'prop-types';

import { FormManager, Form, connectInput } from 'lib/form';
import validator from 'lib/validator';
import { Button } from 'components/Common';
import notification from 'lib/notification';

import OrderStatusSelect from './OrderStatusSelect';
import { putBulkUpdateOrderStatus } from './api';

const OrderStatusSelectField = connectInput(OrderStatusSelect, {
  onChangeArgType: 'raw-value',
});

const initialValues = {
  status: '',
};

const validationSchema = validator.object({
  status: validator.string().required(),
});

const BulkUpdateOrderStatusForm = ({ orderIds, onSubmit }) => {
  const handleOnSubmit = async (values, actions) => {
    try {
      const { status } = values;

      const response = await putBulkUpdateOrderStatus({
        order_ids: orderIds,
        status,
      });

      onSubmit(null, response);

      notification.success(
        'Successfully bulk-updated order status. ',
      );

      return;
    } catch (error) {
      actions.setErrors(error.errors);
      if (error.errors.message)
        actions.setStatus(error.errors.message);

      onSubmit(error);

      notification.error(
        'Something went wrong when bulk updating order status. ',
      );
    }
  };

  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <OrderStatusSelectField
            name="status"
            label="New Order Status"
            required
          />
          <Button type="submit" loading={isSubmitting}>
            Bulk Update
          </Button>
        </Form>
      )}
    </FormManager>
  );
};

BulkUpdateOrderStatusForm.propTypes = {
  orderIds: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func,
};

BulkUpdateOrderStatusForm.defaultProps = {
  orderIds: [],
  onSubmit: () => {},
};

export default BulkUpdateOrderStatusForm;
