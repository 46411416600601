import React from 'react';
import PropTypes from 'prop-types';

import Table, { textFilter } from 'core/components/Table';
import { Datetime } from 'lib/datetime';
import { Link } from 'lib/router';
import { routePaths } from 'appConfig';
import { compile } from 'path-to-regexp';
import * as productFamily from 'lib/productFamily';
import { PublishProductButton, Stock } from 'lib/product';

const CollectionProductsTable = ({
  isLoading,
  data,
  sortField,
  sortDir,
}) => {
  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter({ delay: 3000 }),
      formatter: (cell, row) => (
        <Link
          to={compile(routePaths.PRODUCT_DETAILS)({
            productId: row?.id || '',
          })}
        >
          {cell}
        </Link>
      ),
    },
    {
      dataField: 'product_family',
      text: 'Product Family',
      formatter: (cell) =>
        productFamily.constants.LABEL_BY_PRODUCT_FAMILY[cell],
    },
    {
      dataField: 'variants[0].stock',
      text: 'Stock',
      formatter: (_cell, row) => <Stock product={row} />,
    },
    {
      dataField: 'variants[0].created_at',
      text: 'Created At',
      type: 'date',
      formatter: (cell) => <Datetime value={cell} />,
    },
    {
      dataField: 'variants[0].updated_at',
      text: 'Update At',
      type: 'date',
      formatter: (cell) => <Datetime value={cell} />,
    },
    // {
    //   dataField: '',
    //   text: 'Actions',
    //   formatter: (_cell, row) => (
    //     <PublishProductButton
    //       product={row}
    //       mutateProduct={mutateProduct}
    //     />
    //   ),
    // },
  ];

  return <Table keyField="id" columns={columns} data={data} />;
};

CollectionProductsTable.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

CollectionProductsTable.defaultProps = {
  isLoading: false,
};

export default CollectionProductsTable;
