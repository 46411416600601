import datetime, {
  sortDateTimesAsc,
  sortDateTimesDesc,
} from 'lib/datetime';
import React from 'react';
import {
  DELIVERY_SLOT_CONDITION,
  OPERATION_DATETIME_TYPE,
} from './constants';
import useModifiedDatetimes from './useModifiedDatetimes';
import {
  getCutOffDates,
  getCutOffTimesByDate,
  getDeliveryDates,
  getDeliveryTimesByDate,
} from 'lib/deliveryDateTimes/utils';

const DELIVERY_DATE_RANGE = {
  DAYS_BEFORE_NOW: 7,
  DAYS_AFTER_NOW: 30,
};

const MAX_RANGE_DAYS = 30;

const DeliverySlotsContext = React.createContext({
  modifiedDatetimes: [],
  maxRangeDays: MAX_RANGE_DAYS,
  isLoading: true,
  getAvailableOperationDateTimes: () => {},
  nextCutOffTime: getCutOffDates({
    start: datetime(),
    end: datetime().add(MAX_RANGE_DAYS, 'day'),
  }).map((date) => getCutOffTimesByDate(date))[0],
  refreshCutOffTime: () => {},
  getModifiedDataByTimeSlot: () => {},
  availableDeliverySlots: getDeliveryDates({
    start: datetime(),
    end: datetime().add(MAX_RANGE_DAYS, 'day'),
  })
    .map((date) => getDeliveryTimesByDate(date))
    .flat(),
  getFirstCutOffTime: () => {},
  nextDeliverySlot: datetime(),
  getCutOffTimeBeforeDateTime: () => {},
});

export const useDeliverySlotsContext = () => {
  return React.useContext(DeliverySlotsContext);
};

export const DeliverySlotsContextProvider = ({ children }) => {
  const [currentTime, setCurrentTime] = React.useState(datetime());
  const { modifiedDatetimes = [], isLoading } = useModifiedDatetimes({
    startDate: currentTime,
    endDate: currentTime.add(MAX_RANGE_DAYS, 'day'),
  });

  const getModifiedDataByTimeSlot = React.useCallback(
    (timeSlot) => {
      if (!modifiedDatetimes) return null;

      const found = modifiedDatetimes.find((item) => {
        return datetime(item.blocked_datetime).isSame(
          datetime(timeSlot),
        );
      });

      if (!found) return null;

      return found;
    },
    [modifiedDatetimes],
  );

  const applyOperationModificationToDateTimes = React.useCallback(
    (dateTimes) => {
      return dateTimes
        .map((dateTime) => {
          const modifiedData = getModifiedDataByTimeSlot(dateTime);

          if (!modifiedData) return dateTime;

          const { condition, move_to } = modifiedData;

          switch (condition) {
            case DELIVERY_SLOT_CONDITION.BLOCKED:
              return null;

            case DELIVERY_SLOT_CONDITION.MOVED:
              return datetime(move_to);

            default:
              return dateTime;
          }
        })
        .filter(Boolean); // remove blocked deliverySlot
    },
    [getModifiedDataByTimeSlot],
  );

  const getAvailableOperationDateTimes = React.useCallback(
    ({ start, end, operationDateTimeType }) => {
      let getOperationDates = () => {};
      let getOperationDateTimes = () => {};

      switch (operationDateTimeType) {
        case OPERATION_DATETIME_TYPE.CUTOFF:
          getOperationDates = getCutOffDates;
          getOperationDateTimes = getCutOffTimesByDate;
          break;

        case OPERATION_DATETIME_TYPE.DELIVERY:
          getOperationDates = getDeliveryDates;
          getOperationDateTimes = getDeliveryTimesByDate;
          break;

        default:
          throw Error(
            'unrecognized operationDateTimeType passed as argument',
          );
      }

      const operationDates = getOperationDates({
        start,
        end,
      });

      const operationDateTimes = operationDates.flatMap((date) =>
        getOperationDateTimes(date),
      );

      const operationDateTimesAfterMods = applyOperationModificationToDateTimes(
        operationDateTimes,
      );

      const moddedOperationDateTimesWithinRange = operationDateTimesAfterMods.filter(
        (cutOffDateTime) => cutOffDateTime.isAfter(start),
      );

      return moddedOperationDateTimesWithinRange;
    },
    [applyOperationModificationToDateTimes],
  );

  const getFirstCutOffTime = React.useCallback(
    ({ start, end }) => {
      const availableCutOffDateTimes = getAvailableOperationDateTimes(
        {
          start,
          end,
          operationDateTimeType: OPERATION_DATETIME_TYPE.CUTOFF,
        },
      );

      const [firstCutOffDateTime] = sortDateTimesAsc(
        availableCutOffDateTimes,
      );

      return firstCutOffDateTime;
    },
    [getAvailableOperationDateTimes],
  );

  const nextCutOffTime = React.useMemo(() => {
    return getFirstCutOffTime({
      start: currentTime,
      end: currentTime.add(MAX_RANGE_DAYS, 'day'),
    });
  }, [getFirstCutOffTime, currentTime]);

  const refreshCutOffTime = () => {
    setCurrentTime(datetime());
  };

  const availableDeliverySlots = React.useMemo(
    () =>
      getAvailableOperationDateTimes({
        start: datetime().subtract(
          DELIVERY_DATE_RANGE.DAYS_BEFORE_NOW,
          'day',
        ),
        end: datetime().add(
          DELIVERY_DATE_RANGE.DAYS_AFTER_NOW,
          'day',
        ),
        operationDateTimeType: OPERATION_DATETIME_TYPE.DELIVERY,
      }),
    [getAvailableOperationDateTimes],
  );

  const nextDeliverySlot = React.useMemo(() => {
    const [nextDeliveryDateTime] = sortDateTimesAsc(
      availableDeliverySlots,
    );

    return nextDeliveryDateTime;
  }, [availableDeliverySlots]);

  const getCutOffTimeBeforeDateTime = (dateTime) => {
    const cutOffTimes30DaysBeforeOrderDeliveryDateTime = getAvailableOperationDateTimes(
      {
        operationDateTimeType: OPERATION_DATETIME_TYPE.CUTOFF,
        start: datetime(dateTime).subtract(30, 'days'),
        end: datetime(dateTime),
      },
    );

    const [cutOffTimeBeforeOrderDelivery] = sortDateTimesDesc(
      cutOffTimes30DaysBeforeOrderDeliveryDateTime,
    );

    return cutOffTimeBeforeOrderDelivery;
  };

  return (
    <DeliverySlotsContext.Provider
      value={{
        isLoading,
        modifiedDatetimes,
        getAvailableOperationDateTimes,
        nextCutOffTime,
        getModifiedDataByTimeSlot,
        availableDeliverySlots,
        getFirstCutOffTime,
        nextDeliverySlot,
        refreshCutOffTime,
        maxRangeDays: MAX_RANGE_DAYS,
        getCutOffTimeBeforeDateTime,
      }}
    >
      {children}
    </DeliverySlotsContext.Provider>
  );
};
