import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const useQuery = () => {
  const locationSearch = useLocation().search;

  const query = useMemo(() => {
    return qs.parse(locationSearch, {
      ignoreQueryPrefix: true,
    });
  }, [locationSearch]);

  return query;
};

export default useQuery;
