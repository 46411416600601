import httpClient, { getResponseData } from 'lib/httpClient';
import { createQueryString } from 'lib/utils';
import {
  ComplaintCategoriesQueryParams,
  ComplaintCategoriesResponse,
  ComplaintCategoryPayload,
  ComplaintCategoryResponse,
  ComplaintCategorySummariesQueryParams,
  ComplaintCategorySummariesResponse,
} from './types';

export const getComplaintCategoriesUrl = (
  params?: ComplaintCategoriesQueryParams,
) => {
  const formattedQueryString = createQueryString({
    page: params?.page ?? undefined,
    per_page: params?.perPage ?? undefined,
    search: params?.search ?? undefined,
    user_ids: params?.userIds?.join(','),
  });

  return `/cms/complaint_categories?${formattedQueryString}`;
};

export const getComplaintCategories = async (
  params?: ComplaintCategoriesQueryParams,
) => {
  const response = await httpClient.get<ComplaintCategoriesResponse>(
    getComplaintCategoriesUrl(params),
  );

  return response.data;
};

export const getComplaintCategory = async (
  complaintCategoryId: string,
) => {
  const response = await httpClient.get<ComplaintCategoryResponse>(
    `/cms/complaint_categories/${complaintCategoryId}`,
  );

  return response.data;
};

export const deleteComplaintCategory = async (
  complaintCategoryId: string,
) => {
  const response = await httpClient.delete(
    `/cms/complaint_categories/${complaintCategoryId}`,
  );

  return getResponseData(response).data;
};

export const updateComplaintCategory = async (
  complaintCategoryId: string,
  payload: ComplaintCategoryPayload,
) => {
  const response = await httpClient.put<ComplaintCategoryResponse>(
    `/cms/complaint_categories/${complaintCategoryId}`,
    payload,
  );

  return getResponseData(response).data;
};

export const storeComplaintCategory = async (
  payload: ComplaintCategoryPayload,
) => {
  const response = await httpClient.post<ComplaintCategoryResponse>(
    `/cms/complaint_categories`,
    payload,
  );

  return getResponseData(response).data;
};

export const getComplaintCategorySummaryUrl = (
  params?: ComplaintCategorySummariesQueryParams,
) => {
  const formattedQueryString = createQueryString({
    page: params?.page ?? undefined,
    per_page: params?.perPage ?? undefined,
    search: params?.search ?? undefined,
    status: params?.status ?? undefined,
    kitchen: params?.kitchen ?? undefined,
    started_reported_at: params?.started_reported_at ?? undefined,
    ended_reported_at: params?.ended_reported_at ?? undefined,
    started_delivery_at:
      params?.started_order_delivery_at ?? undefined,
    ended_delivery_at: params?.ended_order_delivery_at ?? undefined,
    complaint_categories: params?.complaint_categories ?? undefined,
  });

  return `/cms/complaint_categories/summaries?${formattedQueryString}`;
};

export const getComplaintCategorySummaries = async (
  params?: ComplaintCategorySummariesQueryParams,
) => {
  const response = await httpClient.get<ComplaintCategorySummariesResponse>(
    getComplaintCategorySummaryUrl(params),
  );

  return response.data;
};