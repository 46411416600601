import { TextField } from 'components/formFields';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import CollectionProductsTable from './CollectionProductsTable';
import { FormManager, Form } from 'lib/form';
import yup from 'lib/validator';
import { Button } from 'components/Common';

const validationSchema = yup.object({
  // delivery_datetime: yup.string().required(),
  // has_utensil: yup.bool().required(),
  // notes: yup.string().nullable(),
});

const CollectionDetails = ({
  collection,
  onUpdate,
  onDelete,
  isDeleting,
  onAddProduct,
  isAddingProduct,
}) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col className="d-flex justify-content-end align-item-center">
            <div>
              <Button
                color={'danger'}
                onClick={onDelete}
                loading={isDeleting}
              >
                Delete Collection
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <FormManager
            initialValues={collection}
            validationSchema={validationSchema}
            onSubmit={onUpdate}
          >
            {({ isSubmitting }) => (
              <Form>
                <Col xs={12}>
                  <TextField
                    type="text"
                    name="name"
                    label="Collection Name"
                  />
                  <Button type="submit" loading={isSubmitting}>
                    Update
                  </Button>
                </Col>
              </Form>
            )}
          </FormManager>
        </Row>
        {/* <Row>
          <Col
            lg={12}
            className="d-flex justify-content-end align-item-center"
          >
            <div>
              <Button
                color={'info'}
                onClick={onAddProduct}
                loading={isAddingProduct}
              >
                Delete Collection
              </Button>
            </div>
          </Col>

          <Col lg={12}>
            <CollectionProductsTable data={[]} />
          </Col>
        </Row> */}
      </CardBody>
    </Card>
  );
};

export default CollectionDetails;
