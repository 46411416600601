import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { useRouter } from 'lib/router';
import { useTags, TagsTable } from 'lib/tag';
import { Page } from 'components/Common';

const TagsPage = () => {
  const { query, updateQueryParams } = useRouter();

  const { page, sizePerPage } = query;

  const { isLoading, tags, total } = useTags({ page, sizePerPage });

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <TagsTable
              isLoading={isLoading}
              data={tags}
              totalSize={total}
              page={page}
              sizePerPage={sizePerPage}
              onPageChange={(page) =>
                updateQueryParams({ page, sizePerPage })
              }
              onSizePerPageChange={(sizePerPage) =>
                updateQueryParams({ page, sizePerPage })
              }
            />
          </CardBody>
        </Card>
      }
    />
  );
};

TagsPage.propTypes = {};

export default TagsPage;
