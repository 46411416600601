import { routePaths } from 'appConfig';
import datetime from 'lib/datetime';
import { DeliverySlotInfo } from 'lib/orders/DeliverySlotInfo';
import { User, UserBadges } from 'lib/users';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

interface ComplaintInformationInterface {
  orderNumber: string;
  deliveryDatetime: string;
  orderId: string;
  userId: string;
  customerName: string;
  email: string;
  completeOrderCount: number;
  user?: User;
}
export const ComplaintInformation = ({
  deliveryDatetime,
  orderId,
  userId,
  orderNumber,
  customerName,
  email,
  completeOrderCount,
  user,
}: ComplaintInformationInterface) => {
  return (
    <>
      <Row>
        <Col md={6}>
          <h5 className="font-weight-bold">{'Order:'}</h5>
        </Col>
        <Col md={6}>
          <h5 className="font-weight-bold">{'Deliver On:'}</h5>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <Link to={`${routePaths.ORDERS}/${orderId}`}>
            #{orderNumber}
          </Link>
        </Col>
        <Col md={6}>
          <DeliverySlotInfo
            deliverySlot={datetime(deliveryDatetime)}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          {' '}
          <h5 className="font-weight-bold">{'Customer'}</h5>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Link to={`${routePaths.USERS}/${userId}`}>
            <div className="d-flex flex-row align-items-center">
              {`${customerName} <${email}>`}
              {user && <UserBadges data={user} />}
            </div>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default ComplaintInformation;
