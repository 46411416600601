import React from 'react';
import PropTypes from 'prop-types';
import { Label as RSLabel } from 'reactstrap';
import { isString, merge } from 'lib/javascript';

const getLabel = (label, required) => {
  if (!label) return null;

  return label + (required ? ' *' : '');
};

const Label = ({
  children,
  htmlFor,
  style,
  className,
  error,
  required,
  check,
}) => {
  if (!children) return null;

  const _children = React.Children.map(children, (child) => {
    if (isString(child)) return getLabel(child, required);
    return child;
  });

  return (
    <RSLabel
      for={htmlFor}
      style={merge({}, style, error ? { color: 'red' } : {})}
      className={className}
      check={check}
    >
      {_children}
    </RSLabel>
  );
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  check: PropTypes.bool,
};

Label.defaultProps = {
  error: false,
  required: false,
  check: false,
};

export default Label;
