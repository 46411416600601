import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const getCoupons = async () => {
  try {
    const response = await httpClient.get('/cms/coupons');

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getCouponByCouponCode = async (couponCode) => {
  try {
    const response = await httpClient.get(
      `/cms/coupons/${couponCode}`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getCouponLogsByCouponCode = async (couponCode) => {
  try {
    const response = await httpClient.get(
      `/cms/coupons/${couponCode}/logs`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const postCreateCoupon = async (coupon) => {
  try {
    const response = await httpClient.post('/cms/coupons', coupon);

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putUpdateCoupon = async (oldCouponCode, changes) => {
  try {
    const response = await httpClient.put(
      `/cms/coupons/${oldCouponCode}`,
      changes,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteCoupon = async (couponCode) => {
  try {
    const response = await httpClient.delete(
      `/cms/coupons/${couponCode}`,
    );

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};
