import { Datetime } from 'lib/datetime';
import { LoveMessage, LoveMessagesQueryParams } from './types';
import { displayName } from 'lib/utils';
import { Table } from 'core/components';
import { Button, Icon, IconButton } from 'components/Common';
import { textFilter } from 'core/components/Table';
import { User } from 'lib/users';
import { useConfirmation } from 'lib/confirm';
import notification from 'lib/notification';

interface LoveMessagesTableProps extends LoveMessagesQueryParams {
  data: Array<LoveMessage>;
  isLoading?: boolean;
  totalSize?: number;
  showRecipient?: boolean;
  onTableChange?: (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => Promise<void>;
  onShowDetails?: (loveMessage: LoveMessage) => void;
  onClickUser?: (userId: string) => void;
  onDelete?: (loveMessageId: string) => Promise<void>;
  allowFilter?: boolean;
}

const LoveMessagesTable = ({
  data,
  isLoading,
  page,
  perPage,
  totalSize,
  allowFilter,
  search,
  showRecipient,
  // TODO: will implement user search
  // userIds,
  onTableChange,
  onShowDetails,
  onClickUser,
  onDelete,
}: LoveMessagesTableProps) => {
  const { confirm } = useConfirmation();

  const _onDelete = async (loveMessageId: string) => {
    await confirm();

    try {
      if (onDelete) {
        await onDelete(loveMessageId);
        notification.success('Love message successfully deleted.');
      }
    } catch (error) {
      notification.error(
        error?.errors?.message || 'Failed to delete love message.',
      );
    }
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      formatter: (id: string, loveMessage: LoveMessage) => (
        // @ts-ignore
        <Button
          variant="link"
          color="secondary"
          className="p-0 border-0 text-left"
          style={{ textDecoration: 'none' }}
          onClick={() => onShowDetails && onShowDetails(loveMessage)}
        >
          <span>{id}</span>
        </Button>
      ),
    },
    ...(showRecipient
      ? [
          {
            dataField: 'recipient',
            text: 'User',
            formatter: (recipient: User) => {
              const fullName = displayName({
                firstName: recipient?.first_name,
                lastName: recipient?.last_name,
              });

              return (
                // @ts-ignore
                <Button
                  variant="link"
                  color="secondary"
                  className="p-0 border-0 text-left"
                  style={{ textDecoration: 'none' }}
                  onClick={() =>
                    onClickUser && onClickUser(recipient?.user_id)
                  }
                >
                  <span>{fullName || 'N/A'}</span>
                </Button>
              );
            },
          },
        ]
      : []),
    {
      dataField: 'content',
      text: 'Content',
      ...(allowFilter
        ? {
            filter: textFilter({ delay: 1500, defaultValue: search }),
          }
        : {}),
    },
    {
      dataField: 'created_at',
      text: 'Created At',
      formatter: (createdAt: string) => (
        <Datetime value={createdAt} />
      ),
    },
    {
      dataField: 'last_updated_by',
      text: 'Updated By',
      formatter: (updatedBy: User) => {
        const fullName = displayName({
          firstName: updatedBy?.first_name,
          lastName: updatedBy?.last_name,
        });

        return (
          // @ts-ignore
          <Button
            variant="link"
            color="secondary"
            className="p-0 border-0 text-left"
            style={{ textDecoration: 'none' }}
            onClick={() =>
              onClickUser && onClickUser(updatedBy?.user_id)
            }
          >
            <span>{fullName || 'N/A'}</span>
          </Button>
        );
      },
    },
    {
      dataField: '',
      text: 'Actions',
      formatter: (_: unknown, loveMessage: LoveMessage) => (
        <div className='d-flex flex-row'>
          {/* @ts-ignore */}
          <IconButton
            onClick={() =>
              onShowDetails && onShowDetails(loveMessage)
            }
            color="info"
            variant="link"
          >
            <Icon name="pencil" />
          </IconButton>
          {/* @ts-ignore-line */}
          <IconButton
            color="danger"
            variant="link"
            onClick={() => _onDelete(loveMessage.id as string)}
          >
            <Icon name="delete" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Table
      remote
      loading={isLoading}
      keyField="id"
      columns={columns}
      data={data}
      pagination={{ page, sizePerPage: perPage, totalSize }}
      onTableChange={onTableChange}
    />
  );
};

export default LoveMessagesTable;
