import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const getAddonOptions = async (params) => {
  try {
    const response = await httpClient.get('/cms/addon_options', {
      params,
    });

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const createAddonOption = async (body) => {
  try {
    const response = await httpClient.post(
      '/cms/addon_options',
      body,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getAddonOption = async (addonOptionId) => {
  try {
    const response = await httpClient.get(
      '/cms/addon_options/' + addonOptionId,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const updateAddonOption = async (addonOptionId, body) => {
  try {
    const response = await httpClient.put(
      `/cms/addon_options/${addonOptionId}`,
      body,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteAddonOption = async (addonOptionId) => {
  try {
    const response = await httpClient.delete(
      `/cms/addon_options/${addonOptionId}`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const createAddonOptionValue = async (addonOptionId, body) => {
  try {
    const response = await httpClient.post(
      `/cms/addon_options/${addonOptionId}/values`,
      body,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const updateAddonOptionValue = async (
  addonOptionId,
  addonOptionValueId,
  body,
) => {
  try {
    const response = await httpClient.put(
      `/cms/addon_options/${addonOptionId}/values/${addonOptionValueId}`,
      body,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteAddonOptionValue = async (
  addonOptionId,
  addonOptionValueId,
) => {
  try {
    const response = await httpClient.delete(
      `/cms/addon_options/${addonOptionId}/values/${addonOptionValueId}`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const reorderAddonOptionValue = async (
  addonOptionId,
  addonOptionValueId,
  body,
) => {
  try {
    const response = await httpClient.put(
      `/cms/addon_options/${addonOptionId}/values/${addonOptionValueId}/rank`,
      body,
    )

    return getResponseData(response).data
  } catch (err) {
    throw getErrorMessage(err);
  }
};
