import config from 'appConfig';
import { Button, DatePicker, Modal } from 'components/Common';
import datetime from 'lib/datetime';
import httpClient from 'lib/httpClient';
import notification from 'lib/notification';
import { useState } from 'react';

type Props = {
  userID: string;
};

const ExportReferredCustomer = ({ userID }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const initialValues: {
    startedAt: Date | null;
    endedAt: Date | null;
  } = {
    startedAt: null,
    endedAt: null,
  };

  const [dateRange, setDateRange] = useState(initialValues);

  const toggleModal = () => {
    setIsOpen((open) => !open);
  };

  const onExport = async () => {
    try {
      const formatStartedAt = () => {
        if (!dateRange.startedAt) {
          return null;
        }

        return datetime(dateRange.startedAt)
          .tz(config.timezone, true)
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .toISOString();
      };

      const formatEndedAt = () => {
        if (!dateRange.endedAt) {
          return null;
        }

        return datetime(dateRange.endedAt)
          .tz(config.timezone, true)
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 59)
          .toISOString();
      };

      const payload = {
        started_at: formatStartedAt(),
        ended_at: formatEndedAt(),
      };

      const res = await httpClient.post(
        `/cms/users/${userID}/reports/referred_orders`,
        payload,
      );

      notification.success(res.data.data?.message);
    } catch (e) {
      notification.error(
        e.response?.data?.errors?.message ??
          'Failed to export referred customers, please let the dev team know',
      );
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <>
      {/* @ts-ignore-line */}
      <Button variant="link" onClick={toggleModal}>
        <span>Export referred customers</span>
      </Button>
      <Modal
        open={isOpen}
        onClose={toggleModal}
        title="Export referred customers"
        className="" // IDK, just to hide an error
        actions={[
          {
            id: 'cancel',
            label: 'Cancel',
            onClick: toggleModal,
            color: 'secondary',
          },
          {
            id: 'export',
            label: 'Export',
            onClick: onExport,
            color: 'primary',
          },
        ]}
      >
        <div className="d-flex" style={{ gap: '1rem' }}>
          <DatePicker
            label="Start Date"
            value={dateRange.startedAt}
            onChange={(date) =>
              setDateRange((prev) => ({
                ...prev,
                startedAt: date,
              }))
            }
            required={false}
            clearable
            containerClassName="w-100"
            inputClassName="w-100"
          />
          <DatePicker
            label="End Date"
            value={dateRange.endedAt}
            onChange={(date) =>
              setDateRange((prev) => ({
                ...prev,
                endedAt: date,
              }))
            }
            required={false}
            clearable
            containerClassName="w-100"
            inputClassName="w-100"
          />
        </div>
      </Modal>
    </>
  );
};

export default ExportReferredCustomer;
