import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { RadioGroupContext } from '../contexts/radioContexts';

const RadioGroup = ({
  name,
  label,
  value: valueProp,
  onChange,
  children,
}) => {
  const [value, setValueState] = useState(valueProp);

  const handleChange = useCallback(
    (event) => {
      setValueState(event.target.value);

      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange],
  );

  const contextValue = useMemo(
    () => ({
      name,
      value,
      onChange: handleChange,
    }),
    [name, value, handleChange],
  );

  return (
    <RadioGroupContext.Provider value={contextValue}>
      <fieldset>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          {children}
        </Form.Group>
      </fieldset>
    </RadioGroupContext.Provider>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
};

RadioGroup.defaultProps = {
  onChange: () => {},
};

export default RadioGroup;
