import React from 'react';
import PropTypes from 'prop-types';
import slugify from '@sindresorhus/slugify';
import yup from 'lib/validator';

import {
  FormManager,
  Form,
  GeneralError,
  connectInput,
} from 'lib/form';
import notification from 'lib/notification';
import { merge } from 'lib/javascript';
import { MediaInput, ASSET_KIND } from 'lib/media';
import { constants } from 'lib/productFamily';
import { SelectField, TextField } from 'components/formFields';
import { Button } from 'components/Common';

import { putProduct } from './api';
import { MAX_LENGTHS } from './constants';
import useProduct from './useProduct';

const initialValues = {
  name: '',
  excerpt: '',
  short_description: '',
  slug: '',
};

const validationSchema = yup.object({
  name: yup.string().max(MAX_LENGTHS.NAME).required(),
  excerpt: yup.string().max(MAX_LENGTHS.EXCERPT).nullable(),
  short_description: yup
    .string()
    .max(MAX_LENGTHS.SHORT_DESCRIPTION)
    .nullable(),
  slug: yup.string().required(),
});

const MediaField = connectInput(MediaInput, {
  onChangeArgType: 'raw-value',
});

const BasicDetailForm = ({ productId, onSubmit }) => {
  const { isLoading, product, mutate } = useProduct(productId);

  const handleOnSubmit = async (product, actions) => {
    try {
      const { response, error } = await putProduct(
        productId,
        product,
      );

      if (error) throw error;

      mutate(response.data);

      notification.success(
        'Successfully updated product basic details',
      );
      onSubmit(null, response);
      return;
    } catch (error) {
      actions.setErrors(error.errors);
      actions.setStatus({
        error:
          error.errors?.message ||
          'Error updating product basic details',
      });
      onSubmit(error, null);
      return;
    }
  };

  const setSlugOnNameChange = (e, form) => {
    const newName = e.target.value;

    const newSlug = slugify(newName);

    form.setFieldValue('slug', newSlug);
  };

  if (isLoading) return null;

  return (
    <FormManager
      initialValues={merge({}, initialValues, product)}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {(form) => (
        <Form autoComplete="off">
          <GeneralError />
          <SelectField
            name="product_family"
            label="Product Family"
            options={constants.productFamilyOptions}
            disabled
          />
          <TextField
            name="name"
            label="Product Name"
            required
            maxLength={MAX_LENGTHS.NAME}
            onChange={(e) => setSlugOnNameChange(e, form)}
          />
          <TextField name="slug" label="Slug" required />
          <TextField
            name="excerpt"
            label="Excerpt"
            maxLength={MAX_LENGTHS.EXCERPT}
          />
          <TextField
            type="textarea"
            name="short_description"
            label={
              <>
                <p className="mb-1">Short Description</p>
                <p className="mb-0 font-weight-normal text-muted">
                  <span>Bold: </span>
                  <span>*text*</span>
                </p>
                <p className="mb-0 font-weight-normal text-muted">
                  <span>Italic: </span>
                  <span>_text_</span>
                </p>
              </>
            }
            maxLength={MAX_LENGTHS.SHORT_DESCRIPTION}
          />
          <MediaField
            name="assets"
            label="Image"
            parent="product"
            parentId={productId}
            assetKind={ASSET_KIND.IMAGE}
            allowMultiple
            maxFiles={4}
          />
          <Button type="submit" loading={form.isSubmitting}>
            Save Changes
          </Button>
        </Form>
      )}
    </FormManager>
  );
};

BasicDetailForm.propTypes = {
  onSubmit: PropTypes.func,
};

BasicDetailForm.defaultProps = {
  onSubmit: () => {},
};

export default BasicDetailForm;
