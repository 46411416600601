import { connectInput } from 'lib/form';
import { TextInput } from '../Common';

const NullableTextField = connectInput(TextInput, {
  updateFormFieldValue: (ev, { field, form }) => {
    form.setFieldValue(
      field.name,
      ev.target.value === '' ? null : ev.target.value,
    );
  },
  mapValue: (value) => (value === null ? '' : value),
});

export default NullableTextField;
