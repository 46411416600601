import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete } from 'components/Common';
import useTags from './useTags';

const TagAutoComplete = ({
  name,
  value,
  onChange,
  label,
  required,
  error,
  helperText,
  isMulti,
  isLoading: isLoadingProp,
  disabled,
}) => {
  const [total, setTotal] = React.useState(15);

  const {
    total: totalTags,
    isLoading: isTagsLoading,
    tags,
  } = useTags({ sizePerPage: total });

  React.useEffect(() => {
    if (totalTags && total !== totalTags) setTotal(totalTags);
  }, [total, totalTags]);

  const isLoading = isLoadingProp || isTagsLoading;
  const shouldDisable = disabled || isLoading;

  return (
    <AutoComplete
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      options={tags}
      getOptionLabel={(tag) => tag.name}
      getOptionValue={(tag) => tag.id}
      isMulti={isMulti}
      isLoading={isLoading}
      disabled={shouldDisable}
    />
  );
};

TagAutoComplete.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

TagAutoComplete.defaultProps = {
  isMulti: false,
  isLoading: false,
  disabled: false,
};

export default TagAutoComplete;
