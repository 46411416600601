import { useListCRUD } from 'lib/swr-crud';

const useIngredients = ({
  page,
  sizePerPage,
  name,
  type,
  sortField,
  sortDir,
}) => {
  const url = '/cms/ingredients';

  const { data, error, isLoading, mutate } = useListCRUD(url, 'id', {
    query: {
      page,
      per_page: sizePerPage,
      name,
      type,
      sort_field: sortField,
      sort_dir: sortDir,
    },
  });
  const ingredients = data?.data || [];
  const total = data?.meta?.total || 0;

  return {
    ingredients,
    error,
    isLoading,
    mutate,
    total,
  };
};

export default useIngredients;
