import datetime from 'lib/datetime';
import validator from 'lib/validator';
import { Form, FormManager, connectInput } from 'lib/form';
import { Button } from 'components/Common';
import { Col, Row } from 'reactstrap';
import { IngredientSelector } from 'lib/ingredients';
import {
  INITIAL_ORDER_STATUSES_TO_EXPORT,
  ISO_DATE_FORMAT,
} from './constants';
import { IngredientReportPayload } from './types';
import {
  DatePickerField,
  OrderStatusAutoCompleteField,
  getErrorMessage,
  getSuccessMessage,
} from './utils';
import useKitchenReport from './useKitchenReport';
import notification from 'lib/notification';

const IngredientSelectorField = connectInput(IngredientSelector, {
  onChangeArgType: 'raw-value',
});

const IngredientReportForm = () => {
  const initialValues: IngredientReportPayload = {
    delivery_start: datetime().format(ISO_DATE_FORMAT),
    delivery_end: null,
    ingredient_id: '',
    order_statuses: INITIAL_ORDER_STATUSES_TO_EXPORT,
  };

  const validationSchema = validator.object({
    delivery_start: validator.string().required(),
    delivery_end: validator.string().nullable(),
    ingredient_id: validator.string().required(),
    order_statuses: validator.array().of(validator.string()).min(1).required(),
  });

  const { requestKitchenReport } = useKitchenReport('ingredient');

  const handleOnSubmit = async ({
    delivery_start,
    delivery_end,
    ingredient_id,
    order_statuses,
  }: IngredientReportPayload) => {
    try {
      await requestKitchenReport({
        delivery_start,
        delivery_end,
        ingredient_id,
        order_statuses,
      });
      notification.success(getSuccessMessage('ingredient'));
    } catch (err) {
      notification.error(getErrorMessage(err));
    }
  };

  return (
    <div style={{ margin: '24px 0' }}>
      <FormManager
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
            <div className="d-flex" style={{ gap: '1rem' }}>
              {/* @ts-ignore */}
              <DatePickerField
                name="delivery_start"
                label="Delivery Start At"
                outputFormat={ISO_DATE_FORMAT}
                required
              />

              {/* @ts-ignore */}
              <DatePickerField
                name="delivery_end"
                label="Delivery End At"
                outputFormat={ISO_DATE_FORMAT}
                clearable
              />
            </div>
            <Row>
              <Col>
                {/* @ts-ignore */}
                <IngredientSelectorField
                  name="ingredient"
                  label="Ingredient"
                  isClearable
                  required
                  onChange={(ingredient) => setFieldValue('ingredient_id', ingredient?.id || '')}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* @ts-ignore */}
                <OrderStatusAutoCompleteField
                  name="order_statuses"
                  label="Order Status"
                  isMulti
                  required
                />
              </Col>
            </Row>
            {/* @ts-ignore */}
            <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
              Send Report
            </Button>
          </Form>
        )}
      </FormManager>
    </div>
  );
};

export default IngredientReportForm;
