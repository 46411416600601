import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';

import { Button } from 'components/Common';

import './MediaGalleryOffline.css';
import { Asset } from 'lib/media/typedef';

const MediaGalleryOffline = React.memo(
  ({
    assets,
    onAssetsChange,
    isAssetSelectable,
    onAssetSelect,
    itemSize,
    children,
  }) => {
    const [removing, setRemoving] = React.useState([]);

    const isAssetBeingRemoved = (assetIndex) =>
      removing.includes(assetIndex);

    const handleRemoveImage = (imageIndex) => {
      setRemoving((removing) => removing.concat(imageIndex));

      onAssetsChange((assets) =>
        assets.filter((asset, index) => index !== imageIndex),
      );

      setRemoving((removing) =>
        removing.filter(
          (removingAssetId) => removingAssetId !== imageIndex,
        ),
      );
    };

    const ImageWrapper = isAssetSelectable
      ? ({ children, asset }) => (
          <Button
            className="selectable-asset"
            onClick={() => onAssetSelect(asset)}
          >
            {children}
          </Button>
        )
      : ({ children }) => children;
    return (
      <Container fluid>
        <Row>
          {assets.map((asset, index) => {
            const assetBeingRemoved = isAssetBeingRemoved(index);
            return (
              <Col xs="auto" key={asset.id}>
                <div className={'asset-container'}>
                  <ImageWrapper asset={asset}>
                    <img
                      src={asset.url}
                      width={itemSize}
                      height={itemSize}
                      alt={asset.id}
                    />
                  </ImageWrapper>
                  <button
                    type="button"
                    className={classNames('remove-image-btn', {
                      disabled: assetBeingRemoved,
                    })}
                    disabled={assetBeingRemoved}
                    onClick={() => handleRemoveImage(index)}
                  >
                    {assetBeingRemoved ? (
                      <i className="bx bx-loader-alt"></i>
                    ) : (
                      <i className="bx bx-x"></i>
                    )}
                  </button>
                </div>
              </Col>
            );
          })}

          {children}
        </Row>
      </Container>
    );
  },
);

MediaGalleryOffline.propTypes = {
  assets: PropTypes.arrayOf(Asset),
  onAssetsChange: PropTypes.func,
  isAssetSelectable: PropTypes.bool,
  onAssetSelect: PropTypes.func,
  itemSize: PropTypes.string,
};

MediaGalleryOffline.defaultProps = {
  assets: [],
  onAssetsChange: () => {},
  isAssetSelectable: false,
  onAssetSelect: () => {},
  itemSize: '150px',
};

export default MediaGalleryOffline;
