import React from 'react';
import PropTypes from 'prop-types';

import { formatCouponCode } from '../utils';

const Coupon = ({ couponCode, rightRender }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '4px 8px',
        borderRadius: '4px',
        backgroundColor: 'white',
        border: '1px solid rgba(0, 0, 0, 0.19)',
      }}
    >
      <code>{formatCouponCode(couponCode)}</code>
      <div>{rightRender}</div>
    </div>
  );
};

Coupon.propTypes = {
  couponCode: PropTypes.string.isRequired,
  rightRender: PropTypes.node,
};

Coupon.defaultProps = {
  rightRender: null,
};

export default Coupon;
