import React from 'react';
import { Button, Page, Skeleton } from 'components/Common';
import { Col, Row, Card, CardBody } from 'reactstrap';
import { DonationForm, useDonation } from 'lib/donations';
import { useHistory, useParams } from 'react-router';
import notification from 'lib/notification';
import { useConfirmation } from 'lib/confirm';
import { routePaths } from 'appConfig';

const DonationDetailsPage = () => {
  const { alert, confirm } = useConfirmation();
  const history = useHistory();
  const { donationId } = useParams();
  const { donation, isLoading, update, _delete } = useDonation(
    donationId,
  );

  const [isDeleting, setIsDeleting] = React.useState(false);

  const onUpdate = async (changes, actions) => {
    try {
      await update(changes);
      notification.success('Successfully updated donation');
    } catch (error) {
      actions.setErrors(error.errors);
      actions.setStatus({
        error: error.errors?.message || 'Error updating donation',
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    await confirm();

    try {
      setIsDeleting(true);
      await _delete();
      notification.success('Successfully deleted donation event');
      history.replace(routePaths.DONATIONS);
    } catch (err) {
      await alert({
        title: 'Cannot Delete Category',
        content: err?.errors?.message,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Page
      content={
        isLoading ? (
          <Skeleton type="page" />
        ) : (
          <React.Fragment>
            <Row>
              <Col>
              <div className="d-flex justify-content-end align-item-center pb-3">
                <Button
                  color={'danger'}
                  onClick={handleDelete}
                  loading={isDeleting}
                  disabled={isDeleting}
                >
                  Delete Donation Event
                </Button>
              </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <DonationForm
                      onSubmit={onUpdate}
                      initialValues={donation}
                      buttonTitle='Update'
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        )
      }
    />
  );
};

export default DonationDetailsPage;
