import { useCallback } from 'react';
import {
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import qs from 'qs';

import { merge } from 'lib/javascript';

import useQuery from './useQuery';
import { pathToTo } from './utils';

type UrlParams = Record<string, string | number>;
type QueryParams = Record<string, string | null>;

interface RouterOptions {
  pathname: string;
  urlParams?: UrlParams;
  queryParams?: QueryParams;
}

interface Router {
  query: QueryParams;
  push: (options: RouterOptions) => void;
  setQueryParams: (params: QueryParams) => void;
  updateQueryParams: (params: QueryParams) => void;
  match: ReturnType<typeof useRouteMatch>
}

const useRouter = (): Router => {
  const queryParams = useQuery();
  const urlParams = useParams<Record<string, string>>();
  const history = useHistory();
  const match = useRouteMatch();

  const query = merge({}, queryParams, urlParams);

  const push = useCallback(
    ({ pathname: pathnameArg, urlParams, queryParams }: RouterOptions) => {
      const search = qs.stringify(queryParams, {
        addQueryPrefix: true,
      });

      const pathname = pathToTo(pathnameArg, {
        URLParams: urlParams,
      });

      history.push({
        pathname,
        search,
      });
    },
    [history],
  );

  const setQueryParams = useCallback(
    (queryParams: QueryParams) => {
      push({ pathname: match.url, queryParams });
    },
    [push, match.url],
  );

  const updateQueryParams = useCallback(
    (newPartialQueryParams: QueryParams) => {
      const newQueryParams = merge(
        {},
        queryParams,
        newPartialQueryParams,
      );

      setQueryParams(newQueryParams);
    },
    [queryParams, setQueryParams],
  );

  return {
    query,
    push,
    match,
    setQueryParams,
    updateQueryParams,
  };
};

export default useRouter;
