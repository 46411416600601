import React from 'react';
import PropTypes from 'prop-types';
import { QuantityInput } from 'components/Common';
import { ProductVariant } from './typedef';

const ProductVariantQuantityInput = ({
  name,
  label,
  required,
  error,
  productVariant,
  value,
  onChange,
  disabled,
}) => {
  if (!productVariant) return null;

  const { stock } = productVariant;

  const isOutOfStock = stock <= 0;

  return (
    <QuantityInput
      name={name}
      label={label}
      required={required}
      error={error}
      defaultValue="1"
      value={value}
      onChange={onChange}
      step={1}
      min={1}
      max={stock}
      disabled={isOutOfStock || disabled}
    />
  );
};

ProductVariantQuantityInput.propTypes = {
  productVariant: ProductVariant.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ProductVariantQuantityInput;
