import { replace, toUpper } from 'lib/javascript';
import {
  LIMIT_TYPE,
  LABEL_BY_LIMIT_TYPE,
  COUPON_TYPE,
  LABEL_BY_COUPON_TYPE,
} from './constants';

export const getLabelByIsPercent = (isPercent) => {
  if (isPercent) return 'Percentage';
  return 'Fixed';
};

export const getLabelByLimitType = (limitType) =>
  LABEL_BY_LIMIT_TYPE[limitType] || 'Unknown';

export const formatCouponCode = (rawCouponCode) => {
  const kebabCased = replace(rawCouponCode, ' ', '-');

  const upperCasedAndKebabCased = toUpper(kebabCased);

  return upperCasedAndKebabCased;
};

export const isUsageLimitInfinity = (
  coupon,
  limitType = LIMIT_TYPE.GLOBAL_LIMIT,
) => coupon.usage_limit[limitType].is_infinity;

export const getUsageLimit = (
  coupon,
  limitType = LIMIT_TYPE.GLOBAL_LIMIT,
) => coupon.usage_limit[limitType].limit;

export const getCouponType = (coupon) => {
  const { description } = coupon;

  const isV1RewardCoupon = /Referral coupon for: .* from order .*/.test(
    description,
  );
  const isV2RewardCoupon = description === 'referral-reward';
  const isRewardCoupon = isV1RewardCoupon || isV2RewardCoupon;
  if (isRewardCoupon) return COUPON_TYPE.REFERRAL_REWARD;

  const isReferralCoupon = description === 'referral';
  if (isReferralCoupon) return COUPON_TYPE.REFERRAL;

  return COUPON_TYPE.CAMPAIGN;
};

export const getLabelOfCouponType = (couponType) =>
  LABEL_BY_COUPON_TYPE[couponType] || 'Unknown';
