import { AxiosError } from 'axios';
import { useGet } from 'lib/swr-crud';
import { APIErrorResponse } from 'types/api';
import {
  deleteComplaint,
  deleteCorrectiveAction,
  deleteResolution,
  getComplaint,
  unverifyComplaint,
  updateComplaint,
  updateCorrectiveAction,
  updateResolution,
  verifyComplaint,
} from './api';
import {
  ComplaintCorrectiveActionPayload,
  ComplaintPayload,
  ComplaintResolutionPayload,
  ComplaintResponse,
} from './types';
import { useState } from 'react';

const useComplaint = (complaintId: string | null) => {
  const key =
    complaintId !== null ? `/cms/complaints/${complaintId}` : null;

  const { data, isLoading, mutate } = useGet<
    ComplaintResponse,
    AxiosError<APIErrorResponse>
  >(key, async () => await getComplaint(complaintId!));

  const isRealLoading = isLoading && key !== null;

  const [isUpdating, setUpdating] = useState(false);

  const _updateComplaint = async (payload: ComplaintPayload) => {
    try {
      const result = await updateComplaint(complaintId!, payload);
      return result;
    } catch (error) {
      await mutate(data);
      throw error;
    }
  };

  const _updateResolution = async (
    payload: ComplaintResolutionPayload,
  ) => {
    try {
      const result = await updateResolution(complaintId!, payload);
      return result;
    } catch (error) {
      await mutate(data);
      throw error;
    }
  };

  const _updateCorrectiveAction = async (
    payload: ComplaintCorrectiveActionPayload,
  ) => {
    try {
      const result = await updateCorrectiveAction(
        complaintId!,
        payload,
      );
      return result;
    } catch (error) {
      await mutate(data);
      throw error;
    }
  };

  const _verifyComplaint = async () => {
    try {
      setUpdating(true);
      const result = await verifyComplaint(complaintId!);
      setUpdating(false);
      return result;
    } catch (error) {
      await mutate(data);
      setUpdating(false);
      throw error;
    }
  };

  const _unverifyComplaint = async () => {
    try {
      setUpdating(true);
      const result = await unverifyComplaint(complaintId!);
      setUpdating(false);
      return result;
    } catch (error) {
      await mutate(data);
      setUpdating(false);
      throw error;
    }
  };

  const _delete = async () => {
    try {
      setUpdating(true);
      await deleteComplaint(complaintId!);
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      throw error;
    }
  };

  const _deleteResolution = async () => {
    try {
      setUpdating(true);
      await deleteResolution(complaintId!);
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      throw error;
    }
  };

  const _deleteCorrectiveAction = async () => {
    try {
      setUpdating(true);
      await deleteCorrectiveAction(complaintId!);
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      throw error;
    }
  };

  return {
    complaint: data?.data ?? null,
    isUpdating,
    mutate,
    isLoading: isRealLoading,
    updateComplaint: _updateComplaint,
    updateResolution: _updateResolution,
    updateCorrectiveAction: _updateCorrectiveAction,
    verifyComplaint: _verifyComplaint,
    unverifyComplaint: _unverifyComplaint,
    deleteComplaint: _delete,
    deleteResolution: _deleteResolution,
    deleteCorrectiveAction: _deleteCorrectiveAction,
  };
};

export default useComplaint;
