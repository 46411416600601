import { useCRUD } from 'lib/swr-crud';
import { isEmpty } from 'lib/javascript';
import { getUserReferralCode } from './api';

const useUserReferralCode = (userId) => {
  const { data, error, isLoading, mutate } = useCRUD(
    userId ? `/cms/users/${userId}/coupon_referral` : null,
    async () => {
      const response = await getUserReferralCode(userId);
      if (isEmpty(response)) throw new Error('No Referral Coupon');

      return response.coupon_code;
    },
  );

  const referralCode = data || null;

  return {
    referralCode,
    error,
    isLoading,
    mutate,
  };
};

export default useUserReferralCode;
