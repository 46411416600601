import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardTitle, CardBody } from 'reactstrap';
import { Row, Col } from 'react-bootstrap';

import { merge, isEmpty } from 'lib/javascript';
import { FormManager, Form, connectInput } from 'lib/form';
import datetime from 'lib/datetime';
import { ProductAutoComplete } from 'lib/product';
import { CategoryAutoComplete } from 'lib/productCategory';
import validator from 'lib/validator';
import { UserSelector } from 'lib/users';
import { DatePicker, ReadOnly } from 'components/Common';
import { TextField, CheckboxField } from 'components/formFields';

import { DISCOUNT_TARGET, LOGIC_OPERATOR } from './constants';
import { getLabelByIsPercent } from './utils';
import LogicOperatorSelect from './LogicOperatorSelect';

import CouponCodeInput from './CouponCodeInput';
import CouponType from './CouponType';
import { CouponPropType } from './typedef';

const DatePickerField = connectInput(DatePicker, {
  onChangeArgType: 'raw-value',
});
const ProductAutoCompleteField = connectInput(ProductAutoComplete, {
  onChangeArgType: 'raw-value',
});
const CategoryAutoCompleteField = connectInput(CategoryAutoComplete, {
  onChangeArgType: 'raw-value',
});
const LogicOperatorSelectField = connectInput(LogicOperatorSelect, {
  onChangeArgType: 'event',
});
const CouponCodeField = connectInput(CouponCodeInput, {
  onChangeArgType: 'raw-value',
});
const UserSelectorField = connectInput(UserSelector, {
  onChangeArgType: 'raw-value',
});

const getDefaultValues = () => {
  const defaultValues = {
    coupon_code: '',
    is_percent: true,
    discount_value: 0,
    is_free_shipping: false,
    usage_limit: {
      global: null,
      per_user: null,
    },
    is_first_order_only: false,
    custom_error_message: null,
    start_at: datetime().startOf('day').toISOString(),
    end_at: null,
    description: '',
    allowed_user_ids: [],
    min_purchase_amount: 0,
    max_purchase_amount: null,
    product: {
      operator: LOGIC_OPERATOR.AND,
      included_ids: [],
      excluded_ids: [],
    },
    category: {
      operator: LOGIC_OPERATOR.AND,
      included_ids: [],
      excluded_ids: [],
    },
    is_valid_with_excluded_item: false,
    // hidden fields
    discount_target: DISCOUNT_TARGET.CART,
    min_purchase_quantity: null,
    bundle_quantity: null,
    is_bundle_unique: false,
    is_active: true,
  };

  return defaultValues;
};

const validationSchema = validator.object().shape({
  coupon_code: validator.string().required(),
  is_percent: validator.bool(),
  discount_value: validator.number().decimalNumber().required(),
  is_free_shipping: validator.bool(),
  usage_limit: validator.object({
    global: validator.number().nullable(),
    per_user: validator.number().nullable(),
  }),
  is_first_order_only: validator.bool(),
  custom_error_message: validator.string().nullable(),
  start_at: validator.string().required(),
  end_at: validator.string().nullable(),
  description: validator.string().nullable(),
  allowed_user_ids: validator
    .array()
    .of(validator.object())
    .required(),
  min_purchase_amount: validator.number().nullable(),
  max_purchase_amount: validator.number().nullable(),
  product: validator.object().shape({
    operator: validator.string(),
    included_ids: validator.array().of(validator.string()),
    excluded_ids: validator.array().of(validator.string()),
  }),
  category: validator.object().shape({
    operator: validator.string(),
    included_ids: validator.array().of(validator.string()),
    excluded_ids: validator.array().of(validator.string()),
  }),
  is_valid_with_excluded_item: validator.bool(),
  discount_target: validator.string().required(),
  min_purchase_quantity: validator.number().nullable(),
  bundle_quantity: validator.number().nullable(),
  is_bundle_unique: validator.bool(),
  is_active: validator.bool(),
});

const mapCouponToForm = (coupon) => {
  const { usage_limit, rules, ...others } = coupon;

  return {
    ...others,
    is_free_shipping: rules.is_free_shipping,
    usage_limit: {
      global: usage_limit.global.limit,
      per_user: usage_limit.per_user.limit,
    },
    is_first_order_only: rules.is_first_order_only,
    allowed_user_ids: rules.user.allowed,
    is_bundle_unique: rules.is_bundle_unique,
    min_purchase_amount: rules.min_purchase_amount,
    max_purchase_amount: rules.max_purchase_amount,
    product: {
      operator: rules.product.operator,
      included_ids: rules.product.included.map(
        (product) => product.id,
      ),
      excluded_ids: rules.product.excluded.map(
        (product) => product.id,
      ),
    },
    category: {
      operator: rules.category.operator,
      included_ids: rules.category.included.map(
        (category) => category.id,
      ),
      excluded_ids: rules.category.excluded.map(
        (category) => category.id,
      ),
    },
    is_valid_with_excluded_item: rules.is_valid_with_excluded_item,
    min_purchase_quantity: rules.min_purchase_quantity,
    bundle_quantity: rules.bundle_quantity,
  };
};

const CouponForm = ({ id, initialValues, onSubmit }) => {
  const _initialValues = merge(
    {},
    getDefaultValues(),
    !isEmpty(initialValues) ? mapCouponToForm(initialValues) : {},
  );

  const handleOnSubmit = (formValues, actions) => {
    const { allowed_user_ids, ...otherFormValues } = formValues;

    const formattedFormValues = {
      ...otherFormValues,
      allowed_user_ids: allowed_user_ids.map((user) => user.user_id),
    };

    return onSubmit(formattedFormValues, actions);
  };

  const renderInlineInput = (input, template) => {
    const inlineInput = (
      <div
        style={{
          padding: '0 12px 0 12px',
          marginBottom: '-16px',
        }}
      >
        {input}
      </div>
    );

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {template(inlineInput)}
      </div>
    );
  };

  return (
    <FormManager
      initialValues={_initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ values }) => (
        <Form id={id}>
          <Card>
            <CardBody>
              <CardTitle>General</CardTitle>
              <Row>
                <Col>
                  <CouponCodeField
                    name="coupon_code"
                    label="Coupon Code"
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <ReadOnly
                    name="coupon-type"
                    label="Coupon Type"
                    render={() => (
                      <CouponType coupon={initialValues} />
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <CheckboxField
                    name="is_percent"
                    label="Discount Type is Percentage"
                  />
                </Col>
                <Col>
                  <TextField
                    name="discount_value"
                    label={`Discount Amount (${getLabelByIsPercent(
                      values['is_percent'],
                    )})`}
                    type="number"
                    step="0.01"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxField
                    name="is_free_shipping"
                    label="Allow Free Shipping"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    name="usage_limit.global"
                    label="Maximum Total Usage"
                    type="number"
                    helperText="leave empty for infinity"
                  />
                </Col>
                <Col>
                  <TextField
                    name="usage_limit.per_user"
                    label="Max Usage Limit per Customer"
                    type="number"
                    helperText="leave empty for infinity"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxField
                    name="is_first_order_only"
                    label="First Purchase Only"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    name="custom_error_message"
                    label="Custom Error Message"
                    type="textarea"
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <CardTitle>Validation Rules</CardTitle>
              <Row>
                <Col>
                  <DatePickerField
                    name="start_at"
                    label="Valid From"
                    outputFormat={(output) =>
                      datetime(output).startOf('day').toISOString()
                    }
                    required
                    disabledDays={(day) =>
                      values['end_at'] &&
                      datetime(day).isAfter(
                        datetime(values['end_at']),
                        'day',
                      )
                    }
                  />
                </Col>
                <Col>
                  <DatePickerField
                    name="end_at"
                    label="Valid Till"
                    outputFormat={(output) =>
                      datetime(output).endOf('day').toISOString()
                    }
                    disabledDays={(day) =>
                      datetime(day).isBefore(
                        datetime(values['start_at']),
                        'day',
                      )
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    name="min_purchase_amount"
                    label="Minimum Order Subtotal ($)"
                    type="number"
                  />
                </Col>
                <Col>
                  <TextField
                    name="max_purchase_amount"
                    label="Maximum Order Subtotal ($)"
                    type="number"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <UserSelectorField
                    name="allowed_user_ids"
                    label="Allow User(s)"
                    isMulti
                    placeholder="All Users"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProductAutoCompleteField
                    name="product.included_ids"
                    label={renderInlineInput(
                      <LogicOperatorSelectField name="product.operator" />,
                      (inlineInput) => (
                        <React.Fragment>
                          Contains
                          {inlineInput}
                          of these Products
                        </React.Fragment>
                      ),
                    )}
                    isMulti
                  />
                </Col>
                <Col>
                  <CategoryAutoCompleteField
                    name="category.included_ids"
                    label={renderInlineInput(
                      <LogicOperatorSelectField name="category.operator" />,
                      (inlineInput) => (
                        <React.Fragment>
                          Contains
                          {inlineInput}
                          of these Categories
                        </React.Fragment>
                      ),
                    )}
                    isMulti
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <ProductAutoCompleteField
                    name="product.excluded_ids"
                    label="Does Not Contains These Products"
                    isMulti
                  />
                </Col>
                <Col xs={12} md={6}>
                  <CategoryAutoCompleteField
                    name="category.excluded_ids"
                    label="Does Not Contains These Product Categories"
                    isMulti
                  />
                </Col>
                <Col xs={12} md={6}>
                  <CheckboxField
                    name="is_valid_with_excluded_item"
                    label="Allow discount with excluded items"
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    </FormManager>
  );
};

CouponForm.propTypes = {
  id: PropTypes.string.isRequired,
  initialValues: CouponPropType,
  onSubmit: PropTypes.func,
};

CouponForm.defaultProps = {
  initialValues: {},
  onSubmit: () => {},
};

export default CouponForm;
