// @ts-nocheck
import { routePaths } from 'appConfig';
import { Button, Page } from 'components/Common';
import { CollectionPointsTable, useCollectionPoints } from 'lib/collection-points';
import { useRouter } from 'lib/router';
import { useHistory } from 'react-router';
import { Card, CardBody } from 'reactstrap';

const CollectionPointsPage = () => {
  const history = useHistory();
  const { query, updateQueryParams } = useRouter();

  const {
    page = 1,
    sizePerPage = 15,
    sortField = 'name',
    sortDir = 'desc',
  } = query;

  const { collectionPoints, total, isLoading } = useCollectionPoints({
    page,
    sizePerPage,
    sortField,
    sortDir,
  });

  const handleOnTableChange = (action: string, newState: any) => {
    switch (action) {
      case 'pagination':
        const { page, sizePerPage } = newState;
        updateQueryParams({
          page,
          sizePerPage,
        });
        break;
      case 'sort':
        const { sortField, sortOrder } = newState;
        updateQueryParams({
          sortField,
          sortDir: sortOrder,
        });
        break;

      default:
        break;
    }
  };

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <div className="d-flex justify-content-end mb-2">
              <Button
                onClick={() => {
                  history.push(`${routePaths.NEW_COLLECTION_POINT}`);
                }}
              >
                Create New Collection Point
              </Button>
            </div>
            <CollectionPointsTable
              data={collectionPoints}
              isLoading={isLoading}
              page={parseInt(page)}
              sizePerPage={parseInt(sizePerPage)}
              sortField={sortField}
              sortDir={sortDir}
              totalSize={total}
              onTableChange={handleOnTableChange}
            />
          </CardBody>
        </Card>
      }
    />
  );
};

export default CollectionPointsPage;
