import { useCRUD } from 'lib/swr-crud';
import { getProductVariant } from './api';

const useProductVariant = (variantId) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/cms/products/variants/${variantId}`,
    async () => {
      const { response, error } = await getProductVariant(variantId);

      if (error) throw error;

      return response.data;
    },
  );

  const productVariant = data || null;

  return {
    productVariant,
    error,
    mutate,
    isValidating,
    isLoading,
  };
};

export default useProductVariant;
