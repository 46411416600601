import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';

import notification from 'lib/notification';
import { Button } from 'components/Common';

import * as api from '../api';
import { Asset } from '../typedef';
import './MediaGallery.css';

const MediaGallery = ({
  assets,
  onAssetsChange,
  isAssetSelectable,
  onAssetSelect,
  itemSize,
  children,
}) => {
  const [removing, setRemoving] = React.useState([]);

  const isAssetBeingRemoved = (assetId) => removing.includes(assetId);

  const handleRemoveImage = async (imageId) => {
    try {
      setRemoving((removing) => removing.concat(imageId));

      const { error } = await api.remove({ imageId });

      if (error) throw error;

      notification.success('Successfully removed image');
      onAssetsChange((assets) =>
        assets.filter((asset) => asset.id !== imageId),
      );
    } catch (err) {
      notification.error(
        'Oops, something went wrong when removing image',
      );
    } finally {
      setRemoving((removing) =>
        removing.filter(
          (removingAssetId) => removingAssetId !== imageId,
        ),
      );
    }
  };

  const ImageWrapper = isAssetSelectable
    ? ({ children, asset }) => (
        <Button
          className="selectable-asset"
          onClick={() => onAssetSelect(asset)}
        >
          {children}
        </Button>
      )
    : ({ children }) => children;

  return (
    <Container fluid>
      <Row>
        {assets.map((asset) => {
          const assetBeingRemoved = isAssetBeingRemoved(asset.id);

          return (
            <Col xs="auto">
              <div className={'asset-container'}>
                <ImageWrapper asset={asset}>
                  <img
                    key={asset.id}
                    src={asset.url}
                    width={itemSize}
                    height={itemSize}
                    alt={asset.id}
                  />
                </ImageWrapper>
                <button
                  type="button"
                  className={classNames('remove-image-btn', {
                    disabled: assetBeingRemoved,
                  })}
                  disabled={assetBeingRemoved}
                  onClick={() => handleRemoveImage(asset.id)}
                >
                  {assetBeingRemoved ? (
                    <i className="bx bx-loader-alt"></i>
                  ) : (
                    <i className="bx bx-x"></i>
                  )}
                </button>
              </div>
            </Col>
          );
        })}

        {children}
      </Row>
    </Container>
  );
};

MediaGallery.propTypes = {
  assets: PropTypes.arrayOf(Asset),
  onAssetsChange: PropTypes.func,
  isAssetSelectable: PropTypes.bool,
  onAssetSelect: PropTypes.func,
  itemSize: PropTypes.string,
};

MediaGallery.defaultProps = {
  assets: [],
  onAssetsChange: () => {},
  isAssetSelectable: false,
  onAssetSelect: () => {},
  itemSize: '150px',
};

export default MediaGallery;
