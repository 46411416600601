import { useCRUD } from 'lib/swr-crud';
import { deleteUser, getUserById, updateUserById } from './api';

const useUser = (userId) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/users/${userId}`,
    async () => {
      return getUserById(userId);
    },
  );
  const user = data || null;

  const update = async (body) => {
    const data = await updateUserById(userId, body);

    mutate(data);
    return data;
  };

  const remove = async () => {
    try {
      await deleteUser(userId);

      mutate(null);
    } catch (err) {
      throw err;
    }
  };

  return {
    user,
    error,
    update,
    mutate,
    remove,
    isValidating,
    isLoading,
  };
};

export default useUser;
