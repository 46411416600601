import { PublishButton } from 'lib/publish';
import { Catalogue } from './types';
import { publishCatalogue, unpublishCatalogue } from './api';
import notification from 'lib/notification';

interface PublishCatalogueButtonProps {
  catalogue: Catalogue;
  mutateCatalogue: (
    catalogueId: number | string,
    newCatalogue: Catalogue
  ) => void;
}

const PublishCatalogueButton = ({
  catalogue,
  mutateCatalogue,
}: PublishCatalogueButtonProps) => {
  const isPublished = Boolean(catalogue.published_at);

  return (
    <PublishButton
      isPublished={isPublished}
      publishRequest={() => publishCatalogue(catalogue.id ?? 0)}
      unPublishRequest={() => unpublishCatalogue(catalogue.id ?? 0)}
      onPublish={(_: unknown, result) => {
        const { response, error } = result;

        if (error) return;

        const newItem: Catalogue = response.data;
        mutateCatalogue(catalogue.id ?? 0, newItem);
        notification.success(`${catalogue.title} ${isPublished ? 'Unpublished' : 'Published'}`);
      }}
    />
  );
};

export default PublishCatalogueButton
