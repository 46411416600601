import { AxiosError } from 'axios';
import { useGet } from 'lib/swr-crud';
import { APIErrorResponse } from 'types/api';
import {
  getComplaintCategorySummaries,
  getComplaintCategorySummaryUrl,
} from './api';
import {
  ComplaintCategorySummariesQueryParams,
  ComplaintCategorySummariesResponse,
} from './types';

interface UseComplaintCategorySummariesOptions {
  params?: ComplaintCategorySummariesQueryParams;
}

const useComplaintCategorySummaries = ({
  params,
}: UseComplaintCategorySummariesOptions) => {
  const { data, isLoading, mutate } = useGet<
    ComplaintCategorySummariesResponse,
    AxiosError<APIErrorResponse>
  >(
    getComplaintCategorySummaryUrl(params),
    async () => await getComplaintCategorySummaries(params),
  );

  return {
    complaintCategorySummaries: data?.data ?? [],
    isLoading,
    mutate,
  };
};

export default useComplaintCategorySummaries;
