import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { Page, SimpleTable } from 'components/Common';
import { Link, useRouter } from 'lib/router';
import { mapValues } from 'lib/javascript';
import {
	ActivateDonationButton,
	activateEventDonation,
	deactivateEventDonation,
	useDonations
} from 'lib/donations';
import Table, { textFilter } from 'core/components/Table';
import notification from 'lib/notification';
import { routePaths } from 'appConfig';

const DonationsPage = () => {

	const { query, updateQueryParams } = useRouter();

	const {
    page = 1,
    sizePerPage = 15,
    sortField = 'organization_name',
    sortDir = 'asc',
    organizationName = '',
    headline = '',
		shortDescription = '',
		description = '',
  } = query;

	const {
    isLoading: isFetchingInactiveEventDonations,
    donations: inactiveEventDonations,
    total,
    mutate: mutateInactiveEventDonations
  } = useDonations({
		page,
		sizePerPage,
		sortField,
		sortDir,
		organizationName,
		headline,
		shortDescription,
		description
	});

  const {
    donations: activeEventDonations,
    mutate: mutateActiveEventDonations
  } = useDonations({}, true);

  const hasActiveEventDonations = activeEventDonations.length > 0;

	const handleOnTableChange = (action, newState) => {
		switch (action) {
			case 'pagination':
				updateQueryParams({
					page: newState.page,
					sizePerPage: newState.sizePerPage,
				});

				break;

			case 'filter':
				const mappedFilters = mapValues(
					newState.filters,
					(filter) => filter.filterVal,
				);

				const organizationName = mappedFilters['organization_name'] || '';
				const headline = mappedFilters['headline'] || '';
				const shortDescription = mappedFilters['short_description'] || '';
				const description = mappedFilters['description'] || '';

				updateQueryParams({
					page: 1,
					sizePerPage: newState.sizePerPage,
					organizationName,
					headline,
					shortDescription,
					description,
				});

				break;

			case 'sort':
				const { sortField, sortOrder } = newState;

				updateQueryParams({
          sortField,
          sortDir: sortOrder,
        });

				break;

			default:
		};
	};

	const onActivate = (result) => {
		const { error } = result;
		if (error) {
			notification.error(error?.errors?.message);
		} else {
			mutateInactiveEventDonations();
      mutateActiveEventDonations();
		}
	};

	const columns = [
		{
			dataField: 'organization_name',
			text: 'Organization Name',
			filter: textFilter({ delay: 1500, defaultValue: organizationName }),
      formatter: (_, donation) => (
        <Link to={`${routePaths.DONATIONS}/${donation.id}`}>
          {donation.organization_name}
        </Link>
      )
		},
		{
			dataField: 'headline',
			text: 'Headline',
			filter: textFilter({ delay: 1500, defaultValue: headline }),
		},
		{
			dataField: 'short_description',
			text: 'Short Description',
			filter: textFilter({ delay: 1500, defaultValue: shortDescription }),
		},
		{
			dataField: '',
			text: 'Actions',
			formatter: (_, donation) => {
				const isActive = Boolean(donation.is_active);
				return (
					<ActivateDonationButton
						isActive={isActive}
						activateRequest={() => activateEventDonation(donation.id)}
						deactivateRequest={() => deactivateEventDonation(donation.id)}
						donation={donation}
						onActivate={(_, result) => onActivate(result)}
					/>
				);
			},
		},
	];

	return (
		<Page
			content={
				<React.Fragment>
					{
						hasActiveEventDonations
							? (
								<Card>
									<CardBody>
										<SimpleTable
											data={activeEventDonations}
											columns={columns}
											keyField='id'
											classes='d-table'
											noDataIndication={() => (
												<tr>
													<td>
														<p className="pl-2 pt-1 pb-1 mb-0"> No Active Event Donation </p>
													</td>
												</tr>
											)}
										/>
									</CardBody>
								</Card>
							) : null
					}
					<Card>
						<CardBody>
							<Table
								remote
								loading={isFetchingInactiveEventDonations}
								keyField="id"
								columns={columns}
								data={inactiveEventDonations}
								pagination={{ page, sizePerPage, total }}
								sort={{ dataField: sortField, order: sortDir }}
								onTableChange={handleOnTableChange}
							/>
						</CardBody>
					</Card>
				</React.Fragment>
			}
		/>
	);
};

export default DonationsPage;
