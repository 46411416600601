import config from 'appConfig';
import datetime from 'lib/datetime';

export const DAYS = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const AVAILABLE_DAYS = [
  DAYS.sunday,
  DAYS.monday,
  DAYS.wednesday,
  DAYS.thursday,
];

export const DAY_LABELS = {
  [DAYS.sunday]: 'Sunday',
  [DAYS.monday]: 'Monday',
  [DAYS.tuesday]: 'Tuesday',
  [DAYS.wednesday]: 'Wednesday',
  [DAYS.thursday]: 'Thursday',
  [DAYS.friday]: 'Friday',
  [DAYS.saturday]: 'Saturday',
};

export const HOUR_LABELS = {
  10: '10am',
  11: '11am',
  19: '7pm',
};

export const dayOptions = [
  {
    value: '',
    label: 'Select Day',
  },
  ...Object.values(AVAILABLE_DAYS).map((day) => ({
    value: day.toString(),
    label: DAY_LABELS[day],
  })),
];

export const defaultHourOptions = {
  value: '',
  label: 'Select Hour',
};

export const timeSlotOptions = {
  [DAYS.monday]: [
    defaultHourOptions,
    {
      value: '10',
      label: HOUR_LABELS[10],
    },
    {
      value: '11',
      label: HOUR_LABELS[11],
    },
    {
      value: '19',
      label: HOUR_LABELS[19],
    },
  ],
  [DAYS.thursday]: [
    defaultHourOptions,
    {
      value: '10',
      label: HOUR_LABELS[10],
    },
    {
      value: '11',
      label: HOUR_LABELS[11],
    },
    {
      value: '19',
      label: HOUR_LABELS[19],
    },
  ],
  [DAYS.wednesday]: [
    defaultHourOptions,
    {
      value: '19',
      label: HOUR_LABELS[19],
    },
  ],
  [DAYS.sunday]: [
    defaultHourOptions,
    {
      value: '19',
      label: HOUR_LABELS[19],
    },
  ],
};

// @ts-expect-error
export const defaultDateTime = datetime({
  year: 2000,
  month: 0,
  date: 2,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
}).tz(config.timezone, true);

export const DEFAULT_TIMEFRAME = 3 * 60;
