import React from 'react';
import PropTypes from 'prop-types';
import datetime from '../datetime';
import { formatDatetime } from '../utils';

const Datetime = ({ value }) => {
  if (!value) return '-';

  const parsedValue = datetime(value);
  const now = datetime();

  const daysUntilNow = now.diff(parsedValue, 'day', true); // positive means the value is in the past, negative means the value is in the future

  const isLessThan1DayAgo = daysUntilNow < 1 && daysUntilNow > 0;

  const rendered = isLessThan1DayAgo
    ? now.to(parsedValue) // relative time e.g. 3 hours ago
    : formatDatetime(parsedValue); // full datetime

  return <React.Fragment>{rendered}</React.Fragment>;
};

Datetime.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
};

export default Datetime;
