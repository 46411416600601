import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'components/Common';

import { OrderPropType } from '../typedef';
import { isCancellable } from '../utils';
import CancelOrderForm from '../CancelOrderForm';

const CancelOrderButton = ({ order, style, className }) => {
  const [showCancelDialog, setShowCancelDialog] = React.useState(
    false,
  );

  const openCancelDialog = () => setShowCancelDialog(true);

  const closeCancelDialog = () => setShowCancelDialog(false);

  if (!isCancellable(order)) return null;

  return (
    <React.Fragment>
      <Button
        className={className}
        style={style}
        onClick={openCancelDialog}
      >
        Cancel Order
      </Button>
      <Modal
        open={showCancelDialog}
        onClose={closeCancelDialog}
        title="Cancel Order"
        content={
          <React.Fragment>
            <p>This will be a full Cancel. </p>
            <CancelOrderForm
              orderId={order.id}
              onSubmit={(err) => {
                if (!err) closeCancelDialog();
              }}
            />
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

CancelOrderButton.propTypes = {
  order: OrderPropType,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default CancelOrderButton;
