import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { Skeleton } from 'components/Common';

import useSalesMetrics from './useSalesMetrics';
import MetricCard from './MetricCard';
import { nFormatter } from 'core/utils';

const NAME_TOTAL_PAYING_CUSTOMERS = 'Total Paying Customers';
const NAME_TIERED_CUSTOMERS = 'Tiered Customers';

const metrics = [
  {
    dataField: 'gross_sales',
    name: 'Gross Sales',
    isMonetary: true,
  },
  {
    dataField: 'net_sales',
    name: 'Net Sales',
    isMonetary: true,
  },
  {
    dataField: 'total_orders',
    name: 'Orders Placed',
  },
  {
    dataField: 'total_items',
    name: 'Items Purchased',
  },
  {
    dataField: 'refunded_amount',
    name: 'Refunded Amount',
    isMonetary: true,
  },
  {
    dataField: 'tax',
    name: 'Tax',
    isMonetary: true,
  },
  {
    dataField: 'shipping_fee',
    name: 'Shipping Fee Charged',
    isMonetary: true,
  },
  {
    dataField: 'total_discount',
    name: 'Discounts Offered',
    isMonetary: true,
  },
  {
    dataField: 'total_complaint',
    name: 'Total Feedback',
  },
  {
    dataField: 'tier',
    name: NAME_TIERED_CUSTOMERS,
  },
  {
    dataField: 'tier',
    name: NAME_TOTAL_PAYING_CUSTOMERS,
  },
];

const filterMetricValues = (dataField, name, salesMetrics) => {
  if (name === NAME_TIERED_CUSTOMERS) {
    const { aaa, aa, a } = salesMetrics?.tier || {};
    return (
      <div className="d-flex justify-content-between font-size-8">
        <div className="font-size-16">{aaa} AAA</div>
        <div className="font-size-16">{aa} AA</div>
        <div className="font-size-16">{a} A</div>
      </div>
    );
  }
  if (name === NAME_TOTAL_PAYING_CUSTOMERS) {
    const { aaa, aa, a, other } = salesMetrics?.tier || {};
    const totalCustomers = aaa + aa + a + other;
    return totalCustomers;
  }
  return salesMetrics?.[dataField];
};

const filterMetricRibbon = (dataField, name, salesMetrics) => {
  if (dataField === 'total_orders') {
    return `${nFormatter(salesMetrics?.new_customers)} New Customers`;
  }
  if (name === NAME_TIERED_CUSTOMERS) {
    const { aaa = 0, aa = 0, a = 0 } = salesMetrics?.tier || {};
    const totalCustomers = aaa + aa + a;
    return `Total ${nFormatter(totalCustomers)}`;
  }
};

const SalesMetrics = ({ startDate, endDate }) => {
  const { isLoading, salesMetrics } = useSalesMetrics({
    startDate,
    endDate,
  });

  if (isLoading) return <Skeleton />;

  return (
    <Row>
      {metrics.map(({ dataField, name, isMonetary }) => (
        <Col key={dataField} xs={12} sm={6} md={4} lg={3}>
          <MetricCard
            name={name}
            value={
              salesMetrics &&
              filterMetricValues(dataField, name, salesMetrics)
            }
            isMonetary={isMonetary}
            ribbonText={
              salesMetrics &&
              filterMetricRibbon(dataField, name, salesMetrics)
            }
          />
        </Col>
      ))}
    </Row>
  );
};

SalesMetrics.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default SalesMetrics;
