import React from 'react';
import PropTypes from 'prop-types';

import Table, { textFilter } from 'core/components/Table';
import { Link } from 'lib/router';
import { Datetime } from 'lib/datetime';

const AddonRulesTable = ({
  loading,
  data,
  page,
  sizePerPage,
  totalSize,
  onTableChange,
  getPathToDetailsPage,
  sortField,
  sortDir,
}) => {
  const columns = [
    {
      dataField: 'label',
      text: 'Label',
      formatter: (label, row) => {
        return <Link to={getPathToDetailsPage(row)}>{label}</Link>;
      },
      filter: textFilter({ delay: 1500 }),
    },
    {
      dataField: 'created_at',
      text: 'Created At',
      sort: true,
      type: 'date',
      formatter: (cell) => <Datetime value={cell} />,
    },
    {
      dataField: 'updated_at',
      text: 'Updated At',
      type: 'date',
      formatter: (cell) => <Datetime value={cell} />,
    },
  ];

  return (
    <Table
      remote
      loading={loading}
      keyField="id"
      columns={columns}
      data={data}
      pagination={{ page, sizePerPage, totalSize }}
      onTableChange={onTableChange}
      sort={{ dataField: sortField, order: sortDir }}
    />
  );
};

AddonRulesTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  sortField: PropTypes.string,
  sortDir: PropTypes.string,
};

AddonRulesTable.defaultProps = {
  loading: false,
};

export default AddonRulesTable;
