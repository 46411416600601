import React from 'react';
import PropTypes from 'prop-types';
import AutoComplete from '../AutoComplete';
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { components } from 'react-select';

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(
    to < 0 ? array.length + to : to,
    0,
    array.splice(from, 1)[0],
  );
  return array;
}

const SortableMultiValue = SortableElement((props) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = sortableHandle((props) => (
  <components.MultiValueLabel {...props} />
));

const SortableAutoCompleteBase = SortableContainer(AutoComplete);

const SortableAutoComplete = ({ value, onChange, ...otherProps }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(value, oldIndex, newIndex);
    onChange(newValue);
  };

  return (
    <SortableAutoCompleteBase
      useDragHandle
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      // react-select props:
      {...otherProps}
      value={value}
      onChange={onChange}
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
      }}
      closeMenuOnSelect={false}
    />
  );
};

SortableAutoComplete.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  disableOptionGroup: PropTypes.bool,
};

SortableAutoComplete.defaultProps = {
  name: null,
  label: null,
  error: false,
  required: false,
  helperText: null,
  isMulti: false,
  disabled: false,
  getOptionValue: (option) => option.value,
  getOptionLabel: (option) => option.label,
  formatOptionLabel: undefined,
  disableOptionGroup: true,
};

export default SortableAutoComplete;
