import React from 'react';

import { Page } from 'components/Common';
import { createIngredient } from 'lib/ingredients';
import IngredientForm from 'lib/ingredients/IngredientForm';
import notification from 'lib/notification';
import { routePaths } from 'appConfig';
import { useHistory } from 'react-router';

const NewIngredientPage = () => {
  const history = useHistory();

  const onCreate = async (values, actions) => {
    try {
      const data = await createIngredient(values);
      notification.success('Successfully create Ingredient');
      history.push(`${routePaths.INGREDIENTS}/${data.id}`);
    } catch (error) {
      actions.setErrors(error.errors);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Page
      content={
        <React.Fragment>
          <IngredientForm
            onSubmit={onCreate}
            initialValues={{}}
            buttonTitle="Add Ingredient"
          />
        </React.Fragment>
      }
    />
  );
};

export default NewIngredientPage;
