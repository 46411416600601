import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AsyncSelect } from 'components/Common';
import { getUsers } from './api';
import notification from 'lib/notification';

const UserSelector = ({
  name,
  value,
  onChange,
  label,
  required,
  error,
  helperText,
  isMulti,
  placeholder,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [userTotalCount, setUserTotalCount] = useState(100);

  const getTotalUserCount = async () => {
    try {
      setLoading(true);
      const data = await getUsers({});
      setUserTotalCount(data.meta.total);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const loadOptions = async (inputValue, callback) => {
    try {
      const { data } = await getUsers({
        per_page: userTotalCount,
        search: inputValue,
      });

      callback(data);
    } catch (err) {
      notification.error(err);
      callback([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTotalUserCount();
  }, []);

  return (
    <AsyncSelect
      isMulti={isMulti}
      isLoading={isLoading}
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      disabled={isLoading}
      loadOptions={loadOptions}
      getOptionLabel={(user) =>
        `${user?.first_name} ${user?.last_name} (${user?.email})`
      }
      getOptionValue={(user) => user?.user_id}
      placeholder={placeholder}
    />
  );
};

UserSelector.propTypes = {
  isMulti: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
};

export default UserSelector;
