import datetime, { getDaysBetween } from 'lib/datetime';
import {
  AVAILABLE_CUTOFF_DAYS,
  AVAILABLE_DELIVERY_DAYS,
  DELIVERY_TIMESLOTS_BY_DAY,
  CUTOFF_TIMESLOT_BY_DAY,
} from './constants';
import config from 'appConfig';

export const getDeliveryDates = ({ start, end }) => {
  let deliveryDates = [];

  AVAILABLE_DELIVERY_DAYS.forEach((deliveryDay) => {
    const days = getDaysBetween({
      start,
      end,
      day: deliveryDay,
    });

    deliveryDates = [...deliveryDates, ...days];
  });

  return deliveryDates;
};

export const getDeliveryTimesByDate = (date) => {
  if (!date) return [];

  const dayOfWeek = datetime(date).day();

  const deliveryDayObj = datetime(date).toObject();

  const timeslots = DELIVERY_TIMESLOTS_BY_DAY[dayOfWeek];

  return timeslots.map(({ hours, minutes }) => {
    const timeslotObj = {
      ...deliveryDayObj,
      hours,
      minutes,
      seconds: 0,
      milliseconds: 0,
    };

    return datetime().tz(config.timezone).set(timeslotObj);
  });
};

export const getCutOffDates = ({ start, end }) => {
  let cutOffDates = [];

  AVAILABLE_CUTOFF_DAYS.forEach((day) => {
    const days = getDaysBetween({
      start,
      end,
      day,
    });

    cutOffDates = [...cutOffDates, ...days];
  });

  return cutOffDates;
};

export const getCutOffTimesByDate = (date) => {
  const dayOfWeek = datetime(date).day();

  const cutOffDayObj = datetime(date).toObject();

  const timeslots = CUTOFF_TIMESLOT_BY_DAY[dayOfWeek];

  return timeslots.map(({ hours, minutes }) => {
    const timeslotObj = {
      ...cutOffDayObj,
      hours,
      minutes,
      seconds: 0,
      milliseconds: 0,
    };

    return datetime(timeslotObj);
  });
};
