import { useGet } from 'lib/swr-crud';
import {
  ComplaintCategory,
  ComplaintCategoryResponse,
} from './types';
import { AxiosError } from 'axios';
import { APIErrorResponse } from 'types/api';
import { getComplaintCategory, updateComplaintCategory } from './api';

const useComplaintCategory = (complaintCategoryId: string) => {
  const { data, isLoading, mutate } = useGet<
    ComplaintCategoryResponse,
    AxiosError<APIErrorResponse>
  >(
    `/complaint_categories/${complaintCategoryId}`,
    async () => await getComplaintCategory(complaintCategoryId),
  );

  const _updateComplaintCategory = async (
    payload: ComplaintCategory,
  ) => {
    try {
      return await updateComplaintCategory(
        complaintCategoryId,
        payload,
      );
    } catch (error) {
      await mutate(data);
      throw error;
    }
  };

  return {
    complaintCategory: data?.data ?? null,
    isLoading,
    updateComplaintCategory: _updateComplaintCategory,
  };
};

export default useComplaintCategory;
