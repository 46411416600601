import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import {
  RadioButton,
  RadioButtonGroup,
  DatePicker,
  Button,
} from 'components/Common';
import datetime from 'lib/datetime';
import { FormManager, Form, connectInput } from 'lib/form';
import { isEmpty, isEqual, isDate } from 'lib/javascript';
import validator from 'lib/validator';

import { getFirstDayAndLastDayInMonthOf } from './utils';

const MAX_RANGE_IN_DAYS = 31;

const DateField = connectInput(DatePicker, {
  onChangeArgType: 'raw-value',
});

const DATE_RANGE_QUERY_OPTION = {
  LAST_MONTH: 'last-month',
  THIS_MONTH: 'this-month',
  CUSTOM: 'custom',
};

const parseDateString = (value, originalValue) => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : datetime(originalValue).toDate();

  return parsedDate;
};

const DateRangeToggle = ({ value, onChange }) => {
  const now = datetime();

  const [
    thisMonthFirstDay,
    thisMonthLastDay,
  ] = getFirstDayAndLastDayInMonthOf(now);

  const [
    dateRangeQueryOption,
    setDateRangeQueryOption,
  ] = React.useState(DATE_RANGE_QUERY_OPTION.THIS_MONTH);
  const [queryStartDate, setQueryStartDate] = React.useState(
    thisMonthFirstDay.toISOString(),
  );
  const [queryEndDate, setQueryEndDate] = React.useState(
    thisMonthLastDay.toISOString(),
  );

  React.useEffect(() => {
    if (!value || isEmpty(value))
      onChange([queryStartDate, queryEndDate]);

    if (!isEqual(value, [queryStartDate, queryEndDate])) {
      setQueryStartDate(value[0]);
      setQueryEndDate(value[1]);
    }
  }, [queryStartDate, queryEndDate, value, onChange]);

  const setQueryDateRange = ([
    startDate = queryStartDate,
    endDate = queryEndDate,
  ]) => {
    setQueryStartDate(startDate);
    setQueryEndDate(endDate);
    onChange([startDate, endDate]);
  };

  const handleDateRangeQueryOptionChange = (value) => {
    setDateRangeQueryOption(value);

    switch (value) {
      case DATE_RANGE_QUERY_OPTION.LAST_MONTH:
        const lastMonth = now.subtract(1, 'month');
        setQueryDateRange(getFirstDayAndLastDayInMonthOf(lastMonth));
        break;

      case DATE_RANGE_QUERY_OPTION.THIS_MONTH:
        setQueryDateRange(getFirstDayAndLastDayInMonthOf(now));
        break;

      default:
    }
  };

  return (
    <Row>
      <Col xs="auto" className="mb-3">
        <RadioButtonGroup
          name="query-date-range"
          value={dateRangeQueryOption}
          onChange={handleDateRangeQueryOptionChange}
        >
          <RadioButton
            value={DATE_RANGE_QUERY_OPTION.LAST_MONTH}
            label="Last Month"
            variant="outline-primary"
          />
          <RadioButton
            value={DATE_RANGE_QUERY_OPTION.THIS_MONTH}
            label="This Month"
            variant="outline-primary"
          />
          <RadioButton
            value={DATE_RANGE_QUERY_OPTION.CUSTOM}
            label="Custom"
            variant="outline-primary"
          />
        </RadioButtonGroup>
      </Col>
      {dateRangeQueryOption === DATE_RANGE_QUERY_OPTION.CUSTOM && (
        <FormManager
          enableReinitialize
          initialValues={{ queryStartDate, queryEndDate }}
          validationSchema={validator.object().shape(
            {
              queryStartDate: validator
                .date()
                .transform(parseDateString),
              queryEndDate: validator
                .date()
                .transform(parseDateString),
            },
            ['queryEndDate', 'queryStartDate'],
          )}
          onSubmit={({ queryStartDate, queryEndDate }, actions) => {
            const newStartDatetime = datetime(queryStartDate).startOf(
              'day',
            );
            const newEndDatetime = datetime(queryEndDate).endOf(
              'day',
            );

            setQueryDateRange([newStartDatetime, newEndDatetime]);

            actions.setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <Col xs="auto">
                <Row>
                  <Col xs="auto">
                    <DateField
                      name="queryStartDate"
                      disabledDays={(day) =>
                        values['queryEndDate'] &&
                        datetime(day).isAfter(
                          datetime(values['queryEndDate']),
                          'day',
                        )
                      }
                    />
                  </Col>
                  <Col
                    xs="auto"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <p>to</p>
                  </Col>
                  <Col xs="auto">
                    <DateField
                      name="queryEndDate"
                      disabledDays={(day) =>
                        datetime(day).isBefore(
                          datetime(values['queryStartDate']),
                          'day',
                        )
                      }
                    />
                  </Col>
                  <Col xs="auto">
                    <Button type="submit" loading={isSubmitting}>
                      Go
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Form>
          )}
        </FormManager>
      )}
    </Row>
  );
};

DateRangeToggle.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default DateRangeToggle;
