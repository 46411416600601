import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const createProductVariants = async ({
  productId,
  options,
  variants,
}) => {
  try {
    const response = await httpClient.post(
      `/cms/products/${productId}/variants`,
      { options, variants },
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const getProductVariant = async (variantId) => {
  try {
    const response = await httpClient.get(
      `/cms/products/variants/${variantId}`,
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const putProductVariant = async (
  productVariantId,
  productVariant,
) => {
  try {
    const response = await httpClient.put(
      `/cms/products/variants/${productVariantId}`,
      productVariant,
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};
