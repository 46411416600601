import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { CheckboxGroupContext } from '../contexts/checkboxContexts';

const CheckboxGroup = ({
  name,
  label,
  value: valueProp,
  onChange,
  children,
}) => {
  const [value, setValueState] = useState(valueProp);

  const handleChange = useCallback(
    (event) => {
      let newValue;

      if (event.target.checked)
        newValue = [...value, event.target.value];
      else newValue = value.filter((v) => v !== event.target.value);

      setValueState(newValue);

      if (onChange) {
        onChange(newValue);
      }
    },
    [value, onChange],
  );

  const contextValue = useMemo(
    () => ({
      name,
      value,
      onChange: handleChange,
    }),
    [name, value, handleChange],
  );

  return (
    <CheckboxGroupContext.Provider value={contextValue}>
      <fieldset>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          {children}
        </Form.Group>
      </fieldset>
    </CheckboxGroupContext.Provider>
  );
};

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  ),
  onChange: PropTypes.func,
};

CheckboxGroup.defaultProps = {
  onChange: () => {},
};

export default CheckboxGroup;
