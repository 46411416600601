import React from 'react';
import PropTypes from 'prop-types';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { FormGroup, FormText } from 'reactstrap';

import Label from '../Label';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
);

const FormFeedback = ({ children }) => (
  <p style={{ color: 'red' }}>{children}</p>
);

const UploadInput = ({
  value,
  /* -------------------------------- callbacks ------------------------------- */
  onChange,
  onError,
  onWarning,
  onProcessFile,
  onProcessFiles,
  server,
  name,
  allowMultiple,
  allowProcess,
  allowReorder,
  maxFiles,
  labelIdle,
  instantUpload,
  styleItemPanelAspectRatio,
  error,
  label,
  required,
  helperText,
  /* ---------------------- filepond-plugin-image-preview --------------------- */
  allowImagePreview,
  imagePreviewMinHeight,
  imagePreviewMaxHeight,
  imagePreviewHeight,
  imagePreviewMaxFileSize,
  imagePreviewTransparencyIndicator,
  imagePreviewMaxInstantPreviewFileSize,
  imagePreviewMarkupShow,
  imagePreviewMarkupFilter,
  /* ------------------- filepond-plugin-file-validate-size ------------------- */
  allowFileSizeValidation,
  minFileSize,
  maxFileSize,
  maxTotalFileSize,
  labelMaxFileSizeExceeded,
  labelMaxFileSize,
  labelMaxTotalFileSizeExceeded,
  labelMaxTotalFileSize,
  /* ------------------- filepond-plugin-file-validate-type ------------------- */
  allowFileTypeValidation,
  acceptedFileTypes,
  labelFileTypeNotAllowed,
  fileValidateTypeLabelExpectedTypes,
  fileValidateTypeLabelExpectedTypesMap,
  fileValidateTypeDetectType,
}) => {
  const HelperText = error ? FormFeedback : FormText;

  return (
    <FormGroup>
      <Label htmlFor={name} error={error} required={required}>
        {label}
      </Label>
      <FilePond
        files={value}
        /* -------------------------------- callbacks ------------------------------- */
        onupdatefiles={onChange}
        onwarning={onWarning}
        onerror={onError}
        onprocessfile={onProcessFile}
        onprocessfiles={onProcessFiles}
        allowMultiple={allowMultiple}
        allowProcess={allowProcess}
        allowReorder={allowReorder}
        maxFiles={maxFiles}
        server={server}
        name={name}
        labelIdle={labelIdle}
        instantUpload={instantUpload}
        styleItemPanelAspectRatio={styleItemPanelAspectRatio}
        /* ---------------------- filepond-plugin-image-preview --------------------- */
        allowImagePreview={allowImagePreview}
        imagePreviewMinHeight={imagePreviewMinHeight}
        imagePreviewMaxHeight={imagePreviewMaxHeight}
        imagePreviewHeight={imagePreviewHeight}
        imagePreviewMaxFileSize={imagePreviewMaxFileSize}
        imagePreviewTransparencyIndicator={
          imagePreviewTransparencyIndicator
        }
        imagePreviewMaxInstantPreviewFileSize={
          imagePreviewMaxInstantPreviewFileSize
        }
        imagePreviewMarkupShow={imagePreviewMarkupShow}
        imagePreviewMarkupFilter={imagePreviewMarkupFilter}
        /* ------------------- filepond-plugin-file-validate-size ------------------- */
        allowFileSizeValidation={allowFileSizeValidation}
        minFileSize={minFileSize}
        maxFileSize={maxFileSize}
        maxTotalFileSize={maxTotalFileSize}
        labelMaxFileSizeExceeded={labelMaxFileSizeExceeded}
        labelMaxFileSize={labelMaxFileSize}
        labelMaxTotalFileSizeExceeded={labelMaxTotalFileSizeExceeded}
        labelMaxTotalFileSize={labelMaxTotalFileSize}
        /* ------------------- filepond-plugin-file-validate-type ------------------- */
        allowFileTypeValidation={allowFileTypeValidation}
        acceptedFileTypes={acceptedFileTypes}
        labelFileTypeNotAllowed={labelFileTypeNotAllowed}
        fileValidateTypeLabelExpectedTypes={
          fileValidateTypeLabelExpectedTypes
        }
        fileValidateTypeLabelExpectedTypesMap={
          fileValidateTypeLabelExpectedTypesMap
        }
        fileValidateTypeDetectType={fileValidateTypeDetectType}
      />
      {helperText && <HelperText>{helperText}</HelperText>}
    </FormGroup>
  );
};

const serverMethodType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    url: PropTypes.string,
    method: PropTypes.string,
    withCredentials: PropTypes.bool,
    headers: PropTypes.object,
    timeout: PropTypes.number,
    onload: PropTypes.func,
    onerror: PropTypes.func,
    ondata: PropTypes.func,
  }),
  PropTypes.func,
]);

export const UploadInputPropTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  /* -------------------------------- callbacks ------------------------------- */
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onWarning: PropTypes.func,
  onProcessFile: PropTypes.func,
  onProcessFiles: PropTypes.func,
  server: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      process: serverMethodType,
      revert: serverMethodType,
      restore: serverMethodType,
      load: serverMethodType,
      fetch: serverMethodType,
    }),
  ]),
  name: PropTypes.string,
  allowMultiple: PropTypes.bool,
  allowProcess: PropTypes.bool,
  allowReorder: PropTypes.bool,
  maxFiles: PropTypes.number,
  labelIdle: PropTypes.string,
  instantUpload: PropTypes.bool,
  styleItemPanelAspectRatio: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /* ---------------------- filepond-plugin-image-preview --------------------- */
  allowImagePreview: PropTypes.bool,
  imagePreviewMinHeight: PropTypes.number,
  imagePreviewMaxHeight: PropTypes.number,
  imagePreviewHeight: PropTypes.number,
  imagePreviewMaxFileSize: PropTypes.string,
  imagePreviewTransparencyIndicator: PropTypes.string,
  imagePreviewMaxInstantPreviewFileSize: PropTypes.number,
  imagePreviewMarkupShow: PropTypes.bool,
  imagePreviewMarkupFilter: PropTypes.func,
  /* ------------------- filepond-plugin-file-validate-size ------------------- */
  allowFileSizeValidation: PropTypes.bool,
  minFileSize: PropTypes.string,
  maxFileSize: PropTypes.string,
  maxTotalFileSize: PropTypes.string,
  labelMaxFileSizeExceeded: PropTypes.string,
  labelMaxFileSize: PropTypes.string,
  labelMaxTotalFileSizeExceeded: PropTypes.string,
  labelMaxTotalFileSize: PropTypes.string,
  /* ------------------- filepond-plugin-file-validate-type ------------------- */
  allowFileTypeValidation: PropTypes.bool,
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
  labelFileTypeNotAllowed: PropTypes.string,
  fileValidateTypeLabelExpectedTypes: PropTypes.string,
  fileValidateTypeLabelExpectedTypesMap: PropTypes.object,
  fileValidateTypeDetectType: PropTypes.func,
};

UploadInput.propTypes = UploadInputPropTypes;

UploadInput.defaultProps = {
  /* -------------------------------- callbacks ------------------------------- */
  onError: () => {},
  onWarning: () => {},
  onProcessFile: () => {},
  onProcessFiles: () => {},
  allowMultiple: false,
  allowProcess: false,
  allowReorder: false,
  maxFiles: null,
  labelIdle:
    'Drag & Drop your files or <span class="filepond--label-action">Browse</span>',
  instantUpload: false,
  styleItemPanelAspectRatio: null,
  /* ---------------------- filepond-plugin-image-preview --------------------- */
  allowImagePreview: false,
  imagePreviewMinHeight: 44,
  imagePreviewMaxHeight: 256,
  imagePreviewHeight: null,
  imagePreviewMaxFileSize: null,
  imagePreviewTransparencyIndicator: null,
  imagePreviewMaxInstantPreviewFileSize: 1000000,
  imagePreviewMarkupShow: true,
  imagePreviewMarkupFilter: () => true,
  /* ------------------- filepond-plugin-file-validate-size ------------------- */
  allowFileSizeValidation: false,
  minFileSize: null,
  maxFileSize: null,
  maxTotalFileSize: null,
  labelMaxFileSizeExceeded: 'File is too large',
  labelMaxFileSize: 'Maximum file size is {filesize}',
  labelMaxTotalFileSizeExceeded: 'Maximum total size exceeded',
  labelMaxTotalFileSize: 'Maximum total file size is {filesize}',
  /* ------------------- filepond-plugin-file-validate-type ------------------- */
  allowFileTypeValidation: false,
  acceptedFileTypes: [],
  labelFileTypeNotAllowed: 'File of invalid type',
  fileValidateTypeLabelExpectedTypes:
    'Expects {allButLastType} or {lastType}',
  fileValidateTypeLabelExpectedTypesMap: {},
  fileValidateTypeDetectType: null,
};

export default UploadInput;
