import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { Form } from 'react-bootstrap';
import ReactAsyncSelect from 'react-select/async';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import Label from '../Label';

const Input = (props) => (
  <components.Input {...props} isHidden={false} />
);

class AsyncSelect extends React.Component {
  constructor(props) {
    super(props);

    this.debouncedLoadOptions = AwesomeDebouncePromise(
      this.loadOptions,
      300,
    );
  }

  loadOptions = (inputValue, callback) => {
    const { loadOptions } = this.props;

    return loadOptions(inputValue, callback);
  };

  render() {
    const {
      value,
      onChange,
      name,
      label,
      error,
      required,
      helperText,
      disabled,
      getOptionValue,
      getOptionLabel,
      isMulti,
      debounce,
      ...otherProps
    } = this.props;

    const HelperText = error ? Form.Control.Feedback : Form.Text;

    const handleOnChange = (selectedOption, { action }) => {
      if (selectedOption === null || action === 'clear') {
        onChange(null, selectedOption);
        return;
      }

      onChange(selectedOption);
    };

    const handleInputChange = (newValue) => {
      return newValue;
    };

    return (
      <Form.Group>
        {label && (
          <Label htmlFor={name} error={error} required={required}>
            {label}
          </Label>
        )}
        <ReactAsyncSelect
          className={error && 'is-invalid'}
          {...otherProps}
          loadOptions={
            debounce ? this.debouncedLoadOptions : this.loadOptions
          }
          isMulti={isMulti}
          value={value}
          onChange={handleOnChange}
          onInputChange={handleInputChange}
          isDisabled={disabled}
          components={{ Input }}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          styles={{
            singleValue: (provided, state) => {
              return {
                ...provided,
                color: state.selectProps.menuIsOpen
                  ? '#d0d0d0'
                  : '#000',
              };
            },
          }}
        />
        {helperText && (
          <HelperText type={error && 'invalid'}>
            {helperText}
          </HelperText>
        )}
      </Form.Group>
    );
  }
}

AsyncSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  isMulti: PropTypes.bool,
  /** Waiting time between user inputs in milliseconds to fire loadOptions. Set to null to disable this behaviour. */
  debounce: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.number,
  ]),
};

AsyncSelect.defaultProps = {
  name: null,
  label: null,
  error: false,
  required: false,
  helperText: null,
  disabled: false,
  getOptionLabel: (option) => option.label,
  getOptionValue: (option) => option.value,
  isMulti: false,
  debounce: 300,
};

export default AsyncSelect;
