import localStorage from './localStorage';
import sessionStorage from './sessionStorage';
import {
  WINDOW_STORAGE_EVENT,
  GET_ITEM_FROM_SYNCED_SESSION_STORAGE,
  SET_ITEM_TO_SYNCED_SESSION_STORAGE,
  REMOVE_ITEM_FROM_SYNCED_SESSION_STORAGE,
  CLEAR_SYNCED_SESSION_STORAGE,
} from './constants';

const syncedSessionStorage = () => {
  let resolveGetItem;

  const listen = () => {
    window.addEventListener(WINDOW_STORAGE_EVENT, (event) => {
      console.log(event.key, event.newValue);

      switch (event.key) {
        case GET_ITEM_FROM_SYNCED_SESSION_STORAGE:
          {
            const key = event.newValue;

            const value = sessionStorage.getItem(key);

            if (value) {
              localStorage.setItem(
                SET_ITEM_TO_SYNCED_SESSION_STORAGE,
                JSON.stringify({ [key]: value }),
              );

              localStorage.removeItem(
                SET_ITEM_TO_SYNCED_SESSION_STORAGE,
              );
            }
          }
          break;

        case SET_ITEM_TO_SYNCED_SESSION_STORAGE:
          {
            const data = JSON.parse(event.newValue);

            if (!data) return;

            Object.entries(data).forEach(([key, value]) => {
              sessionStorage.setItem(key, value);
            });

            if (resolveGetItem) resolveGetItem();
          }
          break;

        case REMOVE_ITEM_FROM_SYNCED_SESSION_STORAGE:
          {
            const key = event.newValue;

            sessionStorage.removeItem(key);
          }
          break;

        case CLEAR_SYNCED_SESSION_STORAGE:
          sessionStorage.clear();
          break;

        default:
      }
    });
  };

  const _emit = (eventName, value) => {
    localStorage.setItem(eventName, value);
    localStorage.removeItem(eventName);
  };

  const setItem = (key, value) => {
    sessionStorage.setItem(key, value);

    _emit(
      SET_ITEM_TO_SYNCED_SESSION_STORAGE,
      JSON.stringify({ [key]: value }),
    );
  };

  const getItem = async (key) => {
    const value = sessionStorage.getItem(key);

    if (!value) {
      _emit(GET_ITEM_FROM_SYNCED_SESSION_STORAGE, key);

      await new Promise((resolve) => {
        const resolver = () => {
          resolve();
          resolveGetItem = null;
        };

        // wait for other tab to emit back the requested session storage value
        resolveGetItem = resolver;
        // or until 300 ms elapsed
        setTimeout(() => resolver(), 300);
      });

      const value = sessionStorage.getItem(key);

      return value;
    }

    return value;
  };

  const removeItem = (key) => {
    sessionStorage.removeItem(key);

    _emit(REMOVE_ITEM_FROM_SYNCED_SESSION_STORAGE, key);
  };

  const clear = () => {
    sessionStorage.clear();

    _emit(CLEAR_SYNCED_SESSION_STORAGE);
  };

  listen();

  return {
    listen,
    setItem,
    getItem,
    removeItem,
    clear,
  };
};

export default syncedSessionStorage();
