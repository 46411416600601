import PropTypes from 'prop-types';

export const CouponPropType = PropTypes.shape({
  coupon_code: PropTypes.string.isRequired,
  product: PropTypes.shape({
    operator: PropTypes.oneOf(['and', 'or']),
    included_ids: PropTypes.arrayOf(PropTypes.string),
    excluded_ids: PropTypes.arrayOf(PropTypes.string),
  }),
  category: PropTypes.shape({
    operator: PropTypes.oneOf(['and', 'or']),
    included_ids: PropTypes.arrayOf(PropTypes.string),
    excluded_ids: PropTypes.arrayOf(PropTypes.string),
  }),
  is_valid_with_excluded_item: PropTypes.bool,
  allowed_user_ids: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  custom_error_message: PropTypes.string,
  referrer_user: PropTypes.string,
  discount_target: PropTypes.oneOf(['cart', 'product']).isRequired,
  is_percent: PropTypes.bool.isRequired,
  discount_value: PropTypes.number.isRequired,
  min_purchase_amount: PropTypes.number,
  max_purchase_amount: PropTypes.number,
  min_purchase_quantity: PropTypes.number,
  usage_limit: PropTypes.number,
  limit_type: PropTypes.oneOf(['global-limit', 'per-user'])
    .isRequired,
  is_first_order_only: PropTypes.bool,
  is_free_shipping: PropTypes.bool,
  bundle_quantity: PropTypes.number,
  is_bundle_unique: PropTypes.bool,
  start_at: PropTypes.string,
  end_at: PropTypes.string,
  is_active: PropTypes.bool,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
});
