import { createContext, useContext } from 'react';

export const defaultRadioGroupContextValues = {
  name: '',
  onChange: () => {},
  value: null,
};

export const RadioGroupContext = createContext(
  defaultRadioGroupContextValues,
);

export const useRadioGroupContext = () => {
  const radioGroupContext = useContext(RadioGroupContext);

  return radioGroupContext;
};
