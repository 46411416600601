import { useListCRUD } from 'lib/swr-crud';
import httpClient, { getResponseData } from 'lib/httpClient';

import { postCreateCoupon } from './api';

const fetchers = {
  read: async (url, query) => {
    try {
      const response = await httpClient({
        method: 'get',
        url,
        query,
      });

      return getResponseData(response);
    } catch (error) {
      throw error;
    }
  },
  create: async (_url, coupon) => {
    try {
      const response = await postCreateCoupon(coupon);

      return response;
    } catch (err) {
      throw err;
    }
  },
};

const useCoupons = ({
  page,
  sizePerPage,
  referrerUserId,
  couponCode,
} = {}) => {
  const url = '/cms/coupons';

  const { data, error, create, isLoading, mutate } = useListCRUD(
    url,
    'coupon_code',
    {
      fetchers,
      query: {
        page,
        per_page: sizePerPage,
        referrer_user_id: referrerUserId,
        coupon_code: couponCode,
      },
    },
  );

  const coupons = data?.data || [];
  const total = data?.meta?.total;

  const mutateCoupons = (updater) =>
    mutate((data) => ({
      ...data,
      data: updater(data.data),
    }));

  return {
    coupons,
    total,
    create,
    error,
    isLoading,
    mutateCoupons,
  };
};

export default useCoupons;
