import React, { useEffect, useState } from 'react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';

//i18n
import { withNamespaces } from 'react-i18next';

import { kitchenNavConfig, navConfig } from 'appConfig';

import SidebarContentItems from './SidebarContentItems';
import { USER_ROLE } from 'lib/users/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getRoleUser } from 'store/auth/GetRoleUser/actions';

const SidebarContent = (props) => {
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);

  const { isKitchenRepresentative, isAdmin } = roleState?.role;

  useEffect(() => {
    dispatch(getRoleUser());
  }, [dispatch]);

  const menuConfig = isKitchenRepresentative
    ? kitchenNavConfig
    : navConfig;

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    var pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      var matchingMenuItem = null;
      var ul = document.getElementById('side-menu');
      var items = ul.getElementsByTagName('a');
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  }

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <SidebarContentItems config={menuConfig} nestedLevel={0} />
        </ul>
      </div>
    </React.Fragment>
  );
};;;;

export default withRouter(withNamespaces()(SidebarContent));
