import { useCRUD } from 'lib/swr-crud';
import { getOrderById } from './api';

const useOrder = (orderId) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/cms/orders/${orderId}`,
    async () => {
      return getOrderById(orderId);
    },
  );

  const order = data || null;

  return {
    order,
    error,
    mutate,
    isValidating,
    isLoading,
  };
};

export default useOrder;
