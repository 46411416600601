import React from 'react';
import PropTypes from 'prop-types';
import yup from 'lib/validator';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import { routePaths } from 'appConfig';
import { FormManager, Form, GeneralError } from 'lib/form';
import notification from 'lib/notification';
import { constants } from 'lib/productFamily';
import { SelectField, TextField } from 'components/formFields';
import { Button } from 'components/Common';
import slugify from '@sindresorhus/slugify';

import { MAX_LENGTHS } from './constants';
import useProducts from './useProducts';

const initialValues = {
  product_family: constants.PRODUCT_FAMILY.NORMAL,
  name: '',
  excerpt: '',
  short_description: '',
  slug: '',
  sku: '',
  price: 0,
  stock: 0,
  alias: '',
};

const validationSchema = yup.object({
  product_family: yup.string().required(),
  name: yup.string().max(MAX_LENGTHS.NAME).required(),
  excerpt: yup.string().max(MAX_LENGTHS.EXCERPT),
  short_description: yup.string().max(MAX_LENGTHS.SHORT_DESCRIPTION),
  slug: yup.string().required(),
  sku: yup.string().required(),
  price: yup.number().required(),
  stock: yup.number().integer().required(),
  alias: yup.string().required(),
});

const AddProductForm = ({ onSubmit }) => {
  const { create } = useProducts();

  const history = useHistory();

  const handleOnSubmit = async (product, actions) => {
    try {
      const response = await create(product);

      notification.success('Successfully created product');
      history.push(routePaths.PRODUCTS);
      onSubmit(null, response);
      return;
    } catch (error) {
      actions.setErrors(error.errors);
      actions.setStatus({
        error: error.errors?.message || 'Error creating product',
      });
      onSubmit(error, null);
      return;
    }
  };

  const setSlugOnNameChange = (e, form) => {
    const newName = e.target.value;

    const newSlug = slugify(newName);

    form.setFieldValue('slug', newSlug);
  };

  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {(form) => (
        <Form autoComplete="off">
          <GeneralError />
          <Container>
            <SelectField
              name="product_family"
              label="Product Family"
              required
              options={constants.productFamilyOptions}
            />
            <TextField
              name="name"
              label="Product Name"
              required
              maxLength={MAX_LENGTHS.NAME}
              onChange={(e) => setSlugOnNameChange(e, form)}
            />
            <Row>
              <Col>
                <TextField name="slug" label="Slug" required />
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={3}>
                <TextField name="sku" label="SKU" required />
              </Col>
              <Col sm={6} md={3}>
                <TextField
                  type="number"
                  name="price"
                  label="Price"
                  required
                />
              </Col>
              <Col sm={6} md={3}>
                <TextField
                  type="number"
                  name="stock"
                  label="Stock"
                  required
                />
              </Col>
              <Col sm={6} md={3}>
                <TextField name="alias" label="Alias" required />
              </Col>
            </Row>
            <TextField
              name="excerpt"
              label="Excerpt"
              maxLength={MAX_LENGTHS.EXCERPT}
            />
            <TextField
              type="textarea"
              name="short_description"
              label="Short Description"
              maxLength={MAX_LENGTHS.SHORT_DESCRIPTION}
            />
            <Button type="submit" loading={form.isSubmitting}>
              Create
            </Button>
          </Container>
        </Form>
      )}
    </FormManager>
  );
};

AddProductForm.propTypes = {
  onSubmit: PropTypes.func,
};

AddProductForm.defaultProps = {
  onSubmit: () => {},
};

export default AddProductForm;
