import qs from 'qs';

import { omitBy, isNil, isEmpty } from 'lib/javascript';

export const stripBlankQueryParams = (queryParams) => {
  return omitBy(
    queryParams,
    (queryParam) => isNil(queryParam) || queryParam === '',
  );
};

export const formatUrlWithQueryParams = (url, queryParams) => {
  if (isNil(queryParams) || isEmpty(queryParams)) return url;

  return (
    url +
    qs.stringify(stripBlankQueryParams(queryParams), {
      addQueryPrefix: true,
    })
  );
};
