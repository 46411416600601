import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { FORGET_PASSWORD } from './actionTypes';
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
} from './actions';

//Include Both Helper File with needed methods
import { postSendResetPasswordEmail } from 'services/api/auth';

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history, actions } }) {
  try {
    const { error } = yield call(postSendResetPasswordEmail, {
      email: user.email,
    });

    if (error) {
      yield put(userForgetPasswordError(error));
      actions.setErrors(error.errors);
    } else {
      yield put(
        userForgetPasswordSuccess(
          'An email have been sent. Please click the link when you get it. ',
        ),
      );
    }

    actions.setSubmitting(false);
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
