import { Dayjs } from 'dayjs';
import httpClient, { getErrorMessage, getResponseData } from 'lib/httpClient';
import { useListCRUD } from 'lib/swr-crud';
import type { SelectableSlot } from './types';

const fetchers = {
  read: async (url: string, query?: any) => {
    try {
      // @ts-ignore
      const response = await httpClient({
        method: 'GET',
        url,
        query,
      });

      return getResponseData(response).data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
};

export type DateRange = {
  startDate: Dayjs;
  endDate: Dayjs;
}

const useSelectableSlots = (
  collectionPointId: string,
  dateRange: DateRange,
) => {
  const url = `/cms/collection_points/${collectionPointId}/selectable_slots`;

  const { startDate, endDate } = dateRange;

  const {
    data,
    error,
    mutate,
    isLoading,
  } = useListCRUD(url, 'slot', {
    // @ts-ignore
    fetchers,
    query: {
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
    },
  });

  const selectableSlots: Array<SelectableSlot> = data || [];

  return {
    selectableSlots,
    error,
    isLoading,
    mutate,
  };
};

export default useSelectableSlots;
