import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AutoComplete } from 'components/Common';
import { getAddonRules } from './api';
import notification from 'lib/notification';

const AddonRuleMultiSelector = ({
  name,
  value,
  onChange,
  label,
  required,
  error,
  helperText,
  isLoading,
}) => {
  const [_isLoading, setLoading] = useState(false);
  const [rules, setRules] = useState([]);

  const loadOptions = async () => {
    try {
      setLoading(true);
      const { meta } = await getAddonRules({});
      const { data } = await getAddonRules({
        per_page: meta.total,
      });

      setRules(data);
    } catch (err) {
      notification.error(err?.errors?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <AutoComplete
      isLoading={isLoading || _isLoading}
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      disabled={isLoading || _isLoading}
      options={rules}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.id}
      isMulti={true}
    />
  );
};

AddonRuleMultiSelector.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default AddonRuleMultiSelector;
