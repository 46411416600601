import React from 'react';
import PropTypes from 'prop-types';

import { AsyncSelect } from 'components/Common';
import { getIngredients } from './api';
import notification from 'lib/notification';

const IngredientSelector = ({
  name,
  value,
  onChange,
  label,
  required,
  error,
  helperText,
  isClearable,
  disabled,
}) => {
  const loadOptions = async (inputValue) => {
    try {
      const { data } = await getIngredients({
        per_page: 5,
        name: inputValue,
      });

      return data;
    } catch (err) {
      notification.error(err);
      return [];
    }
  };

  return (
    <AsyncSelect
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option?.id}
      disabled={disabled}
      isClearable={isClearable}
    />
  );
};

IngredientSelector.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
};

IngredientSelector.defaultProps = {
  isClearable: false,
  disabled: false,
};

export default IngredientSelector;
