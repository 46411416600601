import httpClient, { getResponseData } from 'lib/httpClient';
import { useListCRUD } from 'lib/swr-crud';
import { CollectionPoint } from './types';

const fetchers = {
  read: async (url: string, query?: any) => {
    try {
      // @ts-ignore
      const response = await httpClient({
        method: 'GET',
        url,
        query,
      });

      return getResponseData(response);
    } catch (error) {
      throw error;
    }
  },
};

interface CollectionsParams {
  page?: number;
  sizePerPage?: number;
  sortField?: 'created_at' | 'name';
  sortDir?: 'asc' | 'desc';
}

const useCollectionPoints = ({
  page,
  sizePerPage,
  sortField,
  sortDir,
}: CollectionsParams = {}) => {
  const url = '/cms/collection_points';

  const { data, error, create, isLoading, mutate } = useListCRUD(
    url,
    'id',
    {
      // @ts-expect-error
      fetchers,
      query: {
        page,
        per_page: sizePerPage,
        sort_field: sortField,
        sort_dir: sortDir,
      },
    },
  );

  const collectionPoints: Array<CollectionPoint> = data?.data || [];
  const total = data?.meta?.total || 0;

  const _mutate = (updater) =>
    mutate((data: any) => ({
      ...data,
      data: updater(data.data),
    }));

  return {
    collectionPoints,
    total,
    create,
    error,
    isLoading,
    mutate: _mutate,
  };
};

export default useCollectionPoints;
