import PropTypes from 'prop-types';

import { Price, Table } from 'core/components';
import { Link, useRouter } from 'lib/router';
import { Datetime } from 'lib/datetime';
import useSalesReportByOrder from './useSalesReportByOrder';
import {
  FirstOrderBadge,
  KitchenBadge,
  OrderNumber,
} from 'lib/orders';
import { routePaths } from 'appConfig';
import { UserBadges } from 'lib/users';

const ReportByOrderTable = ({ startDate, endDate }) => {
  const { query, updateQueryParams } = useRouter();

  const { sizePerPage, page } = query;

  const {
    isLoading,
    salesReportByOrder,
    total,
  } = useSalesReportByOrder({
    startDate,
    endDate,
    sizePerPage,
    page,
  });

  const columns = [
    {
      dataField: 'created_at',
      text: 'Date',
      formatter: (createdAt) => <Datetime value={createdAt} />,
    },
    {
      dataField: 'order_number',
      text: 'Order #',
      formatter: (orderNumber, order) => (
        <Link to={`${routePaths.ORDERS}/${order.order_id}`}>
          <div className="d-flex align-items-center">
            <OrderNumber number={orderNumber} />
            {order.is_first_order && (
              <FirstOrderBadge className="ml-1" />
            )}
            <KitchenBadge
              kitchenName={order?.kitchen?.display_name ?? ''}
            />
          </div>
        </Link>
      ),
    },
    {
      dataField: 'customer_name',
      text: 'Customer Name',
      formatter: (customer_name, row) => (
        <Link
          to={{
            pathname: routePaths.USER_DETAILS,
            urlParams: {
              userId: row.user_id,
            },
          }}
        >
          <div className="d-flex align-items-center">
            {customer_name}
            <UserBadges data={row} />
          </div>
        </Link>
      ),
    },
    {
      dataField: 'net_sales',
      text: 'Net Sales',
      formatter: (netSales) => <Price value={netSales} />,
    },
    {
      dataField: 'net_shipping',
      text: 'Net Shipping Fee',
      formatter: (netShipping) => <Price value={netShipping} />,
    },
    {
      dataField: 'tax',
      text: 'Tax Collected',
      formatter: (tax) => <Price value={tax} />,
    },
  ];

  const onTableChange = (action, newState) => {
    switch (action) {
      case 'pagination':
        const { sizePerPage, page } = newState;
        updateQueryParams({ sizePerPage, page });
        break;

      default:
    }
  };

  return (
    <Table
      remote
      loading={isLoading}
      keyField="order_id"
      data={salesReportByOrder}
      columns={columns}
      pagination={{ page, sizePerPage, totalSize: total }}
      onTableChange={onTableChange}
    />
  );
};

ReportByOrderTable.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default ReportByOrderTable;
