import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import { Page } from 'components/Common';
import { DonationForm, useDonations } from 'lib/donations';
import { useRouter } from 'lib/router';
import notification from 'lib/notification';
import { routePaths } from 'appConfig';

const NewDonationPage = () => {
  const { create } = useDonations();
  const router = useRouter();

  const handleOnSubmit = async (fieldValues, actions) => {
    try {
      await create(fieldValues);
      notification.success('Successfully created donation');
      router.push({ pathname: routePaths.DONATIONS });
      return;
    } catch (error) {
      actions.setErrors(error.errors);
      actions.setStatus({
        error: error.errors?.message || 'Error creating donation',
      });

      return;
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Page
      content={
        <Row>
          <Col>
            <Card>
              <CardBody>
                <DonationForm
                  onSubmit={handleOnSubmit}
                  initialValues={{}}
                  buttonTitle='Create'
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      }
    />
  );
};

export default NewDonationPage;
