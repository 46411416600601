import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal as RSModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Button from '../Button';

const Modal = ({
  open,
  onClose,
  title,
  backdrop,
  content,
  actions,
  className,
  unmountOnClose,
  container,
  children,
  width,
}) => {
  return (
    <RSModal
      isOpen={open}
      toggle={onClose}
      className={className}
      unmountOnClose={unmountOnClose}
      container={container}
      backdrop={backdrop}
      style={{ width: width, maxWidth: '900px' }}
    >
      {title && <ModalHeader toggle={onClose}>{title}</ModalHeader>}
      <ModalBody>{content || children}</ModalBody>
      {actions && (
        <ModalFooter>
          {actions.map((action) => (
            <Button
              key={action.id}
              id={action.id}
              color={action.color}
              onClick={action.onClick}
            >
              {action.label}
            </Button>
          ))}
        </ModalFooter>
      )}
    </RSModal>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node,
  backdrop: PropTypes.oneOf([true, false, 'static']),
  content: PropTypes.node,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      color: PropTypes.string,
    }),
  ),
  unmountOnClose: PropTypes.bool,
  container: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  children: PropTypes.node,
  width: PropTypes.string,
};

Modal.defaultProps = {
  unmountOnClose: true,
  backdrop: true,
  width: '50%',
};

export default Modal;
