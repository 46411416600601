import React from 'react';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import ResolutionForm from './ResolutionForm';
import { Button, Icon } from 'components/Common';
import { ComplaintResolutionPayload } from './types';

interface ResolutionForCustomerCardProps {
  isResolutionFormDisable: boolean;
  resolutionData: any;
  onSubmitResolution: (
    payload: ComplaintResolutionPayload,
  ) => Promise<void>;
  onCancelResolution: () => void;
  is_verified: boolean;
  isKitchenRepresentative: boolean;
  setisResolutionFormDisable: (value: boolean) => void;
  onDelete: () => Promise<void>;
}

const ResolutionForCustomerCard: React.FC<ResolutionForCustomerCardProps> = ({
  isResolutionFormDisable,
  resolutionData,
  onSubmitResolution,
  onCancelResolution,
  is_verified,
  isKitchenRepresentative,
  setisResolutionFormDisable,
  onDelete,
}) => (
  <Card>
    <CardHeader className="bg-white pb-0">
      <CardTitle>
        <div className="d-flex flex-row align-items-center justify-content-between">
          Resolution for Customer
          <div className="d-flex flex-row justify-content-end p-0">
            {isResolutionFormDisable &&
              !(is_verified || isKitchenRepresentative) && (
                <>
                  {/* @ts-ignore */}
                  <Button
                    className={`mr-2`}
                    variant={'outlined'}
                    color="dark"
                    onClick={onDelete}
                  >
                    <span className="mr-2">Delete Resolution</span>
                  </Button>
                  {/* @ts-ignore */}
                  <Button
                    onClick={() => setisResolutionFormDisable(false)}
                  >
                    Edit Resolution
                  </Button>
                </>
              )}
          </div>
        </div>
      </CardTitle>
    </CardHeader>
    <CardBody>
      <ResolutionForm
        data={resolutionData}
        onSubmit={onSubmitResolution}
        isDisabled={isResolutionFormDisable}
        onCancel={onCancelResolution}
      />
    </CardBody>
  </Card>
);

export default ResolutionForCustomerCard;
