import { Badge } from 'reactstrap';

interface CompletedOrdersCountBadgeProps {
  value: number;
  className?: string;
  color?: string;
}

const CompletedOrdersCountBadge = ({
  value,
  className,
  color = 'success',
}: CompletedOrdersCountBadgeProps) => {
  return (
    <Badge className={className} color={color}>
      <span title={`${value} completed order(s)`}>{value}</span>
    </Badge>
  );
};

export default CompletedOrdersCountBadge;
