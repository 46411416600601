import { useHistory, useParams } from 'react-router';
import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Button, Page } from 'components/Common';
import {
  CatalogueForm,
  CatalogueItemPayload,
  CatalogueItems,
  CataloguePayload,
  useCatalogue,
} from 'lib/catalogues';
import { List } from 'react-content-loader';
import notification from 'lib/notification';
import { useConfirmation } from 'lib/confirm';
import { routePaths } from 'appConfig';

const notifiedError = (
  err: {
    message?: string;
    errors?: { message?: string };
  },
  fallbackMessage: string = '',
) => {
  if (Boolean(fallbackMessage)) {
    notification.error(fallbackMessage);
  } else {
    const message = err?.message || err?.errors?.message;
    if (Boolean(message)) {
      notification.error(message);
    }
  }
};

const CatalogueDetailsPage = () => {
  const { catalogueId } = useParams<{ catalogueId?: string }>();

  const {
    catalogue,
    isLoading,
    update,
    addItem,
    updateItem,
    deleteItem,
    sortItem,
    remove,
    mutate,
  } = useCatalogue(catalogueId?.toString() ?? '');

  const { confirm } = useConfirmation();
  const history = useHistory();
  const [isDeleting, setIsDeleting] = useState(false);
  const onDelete = async () => {
    await confirm();

    try {
      setIsDeleting(true);
      await remove();

      notification.success('Successfully deleted manual collection');
      history.replace(routePaths.CATALOGUES);
    } catch (err) {
      notifiedError(err);
      throw err;
    } finally {
      setIsDeleting(false);
    }
  };

  const onUpdate = async (body: CataloguePayload) => {
    try {
      const updated = await update(body);
      notification.success('Successfully updated manual collection');
      mutate(updated);
    } catch (err) {
      notifiedError(err);
      throw err;
    }
  };

  const initialValues: CataloguePayload = {
    title: catalogue?.title ?? '',
    slug: catalogue?.slug ?? '',
  };

  const onAddItem = async (payload: CatalogueItemPayload) => {
    try {
      await addItem(payload);
      mutate();
      notification.success('Successfully add manual collection item');
    } catch (err) {
      notifiedError(err);
      throw err;
    }
  };

  const onUpdateItem = async (payload: CatalogueItemPayload) => {
    try {
      await updateItem(payload?.id ?? '', payload);
      mutate();
      notification.success(
        'Successfully update manual collection item',
      );
    } catch (err) {
      notifiedError(err);
      throw err;
    }
  };

  const onDeleteItem = async (id: string | number) => {
    try {
      await deleteItem(id);
      mutate();
      notification.success(
        'Successfully delete manual collection item',
      );
    } catch (err) {
      notifiedError(err);
      throw err;
    }
  };

  const onSortItem = async (
    changedId?: string | number,
    beforeId?: string | number | null,
  ) => {
    try {
      await sortItem(changedId, beforeId);
      mutate();
    } catch (err) {
      notifiedError(
        err,
        'Something went wrong when sort manual collection item.',
      );
      throw err;
    }
  };

  return (
    <Page
      content={
        <React.Fragment>
          {isLoading ? (
            <List />
          ) : (
            <React.Fragment>
              <Card>
                <CardBody>
                  <Row>
                    <Col className="d-flex justify-content-end align-items-center">
                      {/* @ts-ignore */}
                      <Button
                        color="danger"
                        onClick={onDelete}
                        loading={isDeleting}
                        disabled={isDeleting}
                      >
                        <span>Delete Manual Collection</span>
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CatalogueForm
                        onSubmit={onUpdate}
                        initialValues={initialValues}
                        buttonLabel="Update"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <CatalogueItems
                items={catalogue?.items}
                onAdd={onAddItem}
                onUpdate={onUpdateItem}
                onDelete={onDeleteItem}
                onSort={onSortItem}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      }
    />
  );
};

export default CatalogueDetailsPage;
