import React, { useState } from 'react';

import { Button, Page } from 'components/Common';
import notification from 'lib/notification';
import { useConfirmation } from 'lib/confirm';
import { useHistory, useParams } from 'react-router';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { List } from 'react-content-loader';
import { routePaths } from 'appConfig';
import { AddonRuleForm, useAddonRule } from 'lib/addon-rules';

const AddonRuleDetailsPage = () => {
  const { confirm } = useConfirmation();

  const history = useHistory();
  const { addonRuleId } = useParams();
  const {
    isLoading,
    addonRule,
    update,
    _delete,
    mutate,
  } = useAddonRule(addonRuleId);
  const [isDeleting, setDeleting] = useState(false);

  const onUpdate = async (values, actions) => {
    try {
      const data = await update(values);
      mutate(data);
      notification.success('Successfully update Addon Rule');
    } catch (error) {
      actions.setErrors(error.errors);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const onDelete = async () => {
    await confirm();

    try {
      setDeleting(true);
      await _delete();
      history.replace(routePaths.ADDON_RULES);
      notification.success('Successfully delete Addon Rule');
    } catch (error) {
      notification.error(error?.errors?.message);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Page
      content={
        <React.Fragment>
          {isLoading ? (
            <List uniqueKey="addon-rule-loading" />
          ) : (
            <>
              <Card>
                <CardBody>
                  <Row>
                    <Col
                      xs={12}
                      className="d-flex justify-content-end align-item-center"
                    >
                      <div>
                        <Button
                          color={'danger'}
                          onClick={onDelete}
                          loading={isDeleting}
                        >
                          Delete Addon Rule
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <AddonRuleForm
                        onSubmit={onUpdate}
                        initialValues={{
                          ...addonRule,
                          selected_addon_option_value_ids: addonRule.selected_addon_option_values.map(
                            (i) => i.id,
                          ),
                          value_addon_option_ids: addonRule.value_addon_options.map(
                            (i) => i.id,
                          ),
                        }}
                        buttonText="Update"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}
        </React.Fragment>
      }
    />
  );
};

export default AddonRuleDetailsPage;
