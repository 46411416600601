import { useListCRUD } from 'lib/swr-crud';
import httpClient, { getResponseData } from 'lib/httpClient';

const fetchers = {
  read: async (url, query) => {
    try {
      const response = await httpClient({
        method: 'get',
        url,
        query,
      });

      return getResponseData(response);
    } catch (error) {
      throw error;
    }
  },
};

const useSalesReportByCategory = ({
  startDate,
  endDate,
  sizePerPage,
  page,
}) => {
  const url = '/cms/reports/categories';

  const { data, error, isLoading } = useListCRUD(url, 'id', {
    fetchers,
    query: {
      start_date: startDate,
      end_date: endDate,
      per_page: sizePerPage,
      page,
    },
  });

  const salesReportByCategory = data?.data || [];
  const total = data?.meta?.total;

  return {
    total,
    error,
    isLoading,
    salesReportByCategory,
  };
};

export default useSalesReportByCategory;
