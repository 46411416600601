import { Badge } from 'reactstrap';

type Props = {
  kitchenName: string;
  className?: string;
};

export default function KitchenBadge({
  kitchenName,
  className,
}: Props) {
  return (
    <Badge
      className={`ml-1 mb-1 ${className}`}
      style={{
        backgroundColor: '#a42e27',
      }}
    >
      <span title={`Order handled by ${kitchenName} kitchen`}>
        {kitchenName}
      </span>
    </Badge>
  );
}
