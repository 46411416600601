import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import { isEqual, includes, omit, isArray } from 'lodash';
import { Form } from 'react-bootstrap';

import Label from '../Label';

const Input = (props) => (
  <components.Input {...props} isHidden={false} />
);

const GroupSelect = ({
  value,
  onChange,
  options,
  name,
  label,
  error,
  required,
  helperText,
  isMulti,
  disabled,
  getOptionValue,
  getOptionLabel,
  ...otherProps
}) => {
  const HelperText = error ? Form.Control.Feedback : Form.Text;

  const getValue = () => {
    if (options && value) {
      const _flatOptions = options.map((o) => o.options).flat();
      return isMulti
        ? _flatOptions.filter((option) =>
            includes(value, getOptionValue(option)),
          )
        : _flatOptions.find((option) =>
            isEqual(getOptionValue(option), value),
          ) || '';
    } else {
      return isMulti ? [] : '';
    }
  };

  const handleOnChange = (selectedOption, { action }) => {
    if (selectedOption === null || action === 'clear') {
      onChange(null, selectedOption);
      return;
    }
    onChange(
      isMulti
        ? selectedOption.map((item) => getOptionValue(item))
        : getOptionValue(selectedOption),
      selectedOption,
    );
  };

  const getOptions = () => {
    return options;
  };

  return (
    <Form.Group>
      {label && (
        <Label htmlFor={name} error={error} required={required}>
          {label}
        </Label>
      )}
      <ReactSelect
        className={error && 'is-invalid'}
        {...otherProps}
        value={getValue()}
        onChange={handleOnChange}
        options={getOptions()}
        isMulti={isMulti}
        isDisabled={disabled}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        components={{ Input }}
        styles={{
          singleValue: (provided, state) => {
            return {
              ...provided,
              color: state.selectProps.menuIsOpen
                ? '#d0d0d0'
                : '#000',
            };
          },
        }}
      />
      {helperText && (
        <HelperText type={error && 'invalid'}>
          {helperText}
        </HelperText>
      )}
    </Form.Group>
  );
};

GroupSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  disableOptionGroup: PropTypes.bool,
};

GroupSelect.defaultProps = {
  name: null,
  label: null,
  error: false,
  required: false,
  helperText: null,
  isMulti: false,
  disabled: false,
  getOptionValue: (option) => option.value,
  getOptionLabel: (option) => option.label,
};

export default GroupSelect;
