import React from 'react';
import dayjs from 'dayjs';
import datetime from '..';
import config from 'appConfig';

const formatDatetimeAMPM = (value: dayjs.Dayjs): string => {
  return value.format('DD-MM-YYYY, hh:mm a');
};

interface DatetimeAMPMProps {
  value?: string | Date;
}

const DatetimeAMPM: React.FC<DatetimeAMPMProps> = ({ value }) => {
  if (!value) return <span>-</span>;

  const parsedValue = datetime(value).tz(config.timezone);

  const formatted = formatDatetimeAMPM(parsedValue);

  return <>{formatted}</>;
};

export default DatetimeAMPM;
