import { Form, FormManager } from 'lib/form';
import {
  LoveMessage,
  LoveMessageFormModel,
  LoveMessagePayload,
} from './types';
import validator from 'lib/validator';
import { Col, Row } from 'reactstrap';
import { TextField } from 'components/formFields';
import { Button } from 'components/Common';

interface LoveMessageFormProps {
  loveMessage?: LoveMessage | null;
  onSubmit: (payload: LoveMessagePayload) => Promise<void>;
  showCancel?: boolean;
  onCancel?: () => void;
}

const LoveMessageForm = ({
  loveMessage,
  onSubmit,
  showCancel = true,
  onCancel,
}: LoveMessageFormProps) => {
  const defaultValues: LoveMessageFormModel = {
    id: null,
    content: '',
  };

  const initialValues: LoveMessageFormModel = {
    ...defaultValues,
    ...loveMessage,
  };

  const validationSchema = validator.object({
    content: validator.string().required(),
  });

  const handleOnSubmit = async (payload: LoveMessagePayload) => {
    await onSubmit(payload);
  };

  return (
    <FormManager
      initialValues={initialValues}
      validateOnBlur
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting, values }) => {
        return (
          <Form>
            <input
              type="hidden"
              value={initialValues.id ?? undefined}
            />
            <Row>
              <Col>
                {/* @ts-ignore */}
                <TextField
                  name="content"
                  label="Content"
                  required
                  type="textarea"
                  rows="4"
                  multiline
                  placeholder="Your love message here"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {showCancel && (
                  <>
                    {/* @ts-ignore */}
                    <Button
                      type="button"
                      variant="outlined"
                      color="dark"
                      onClick={onCancel}
                      disabled={isSubmitting}
                    >
                      <span>Cancel</span>
                    </Button>
                  </>
                )}
                {/* @ts-ignore */}
                <Button
                  type="submit"
                  color="danger"
                  className={showCancel && 'ml-2'}
                  loading={isSubmitting}
                  disabled={isSubmitting || !values.content}
                >
                  <span>
                    {loveMessage?.id
                      ? 'Update Love Message'
                      : 'Send Love'}
                  </span>
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </FormManager>
  );
};

export default LoveMessageForm;
