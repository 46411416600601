import React from 'react';
import { FormManager, Form } from 'lib/form';
import { getIngredientTypeOptions } from './utils';
import { merge } from 'lib/javascript';
import yup from 'lib/validator';
import { SelectField, TextField } from 'components/formFields';
import { Button } from 'components/Common';
import { Col, Row } from 'reactstrap';
import useIngredientType from './useIngredientType';
import { List } from 'react-content-loader';

const validationSchema = yup.object({
  name: yup.string().required(),
  alias: yup.string().required(),
  type: yup.string().required(),
  stock: yup.number().nullable(),
  unit_of_measurement: yup.string().required(),
});

const IngredientForm = ({ onSubmit, initialValues, buttonTitle }) => {
  const {
    ingredientTypes,
    isLoading: isIngredientTypesLoading,
  } = useIngredientType();

  const defaultValues = {
    name: '',
    alias: '',
    type: getIngredientTypeOptions(ingredientTypes)?.[0]?.value,
    stock: null,
    unit_of_measurement: '',
  };

  return (
    <FormManager
      initialValues={merge({}, defaultValues, initialValues)}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Row>
              <Col md={12} lg={6}>
                <TextField name={'name'} label="Name" required />
              </Col>

              <Col md={12} lg={6}>
                <TextField name={'alias'} label="Alias" required />
              </Col>
            </Row>

            <Row>
              <Col md={12} lg={6}>
                {isIngredientTypesLoading ? (
                  <List uniqueKey="ingredient-type-loading" />
                ) : (
                  <SelectField
                    name={'type'}
                    label="Type"
                    options={getIngredientTypeOptions(
                      ingredientTypes,
                    )}
                  />
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12} lg={6}>
                <TextField
                  type="number"
                  name={'stock'}
                  label="Stock"
                  placeholder="Leave empty for infinite stock"
                />
              </Col>

              <Col md={12} lg={6}>
                <TextField
                  name={'unit_of_measurement'}
                  label="Stock Unit of Measurement"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Button type="submit" loading={isSubmitting}>
                  {buttonTitle}
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </FormManager>
  );
};

export default IngredientForm;
