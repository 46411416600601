import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

import jwtClient from 'lib/jwtClient';

import { encrypt } from 'lib/encryption';

export const postJwtLogin = async ({ email, password }) => {
  try {
    const response = await httpClient.post(
      '/auth/access',
      {
        email,
        password: await encrypt(password),
      },
      {
        skipAuthRefresh: true,
      },
    );

    if (response.status === 400 || response.status === 500)
      return { error: response.data };

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const refreshJwtToken = async () => {
  try {
    const response = await httpClient({
      method: 'post',
      url: '/auth/refresh',
      skipAuthRefresh: true,
    });

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const postJwtLogout = async () => {
  const token = await jwtClient.getToken();

  if (!token) return { response: { message: 'No token to logout' } };

  try {
    const response = await httpClient({
      method: 'post',
      url: '/auth/logout',
      skipAuthRefresh: true,
    });
    return { response: getResponseData(response) };
  } catch (err) {
    return { error: err };
  }
};

export const postSendResetPasswordEmail = async ({ email }) => {
  try {
    const response = await httpClient.post('/auth/password/reset', {
      email,
    });

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const getVerifyResetToken = async ({ email, token }) => {
  try {
    const response = await httpClient.get('/auth/password/confirm', {
      params: {
        email,
        token,
      },
    });

    const data = getResponseData(response);

    if (data.message === 'invalid') throw new Error('Invalid Token');

    return { response: data };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const postResetPassword = async ({
  email,
  password,
  token,
}) => {
  try {
    const response = await httpClient.post('/auth/password/confirm', {
      email,
      password: await encrypt(password),
      token,
    });

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};
