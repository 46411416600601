import { Table } from 'core/components';
import type { Log, LogNode, LogsQueryParams } from './types';
import { formatDate, formatTime } from 'lib/datetime';
// @ts-ignore
import { isNull } from 'lib/javascript';
import { getUserFullName, User } from 'lib/users';
import { Link, useHistory } from 'react-router-dom';
import { routePaths } from 'appConfig';

interface LogTableProps extends LogsQueryParams {
  data: Array<Log>;
  isLoading?: boolean;
  totalSize?: number;
  onTableChange?: (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => Promise<void>;
}

const LogTable = ({
  data,
  isLoading,
  page,
  perPage,
  totalSize,
  onTableChange,
}: LogTableProps) => {
  const history = useHistory();

  const onClickUser = (userId: string) => {
    history.push(`${routePaths.USERS}/${userId}`);
  };
  const columns = [
    {
      dataField: 'id',
      text: '#',
      formatter: (_id: string, _log: Log, index: number) => {
        const currentRowNumber = index;
        const _page = Number(page) || 1;
        const _perPage = Number(perPage) || 15;
        const _totalSize = Number(totalSize) || 0;
        const offset = (_page - 1) * _perPage;
        const _offset = offset > _totalSize ? _totalSize : offset;

        const reversedIndex = _totalSize - _offset - currentRowNumber;

        return <div className="number">{reversedIndex}</div>;
      },
    },
    {
      dataField: 'action_type',
      text: 'Log Description',
    },
    {
      dataField: 'nodes',
      text: 'Changelog',
      formatter: (nodes: LogNode[]) => (
        <>
          {nodes.map((node) => (
            <p>
              <strong>{node.field_display_name}</strong>:{' '}
              <span>
                {!isNull(node.from) ? (
                  // @ts-ignore
                  <strike>{node.from.display_value}</strike>
                ) : (
                  // @ts-ignore
                  <strike className="text-color-mute text-monospace">{`<Empty>`}</strike>
                )}{' '}
                {node.to?.display_value}
              </span>
            </p>
          ))}
        </>
      ),
    },
    {
      dataField: 'actor',
      text: 'Action By',
      formatter: (actor: User) => {
        const displayedInfo = isNull(actor)
          ? 'System'
          : `${getUserFullName(actor)} <${actor.email}>`;

        return isNull(actor) ? (
          <span>{displayedInfo}</span>
        ) : (
          <Link to={`${routePaths.USERS}/${actor.user_id}`}>
            {displayedInfo}
          </Link>
        );
      },
    },
    {
      dataField: 'timestamp',
      text: 'Changed At',
      formatter: (timestamp: string) => (
        <div>
          <p>
            <span>{formatDate(timestamp)}</span>
            &nbsp;
            <span>{formatTime(timestamp)}</span>
          </p>
        </div>
      ),
    },
  ];

  return (
    <Table
      remote
      loading={isLoading}
      keyField="id"
      data={data}
      columns={columns}
      pagination={{ page, sizePerPage: perPage, totalSize }}
      onTableChange={onTableChange}
    />
  );
};

export default LogTable;
