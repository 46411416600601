import config from 'appConfig';
import { Button, DatePicker } from 'components/Common';
import { SelectField, TextField } from 'components/formFields';
import {
  ComplaintCategoryAutoComplete,
  ComplaintInput,
  ComplaintPayload,
  ComplaintSchema,
  KitchenImagePreview,
} from 'lib/complaint';
import datetime from 'lib/datetime';
import {
  connectInput,
  Form,
  FormManager,
  GeneralError,
} from 'lib/form';
import { ISO_DATE_FORMAT } from 'lib/kitchen';
import { ASSET_KIND, MediaInput } from 'lib/media';
import validator from 'lib/validator';
import { Col, Row } from 'reactstrap';

import { USER_ROLE } from 'lib/users/constants';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRoleUser } from 'store/auth/GetRoleUser/actions';
import {
  DISPLAY_KITCHEN_MAIN,
  DISPLAY_KITCHEN_OTHER,
  getComplaintPayload,
} from './utils';
import { MediaInputOffline } from 'lib/media/Offline';
import { RootState } from 'store/types';

interface ComplaintFormInterface {
  showCancel?: boolean;
  onSubmit: (
    values: ComplaintPayload,
    attachments: File[] | null,
  ) => Promise<void>;
  onCancel?: () => void;
  hideAttachments?: boolean;
  showComplaintModalAttachments?: boolean;
  isDisabled?: boolean;
  data?: ComplaintInput;
  mutate: () => void;
}

const MediaField = connectInput(MediaInput, {
  onChangeArgType: 'raw-value',
});

const DatePickerField = connectInput(DatePicker, {
  onChangeArgType: 'raw-value',
});

const ComplaintCategorySelectorField = connectInput(
  ComplaintCategoryAutoComplete,
  {
    onChangeArgType: 'raw-value',
  },
);

const validationSchema = validator.object({
  complaint_categories: validator.array(validator.string()),
  kitchen_representative: validator.string().required(),
  reported_date: validator.string().required(),
  reported_time: validator.string().required(),
  feedback: validator.string().required(),
  attachments: validator
    .array()
    .of(
      validator.object().shape({
        file: validator.mixed().required(),
        url: validator.string().required(),
      }),
    )
    .max(3),
});

const MediaOffline = connectInput(MediaInputOffline, {
  onChangeArgType: 'raw-value',
});

const ComplaintForm = ({
  showCancel,
  onCancel,
  onSubmit,
  hideAttachments,
  showComplaintModalAttachments,
  isDisabled,
  data,
  mutate,
}: ComplaintFormInterface) => {
  const dispatch = useDispatch();
  const isKitchenRepresentative = useSelector(
    (state: RootState) => state.role.role?.isKitchenRepresentative,
  );

  useEffect(() => {
    dispatch(getRoleUser());
  }, [dispatch]);

  const _isDisabled = false;

  const {
    complaint_categories,
    feedback,
    kitchen_representative,
    reported_at,
    id,
    assets,
    is_verified,
  } = data || {};

  const defaultValues: ComplaintSchema = {
    id: id || '',
    complaint_categories: complaint_categories || [],
    kitchen_representative: kitchen_representative || '',
    reported_date:
      datetime
        .utc(reported_at)
        .tz(config.timezone)
        .format(ISO_DATE_FORMAT) ||
      datetime().tz(config.timezone).format(ISO_DATE_FORMAT),
    reported_time:
      datetime.utc(reported_at).tz(config.timezone).format('HH:mm') ||
      '',
    feedback: feedback || '',
    assets: data?.assets || [],
  };

  const handleOnSubmit = async (values: ComplaintSchema) => {
    const payload = getComplaintPayload(values);
    const attachments =
      values.attachments?.map((asset) => asset.file) || null;
    await onSubmit(payload, attachments);
  };

  const initialValues = {
    ...defaultValues,
    assets,
  };

  const handleFieldChange = (field: string, value: any) => {
    if (field === 'assets') {
      mutate();
    }
  };

  return (
    <FormManager
      initialValues={initialValues}
      validateOnBlur
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
      enableReinitialize={false}
    >
      {({ isSubmitting, values, setFieldValue, resetForm }) => {
        const handleCancel = async () => {
          if (onCancel) {
            resetForm(values);
            await mutate();
            setFieldValue('assets', assets);
            onCancel();
          }
        };

        return (
          <Form autoComplete="off">
            <GeneralError />
            <ComplaintCategorySelectorField
              name="complaint_categories"
              disabled={isDisabled}
              label="Category"
              // @ts-ignore
              onChange={(value) => {
                setFieldValue('complaint_categories', value);
              }}
              isMulti
              helperText={undefined}
            />
            <SelectField
              name="kitchen_representative"
              label="Kitchen"
              disabled={isDisabled || _isDisabled}
              required
              options={[
                {
                  values: '',
                  label: 'Select Kitchen',
                },
                {
                  value: USER_ROLE.KITCHEN_REPRESENTATIVE_MAIN,
                  label: DISPLAY_KITCHEN_MAIN,
                },
                {
                  value: USER_ROLE.KITCHEN_REPRESENTATIVE_OTHER,
                  label: DISPLAY_KITCHEN_OTHER,
                },
              ]}
              onChange={undefined}
              helperText={undefined}
            />
            <Row>
              <Col md={3}>
                <DatePickerField
                  name="reported_date"
                  label="Reported Date"
                  disabled={isDisabled || _isDisabled}
                  block
                  outputFormat={(output) =>
                    datetime(output).endOf('day').toISOString()
                  }
                  required
                  helperText={undefined}
                  onChange={(e) => {
                    setFieldValue('reported_date', e);
                  }}
                />
              </Col>
              <Col md={3}>
                <TextField
                  name="reported_time"
                  label="Reported Time"
                  disabled={isDisabled || _isDisabled}
                  defaultValue={values.reported_time}
                  required
                  type="time"
                  rows={5}
                  onChange={undefined}
                  helperText={undefined}
                />
              </Col>
            </Row>

            <TextField
              name="feedback"
              disabled={isDisabled || _isDisabled}
              label="Feedback"
              required
              type="textarea"
              rows={5}
              onChange={undefined}
              helperText={undefined}
            />

            {!isKitchenRepresentative &&
              !hideAttachments &&
              !isDisabled &&
              !is_verified && (
                // @ts-ignore
                <MediaField
                  name="assets"
                  label="Image"
                  parent="complaint"
                  parentId={id || ''}
                  assetKind={ASSET_KIND.IMAGE}
                  onChange={(value) =>
                    handleFieldChange('assets', value)
                  }
                  allowMultiple
                  maxFiles={3}
                />
              )}
            {/* @ts-ignore */}
            {showComplaintModalAttachments && (
              // @ts-ignore
              <MediaOffline
                name="attachments"
                label="Images"
                assetKind={ASSET_KIND.IMAGE}
                onChange={(value) =>
                  setFieldValue('attachments', value)
                }
                allowMultiple
                maxFiles={3}
                noContainer
              />
            )}

            {!hideAttachments &&
              (isKitchenRepresentative ||
                isDisabled ||
                is_verified) && (
                <KitchenImagePreview assets={assets || []} />
              )}

            <Row>
              <Col>
                {!isDisabled && (
                  <div className="d-flex justify-content-end">
                    {showCancel && (
                      <Button
                        type="button"
                        variant="outlined"
                        color="dark"
                        onClick={handleCancel}
                        disabled={isSubmitting}
                        shape={undefined}
                        className={'mr-2'}
                        loading={undefined}
                        block={undefined}
                        renderLoading={undefined}
                      >
                        <span>Cancel</span>
                      </Button>
                    )}
                    <Button
                      type="submit"
                      className={`${
                        showCancel && 'ml-2'
                      } float-right ${isDisabled ? 'd-none' : ''}`}
                      loading={isSubmitting}
                      disabled={isSubmitting || isDisabled}
                      variant={undefined}
                      color={undefined}
                      shape={undefined}
                      block={undefined}
                      renderLoading={undefined}
                    >
                      {data?.id ? 'Save' : 'Add Feedback'}
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        );
      }}
    </FormManager>
  );
};

export default ComplaintForm;
