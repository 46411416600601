import React from 'react';
import { FormGroup } from 'reactstrap';
import Label from './Label';
import PropTypes from 'prop-types';

const ReadOnly = ({ name, label, value, render }) => (
  <FormGroup>
    <Label htmlFor={name}>{label}</Label>
    <div>{render(value)}</div>
  </FormGroup>
);

ReadOnly.propTypes = {
  render: PropTypes.func,
};

ReadOnly.defaultProps = {
  render: (value) => value,
};

export default ReadOnly;
