import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const putUpdateProductVariantNutrition = async (
  variantId,
  nutrition,
) => {
  try {
    const response = await httpClient.put(
      `/cms/products/variants/${variantId}/nutrition`,
      nutrition,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
