import { Button } from 'components/Common';
import { useState } from 'react';
import { Col, Row } from 'reactstrap';
import CollectionPointSlotLines from './CollectionPointSlotLines';
import CollectionPointSlotModal from './CollectionPointSlotModal';
import { DEFAULT_TIMEFRAME } from './constants';
import type { CollectionPoint, CollectionPointSlotLine } from './types';
import useCollectionPointSlots from './useCollectionPointSlots';
import { formatDateTime, getDayHourFromSlot } from './utils';

interface CollectionPointSlotsProps {
  collectionPoint: CollectionPoint;
  mutate: any;
}

const CollectionPointSlots = ({ collectionPoint, mutate }: CollectionPointSlotsProps) => {
  const { slots } = collectionPoint;

  const { create, update, deleteSlot } = useCollectionPointSlots(collectionPoint.id);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const onCreate = async (line: CollectionPointSlotLine) => {
    const { day, hour } = line;
    const slotDateTime = formatDateTime({
      day: parseInt(day),
      hour: parseInt(hour),
    });

    try {
      await create({
        slot: slotDateTime,
        timeframe: DEFAULT_TIMEFRAME,
      });
      mutate();
    } catch (err) {
      throw err;
    }
  };

  const onUpdate = async (line: CollectionPointSlotLine) => {
    try {
      const { id } = line;

      const { day, hour } = line;
      const slotDateTime = formatDateTime({
        day: parseInt(day),
        hour: parseInt(hour),
      });

      await update(id, { slot: slotDateTime, timeframe: DEFAULT_TIMEFRAME });
      mutate();
    } catch (err) {
      throw err;
    }
  };

  const onDelete = async (slotId: string) => {
    try {
      await deleteSlot(slotId);
      mutate();
    } catch (err) {
      throw err;
    }
  };

  const renderSlots = () => {
    if (!slots.length) {
      return (
        <p className="text-center">
          No Collection Slot yet. Please add more Collection Slot.
        </p>
      )
    }

    const lines: Array<CollectionPointSlotLine> = slots.map(({ id, slot }) => {
      const { day, hour } = getDayHourFromSlot(slot);
      return { id, day, hour };
    });

    return (
      <CollectionPointSlotLines
        lines={lines}
        onUpdate={onUpdate}
        onDelete={onDelete}
      />
    );
  };

  return (
    <>
      <Row>
        <Col>{renderSlots()}</Col>
      </Row>
      <Row>
        <Col xs={12} className="d-flex">
          {/* @ts-expect-error */}
          <Button
            color="info"
            className="flex-fill"
            onClick={() => setIsOpenModal(true)}
          >
            Add Collection Slot
          </Button>
        </Col>
      </Row>
      <CollectionPointSlotModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        title="Add New Collection Slot"
        buttonLabel="Add"
        onSubmit={onCreate}
      />
    </>
  )
};

export default CollectionPointSlots;
