import localStorage from './localStorage';
import sessionStorage from './sessionStorage';
import syncedSessionStorage from './syncedSessionStorage';

const webStorage = () => {
  return {
    local: localStorage,
    session: sessionStorage,
    syncedSession: syncedSessionStorage,
  };
};

export default webStorage();
