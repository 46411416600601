import { Page, Tab, Tabs } from 'components/Common';
import { IngredientReportForm, ProductReportForm } from 'lib/kitchen';
import { useRouter } from 'lib/router';
import { Card, CardBody } from 'reactstrap';

enum KITCHEN_REPORT_TYPE {
  PRODUCT = 'product',
  INGREDIENT = 'ingredient',
}

const KitchenReportProduct = () => {
  const { query, updateQueryParams } = useRouter();
  const { tab } = query;
  return (
    <Page
      content={
        <Card>
          <CardBody>
            <Tabs
              activeKey={tab as string}
              defaultActiveKey={KITCHEN_REPORT_TYPE.PRODUCT}
              onSelect={(tab) => updateQueryParams({ tab })}
            >
              <Tab
                title="Product"
                eventKey={KITCHEN_REPORT_TYPE.PRODUCT}
              >
                <ProductReportForm />
              </Tab>
              <Tab
                title="Ingredient"
                eventKey={KITCHEN_REPORT_TYPE.INGREDIENT}
              >
                <IngredientReportForm />
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      }
    />
  );
};

export default KitchenReportProduct;
