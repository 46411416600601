// @ts-nocheck
// fix: is missing the following properties
import { TextField } from 'components/formFields';
import { Col, Row } from 'react-bootstrap';

interface Props {
  name: string;
  hasUnitNumber?: boolean;
}

const CollectionPointAddressFields = ({ name }: Props) => {
  const makeNestedFieldName = (
    fieldName: string
  ): string => `${name}.${fieldName}`;

  return (
    <>
      <Row>
        <Col sm={6}>
          <TextField
            name={makeNestedFieldName('first_name')}
            label="First Name"
            required
          />
        </Col>
        <Col sm={6}>
          <TextField
            name={makeNestedFieldName('last_name')}
            label="Last Name"
            required
          />
        </Col>
        <Col xs={12}>
          <TextField
            name={makeNestedFieldName('phone_number')}
            label="Contact Number"
            required
          />
        </Col>
        <Col sm={6}>
          <TextField
            name={makeNestedFieldName('postal_code')}
            label="Postal Code"
            required
          />
        </Col>
        <Col sm={6}>
          <TextField
            name={makeNestedFieldName('address_1')}
            label="Block No. & Street"
            required
          />
        </Col>
        <Col sm={6}>
          <TextField
            name={makeNestedFieldName('address_2')}
            label="Building Name"
          />
        </Col>
        <Col sm={6}>
          <TextField
            name={makeNestedFieldName('address_3')}
            label="Unit No."
          />
        </Col>
      </Row>
    </>
  )
}

export default CollectionPointAddressFields
