import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Common';
import { NullableTextField } from 'components/formFields';
import { merge, mapValues } from 'lib/javascript';
import { FormManager, Form } from 'lib/form';
import validator from 'lib/validator';
import notification from 'lib/notification';
import {
  MacroFields,
  macroValidationSchema,
  macroInitialValues,
} from 'lib/nutrition';
import { putUpdateIngredientNutrition } from './api';
import { isNil } from 'lib/javascript';

const IngredientNutritionForm = ({
  ingredientId,
  initialValues,
  onSubmit,
}) => {
  const defaultValues = {
    constant: macroInitialValues,
    proportional: macroInitialValues,
    base_portion: 0,
  };

  const requiredFields = {
    calorie: true,
    protein: true,
    fat: true,
    carb: true,
    sodium: true,
  };

  const validationSchema = validator.object({
    constant: macroValidationSchema(),
    proportional: macroValidationSchema(requiredFields),
    base_portion: validator.number().nullable().required(),
  });

  const handleSubmit = async (formValues, actions) => {
    try {
      actions.setStatus({
        error: null,
      });

      const floatConstant = mapValues(
        formValues.constant,
        (value) => {
          return !isNil(value) ? Math.round(value * 100) / 100 : null;
        },
      );

      const floatBasePortion =
        Math.round(formValues.base_portion * 100) / 100;

      const floatProportional = mapValues(
        formValues.proportional,
        (value) => {
          return !isNil(value) ? Math.round(value * 100) / 100 : null;
        },
      );

      const updatedNutrition = await putUpdateIngredientNutrition(
        ingredientId,
        {
          ...formValues,
          constant: floatConstant,
          base_portion: floatBasePortion,
          proportional: floatProportional,
        },
      );

      notification.success('Successfully updated nutritional values');

      onSubmit(null, updatedNutrition);
    } catch (err) {
      actions.setStatus({ error: Object.values(err?.errors)?.[0] });
      actions.setFieldError(Object.keys(err?.errors)?.[0]);

      notification.error(
        'Something went wrong when updating nutritional values',
      );

      onSubmit(err);
    }
  };

  return (
    <FormManager
      initialValues={merge({}, defaultValues, initialValues)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <section>
            <h4>Constant</h4>
            <MacroFields name="constant" />
          </section>

          <section style={{ marginTop: '12px' }}>
            <h4>Proportional</h4>
            <NullableTextField
              name="base_portion"
              label="For Portion"
              type="number"
              required
            />
            <MacroFields
              name="proportional"
              requiredFields={Object.keys(requiredFields)}
            />
          </section>

          <Button type="submit" loading={isSubmitting}>
            Update Nutritional Values
          </Button>
        </Form>
      )}
    </FormManager>
  );
};

IngredientNutritionForm.propTypes = {
  ingredientId: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

IngredientNutritionForm.defaultProps = {
  initialValues: {},
  onSubmit: () => {},
};

export default IngredientNutritionForm;
