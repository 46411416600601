import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import yup from 'lib/validator';
import { FormManager, Form, connectInput } from 'lib/form';
import {
  AddressFormFields,
  COUNTRIES,
  findDefaultBillingAddress,
  findDefaultShippingAddress,
  isBillingAddressAndShippingAddressSame,
} from 'lib/address';
import { merge } from 'lib/javascript';
import { useDeliverySlotsContext } from 'lib/operation';
import { Modal, Button } from 'components/Common';
import { CheckboxField } from 'components/formFields';

import { UserSelector } from '../../users';
import datetime from 'lib/datetime';

const UserSelectorField = connectInput(UserSelector, {
  onChangeArgType: 'raw-value',
});

const validationSchema = yup.object({
  user: yup.object().required(),
  shipping: yup
    .object({
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      company_name: yup.string().nullable(),
      phone_number: yup.string().required(),
      country: yup.string().required(),
      postal_code: yup.string().length(6).required(),
      address_1: yup.string().required(),
      address_2: yup.string().nullable(),
    })
    .required(),
  billing: yup
    .object()
    .when(
      'same_for_billing_address',
      (sameForBillingAddresss, schema) => {
        if (!sameForBillingAddresss)
          return schema.shape({
            first_name: yup.string().required(),
            last_name: yup.string().required(),
            company_name: yup.string().nullable(),
            phone_number: yup.string().required(),
            country: yup.string().required(),
            postal_code: yup.string().length(6).required(),
            address_1: yup.string().required(),
            address_2: yup.string().nullable(),
          });

        return schema;
      },
    ),
  same_for_billing_address: yup.bool().required(),
});

const defaultValues = {
  user: null,
  shipping: {
    first_name: '',
    last_name: '',
    company_name: '',
    phone_number: '',
    country: COUNTRIES.SINGAPORE,
    postal_code: '',
    address_1: '',
    address_2: '',
  },
  billing: {
    first_name: '',
    last_name: '',
    company_name: '',
    phone_number: '',
    country: COUNTRIES.SINGAPORE,
    postal_code: '',
    address_1: '',
    address_2: '',
  },
  same_for_billing_address: true,
};

const NewOrderModal = ({
  isOpen,
  onClose,
  title,
  buttonTitle,
  onSubmit,
}) => {
  const { isLoading, nextDeliverySlot } = useDeliverySlotsContext();

  const setAddressFields = async (user, setFieldValue) => {
    const { addresses } = user;

    const shippingAddress = findDefaultShippingAddress(addresses);
    const billingAddress = findDefaultBillingAddress(addresses);

    setFieldValue('shipping', shippingAddress);

    setFieldValue('billing', billingAddress);

    const isSame = isBillingAddressAndShippingAddressSame(
      billingAddress,
      shippingAddress,
    );

    setFieldValue('same_for_billing_address', isSame);
  };

  const handleOnSubmit = async (formValues, actions) => {
    const {
      same_for_billing_address,
      shipping,
      billing,
      ...otherFormValues
    } = formValues;

    const newOrderValues = {
      ...otherFormValues,
      addresses: {
        shipping,
        billing: same_for_billing_address ? shipping : billing,
      },
    };

    const defaultOrderValues = {
      has_utensil: false,
      delivery_time: nextDeliverySlot || datetime(), //TODO add proper date time with disabled/blocked check
      notes: '-',
    };

    const values = merge({}, defaultOrderValues, newOrderValues);

    return await onSubmit(values).catch((error) => {
      if (!error.errors) return;

      const { addresses = {}, ...others } = error.errors;

      actions.setErrors({ ...addresses, ...others });
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={title}
      width={'75%'}
    >
      {!isLoading && isOpen && (
        <FormManager
          initialValues={defaultValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ setFieldValue, resetForm, values, isSubmitting }) => {
            const sameForBillingAddress =
              values['same_for_billing_address'];

            return (
              <Form>
                <Row>
                  <Col>
                    <UserSelectorField
                      name="user"
                      required
                      label="Customer"
                      onChange={(user) => {
                        // resetForm over setFieldValue because want to reset previously set addresses
                        // before loading addresses of the new user
                        resetForm({
                          values: { ...defaultValues, user },
                        });

                        setAddressFields(user, setFieldValue);
                      }}
                    />
                  </Col>
                </Row>

                {values?.user && (
                  <Row>
                    <Col md={12}>
                      <h5>Recipient Details</h5>
                      <AddressFormFields name="shipping" />
                    </Col>

                    <Col md={12}>
                      <CheckboxField
                        name="same_for_billing_address"
                        label="Use the same for billing details"
                      />
                    </Col>

                    {!sameForBillingAddress && (
                      <Col md={12}>
                        <h5>Billing Details</h5>
                        <AddressFormFields name="billing" />
                      </Col>
                    )}
                  </Row>
                )}

                <Button
                  loading={isSubmitting}
                  type="submit"
                  className="mt-4"
                >
                  {buttonTitle}
                </Button>
              </Form>
            );
          }}
        </FormManager>
      )}
    </Modal>
  );
};

NewOrderModal.propTypes = {
  onSubmit: PropTypes.func,
};

NewOrderModal.defaultProps = {
  onSubmit: () => {},
};

export default NewOrderModal;
