import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/Common';
import { isEmpty } from 'lib/javascript';

import MediaUploadInput from '../MediaUploadInput';
import MediaGallery from '../MediaGallery';
import { Asset } from '../typedef';

const MediaPickerModal = ({
  open,
  onClose,
  parent,
  parentId,
  onProcessFile,
  allowMultiple,
  maxFiles,
  availableAssets,
  noContainer,
}) => {
  const [files, setFiles] = React.useState([]);
  const [error, setError] = React.useState(null);

  const handleOnClose = () => {
    setError(null);
    onClose();
  };

  const handleOnProcessFiles = () => {
    // empty uploader and close dialog when all files have been uploaded
    setFiles([]);
    if (open) handleOnClose();
  };

  const handleUploadInputError = (error) => {
    const { body } = error;

    setError(body);
  };

  const handleUploadInputWarning = (warning) => {
    const { type, body } = warning;

    if (type === 'warning' && body === 'Max files')
      setError(`Please upload no more than ${maxFiles} images. `);
    else setError(body);
  };

  return (
    <Modal
      container={
        noContainer
          ? undefined
          : () =>
              document &&
              document.getElementById('media-picker-modal')
      }
      open={open}
      unmountOnClose={false}
      onClose={handleOnClose}
      title="Upload Image"
      content={
        <div>
          <MediaUploadInput
            name="image"
            parent={parent}
            parentId={parentId}
            value={files}
            onChange={setFiles}
            onError={handleUploadInputError}
            onWarning={handleUploadInputWarning}
            onProcessFile={onProcessFile}
            onProcessFiles={handleOnProcessFiles}
            allowMultiple={allowMultiple}
            maxFiles={maxFiles}
            instantUpload
          />
          <p style={{ color: 'red' }}>{error}</p>

          {!isEmpty(availableAssets) && (
            <div style={{ paddingTop: '24px' }}>
              <h6>Or Choose from uploaded medias:</h6>

              <div style={{ paddingTop: '12px' }}>
                <MediaGallery
                  assets={availableAssets}
                  isAssetSelectable
                  onAssetSelect={(asset) => {
                    onProcessFile(null, asset);
                    onClose();
                  }}
                />
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};

MediaPickerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  parent: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  onProcessFile: PropTypes.func,
  allowMultiple: PropTypes.bool,
  maxFiles: PropTypes.number,
  availableAssets: PropTypes.arrayOf(Asset),
};

MediaPickerModal.defaultProps = {
  availableAssets: [],
};

export default MediaPickerModal;
