import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'components/Common';

import * as typedef from '../typedef';
import { makeProductVariantLabel } from '../utils';

const ProductVariantPicker = ({
  name,
  label,
  required,
  productOptions,
  productVariants,
  value,
  onChange,
}) => {
  const _value = value?.id;

  const _onChange = (e) => {
    const newVariantId = e.target.value;
    const newVariant = productVariants.find(
      (variant) => variant.id === newVariantId,
    );
    onChange(newVariant);
  };

  const options = productVariants.map((variant) => {
    const isVariantAvailable = variant.stock > 0;

    return {
      label: makeProductVariantLabel(variant, productOptions),
      value: variant.id,
      disabled: !isVariantAvailable,
    };
  });

  return (
    <Select
      name={name}
      label={label}
      required={required}
      value={_value}
      onChange={_onChange}
      options={options}
    />
  );
};

ProductVariantPicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  productOptions: PropTypes.objectOf(typedef.ProductOption)
    .isRequired,
  productVariants: PropTypes.arrayOf(typedef.ProductVariant)
    .isRequired,
  /** slugified option label as key, selected option value as value */
  value: typedef.ProductVariant,
  onChange: PropTypes.func,
};

ProductVariantPicker.defaultProps = {
  productVariants: [],
  value: {},
  onChange: () => {},
};

export default ProductVariantPicker;
