import { Page } from 'components/Common';
import {
  ComplaintCategoryForm,
  ComplaintCategoryPayload,
  useComplaintCategory,
} from 'lib/complaint-categories';
import { useRouter } from 'lib/router';
import { List } from 'react-content-loader';
import { routePaths } from 'appConfig';
// @ts-ignore-line
import { pick } from 'lib/javascript';
import { LogTable, useLogs } from 'lib/log';
import notification from 'lib/notification';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';

const ComplaintCategoryDetailPage = () => {
  const history = useHistory();
  const {
    complaintCategoryId,
  }: { complaintCategoryId: string } = useParams();
  const { query, updateQueryParams } = useRouter();

  const {
    complaintCategory,
    isLoading,
    updateComplaintCategory,
  } = useComplaintCategory(complaintCategoryId);

  const { page = 1, sizePerPage = 15 } = query;

  const logsUrl = `/cms/complaint_categories/${complaintCategoryId}/logs`;

  const { logs, isLoading: isFetchingLogs, total } = useLogs({
    url: logsUrl,
    params: { page, perPage: sizePerPage },
  });

  const handleOnSubmit = async (
    payload: ComplaintCategoryPayload,
  ) => {
    try {
      const newPayload = pick(payload, ['name']);
      await updateComplaintCategory(newPayload);
      history.replace(routePaths.COMPLAINT_CATEGORIES);
      notification.success('Feedback category updated.');
    } catch (error) {
      notification.error(
        'Something went wrong while updating feedback category.',
      );
    }
  };

  const onTableChange = async (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => {
    switch (action) {
      case 'pagination':
        const { page: _page, sizePerPage } = newState;
        updateQueryParams({
          page: _page,
          sizePerPage,
        });
        break;

      default:
        break;
    }
  };

  return (
    <Page
      content={
        <>
          {isLoading ? (
            <List uniqueKey="complaint-category-detail-loading" />
          ) : (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div className="mb-5">
                      <ComplaintCategoryForm
                        complaintCategory={complaintCategory}
                        onSubmit={handleOnSubmit}
                        showCancel={false}
                      />
                    </div>
                    <h5 className="mb-4">Feedback Category Log</h5>
                    <LogTable
                      data={logs}
                      isLoading={isFetchingLogs}
                      onTableChange={onTableChange}
                      totalSize={total}
                      page={parseInt(page?.toString() ?? '1')}
                      perPage={sizePerPage}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </>
      }
    />
  );
};

export default ComplaintCategoryDetailPage;
