import { getKitchenReport } from './api';
import { KitchenProductPayload, ReportCategory } from './types';

const useKitchenReport = (reportCategory: ReportCategory) => {
  const requestKitchenReport = async (body: KitchenProductPayload) => {
    return await getKitchenReport(body, reportCategory);
  };

  return { requestKitchenReport };
};

export default useKitchenReport;
