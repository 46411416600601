import ReadOnly from 'components/Common/ReadOnly';
import { Field } from 'lib/form';
import React from 'react';
import PropTypes from 'prop-types';

const ReadOnlyField = ({ name, label, render }) => (
  <Field name={name}>
    {({ field }) => (
      <ReadOnly
        name={name}
        label={label}
        value={field.value}
        render={render}
      />
    )}
  </Field>
);

ReadOnlyField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  render: PropTypes.func,
};

ReadOnlyField.defaultProps = {
  name: '',
  label: '',
  render: (value) => value,
};

export default ReadOnlyField;
