import { Page } from 'components/Common';
import {
  LoveMessageForm,
  LoveMessagePayload,
  useLoveMessage,
} from 'lib/love-message';
import notification from 'lib/notification';
import { List } from 'react-content-loader';
import { useParams, useHistory } from 'react-router';
import { Card, CardBody, Col, Row } from 'reactstrap';
// @ts-ignore-line
import { pick } from 'lib/javascript';
import { routePaths } from 'appConfig';
import { useRouter } from 'lib/router';
import { LogTable, useLogs } from 'lib/log';

const LoveMessageDetailPage = () => {
  const history = useHistory();
  const { loveMessageId }: { loveMessageId: string } = useParams();
  const { query, updateQueryParams } = useRouter();

  const {
    loveMessage,
    isLoading,
    updateLoveMessage,
  } = useLoveMessage(loveMessageId);

  const { page = 1, sizePerPage = 15 } = query;

  const logsUrl = `/cms/love_messages/${loveMessageId}/logs`;
  const { logs, isLoading: isFetchingLogs, total } = useLogs({
    url: logsUrl,
    params: { page, perPage: sizePerPage },
  });

  const handleOnSubmit = async (payload: LoveMessagePayload) => {
    try {
      const newPayload = pick(payload, ['content']);
      await updateLoveMessage(newPayload);
      history.replace(routePaths.LOVE_MESSAGES);
      notification.success('Love message updated.');
    } catch (error) {
      notification.error(
        'Something went wrong while updating love message.',
      );
    }
  };

  const onTableChange = async (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => {
    switch (action) {
      case 'pagination':
        const { page: _page, sizePerPage } = newState;
        updateQueryParams({
          page: _page,
          sizePerPage,
        });
        break;

      default:
        break;
    }
  };

  return (
    <Page
      content={
        <>
          {isLoading ? (
            <List uniqueKey="love-messages-detail-loading" />
          ) : (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div className="mb-5">
                      <LoveMessageForm
                        onSubmit={handleOnSubmit}
                        loveMessage={loveMessage}
                        showCancel={false}
                      />
                    </div>
                    <h5 className="mb-4">Love Message Log</h5>
                    <LogTable
                      data={logs}
                      isLoading={isFetchingLogs}
                      onTableChange={onTableChange}
                      totalSize={total}
                      page={parseInt(page?.toString() ?? '1')}
                      perPage={sizePerPage}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </>
      }
    />
  );
};

export default LoveMessageDetailPage;
