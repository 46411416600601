import config from 'appConfig';
import datetime from './datetime';

export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATETIME_FORMAT = 'DD-MM-YYYY, hh:mm a';
export const TIME_FORMAT = 'hh:mm a';

export const formatDate = (parsableDatetime, timezone = config.timezone) =>
  datetime(parsableDatetime).tz(timezone).format(DATE_FORMAT);

export const formatDatetime = (parsableDatetime, timezone = config.timezone) =>
  datetime(parsableDatetime).tz(timezone).format(DATETIME_FORMAT);

export const formatTime = (parsableDatetime, timezone = config.timezone) =>
  datetime(parsableDatetime).tz(timezone).format(TIME_FORMAT);

/**
 * Get all days by day number between a given date range e.g. get all Tuesdays between 1/3/2021 to 1/4/2021
 * @param {Object} arguments
 * @param {string} arguments.start ISO date string for start date
 * @param {string} arguments.end  ISO date string for end date
 * @param {number} arguments.day Day number for the queried day, Sunday -> 0, Monday -> 1 and so on.
 * @returns {Object[]} An array of datetime instances
 */
export const getDaysBetween = ({ start, end, day }) => {
  const result = [];

  const parsedStart = datetime(start);
  const parsedEnd = datetime(end);

  const firstDayInWeek = parsedStart.day(day);

  let current = firstDayInWeek.isBefore(parsedStart, 'day')
    ? firstDayInWeek.add(7, 'days')
    : firstDayInWeek;

  while (
    current.isBefore(parsedEnd, 'day') ||
    current.isSame(parsedEnd, 'day')
  ) {
    result.push(datetime(current));
    current = current.add(7, 'days');
  }

  return result;
};

export const setTimeToDate = (date, time) => {
  const { years, months, day } = datetime(date).toObject();
  const { hours, minutes, seconds, milliseconds } = datetime(
    time,
  ).toObject();

  return datetime({
    years,
    months,
    day,
    hours,
    minutes,
    seconds,
    milliseconds,
  });
};

export const sortDateTimesAsc = (dateTimes) =>
  dateTimes.sort((a, b) => (a.isAfter(b) ? 1 : -1));

export const sortDateTimesDesc = (dateTimes) =>
  dateTimes.sort((a, b) => (b.isAfter(a) ? 1 : -1));
