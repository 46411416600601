import React from 'react';
import PropTypes from 'prop-types';

import Table, { textFilter } from 'core/components/Table';
import { Datetime } from 'lib/datetime';
import { Link } from 'lib/router';

import { routePaths } from 'appConfig';
import { CouponPropType } from './typedef';
import { getLabelByIsPercent } from './utils';
import CouponType from './CouponType';
import CouponUsage from './CouponUsage';

const CouponsTable = ({
  loading,
  data,
  page,
  sizePerPage,
  totalSize,
  onTableChange,
  sortField,
  sortDir,
}) => {
  const columns = [
    {
      dataField: 'coupon_code',
      text: 'Code',
      formatter: (couponCode) => (
        <Link to={`${routePaths.COUPONS}/${couponCode}`}>
          {couponCode}
        </Link>
      ),
      filter: textFilter({
        delay: 1500,
      }),
    },
    {
      dataField: 'description',
      text: 'Coupon Type',
      formatter: (_description, coupon) => (
        <CouponType coupon={coupon} />
      ),
    },
    {
      dataField: 'is_percent',
      text: 'Discount Type',
      formatter: (isPercent) => getLabelByIsPercent(isPercent),
    },
    {
      dataField: 'discount_value',
      text: 'Discount Amount',
    },
    {
      dataField: '',
      text: 'Usage / Limit',
      formatter: (_, coupon) => <CouponUsage coupon={coupon} />,
    },
    {
      dataField: 'end_at',
      text: 'Valid Until',
      formatter: (endAt) => <Datetime value={endAt} />,
    },
  ];

  return (
    <Table
      remote
      loading={loading}
      keyField="id"
      columns={columns}
      data={data}
      pagination={{ page, sizePerPage, totalSize }}
      onTableChange={onTableChange}
      sort={{ dataField: sortField, order: sortDir }}
    />
  );
};

CouponsTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(CouponPropType).isRequired,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  sortField: PropTypes.string,
  sortDir: PropTypes.string,
};

CouponsTable.defaultProps = {
  loading: false,
};

export default CouponsTable;
