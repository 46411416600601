import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Common';
import { FormManager, Form } from 'lib/form';
import validator from 'lib/validator';
import { SelectField } from 'components/formFields';

import { refundOrder } from './api';
import { REFUND_REASON, LABEL_BY_REFUND_REASON } from './constants';
import useOrder from './useOrder';
import notification from 'lib/notification';

const refundReasonOptions = Object.values(REFUND_REASON).map(
  (refundReason) => ({
    value: refundReason,
    label: LABEL_BY_REFUND_REASON[refundReason],
  }),
);

const RefundOrderForm = ({ orderId, onSubmit }) => {
  const { mutate } = useOrder(orderId);

  const initialValues = {
    reason: REFUND_REASON.REQUESTED_BY_CUSTOMER,
  };

  const validationSchema = validator.object({
    reason: validator.string().required(),
  });

  const handleOnSubmit = async (values) => {
    try {
      const { reason } = values;

      const response = await refundOrder(orderId, { reason });

      const refundedOrder = response.data;

      mutate(refundedOrder);

      onSubmit(null, refundedOrder);

      notification.success('Successfully refunded order');
    } catch (err) {
      notification.error('Something went wrong when refunding order');
    }
  };

  return (
    <FormManager
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <SelectField
            name="reason"
            label="Reason"
            required
            options={refundReasonOptions}
          />

          <Button type="submit" loading={isSubmitting}>
            Refund
          </Button>
        </Form>
      )}
    </FormManager>
  );
};

RefundOrderForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

RefundOrderForm.defaultProps = {
  onSubmit: () => {},
};

export default RefundOrderForm;
