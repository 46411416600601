import React from 'react';
import PropTypes from 'prop-types';

import { PublishButton } from 'lib/publish';

import * as api from './api';

const PublishCollectionButton = ({
  collection,
  mutateCollection,
}) => {
  const isPublished = Boolean(collection.published_at);

  return (
    <PublishButton
      isPublished={isPublished}
      publishRequest={() => api.publishCollection(collection.id)}
      unPublishRequest={() => api.unpublishCollection(collection.id)}
      onPublish={(_, result) => {
        const { response, error } = result;

        if (error) return;

        const newItem = response.data;

        mutateCollection(collection.id, newItem);
      }}
    />
  );
};

PublishCollectionButton.propTypes = {
  collection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    published_at: PropTypes.string.isRequired,
  }),
  mutateCollection: PropTypes.func,
};

export default PublishCollectionButton;
