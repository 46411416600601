import React from 'react';
import { Button } from 'components/Common';

const ActivateEventDonationButton = ({
	beforeActivate,
	onActivate,
	isActive,
	activateRequest,
	deactivateRequest,
	donation,
}) => {
	const [isActivating, setIsActivating] = React.useState();

	const color = isActive ? 'secondary' : 'primary';
  let label = isActive ? 'Deactivate' : 'Activate';
  if (isActivating) label = label.slice(0, -1) + 'ing';
  const icon = isActive ? 'fa fa-eye-slash' : 'fa fa-eye';
  const request = isActive ? deactivateRequest : activateRequest;
  const disabled = isActivating;

	return (
		<Button
			color={color}
			style={{ minWidth: '120px' }}
			onClick={async () => {
				try {
          if (beforeActivate) await beforeActivate();

          setIsActivating(true);

          const response = await request();

          onActivate(donation, response);
        } catch (err) {
          console.log('err', err);
        } finally {
          setIsActivating(false);
        }
			}}
			disabled={disabled}
		>
			<i className={`${icon} mr-2`}></i>
      {label}
		</Button>
	);
};

export default ActivateEventDonationButton;
