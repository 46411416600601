import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const putUpdateIngredientNutrition = async (
  ingredientId,
  nutrition,
) => {
  try {
    const response = await httpClient.put(
      `/cms/ingredients/${ingredientId}/nutrition`,
      nutrition,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
