import { routePaths } from 'appConfig';
import { Button } from 'components/Common';
import { TextField } from 'components/formFields';
import { Datetime } from 'lib/datetime';
import { Form, FormManager } from 'lib/form';
import { displayName } from 'lib/utils';
import validator from 'lib/validator';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  ComplaintCorrectiveActionPayload,
  ComplaintCorrectiveActionSchema,
} from './types';

interface CorrectiveActionFormInterface {
  isDisabled?: boolean;
  onSubmit: (
    payload: ComplaintCorrectiveActionPayload,
  ) => Promise<void>;
  onCancel: () => void;
  data: ComplaintCorrectiveActionSchema;
}

const CorrectiveActionForm = ({
  isDisabled,
  onSubmit,
  onCancel,
  data,
}: CorrectiveActionFormInterface) => {
  const history = useHistory();

  const validationSchema = validator.object({
    corrective_action: validator.string().required(),
  });

  const {
    corrective_action,
    id: complaintId,
    corrected_at,
    corrector,
  } = data || {};
  const { user_id: userId } = corrector || {};

  const correctorName = displayName({
    firstName: corrector?.first_name,
    lastName: corrector?.last_name,
  });

  const defaultValues: ComplaintCorrectiveActionSchema = {
    id: complaintId || '',
    corrective_action: corrective_action || '',
  };

  const initialValues = {
    ...defaultValues,
  };

  const handleOnSubmit = async (
    values: ComplaintCorrectiveActionSchema,
  ) => {
    const payload: ComplaintCorrectiveActionPayload = {
      corrective_action: values.corrective_action,
    };
    await onSubmit(payload);
  };

  const onShowUser = (userId: string) => {
    history.push(`${routePaths.USERS}/${userId}`);
  };

  const setValuesRef = useRef<any>(null);

  return (
    <FormManager
      initialValues={initialValues}
      validateOnBlur
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting, setValues, setErrors }) => {
        const handleCancel = () => {
          setValues(defaultValues);
          setErrors({});
          onCancel();
        };

        return (
          <Form>
            <Row>
              <Col>
                <>
                  {corrected_at && corrector && (
                    <div className="pb-3">
                      <span>Updated at </span>
                      <Datetime value={corrected_at} />{' '}
                      <span> by </span>
                      <Button
                        variant="link"
                        color="secondary"
                        className="p-0 border-0 text-left text-decoration-none"
                        style={{ textDecoration: 'none' }}
                        onClick={() => onShowUser(userId || '')}
                        shape={undefined}
                        children={<span>{`${correctorName}`}</span>}
                        loading={undefined}
                        block={undefined}
                        renderLoading={undefined}
                      />
                    </div>
                  )}
                </>
                <TextField
                  name={'corrective_action'}
                  label="Corrective Action"
                  required
                  type="textarea"
                  rows={5}
                  onChange={undefined}
                  helperText={undefined}
                  disabled={isDisabled}
                />
              </Col>
            </Row>
            {!isDisabled && (
              <div className="d-flex justify-content-end">
                <Button
                  type="button"
                  className="mr-2"
                  onClick={handleCancel}
                  variant={'outlined'}
                  color="dark"
                  shape={undefined}
                  block={undefined}
                  renderLoading={undefined}
                  loading={undefined}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className={`float-right`}
                  loading={isSubmitting}
                  disabled={isSubmitting || isDisabled}
                  variant={undefined}
                  color={undefined}
                  shape={undefined}
                  block={undefined}
                  renderLoading={undefined}
                >
                  Save
                </Button>
              </div>
            )}
          </Form>
        );
      }}
    </FormManager>
  );
};

export default CorrectiveActionForm;
