import React, { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router';

import { routePaths } from 'appConfig';
import { Page, Button } from 'components/Common';
import { useRouter } from 'lib/router';
import notification from 'lib/notification';
import { useOrders, OrdersTable } from 'lib/orders';
import {
  NewOrderModal,
  postAddOrder,
  putUpdateOrder,
} from 'lib/orders';
import { expand } from 'lib/javascript';

const OrdersPage = () => {
  const history = useHistory();
  const { query, updateQueryParams } = useRouter();

  const [filterParams, setFilterParams] = React.useState({
    orderNumber: '',
    status: '',
    customerId: '',
    deliveryDateTime: '',
  });

  const [isNewOrderModalOpen, setNewOrderModalOpen] = useState(false);

  const {
    page = 1,
    sizePerPage = 15,
    sortField = 'created_at',
    sortDir = 'desc',
  } = query;

  const { isLoading, orders, total, mutateOrders } = useOrders({
    page,
    sizePerPage,
    ...filterParams,
    sortField,
    sortDir,
  });

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <div className="d-flex justify-content-end mb-2">
              <Button onClick={() => setNewOrderModalOpen(true)}>
                Add New Order
              </Button>
            </div>
            <OrdersTable
              loading={isLoading}
              data={orders}
              page={parseInt(page)}
              sizePerPage={parseInt(sizePerPage)}
              totalSize={total}
              getPathToDetailsPage={(order) =>
                `${routePaths.ORDERS}/${order.id}`
              }
              sortDir={sortDir}
              sortField={sortField}
              updateQueryParams={updateQueryParams}
              setFilterParams={setFilterParams}
              mutateOrders={mutateOrders}
            />
            <NewOrderModal
              isOpen={isNewOrderModalOpen}
              onClose={() => setNewOrderModalOpen(false)}
              title="NEW ORDER"
              buttonTitle="Add New Order"
              onSubmit={async (values) => {
                try {
                  const {
                    user,
                    addresses,
                    has_utensil,
                    delivery_time,
                    notes,
                  } = values;

                  const newOrder = await postAddOrder(user);

                  await putUpdateOrder(newOrder.id, {
                    addresses,
                    has_utensil,
                    delivery_time,
                    notes,
                  });

                  notification.success(
                    'Successfully created a new order',
                  );

                  history.push(`/orders/${newOrder.id}`);
                } catch (error) {
                  if (error.errors && !error.errors.message) {
                    const expandedErrors = expand(error.errors);
                    const newErrors = { errors: expandedErrors };
                    throw newErrors;
                  }

                  notification.error(
                    error.errors.message ||
                      'Error occur when create new order',
                  );
                }
              }}
            />
          </CardBody>
        </Card>
      }
    />
  );
};

OrdersPage.propTypes = {};

export default OrdersPage;
