const macroInitialValues = {
  calorie: null,
  protein: null,
  fat: null,
  carb: null,
  polyunsaturated_fat: null,
  monounsaturated_fat: null,
  saturated_fat: null,
  trans_fat: null,
  dietary_fiber: null,
  sugar: null,
  cholesterol: null,
  vitamin_a: null,
  vitamin_c: null,
  sodium: null,
  calcium: null,
  iron: null,
  potassium: null,
};

export default macroInitialValues;
