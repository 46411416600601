import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const getAddonRules = async (params) => {
  try {
    const response = await httpClient.get('/cms/addon_rules', {
      params,
    });

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const createAddonRule = async (body) => {
  try {
    const response = await httpClient.post('/cms/addon_rules', body);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getAddonRule = async (addonRuleId) => {
  try {
    const response = await httpClient.get(
      '/cms/addon_rules/' + addonRuleId,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const updateAddonRule = async (addonRuleId, body) => {
  try {
    const response = await httpClient.put(
      `/cms/addon_rules/${addonRuleId}`,
      body,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteAddonRule = async (addonRuleId) => {
  try {
    const response = await httpClient.delete(
      `/cms/addon_rules/${addonRuleId}`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
