import axios from 'axios';

import config from 'appConfig';

import jwtClient from '../jwtClient';

const init = () => {
  const instance = axios.create({
    baseURL: config.api.baseURL,
    headers: {
      common: {
        'X-CANDY-HUB': true, // flag to indicate it's for admin hub/CMS
      },
    },
  });

  instance.interceptors.request.use(async (config) => {
    const token = await jwtClient.getToken();

    if (token) config.headers['Authorization'] = `Bearer ${token}`;

    return config;
  });

  instance.CancelToken = axios.CancelToken;
  instance.isCancel = axios.isCancel;

  return instance;
};

export default init;
