import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

import { Page, Skeleton, Button } from 'components/Common';

import { routePaths } from 'appConfig';
import { useConfirmation } from 'lib/confirm';
import { CategoryForm, useCategory } from 'lib/productCategory';
import notification from 'lib/notification';
import { MediaInput, ASSET_KIND } from 'lib/media';

const CategoryDetailsPage = () => {
  const { alert, confirm } = useConfirmation();

  const history = useHistory();
  const { categoryId } = useParams();

  const {
    isLoading,
    category,
    update,
    isRemoving,
    remove,
    mutate,
  } = useCategory(categoryId);

  const onUpdate = async (values, actions) => {
    try {
      await update(values);
      notification.success('Successfully update category');
    } catch (error) {
      const { errors, message } = error;

      if (errors) actions.setErrors(errors); // field-level errors
      if (message) actions.setStatus({ error: message }); // general error
    }
  };

  const onAssetsChange = (assets) => {
    mutate((category) => ({ ...category, assets }));
  };

  const handleDelete = async () => {
    await confirm();

    try {
      await remove();
      notification.success('Successfully deleted category');
      history.replace(routePaths.CATEGORIES);
    } catch (err) {
      await alert({
        title: 'Cannot Delete Category',
        content: err?.errors?.message,
      });
    }
  };

  const getContent = () => {
    if (isLoading) return <Skeleton type="page" />;

    return (
      <React.Fragment>
        <div className="d-flex justify-content-end align-item-center pb-3">
          <Button
            color={'danger'}
            onClick={handleDelete}
            loading={isRemoving}
          >
            Delete Category
          </Button>
        </div>
        <Card>
          <CardBody>
            <CategoryForm
              initialValues={category}
              onSubmit={onUpdate}
              buttonText="Update"
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <MediaInput
              name="image"
              label="Image"
              parent="category"
              assetKind={ASSET_KIND.IMAGE}
              parentId={categoryId}
              value={category.assets}
              onChange={onAssetsChange}
              allowMultiple={false}
              maxFiles={1}
            />
          </CardBody>
        </Card>
      </React.Fragment>
    );
  };

  return (
    <Page
      breadcrumbProps={{
        config: {
          category: {
            path: routePaths.CATEGORY_DETAILS,
            label: category?.name,
          },
        },
      }}
      content={getContent()}
    />
  );
};

CategoryDetailsPage.propTypes = {};

export default CategoryDetailsPage;
