import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Col } from 'reactstrap';

import { Label } from 'components/Common';
import { isEqual } from 'lib/javascript';

import * as types from '../typedef';
import MediaPickerModal from '../MediaPickerModal';
import MediaGallery from '../MediaGallery';
import { ASSET_KIND } from '../constants';
import { isAssetOfKind } from '../utils';
import './MediaInput.css';

const ITEM_SIZE = '150px';

const MediaInput = ({
  name,
  error,
  required,
  label,
  value: allAssets,
  onChange,
  parent,
  parentId,
  assetKind,
  allowMultiple,
  maxFiles,
  noContainer,
}) => {
  const value = assetKind
    ? allAssets.filter((asset) => isAssetOfKind(asset, assetKind))
    : allAssets;

  const [showModal, setShowModal] = React.useState(false);
  const [assets, setAssets] = React.useState(value);

  const canAdd = assets.length < maxFiles;

  const noOfAddedFiles = assets.length;
  const noOfFilesCanBeAdded = maxFiles - noOfAddedFiles;

  React.useEffect(() => {
    const valueIds = value?.map((value) => value.id);
    const assetsIds = assets?.map((asset) => asset.id);

    // should not directly check equality between assets from prop and assets state
    // because assets state (the upload image response) updated_at has sensitivity up to milliseconds
    // but the assets from prop (the get category response's 'assets' key) updated_at has sensitivity up to only seconds
    // this subtle difference will make them never to be equal
    if (!isEqual(valueIds, assetsIds)) onChange(assets);
  }, [value, assets, onChange]);

  const handleOnProcessFile = (error, file) => {
    if (error) return;

    setAssets((assets) => (assets || []).concat(file));
  };

  return (
    <FormGroup>
      <Label htmlFor={name} error={error} required={required}>
        {label} ({noOfAddedFiles}/{maxFiles})
      </Label>

      <MediaGallery
        assets={assets}
        onAssetsChange={setAssets}
        itemSize={ITEM_SIZE}
      >
        {canAdd && (
          <Col xs="auto">
            <button
              type="button"
              className="add-image-btn"
              style={{ width: ITEM_SIZE, height: ITEM_SIZE }}
              onClick={() => setShowModal(true)}
            >
              <i className="bx bx-plus"></i>
            </button>
          </Col>
        )}
      </MediaGallery>

      <MediaPickerModal
        open={showModal}
        onClose={() => setShowModal(false)}
        parent={parent}
        parentId={parentId}
        allowMultiple={allowMultiple}
        maxFiles={noOfFilesCanBeAdded}
        onProcessFile={handleOnProcessFile}
        noContainer={noContainer}
      />
    </FormGroup>
  );
};

MediaInput.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.arrayOf(types.Asset),
  onChange: PropTypes.func,
  parent: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  maxFiles: PropTypes.number,
  assetKind: PropTypes.oneOfType(Object.values(ASSET_KIND)),
};

MediaInput.defaultProps = {
  value: [],
  onChange: () => {},
  error: false,
  required: false,
  assetKind: null,
};

MediaInput.whyDidYouRender = true;

export default MediaInput;
