import { useListCRUD } from 'lib/swr-crud';
import { getCategories, postCategory } from './api';

const fetchers = {
  read: async (_url) => {
    const { response, error } = await getCategories();

    if (error) throw error;

    return response.data;
  },
  create: async (_url, category) => {
    const { response, error } = await postCategory(category);

    if (error) throw error;

    return response;
  },
};

const useCategories = (options = {}) => {
  const url = '/cms/categories';

  const { data, error, create, isLoading, mutate } = useListCRUD(
    url,
    'id',
    {
      ...options,
      fetchers,
    },
  );

  const categories = data || [];

  return {
    categories,
    create,
    error,
    isLoading,
    mutate,
  };
};

export default useCategories;
