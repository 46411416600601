import React from 'react';
import PropTypes from 'prop-types';
import { FormCheck } from 'react-bootstrap';

import { useRadioGroupContext } from '../contexts/radioContexts';

import styles from './Radio.module.css';

const Radio = ({
  name: nameProp,
  label,
  checked: checkedProp,
  value,
  onChange: onChangeProp,
  checkedRender,
}) => {
  const radioGroupCtx = useRadioGroupContext();

  let checked = checkedProp;
  let name = nameProp;

  if (radioGroupCtx) {
    if (typeof checked === 'undefined') {
      checked = radioGroupCtx.value === value;
    }
    if (typeof name === 'undefined') {
      name = radioGroupCtx.name;
    }
  }

  const onChange = (e) => {
    onChangeProp(e);
    if (radioGroupCtx) radioGroupCtx.onChange(e);
  };

  return (
    <FormCheck id={value}>
      <FormCheck.Input
        type="radio"
        className={styles.input}
        title={value}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <FormCheck.Label className={styles.label}>
        {label}
      </FormCheck.Label>
    </FormCheck>
  );
};

Radio.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  checkedRender: PropTypes.func,
};

Radio.defaultProps = {
  onChange: () => {},
  checkedRender: () => null,
};

export default Radio;
