import React from 'react';
import PropTypes from 'prop-types';

import Table, { textFilter } from 'core/components/Table';
import { Link } from 'lib/router';

import IngredientStock from './IngredientStock';

const IngredientTable = ({
  loading,
  data,
  page,
  sizePerPage,
  totalSize,
  onTableChange,
  getPathToDetailsPage,
  sortField,
  sortDir,
}) => {
  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (name, ingredient) => {
        return (
          <Link to={getPathToDetailsPage(ingredient)}>{name}</Link>
        );
      },
      filter: textFilter({ delay: 1500 }),
    },
    {
      dataField: 'stock',
      text: 'Stock',
      formatter: (_stock, ingredient) => {
        return <IngredientStock ingredient={ingredient} />;
      },
      //   filter: textFilter({ delay: 3000 }),
    },

    {
      dataField: 'type',
      text: 'Type',
      //   filter: textFilter({ delay: 3000 }),
    },

    {
      dataField: 'alias',
      text: 'Alias',
      //   filter: textFilter({ delay: 3000 }),
    },
  ];

  return (
    <Table
      remote
      loading={loading}
      keyField="id"
      columns={columns}
      data={data}
      pagination={{ page, sizePerPage, totalSize }}
      onTableChange={onTableChange}
      sort={{ dataField: sortField, order: sortDir }}
    />
  );
};

IngredientTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  sortField: PropTypes.string,
  sortDir: PropTypes.string,
};

IngredientTable.defaultProps = {
  loading: false,
};

export default IngredientTable;
