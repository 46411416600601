import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { routePaths } from 'appConfig';
import { Page } from 'components/Common';
import { useRouter } from 'lib/router';
import { UserTable, useUsers } from 'lib/users';

const OrdersPage = () => {
  const { query, updateQueryParams } = useRouter();

  const {
    page = 1,
    sizePerPage = 15,
    sortField = 'created_at',
    sortDir = 'desc',
    name = '',
    email = '',
    phone_number = '',
  } = query;

  const { isLoading, users, total } = useUsers({
    page,
    sizePerPage,
    name,
    email,
    sortField,
    sortDir,
    phoneNumberFlag: phone_number,
  });

  const handleOnTableChange = (action, newState) => {
    switch (action) {
      case 'pagination':
        updateQueryParams({
          page: newState.page,
          sizePerPage: newState.sizePerPage,
        });
        break;

      case 'filter':
        const name = newState.filters?.first_name?.filterVal ?? '';
        const email = newState.filters?.email?.filterVal ?? '';
        const phoneNumber =
          newState.filters?.phone_number?.filterVal ?? '';

        updateQueryParams({
          page: 1,
          sizePerPage: newState.sizePerPage,
          name,
          email,
          phone_number: phoneNumber,
        });

        break;

      case 'sort':
        const { sortField, sortOrder } = newState;

        updateQueryParams({
          sortField,
          sortDir: sortOrder,
        });

        break;

      default:
    }
  };

  return (
    <Page
      content={
        <Card>
          <CardBody>
            <UserTable
              loading={isLoading}
              data={users}
              page={parseInt(page)}
              sizePerPage={parseInt(sizePerPage)}
              totalSize={total}
              onTableChange={handleOnTableChange}
              getPathToDetailsPage={(user) =>
                `${routePaths.USERS}/${user.user_id}`
              }
              sortDir={sortDir}
              sortField={sortField}
              name={name}
              email={email}
              phoneNumberFlag={phone_number}
            />
          </CardBody>
        </Card>
      }
    />
  );
};

OrdersPage.propTypes = {};

export default OrdersPage;
