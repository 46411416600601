// @ts-nocheck
import { routePaths } from 'appConfig';
import { Button, Page, Tab, Tabs } from 'components/Common';
import type { CollectionPoint } from 'lib/collection-points';
import {
  CollectionPointForm,
  CollectionPointSlots,
  useCollectionPoint,
} from 'lib/collection-points';
import { CollectionPointSlotsCalendar } from 'lib/collection-points';
import { useConfirmation } from 'lib/confirm';
import notification from 'lib/notification';
import { useState } from 'react';
import { List } from 'react-content-loader';
import { useHistory, useParams } from 'react-router';
import { Card, CardBody, Col, Row } from 'reactstrap';

const COLLECTION_POINT_TAB_IDS = {
  DETAILS: 'details',
  SLOTS: 'slots',
  PREVIEW: 'preview',
}

type InitialValues = Omit<
  CollectionPoint, 'id' | 'slots' | 'created_at' | 'updated_at'
>;

const CollectionPointDetailPage = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [activeTab, setActiveTab] = useState(COLLECTION_POINT_TAB_IDS.DETAILS);

  const history = useHistory();
  const { collectionPointId } = useParams();
  const { confirm } = useConfirmation();

  const {
    collectionPoint,
    isLoading,
    updateCollectionPoint,
    removeCollectionPoint,
    mutate,
  } = useCollectionPoint(collectionPointId);

  const onUpdate = async (collectionPoint: CollectionPoint) => {
    try {
      const updated = await updateCollectionPoint(collectionPoint);
      notification.success('Successfully updated collection point');
      mutate(updated);
    } catch (err) {
      let message = err;
      if (typeof err === 'object') {
        const errors = err.errors;
        const errorKeys = Object.keys(errors);
        if (errors && errorKeys.length > 0) {
          message = errors[errorKeys[0]];
        }
      }

      notification.error(message);
    }
  }

  const onDelete = async () => {
    await confirm();

    try {
      setIsDeleting(true);

      await removeCollectionPoint();

      history.replace(routePaths.COLLECTION_POINTS);
      notification.success('Successfully deleted collection point');
    } catch (err) {
      const errorMessage =
        err.errors?.message ||
        'Something went wrong when deleting collection';

      notification.error(errorMessage);
    } finally {
      setIsDeleting(false);
    }
  };

  const initialValues: InitialValues = collectionPoint;

  return (
    <Page
      content={
        <>
          {isLoading
            ? <List />
            : (
              <>
                <div className="d-flex justify-content-end align-items-center mb-4">
                  <Button
                    color="danger"
                    onClick={onDelete}
                    loading={isDeleting}
                    disabled={isDeleting}
                  >
                    Delete
                  </Button>
                </div>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <Tabs
                          defaultActiveKey={COLLECTION_POINT_TAB_IDS.DETAILS}
                          activeKey={activeTab}
                          onSelect={(tab) => {
                            setActiveTab(tab)
                          }}
                        >
                          <Tab eventKey={COLLECTION_POINT_TAB_IDS.DETAILS} title="Details">
                            <CollectionPointForm
                              onSubmit={onUpdate}
                              initialValues={initialValues}
                              transparentCard
                              buttonLabel="Save"
                            />
                          </Tab>
                          <Tab eventKey={COLLECTION_POINT_TAB_IDS.SLOTS} title="Slots">
                            <div className="py-3">
                              <CollectionPointSlots collectionPoint={collectionPoint} mutate={mutate} />
                            </div>
                          </Tab>
                          <Tab eventKey={COLLECTION_POINT_TAB_IDS.PREVIEW} title="Preview">
                            <div className="py-3">
                              <CollectionPointSlotsCalendar collectionPoint={collectionPoint} />
                            </div>
                          </Tab>
                        </Tabs>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            )
          }
        </>
      }
    />
  )
}

export default CollectionPointDetailPage
