import { useEffect } from 'react';
import { useHistory } from 'react-router';

import httpClient, {
  createAuthRefreshInterceptor,
} from 'lib/httpClient';
import { routePaths } from 'appConfig';

import { refreshJwtToken } from 'services/api';
import sessionManager from './sessionManager';

const useSessionListener = () => {
  const history = useHistory();

  useEffect(() => {
    const refreshAuthLogic = async (failedRequest) => {
      try {
        sessionManager.setIsRefreshingSession(true);

        const { response, error } = await refreshJwtToken();

        if (error) throw failedRequest;

        const token = response.access_token;

        failedRequest.response.config.headers[
          'Authorization'
        ] = `Bearer ${token}`;

        sessionManager.startSession(token);
        return Promise.resolve();
      } catch (err) {
        sessionManager.endSession();
        history.push(routePaths.LOGOUT);
        return Promise.reject(err);
      } finally {
        sessionManager.setIsRefreshingSession(false);
      }
    };

    // triggered on status code 401
    const authRefreshInterceptor = createAuthRefreshInterceptor(
      httpClient,
      refreshAuthLogic,
    );

    return () => {
      httpClient.interceptors.response.eject(authRefreshInterceptor);
    };
  }, [history]);
};

export default useSessionListener;
