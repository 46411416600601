import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const getIngredients = async ({ per_page, name }) => {
  try {
    const response = await httpClient.get(`/cms/ingredients`, {
      params: {
        per_page,
        name,
      },
    });

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getIngredientById = async (ingredientId) => {
  try {
    const response = await httpClient.get(
      `/cms/ingredients/${ingredientId}`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const createIngredient = async (body) => {
  try {
    const response = await httpClient.post(`/cms/ingredients`, body);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const updateIngredientById = async (ingredientId, body) => {
  try {
    const response = await httpClient.put(
      `/cms/ingredients/${ingredientId}`,
      body,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const deleteIngredientById = async (ingredientId) => {
  try {
    const response = await httpClient.delete(
      `/cms/ingredients/${ingredientId}`,
    );

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getIngredientTypes = async () => {
  try {
    const response = await httpClient.get(`/cms/ingredient_types`);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
