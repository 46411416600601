import React from 'react';
import PropTypes from 'prop-types';

import {
  SimpleTable,
  Button,
  Modal,
  IconButton,
  Icon,
} from 'components/Common';
import { Price } from 'core/components';
import {
  useProductVariant,
  ProductVariantQuantityInput,
  utils as productVariantUtils,
} from 'lib/productVariants';
import { FormManager, Field } from 'lib/form';
import { AddonOptionValues } from 'lib/productAddons';
import { TextField } from 'components/formFields';

import { OrderLinePropType } from '../typedef';
import AddOrderLineForm from '../AddOrderLineForm';
import EditOrderLineAddonsForm from '../EditOrderLineAddonsForm';
import { useConfirmation } from 'lib/confirm';

const ProductVariantQuantityInputContainer = ({
  productVariantId,
  ...props
}) => {
  const { isLoading, productVariant } = useProductVariant(
    productVariantId,
  );

  if (isLoading) return 'Loading...';

  if (!isLoading && !productVariant) return 'Errored';

  return (
    <ProductVariantQuantityInput
      {...props}
      productVariant={productVariant}
    />
  );
};

const ProductVariantContainer = ({
  productVariantId,
  productOptions,
}) => {
  const { isLoading, productVariant } = useProductVariant(
    productVariantId,
  );

  if (isLoading) return 'Loading...';

  if (!isLoading && !productVariant) return 'Errored';

  return productVariantUtils.makeProductVariantLabel(
    productVariant,
    productOptions,
  );
};

const ManageOrderLines = ({
  orderId,
  data,
  isUpdatingOrderLine,
  updateOrderLine,
  isRemovingOrderLine,
  removeFromOrder,
  addOrderLine,
  updateOrderLineAddons,
  disabled,
}) => {
  const { confirm } = useConfirmation();
  const [
    showAddOrderLineDialog,
    setShowAddOrderLineDialog,
  ] = React.useState(false);

  const [editingOrderLineId, setEditingOrderLineId] = React.useState(
    false,
  );

  const editingOrderLine = data.find(
    (orderLine) => orderLine.id === editingOrderLineId,
  );

  const can = {
    addOrderLine: () => !disabled,
    editOrderLine: (orderLineId) =>
      !disabled && !isUpdatingOrderLine(orderLineId),
    deleteOrderLine: (orderLineId) =>
      !disabled && !isRemovingOrderLine(orderLineId),
  };

  const columns = [
    {
      dataField: 'product.name',
      text: 'Product Name',
      formatter: (productName, orderLine) => {
        const addonOptionValues =
          orderLine?.components?.addon_option_values;

        return (
          <div>
            <strong>{productName}</strong>
            <AddonOptionValues data={addonOptionValues} />
            {addonOptionValues && (
              <Button
                variant="link"
                onClick={() => setEditingOrderLineId(orderLine.id)}
                disabled={!can.editOrderLine(orderLine.id)}
              >
                Edit Addons
              </Button>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'product_variant_id',
      text: 'Product Variant',
      classes: 'product-col',
      formatter: (productVariantId, order) => {
        return (
          <ProductVariantContainer
            productVariantId={productVariantId}
            productOptions={order?.product?.options}
          />
        );
      },
    },
    {
      dataField: 'unit_price',
      text: 'Unit Price',
      classes: 'price-col',
      formatter: (_cell, row) => {
        return (
          <div style={{ minWidth: '70px' }}>
            <TextField
              name="unit_price"
              type="number"
              disabled={!can.editOrderLine(row.id)}
            />
          </div>
        );
      },
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
      classes: 'quantity-col',
      formatter: (_cell, row) => {
        const orderLine = row;

        return (
          <div style={{ minWidth: '100px' }}>
            <Field name="quantity">
              {({ field, form }) => (
                <ProductVariantQuantityInputContainer
                  value={field.value}
                  onChange={(newValue) =>
                    form.setFieldValue(field.name, newValue)
                  }
                  disabled={!can.editOrderLine(orderLine.id)}
                  productVariantId={row.product_variant_id}
                />
              )}
            </Field>
          </div>
        );
      },
    },
    {
      dataField: 'line_total',
      text: 'Total before Tax',
      classes: 'total-col',
      formatter: (cell) => <Price value={cell} />,
    },
    {
      dataField: 'tax_total',
      text: 'Tax',
      formatter: (cell) => <Price value={cell} />,
    },
    {
      dataField: 'line_total',
      text: 'Total after Tax',
      formatter: (lineTotal, orderLine) => (
        <Price value={lineTotal + orderLine.tax_total} />
      ),
    },
    {
      dataField: 'id',
      classes: 'remove-col',
      formatter: (id, orderLine) => (
        <div className="d-flex">
          <Field>
            {({ form }) => (
              <IconButton
                className="mr-2"
                type="submit"
                color="success"
                onClick={form.submitForm}
                disabled={!can.editOrderLine(orderLine.id)}
                loading={isUpdatingOrderLine(id)}
              >
                <Icon name="content-save" />
              </IconButton>
            )}
          </Field>
          <IconButton
            onClick={() =>
              confirm({
                title: `To Delete Order Line ${orderLine?.product?.name}?`,
                content: 'This action is irreversible. Are you sure?',
              }).then(() => removeFromOrder(orderLine))
            }
            loading={isRemovingOrderLine(id)}
            disabled={!can.deleteOrderLine(id)}
            color="danger"
          >
            <Icon name="delete" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <SimpleTable
        data={data}
        columns={columns}
        classes="table-cart"
        noDataIndication={() => (
          <tr>
            <td>
              <p className="pl-2 pt-1 pb-1"> No Products in Cart </p>
            </td>
          </tr>
        )}
        renderBodyRow={(children, orderLine) => (
          <tr>
            <FormManager
              key={orderLine.id}
              enableReinitialize
              initialValues={orderLine}
              onSubmit={(newOrderLine) =>
                updateOrderLine(orderLine.id, newOrderLine)
              }
            >
              {() => children}
            </FormManager>
          </tr>
        )}
      />
      <Button
        block
        disabled={!can.addOrderLine()}
        onClick={() => setShowAddOrderLineDialog(true)}
      >
        Add Order Line
      </Button>
      <Modal
        open={showAddOrderLineDialog}
        onClose={() => setShowAddOrderLineDialog(false)}
        title="Add Order Line"
      >
        <AddOrderLineForm
          orderId={orderId}
          onSubmit={async (orderLineToAdd) => {
            try {
              await addOrderLine(orderLineToAdd);
              setShowAddOrderLineDialog(false);
              return;
            } catch (err) {
              throw err;
            }
          }}
        />
      </Modal>
      <Modal
        open={Boolean(editingOrderLineId)}
        onClose={() => setEditingOrderLineId(null)}
        title="Edit Order Line Addons"
      >
        <EditOrderLineAddonsForm
          productVariantId={editingOrderLine?.product_variant_id}
          addons={editingOrderLine?.components?.addon_option_values?.reduce(
            (result, addonOptionValue) => {
              result[addonOptionValue.addon_option_id] =
                addonOptionValue.id;

              return result;
            },
            {},
          )}
          onSubmit={async (addonOptionValueIds) => {
            try {
              await updateOrderLineAddons(editingOrderLineId, {
                addonOptionValueIds,
              });
              setEditingOrderLineId(null);
              return;
            } catch (err) {
              throw err;
            }
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

ManageOrderLines.propTypes = {
  orderId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(OrderLinePropType),
  isUpdatingOrderLine: PropTypes.func,
  changeQty: PropTypes.func,
  changeUnitPrice: PropTypes.func,
  isRemovingOrderLine: PropTypes.func,
  removeFromOrder: PropTypes.func,
  addOrderLine: PropTypes.func,
  updateOrderLineAddons: PropTypes.func,
  disabled: PropTypes.bool,
};

ManageOrderLines.defaultProps = {
  data: [],
  isUpdatingOrderLine: () => {},
  changeQty: () => {},
  changeUnitPrice: () => {},
  isRemovingOrderLine: () => {},
  removeFromOrder: () => {},
  addOrderLine: () => {},
  updateOrderLineAddons: () => {},
  disabled: false,
};

export default ManageOrderLines;
