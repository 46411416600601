import React from 'react';
import PropTypes from 'prop-types';
import UploadInput, {
  UploadInputPropTypes,
} from 'components/Common/UploadInput';

import { createProcessServerMethod } from './server';

const MediaUploadInput = ({
  parent,
  parentId,
  mapProcessResponseToLoad,
  onProcessFile,
  ...props
}) => {
  const server = {
    process: createProcessServerMethod({
      method: 'post',
      url: `/cms/images`,
      onFormData: (formData) => {
        formData.append('element_type', parent);
        formData.append('element_id', parentId);
      },
      onResponse: (res) => onProcessFile(null, res.data),
      mapResponseToLoad: mapProcessResponseToLoad,
    }),
  };

  return (
    <UploadInput
      {...props}
      server={server}
      allowFileTypeValidation
      acceptedFileTypes={['image/png', 'image/jpeg']}
      allowFileSizeValidation
      maxFileSize="3MB"
    />
  );
};

MediaUploadInput.propTypes = {
  ...UploadInputPropTypes,
  parent: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  mapProcessResponseToLoad: PropTypes.func.isRequired,
};

MediaUploadInput.defaultProps = {
  mapProcessResponseToLoad: (res) => {
    console.log(res);
    return res.data.id;
  },
};

export default MediaUploadInput;
