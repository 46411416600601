/* -------------------------------------------------------------------------- */
/*                               authentication                               */
/* -------------------------------------------------------------------------- */

export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/password/confirm';

/* -------------------------------------------------------------------------- */
/*                                 user routes                                */
/* -------------------------------------------------------------------------- */
export const HOME = '/';
export const DASHBOARD = '/dashboard';
export const USER_PROFILE = '/profile';
export const USERS = '/users';
export const USER_DETAILS = '/users/:userId';

/* --------------------------------- catalog -------------------------------- */

export const CATEGORIES = '/categories';
export const NEW_CATEGORY = '/categories/new';
export const CATEGORY_DETAILS = '/categories/:categoryId';
export const PRODUCTS = '/products';
export const NEW_PRODUCT = '/products/new';
export const PRODUCT_DETAILS = '/products/:productId';
export const PRODUCT_VARIANT_SETUP =
  '/products/:productId/variants/setup';
export const PRODUCT_VARIANT =
  '/products/:productId/variants/:variantId';

/* ---------------------------------- meal --------------------------------- */

export const INGREDIENTS = '/ingredients';
export const NEW_INGREDIENT = '/ingredients/new';
export const INGREDIENT_DETAIL = '/ingredients/:ingredientId';

export const ADDON_OPTIONS = '/addon-options';
export const NEW_ADDON_OPTION = '/addon-options/new';
export const ADDON_OPTION_DETAILS = '/addon-options/:addonOptionId';

export const ADDON_RULES = '/addon-rules';
export const NEW_ADDON_RULE = '/addon-rules/new';
export const ADDON_RULE_DETAILS = '/addon-rules/:addonRuleId';

/* ---------------------------------- order --------------------------------- */

export const ORDERS = '/orders';
export const ORDER_DETAIL = '/orders/:orderId';
export const ORDERS_EXPORT = '/orders/export';

/* ---------------------------------- operation --------------------------------- */

export const OPERATION = '/operation';
/* --------------------------------- coupons -------------------------------- */

export const COUPONS = '/coupons';
export const NEW_COUPON = '/coupons/new';
export const COUPON_DETAILS = '/coupons/:couponCode';

/* --------------------------------- donations -------------------------------- */
export const DONATIONS = '/donations';
export const NEW_DONATION = '/donations/new';
export const DONATION_DETAILS = '/donations/:donationId';

/* ---------------------------------- collection --------------------------------- */

export const COLLECTIONS = '/collections';
export const NEW_COLLECTION = '/collections/new';
export const COLLECTION_DETAIL = '/collections/:collectionId';

/* -------------------- manual collection (catalogues) ------------------------ */
export const CATALOGUES = '/catalogues';
export const NEW_CATALOGUE = '/catalogues/new';
export const CATALOGUE_DETAIL = '/catalogues/:catalogueId';

/* -------------------------------- analytics ------------------------------- */

export const ANALYTICS = '/analytics';

/* ----------------------------------- tag ---------------------------------- */

export const TAGS = '/tags';
export const NEW_TAG = '/tags/new';
export const TAG_DETAIL = '/tags/:tagId';

/* ----------------------------------- pickup ---------------------------------- */
export const COLLECTION_POINTS = '/collection-points';
export const NEW_COLLECTION_POINT = '/collection-points/new';
export const COLLECTION_POINT_DETAILS =
  '/collection-points/:collectionPointId';

/* ----------------------------- love messages -------------------------------- */
export const LOVE_MESSAGES = '/love-messages';
export const LOVE_MESSAGE_DETAIL = '/love-messages/:loveMessageId';

/* ---------------------------------- report ---------------------------------- */
export const REPORT_KITCHEN_PRODUCT = '/kitchen-report';

/* -------------------------------------------------------------------------- */
/*                                    misc                                    */
/* -------------------------------------------------------------------------- */

export const PAGE_MAINTENANCE = '/pages-maintenance';
export const PAGE_COMING_SOON = '/pages-comingsoon';
export const PAGE_404 = '/pages-404';
export const PAGE_500 = '/pages-500';

/* ---------------------------------- complaint-categories ---------------------------------- */

export const COMPLAINT_CATEGORIES = '/complaint-categories';
export const NEW_COMPLAINT_CATEGORY = '/complaint-categories/new';
export const COMPLAINT_CATEGORY_DETAIL =
  '/complaint-categories/:complaintCategoryId';

/* ---------------------------------- complaint ---------------------------------- */

export const COMPLAINTS = '/complaints';
export const COMPLAINT_DETAIL = '/complaints/:complaintId';
