import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

import { AutoComplete } from 'components/Common';

import useOrderStatus from './useOrderStatus';
import {
  mapOrderStateToOptionLabel,
  mapOrderStateToOptionValue,
} from './utils';

const OrderStatusAutoComplete = ({
  styles,
  value,
  label,
  disabled,
  isLoading: isLoadingProp,
  isMulti,
  onChange,
  ...others
}) => {
  const {
    isLoading: isOrderStatusLoading,
    orderStatus,
  } = useOrderStatus();

  const isLoading = isLoadingProp || isOrderStatusLoading;

  if (isLoading)
    return (
      <ContentLoader height={35} style={{ width: '100%' }}>
        <rect x="0" y="0" rx="0" ry="0" width="680" height="25" />
      </ContentLoader>
    );

  return (
    <AutoComplete
      styles={styles}
      value={value}
      label={label}
      onChange={onChange}
      disabled={disabled}
      options={orderStatus}
      getOptionValue={mapOrderStateToOptionValue}
      getOptionLabel={mapOrderStateToOptionLabel}
      isMulti={isMulti}
      {...others}
    />
  );
};

OrderStatusAutoComplete.propTypes = {
  /**
   * order status value e.g. 'awaiting-payment'
   */
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
};

OrderStatusAutoComplete.defaultProps = {
  onChange: () => {},
  isLoading: false,
  isMulti: false,
};

export default OrderStatusAutoComplete;
