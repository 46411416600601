import React from 'react';
import PropTypes from 'prop-types';

import { routePaths } from 'appConfig';
import { Price, Table } from 'core/components';
import { Link, useRouter, pathToTo } from 'lib/router';
import useSalesReportByProduct from './useSalesReportByProduct';

const ReportByProductTable = ({ startDate, endDate }) => {
  const { query, updateQueryParams } = useRouter();

  const { sizePerPage, page } = query;

  const {
    isLoading,
    salesReportByProduct,
    total,
  } = useSalesReportByProduct({
    startDate,
    endDate,
    sizePerPage,
    page,
  });

  const columns = [
    {
      dataField: 'product_name',
      text: 'Product Title',
      formatter: (productName, row) => (
        <Link
          to={pathToTo(routePaths.PRODUCT_DETAILS, {
            URLParams: { productId: row.product_id },
          })}
        >
          {productName}
        </Link>
      ),
    },
    {
      dataField: 'categories',
      text: 'Categories',
      formatter: (categories) => (
        <div style={{ display: 'flex' }}>
          {categories.map((category) => (
            <Link
              to={pathToTo(routePaths.CATEGORY_DETAILS, {
                URLParams: { categoryId: category.id },
              })}
            >
              {category.name}
            </Link>
          ))}
        </div>
      ),
    },
    {
      dataField: 'total_items',
      text: 'Items Sold',
    },
    {
      dataField: 'net_sales',
      text: 'Net Sales',
      formatter: (netSales) => <Price value={netSales} />,
    },
    {
      dataField: 'total_orders',
      text: 'Orders',
    },
  ];

  const onTableChange = (action, newState) => {
    switch (action) {
      case 'pagination':
        const { sizePerPage, page } = newState;
        updateQueryParams({ sizePerPage, page });
        break;

      default:
    }
  };

  return (
    <Table
      remote
      loading={isLoading}
      keyField="product_id"
      data={salesReportByProduct || []}
      columns={columns}
      pagination={{ page, sizePerPage, totalSize: total }}
      onTableChange={onTableChange}
    />
  );
};

ReportByProductTable.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default ReportByProductTable;
