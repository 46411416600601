import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'components/Common';

const OperationSwitch = ({ value, onToggle }) => {
  const [isOn, setOn] = useState(value);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setOn(value);
  }, [value]);

  const _onToggle = () => {
    setLoading(true);
    onToggle(!isOn)
      .then(() => {
        setOn(!isOn);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        marginRight: '0.5rem',
      }}
    >
      {isLoading ? (
        <Spinner size="sm" />
      ) : (
        <div
          onClick={_onToggle}
          style={{
            width: '1rem',
            minWidth: '1rem',
            height: '1rem',
            minHeight: '1rem',
            backgroundColor: isOn ? '#4DFF01' : '#C0C1C0',
          }}
        />
      )}
    </div>
  );
};

OperationSwitch.propTypes = {
  defaultValue: PropTypes.bool,
  onToggle: PropTypes.func,
};

OperationSwitch.defaultProps = {
  defaultValue: true,
  onToggle: () => {},
};

export default OperationSwitch;
