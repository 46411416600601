import { useListCRUD } from 'lib/swr-crud';
import httpClient, {
  getErrorMessage,
  getResponseData,
} from 'lib/httpClient';

const fetchers = {
  read: async (url, query) => {
    try {
      const response = await httpClient({
        method: 'get',
        url,
        query,
      });

      return getResponseData(response);
    } catch (err) {
      throw getErrorMessage(err);
    }
  },
  update: async (url, data) => {
    try {
      const response = await httpClient({
        method: 'put',
        url,
        data,
      });

      return getResponseData(response);
    } catch (err) {
      throw getErrorMessage(err);
    }
  },
};

const useProductVariantAddonRules = (variantId) => {
  const url = `/cms/products/variants/${variantId}/addon_rules`;

  const { data, error, isLoading, mutate, update } = useListCRUD(
    url,
    '',
    {
      fetchers,
    },
  );

  const addonRules = data?.data || [];
  const addonRuleids = addonRules.map((d) => d.id);

  return {
    addonRules,
    addonRuleids,
    error,
    isLoading,
    mutate,
    update,
  };
};

export default useProductVariantAddonRules;
