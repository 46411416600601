export const LOG_EVENT_TYPE = {
  ORDER_PLACED: 'order-placed',
  ORDER_REDEEM: 'order-redeem',
  ORDER_REFUNDED: 'order-refunded',
  ORDER_CANCELED: 'order-canceled',
  ADMIN_ADJUSTMENT: 'admin-adjustment',
  EXPIRE: 'expire',
};

export const LABEL_BY_LOG_EVENT_TYPE = {
  [LOG_EVENT_TYPE.ORDER_PLACED]: 'Earned for purchase',
  [LOG_EVENT_TYPE.ORDER_REDEEM]: 'Redeemed towards purchase',
  [LOG_EVENT_TYPE.ORDER_REFUNDED]: 'Recalled after refunding order',
  [LOG_EVENT_TYPE.ORDER_CANCELED]: 'Recalled after cancelling order',
  [LOG_EVENT_TYPE.ADMIN_ADJUSTMENT]: 'Adjusted by admin',
  [LOG_EVENT_TYPE.EXPIRE]: 'Expired',
};
