import { useState } from 'react';
import { useCRUD } from 'lib/swr-crud';
import {
  getCategoryById,
  updateCategory,
  deleteCategory,
} from './api';
import useCategories from './useCategories';

const useCategory = (categoryId) => {
  const [isRemoving, setIsRemoving] = useState(false);

  const { mutate: mutateCategories } = useCategories({
    revalidateOnMount: false,
    revalidateOnFocus: false,
    refreshInterval: 0,
  });

  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/cms/category/${categoryId}`,
    async () => {
      try {
        const { response, error } = await getCategoryById(categoryId);

        if (error) throw error;

        const { data } = response;

        return data;
      } catch (err) {
        throw err;
      }
    },
  );
  const category = data || null;

  const update = async (body) => {
    try {
      const { response, error } = await updateCategory(
        categoryId,
        body,
      );

      if (error) throw error;

      const { data } = response;

      mutate(data);
      await mutateCategories((categories = []) =>
        categories.map((category) => {
          if (category.id !== categoryId) return category;

          return data;
        }),
      );
      return data;
    } catch (err) {
      throw err;
    }
  };

  const remove = async () => {
    try {
      setIsRemoving(true);

      const { error } = await deleteCategory(categoryId);

      if (error) throw error;
    } catch (err) {
      throw err;
    } finally {
      setIsRemoving(false);
    }
  };

  return {
    category,
    error,
    update,
    remove,
    mutate,
    isValidating,
    isLoading,
    isRemoving,
  };
};

export default useCategory;
