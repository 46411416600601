import httpClient, {
  getErrorMessage,
  getResponseData,
} from 'lib/httpClient';

export const getSalesMetrics = async ({ start_date, end_date }) => {
  try {
    const response = await httpClient.get('/cms/reports/sales', {
      params: { start_date, end_date },
    });

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getSalesReportByOrder = async ({
  start_date,
  end_date,
  per_page,
  page,
}) => {
  try {
    const response = await httpClient.get('/cms/reports/orders', {
      params: { start_date, end_date, per_page, page },
    });

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getSalesReportByCoupon = async ({
  start_date,
  end_date,
  per_page,
  page,
}) => {
  try {
    const response = await httpClient.get('/cms/reports/coupons', {
      params: { start_date, end_date, per_page, page },
    });

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getSalesReportByCategory = async ({
  start_date,
  end_date,
  per_page,
  page,
}) => {
  try {
    const response = await httpClient.get('/cms/reports/category', {
      params: { start_date, end_date, per_page, page },
    });

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getSalesReportByProduct = async ({
  start_date,
  end_date,
  per_page,
  page,
}) => {
  try {
    const response = await httpClient.get('/cms/reports/products', {
      params: { start_date, end_date, per_page, page },
    });

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const exportSalesReportByOrder = async ({
  start_date,
  end_date,
}) => {
  try {
    const response = await httpClient.post(
      '/cms/reports/orders/excel',
      {
        start_date,
        end_date,
      },
    );

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const exportSalesReportByOrderNow = async ({
  start_date,
  end_date,
}) => {
  try {
    const response = await httpClient.post(
      '/cms/reports/orders/excel/now',
      {
        start_date,
        end_date,
      },
      { responseType: 'blob' },
    );

    return response;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
