export const COUNTRIES = {
  SINGAPORE: 'SG',
};

export const LABEL_BY_COUNTRY = {
  [COUNTRIES.SINGAPORE]: 'Singapore',
};

export const countryOptions = [
  {
    value: '',
    label: 'Please select',
  },
  ...Object.values(COUNTRIES).map((countryCode) => ({
    value: countryCode,
    label: LABEL_BY_COUNTRY[countryCode],
  })),
];

export const ADDRESS_TYPE = {
  BILLING: 'billing',
  SHIPPING: 'shipping',
};

export const REQUIRED_ADDRESS_FIELDS = [
  'first_name',
  'last_name',
  'phone_number',
  'address_1',
];

export const ORDER_NOTES_TEMPLATE = 'Put inside cooler bag/box. ';
