import httpClient from 'lib/httpClient';
import {
  ComplaintCorrectiveActionPayload,
  ComplaintPayload,
  ComplaintResolutionPayload,
  ComplaintResponse,
  ComplaintsQueryParams,
  ComplaintsResponse,
} from './types';
import { createQueryString } from 'lib/utils';

export const storeComplaint = async (
  payload: ComplaintPayload,
  orderId: string,
  attachments: File[] | null,
) => {
  let data = new FormData();

  data.append('feedback', payload.feedback);
  data.append(
    'kitchen_representative',
    payload.kitchen_representative,
  );
  data.append('reported_at', payload.reported_at);
  data.append(
    'complaint_categories',
    payload.complaint_categories.join(','),
  );
  if (attachments && attachments.length > 0) {
    attachments.forEach((attachment) => {
      data.append('images[]', attachment);
    });
  }
  const response = await httpClient.post<ComplaintResponse>(
    `cms/orders/${orderId}/complaints`,
    data,
  );

  return response.data;
};

export const getComplaintsUrl = (params?: ComplaintsQueryParams) => {
  const formattedQueryString = createQueryString({
    page: params?.page ?? undefined,
    per_page: params?.perPage ?? undefined,
    search: params?.search ?? undefined,
    status: params?.status ?? undefined,
    kitchen: params?.kitchen ?? undefined,
    started_reported_at: params?.started_reported_at ?? undefined,
    ended_reported_at: params?.ended_reported_at ?? undefined,
    started_delivery_at:
      params?.started_order_delivery_at ?? undefined,
    ended_delivery_at: params?.ended_order_delivery_at ?? undefined,
    complaint_categories: params?.complaint_categories ?? undefined,
    user_id: params?.userId ?? undefined,
  });

  return `/cms/complaints?${formattedQueryString}`;
};

export const getComplaints = async (
  params?: ComplaintsQueryParams,
) => {
  const response = await httpClient.get<ComplaintsResponse>(
    getComplaintsUrl(params),
  );

  return response.data;
};

export const deleteComplaint = async (complaintId: string) => {
  const response = await httpClient.delete(
    `/cms/complaints/${complaintId}`,
  );

  return response.data;
};

export const getComplaint = async (complaintId: string) => {
  const response = await httpClient.get<ComplaintResponse>(
    `/cms/complaints/${complaintId}`,
  );

  return response.data;
};

export const updateComplaint = async (
  complaintId: string,
  payload: ComplaintPayload,
) => {
  const response = await httpClient.put<ComplaintResponse>(
    `/cms/complaints/update/complaint/${complaintId}`,
    payload,
  );

  return response.data;
};

export const updateResolution = async (
  complaintId: string,
  payload: ComplaintResolutionPayload,
) => {
  const response = await httpClient.put<ComplaintResponse>(
    `/cms/complaints/update/resolution/${complaintId}`,
    payload,
  );

  return response.data;
};

export const updateCorrectiveAction = async (
  complaintId: string,
  payload: ComplaintCorrectiveActionPayload,
) => {
  const response = await httpClient.put<ComplaintResponse>(
    `/cms/complaints/update/corrective_action/${complaintId}`,
    payload,
  );

  return response.data;
};

export const verifyComplaint = async (complaintId: string) => {
  const response = await httpClient.put<ComplaintResponse>(
    `/cms/complaints/${complaintId}/verify`,
    {
      verify: true,
    },
  );

  return response.data;
};

export const unverifyComplaint = async (complaintId: string) => {
  const response = await httpClient.put<ComplaintResponse>(
    `/cms/complaints/${complaintId}/verify`,
    { verify: false },
  );

  return response.data;
};


export const deleteResolution = async (complaintId: string) => {
  const response = await httpClient.delete(
    `/cms/complaints/${complaintId}/resolution`,
  );

  return response.data;
};

export const deleteCorrectiveAction = async (complaintId: string) => {
  const response = await httpClient.delete(
    `/cms/complaints/${complaintId}/corrective_action`,
  );

  return response.data;
};