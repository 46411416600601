import React from 'react';

import { CouponPropType } from './typedef';
import { isUsageLimitInfinity, getUsageLimit } from './utils';

const CouponUsage = ({ coupon }) => {
  const limitLabel = isUsageLimitInfinity(coupon)
    ? '∞'
    : getUsageLimit(coupon);

  const usageLabel = coupon.usage;

  return (
    <React.Fragment>
      {usageLabel} / {limitLabel}
    </React.Fragment>
  );
};

CouponUsage.propTypes = {
  coupon: CouponPropType.isRequired,
};

export default CouponUsage;
