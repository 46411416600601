import React from 'react';
import PropTypes from 'prop-types';

const Image = ({
  src,
  width,
  height,
  alt,
  objectFit,
  objectPosition,
  ...others
}) => {
  return (
    <img
      {...others}
      src={src}
      width={width}
      height={height}
      alt={alt}
      style={{ objectFit, objectPosition }}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  alt: PropTypes.string,
  objectFit: PropTypes.oneOf([
    'fill',
    'contain',
    'cover',
    'none',
    'scale-down',
  ]),
  objectPosition: PropTypes.string,
};

export default Image;
