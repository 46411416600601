import * as routePaths from './routePaths';

const navConfig = [
  {
    title: 'Analytics',
    children: [
      {
        title: 'Analytics',
        to: routePaths.ANALYTICS,
        icon: 'bx bx-analyse',
      },
    ],
  },
  {
    title: 'Users',
    to: '',
    children: [
      {
        title: 'Users',
        icon: 'bx bx-user',
        to: routePaths.USERS,
      },
    ],
  },
  {
    title: 'Products',
    children: [
      {
        title: 'Category',
        to: '',
        icon: 'bx bx-sitemap',
        children: [
          {
            title: 'Categories',
            to: routePaths.CATEGORIES,
          },
          {
            title: 'Add Category',
            to: routePaths.NEW_CATEGORY,
          },
        ],
      },
      {
        title: 'Product',
        to: '',
        icon: 'bx bx-detail',
        children: [
          {
            title: 'Products',
            to: routePaths.PRODUCTS,
          },
          {
            title: 'Add Product',
            to: routePaths.NEW_PRODUCT,
          },
        ],
      },
      {
        title: 'Collections',
        to: '/#',
        icon: 'bx bx-collection',
        children: [
          {
            title: 'Collections',
            to: routePaths.COLLECTIONS,
          },
          {
            title: 'Add Collection',
            to: routePaths.NEW_COLLECTION,
          },
        ],
      },
      {
        title: 'Manual Collection',
        to: '/#',
        icon: 'bx bx-book-content',
        children: [
          {
            title: 'Manual Collection',
            to: routePaths.CATALOGUES,
          },
          {
            title: 'Add Manual Collection',
            to: routePaths.NEW_CATALOGUE,
          },
        ],
      },
      {
        title: 'Tags',
        to: '/#',
        icon: 'bx bx-tag',
        children: [
          {
            title: 'Tags',
            to: routePaths.TAGS,
          },
          {
            title: 'Add Tags',
            to: routePaths.NEW_TAG,
          },
        ],
      },
    ],
  },
  {
    title: 'Meal',
    children: [
      {
        title: 'Ingredients',
        to: '',
        icon: 'mdi mdi-food-apple',
        children: [
          {
            title: 'Ingredients',
            to: routePaths.INGREDIENTS,
          },
          {
            title: 'Add Ingredient',
            to: routePaths.NEW_INGREDIENT,
          },
        ],
      },
      {
        title: 'Addon Options',
        to: '',
        icon: 'bx bx-list-ol',
        children: [
          {
            title: 'Addon Options',
            to: routePaths.ADDON_OPTIONS,
          },
          {
            title: 'Add Addon Option',
            to: routePaths.NEW_ADDON_OPTION,
          },
        ],
      },
      {
        title: 'Addon Rules',
        to: '',
        icon: 'bx bx-code-curly',
        children: [
          {
            title: 'Addon Rules',
            to: routePaths.ADDON_RULES,
          },
          {
            title: 'Add Addon Rule',
            to: routePaths.NEW_ADDON_RULE,
          },
        ],
      },
    ],
  },
  {
    title: 'Orders',
    children: [
      {
        title: 'Orders',
        to: routePaths.ORDERS,
        icon: 'bx bx-money',
      },
      {
        title: 'Orders Export',
        to: routePaths.ORDERS_EXPORT,
        icon: 'bx bx-export',
      },
    ],
  },
  {
    title: 'Marketing',
    children: [
      {
        title: 'Coupons',
        to: '',
        icon: 'bx bxs-coupon',
        children: [
          {
            title: 'Coupons',
            to: routePaths.COUPONS,
          },
          {
            title: 'Add Coupon',
            to: routePaths.NEW_COUPON,
          },
        ],
      },
    ],
  },
  {
    title: 'Events',
    children: [
      {
        title: 'Donations',
        to: '',
        icon: 'bx bxs-donate-heart',
        children: [
          {
            title: 'Donations',
            to: routePaths.DONATIONS,
          },
          {
            title: 'Add Donation Event',
            to: routePaths.NEW_DONATION,
          },
        ],
      },
    ],
  },
  {
    title: 'Operation',
    children: [
      {
        title: 'Operation Date Time',
        to: routePaths.OPERATION,
        icon: 'bx bx-calendar',
      },
      {
        title: 'Collection Points',
        to: '',
        icon: 'bx bx-map',
        children: [
          {
            title: 'Collection Points',
            to: routePaths.COLLECTION_POINTS,
          },
          {
            title: 'Add Collection Point',
            to: routePaths.NEW_COLLECTION_POINT,
          },
        ],
      },
    ],
  },
  {
    title: 'CRM',
    children: [
      {
        title: 'Feedback',
        to: '',
        icon: 'bx bx-sad',
        children: [
          {
            title: 'Feedback List',
            to: routePaths.COMPLAINTS,
          },
          {
            title: 'Feedback Categories',
            to: routePaths.COMPLAINT_CATEGORIES,
          },
        ],
      },
      {
        title: 'Love Messages',
        to: routePaths.LOVE_MESSAGES,
        icon: 'bx bx-heart',
      },
    ],
  },
  {
    title: 'Report',
    children: [
      {
        title: 'Product Kitchen Report',
        to: routePaths.REPORT_KITCHEN_PRODUCT,
        icon: 'bx bx-file-find',
      },
    ],
  },
];

export default navConfig;
