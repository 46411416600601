import React from 'react';
import { BulletList } from 'react-content-loader';
import { Price } from 'core/components';

const OrderDonation = ({ isLoading, order }) => {
  const { donation } = order || {};
  const donationDetail = donation?.detail || {};

  const renderContent = () => (
    <table className="table table-summary">
      <tbody>
        <tr>
          <td>Organization Name</td>
          <td>
            <a
              style={{ color: '#a42e27' }}
              href={donationDetail?.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {donationDetail?.organization_name}
            </a>
          </td>
        </tr>
        <tr>
          <td>Donation Amount</td>
          <td>
            <Price value={donationDetail?.donation_amount} />
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <div className="card card-dashboard">
      <div className="card-body">
        <h3 className="card-title mb-3">Donation Details</h3>
        {isLoading ? (
          <BulletList uniqueKey="order-donation-loading" />
        ) : (
          renderContent()
        )}
      </div>
    </div>
  );
};

export default OrderDonation;
