import { Modal } from 'components/Common';
import { Address as AddressInterface, AddressPayload } from './types';
import AddressForm from './AddressForm';

interface AddressFormModalProps {
  onSubmit: (payload: AddressPayload) => Promise<void>;
  isOpen: boolean;
  onHide: () => void;
  address?: AddressInterface;
  title?: string;
  className?: string;
}

const AddressFormModal = ({
  onSubmit,
  isOpen,
  onHide,
  address,
  title,
  className,
}: AddressFormModalProps) => (
  <Modal open={isOpen} onClose={onHide} className={className}>
    <button
      type="button"
      className="close"
      aria-label="Close"
      onClick={onHide}
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <div className="p-4">
      <AddressForm
        title={title}
        address={address}
        onSubmit={onSubmit}
      />
    </div>
  </Modal>
);

export default AddressFormModal;
