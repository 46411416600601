import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lib/javascript';

const SimpleTable = ({
  keyField,
  columns,
  data,
  classes,
  noDataIndication,
  renderBodyRow,
}) => {
  const headers = columns.map((column) => (
    <th key={column[keyField]}>{column.text}</th>
  ));

  const rows = data
    ? data.map((datum, rowIndex) => {
      const row = columns.map((column) => {
        const cellValue = get(datum, column.dataField);

        const toRenderInCell = column.formatter
          ? column.formatter(cellValue, datum, rowIndex)
          : cellValue;

        return <td className={column.classes}>{toRenderInCell}</td>;
      });

      return renderBodyRow(row, datum, rowIndex);
    })
    : [];

  return (
    <table
      className={classNames('table table-mobile', classes)}
      style={{
        display: 'block',
        overflowX: 'auto',
      }}
    >
      <thead>
        <tr>{headers}</tr>
      </thead>
      <tbody>{data && data.length > 0 ? rows : noDataIndication()}</tbody>
    </table>
  );
};

SimpleTable.propTypes = {
  keyField: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      /** the key of the data to access for this column */
      dataField: PropTypes.string.isRequired,
      /** label of the column header */
      text: PropTypes.string.isRequired,
      /** classes applied to the cell container */
      classes: PropTypes.string,
      /** custom renderer for the column. Signature: (cell, row, rowIndex) => React.Component | React.element */
      formatter: PropTypes.func,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.string,
  noDataIndication: PropTypes.func,
  renderBodyRow: PropTypes.func,
};

SimpleTable.defaultProps = {
  keyField: 'id',
  data: [],
  noDataIndication: () => 'No Data',
  renderBodyRow: (children, _rowData, rowIndex) => (
    <tr key={rowIndex}>{children}</tr>
  ),
};

export default SimpleTable;
