import React from 'react';
import PropTypes from 'prop-types';

import { AddonOptionValuePropType } from '../typedef';

const AddonOptionValues = ({ data, condensed }) => {
  if (condensed)
    return (
      <small>
        {data
          .map((addonOptionValue) => addonOptionValue.label)
          .join(', ')}
      </small>
    );

  return (
    <ul style={{ '-webkit-padding-start': 0, listStyle: 'none' }}>
      {data.map((addonOptionValue) => {
        return (
          <li key={addonOptionValue.id}>{addonOptionValue.label}</li>
        );
      })}
    </ul>
  );
};

AddonOptionValues.propTypes = {
  data: PropTypes.arrayOf(AddonOptionValuePropType),
  condensed: PropTypes.bool,
};

AddonOptionValues.defaultProps = {
  data: [],
  condensed: false,
};

export default AddonOptionValues;
