import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { routePaths } from 'appConfig';
import { Page, Button } from 'components/Common';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import { Tabs, Tab } from 'components/Common';
import { Link } from 'react-router-dom';
import {
  useProduct,
  constants,
  BasicDetailForm,
  ProductAssociationsConfig,
  TabContentForm,
} from 'lib/product';
import * as productVariants from 'lib/productVariants';
import * as productFamily from 'lib/productFamily';
import notification from 'lib/notification';
import { useRouter } from 'lib/router';
import { ProductAddonsDetails } from 'lib/productAddons';
import { ProductVariantNutritionForm } from 'lib/productVariantNutrition';
import { useConfirmation } from 'lib/confirm';

const commonTabs = [
  constants.TAB_IDS.BASIC_DETAILS,
  constants.TAB_IDS.TAB_CONTENT,
  constants.TAB_IDS.ASSOCIATIONS,
];

const tabsByProductFamily = {
  [productFamily.constants.PRODUCT_FAMILY.CUSTOMIZABLE]: [
    constants.TAB_IDS.INVENTORY,
    constants.TAB_IDS.ADDONS,
  ],
  [productFamily.constants.PRODUCT_FAMILY.NORMAL]: [
    constants.TAB_IDS.INVENTORY,
    constants.TAB_IDS.NUTRITION,
  ],
  [productFamily.constants.PRODUCT_FAMILY.MERCHANDISE]: [
    constants.TAB_IDS.VARIANTS,
  ],
};

const ProductDetailsPage = () => {
  const { productId } = useParams();
  const history = useHistory();
  const { confirm } = useConfirmation();
  const [isDeleting, setIsDeleting] = useState(false);

  const { product, isLoading, remove, mutate } = useProduct(
    productId,
  );

  const router = useRouter();

  const { tab } = router.query;

  const canAddVariant =
    !productVariants.utils.hasProductVariants(product) &&
    product?.product_family ===
      productFamily.constants.PRODUCT_FAMILY.MERCHANDISE;

  const defaultVariant = product?.variants[0];

  const defaultVariantId = defaultVariant?.id;

  const accessibleTabIds = [
    ...commonTabs,
    ...(tabsByProductFamily[product?.product_family] || []),
  ];

  const tabs = [
    {
      eventKey: constants.TAB_IDS.BASIC_DETAILS,
      title: 'Basic Details',
      children: <BasicDetailForm productId={productId} />,
    },
    {
      eventKey: constants.TAB_IDS.TAB_CONTENT,
      title: 'Tab Contents',
      children: <TabContentForm productId={productId} />,
    },
    {
      eventKey: constants.TAB_IDS.ASSOCIATIONS,
      title: 'Associations',
      children: <ProductAssociationsConfig productId={productId} />,
    },
    {
      eventKey: constants.TAB_IDS.INVENTORY,
      title: 'Inventory',
      children: (
        <productVariants.UpdateVariantForm
          variantId={defaultVariantId}
        />
      ),
    },
    {
      eventKey: constants.TAB_IDS.VARIANTS,
      title: 'Variants',
      children: (
        <React.Fragment>
          {canAddVariant && (
            <Row
              style={{
                marginBottom: '12px',
              }}
            >
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Link to={`/products/${productId}/variants/setup`}>
                  <Button>Setup Variants</Button>
                </Link>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <productVariants.VariantsTable
                onClickSubjectLinkTo={(row) => ({
                  pathname: routePaths.PRODUCT_VARIANT,
                  urlParams: {
                    productId,
                    variantId: row.id,
                  },
                })}
                data={product?.variants}
              />
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      eventKey: constants.TAB_IDS.ADDONS,
      title: 'Add Ons',
      children: <ProductAddonsDetails variantId={defaultVariantId} />,
    },
    {
      eventKey: constants.TAB_IDS.NUTRITION,
      title: 'Nutritional Value',
      children: (
        <ProductVariantNutritionForm
          productVariantId={defaultVariantId}
          initialValues={defaultVariant?.nutrition}
          onSubmit={(err) => !err && mutate()}
        />
      ),
    },
  ];

  const deleteProduct = async () => {
    try {
      await confirm({
        title: 'Delete Product?',
        content: 'This action is irreversible. Are you sure?',
      });
    } catch (err) {
      return;
    }

    try {
      setIsDeleting(true);

      await remove();

      history.replace(routePaths.PRODUCTS);

      notification.success('Successfully deleted product');
    } catch (err) {
      notification.error(
        'Something went wrong when deleting product',
      );
    } finally {
      setIsDeleting(false);
    }
  };

  if (isLoading) return 'loading... ';

  return (
    <Page
      breadcrumbProps={{
        config: {
          product: {
            path: routePaths.PRODUCT_DETAILS,
            label: product?.name ?? productId,
          },
        },
      }}
      content={
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '24px',
            }}
          >
            <Button
              color="danger"
              onClick={deleteProduct}
              loading={isDeleting}
            >
              Delete
            </Button>
          </div>
          <Card>
            <CardBody>
              <Tabs
                activeKey={tab}
                onSelect={(newKey) =>
                  router.push({
                    pathname: router.match.url,
                    queryParams: { tab: newKey },
                  })
                }
              >
                {tabs
                  .filter((tab) =>
                    accessibleTabIds.includes(tab.eventKey),
                  )
                  .map((accessibleTab) => (
                    <Tab
                      key={accessibleTab.eventKey}
                      eventKey={accessibleTab.eventKey}
                      title={accessibleTab.title}
                    >
                      <Container style={{ margin: '24px 0' }} fluid>
                        {accessibleTab.children}
                      </Container>
                    </Tab>
                  ))}
              </Tabs>
            </CardBody>
          </Card>
        </React.Fragment>
      }
    />
  );
};

ProductDetailsPage.propTypes = {};

export default ProductDetailsPage;
