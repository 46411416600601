import { useGet } from 'lib/swr-crud';
import {
  ComplaintCategoriesResponse,
  ComplaintCategoriesQueryParams,
} from './types';
import { AxiosError } from 'axios';
import { APIErrorResponse } from 'types/api';
import {
  deleteComplaintCategory,
  getComplaintCategories,
  getComplaintCategoriesUrl,
} from './api';

interface UseComplaintCategoriesOptions {
  params?: ComplaintCategoriesQueryParams;
}

const useComplaintCategories = ({
  params,
}: UseComplaintCategoriesOptions) => {
  const { data, isLoading, mutate } = useGet<
    ComplaintCategoriesResponse,
    AxiosError<APIErrorResponse>
  >(
    getComplaintCategoriesUrl(params),
    async () => await getComplaintCategories(params),
  );

  const total: number = data?.meta?.total ?? 0;

  const onDelete = async (complaintCategoryId: string) => {
    await deleteComplaintCategory(complaintCategoryId);
    mutate();
  };

  return {
    complaintCategories: data?.data ?? [],
    isLoading,
    total,
    mutate,
    onDelete,
  };
};

export default useComplaintCategories;
