import React, { useState } from 'react';
import { Icon, IconButton, SortableTable } from 'components/Common';
import { FormManager, Field, connectInput } from 'lib/form';
import { TextField } from 'components/formFields';
import notification from 'lib/notification';
import { useConfirmation } from 'lib/confirm';
import { IngredientSelector } from 'lib/ingredients';
import {
  DragHandler,
  SortableItem,
} from 'components/Common/SortableTable';

const IngredientSelectorField = connectInput(IngredientSelector, {
  onChangeArgType: 'raw-value',
});

const AddonOptionValueLines = ({
  addonOptionId,
  values,
  onUpdate,
  onDelete,
  onReorder,
  mutate,
}) => {
  const { confirm } = useConfirmation();
  const [loadingLineIds, setLoadingLineIds] = useState([]);

  const isLoadingLine = (id) => loadingLineIds.includes(id);
  const addLoadingLine = (id) =>
    setLoadingLineIds([...loadingLineIds, id]);
  const removeLoadingLine = (id) =>
    setLoadingLineIds(loadingLineIds.filter((line) => line === id));

  const _onDelete = (id) => {
    confirm({
      title: 'Delete Addon Option',
    }).then(async () => {
      try {
        addLoadingLine(id);
        await onDelete(id);

        mutate();
        notification.success('Deleted Addon Option');
      } catch (err) {
        notification.error(err?.errors?.message);
      } finally {
        removeLoadingLine(id);
      }
    });
  };

  const _onUpdate = async (id, values, actions) => {
    try {
      addLoadingLine(id);
      await onUpdate(id, values);

      mutate();
      notification.success('Updated Addon Option');
    } catch (err) {
      actions.setErrors(err?.errors);
      Object.values(err?.errors).forEach((err) => {
        notification.error(err);
      });
    } finally {
      removeLoadingLine(id);
    }
  };

  const _onSort = async (changed, before) => {
    try {
      addLoadingLine(changed.id);

      const beforeId = before ? before.id : null;

      await onReorder(changed.id, { before_id: beforeId });
      mutate();
    } catch (err) {
      const errorMessage = err?.errors?.message || err?.message;
      notification.error(errorMessage);
    } finally {
      removeLoadingLine(changed.id);
    }
  };

  const columns = [
    {
      dataField: 'sortable',
      formatter: (_cell, _row) => <DragHandler />,
    },
    {
      dataField: 'label',
      text: 'Label',
      classes: 'w-25',
      formatter: (_cell, row) => {
        return (
          <TextField name="label" disabled={isLoadingLine(row.id)} />
        );
      },
    },
    {
      dataField: 'ingredient',
      text: 'Ingredient',
      classes: 'w-25',
      formatter: (_cell, row) => {
        return (
          <IngredientSelectorField
            name="ingredient"
            isClearable
            disabled={isLoadingLine(row.id)}
          />
        );
      },
    },
    {
      dataField: 'price',
      text: 'Price',
      classes: 'w-25',
      formatter: (_cell, row) => {
        return (
          <TextField
            name="price"
            type="number"
            disabled={isLoadingLine(row.id)}
          />
        );
      },
    },
    {
      dataField: 'consume_quantity',
      text: 'Consume Quantity',
      classes: 'w-25',
      formatter: (_cell, row) => (
        <TextField
          name="consume_quantity"
          type="number"
          disabled={isLoadingLine(row.id)}
        />
      ),
    },
    {
      dataField: 'id',
      classes: 'remove-col',
      formatter: (id, row) => (
        <div className="d-flex">
          <Field>
            {({ form }) => (
              <IconButton
                className="mr-2"
                type="submit"
                color="success"
                onClick={form.submitForm}
                loading={isLoadingLine(id)}
              >
                <Icon name="content-save" />
              </IconButton>
            )}
          </Field>
          <IconButton
            onClick={() => _onDelete(row.id)}
            loading={isLoadingLine(id)}
            disabled={isLoadingLine(id)}
            color="danger"
          >
            <Icon name="delete" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <SortableTable
      columns={columns}
      dataSource={values}
      onSort={_onSort}
      noDataIndication={() => (
        <tr>
          <td colSpan={columns.length}>
            <p className="pl-2 pt-1 pb-1 text-center">
              {' '}
              No values in this Addon Option{' '}
            </p>
          </td>
        </tr>
      )}
      renderSortableItem={(children, row, index) => (
        <SortableItem
          index={index}
          key={row.id}
          disabled={isLoadingLine(row.id)}
        >
          <FormManager
            initialValues={row}
            onSubmit={(values, actions) => {
              const ingredient_id = values.ingredient?.id || null;

              _onUpdate(
                row.id,
                {
                  ...values,
                  ingredient_id,
                },
                actions,
              );
            }}
          >
            {() => children}
          </FormManager>
        </SortableItem>
      )}
    />
  );
};

export default AddonOptionValueLines;
