import React from 'react';
import { Row, Col, Alert } from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import yup from 'lib/validator';

import config from 'appConfig';
import { Layout as AuthLayout } from 'lib/auth';
import { FormManager, Form } from 'lib/form';
import { TextField } from 'components/formFields';
import { Button } from 'components/Common';

// action
import { userForgetPassword } from '../../store/actions';

const initialValues = {
  email: '',
};

const validationSchema = yup.object({
  email: yup.string().email().required(),
});

const ForgetPasswordPage = (props) => {
  const handleValidSubmit = async (values, actions) => {
    props.userForgetPassword(values, props.history, actions);
  };

  return (
    <AuthLayout
      title="Welcome Back! "
      subtitle={`Sign in to continue to ${config.appName}.`}
      content={
        <React.Fragment>
          {props.forgetSuccessMsg ? (
            <Alert color="success" style={{ marginTop: '13px' }}>
              {props.forgetSuccessMsg}
            </Alert>
          ) : null}

          <FormManager
            className="form-horizontal mt-4"
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleValidSubmit}
          >
            {() => (
              <Form>
                <TextField
                  name="email"
                  label="Email"
                  className="form-control"
                  placeholder="Enter email"
                  type="email"
                  required
                />
                <Row className="form-group">
                  <Col className="text-right">
                    <Button type="submit">Reset</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </FormManager>
        </React.Fragment>
      }
      footer={
        <p>
          Go back to{' '}
          <Link
            to="login"
            className="font-weight-medium text-primary"
          >
            Login
          </Link>{' '}
        </p>
      }
    />
  );
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(
    ForgetPasswordPage,
  ),
);
