import React from 'react';
import { getUserFullName } from './utils';
import { UserPropType } from './typedef';

const FullName = ({ user }) => {
  return (
    <React.Fragment>
      {getUserFullName(user) || '(full name N/A)'}
    </React.Fragment>
  );
};

FullName.propTypes = {
  user: UserPropType.isRequired,
};

export default FullName;
