import { Order as OrderInterface } from 'lib/orders';
import { Card, Col, Row } from 'react-bootstrap';
import Address from './Address';
import AddressFormModal from '../AddressFormModal';
import { useState } from 'react';
import {
  Address as AddressInterface,
  AddressPayload,
} from '../types';
// @ts-ignore-line
import { omit } from 'lib/javascript';
import { getDialogTitle } from './getDialogTitle';

interface AddressesProps {
  order: OrderInterface;
  onSubmit: ({
    addresses,
  }: {
    addresses: {
      shipping: AddressInterface;
      billing: AddressInterface;
    };
  }) => Promise<void>;
}

const Addresses = ({ order, onSubmit }: AddressesProps) => {
  const [addressPayload, setAddressPayload] = useState<
    AddressInterface | undefined
  >(undefined);
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);
  const shippingAddress: AddressInterface = {
    ...order.addresses.shipping,
    id: `addressOrder-${order.id}`,
    type: 'shipping',
    is_order_address: true,
  };
  const billingAddress: AddressInterface = {
    ...order.addresses.billing,
    id: `addressOrder-${order.id}`,
    type: 'billing',
    is_order_address: true,
  };

  const onCloseAddressForm = () => {
    setIsOpenAddressModal(false);
    setAddressPayload(undefined);
  };

  const onSetEditAddress = (address: AddressInterface) => {
    setAddressPayload(address);
    setIsOpenAddressModal(true);
  };

  const handleOnSubmit = async (payload: AddressPayload) => {
    try {
      const addressType = payload.type;

      if (!addressType) return;
      const newPayload = omit(payload, ['id']);
      const addresses = {
        ...order.addresses,
        [addressType]: newPayload as AddressInterface,
      };
      await onSubmit({ addresses });
      onCloseAddressForm();
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <Address
                address={shippingAddress}
                pinned={false}
                isOrderAddress
                onSetEditAddress={onSetEditAddress}
                title="Delivery Details"
              />
            </Col>
            <Col sm={6}>
              <Address
                address={billingAddress}
                pinned={false}
                isOrderAddress
                onSetEditAddress={onSetEditAddress}
                title="Billing Details"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <AddressFormModal
        address={addressPayload}
        isOpen={isOpenAddressModal}
        onHide={onCloseAddressForm}
        onSubmit={handleOnSubmit}
        title={getDialogTitle(addressPayload)}
      />
    </>
  );
};

export default Addresses;
