import React from 'react';
import PropTypes from 'prop-types';

import { Price, Table } from 'core/components';
import { Link, useRouter } from 'lib/router';
import { Datetime } from 'lib/datetime';
import useSalesReportByCoupon from './useSalesReportByCoupon';
import { getLabelByIsPercent } from 'lib/coupons';
import { routePaths } from 'appConfig';

const ReportByCouponTable = ({ startDate, endDate }) => {
  const { query, updateQueryParams } = useRouter();

  const { sizePerPage, page } = query;

  const {
    isLoading,
    salesReportByCoupon,
    total,
  } = useSalesReportByCoupon({
    startDate,
    endDate,
    sizePerPage,
    page,
  });

  const columns = [
    {
      dataField: 'coupon_code',
      text: 'Coupon Code',
      formatter: (coupon_code, coupon) => (
        <Link to={`${routePaths.COUPONS}/${coupon.coupon_code}`}>
          {coupon_code}
        </Link>
      ),
    },
    {
      dataField: 'total_orders',
      text: 'Orders',
    },
    {
      dataField: 'total_discount',
      text: 'Amount Discounted',
      formatter: (netSales) => <Price value={netSales} />,
    },
    {
      dataField: 'coupon_created_at',
      text: 'Created',
      formatter: (createdAt) => <Datetime value={createdAt} />,
    },
    {
      dataField: 'coupon_end_at',
      text: 'Expires',
      formatter: (createdAt) => <Datetime value={createdAt} />,
    },
    {
      dataField: 'coupon_is_percent',
      text: 'Type',
      formatter: (isPercent) => getLabelByIsPercent(isPercent),
    },
  ];

  const onTableChange = (action, newState) => {
    switch (action) {
      case 'pagination':
        const { sizePerPage, page } = newState;
        updateQueryParams({ sizePerPage, page });
        break;

      default:
    }
  };

  return (
    <Table
      remote
      loading={isLoading}
      keyField="coupon_code"
      data={salesReportByCoupon || []}
      columns={columns}
      pagination={{ page, sizePerPage, totalSize: total }}
      onTableChange={onTableChange}
    />
  );
};

ReportByCouponTable.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default ReportByCouponTable;
