import {
  AutoComplete,
  SortableAutoComplete,
} from 'components/Common';
import {
  ComplaintCategory,
  useComplaintCategories,
} from 'lib/complaint-categories';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const ComplaintCategoryAutoComplete = ({
  name,
  value,
  onChange,
  label,
  required,
  error,
  helperText,
  isMulti,
  isLoading,
  disabled,
  sortable,
}) => {
  const [page, _setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(15);

  const {
    complaintCategories,
    isLoading: isLoadingProp,
    total,
    mutate,
  } = useComplaintCategories({
    params: { page, perPage: sizePerPage },
  });

  useEffect(() => {
    if (complaintCategories.length !== total) {
      setSizePerPage(total);
    }
  }, [complaintCategories.length, total]);

  const shouldDisable = disabled || isLoading;

  const getOptionLabel = (category: ComplaintCategory) =>
    category.name;
  const Component = sortable ? SortableAutoComplete : AutoComplete;

  return (
    <Component
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      options={complaintCategories}
      getOptionLabel={getOptionLabel}
      getOptionValue={(category) => category.id}
      isMulti={isMulti}
      isLoading={isLoading || isLoadingProp}
      disabled={shouldDisable}
      formatOptionLabel={undefined}
      disableOptionGroup={undefined}
      components={undefined}
    />
  );
};

ComplaintCategoryAutoComplete.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

ComplaintCategoryAutoComplete.defaultProps = {
  isMulti: false,
  isLoading: false,
  disabled: false,
};

export default ComplaintCategoryAutoComplete;
