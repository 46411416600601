import filterFactory, {
  textFilter,
  selectFilter,
  multiSelectFilter,
  numberFilter,
  dateFilter,
  customFilter,
  Comparator,
  FILTER_TYPES,
} from 'react-bootstrap-table2-filter';

export default filterFactory;

export {
  textFilter,
  selectFilter,
  multiSelectFilter,
  numberFilter,
  dateFilter,
  customFilter,
  Comparator,
  FILTER_TYPES,
};
