import { routePaths } from 'appConfig';
import { Page } from 'components/Common';
import { CollectionPoint, createCollectionPoint } from 'lib/collection-points';
import { CollectionPointForm } from 'lib/collection-points';
import notification from 'lib/notification';
import { useRouter } from 'lib/router';
import React from 'react';

const NewCollectionPointPage = () => {
  const router = useRouter()

  const handleOnSubmit = async (collectionPoint: CollectionPoint) => {
    try {
      await createCollectionPoint(collectionPoint);

      notification.success('Successfully created collection point');
      // @ts-ignore
      router.push({ pathname: routePaths.COLLECTION_POINTS });
    } catch (error) {
      notification.error(error.errors?.message || 'Error creating collection point');
    }
  }

  return (
    <Page
      content={
        <React.Fragment>
          <CollectionPointForm onSubmit={handleOnSubmit} />
        </React.Fragment>
      }
    />
  );
};

export default NewCollectionPointPage;
