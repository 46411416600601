import { useGet } from 'lib/swr-crud';
import { ComplaintsQueryParams, ComplaintsResponse } from './types';
import { AxiosError } from 'axios';
import { APIErrorResponse } from 'types/api';
import {
  deleteComplaint,
  getComplaints,
  getComplaintsUrl,
} from './api';

interface UseComplaintsOptions {
  params?: ComplaintsQueryParams;
}

const useComplaints = ({ params }: UseComplaintsOptions) => {
  const { data, isLoading, mutate } = useGet<
    ComplaintsResponse,
    AxiosError<APIErrorResponse>
  >(
    getComplaintsUrl(params),
    async () => await getComplaints(params),
  );

  const total: number = data?.meta?.total ?? 0;

  const onDelete = async (complaintId: string) => {
    await deleteComplaint(complaintId);
    mutate();
  };

  return {
    complaints: data?.data ?? [],
    isLoading,
    total,
    mutate,
    onDelete,
  };
};

export default useComplaints;
