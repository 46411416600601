/**
 * @enum {string}
 */
export const DEFAULT_DESCRIPTION = {
  created: 'created',
  updated: 'updated',
  deleted: 'deleted',
};

/**
 * @enum {string}
 */
export const LOG_NAME = {
  default: 'default',
};

/**
 * @enum {string}
 */
export const COUPON_OPERATOR = {
  and: 'and',
  or: 'or',
};

/**
 * @typedef Log
 * @type Object
 *
 * @property {number} id
 * @property {Causer} causer
 * @property {LOG_NAME} log_name
 * @property {DEFAULT_DESCRIPTION} description
 * @property {Properties} properties
 * @property {string} created_at
 * @property {string} updated_at
 */

/**
 * @typedef Causer
 * @type Object
 *
 * @property {string} user_id
 * @property {string} email
 * @property {string} first_name
 * @property {string} last_name
 */

/**
 * @typedef Properties
 * @type Object
 *
 * @property {LogDetail} old
 * @property {LogDetail} new
 */

/**
 * @typedef LogDetail
 * @type Object
 *
 * @property {(string|null)} coupon_code
 * @property {(string|null)} description
 * @property {(string|null)} custom_error_message
 * @property {Rule} rules
 * @property {(string|null)} discount_target
 * @property {(StaticLog|null)} referrer_user
 * @property {(boolean|null)} is_percent
 * @property {(number|null)} discount_value
 * @property {(string|null)} start_at
 * @property {(string|null)} end_at
 * @property {(boolean|null)} is_active
 */

/**
 * @typedef Rule
 * @type Object
 *
 * @property {ItemRule} product
 * @property {ItemRule} category
 * @property {UserRule} user
 * @property {(boolean|null)} is_valid_with_excluded_item
 * @property {(number|null)} min_purchase_amount
 * @property {(number|null)} max_purchase_amount
 * @property {(number|null)} min_purchase_quantity
 * @property {(boolean|null)} is_first_order_only
 * @property {(boolean|null)} is_free_shipping
 * @property {(number|null)} bundle_quantity
 * @property {(boolean|null)} is_bundle_unique
 * @property {(number|null)} usage_limit
 * @property {(number|null)} usage_limit_per_user
 */

/**
 * @typedef StaticLog
 * @type Object
 *
 * @property {string} id
 * @property {string} value
 */

/**
 * @typedef ItemRule
 * @type Object
 *
 * @property {COUPON_OPERATOR} operator
 * @property {(StaticLog[]|null)} included
 * @property {(StaticLog[]|null)} excluded
 */

/**
 * @typedef UserRule
 * @type Object
 *
 * @property {(StaticLog[]|null)} allowed
 */
