import httpClient from 'lib/httpClient';

import * as api from './api';

export const createProcessServerMethod = ({
  method = 'post',
  url,
  onFormData,
  onResponse,
  mapResponseToLoad,
}) => {
  return (
    fieldName,
    file,
    _metadata,
    load,
    error,
    progress,
    abort,
  ) => {
    // related to aborting the request
    const CancelToken = httpClient.CancelToken;
    const source = CancelToken.source();

    api
      .process({
        method,
        url,
        fieldName,
        file,
        cancelToken: source.token,
        onFormData,
        onUploadProgress: (e) => {
          // updating progress indicator
          progress(e.lengthComputable, e.loaded, e.total);
        },
      })
      .then(({ response, error }) => {
        if (error) throw error;

        onResponse(response);

        const toLoad = mapResponseToLoad(response);

        load(toLoad); // passing the file id to FilePond
      })
      .catch((thrown) => {
        if (httpClient.isCancel(thrown)) {
          error('User cancelled. ');
        } else {
          error(thrown);
        }
      });

    // Setup abort interface
    return {
      abort: () => {
        source.cancel('Operation canceled by the user.'); // Cancel HTTP request

        abort(); // Let FilePond know the request has been cancelled
      },
    };
  };
};
