import React from 'react';
import PropTypes from 'prop-types';

import { makePrice } from 'core/utils';
import { Select } from 'components/Common';
import { AddonOptionPropType } from '../typedef';
import { isIngredientOutOfStock } from '../utils';

const AddonSelect = ({ addonOption, value, onChange }) => {
  const makeAddonOptionValueLabel = (addonOptionValue) => {
    const { label, price = 0 } = addonOptionValue;

    let pricePrefix;
    if (price >= 0) pricePrefix = '+';

    return `${label} (${pricePrefix}${makePrice(price)})`;
  };

  const addonOptionValueToSelectOption = React.useCallback(
    (addonOptionValue) => {
      return {
        label: makeAddonOptionValueLabel(addonOptionValue),
        value: addonOptionValue.id,
        disabled: isIngredientOutOfStock(addonOptionValue),
      };
    },
    [],
  );

  const options = React.useMemo(() => {
    const emptyOption = {
      hidden: true,
      value: undefined,
      label: 'Please select',
    };

    return [
      emptyOption,
      ...addonOption.values.map(addonOptionValueToSelectOption),
    ];
  }, [addonOption.values, addonOptionValueToSelectOption]);

  return (
    <Select
      name={addonOption.id}
      label={addonOption.label}
      options={options}
      value={value}
      onChange={(e) => {
        onChange(addonOption, e.target.value);
      }}
    />
  );
};

AddonSelect.propTypes = {
  addonOption: AddonOptionPropType,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default AddonSelect;
