import React from 'react';

import { routePaths } from 'appConfig';
import { Page } from 'components/Common';
import notification from 'lib/notification';
import { List } from 'react-content-loader';
import { useCollection } from 'lib/collection';
import { useHistory, useParams } from 'react-router';
import CollectionDetails from 'lib/collection/CollectionDetails';
import { useConfirmation } from 'lib/confirm';

const CollectionDetailsPage = () => {
  const history = useHistory();
  const { collectionId } = useParams();
  const {
    collection,
    mutate,
    isLoading,
    update,
    _delete,
  } = useCollection(collectionId);
  const { confirm } = useConfirmation();

  const [isDeleting, setIsDeleting] = React.useState(false);

  const updateCollection = async (values, actions) => {
    try {
      const updated = await update(values);
      notification.success('Successfully updated collection');
      mutate(updated);
    } catch (err) {
      // actions.setErrors(err.errors);
      // either notification or field error
      let msg = err;
      if (typeof err === 'object') {
        const _errors = err.errors;
        const _keys = Object.keys(_errors);
        if (_errors && _keys.length > 0) {
          msg = _errors[_keys[0]];
        }
      }

      notification.error(msg);
    }
  };

  const deleteCollection = async () => {
    await confirm();

    try {
      setIsDeleting(true);

      await _delete();

      history.replace(routePaths.COLLECTIONS);

      notification.success('Successfully deleted collection');
    } catch (err) {
      const errorMessage =
        err.errors.message ||
        'Something went wrong when deleting collection';

      notification.error(errorMessage);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Page
      content={
        <React.Fragment>
          {isLoading ? (
            <List />
          ) : (
            <CollectionDetails
              collection={collection}
              isDeleting={isDeleting}
              onUpdate={updateCollection}
              onDelete={deleteCollection}
            />
          )}
        </React.Fragment>
      }
    />
  );
};

CollectionDetailsPage.propTypes = {};

export default CollectionDetailsPage;
