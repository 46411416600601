import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { Button, Modal, DatePicker } from 'components/Common';
import { Price } from 'core/components';
import Table, {
  selectFilter,
  textFilter,
  customFilter,
} from 'core/components/Table';
import datetime, { Datetime } from 'lib/datetime';
import { Link } from 'lib/router';
import notification from 'lib/notification';
import { uniq, mapValues, isEmpty } from 'lib/javascript';

import OrderStatus from './OrderStatus';
import OrderNumber from './OrderNumber';
import useOrderStatus from './useOrderStatus';
import {
  formatDeliveryDateTimeRange,
  mapOrderStatusToOptions,
} from './utils';
import { putUpdateOrderStatus } from './api';
import BulkUpdateOrderStatusForm from './BulkUpdateOrderStatusForm';
import FirstOrderBadge from './FirstOrderBadge';
import { FullName, UserBadges } from 'lib/users';
import KitchenBadge from './KitchenBadge';

const DeliveryDateTimeRangeFilter = ({ filterValue, onFilter }) => {
  const { startDeliveryDateTime, endDeliveryDateTime } = filterValue;

  return (
    <Row>
      <Col xs={12}>
        <DatePicker
          value={startDeliveryDateTime}
          onChange={(startDateTime) => {
            onFilter({
              startDateTime: datetime(startDateTime)
                .startOf('day')
                .toISOString(),
              endDateTime: endDeliveryDateTime,
            });
          }}
        />
      </Col>
      <Col xs={12}>-</Col>
      <Col xs={12}>
        <DatePicker
          value={endDeliveryDateTime}
          onChange={(endDateTime) => {
            onFilter({
              endDateTime: datetime(endDateTime)
                .endOf('day')
                .toISOString(),
              startDateTime: startDeliveryDateTime,
            });
          }}
        />
      </Col>
    </Row>
  );
};

const OrdersTable = ({
  loading,
  data,
  page,
  sizePerPage,
  totalSize,
  getPathToDetailsPage,
  sortField,
  sortDir,
  updateQueryParams,
  setFilterParams,
  mutateOrders,
  isHideUserColumn,
}) => {
  const [updatingOrderIds, setUpdatingOrderIds] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [
    showBulkUpdateStatusModal,
    setShowBulkUpdateStatusModal,
  ] = React.useState(false);
  const [
    deliveryDateTimeRange,
    setDeliveryDateTimeRange,
  ] = React.useState({
    startDeliveryDateTime: null,
    endDeliveryDateTime: null,
  });

  const { orderStatus } = useOrderStatus();

  const isOrderUpdating = (orderId) => {
    return updatingOrderIds.includes(orderId);
  };

  const onTableChange = (action, newState) => {
    switch (action) {
      case 'pagination':
        updateQueryParams({
          page: newState.page,
          sizePerPage: newState.sizePerPage,
        });
        break;

      case 'filter':
        const mappedFilters = mapValues(
          newState.filters,
          (filter) => filter.filterVal,
        );

        const orderNumber = mappedFilters['order_number'];
        const status = mappedFilters['status'];
        const customerId = mappedFilters['user.user_id'];
        const deliveryStartDateTime =
          mappedFilters['delivery_datetime']?.startDateTime;
        const deliveryEndDateTime =
          mappedFilters['delivery_datetime']?.endDateTime;

        setFilterParams({
          orderNumber,
          status,
          customerId,
          deliveryStartDateTime,
          deliveryEndDateTime,
        });
        updateQueryParams({
          page: 1,
          sizePerPage: newState.sizePerPage,
        });

        break;

      case 'sort':
        const { sortField, sortOrder } = newState;

        updateQueryParams({
          sortField,
          sortDir: sortOrder,
        });

        break;

      default:
    }
  };

  const handleOnSelectRows = (isSelect, rows) => {
    if (isSelect)
      setSelectedRows((selectedRows) => [...selectedRows, ...rows]);
    else
      setSelectedRows((selectedRows) =>
        selectedRows.filter(
          (selectedRow) => !rows.includes(selectedRow),
        ),
      );
  };

  const handleOnOrderStatusChange = async (
    orderId,
    newOrderStatus,
  ) => {
    try {
      setUpdatingOrderIds((updatingOrderIds) =>
        uniq([...updatingOrderIds, orderId]),
      );

      const updatedOrder = await putUpdateOrderStatus(
        orderId,
        newOrderStatus,
      );

      await mutateOrders((orders) =>
        orders.map((order) =>
          order.id === orderId
            ? { ...order, status: updatedOrder.status }
            : order,
        ),
      );

      setUpdatingOrderIds((updatingOrderIds) =>
        updatingOrderIds.filter(
          (updatingOrderId) => updatingOrderId !== orderId,
        ),
      );

      notification.success('Successfully updated order status');
    } catch (err) {
      notification.error(
        'Failed to update order status',
        err.message,
      );
    }
  };

  const userColumns = [
    {
      dataField: 'user.user_id',
      text: 'Customer ID',
      filter: textFilter({
        delay: 1000,
      }),
    },
    {
      dataField: 'user',
      text: 'Customer Name',
      formatter: (user) => {
        if (!user) return null;
        return (
          <div className="d-flex flex-row align-items-center">
            <FullName user={user} />
            <UserBadges data={user} />
          </div>
        );
      },
    },
  ];

  const columns = [
    {
      dataField: 'order_number',
      text: 'Order Number',
      formatter: (orderNumber, order) => (
        <React.Fragment>
          <Link to={getPathToDetailsPage(order)}>
            <div className="d-flex align-items-center">
              <OrderNumber number={orderNumber} />
              {order.is_first_order && (
                <FirstOrderBadge className="ml-1" />
              )}
              <KitchenBadge
                kitchenName={order?.kitchen?.display_name ?? ''}
              />
            </div>
          </Link>
          {order.donation.has_donation ? (
            <span className="ml-2" title="Has donation">
              <i className="bx bxs-donate-heart"></i>
            </span>
          ) : null}
        </React.Fragment>
      ),
      filter: textFilter({ delay: 1500 }),
    },
    {
      dataField: 'created_at',
      text: 'Date',
      formatter: (updatedAt) => <Datetime value={updatedAt} />,
      sort: true,
    },
    ...(isHideUserColumn ? [] : userColumns),
    {
      dataField: 'status',
      text: 'Status',
      formatter: (_status, order) => {
        return <OrderStatus order={order} />;
      },
      filter: selectFilter({
        options: mapOrderStatusToOptions(orderStatus),
        placeholder: 'All',
      }),
    },
    {
      dataField: 'delivery_datetime',
      text: 'Delivery Date Time',
      formatter: (dateTime, row) => {
        return <p>{formatDeliveryDateTimeRange(dateTime)}</p>;
      },
      filter: customFilter(),
      filterRenderer: (onFilter, column) => (
        <DeliveryDateTimeRangeFilter
          filterValue={deliveryDateTimeRange}
          onFilter={({ startDateTime, endDateTime }) => {
            onFilter({
              startDateTime,
              endDateTime,
            });
            setDeliveryDateTimeRange({
              startDeliveryDateTime: startDateTime,
              endDeliveryDateTime: endDateTime,
            });
          }}
          column={column}
        />
      ),
    },
    {
      dataField: 'grand_total',
      text: 'Total',
      formatter: (grandTotal) => <Price value={grandTotal} />,
    },
  ];

  return (
    <div>
      {!isEmpty(selectedRows) && (
        <div
          style={{
            padding: 8,
            backgroundColor: '#f8f9fa',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setShowBulkUpdateStatusModal(true)}
          >
            Bulk Update Order Status
          </Button>
        </div>
      )}
      <Table
        remote
        loading={loading}
        keyField="id"
        columns={columns}
        data={data}
        pagination={{ page, sizePerPage, totalSize }}
        onTableChange={onTableChange}
        sort={{ dataField: sortField, order: sortDir }}
        selectRow={{
          mode: 'checkbox',
          onSelect: (row, isSelect) =>
            handleOnSelectRows(isSelect, [row]),
          onSelectAll: (isSelect, rows) =>
            handleOnSelectRows(isSelect, rows),
        }}
      />
      <Modal
        open={showBulkUpdateStatusModal}
        onClose={() => setShowBulkUpdateStatusModal(false)}
        title={'Bulk Update Order Status'}
        content={
          <BulkUpdateOrderStatusForm
            orderIds={selectedRows.map((order) => order.id)}
            onSubmit={(err) => {
              if (err) {
                return;
              }

              setShowBulkUpdateStatusModal(false);
              mutateOrders();
            }}
          />
        }
      />
    </div>
  );
};

OrdersTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  getPathToDetailsPage: PropTypes.func.isRequired,
  sortField: PropTypes.string,
  sortDir: PropTypes.string,
  updateQueryParams: PropTypes.func.isRequired,
  setFilterParams: PropTypes.func.isRequired,
  mutateOrders: PropTypes.func.isRequired,
  isHideUserColumn: PropTypes.bool,
};

OrdersTable.defaultProps = {
  loading: false,
  isHideUserColumn: false,
};

export default OrdersTable;
