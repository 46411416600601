import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import Label from '../Label';
import styles from './PhoneNumberInput.module.scss';

const DEFAULT_COUNTRY = 'sg';

const PhoneNumberInput = ({
  name,
  placeholder,
  required,
  error,
  label,
  helperText,
  value,
  onChange,
  onBlur,
  disabled,
  disableDropdown,
  disableCountryCode,
  countryCodeEditable,
  country,
  onlyCountries,
}) => {
  return (
    <div className="form-group">
      <Label htmlFor={name} required={required} error={error}>
        {label}
      </Label>
      <PhoneInput
        country={country || onlyCountries?.[0]}
        onlyCountries={onlyCountries}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        disableDropdown={disableDropdown}
        disableCountryCode={disableCountryCode}
        countryCodeEditable={countryCodeEditable}
        inputProps={{ name, required }}
        inputClass={styles['form-control']}
      />

      {helperText && (
        <p style={error ? { color: 'red' } : {}}>{helperText}</p>
      )}
    </div>
  );
};

PhoneNumberInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  disableDropdown: PropTypes.bool,
  disableCountryCode: PropTypes.bool,
  countryCodeEditable: PropTypes.bool,
  country: PropTypes.string,
  onlyCountries: PropTypes.arrayOf(PropTypes.string),
};

PhoneNumberInput.defaultProps = {
  onBlur: () => {},
  error: false,
  disabled: false,
  countryCodeEditable: true,
  disableCountryCode: false,
  country: DEFAULT_COUNTRY,
};

export default PhoneNumberInput;
