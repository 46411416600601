import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'core/components';
import { ReferralRewardCouponPropType } from '../typedef';
import { Datetime } from 'lib/datetime';
import { Link } from 'lib/router';
import { routePaths } from 'appConfig';

const ReferralRewardCouponsTable = ({
  isLoading,
  data,
  currentPage,
  total,
  sizePerPage,
  onChangePage,
  onChangeSizePerPage,
}) => {
  const columns = [
    {
      dataField: 'coupon_code',
      text: 'Coupon Code',
      formatter: (couponCode) => (
        <Link
          to={{
            pathname: routePaths.COUPON_DETAILS,
            urlParams: {
              couponCode,
            },
          }}
        >
          {couponCode}
        </Link>
      ),
    },
    {
      dataField: 'end_at',
      text: 'Expiry Date',
      formatter: (expiryDate) => <Datetime value={expiryDate} />,
    },
    {
      dataField: 'usage',
      text: 'Used',
      formatter: (usage, rewardCoupon) => {
        const used =
          usage / rewardCoupon.usage_limit.per_user.limit === 1;

        return used ? 'Used' : 'Not Used';
      },
    },
  ];

  const onTableChange = (type, newState) => {
    switch (type) {
      case 'pagination':
        const { page, sizePerPage } = newState;
        onChangePage(page);
        onChangeSizePerPage(sizePerPage);
        break;

      default:
    }
  };

  return (
    <div>
      <Table
        remote
        keyField="coupon_code"
        loading={isLoading}
        columns={columns}
        data={data}
        noDataIndication={() => (
          <tr>
            <td>
              <p>No Data</p>
            </td>
          </tr>
        )}
        pagination={{
          page: currentPage,
          totalSize: total,
          sizePerPage,
          sizePerPageList: [
            {
              text: '5',
              value: 5,
            },
            {
              text: '10',
              value: 10,
            },
            {
              text: '15',
              value: 15,
            },
          ],
        }}
        onTableChange={onTableChange}
      />
    </div>
  );
};

ReferralRewardCouponsTable.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(ReferralRewardCouponPropType),
  currentPage: PropTypes.number,
  total: PropTypes.number,
  sizePerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeSizePerPage: PropTypes.func,
};

ReferralRewardCouponsTable.defaultProps = {
  isLoading: false,
  data: [],
  currentPage: 1,
  total: 0,
  sizePerPage: 5,
  onChangePage: () => {},
  onChangeSizePerPage: () => {},
};

export default ReferralRewardCouponsTable;
