import React from 'react';

import datetime from 'lib/datetime';
import validator from 'lib/validator';
import { FormManager, Form, connectInput } from 'lib/form';
import { DatePicker, Button } from 'components/Common';

import OrderStatusAutoComplete from './OrderStatusAutoComplete';
import useOrdersExport from './useOrdersExport';
import useOrderStatus from './useOrderStatus';
import { mapOrderStateToOptionValue } from './utils';

const ISO_DATE_FORMAT = 'YYYY-MM-DD';

const DatePickerField = connectInput(DatePicker, {
  onChangeArgType: 'raw-value',
});

const OrderStatusAutoCompleteField = connectInput(
  OrderStatusAutoComplete,
  {
    onChangeArgType: 'raw-value',
  },
);

const validationSchema = validator.object({
  delivery_start: validator.string().required(),
  delivery_end: validator.string().required(),
  order_statuses: validator.array().of(validator.string()).required(),
});

const INITIAL_ORDER_STATUS_TO_EXPORT = ['payment-received'];

const isInitialOrderStatusToExport = (orderStatus) =>
  INITIAL_ORDER_STATUS_TO_EXPORT.includes(orderStatus.name);

const ExportOrdersForm = () => {
  const { orderStatus } = useOrderStatus();
  const { requestOrdersExport } = useOrdersExport();

  const initialValues = {
    delivery_start: datetime().format(ISO_DATE_FORMAT),
    delivery_end: datetime().format(ISO_DATE_FORMAT),
    order_statuses: orderStatus
      ?.filter(isInitialOrderStatusToExport)
      ?.map(mapOrderStateToOptionValue),
  };

  const handleOnSubmit = ({
    delivery_start,
    delivery_end,
    order_statuses,
  }) => {
    return requestOrdersExport({
      delivery_start,
      delivery_end,
      order_statuses,
    });
  };

  return (
    <FormManager
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <DatePickerField
            name="delivery_start"
            label="Delivery Start At"
            outputFormat={ISO_DATE_FORMAT}
            required
          />
          <DatePickerField
            name="delivery_end"
            label="Delivery End At"
            outputFormat={ISO_DATE_FORMAT}
            required
          />
          <OrderStatusAutoCompleteField
            name="order_statuses"
            label="Order Status"
            isMulti
            required
          />
          <Button type="submit" loading={isSubmitting}>
            Export
          </Button>
        </Form>
      )}
    </FormManager>
  );
};

ExportOrdersForm.propTypes = {};

export default ExportOrdersForm;
