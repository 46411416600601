import useSession from 'lib/useSession';
import {
  createNewAddress,
  deleteAddress,
  getUserAddresses,
  getUserAddressesUrl,
  markAsDefault,
  putUpdateAddressById,
} from './api';
import {
  Address,
  AddressPayload,
  GetUserAddressesQueryParams,
  GetUserAddressesResponse,
  GetUserAddressResponse,
} from './types';
import { AxiosError } from 'axios';
import { APIErrorResponse } from 'types/api';
import { useGet } from 'lib/swr-crud';

interface UseUserAddressesOptions
  extends GetUserAddressesQueryParams {
  userId: string;
}

const useUserAddresses = ({
  userId,
  page = 1,
  perPage = 100,
}: UseUserAddressesOptions) => {
  const { session, loading } = useSession();

  const shouldFetch = !loading && session && userId;

  const { data, error, isLoading, mutate } = useGet<
    GetUserAddressesResponse,
    AxiosError<APIErrorResponse>
  >(
    shouldFetch
      ? getUserAddressesUrl(userId, { page, perPage })
      : null,
    async () => await getUserAddresses(userId, { page, perPage }),
  );

  const prevAddressData = data;

  const mutateAnAddress = (
    addressId: string,
    newAddress: AddressPayload,
  ) =>
    mutate((currentData) => {
      if (!currentData) return prevAddressData;

      const updatedData = currentData.data.map((address) =>
        address.id === addressId ? newAddress : address,
      );

      return {
        ...currentData,
        updatedData,
      };
    });

  const createAddress = async (
    payload: AddressPayload,
  ): Promise<GetUserAddressResponse | undefined> => {
    try {
      if (!userId) return;
      return await createNewAddress(userId, payload);
    } catch (error) {
      throw error;
    } finally {
      mutate();
    }
  };

  const updateAddress = async (
    addressId: string,
    newAddress: AddressPayload,
  ) => {
    try {
      mutateAnAddress(addressId, newAddress); // optimistic update
      const updatedAddress = await putUpdateAddressById(
        userId,
        addressId,
        newAddress,
      );

      return mutateAnAddress(addressId, updatedAddress);
    } catch (error) {
      await mutate(prevAddressData); // revert optimistic update
      throw error;
    }
  };

  const markAddressAsDefault = async (addressId: string) => {
    try {
      if (!userId) return;
      return await markAsDefault(userId, addressId);
    } catch (error) {
      throw error;
    } finally {
      mutate();
    }
  };

  const _deleteAddress = async (addressId: string) => {
    try {
      if (!userId) return;

      return await deleteAddress(userId, addressId);
    } catch (error) {
      throw error;
    } finally {
      mutate();
    }
  };

  return {
    addresses: data?.data ?? [],
    error,
    isLoading,
    updateAddress,
    createAddress,
    markAddressAsDefault,
    deleteAddress: _deleteAddress,
  };
};

export default useUserAddresses;
