import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CategoryAutoComplete } from 'lib/productCategory';
import { CollectionAutoComplete } from 'lib/collection';
import notification from 'lib/notification';

import {
  attachProductToCategory,
  attachProductToCollection,
  attachProductToTag,
} from './api';
import useProduct from './useProduct';
import { TagAutoComplete } from 'lib/tag';

const ProductAssociationsConfig = ({ productId }) => {
  const [attachingEntityNames, setAttachingEntityNames] = useState(
    [],
  );
  const { product, mutate } = useProduct(productId);
  const { categories, collections, tags } = product;

  const isAttachingEntity = (entityName) =>
    attachingEntityNames.includes(entityName);

  const setIsAttachingEntity = (entityName, isAttaching) =>
    isAttaching
      ? setAttachingEntityNames((attachingEntityNames) => [
          ...attachingEntityNames,
          entityName,
        ])
      : setAttachingEntityNames((attachingEntityNames) =>
          attachingEntityNames.filter(
            (attachingEntityName) =>
              attachingEntityName !== entityName,
          ),
        );

  const attachEntityToProduct = async ({
    entityName,
    attachRequest,
  }) => {
    try {
      setIsAttachingEntity(entityName, true);
      const { response, error } = await attachRequest();

      if (error) throw error;

      const newProduct = response.data;

      mutate(newProduct);
      notification.success(
        `Successfully attached product to ${entityName}`,
      );
    } catch (error) {
      notification.error(error.message);
    } finally {
      setIsAttachingEntity(entityName, false);
    }
  };

  const onCategoryChange = async (categoryId) => {
    await attachEntityToProduct({
      entityName: 'category',
      attachRequest: () =>
        attachProductToCategory({ categoryId, productId }),
    });
  };

  const onCollectionChange = async (collectionId) => {
    await attachEntityToProduct({
      entityName: 'collection',
      attachRequest: () =>
        attachProductToCollection({
          collectionId,
          productId,
        }),
    });
  };

  const onTagChange = async (tagId) => {
    await attachEntityToProduct({
      entityName: 'tag',
      attachRequest: () =>
        attachProductToTag({
          tagId,
          productId,
        }),
    });
  };

  return (
    <div>
      <CategoryAutoComplete
        name="categoryId"
        label="Category"
        value={(categories || []).map((category) => category.id)}
        onChange={onCategoryChange}
        isLoading={isAttachingEntity('category')}
        isMulti
      />
      <CollectionAutoComplete
        name="collectionId"
        label="Collection"
        value={(collections || []).map((collection) => collection.id)}
        onChange={onCollectionChange}
        isLoading={isAttachingEntity('collection')}
        isMulti
      />
      <TagAutoComplete
        name="tagId"
        label="Tag"
        value={(tags || []).map((tag) => tag.id)}
        onChange={onTagChange}
        isLoading={isAttachingEntity('tag')}
        isMulti
      />
    </div>
  );
};

ProductAssociationsConfig.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default ProductAssociationsConfig;
