export const POINT_CONVERSION = {
  every: {
    currency: 'SGD', // unused at the moment. Could be useful when multi currency is involved
    value: 1,
  },
  points: 3,
};

export const POINT_NAME = 'YB Reps';

export const POINT_ROUNDING_TYPE = {
  UP: 'up',
  DOWN: 'down',
  NEAREST_INTEGER: 'nearest-integer',
  NO_ROUNDING: 'none',
};

export const POINT_ROUNDING = POINT_ROUNDING_TYPE.DOWN;

export const getPointRoundingStrategy = () => {
  switch (POINT_ROUNDING) {
    case POINT_ROUNDING_TYPE.DOWN:
      return Math.floor;

    case POINT_ROUNDING_TYPE.UP:
      return Math.ceil;

    case POINT_ROUNDING_TYPE.NEAREST_INTEGER:
      return Math.round;

    case POINT_ROUNDING_TYPE.NO_ROUNDING:
    default:
      return (amount) => amount; // no rounding
  }
};

export const calculatePointsAwardedByOrderAmount = (orderAmount) => {
  const pointRoundingStrategy = getPointRoundingStrategy();

  return (
    pointRoundingStrategy(
      orderAmount / POINT_CONVERSION.every.value,
    ) * POINT_CONVERSION.points
  );
};
