import { useCRUD } from 'lib/swr-crud';
import {
  getAddonRule,
  updateAddonRule,
  deleteAddonRule,
} from './api';

const useAddonRule = (addonRuleId) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/cms/addon_rules/${addonRuleId}`,
    async () => {
      return getAddonRule(addonRuleId);
    },
  );
  const addonRule = data || null;

  const update = async (body) => {
    const data = await updateAddonRule(addonRuleId, body);
    mutate(data);
    return data;
  };

  const _delete = async () => {
    await deleteAddonRule(addonRuleId);
  };

  return {
    addonRule,
    error,
    update,
    _delete,
    mutate,
    isValidating,
    isLoading,
  };
};

export default useAddonRule;
