import { useListCRUD } from 'lib/swr-crud';

import { getOrderStatusList } from './api';

const fetchers = {
  read: async (_url) => {
    try {
      const response = await getOrderStatusList();

      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

const useOrderStatus = () => {
  const url = '/order-statuses';

  const { data, error, create, isLoading, mutate } = useListCRUD(
    url,
    'id',
    {
      fetchers,
    },
  );

  const orderStatus = data || [];

  return {
    orderStatus,
    create,
    error,
    isLoading,
    mutate,
  };
};

export default useOrderStatus;
