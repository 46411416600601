import { isAxiosError } from 'lib/httpClient';
import { Configuration } from 'swr/dist/types';

const downtimeDelay = 30000;

export const defaultSWRConfig: Partial<Configuration> = {
  onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
    // // Only retry up to 10 times.
    if (retryCount >= 10) return;

    if (isAxiosError(error)) {
      const status = error.response?.status;

      if ([403, 409, 422].includes(status ?? 0)) {
        return;
      }

      if (status === 500) {
        setTimeout(() => revalidate({ retryCount }), downtimeDelay);
        return;
      }
    }

    setTimeout(
      () => revalidate({ retryCount }),
      config.loadingTimeout,
    );
  },
};
