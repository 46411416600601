import { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { TextInput } from 'components/Common';

import datetime, { DATE_FORMAT } from 'lib/datetime';
import { isFunction, isString } from 'lib/javascript';

import 'react-day-picker/lib/style.css';
import styles from './DatePicker.module.css';

function YearMonthForm({
  date,
  localeUtils,
  onChange,
  fromMonth,
  toMonth,
}) {
  const months = localeUtils.getMonths();

  const years = [];
  let fromYear = 1901;
  let toYear = datetime().add(20, 'year').year();
  if (fromMonth) {
    fromYear = datetime(fromMonth).year();
  }
  if (toMonth) {
    toYear = datetime(toMonth).year();
  }

  for (let i = fromYear; i <= toYear; i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select
        name="month"
        onChange={handleChange}
        value={date.getMonth()}
      >
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select
        name="year"
        onChange={handleChange}
        value={date.getFullYear()}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
}

const DatePicker = ({
  value,
  onChange,
  disabled,
  disabledDays,
  fromMonth,
  toMonth,
  label,
  helperText,
  error,
  displayFormat,
  outputFormat,
  required,
  onMonthChange,
  clearable,
  containerClassName,
  inputClassName,
}) => {
  const [currentMonth, setCurrentMonth] = useState(
    value ? datetime(value).toDate() : null,
  );
  const handleOnDayChange = (day, _modifiers, _dayPickerInput) => {
    if (day === null) {
      onChange(null);
    } else {
      const formattedDay = outputFormat
        ? formatDate(day, outputFormat) // if there's a specific output format, format to the required format date string.
        : day; // otherwise leave it as Date object

      onChange(formattedDay);
    }
  };

  const parseDate = (str, format, _locale) => {
    const parsed = datetime(str, format);

    if (!parsed.isValid()) return undefined;

    return parsed.toDate();
  };

  const formatDate = (date, format, _locale) => {
    if (isFunction(format)) return format(date);

    if (isString(format)) return datetime(date).format(format);

    throw new Error('format must be either string or function');
  };

  const getValue = () => {
    return parseDate(value, outputFormat);
  };

  const handleYearMonthChange = (m) => {
    setCurrentMonth(m);
    onMonthChange(m);
  };

  const handleClear = () => {
    handleOnDayChange(null);
  };

  return (
    <div className={cn('position-relative', containerClassName)}>
      <DayPickerInput
        value={getValue()}
        formatDate={formatDate}
        parseDate={parseDate}
        format={displayFormat}
        onDayChange={handleOnDayChange}
        component={(props) => (
          <TextInput
            {...props}
            className={styles.input}
            label={label}
            helperText={helperText}
            error={error}
            required={required}
          />
        )}
        inputProps={{ readOnly: true, disabled }}
        dayPickerProps={{
          disabledDays,
          month: currentMonth,
          fromMonth,
          toMonth,
          captionElement: ({ date, localeUtils }) => (
            <YearMonthForm
              date={date}
              localeUtils={localeUtils}
              onChange={handleYearMonthChange}
              fromMonth={fromMonth}
              toMonth={toMonth}
            />
          ),
          onMonthChange: (month) => onMonthChange(month),
        }}
        classNames={{
          overlay: styles.overlay,
          // reassign the defaults because custom className is not merge with defaults
          container: cn('DayPickerInput', inputClassName),
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
        }}
        placeholder={displayFormat}
      />
      {clearable && value && (
        <div
          className="position-absolute p-2"
          style={{
            top: '50%',
            right: 0,
            transform: 'translateY(-30%)',
            lineHeight: 1,
          }}
          role="button"
          onClick={handleClear}
        >
          <span className="fa fa-times" />
        </div>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  onMonthChange: PropTypes.func,
  disabled: PropTypes.bool,
  disabledDays: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    PropTypes.func,
  ]),
  fromMonth: PropTypes.instanceOf(Date),
  toMonth: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  displayFormat: PropTypes.string,
  outputFormat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  clearable: PropTypes.bool,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};

DatePicker.defaultProps = {
  onChange: () => {},
  displayFormat: DATE_FORMAT,
  onMonthChange: () => {},
  clearable: false,
};

export default DatePicker;
