const defaultOptions = {
  positionClass: 'toast-top-right',
  timeOut: 5000,
  extendedTimeOut: 1000,
  closeButton: true,
  debug: false,
  progressBar: false,
  preventDuplicates: true,
  newestOnTop: false,
  showEasing: 'swing',
  hideEasing: 'swing',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
  showDuration: 300,
  hideDuration: 1000,
};

export default defaultOptions;
