import { useListCRUD } from 'lib/swr-crud';
import { getProducts, postProduct } from './api';

const fetchers = {
  read: async (_url) => {
    const { response, error } = await getProducts();

    if (error) throw error;

    return response.data;
  },
  create: async (_url, product) => {
    const { response, error } = await postProduct(product);

    if (error) throw error;

    return response;
  },
};

const useProducts = () => {
  const url = '/cms/products';

  const { data, error, create, isLoading, mutate } = useListCRUD(
    url,
    'id',
    {
      fetchers,
    },
  );

  const products = data || [];

  return {
    products,
    create,
    error,
    isLoading,
    mutate,
  };
};

export default useProducts;
