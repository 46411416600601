import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const postCategory = async (category) => {
  try {
    const response = await httpClient.post(
      '/cms/categories',
      category,
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const getCategories = async () => {
  try {
    const response = await httpClient.get('/cms/categories');

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const getCategoryById = async (categoryId) => {
  try {
    const response = await httpClient.get(
      `/cms/categories/${categoryId}`,
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const updateCategory = async (categoryId, changes) => {
  try {
    const response = await httpClient.put(
      `/cms/categories/${categoryId}`,
      changes,
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const updateCategoryPublishStatus = async (
  categoryId,
  { publish },
) => {
  try {
    const response = await httpClient.put(
      `/cms/categories/${categoryId}/publish`,
      { publish },
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const publishCategory = async (categoryId) =>
  updateCategoryPublishStatus(categoryId, { publish: true });

export const unpublishCategory = async (categoryId) =>
  updateCategoryPublishStatus(categoryId, { publish: false });

export const deleteCategory = async (categoryId) => {
  try {
    const response = await httpClient.delete(
      `/cms/categories/${categoryId}`,
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};
