import { routePaths } from 'appConfig';
import { Button, Page } from 'components/Common';
import {
  ComplaintActionsSection,
  ComplaintCorrectiveActionPayload,
  ComplaintInformation,
  ComplaintOverview,
  ComplaintPayload,
  ComplaintResolutionPayload,
} from 'lib/complaint';
import useComplaint from 'lib/complaint/use-complaint';
import { useConfirmation } from 'lib/confirm';
import { LogTable, useLogs } from 'lib/log';
import notification from 'lib/notification';
import { useRouter } from 'lib/router';
import { displayName } from 'lib/utils';
import { useEffect, useState } from 'react';
import { List } from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import { getRoleUser } from 'store/auth/GetRoleUser/actions';
import { UserRoleRedux } from 'store/auth/GetRoleUser/types';
import { setComplaintFormStatus } from 'store/features/complaint/actionTypes';

const ComplaintDetailPage = () => {
  const history = useHistory();
  const { complaintId }: { complaintId: string } = useParams();
  const { query, updateQueryParams } = useRouter();
  const { confirm } = useConfirmation();

  const [isMutating, setisMutating] = useState(false);

  const dispatch = useDispatch();
  const roleState = useSelector((state: UserRoleRedux) => state.role);

  const {
    isKitchenRepresentative,
    isAdmin,
    isExecutiveAssistant,
  } = roleState?.role;

  useEffect(() => {
    dispatch(getRoleUser());
  }, [dispatch]);

  const {
    complaint,
    isLoading,
    isUpdating,
    updateComplaint,
    updateCorrectiveAction,
    updateResolution,
    verifyComplaint,
    unverifyComplaint,
    deleteComplaint,
    deleteResolution,
    deleteCorrectiveAction,
    mutate,
  } = useComplaint(complaintId);

  const { first_name, last_name, email, user_id } =
    complaint?.customer || {};

  const { order_number, delivery_datetime, id } =
    complaint?.order || {};
  const { completed_orders_count } = complaint?.customer || {};

  const customerName = displayName({
    firstName: first_name,
    lastName: last_name,
  });

  const { is_verified, is_cannot_verify } = complaint || {};

  const onDeleteComplaint = async () => {
    await confirm();
    try {
      await deleteComplaint();
      notification.success('Feedback deleted.');
      history.push(routePaths.COMPLAINTS);
    } catch (error) {
      if (error?.response?.status === 409) {
        notification.error(
          'This complaint has already been verified and cannot be deleted.',
        );
        return;
      }
      notification.error(
        'Something went wrong while deleting Feedback.',
      );
    }
  };

  const { page = 1, sizePerPage = 15 } = query;

  const logsUrl = `/cms/complaints/${complaintId}/logs`;

  const {
    logs,
    isLoading: isFetchingLogs,
    total,
    mutate: mutateLogs,
  } = useLogs({
    url: logsUrl,
    params: { page, perPage: sizePerPage },
  });

  const onTableChange = async (
    action: 'pagination' | 'sort' | 'filter',
    newState: any,
  ) => {
    switch (action) {
      case 'pagination':
        const { page: _page, sizePerPage } = newState;
        updateQueryParams({
          page: _page,
          sizePerPage,
        });
        break;

      default:
        break;
    }
  };

  const onSubmit = async (payload: ComplaintPayload) => {
    try {
      await updateComplaint(payload);
      mutateLogs();
      mutateComplaint();
      notification.success('Feedback updated.');
      dispatch(
        setComplaintFormStatus('isComplaintFormDisable', true),
      );
    } catch (error) {
      notification.error(
        'Something went wrong while updating Feedback.',
      );
    }
  };

  const onSubmitResolution = async (
    payload: ComplaintResolutionPayload,
  ) => {
    try {
      await updateResolution(payload);
      mutateLogs();
      mutateComplaint();
      notification.success('Resolution updated.');
      dispatch(
        setComplaintFormStatus('isResolutionFormDisable', true),
      );
    } catch (error) {
      notification.error(
        'Something went wrong while updating feedback resolution.',
      );
    }
  };

  const onSubmitCorrectiveAction = async (
    payload: ComplaintCorrectiveActionPayload,
  ) => {
    try {
      await updateCorrectiveAction(payload);
      mutateLogs();
      mutateComplaint();
      notification.success('Corrective action updated.');
      dispatch(
        setComplaintFormStatus('isCorrectiveActionFormDisable', true),
      );
    } catch (error) {
      notification.error(
        'Something went wrong while updating feedback corrective action.',
      );
    }
  };

  const onDeleteResolution = async () => {
    await confirm();
    try {
      await deleteResolution();
      notification.success('Resolution deleted.');
      await mutateComplaint();
      await mutateLogs();
    } catch (error) {
      notification.error(
        'Something went wrong while deleting feedback resolution.',
      );
    }
  };

  const onDeleteCorrectiveAction = async () => {
    await confirm();
    try {
      await deleteCorrectiveAction();
      notification.success('Corrective action deleted.');
      await mutateComplaint();
      await mutateLogs();
    } catch (error) {
      notification.error(
        'Something went wrong while deleting feedback corrective action.',
      );
    }
  };

  const mutateComplaint = async () => {
    await mutate();
    if (!isMutating) {
      setisMutating(true);
      try {
        await mutate();
      } finally {
        setisMutating(false);
      }
    }
  };

  const onVerifyComplaint = async () => {
    try {
      await verifyComplaint();
      await mutateComplaint();
      await mutateLogs();
      dispatch(
        setComplaintFormStatus('isResolutionFormDisable', true),
      );
      dispatch(
        setComplaintFormStatus('isCorrectiveActionFormDisable', true),
      );
      dispatch(
        setComplaintFormStatus('isComplaintFormDisable', true),
      );
      notification.success('Feedback verified.');
    } catch (error) {
      notification.error(
        'Something went wrong while verifying feedback.',
      );
    }
  };

  const onUnverifyComplaint = async () => {
    try {
      await unverifyComplaint();
      await mutateComplaint();
      await mutateLogs();
      notification.success('Feedback unverified.');
    } catch (error) {
      notification.error(
        'Something went wrong while unverifying feedback.',
      );
    }
  };

  return (
    <Page
      content={
        <>
          {isLoading ? (
            <List uniqueKey="complaint-detail-loading" />
          ) : (
            <>
              <Row>
                <Col>
                  {/* @ts-ignore */}
                  <Button
                    className={`float-right mb-3 mr-2 ${
                      !isAdmin && !isExecutiveAssistant && 'd-none'
                    }`}
                    onClick={onDeleteComplaint}
                    style={{ width: '100px' }}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardHeader className="bg-white pb-0">
                      <CardTitle>Order Information</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <ComplaintInformation
                        orderNumber={order_number ?? ''}
                        deliveryDatetime={delivery_datetime ?? ''}
                        orderId={id ?? ''}
                        userId={user_id ?? ''}
                        customerName={customerName}
                        email={email ?? ''}
                        completeOrderCount={
                          completed_orders_count ?? 0
                        }
                        user={complaint?.order?.user}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardHeader className="bg-white pb-0">
                      <CardTitle>Feedback Overview</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <ComplaintOverview complaint={complaint} />
                      {/* @ts-ignore */}
                      <Button
                        className={`mb-2 ${!isAdmin && 'd-none'}`}
                        loading={isUpdating || isMutating}
                        block={true}
                        disabled={
                          is_cannot_verify || isUpdating || isMutating
                        }
                        onClick={
                          is_verified
                            ? onUnverifyComplaint
                            : onVerifyComplaint
                        }
                      >
                        {isUpdating || isMutating
                          ? 'Updating...'
                          : is_verified
                          ? 'Unverify'
                          : 'Verify'}
                      </Button>
                      <span
                        className={`align-middle text-muted ${
                          !(is_cannot_verify && isAdmin) && 'd-none'
                        }`}
                      >
                        Feedback need to be Corrected and Resolved
                        before it can be Verified
                      </span>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <ComplaintActionsSection
                data={complaint}
                complaintId={complaintId}
                isKitchenRepresentative={isKitchenRepresentative}
                isAdmin={isAdmin}
                onSubmit={onSubmit}
                onSubmitResolution={onSubmitResolution}
                onSubmitCorrectiveAction={onSubmitCorrectiveAction}
                mutateComplaint={mutateComplaint}
                onDeleteCorrectiveAction={onDeleteCorrectiveAction}
                onDeleteResolution={onDeleteResolution}
              />
              <Row>
                <Col>
                  <Card>
                    <CardHeader className="bg-white pb-0">
                      <CardTitle>Feedback Logs</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <LogTable
                        data={logs}
                        isLoading={isFetchingLogs}
                        onTableChange={onTableChange}
                        totalSize={total}
                        page={parseInt(page?.toString() ?? '1')}
                        perPage={sizePerPage}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </>
      }
    />
  );
};

export default ComplaintDetailPage;
