import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'core/components';
import { ReferreePropType } from '../typedef';
import { Link } from 'lib/router';
import { FullName } from 'lib/users';
import { Datetime } from 'lib/datetime';
import { routePaths } from 'appConfig';

const ReferreesTable = ({
  isLoading,
  data,
  currentPage,
  total,
  sizePerPage,
  onChangePage,
  onChangeSizePerPage,
}) => {
  const columns = [
    {
      dataField: 'triggered_by_order.user',
      text: 'Name',
      formatter: (user) => {
        if (!user) return '(No Record)';

        return (
          <Link
            to={{
              pathname: routePaths.USER_DETAILS,
              urlParams: {
                userId: user.user_id,
              },
            }}
          >
            <FullName user={user} />
          </Link>
        );
      },
    },
    {
      dataField: 'created_at',
      text: 'Referral Completed At',
      formatter: (referredAt) => <Datetime value={referredAt} />,
    },
  ];

  const onTableChange = (type, newState) => {
    switch (type) {
      case 'pagination':
        const { page, sizePerPage } = newState;
        onChangePage(page);
        onChangeSizePerPage(sizePerPage);
        break;

      default:
    }
  };

  return (
    <div>
      <Table
        remote
        keyField="user.user_id"
        loading={isLoading}
        columns={columns}
        data={data}
        noDataIndication={() => (
          <tr>
            <td>
              <p>No Data</p>
            </td>
          </tr>
        )}
        pagination={{
          page: currentPage,
          totalSize: total,
          sizePerPage,
          sizePerPageList: [
            {
              text: '5',
              value: 5,
            },
            {
              text: '10',
              value: 10,
            },
            {
              text: '15',
              value: 15,
            },
          ],
        }}
        onTableChange={onTableChange}
      />
    </div>
  );
};

ReferreesTable.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(ReferreePropType),
  currentPage: PropTypes.number,
  total: PropTypes.number,
  sizePerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeSizePerPage: PropTypes.func,
};

ReferreesTable.defaultProps = {
  isLoading: false,
  data: [],
  currentPage: 1,
  total: 0,
  sizePerPage: 5,
  onChangePage: () => {},
  onChangeSizePerPage: () => {},
};

export default ReferreesTable;
