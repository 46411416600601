import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete } from 'components/Common';
import useCollections from './useCollections';

const CollectionAutoComplete = ({
  name,
  value,
  onChange,
  label,
  required,
  error,
  helperText,
  isMulti,
  isLoading: isLoadingProp,
  disabled,
}) => {
  const [total, setTotal] = React.useState(15);

  const {
    total: totalCollections,
    isLoading: isCollectionsLoading,
    collections,
  } = useCollections({ sizePerPage: total });

  React.useEffect(() => {
    if (totalCollections && total !== totalCollections)
      setTotal(totalCollections);
  }, [total, totalCollections]);

  const isLoading = isLoadingProp || isCollectionsLoading;
  const shouldDisable = disabled || isLoading;

  return (
    <AutoComplete
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      options={collections}
      getOptionLabel={(category) => category.name}
      getOptionValue={(category) => category.id}
      isMulti={isMulti}
      isLoading={isLoading}
      disabled={shouldDisable}
    />
  );
};

CollectionAutoComplete.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

CollectionAutoComplete.defaultProps = {
  isMulti: false,
  isLoading: false,
  disabled: false,
};

export default CollectionAutoComplete;
