import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const postProduct = async (product) => {
  try {
    const response = await httpClient.post(`/cms/products`, product);

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const getProducts = async () => {
  try {
    const response = await httpClient.get(`/cms/products`);

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const updateProductPublishStatus = async (
  productId,
  { publish },
) => {
  try {
    const response = await httpClient.put(
      `/cms/products/${productId}/publish`,
      { publish },
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const publishProduct = async (productId) =>
  updateProductPublishStatus(productId, { publish: true });

export const unpublishProduct = async (productId) =>
  updateProductPublishStatus(productId, { publish: false });

export const getProductById = async (productId) => {
  try {
    const response = await httpClient.get(
      `/cms/products/${productId}`,
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const attachProductToCategory = async ({
  categoryId,
  productId,
}) => {
  try {
    const response = await httpClient.put(
      '/cms/products/categories/attach',
      { category_ids: categoryId, product_id: productId },
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const attachProductToCollection = async ({
  collectionId,
  productId,
}) => {
  try {
    const response = await httpClient.put(
      `/cms/products/collections/attach`,
      { collection_ids: collectionId, product_id: productId },
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const attachProductToTag = async ({ tagId, productId }) => {
  try {
    const response = await httpClient.put(
      `/cms/products/tags/attach`,
      { tag_ids: tagId, product_id: productId },
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const putProduct = async (
  productId,
  { tab_contents, ...product },
) => {
  try {
    const response = await httpClient.put(
      `/cms/products/${productId}`,
      {
        ...product,
        tab_contents: tab_contents && JSON.stringify(tab_contents),
      },
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return {
      error: getErrorMessage(err),
    };
  }
};

export const deleteProduct = async (productId) => {
  try {
    const response = await httpClient.delete(
      `/cms/products/${productId}`,
    );

    return { response: getResponseData(response) };
  } catch (err) {
    return {
      error: getErrorMessage(err),
    };
  }
};
