import React from 'react';
import PropTypes from 'prop-types';

import { Price, Table } from 'core/components';
import { Link, useRouter } from 'lib/router';
import useSalesReportByCategory from './useSalesReportByCategory';
import { routePaths } from 'appConfig';

const ReportByCouponTable = ({ startDate, endDate }) => {
  const { query, updateQueryParams } = useRouter();

  const { sizePerPage, page } = query;

  const {
    isLoading,
    salesReportByCategory,
    total,
  } = useSalesReportByCategory({
    startDate,
    endDate,
    sizePerPage,
    page,
  });

  const columns = [
    {
      dataField: 'category_name',
      text: 'Category',
      formatter: (category_name, category) => (
        <Link to={`${routePaths.CATEGORIES}/${category.category_id}`}>
          {category_name}
        </Link>
      ),
    },
    {
      dataField: 'total_items',
      text: 'Items Sold',
    },
    {
      dataField: 'net_sales',
      text: 'Net Sales',
      formatter: (netSales) => <Price value={netSales} />,
    },
    {
      dataField: 'total_orders',
      text: 'Orders',
    },
  ];

  const onTableChange = (action, newState) => {
    switch (action) {
      case 'pagination':
        const { sizePerPage, page } = newState;
        updateQueryParams({ sizePerPage, page });
        break;

      default:
    }
  };

  return (
    <Table
      remote
      loading={isLoading}
      keyField="category_id"
      data={salesReportByCategory || []}
      columns={columns}
      pagination={{ page, sizePerPage, totalSize: total }}
      onTableChange={onTableChange}
    />
  );
};

ReportByCouponTable.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default ReportByCouponTable;
