import React from 'react';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import ComplaintForm from './ComplaintForm';
import { Button } from 'components/Common';
import { ComplaintPayload } from './types';

interface ComplaintOrFeedbackCardProps {
  isComplaintFormDisable: boolean;
  complaintData: any;
  onSubmit: (values: ComplaintPayload) => Promise<void>;
  onCancelComplaint: () => void;
  mutateComplaint: () => void;
  is_verified: boolean;
  isKitchenRepresentative: boolean;
  setisComplaintFormDisable: (value: boolean) => void;
}

const ComplaintOrFeedbackCard: React.FC<ComplaintOrFeedbackCardProps> = ({
  isComplaintFormDisable,
  complaintData,
  onSubmit,
  onCancelComplaint,
  mutateComplaint,
  is_verified,
  isKitchenRepresentative,
  setisComplaintFormDisable,
}) => (
  <Card>
    <CardHeader className="bg-white pb-0">
      <CardTitle>
        <div className="d-flex flex-row align-items-center justify-content-between">
          Feedback
          {isComplaintFormDisable && (
            // @ts-ignore
            <Button
              className={`float-right ${
                (is_verified || isKitchenRepresentative) && 'd-none'
              }`}
              onClick={() => setisComplaintFormDisable(false)}
            >
              Edit Feedback
            </Button>
          )}
        </div>
      </CardTitle>
    </CardHeader>
    <CardBody>
      <ComplaintForm
        isDisabled={isComplaintFormDisable}
        data={complaintData}
        onSubmit={onSubmit}
        onCancel={onCancelComplaint}
        showCancel
        mutate={mutateComplaint}
      />
    </CardBody>
  </Card>
);

export default ComplaintOrFeedbackCard;
