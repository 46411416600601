import { call, put, takeEvery } from 'redux-saga/effects';
import { SET_COMPLAINT_FORM_STATUS } from './actionTypes';

const DISABLE_COMPLAINT_FORM_ACTION = 'DISABLE_COMPLAINT_FORM_ACTION';
const ENABLE_COMPLAINT_FORM_ACTION = 'ENABLE_COMPLAINT_FORM_ACTION';

function* handleSetComplaintFormStatus(action) {
  try {
    yield put({
      type: SET_COMPLAINT_FORM_STATUS,
      payload: action.payload,
    });
  } catch (error) {
    console.error(error);
  }
}

function* watchComplaintFormStatusActions() {
  yield takeEvery(
    [DISABLE_COMPLAINT_FORM_ACTION, ENABLE_COMPLAINT_FORM_ACTION],
    handleSetComplaintFormStatus,
  );
}

export function* complaintFormSaga() {
  yield watchComplaintFormStatusActions();
}
