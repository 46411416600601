import React from 'react';
import PropTypes from 'prop-types';

import notification from 'lib/notification';

import TagForm from './TagForm';
import { putUpdateTag } from './api';
import useTag from './useTag';

const EditTagForm = ({ tagId, onSubmit }) => {
  const { isLoading, tag, mutate } = useTag(tagId);

  const handleOnSubmit = async (values, actions) => {
    try {
      const response = await putUpdateTag(tagId, values);

      await mutate();
      notification.success('Successfully updated tag. ');
      onSubmit(null, response);
      return;
    } catch (err) {
      actions.setErrors(err.errors);
      actions.setStatus({
        error: err.errors?.message || 'Error updating tag',
      });
      onSubmit(err, null);
      return;
    }
  };

  if (isLoading) return null;

  return (
    <TagForm
      initialValues={tag}
      onSubmit={handleOnSubmit}
      buttonText="Update"
    />
  );
};

EditTagForm.propTypes = {
  tagId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

EditTagForm.defaultProps = {
  onSubmit: () => {},
};

export default EditTagForm;
