import { COUPON_OPERATOR } from './typedef';

/**
 * @typedef {import('./typedef').StaticLog} StaticLog
 */

/**
 * @param {COUPON_OPERATOR|null} operatorName
 */
export const changeOperatorName = (operatorName) => {
  if (operatorName === COUPON_OPERATOR.and) {
    return 'All';
  }

  if (operatorName === COUPON_OPERATOR.or) {
    return 'At least one';
  }

  return '';
};

/**
 * @param {(StaticLog[]|null)} oldLogs
 * @param {(StaticLog[]|null)} newLogs
 */
export const isStaticLogsDifferent = (oldLogs, newLogs) => {
  if (oldLogs === null && newLogs === null) return false;

  const newLogIds = newLogs?.map((log) => log.id);
  const oldLogIds = oldLogs?.map((log) => log.id);

  const hasEveryItem = newLogIds?.every((newId) =>
    oldLogIds?.includes(newId),
  );
  const hasSameTotal = oldLogs?.length === newLogs?.length;

  return !(hasEveryItem && hasSameTotal);
};
