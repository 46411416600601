import React from 'react';
import PropTypes from 'prop-types';
import useSessionListener from './useSessionListener';

const SessionListener = ({ children }) => {
  useSessionListener();

  return <React.Fragment>{children}</React.Fragment>;
};

SessionListener.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SessionListener;
