import { routePaths } from 'appConfig';

// Profile
import UserProfile from '../pages/Authentication/user-profile';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import ForgetPwd from '../pages/Authentication/ForgetPassword';
import ResetPassword from '../pages/Authentication/ResetPassword';

// users
import UsersPage from '../pages/users';
import UserDetailsPage from '../pages/users/[userId]';

// Misc
import PagesMaintenance from '../pages/Utility/pages-maintenance';
import PagesComingsoon from '../pages/Utility/pages-comingsoon';

// product-related
import CategoriesPage from '../pages/categories';
import NewCategoryPage from '../pages/categories/new';
import CategoryDetailsPage from '../pages/categories/[categoryId]';
import ProductsPage from '../pages/products';
import NewProductsPage from '../pages/products/new';
import ProductDetailsPage from '../pages/products/[productId]';
import SetupVariantsPage from '../pages/products/[productId]/variants/setup';
import VariantPage from '../pages/products/[productId]/variants/[variantId]';

// meals
import IngredientsPage from '../pages/ingredients';
import NewIngredientPage from '../pages/ingredients/new';
import IngredientDetailsPage from '../pages/ingredients/[ingredientId]';
import AddonOptionsPage from '../pages/addon-options';
import NewAddonOptionPage from '../pages/addon-options/new';
import AddonOptionDetailsPage from '../pages/addon-options/[addonOptionId]';
import AddonRulesPage from '../pages/addon-rules';
import NewAddonRulePage from '../pages/addon-rules/new';
import AddonRuleDetailsPage from '../pages/addon-rules/[addonRuleId]';

// orders
import OrdersPage from '../pages/orders';
import OrderDetailsPage from '../pages/orders/[orderId]';
import ExportOrdersPage from '../pages/orders/export';

// coupons
import CouponsPage from '../pages/coupons';
import NewCouponPage from '../pages/coupons/new';
import CouponDetailsPage from '../pages/coupons/[couponCode]';

// donations
import DonationsPage from '../pages/donations';
import NewDonationPage from '../pages/donations/new';
import DonationDetailsPage from '../pages/donations/[donationId]';

//operation
import OperationPage from 'pages/operation';

// collection points
import CollectionPointsPage from 'pages/collection-points';
import CollectionPointDetailPage from 'pages/collection-points/[collectionPointId]';
import NewCollectionPointPage from 'pages/collection-points/new';

// collections
import CollectionsPage from '../pages/collections';
import NewCollectionPage from '../pages/collections/new';
import CollectionDetailsPage from '../pages/collections/[collectionId]';

// manual collection (catalogues)
import CataloguesPage from '../pages/catalogues';
import NewCataloguePage from '../pages/catalogues/new';
import CatalogueDetailsPage from 'pages/catalogues/[catalogueId]';

// analytics
import AnalyticsPage from '../pages/analytics';

// tags
import TagsPage from 'pages/tags';
import NewTagPage from 'pages/tags/new';
import TagDetailsPage from 'pages/tags/[tagId]';

// CRM
import LoveMessagesPage from 'pages/love-messages';
import LoveMessageDetailPage from 'pages/love-messages/[loveMessageId]';
import ComplaintCategoriesPage from 'pages/complaint-categories';
import ComplaintCategoryDetailPage from 'pages/complaint-categories/[complaintCategoryId]';
import NewComplaintCategoryPage from 'pages/complaint-categories/new';

// Report
import KitchenReportProduct from 'pages/kitchen/KitchenReportProduct';
import ComplaintsPage from 'pages/complaints';
import ComplaintDetailPage from 'pages/complaints/[complaintId]';

import { CMSRoles, KitchenRoles } from 'lib/users/constants';

const userRoutes = [
  // user
  {
    path: routePaths.USERS,
    exact: true,
    component: UsersPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.USER_DETAILS,
    component: UserDetailsPage,
    roles: [...CMSRoles],
  },

  // product-related
  {
    path: routePaths.CATEGORIES,
    exact: true,
    component: CategoriesPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.NEW_CATEGORY,
    component: NewCategoryPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.CATEGORY_DETAILS,
    component: CategoryDetailsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.PRODUCTS,
    exact: true,
    component: ProductsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.NEW_PRODUCT,
    component: NewProductsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.PRODUCT_DETAILS,
    exact: true,
    component: ProductDetailsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.PRODUCT_VARIANT_SETUP,
    component: SetupVariantsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.PRODUCT_VARIANT,
    component: VariantPage,
    roles: [...CMSRoles],
  },

  /* --------------------------------- meal --------------------------------- */
  {
    path: routePaths.INGREDIENTS,
    exact: true,
    component: IngredientsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.NEW_INGREDIENT,
    component: NewIngredientPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.INGREDIENT_DETAIL,
    component: IngredientDetailsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.ADDON_OPTIONS,
    exact: true,
    component: AddonOptionsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.NEW_ADDON_OPTION,
    component: NewAddonOptionPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.ADDON_OPTION_DETAILS,
    component: AddonOptionDetailsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.ADDON_RULES,
    exact: true,
    component: AddonRulesPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.NEW_ADDON_RULE,
    component: NewAddonRulePage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.ADDON_RULE_DETAILS,
    component: AddonRuleDetailsPage,
    roles: [...CMSRoles],
  },

  /* --------------------------------- orders --------------------------------- */
  {
    path: routePaths.ORDERS,
    exact: true,
    component: OrdersPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.ORDERS_EXPORT,
    component: ExportOrdersPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.ORDER_DETAIL,
    component: OrderDetailsPage,
    roles: [...CMSRoles, ...KitchenRoles],
  },

  /* --------------------------------- coupons -------------------------------- */
  {
    path: routePaths.COUPONS,
    exact: true,
    component: CouponsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.NEW_COUPON,
    component: NewCouponPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.COUPON_DETAILS,
    component: CouponDetailsPage,
    roles: [...CMSRoles],
  },

  /* --------------------------------- donations -------------------------------- */
  {
    path: routePaths.DONATIONS,
    exact: true,
    component: DonationsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.NEW_DONATION,
    component: NewDonationPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.DONATION_DETAILS,
    component: DonationDetailsPage,
    roles: [...CMSRoles],
  },

  /* -------------------------------- operation ------------------------------- */
  {
    path: routePaths.OPERATION,
    exact: true,
    component: OperationPage,
    roles: [...CMSRoles],
  },

  /* --------------------------- collection points --------------------------- */
  {
    path: routePaths.COLLECTION_POINTS,
    exact: true,
    component: CollectionPointsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.NEW_COLLECTION_POINT,
    component: NewCollectionPointPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.COLLECTION_POINT_DETAILS,
    component: CollectionPointDetailPage,
    roles: [...CMSRoles],
  },

  /* --------------------------------- coupons -------------------------------- */
  {
    path: routePaths.COLLECTIONS,
    exact: true,
    component: CollectionsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.NEW_COLLECTION,
    component: NewCollectionPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.COLLECTION_DETAIL,
    component: CollectionDetailsPage,
    roles: [...CMSRoles],
  },

  /* ------------------ manual collection (catalogues) -------------------- */
  {
    path: routePaths.CATALOGUES,
    exact: true,
    component: CataloguesPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.NEW_CATALOGUE,
    component: NewCataloguePage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.CATALOGUE_DETAIL,
    component: CatalogueDetailsPage,
    roles: [...CMSRoles],
  },

  // //profile
  {
    path: routePaths.USER_PROFILE,
    component: UserProfile,
    roles: [...CMSRoles],
  },

  /* -------------------------------- analytics ------------------------------- */
  {
    path: routePaths.ANALYTICS,
    exact: true,
    component: AnalyticsPage,
    roles: [...CMSRoles],
  },

  /* ---------------------------------- tags ---------------------------------- */
  {
    path: routePaths.TAGS,
    exact: true,
    component: TagsPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.NEW_TAG,
    component: NewTagPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.TAG_DETAIL,
    component: TagDetailsPage,
    roles: [...CMSRoles],
  },

  /* ----------------------------- love messages -------------------------------- */
  {
    path: routePaths.LOVE_MESSAGES,
    exact: true,
    component: LoveMessagesPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.LOVE_MESSAGE_DETAIL,
    component: LoveMessageDetailPage,
    roles: [...CMSRoles],
  },

  /* ---------------------------------- report ---------------------------------- */
  {
    path: routePaths.REPORT_KITCHEN_PRODUCT,
    exact: true,
    component: KitchenReportProduct,
    roles: [...CMSRoles],
  },

  /* ---------------------------------- misc ---------------------------------- */
  // {
  //   path: routePaths.HOME,
  //   // exact: true,
  //   component: HomeRedirect,
  // },
  /* ---------------------------------- complaint category ---------------------------------- */
  {
    path: routePaths.NEW_COMPLAINT_CATEGORY,
    exact: true,
    component: NewComplaintCategoryPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.COMPLAINT_CATEGORIES,
    exact: true,
    component: ComplaintCategoriesPage,
    roles: [...CMSRoles],
  },
  {
    path: routePaths.COMPLAINT_CATEGORY_DETAIL,
    component: ComplaintCategoryDetailPage,
    roles: [...CMSRoles, ...KitchenRoles],
  },
  /* ---------------------------------- complaint category ---------------------------------- */
  {
    path: routePaths.COMPLAINTS,
    exact: true,
    component: ComplaintsPage,
    roles: [...CMSRoles, ...KitchenRoles],
  },
  {
    path: routePaths.COMPLAINT_DETAIL,
    component: ComplaintDetailPage,
    roles: [...CMSRoles, ...KitchenRoles],
  },
];

const authRoutes = [
  { path: routePaths.LOGOUT, component: Logout },
  { path: routePaths.LOGIN, component: Login },
  { path: routePaths.FORGOT_PASSWORD, component: ForgetPwd },
  { path: routePaths.RESET_PASSWORD, component: ResetPassword },

  { path: routePaths.PAGE_MAINTENANCE, component: PagesMaintenance },
  { path: routePaths.PAGE_COMING_SOON, component: PagesComingsoon },
];

export { userRoutes, authRoutes };
